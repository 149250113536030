<template>
  <v-card
    :min-width="cardMinWidth"
    :max-width="cardMaxWidth"
    :height="cardHeight"
    :max-height="cardHeight"
    :min-height="cardHeight"
    tile
  >
    <v-img :height="cardHeight" :src="getImage(backgroundImage)" />
    <v-row
      :style="
        'margin: 0%; position: absolute; top:' +
        avatarTop +
        'px; left:' +
        avatarLeft +
        'px'
      "
    >
      <v-list-item>
        <v-list-item-avatar
          color="white"
          class="pl-4"
          :size="outlineAvatarsSize"
          style="z-index: 1"
        >
          <v-list-item-avatar :size="avatarSize" :color="backColor">
            <span
              class="white--text"
              style="font-family: Inter; font-size: 200.25%"
              :style="' font-size:' + fontSizeImg"
              v-if="!isImg"
            >
              {{ photo }}
            </span>
            <v-img
              v-else
              :src="getAvatarImage(photo)"
              :lazy-src="getAvatarImage(photo)"
              alt="John"
            />
          </v-list-item-avatar>
        </v-list-item-avatar>
        <v-list-item-content class="mb-8 pt-0">
          <v-list-item-title
            class="pb-0 textDecorationBold whiteColor"
            style="font-size: 24px !important; margin-top: 20px"
          >
            {{ title }}
          </v-list-item-title>
          <v-list-item-title
            class="pt-0 pb-6 textDecorationBold whiteColor"
            style="font-size: 14px !important"
          >
            {{ subTitle }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-row>
    <v-row
      class="mt-3 justify-center"
      :style="'position: absolute; top: 0px; right:' + logoRight + '%;'"
    >
      <v-col class="pb-0" cols="12" align="center">
        <v-img
          class="mt-6"
          max-height="50"
          max-width="140"
          :src="getImage(logoImage)"
        ></v-img>
      </v-col>
      <v-col class="pb-4 pt-0" align="center">
        <span class="font18" cols="12" style="color: white">
          {{imageTitle}}
        </span>
      </v-col>
      <v-list-item>
        <v-list-item-content class="mb-8 pt-0">
          <v-list-item-title
            align="center"
            class="justify-center whiteColor"
            style="font-size: 18px !important"
          >
            {{ logoTitle }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-row>
  </v-card>
</template>
<script>
/*
 Name:PersonAppBar
 Description: Component with an appbar and a avatar with title, subtitle and a image at right with a title also
 API Property:
    cardMinWidth: v-card min-width
    cardMaxWidth: v-card max-width
    cardHeight: v-card height
    outlineAvatarsSize: v-avatars ouline box size
    avatarSize: v-avatar size
    avatarTop: v-avatar top margin
    avatarLeft: v-avatar left margin
    title: title next to v-avatar
    subTitle: subtitle next to v-avatar
    imageTitle: { type: String, default: "" }, // subtitle below the image 
    logoTitle: subtitle next to v-avatar
    logoRight: logo (company image with subtitle) right margin
    avatarImage:
      {
          path: require('@/assets/draggable/addImageCard.png'),
          absolutePath: true,
      }
    logoImage:
      {
          path: require('@/assets/draggable/addImageCard.png'),
          absolutePath: true,
      }
    backgroundImage:
      {
          path: require('@/assets/Persons/background.png'),
          absolutePath: true,
      }
    backgroundColor: Background color of avatar
    isImg: If component render img or name
    photo: String containing image path or name initials
*/
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "PersonAppBar",
  props: {
    cardMinWidth: { type: Number, default: 1000 }, // v-card min-width
    cardMaxWidth: { type: Number, default: 1600 }, // v-card max-width
    cardHeight: { type: Number, default: 133 }, // v-card height
    fontSizeImg: { type: [String, Number], default: "43px" },
    outlineAvatarsSize: { type: Number, default: 155 }, // v-avatars ouline box size
    avatarSize: { type: Number, default: 145 }, // v-avatar size
    avatarTop: { type: Number, default: 45 }, // v-avatar top margin
    avatarLeft: { type: Number, default: 30 }, // v-avatar left margin
    backgroundColor: { type: String, default: "#263573" }, //background color of avatar
    isImg: { type: Boolean, default: false }, // If component render img or name
    photo: String, //String containing image path or name initials

    avatarImage: {
      type: Object,
      default() {
        return {
          path: require("@/assets/general/no_img_found.png"),
          absolutePath: true,
        };
      },
    }, // v-avatar image
    logoImage: {
      type: Object,
      default() {
        return {
          path: require("@/assets/companyCard/whiteLogo.png"),
          absolutePath: true,
        };
      },
    }, // subtitle next to v-avatar
    backgroundImage: {
      type: Object,
      default() {
        return {
          path: require("@/assets/persons/background.png"),
          absolutePath: true,
        };
      },
    }, // background image url
    title: { type: String, default: "" }, // title next to v-avatar
    subTitle: { type: String, default: "" }, // subtitle next to v-avatar
    logoTitle: { type: String, default: "" }, // subtitle next to v-avatar
    imageTitle: { type: String, default: "" }, // subtitle below the image
    logoRight: { type: Number, default: 5 }, // logo (company image with subtitle) right margin
  },
  created() {
    this.isImg
      ? (this.backColor = "#FFFFFF")
      : (this.backColor = this.backgroundColor);
  },
  methods: {
    getImage(item) {
      return CoreHelper.getImagePath(item);
    },
    getAvatarImage(item) {
      return CoreHelper.getImagePath({
        path: item,
        absolutePath: !this.isImg,
      });
    },
  },
};
</script>
