var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pl-0 pr-0 pt-0 pb-0"},[_c('v-row',{staticClass:"justify-space-around flexColMedia"},[_c('v-col',[_c('gmap-map',{staticClass:"map",style:("height: " + (this.mapHeight) + "; width: " + (this.mapWidth) + ";" + _vm.mapStyle),attrs:{"options":{
        styles: _vm.style,
        mapTypeControl: false,
        scaleControl: true,
        scrollwheel: true,
        navigationControl: false,
        streetViewControl: true,
        fullscreenControl: true,
      },"zoom":_vm.zoomComp,"center":_vm.center},on:{"bounds_changed":_vm.setCoordinates,"zoom_changed":_vm.searchByCoords,"dragend":_vm.searchByCoords}},[_c('GmapCluster',{attrs:{"zoom-on-click":true,"maxZoom":16,"averageCenter":true}},_vm._l((_vm.filteredLocationMarkers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"icon":_vm.markerIcon(m.icon, m.type)},on:{"click":function($event){_vm.center = _vm.mClicked(m)}}})}),1)],1)],1),(_vm.showDetails && _vm.oneMarker)?_c('v-col',{staticClass:"align-self-top mt-3 ml-5",attrs:{"xs":12,"sm":12,"md":6,"lg":4,"xl":4}},[(_vm.isMedia)?_c('v-row',[_c('v-col',{attrs:{"align":"left"}},[_c('HeaderTitle',{attrs:{"title":_vm.$t('theLocationOfTheProperty')}})],1)],1):_vm._e(),(
        ![undefined, '', null].includes(
          _vm.filteredLocationMarkers[0].data.Country
        )
      )?_c('v-row',{staticClass:"mb-4 mt-4"},[_c('v-col',{attrs:{"align":"left"}},[_c('v-row',{staticClass:"listHeader"},[_vm._v(" "+_vm._s(_vm.$t("country"))+" ")]),_c('v-row',{staticClass:"listContent"},[_vm._v(" "+_vm._s(_vm.filteredLocationMarkers[0].data.Country)+" ")])],1)],1):_vm._e(),(![undefined, '', null].includes(_vm.filteredLocationMarkers[0].data.AreaSP))?_c('v-row',{staticClass:"mb-4 mt-4"},[_c('v-col',{attrs:{"align":"left"}},[_c('v-row',{staticClass:"listHeader"},[_vm._v(" "+_vm._s(_vm.$t("region"))+" ")]),_c('v-row',{staticClass:"listContent"},[_vm._v(" "+_vm._s(_vm.filteredLocationMarkers[0].data.AreaSP)+" ")])],1)],1):_vm._e(),(![undefined, '', null].includes(_vm.filteredLocationMarkers[0].data.Area))?_c('v-row',{staticClass:"mb-4 mt-4"},[_c('v-col',{attrs:{"align":"left"}},[_c('v-row',{staticClass:"listHeader"},[_vm._v(" "+_vm._s(_vm.$t("city"))+" ")]),_c('v-row',{staticClass:"listContent"},[_vm._v(" "+_vm._s(_vm.filteredLocationMarkers[0].data.Area)+" ")])],1)],1):_vm._e(),(![undefined, '', null].includes(_vm.filteredLocationMarkers[0].data.Address))?_c('v-row',{staticClass:"mb-4 mt-4"},[_c('v-col',{attrs:{"align":"left"}},[_c('v-row',{staticClass:"listHeader"},[_vm._v(" "+_vm._s(_vm.$t("addressNumber"))+" ")]),_c('v-row',{staticClass:"listContent"},[_vm._v(" "+_vm._s(_vm.filteredLocationMarkers[0].data.Address)+" ")])],1)],1):_vm._e(),(![undefined, '', null].includes(_vm.filteredLocationMarkers[0].data.PostalCode))?_c('v-row',{staticClass:"mb-4 mt-4"},[_c('v-col',{attrs:{"align":"left"}},[_c('v-row',{staticClass:"listHeader text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("postalCode"))+" ")]),_c('v-row',{staticClass:"listContent"},[_vm._v(" "+_vm._s(_vm.filteredLocationMarkers[0].data.PostalCode)+" ")])],1)],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }