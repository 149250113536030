<template>
  <div>
    <v-radio-group v-model="getRadioValue" :row="isRow">
      <v-radio
        v-for="(item, i) in options"
        :key="i"
        color="warning"
        :label="$t(item.label)"
        :value="item.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>

/*
 Name:RadioField
 Description: Component with a group of v-radio choices and return the v-radio choice of the user.
 API Property:
  val: Incoming Field Value
  fieldName:  Incoming Field Name, (Will update this field in parent)
  isRow: True is row False is column
  options: Radio options
  radioValues: Radio Values on Parent
  radioChangeHandler: Fucntion that update parent with data
  fontSize: The labels font-size
*/

export default {
  name: "RadioField",
  props: {
    val: Number, // Incoming Field Value
    fieldName: [String, Number], // Incoming Field Name, (Will update this field in parent)
    isRow: Boolean, // True is row False is column
    options: Array, // Radio options
    radioValues: String, // Radio Values on Parent
    radioChangeHandler: Function, // Update parent with data
    fontSize: String, //Our font-size
  },

  data() {
    return {};
  },

  computed: {
    getRadioValue: {
      get() {
        return this.val;
      },
      set(val) {
        this.radioChangeHandler({
          fieldName: this.fieldName,
          selectedRadio: val,
          radioValues: this.radioValues,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
