export default {
  state: {
    availableForList: [
      { id: 0, val: "Πώληση", text: "sale" },
      { id: 1, val: "Ενοικίαση", text: "lease" },
      { id: 2, val: "Αντιπαροχή", text: "consideration" },
    ],
    realEstateCategoriesList: [
      {
        id: 0,
        text: "allCategories",
        subCategoriesList: [
          {
            id: 0,
            text: "apartment",
            val: "Διαμέρισμα",
          },
          {
            id: 1,
            text: "house",
            val: "Μονοκατοικία",
          },
          {
            id: 2,
            text: "maisonette",
            val: "Μεζονέτα",
          },
          {
            id: 3,
            text: "blockOfFlats",
            val: "Κτίριο Πολυκατοικία",
          },
          {
            id: 4,
            text: "villaWithGarden",
            val: "Βίλα με κήπο",
          },
          {
            id: 5,
            text: "office",
            val: "Γραφείο",
          },
          {
            id: 6,
            text: "storageArea",
            val: "Αποθηκευτικός χώρος",
          },
          {
            id: 7,
            text: "industrialSite",
            val: "Βιομηχανικός χώρος",
          },
          {
            id: 8,
            text: "hotel",
            val: "Ξενοδοχείο",
          },
          {
            id: 9,
            text: "craftArea",
            val: "Βιοτεχνικός χώρος",
          },
          {
            id: 10,
            text: "warehouse",
            val: "Αποθήκη",
          },
          {
            id: 11,
            text: "professionalBuilding",
            val: "Επαγγελματικό κτίριο",
          },
          {
            id: 12,
            text: "building",
            val: "Κτίριο",
          },
          {
            id: 13,
            text: "professionalSpace",
            val: "Επαγγελματικός χώρος",
          },
          {
            id: 14,
            text: "room",
            val: "Αίθουσα",
          },
          {
            id: 15,
            text: "site",
            val: "Χώρος",
          },
          {
            id: 16,
            text: "plot",
            val: "Οικόπεδο",
          },
          {
            id: 17,
            text: "parcel",
            val: "Αγροτεμάχιο",
          },
          {
            id: 18,
            text: "field",
            val: "Έκταση",
          },
          {
            id: 19,
            text: "restaurant",
            val: "Εστιατόρια",
          },
          {
            id: 20,
            text: "miniMarket",
            val: "Mini Market",
          },
          {
            id: 21,
            text: "hotelsPension",
            val: "Ξενοδοχεία - Πανσιόν",
          },
          {
            id: 22,
            text: "cafe",
            val: "Καφετέριες - Αναψυκτήρια",
          },
          {
            id: 23,
            text: "tavern",
            val: "Ταβέρνες - Ψητοπωλεία",
          },
          {
            id: 24,
            text: "bakeryPastry",
            val: "Αρτοποιεία - Ζαχαροπλαστεία",
          },
          {
            id: 25,
            text: "ouzeri",
            val: "Μεζεδοπωλεία - Ουζερί",
          },
          {
            id: 26,
            text: "privateSchool",
            val: "Εκπαιδευτήρια",
          },
          {
            id: 27,
            text: "barsPubsDanceHouse",
            val: "Bars - Κέντρα Διασκέδασης",
          },
          {
            id: 28,
            text: "supermarket",
            val: "Καταστήματα Τροφίμων-Ποτών",
          },
          {
            id: 29,
            text: "clothingStore",
            val: "Καταστήματα Ενδυμάτων",
          },
          {
            id: 30,
            text: "serviceCompany",
            val: "Επιχειρήσεις Υπηρεσιών",
          },
          {
            id: 31,
            text: "gyms",
            val: "Γυμναστήρια",
          },
          {
            id: 32,
            text: "drugStores",
            val: "Φαρμακεία - Ειδικά Κέντρα",
          },
          {
            id: 33,
            text: "parking",
            val: "Παρκιν Οχημάτων - Σκαφών",
          },
          {
            id: 34,
            text: "shoeStore",
            val: "Υποδημάτων - Αξεσουάρ",
          },
        ],
      },
      {
        id: 1,
        text: "residence",
        val: "Κατοικία",
        subCategoriesList: [
          {
            id: 0,
            text: "apartment",
            val: "Διαμέρισμα",
          },
          {
            id: 1,
            text: "house",
            val: "Μονοκατοικία",
          },
          {
            id: 2,
            text: "maisonette",
            val: "Μεζονέτα",
          },
          {
            id: 3,
            text: "blockOfFlats",
            val: "Κτίριο Πολυκατοικία",
          },
          {
            id: 4,
            text: "villaWithGarden",
            val: "Βίλα με κήπο",
          },
        ],
      },
      {
        id: 2,
        text: "businessProperty",
        val: "Επαγγελματικά",
        subCategoriesList: [
          {
            id: 0,
            text: "office",
            val: "Γραφείο",
          },
          {
            id: 1,
            text: "store",
            val: "Κατάστημα",
          },
          {
            id: 2,
            text: "storageArea",
            val: "Αποθηκευτικός χώρος",
          },
          {
            id: 3,
            text: "industrialSite",
            val: "Βιομηχανικός χώρος",
          },
          {
            id: 4,
            text: "hotel",
            val: "Ξενοδοχείο",
          },
          {
            id: 5,
            text: "craftArea",
            val: "Βιοτεχνικός χώρος",
          },
          {
            id: 6,
            text: "warehouse",
            val: "Αποθήκη",
          },
          {
            id: 7,
            text: "professionalBuilding",
            val: "Επαγγελματικό κτίριο",
          },
          {
            id: 8,
            text: "building",
            val: "Κτίριο",
          },
          {
            id: 9,
            text: "professionalSpace",
            val: "Επαγγελματικός χώρος",
          },
          {
            id: 10,
            text: "room",
            val: "Αίθουσα",
          },
          {
            id: 11,
            text: "site",
            val: "Χώρος",
          },
        ],
      },
      {
        id: 3,
        text: "land",
        val: "Αξιοποιήσιμη Γη",
        subCategoriesList: [
          {
            id: 0,
            text: "plot",
            val: "Οικόπεδο",
          },
          {
            id: 1,
            text: "parcel",
            val: "Αγροτεμάχιο",
          },
          {
            id: 2,
            text: "field",
            val: "Έκταση",
          },
        ],
      },
      {
        id: 4,
        text: "businesses",
        val: "Επιχειρήσεις",
        subCategoriesList: [
          {
            id: 0,
            text: "restaurant",
            val: "Εστιατόρια",
          },
          {
            id: 1,
            text: "miniMarket",
            val: "Mini Market",
          },
          {
            id: 2,
            text: "hotelsPension",
            val: "Ξενοδοχεία - Πανσιόν",
          },
          {
            id: 3,
            text: "cafe",
            val: "Καφετέριες - Αναψυκτήρια",
          },
          {
            id: 4,
            text: "tavern",
            val: "Ταβέρνες - Ψητοπωλεία",
          },
          {
            id: 5,
            text: "bakeryPastry",
            val: "Αρτοποιεία - Ζαχαροπλαστεία",
          },
          {
            id: 6,
            text: "ouzeri",
            val: "Μεζεδοπωλεία - Ουζερί",
          },
          {
            id: 7,
            text: "privateSchool",
            val: "Εκπαιδευτήρια",
          },
          {
            id: 8,
            text: "barsPubsDanceHouse",
            val: "Bars - Κέντρα Διασκέδασης",
          },
          {
            id: 9,
            text: "supermarket",
            val: "Καταστήματα Τροφίμων-Ποτών",
          },
          {
            id: 10,
            text: "clothingStore",
            val: "Καταστήματα Ενδυμάτων",
          },
          {
            id: 11,
            text: "serviceCompany",
            val: "Επιχειρήσεις Υπηρεσιών",
          },
          {
            id: 12,
            text: "gyms",
            val: "Γυμναστήρια",
          },
          {
            id: 13,
            text: "drugStores",
            val: "Φαρμακεία - Ειδικά Κέντρα",
          },
          {
            id: 14,
            text: "parking",
            val: "Παρκιν Οχημάτων - Σκαφών",
          },
          {
            id: 15,
            text: "shoeStore",
            val: "Υποδημάτων - Αξεσουάρ",
          },
        ],
      },
    ],
    roomList: [
      { id: 0, text: "1", val: 1 },
      { id: 1, text: "2", val: 2 },
      { id: 2, text: "3", val: 3 },
      { id: 3, text: "4", val: 4 },
      { id: 4, text: "5", val: 5 },
      { id: 5, text: "+6", val: 6 },
    ],
    visibleToList: [
      { id: 1, text: "toMeOnly" },
      { id: 2, text: "office" },
      { id: 3, text: "network" },
      { id: 4, text: "public" },
    ],
    yesNoList: [
      { id: 1, text: "yes" },
      { id: 2, text: "no" },
    ],
    buildingStatusList: [
      { id: 0, text: "newlyBuilt", val: "Νεόδμητο" },
      { id: 1, text: "unfinished", val: "Ημιτελές" },
      // { id: 2, text: "preservable", val: "Διατηρητέο" },
      // { id: 3, text: "neoclassical", val: "Νεοκλασικό" },
      { id: 2, text: "renovated", val: "Ανακαινισμένο" },
      { id: 3, text: "needsToBeRenovated", val: "Χρήζει ανακαίνισης" },
      { id: 4, text: "perfectCondition", val: "Άριστη κατάσταση" },
      { id: 5, text: "goodCondition", val: "Καλή κατάσταση" },
      { id: 6, text: "underConstruction", val: "Υπο κατασκευή" },
    ],
    generalFeaturesList: [
      { id: 0, text: "luxurious", val: "luxurious" },
      { id: 1, text: "view", val: "Θέα" },
      { id: 2, text: "pool", val: "Πισίνα" },
      { id: 3, text: "fireplace", val: "Τζάκι" },
      { id: 4, text: "garden", val: "Με κήπο" },
      { id: 5, text: "furnished", val: "Επιπλωμένο" },
      { id: 6, text: "vechParking", val: "Parking" },
    ],
    moreFeaturesList: [
      { id: 1, text: "warehouse", val: "Αποθήκη" },
      { id: 2, text: "attic", val: "Σοφίτα" },
      { id: 3, text: "serviceRoom", val: "Δωμάτιο Υπηρεσίας" },
      { id: 4, text: "privateTerrace", val: "Ιδιόκτητη ταράτσα" },
      { id: 5, text: "playroom", val: "Με playroom" },
    ],
    heatingList: [
      { id: 1, text: "independentHeating", val: "Αυτόνομη θέρμανση" },
      { id: 2, text: "acUnit", val: "Κλιματισμός" },
      { id: 3, text: "solarBoiler", val: "Ηλιακός θερμοσίφωνας" },
      { id: 4, text: "fireplace", val: "Τζάκι" },
      { id: 5, text: "liquidGas", val: "Υγραέριο" },
      { id: 6, text: "heater", val: "Σόμπα" },
      { id: 7, text: "centralHeating", val: "Κεντρική θέρμανση" },
      { id: 8, text: "underfloorHeating", val: "Υποδαπέδια θέρμανση" },
      { id: 9, text: "naturalGas", val: "Φυσικό αέριο" },
      { id: 10, text: "oil", val: "Πετρέλαιο" },
      { id: 11, text: "electricity", val: "Ρεύμα" },
      { id: 12, text: "heatAccumulator", val: "Θερμοσυσσωρευτής" },
    ],
    techFeaturesAndInteriorList: [
      { id: 1, text: "bright", val: "Φωτεινό" },
      { id: 2, text: "painted", val: "Βαμμένο" },
      {
        id: 3,
        text: "electricalEquipment",
        val: "electrical_equipment",
      },
      { id: 4, text: "satelliteAntenna", val: "Δορυφορική κεραία" },
      { id: 5, text: "luxurious", val: "Πολυτελές" },
      { id: 6, text: "windowNets", val: "Σίτες" },
      { id: 7, text: "allowingPets", val: "Κατοικίδια ευπρόσδεκτα" },
      { id: 8, text: "internalStaircase", val: "Εσωτερική Σκάλα" },
      { id: 9, text: "elevator", val: "Ασανσέρ" },
    ],
    safetyList: [
      { id: 1, text: "safetyDoor", val: "Πόρτα ασφαλείας" },
      { id: 2, text: "cameras", val: "Κάμερα Ασφαλείας" },
      { id: 3, text: "safetySystem", val: "Συστήματα ασφαλείας" },
      { id: 4, text: "alarm", val: "Συναγερμός" },
      { id: 5, text: "cctv", val: "Θυροτηλεόραση" },
    ],
    publishedList: [
      { id: 1, text: "spitogatos", val: "SP" },
      { id: 2, text: "xrisiEukairia", val: "XE" },
      // { id: 1, text: "newdealGr" },
      // { id: 3, text: "spiti360Gr" },
      // { id: 5, text: "toBeApproved" },
    ],
    statusCharList: [
      { id: 0, text: "leased", val: "Ενοικιάζεται" },
      { id: 1, text: "free", val: "Ελεύθερο χρήσης" },
      { id: 2, text: "inhabited", val: "" },
    ],
    rateList: [
      { id: 1, text: "1", val: 1 },
      { id: 2, text: "2", val: 2 },
      { id: 3, text: "3", val: 3 },
      { id: 4, text: "4", val: 4 },
      { id: 5, text: "5", val: 5 },
    ],
    zoneList: [
      { id: 1, text: "residentialZone", val: "Οικιστική ζώνη" },
      { id: 2, text: "commericalZone", val: "Εμπορική ζώνη" },
      { id: 3, text: "ruralZone", val: "Αγροτική ζώνη" },
      { id: 4, text: "industrialZone", val: "Βιομηχανική ζώνη" },
      { id: 5, text: "regenerationZone", val: "Ζώνη ανάπλασης" },
      { id: 6, text: "offPlan", val: "Εκτός σχεδίου" },
    ],
    adsWithList: [
      // { id: 1, text: "photos", val: "" },
      // { id: 2, text: "noPhotos", val: "" },
      { id: 0, text: "video", val: "Youtube" },
      { id: 1, text: "photo360", val: "360Photo" },
      { id: 2, text: "manufacturers", val: "fromBuilders" },
      { id: 3, text: "reducedPrice", val: "reducedPrice" },
      { id: 4, text: "banner", val: "Banner" },
      { id: 5, text: "new", val: "New" },
    ],
    outdoorAreasAndPropertyLocationList: [
      { id: 1, text: "vechParking" },
      { id: 2, text: "veranda" },
      { id: 3, text: "awnings" },
      { id: 4, text: "garden" },
      { id: 5, text: "independentEntrance" },
      { id: 6, text: "disabledAccess" },
      { id: 7, text: "pool" },
      { id: 8, text: "bbq" },
    ],
    suitableForList: [
      { id: 1, text: "student" },
      { id: 2, text: "holidayHome" },
      { id: 3, text: "professionalUse" },
      { id: 4, text: "investmentAirbnb" },
    ],
    floorsList: [
      { id: 1, text: "f5basement", val: -5 },
      { id: 2, text: "f4basement", val: -4 },
      { id: 3, text: "f3basement", val: -3 },
      { id: 4, text: "f2basement", val: -2 },
      { id: 5, text: "basement", val: -1 },
      { id: 6, text: "semiBasement", val: -0.5 },
      { id: 7, text: "groundFloor", val: 0 },
      { id: 8, text: "higherGroundFloor", val: 0.3 },
      { id: 9, text: "mezzanineFloor", val: 0.5 },
      { id: 10, text: "f1th", val: 1 },
      { id: 11, text: "f2th", val: 2 },
      { id: 12, text: "f3th", val: 3 },
      { id: 13, text: "f4th", val: 4 },
      { id: 14, text: "f5th", val: 5 },
      { id: 15, text: "f6th", val: 6 },
      { id: 16, text: "f7th", val: 7 },
      { id: 17, text: "f8th", val: 8 },
      { id: 18, text: "f9th", val: 9 },
      { id: 19, text: "f10th", val: 10 },
      { id: 20, text: "f11th", val: 11 },
      { id: 21, text: "f12th", val: 12 },
      { id: 22, text: "f13th", val: 13 },
      { id: 23, text: "f14th", val: 14 },
      { id: 24, text: "f15th", val: 15 },
      { id: 25, text: "f16th", val: 16 },
      { id: 26, text: "f17th", val: 17 },
      { id: 27, text: "f18th", val: 18 },
      { id: 28, text: "f19th", val: 19 },
      { id: 29, text: "f20th", val: 20 },
    ],
    kindList: [
      { id: 1, text: "floorApartment", val: "Οροφοδιαμέρισμα" },
      { id: 2, text: "prefabrication", val: "Προκατασκευή" },
      { id: 3, text: "mobileHome", val: "Λυόμενο" },
      { id: 4, text: "Studio", val: "Studio" },
      { id: 5, text: "inkHouse", val: "Πέτρινο" },
      { id: 6, text: "villa", val: "Βίλα" },
      { id: 7, text: "traditional", val: "Παραδοσιακό" },
      { id: 8, text: "loft", val: "Loft" },
      { id: 1, text: "student", val: "Φοιτητικό" },
      { id: 9, text: "preserved", val: "Διατηρητέο" },
      { id: 10, text: "neoclassical", val: "Νεοκλασικό" },
      { id: 11, text: "bungalow", val: "Bungalow" },
      { id: 12, text: "farm", val: "Φάρμα" },
      { id: 13, text: "residenceComplex", val: "Συγκρότημα Κατοικιών" },
    ],
    availabilityList: [
      { id: 1, text: "leased", val: "Μισθωμένο" },
      { id: 2, text: "free", val: "Ελεύθερο χρήσης" },
      { id: 3, text: "inhabited", val: "Κατοικείται" },
      { id: 4, text: "rent", val: "Ενοικιάζεται" },
    ],
    energyClassList: [
      { id: 1, text: "A+", val: "Α+" },
      { id: 2, text: "A", val: "Α" },
      { id: 3, text: "B+", val: "Β+" },
      { id: 4, text: "B", val: "Β" },
      { id: 5, text: "G", val: "Γ" },
      { id: 6, text: "D", val: "Δ" },
      { id: 7, text: "E", val: "Ε" },
      { id: 8, text: "Z", val: "Ζ" },
      { id: 9, text: "H", val: "Η" },
      {
        id: 10,
        text: "noEnergyPerformance",
        val: "Δεν απαιτείται Πιστοποιητικό Ενεργειακής Απόδοσης για το συγκεκριμένο ακίνητο",
      },
      {
        id: 11,
        text: "issueOfenergy",
        val: "Έκδοση Πιστοποιητικού Ενεργειακής Απόδοσης σε εξέλιξη",
      },
    ],
    investmentList: [
      { id: 1, text: "notRented", val: "Δεν έιναι μισθωμένο" },
      { id: 2, text: "longTermRent", val: "Μακροχρόνια μίσθωση" },
      { id: 3, text: "shortTermRent", val: "Βραχυχρόνια μίσθωση" },
    ],

    selectBoxesList: [
      { id: 1, text: "doubleGlazingBars", val: "Διπλά Tζάμια" },
      { id: 2, text: "woodenFrames", val: "Ξύλινα κουφώματα" },
      { id: 3, text: "syntheticFrames", val: "Συνθετικά κουφώματα" },
      { id: 4, text: "electricFrames", val: "Ηλεκτρικά κουφώματα" },
      { id: 5, text: "aluminiumFrames", val: "Κουφώματα αλουμινίου" },
      { id: 6, text: "slidingRolls", val: "Συρόμενα Ρολά" },
      { id: 7, text: "screens", val: "Σίτες" },
      // { id: 8, text: "sliding"), val: "Συρόμενα" },
      // { id: 9, text: "rolls"), val: "Ρολά" },
    ],
    floorList: [
      { id: 1, text: "marble", val: "Μάρμαρο" },
      { id: 2, text: "wood", val: "Ξύλο" },
      { id: 3, text: "stone", val: "Πέτρα" },
      { id: 4, text: "tile", val: "Πλακάκι" },
      { id: 5, text: "cement", val: "Τσιμέντο" },
      { id: 6, text: "mosaic", val: "Μωσαϊκό" },
      { id: 7, text: "marbleWood", val: "Μάρμαρο - Ξύλο" },
      { id: 8, text: "marbleTile", val: "Μάρμαρο-Πλακάκι" },
      { id: 9, text: "stoneWood", val: "Πέτρα-Ξύλο" },
      { id: 10, text: "stonemarble", val: "Πέτρα - Μάρμαρο" },
      { id: 11, text: "tileWood", val: "Πλακάκι-Ξύλο" },
      { id: 12, text: "mosaicWood", val: "Μωσαϊκό-Ξύλο" },
      { id: 13, text: "industrialFlooring", val: "Βιομηχανικό δάπεδο" },
      { id: 14, text: "laminate", val: "Laminate" },
    ],
    chooseAViewList: [
      { id: 1, text: "city", val: "Πόλη" },
      { id: 2, text: "mountain", val: "Βουνό" },
      { id: 3, text: "acropoliMonuments", val: "Μνημεία" },
      { id: 4, text: "lake", val: "Λίμνη" },
      { id: 5, text: "sea", val: "Θάλασσα" },
      { id: 6, text: "river", val: "Ποτάμι" },
      { id: 7, text: "park", val: "Πάρκο" },
    ],
    selectPositionList: [
      { id: 1, text: "corner", val: "Γωνιακό" },
      { id: 2, text: "interior", val: "Εσωτερικό" },
      { id: 3, text: "facade", val: "Προσόψεως" },
      { id: 4, text: "penthouse", val: "Ρετιρέ" },
      { id: 5, text: "through", val: "Διαμπερές" },
      { id: 6, text: "onMainStreet", val: "Σε κεντρικό δρόμο" },
      { id: 7, text: "onPedestrianStreet", val: "Σε πεζόδρομο" },
    ],
    orientationList: [
      { id: 1, text: "eastern" },
      { id: 2, text: "eastWest" },
      { id: 3, text: "easternMeridian" },
      { id: 4, text: "north" },
      { id: 5, text: "northEast" },
      { id: 6, text: "northWest" },
      { id: 7, text: "west" },
      { id: 8, text: "westMeridian" },
      { id: 9, text: "meridian" },
      { id: 10, text: "south" },
      { id: 11, text: "southEast" },
      { id: 12, text: "southWest" },
    ],
    typeOfParkingList: [
      { id: 1, text: "closed" },
      { id: 2, text: "basement" },
      { id: 3, text: "pilot" },
      { id: 4, text: "open" },
    ],
    typeOfMandateList: [
      { id: 1, text: "simple" },
      { id: 2, text: "newDealExclusive" },
      { id: 3, text: "marketResearch" },
      { id: 4, text: "record" },
      { id: 5, text: "hiddenAdminOnly" },
    ],
    statusList: [
      { id: 1, text: "active", val: "Ενεργό" },
      { id: 2, text: "inactive", val: "Μη ενεργό" },
      { id: 3, text: "rented", val: "Ενοικιάστηκε" },
      { id: 4, text: "sold", val: "Πουλήθηκε" },
      { id: 5, text: "underNegotiation", val: "Υπο διαπραγμάτευση" },
      { id: 6, text: "receivedAdvancePayment", val: "Προκαταβολή" },
      { id: 7, text: "draft", val: "Πρόχειρο" },
      // { id: 8, text: "setAsVipAdminOnly", val: "vip" },
      { id: 9, text: "notPublished", val: "Μη δημοσιευμένο" },
      { id: 10, text: "toBeApproved", val: "IsApprovedFalse" },
    ],
    companyList: [
      { id: 1, text: "newDeal", val: "newdeal" },
      { id: 2, text: "newDealKolonaki", val: "newdeal Κολωνάκι" },
      { id: 3, text: "newDealGlyfada", val: "newdeal Γλυφάδα" },
      { id: 4, text: "newDealPiraeus", val: "newdeal Πειραιάς" },
      {
        id: 5,
        text: "newDealThessaloniki",
        val: "newdeal Θεσσαλονίκη",
      },
    ],
    technicalFeaturesInteriorList: [
      { id: 1, text: "furnished" },
      { id: 2, text: "bright" },
      { id: 3, text: "painted" },
      { id: 4, text: "electricalEquipment" },
      { id: 5, text: "satelliteAntenna" },
      { id: 6, text: "luxury" },
      { id: 7, text: "screens" },
      { id: 8, text: "petsWelcome" },
      { id: 9, text: "internalStaircase" },
      { id: 10, text: "elevator" },
    ],
    securityList: [
      { id: 1, text: "securityDoor" },
      { id: 2, text: "securityCamera" },
      { id: 3, text: "securitySystem" },
      { id: 4, text: "alarmSystem" },
      { id: 5, text: "CCTV" },
    ],
    outdoorSpacesList2: [
      { id: 1, text: "awnings" },
      { id: 3, text: "independentEntrance" },
      { id: 4, text: "disabledAccess" },
      { id: 5, text: "BBQ" },
    ],
    suitableForList2: [
      { id: 1, text: "student" },
      { id: 2, text: "professionalUse" },
      { id: 3, text: "airbnb" },
    ],
    distancesList: [
      { id: 1, text: "sea" },
      { id: 2, text: "acropoliMonuments" },
      { id: 3, text: "school" },
      { id: 4, text: "metro" },
      { id: 5, text: "ΗΣΑΠ" },
      { id: 6, text: "park" },
      { id: 7, text: "suburban" },
      { id: 8, text: "internationalSchools" },
      { id: 9, text: "airport" },
      { id: 10, text: "bank" },
      { id: 11, text: "supermarket" },
      { id: 12, text: "urbanTransport" },
      { id: 13, text: "square" },
      { id: 14, text: "hospital" },
      { id: 15, text: "tram" },
    ],
    assignationsList: [
      { id: 1, text: "basic", val: "Απλή" },
      { id: 2, text: "exclusive", val: "Αποκλειστική" },
      // { id: 3, text: "martketResearch", val: "Έρευνα αγοράς" },
      // { id: 4, text: "record", val: "Καταγραφή" },
    ],
    roleList: [
      { id: 0, text: "Member", val: "Member" },
      { id: 1, text: "Office Admin", val: "OfficeAdmin" },
      { id: 2, text: "Leader", val: "Leader" },
      { id: 3, text: "HR", val: "HR" },
      { id: 4, text: "Host", val: "Host" },
      { id: 5, text: "Admin", val: "Admin" },
      { id: 6, text: "Associate", val: "Associate" },
      { id: 7, text: "Secretary", val: "Secretary" },
    ],
    refferalList: [
      { id: 0, text: "recommendation", val: "Σύσταση" },
      { id: 1, text: "adInternet", val: "Αγγελία / Internet" },
      { id: 2, text: "poster", val: "Αφίσα" },
      { id: 3, text: "newsletter", val: "Newsletter" },
      { id: 4, text: "brand", val: "Brand" },
      { id: 5, text: "banner", val: "Πανό" },
    ],
    // fileCategories: [
    //     {
    //         name: "Όλα τα αρχεία",
    //         subCats: []
    //     },
    //     {
    //         name: "Εντολές",
    //         subCats: [
    //             {
    //                 name: "Εντολές",
    //                 id: "legal_tasks",
    //                 fileCategory: "Εντολές",
    //             },
    //             {
    //                 name: "Υποδείξεις",
    //                 id: "showcase_task_documents",
    //                 fileCategory: "Εντολές",
    //             }]
    //     },
    //     {
    //         name: "Έγγραφα ακινήτου",
    //         subCats: [
    //             {
    //                 name: "Κάτοψη",
    //                 id: "layout",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Τίτλοι",
    //                 id: "titles",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Ιδιωτικό Συμφωνητικό",
    //                 id: "private_agreement",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Φορολογική ενημερότητα του πωλητή",
    //                 id: "seller_tax_awareness_document",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Ασφαλιστική ενημερότητα ΕΦΚΑ",
    //                 id: "efka_seller_insurance_awareness",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Πιστοποιητικά ΕΝΦΙΑ από τον πωλητή",
    //                 id: "enfia_certificates",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Βεβαίωση Μηχανικού",
    //                 id: "engineers_certificate",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Πιστοποιητικό Ενεργειακής Απόδοσης",
    //                 id: "energy_class_certificate",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "ΤΑΠ",
    //                 id: "property_fee",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Τοπογραφικό",
    //                 id: "topographic_document",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //             {
    //                 name: "Έγγραφα προσωπικών στοιχείων",
    //                 id: "personal_documents",
    //                 fileCategory: "Έγγραφα ακινήτου",
    //             },
    //         ]
    //     },
    //     {
    //         name: "Διαφημιστικό υλικό",
    //         subCats: [
    //             {
    //                 name: "Διαφημιστικά Φυλλάδια",
    //                 id: "marketing",
    //                 fileCategory: "Διαφημιστικό υλικό",
    //             },
    //             {
    //                 name: "Παρουσιάσεις",
    //                 id: "presentations",
    //                 fileCategory: "Διαφημιστικό υλικό",
    //             },]
    //     },
    // ],
    // fileSubCategories: [
    // ],
    realEstateRules: [
      {
        category: ["Γη", "Αξιοποιήσιμη Γη", "ΓΗ"],
        rules: [
          "Οικονομικά ακινήτου",
          "Αποστάσεις",
          "Περιγραφή",
          "Διαχείριση ακινήτου",
          "Σημειώσεις",
          "Επαγγελματκή χρήση",
          "Εκτός σχεδίου",
          "Περισσότερα στοιχεία οικοπέδου",
        ],
      },
      {
        category: ["Κατοικία"],
        rules: [
          "Επιπλέον Χώροι",
          "Οικονομικά ακινήτου",
          "Θέρμανση & κατανάλωση",
          "Κατασκευή",
          "Τεχνικά γνωρίσματα & εσωτερικό",
          "Ασφάλεια",
          "Εξωτερ. χώροι & θέση ακινήτου",
          "Κατάλληλο για",
          "Αποστάσεις",
          "Περιγραφή",
          "Διαχείριση ακινήτου",
          "Σημειώσεις",
          "Είδος",
          "Όροφος",
          "Εμβαδόν οικοπέδου",
          "Διαθεσιμότητα",
          "Επίπεδα",
          "Υπνοδωματια",
          "Κουζίνες",
          "Μπάνια",
          "Μπαλκόνια",
          "Εμβαδόν Μπαλκονιών",
          "Σαλόνια",
          "wc",
        ],
      },
      {
        category: ["Επαγγελματική στέγη προς πώληση", "Επαγγελματικά"],
        rules: [
          "Επιπλέον Χώροι",
          "Οικονομικά ακινήτου",
          "Θέρμανση & κατανάλωση",
          "Κατασκευή",
          "Τεχνικά γνωρίσματα & εσωτερικό",
          "Ασφάλεια",
          "Εξωτερ. χώροι & θέση ακινήτου",
          "Κατάλληλο για",
          "Αποστάσεις",
          "Περιγραφή",
          "Διαχείριση ακινήτου",
          "Σημειώσεις",
          "Κενό",
          "Όροφος",
          "Εμβαδόν οικοπέδου",
          "Διαθεσιμότητα",
          "Μπαλκόνια",
          "wc",
          "Αριθμός Χώρων",
          "Από κατασκευαστές",
          "Αποθήκη",
        ],
      },
      {
        category: ["Επιχείρηση προς πώληση", "Επιχείρηση", "Επιχειρήσεις"],
        rules: [
          "Επιπλέον Χώροι",
          "Οικονομικά ακινήτου",
          "Θέρμανση & κατανάλωση",
          "Κατασκευή",
          "Τεχνικά γνωρίσματα & εσωτερικό",
          "Ασφάλεια",
          "Εξωτερ. χώροι & θέση ακινήτου",
          "Κατάλληλο για",
          "Αποστάσεις",
          "Περιγραφή",
          "Διαχείριση ακινήτου",
          "Σημειώσεις",
          "Αέρας",
          "wc",
          "Από κατασκευαστές",
          "Αριθμός Χώρων",
          "Όροφος",
        ],
      },
    ],
  },
  getters: {
    availableForList(state) {
      return state.availableForList;
    },
    realEstateCategoriesList(state) {
      return state.realEstateCategoriesList;
    },
    visibleToList(state) {
      return state.visibleToList;
    },
    yesNoList(state) {
      return state.yesNoList;
    },
    buildingStatusList(state) {
      return state.buildingStatusList;
    },
    generalFeaturesList(state) {
      return state.generalFeaturesList;
    },
    moreFeaturesList(state) {
      return state.moreFeaturesList;
    },
    heatingList(state) {
      return state.heatingList;
    },
    techFeaturesAndInteriorList(state) {
      return state.techFeaturesAndInteriorList;
    },
    safetyList(state) {
      return state.safetyList;
    },
    publishedList(state) {
      return state.publishedList;
    },
    statusCharList(state) {
      return state.statusCharList;
    },
    adsWithList(state) {
      return state.adsWithList;
    },
    outdoorAreasAndPropertyLocationList(state) {
      return state.outdoorAreasAndPropertyLocationList;
    },
    suitableForList(state) {
      return state.suitableForList;
    },
    getFloorDescriptionByVal: (state) => (val) => {
      let floorDescr = "";
      for (let i = 0; i < state.floorsList.length; i++) {
        if (state.floorsList[i].val == val) {
          floorDescr = state.floorsList[i].text;
        }
      }
      return floorDescr;
    },
    floorsList(state) {
      return state.floorsList;
    },
    kindList(state) {
      return state.kindList;
    },
    availabilityList(state) {
      return state.availabilityList;
    },
    energyClassList(state) {
      return state.energyClassList;
    },

    investmentList(state) {
      return state.investmentList;
    },

    selectBoxesList(state) {
      return state.selectBoxesList;
    },
    floorList(state) {
      return state.floorList;
    },
    chooseAViewList(state) {
      return state.chooseAViewList;
    },
    selectPositionList(state) {
      return state.selectPositionList;
    },
    orientationList(state) {
      return state.orientationList;
    },
    typeOfParkingList(state) {
      return state.typeOfParkingList;
    },
    typeOfMandateList(state) {
      return state.typeOfMandateList;
    },
    statusList(state) {
      return state.statusList;
    },
    companyList(state) {
      return state.companyList;
    },
    zoneList(state) {
      return state.zoneList;
    },
    technicalFeaturesInteriorList(state) {
      return state.technicalFeaturesInteriorList;
    },
    securityList(state) {
      return state.securityList;
    },
    outdoorSpacesList2(state) {
      return state.outdoorSpacesList2;
    },
    suitableForList2(state) {
      return state.suitableForList2;
    },
    distancesList(state) {
      return state.distancesList;
    },
    getRoomList(state) {
      return state.roomList;
    },
    getAssignationsList(state) {
      return state.assignationsList;
    },
    getRateList(state) {
      return state.rateList;
    },
    getRoleList(state) {
      return state.roleList;
    },
    getRefferalList(state) {
      return state.refferalList;
    },
  },
  mutations: {},
  actions: {},
};
