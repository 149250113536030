<template>
  <v-tooltip top :open-on-hover="hoverText != undefined" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.543"
        height="22.543"
        viewBox="0 0 22.543 22.543"
        v-bind="attrs"
        v-on="on"
        class="ma-1"
      >
        <g
          id="Icon_feather-info"
          data-name="Icon feather-info"
          transform="translate(1.5 1.5)"
        >
          <g id="Component_96_1" data-name="Component 96 – 1">
            <path
              id="Path_4197"
              data-name="Path 4197"
              d="M22.543,12.771A9.771,9.771,0,1,1,12.771,3,9.771,9.771,0,0,1,22.543,12.771Z"
              transform="translate(-3 -3)"
              fill="none"
              :stroke="color"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              id="Path_4198"
              data-name="Path 4198"
              d="M18,21.909V18"
              transform="translate(-8.229 -8.229)"
              fill="none"
              :stroke="color"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              id="Path_4199"
              data-name="Path 4199"
              d="M18,12h0"
              transform="translate(-8.229 -6.137)"
              fill="none"
              :stroke="color"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
          </g>
        </g>
      </svg>
    </template>
    <span v-html="$t(hoverText)"></span>
  </v-tooltip>
</template>

<script>

/*
 Name:InfoField
 Description: Field with hover text

 API Property:
  color: String for the color
  hoverText: String for the hover text
*/
export default {
  name: "InfoField",
  props: { color: { type: String, default: "#e52628" }, hoverText: String },
};
</script>
