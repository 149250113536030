<template>
  <v-col class="pl-0 pr-0">
    <v-row justify="start">
      <v-col :class="titleClass" class="hTitle pt-0 pb-0">
        <span> {{ title }} </span>
      </v-col>
    </v-row>
    <v-row justify="start" class="flex-nowrap" :align="showIcon && iconObj && iconObj.icon ? 'center' : ''">
      <v-col class="flex-grow-0 pr-0" v-if="showIcon && iconObj && iconObj.icon">
        <Icon :icon="iconObj.icon" :height="20.5" :color="iconObj.color" />
      </v-col>
      <v-col :class="(showIcon && iconObj && iconObj.icon ? 'pl-1 pt-1' : 'pt-0 pl-5') + ' ' + subTitleClass"
        align="left" class="font15">
        <span class="subtitle"
          :style="'font-size:' + fontSizeSub + '!important' + ';font-family:' + fontFamilySub + '!important;'">
          {{ subTitle }}
        </span>
      </v-col>
      <v-col v-if="extraContent" :class="extraContentClass">
        <span> {{ extraContent }} </span>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:HeaderTitle
 Description: Component to render Headers title with subTitle

 API Property:
 title:Title to render on dom
 titleClass: String containing classes for title
 subTitle:Subtitle to render on dom
 subTitleClass: String containing classes for subTitle
 extraContent: Extra content to render on dom
 extraContentClass: Extra content classes
 showIcon: show/hide icon area
 iconObj: object of icon
            iconObj: {
                icon: "ant-design:up-outlined",
                color: "white"
            }
 fontSizeTitle:Size of letters on title
 sontSizeSub: Size of letters on subtitle
 fontFamilyTitle: Font family on title
 fontFamilySub: Font family on subtitle
 
  Components Used:
    Icon
*/
import { Icon } from "@iconify/vue2";
export default {
  name: "HeaderTitle",
  props: {
    title: String,
    titleClass: String,
    subTitle: String,
    subTitleClass: String,
    extraContent: String,
    extraContentClass: String,
    showIcon: { type: Boolean, default: false },
    iconObj: Object,
    fontSizeTitle: { type: String, default: "24px" },
    fontSizeSub: { type: String, default: "16px" },
    fontFamilyTitle: { type: String, default: "CenturyGothicBold" },
    fontFamilySub: { type: String, default: "CenturyGothicBold" },
  },
  components: { Icon },
};
</script>

<style scoped>
.headerTitle {
  text-align: initial;
}

.hTitle {
  color: #263573;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  text-align: start;
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

.subtitle {
  font-size: 15px;
  color: #3a3a40 !important;
  word-break: break-word !important;
}
</style>