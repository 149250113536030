<template>
  <v-col>
    <v-row class="listHeader">
      <v-col align="left" class="pa-0"
        :style="'font-size:' + fontSize + ';font-family:' + fontFamilyLabel + '!important'">
        <span>{{ $t(this.label) }}</span>
      </v-col>
    </v-row>
    <v-row v-if="showHtml != true">
      <v-col v-if="showIcon == true" class="pa-0 flex-grow-0" align="left">
        <Icon :icon="iconObj.icon" :height="iconObj.height" :color="iconObj.color" />
      </v-col>
      <v-col align="left" class="pa-0" :class="dataClass"
        :style="'font-size:' + (fontSizeValue ? fontSizeValue : fontSize) + ';font-family:' + fontFamilyValue + '!important'">
        <span>
          {{ data ? data : '' }}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="showHtml == true">
      <v-col align="left" class="pa-0" :class="dataClass" v-html="htmlVal"> </v-col>
      <!-- <v-col align="left" class="pa-0" :class="dataClass" v-for="item in data.split(',')"
        :style="'font-size:' + (fontSizeValue ? fontSizeValue : fontSize) + ';font-family:' + fontFamilyValue + '!important'">
        <span>
          {{ data ? data : '' }}
        </span>
      </v-col> -->
    </v-row>
  </v-col>
</template>

<script>
/*

   showIcon: show/hide icon area
   showHtml: show/hide with html content
   iconObj: object of icon
              iconObj: {
                  icon: "ant-design:up-outlined",
                  height: 15,
                  attr: outline,
                  color: "white"
              }
*/


/*
  Name:ReadField
  Description: Field for display a label with title and value

  API Property:
    label: Strin for label
    data: String, Number for data
    dataClass: String for data class
    showHtml: Boolean to show html tag
    htmlVal: String for html tag value
    showIcon: Boolean to show icon
    iconObj: Object for the icon display
    fontSize: Size of the font-size
    fontSizeValue: String of the font value
    fontFamilyLabel: String for font-family we use on label
    fontFamilyValue: String for font-family for value under label

    Components Used:
      Icon
*/
import { Icon } from "@iconify/vue2";
export default {
  name: "ReadField",
  components: {
    Icon,
  },
  data() {
    return {
    htmlSpan: "<span> {{ data ? data : '' }} </span> <br/>"
    };
  },
  props: {
    label: String,
    data: [String, Number],
    dataClass: String,
    showHtml: { type: Boolean, default: false },
    htmlVal: String,
    showIcon: { type: Boolean, default: false },
    iconObj: Object,
    isUnderline: { type: Boolean, default: false },
    fontSize: String, //Size of the font-size
    fontSizeValue: String,
    fontFamilyLabel: String, //font-family we use on label
    fontFamilyValue: String, //font-family for value under label
  },
  computed: {
    // getClass() {
    //   if (this.isUnderline) {
    //     return "fontUnderline successColor";
    //   } else {
    //     return "";
    //   }
    // },
    // value() {
    //   if (this.data != undefined) {
    //     return this.data;
    //   } else return "";
    // },
  },
};
</script>

<style scoped>
.listHeader {
  color: #263573;
}

.fontUnderline {
  text-decoration: underline;
}
</style>
