<template>
  <v-col class="pa-0" align="center">
    <v-card
      :min-width="width"
      :min-height="height"
      :width="width"
      :height="height"
      :elevation="0"
      color="transparent"
    >
      <v-row class="mt-n6 mr-0" v-if="characteristicsArray">
      <v-col cols="4" v-for="item in characteristicsArray" :key="item.id" class="align-self-center pr-0 flex-grow-0">
        <v-row class="flex-nowrap" v-if="rightSelect">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/' + item.image)">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            <span> {{ item.value }} </span>
          </v-col>
        </v-row>
         <v-row class="flex-nowrap" v-if="!rightSelect">
           <v-col class="align-self-center font14 text-no-wrap interSemiBlack pr-0">
            <span> {{ item.value }} </span>
          </v-col>
          <v-col class="align-self-center mr-2">
            <v-img :src="require('@/assets/' + item.image)">
            </v-img>
          </v-col>
        </v-row>
      </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>
<script>
/*
 Name:CharacteristicsInfo
 Description: Component to render card with propertys and a value for every property

 API Property:
 width: width component
 height: height component

 Array with image path and value for every property
  characteristicsArray: [
        {
          image: "realEstateSilder/bedroom.png",
          value: 10,
        },
        {
          image: "realEstateSilder/bathroom.png",
          value: 20,
        },
        {
          image: "realEstateSilder/sqft.png",
          value: 30,
        },
      ],
*/

export default {
  name: "CharacteristicsInfo",

  props: {
    width: { type: Number, default: 200 }, // width compenent
    height: { type: Number, default: 18 }, // height compenent
    characteristicsArray: Array, //array with image path and value for every property
    rightSelect: { type: Boolean, default: true }, // Boolean to select the numbers at the right of the icon 
  },
};
</script>

