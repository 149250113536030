import AxiosClientToken from "../Client/AxiosClientToken";
const resourceCud = "/general";

export default {
  get(body) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/${resourceCud}/NewdealCompanies`,body
    );
  },
};
