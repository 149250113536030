<template>
  <v-row>
    <v-col align="center" class="listContent">
      <v-row v-if="!hasVip">
        <v-col>
          <v-btn width="100%" max-width="388" min-width="260" height="40" class="btn textDecorationBold" elevation="1"
            outlined @click="vipInterest" :loading="loadingVip">
            <span>{{ $t("intrestVip1") }} &nbsp;</span>
            <span class="fontOrange textDecorationBold">
              {{ $t("intrestVip2") }}&nbsp;
            </span>
            <span>{{ $t("intrestVip3") }} </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0" v-else>
        <v-col class="pa-0">
          <v-row v-if="vipAction == 1">
            <v-col>
              <span class="fontOrange">
                {{ vipMessage }}
              </span>
            </v-col>
          </v-row>
          <v-row v-else-if="vipAction == 2" style="background-color: #e8e8ea; border-radius: 4px">
            <v-col cols="12" align="center">
              <Icon icon="mdi:alert-circle" width="16" height="16" class="mr-1" />

              <span class="textDecorationBold" style="color: #263573">
                {{ $t("intrestVip4") }}
              </span>
              <span class="fontOrange textDecorationBold">
                {{ $t("intrestVip2") }}
              </span>
              <span class="textDecorationBold" style="color: #263573">{{ $t("intrestVip6") }}...
              </span>
            </v-col>
          </v-row>
          <v-row v-else-if="
            this.data &&
            !this.data[this.data.length - 1].active &&
            !this.data[this.data.length - 1].approved
          " class="ma-0" style="
              background-color: #e8e8ea;
              border-radius: 4px;
              max-width: 388px;
            ">
            <v-col>
              <v-row>
                <v-col align="center">
                  <Icon icon="mdi:alert-circle" width="16" height="16" class="mr-1" />
                  <span class="textDecorationBold" style="color: #263573">
                    {{ $t("intrestVip4") }}
                  </span>
                  <span class="fontOrange textDecorationBold">
                    {{ $t("intrestVip2") }}
                  </span>
                  <span class="textDecorationBold" style="color: #263573">
                    {{ $t("intrestVip5") }}...
                  </span>
                </v-col>
              </v-row>
              <v-row class="pl-2 pr-2 mt-0">
                <v-col class="pl-0 pr-0">
                  <v-btn elevation="1" align="center" class="mr-2 btn textDecorationBold" color="primary" width="160"
                    :loading="loadingReminder" @click="sendReminder">
                    {{ $t("resend") }}
                  </v-btn>
                </v-col>
                <v-col class="pl-0 pr-0">
                  <v-btn elevation="1" align="center" class="btn textDecorationBold" color="primary"
                    @click="cancelRequest" outlined style="background-color: white" width="159">
                    {{ $t("cancel") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
/*
 Name: RealEstateSlider
 Description: Component for the vip info at the real estate slider
 API Property: 
    data: Object for the data
    estateId: Number for the real estate id

    Components Used:
      Icon
    
    Endpoints Functions Called:
    -RealEstateRepo
      vipInterest
      reminderVip
      cancelVip
*/
import Repository from "@/services/Repository";
import { Icon } from "@iconify/vue2";
import i18n from "@/i18n/i18n";
const RealEstateRepo = Repository.get("realEstate");

export default {
  name: "VipInfo",
  props: { estateId: Number, data: Array },
  components: {
    Icon,
  },
  created() {
    if (this.data && this.data.length > 0) {
      this.hasVip = true;
    }
  },
  data() {
    return {
      vipMessage: null,
      vipAction: null, // 1:Insert . 2:Cancel
      hasVip: false,
      loadingVip: false,
      loadingReminder: false,
      loadingCancel: false,
    };
  },
  methods: {
    async vipInterest() {
      try {
        this.loadingVip = true;
        let resp = await RealEstateRepo.vipInterest(this.estateId);
        this.loadingVip = false;
        this.vipAction = 1;
        this.hasVip = true;
        if (resp.data["_id"] != undefined)
          this.vipMessage = i18n.t("contactSoon") + "!";
        else this.vipMessage = i18n.t("smthWrong") + "!";
      } catch (e) {
        this.vipMessage = i18n.t("smthWrong") + "!";
        this.loadingVip = false;
        throw e;
      }
    },
    async sendReminder() {
      try {
        this.loadingReminder = true;
        let resp = await RealEstateRepo.reminderVip(this.estateId);
        this.loadingReminder = false;
        this.vipAction = 1;
        if (resp.data["_id"] != undefined)
          this.vipMessage = i18n.t("contactSoon") + "!";
        else this.vipMessage = i18n.t("smthWrong") + "!";
      } catch (e) {
        this.loadingReminder = false;
        throw e;
      }
    },
    async cancelRequest() {
      try {
        this.loadingCancel = true;
        await RealEstateRepo.cancelVip(this.estateId);
        this.loadingCancel = false;
        this.vipAction = 2;
      } catch (e) {
        this.vipMessage = i18n.t("smthWrong") + "!";
        this.loadingCancel = false;

        throw e;
      }
    },
  },
};
</script>

<style scoped>
.btn {
  text-transform: unset !important;
  color: #263573;
}

.listContent {
  font-size: 14px;
}

.fontOrange {
  color: #e89008;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
</style>