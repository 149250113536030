import AxiosClientToken from "../Client/AxiosClientToken";

const resourceCud = "/list";

export default {
    get(filters, params) {
        return AxiosClientToken.post(
            `${process.env.VUE_APP_BASE_URL}${resourceCud}/CooperationRequestsList`,
            filters,
            { params: params }
        );
    },
    update(id, payload) {
        return AxiosClientToken.post(
            `${process.env.VUE_APP_BASE_URL}/general/UpdateCooperationRequest/${id}`,
            payload
        );
    },
};
