 <template>
  <v-col class="pl-0 pr-0">
    <v-row class="align-center justify-space-between">
      <v-col class="titleClass">
        <span class="headerTitleContent">
          {{ title }}
        </span>
      </v-col>
      <v-col v-if="isVip" class="flex-grow-0">
        <span class="vipClass textDecorationBold"> VIP </span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pr-0 headerSubTitle titleClass pt-0">
        <span class="headerSubTitleContent">{{ subtitle }}</span>
      </v-col>
      <v-col class="align-self-center flex-grow-0 pt-0" align="left">
        <v-row class="align-center flex-nowrap">
          <v-col class="flex-grow-0 pr-0 pl-0">
            <v-img style="width: 18px !important" :src="require('@/assets/realEstateCard/star.svg')">
            </v-img>
          </v-col>
          <v-col style="color: #e89008" class="pl-1 pr-1 flex-grow-0 textDecorationBold font14">
            {{ rating }}
          </v-col>
          <v-col align="left" class="pl-0 pr-0 font-italic font14" style="font-family: Inter">
            ({{ ratingCount }})
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col align="left">
        <span class="priceClass font24"> {{ price }} </span>
      </v-col>
      <v-col class="align-self-center pr-0" align="right">
        <span class="interSemiBlack font14"> {{ code }} </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0 pt-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col v-if="![undefined, null, ''].includes(bedrooms)" class="flex-grow-0">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/bedroom.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ bedrooms }}
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="![undefined, null, ''].includes(bathrooms)" class="flex-grow-0">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/bathroom.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ bathrooms }}
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="![undefined, null, ''].includes(floor)" class="flex-grow-0">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/floor.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ floor }}
            <!-- <span style="text-transform: lowercase"> {{ $t("floor") }} </span> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="![undefined, null, ''].includes(constructionYear)" class="flex-grow-0">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/calendar.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ $t("yearOfConstruction") + ": " + constructionYear }}
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="![undefined, null, ''].includes(size)" class="flex-grow-0">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/sqft.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ size + " " + $t("sqftTable") }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template> 

 <script>
  /*
Name:RealEstateHeaderMobile
Description: Component to render the real estate headers mobile
API Property:
    title: String for the header title
    code: String for the code
    price: String, Number for the price
    isVip: Boolean if is vip
    subtitle: String for the header subtitle
    rating: Number, String for the property rating
    ratingCount: Number, String for the property rating Count
    bedrooms: Number, String for the property bedrooms
    bathrooms: Number, String for the property bathrooms
    size: String, Number for the property sqft size
    constructionYear: Number, String for the property construction year
    floor: Number, String for the property floor
*/
export default {
  name: "RealEstateHeaderMobile",
  props: {
    title: String, // Header title
    code: String,
    price: [String, Number],
    isVip: Boolean, // isVip
    subtitle: String, // Header subtitle
    rating: [Number, String], // Property Rating
    ratingCount: [Number, String], // Property Rating Count
    bedrooms: [Number, String], //Property bedrooms
    bathrooms: [Number, String], //Property Bathrooms
    size: [String, Number], // Property Sqft size
    constructionYear: [Number, String], //Property Construction year
    floor: [Number, String], //Property floor
  },
};
</script> 

 <style lang="scss" scoped>
 .subtitle {
   font-size: 15px !important;
   color: #3a3a40 !important;
   font-family: CenturyGothicBold;
   font-weight: 500;
 }
 
 .vipClass {
   background: rgba(232, 144, 8, 0.75);
   color: #ffffff;
   width: 40px;
   height: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .titleClass {
   flex-grow: 0;
   text-align: left;
   white-space: nowrap;
 }
 
 .headerTitleContent {
   font-size: 19px;
   font-family: InterSemiBold;
 }
 
 .headerSubTitleContent {
   font-family: InterMedium;
   font-size: 14px;
 }
 
 .priceClass {
   font-family: InterSemiBold;
   color: #263573;
 }
 
 .interSemiBlack {
   font-family: InterSemiBold;
   color: #3a3a40;
 }
 </style> 
