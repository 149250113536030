<template>
  <v-row class="checkboxRow">
    <v-col v-if="label" class="compClass" :style="'font-size:' + fontSize + ';'">
      {{ label }}
    </v-col>
    <v-col class="plusMinCol">
      <v-row class="justify-start flex-nowrap">
        <v-col class="compClass flex-grow-0 pt-0 pr-0 pb-0">
          <v-icon
            size="30"
            @click="decreaseCheckbox()"
            class="fa-thin minusPlus"
            >mdi-minus-box-outline</v-icon
          >
        </v-col>
        <v-col class="compClass pl-0 pr-0" align="center">
          <v-text-field
            v-model="getTextFieldValue"
            class="textDecorationBold centered-input"
            :class="valClass"
            :readonly="disableInput"
            type="number"
            hide-spin-buttons
            hide-details
            flat
            solo
            dense
          />
        </v-col>
        <v-col class="compClass flex-grow-0 pa-0">
          <v-icon
            size="30px"
            font-weight="light"
            @click="increaseCheckbox()"
            class="fa-thin minusPlus"
            >mdi-plus-box-outline</v-icon
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
/*
 Name:MinusPlusField
 Description: Component with a value and two boxes for increase or decrease this value.

 API Property:
    label: String for incoming label of the field
    fieldName: String for incoming Field Name, (Will update this field in parent)
    val: Number for incoming Field Value
    minVal: Number for min value that the user can decrease the field
    maxVal: Number for max value that the user can increase the field
    incrementBy: Number amount for increase or decrease the main value
    valClass: String for class
    disableInput: Boolean for disable input
    plusMinusChangeHandler: Function for update parent with data
    fontSize: String for fontsize of lab
*/
export default {
  props: {
    label: String, // Incoming label of the field
    fieldName: String, // Incoming Field Name, (Will update this field in parent)
    val: { type: Number, default: 0 }, // Incoming Field Value
    minVal: { type: Number, default: 0 }, //min value that the user can decrease the field
    maxVal: { type: Number, default: null }, //max value that the user can increase the field
    incrementBy: {type: Number, default : 1}, 
    valClass: String,
    disableInput: {type: Boolean, default: false},
    plusMinusChangeHandler: Function, //Update parent with data
    fontSize: String, //Fontsize of lab
  },
  methods: {
    decreaseCheckbox() {
      if (this.getTextFieldValue > this.minVal) {
        this.getTextFieldValue -= this.incrementBy;
      }
    },
    increaseCheckbox() {
      if (this.getTextFieldValue < this.maxVal || this.maxVal == null) {
        this.getTextFieldValue += this.incrementBy;
      }
    },
  },

  computed: {
    getTextFieldValue: {
      get() {
        return this.val;
      },
      set(val) {
        this.plusMinusChangeHandler({
          fieldName: this.fieldName,
          textFieldValue: val,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.minusPlus {
  cursor: pointer;
  color: #263573;
  // max-height: 40px;
}
.compClass {
  display: flex;
  align-items: center;
}
.centered-input >>> input {
  text-align: center;
}
.plusMinCol {
  min-width: 110px !important;
  max-width: 150px !important;
}
</style>
