<template>
  <v-alert dense class="test" :color="backgroundColor ? backgroundColor : '#e8e8ea'" :style="`color: ${color ? color : '#263573'}`"
    :icon="this.icon">
    <span :style="`font-size:  ${hintFontSize ? hintFontSize : '12px'}`" class="hintField">
      {{ this.data }}
    </span>
  </v-alert>
</template>

<script>
/*
 Name:HintField
 Description: Field with icon for hint text

 API Property:
  icon: String for the icon that we show (eye)
  data: String for the text
  color: String for the color of the alert area
  backgroundColor: String for hint background color
  hintFontSize: String for font size of text
*/
export default {
  name: "HintField",
  props: {
    icon: String, //the icon that we show (eye)
    data: String, //the text
    color: String, //the color of the alert area
    backgroundColor: String, // Hint background color
    hintFontSize: String // Font size of text
  },
  computed: {
    cssColor() {
      return {
        "--color": this.color || "#263573",
      };
    },
  },
};
</script>

<style scoped>
.hintField {
  color: var(--color);
}

i {
  color: yellow  !important;
}
/* zaka */
.test i{
   color: yellow !important;
}
</style>
