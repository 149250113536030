<template>
  <v-col>
    <v-row class="mb-5 orangeText textDecorationBold font14">
      {{ title }}
    </v-row>
    <v-row :style="mobileBreakpoint == true ? 'margin-right: -48px;' : ''">
      <Map :locationMarkers="locationMarkers" :showDetails="showDetails" :showNIS="showNIS" mapWidth="100%" :mapStyle="
        mobileBreakpoint == true ? 'margin-left: -18px !important;' : ''
      " />
    </v-row>
    <v-row>
      <v-col class="align-self-center flex-grow-0">
        <v-btn @click="showNIS = !showNIS" :class="showNIS ? 'activeBtn' : false" :loading="loadingShowNIS"
          class="ma-1 btn" outlined>
          {{ $t("ViewInternationalSchools") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
 /*
 Name: MapInfo
 Description: Component for real estate map info at the slider

 API Property: 
    title: String for the title
    data: Object, Array containing location info
    showDetails: Boolean to show details

    Components Used:
      Map
*/
import Map from "@/components/Maps/Map";
import Repository from "@/services/Repository";
const RealEstateRepo = Repository.get("realEstate");

export default {
  name: "MapInfo",
  components: { Map },
  props: {
    title: String, // Title
    data: [Object, Array], // Object containing location info
    showDetails: { type: Boolean, default: true },
  },
  created() {
    this.fetchIntSchools();
  },
  watch: {
    showNIS() {
      this.fetchIntSchools();
    },
  },
  data() {
    return {
      showNIS: false,
      loadingShowNIS: false,
      interSchoolsLocMarkers: [],
    };
  },
  methods: {
    async fetchIntSchools() {
      if (this.showNIS) {
        this.loadingShowNIS = true;
        const resp = await RealEstateRepo.getInternationalSchools();
        let internationalSchools = [];
        try {
          resp.data.map((school) => {
            internationalSchools.push({
              position: {
                lat: school["GPS"]["coordinates"][0],
                lng: school["GPS"]["coordinates"][1],
              },
              type: "school",
            });
          });
        } catch (err) {
          internationalSchools = [];
        }
        this.interSchoolsLocMarkers = internationalSchools;
        this.loadingShowNIS = false;
      }
    },
  },
  computed: {
    locationMarkers() {
      let locMarkers = [];
      try {
        locMarkers = JSON.parse(
          JSON.stringify([
            {
              position: { lat: this.data.GPS[0], lng: this.data.GPS[1] },
              data: this.data,
              type: "data",
            },
          ])
        );
      } catch {
        locMarkers = [{ position: { lat: "", lng: "" }, type: "data" }];
      }
      this.showNIS
        ? locMarkers.push(...this.interSchoolsLocMarkers)
        : locMarkers;
      return locMarkers;
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
<style scoped>
.activeBtn {
  background-color: #263573 !important;
  color: white !important;
  text-transform: unset !important;
  background-color: #263573 !important;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}
</style>