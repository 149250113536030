import store from "@/store";
import authData from "@/static/auth/auth.js";

export default {
  getKeyForAuth(role) {
    return ["OfficeAdmin", "Secretary"].includes(role)
      ? "Company"
      : ["Member", "Host"].includes(role)
        ? "CustomerID"
        : ["Attorney"].includes(role)
          ? "AttorneyID"
          : "UserID";
  },
  authorization(action, param, paraUserId) {
    /* ----------------------------------------------------------------------------
      ['OfficeAdmin', 'Secretary'].includes(userDetails.Role) THEN 'Company'
      ['Member', 'Host'].includes(userDetails.Role) THEN 'CustomerID'
      ELSE 'UserID'
    -------------------------------------------------------------------------------*/
    let ret = false;
    let role = store.state.auth.userDetails.Role;
    let userId = store.state.auth.userDetails["_id"];

    if (
      role == "Associate" &&
      action == "requestCoopetration" &&
      paraUserId != userId
    ) {
      ret = true;
    }
    //If user role is admin always return true
    if (role == "Admin") return true;
    //If user role is OfficeAdmin or Secretary then check if the param Companies match the user Company
    if (
      ["OfficeAdmin", "Secretary"].includes(role) &&
      param == store.state.auth.userDetails["Company"]
    ) {
      ret = this.hasPrivilege(action, role);
      if (ret == true) return ret;
    }
    //If user is the customer that of the record
    if (param == userId) {
      ret = this.hasPrivilege(action, role);
      if (ret == true) return ret;
    }
    //If user is the same user that created the record
    if (paraUserId == userId) {
      ret = this.hasPrivilege(action, role);
      if (ret == true) return ret;
    }
    // Will need to check later 
    if (role == "Leader") {
      var team = store.state.auth.userDetails.Team && store.state.auth.userDetails.Team.length > 0 ? store.state.auth.userDetails.Team : [];
      if (team && team.length > 0) {
        if (team.includes(paraUserId)){
          ret = this.hasPrivilege(action, role);
          if (ret == true) return ret;
        }
      } 
    }  
    return ret;
    // else if (role == "OfficeAdmin") {
    //   let company = store.state.auth.userDetails.Company;
    //   if (users.length == 0) {
    //     return this.hasPrivilege(action, role);
    //   } else {
    //     return users.includes(company);
    //   }
    //   //company check
    // }
  },
  hasPrivilege(action, role) {
    try {
      let data = authData.privilege;
      let privilege = data.filter((d) => {
        if (d.role == role) return d;
      })[0].privilege;
      return privilege.includes(action);
    } catch {
      return false;
    }
  },
};
