export default {
  state: {
    isAuthenticated: false,
    token: null,
    user: null,
    userDetails: null,
    refreshToken: null,
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    getFirstname(state) {
      if (
        state.userDetails.FirstName != undefined ||
        state.userDetails.FirstName != null
      ) {
        let firstname = state.userDetails.FirstName;
        return firstname;
      }
      return "";
    },
    getLastname(state) {
      if (
        state.userDetails.LastName != undefined ||
        state.userDetails.LastName != null
      ) {
        let lastname = state.userDetails.LastName;
        return lastname;
      }
      return "";
    },
    // getUserPhoto(state) {
    //   return state && state.userDetails && state.userDetails.Photo ? `https://my.newdeal.gr/${state.userDetails.Photo}` : null;
    // },
    getToken(state) {
      return state.token;
    },
    getRefreshToken(state) {
      return state.user.refreshToken;
    },
  },
  mutations: {
    setLogin(state, payload) {
      state.isAuthenticated = true;
      state.token = payload.token;
      state.user = payload.user;
    },
    setLogout(state) {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
      state.userDetails = null;
    },
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setRefreshToken(state, payload) {
      state.user.refreshToken = payload;
    },
    setCoins(state, payload) {
      state.userDetails[payload.prop] = payload.value;
    },
  },
  actions: {
    onLogin({ commit }, payload) {
      commit("setLogin", payload);
    },
    onLogout({ commit }) {
      commit("setLogout");
    },
    onSetUserDetails({ commit }, payload) {
      commit("setUserDetails", payload);
    },
    onSetToken({ commit }, payload) {
      commit("setToken", payload);
    },
    onSetRefreshToken({ commit }, payload) {
      commit("setRefreshToken", payload);
    },
    onSetCoins({ commit }, payload) {
      commit("setCoins", payload);
    },
  },
};
