export default {
    state: {
        callΙmportance: [
            { id: 0, val: "Εύκολο", text: "easy" },
            { id: 1, val: "Κανονικό", text: "normal" },
            { id: 2, val: "Επείγων", text: "urgent" },
        ],
    },

    getters: {
        callΙmportance(state) {
            return state.callΙmportance;
        },
    },


};