<template>
  <v-row>
    <v-col cols="12" align="left" class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" align="left">
          <span class="listHeader fontOrange">{{ $t("distances") }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4" v-if="true == checkShow('distance_from_sea')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('sea')" :data="this.data.distance_from_sea + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_airport')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('airport')" :data="this.data.distance_from_airport + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_acropolis')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('acropoliMonuments')" :data="this.data.distance_from_acropolis + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4" v-if="true == checkShow('distance_from_bank')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('bank')" :data="this.data.distance_from_bank + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_schools')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('school')" :data="this.data.distance_from_schools + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_super_market')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('superMarket')" :data="this.data.distance_from_super_market + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4" v-if="true == checkShow('distance_from_metro')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('metro')" :data="this.data.distance_from_metro + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_commuting_stops')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('urbanTransport')" :data="this.data.distance_from_commuting_stops + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4" v-if="true == checkShow('distance_from_train')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('ΗΣΑΠ')" :data="this.data.distance_from_train + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_square')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('square')" :data="this.data.distance_from_square + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4" v-if="true == checkShow('distance_from_park')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('park')" :data="this.data.distance_from_park + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_hospital')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('hospital')" :data="this.data.distance_from_hospital + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_suburban')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('suburbanRailway')" :data="this.data.distance_from_suburban + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4" v-if="true == checkShow('distance_from_tram')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('tram')" :data="this.data.distance_from_tram + ' ' + mu" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="2" lg="2" md="4" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('distance_from_international_schools')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('internationalSchools')" :data="this.data.distance_from_international_schools + ' ' + mu"
            :isUnderline="false" />
        </v-col>
      </v-row>
      <v-row v-if="mobileBreakpoint == true && topShow.showMore != undefined" class="mt-0 justify-center">
        <v-col class="pt-0 pb-0" align="center">
          <v-btn class="primaryColor unsetCapitalize opacity1 textDecorationBold" text plain
            @click="() => (topShow.showMore = !topShow.showMore)">
            {{ topShow.showMore == true ? $t("lessDistances") : $t("moreDistances") }}...
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
 /*
 Name: Cooperation
 Description: Component for real estate distances info at the slider

 API Property: 
    Components Used:
      ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "DistanceInfo",
  props: ["data"],
  components: {
    ReadField,
  },
  data() {
    return {
      topArray: [
        "distance_from_sea",
        "distance_from_airport",
        "distance_from_acropolis",
        "distance_from_bank",
        "distance_from_schools",
        "distance_from_super_market",
        "distance_from_metro",
        "distance_from_commuting_stops",
        "distance_from_train",
        "distance_from_square",
        "distance_from_park",
        "distance_from_hospital",
        "distance_from_suburban",
        "distance_from_tram",
        "distance_from_international_schools",
      ],
      topShow: { showKeys: [], showMore: undefined },
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
    };
  },
  created() {
    if (this.mobileBreakpoint == true)
      this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4);
  },
  computed: {
    mu() {
      return this.data.distanceUnit ? this.data.distanceUnit : "";
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    checkShow(objKey) {
      return this.data[objKey]
        ? this.mobileBreakpoint != true
          ? true
          : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
            this.topShow.showMore != false
            ? true
            : false
        : false;
    },
  },
};
</script>

<style scoped>
.listHeader {
  color: #263573;
  font-size: 14px;
}

.fontOrange {
  color: #e89008;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
</style>
