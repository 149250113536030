export default {
  privilege: [
    { role: "Admin", privilege: ["add", "edit", "delete", "upload", "viewAll", "approveEstate"] },
    { role: "OfficeAdmin", privilege: ["add", "edit", "delete", "upload", "viewAll", "approveEstate"] },
    { role: "Secretary", privilege: ["add", "edit", "delete", "upload", "viewAll", "approveEstate"] },
    { role: "HR", privilege: ["add", "edit", "delete", "upload", "viewAll", "approveEstate"] },
    { role: "Associate", privilege: ["add", "edit", "delete", "upload", "viewAll"] },
    { role: "Leader", privilege: ["add", "edit", "delete", "upload", "viewAll"] },
    { role: "Attorney", privilege: ["add", "edit", "upload", "viewAll"] },
    { role: "Host", privilege: ["viewAll", "upload",] },
    { role: "Member", privilege: ["viewAll", "upload",] },
  ],
};
