export default {
  filterMarkers(locationMarkers) {
    return locationMarkers.reduce(function (filtered, m) {
      if (
        ![{}, "", null, undefined].includes(m.position.lat) &&
        ![{}, "", null, undefined].includes(m.position.lng)
      ) {
        filtered.push(m);
      }
      return filtered;
    }, []);
  },
  getCenter(locationMarkers, mapCenter) {
    if (![null, undefined, ''].includes(mapCenter))
      return mapCenter
    else if (
      locationMarkers &&
      locationMarkers.length == 1 &&
      locationMarkers[0].position &&
      locationMarkers[0].position.lat &&
      locationMarkers[0].position.lng &&
      ![{}, "", null, undefined].includes(locationMarkers[0].position.lat) &&
      ![{}, "", null, undefined].includes(locationMarkers[0].position.lng)
    ) {
      return locationMarkers[0].position;
    } else {
      return { lat: 37.98381, lng: 23.727539 };
    }
  },
  getStyles(
    textFillColor,
    textStrokeColor,
    showIcons,
    landScapeColor,
    roadColors
  ) {
    return [
      {
        featureType: "all",
        elementType: "geometry",
        stylers: [
          {
            color: "#202c3e",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
          {
            gamma: 0.01,
          },
          {
            lightness: 20,
          },
          {
            weight: "1.39",
          },
          {
            color: textFillColor,
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
          {
            weight: "0.96",
          },
          {
            saturation: "9",
          },
          {
            visibility: "on",
          },
          {
            color: textStrokeColor,
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: showIcons,
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            lightness: 30,
          },
          {
            saturation: "9",
          },
          {
            color: landScapeColor,
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            saturation: 20,
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            lightness: 20,
          },
          {
            saturation: -20,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            lightness: 10,
          },
          {
            saturation: -30,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: roadColors,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: 25,
          },
          {
            lightness: 25,
          },
          {
            weight: "0.01",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            lightness: -20,
          },
        ],
      },
    ];
  },
  getAddressObj(components) {
    let final = {};
    components.map((c) => {
      final[c.types[0]] = c.long_name ? c.long_name : c.short_name;
    });
    return final;
  },
  updateSearch(locationGoogle, locationsNewDeal, showNewDealList) {
    if (showNewDealList == true) {
      let locG = "";
      let locND = "";
      let route = "";
      let region = "";
      let commaSeperate = "";
      let areaSeperate = "";
      if (locationGoogle && locationGoogle.addressExtracted) {
        region = locationGoogle.addressExtracted
          .administrative_area_level_1
          ? locationGoogle.addressExtracted.administrative_area_level_1
          : locationGoogle.addressExtracted.administrative_area_level_2
            ? locationGoogle.addressExtracted.administrative_area_level_2
            : locationGoogle.addressExtracted.administrative_area_level_3
              ? locationGoogle.addressExtracted.administrative_area_level_3
              : "";
        route = locationGoogle && locationGoogle.route ?
          locationGoogle.route : locationGoogle && locationGoogle.name ?
            locationGoogle.name : "";
      }
      else
        route = locationGoogle && locationGoogle.name ? locationGoogle.name : "";
      areaSeperate = region != "" && route != "" ? " | " : "";
      commaSeperate = region != "" || route != "" ? ", " : "";
      locG = region + areaSeperate + route;
      locND = locationsNewDeal.length > 0 ? locationsNewDeal.join(", ") : "";
      return locG + commaSeperate + locND;
    }
    return locationGoogle
  },
  getStateGoogle(locationGoogle) {
    return locationGoogle.vicinity ? locationGoogle.vicinity : "";
  },
  getRegionGoogle(locationGoogle) {
    if (locationGoogle.addressExtracted)
      return locationGoogle.addressExtracted.administrative_area_level_1
        ? locationGoogle.addressExtracted.administrative_area_level_1
        : locationGoogle.addressExtracted.administrative_area_level_2
          ? locationGoogle.addressExtracted.administrative_area_level_2
          : locationGoogle.addressExtracted.administrative_area_level_3
            ? locationGoogle.addressExtracted.administrative_area_level_3
            : "";
    return "";
  }
};
