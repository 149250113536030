<template>
  <v-navigation-drawer
    width="866px"
    style="height: 100%"
    fixed
    right
    v-model="show"
    app
    temporary
  >
    <v-col class="px-12">
      <v-row>
        <v-col align="right" class="pb-0">
          <v-icon @click="onCloseHandler" class="primaryColorOnHover xClass">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>

      <v-row class="mt-2" v-if="data.basicInfo.isImg == true">
        <v-col cols="12" @click="openDialog('upload')">
          <AvatarField
            class="cursor-pointer"
            :data="getPhoto(data)"
            :isImg="data.basicInfo.isImg ? true : false"
            :showName="true"
            :nameContent="
              (data.basicInfo.FirstName ? data.basicInfo.FirstName + ' ' : '') +
              (data.basicInfo.LastName ? data.basicInfo.LastName : '')
            "
            fontSizeName="24px"
            :size="135"
            nameContentClass="textDecorationBold primaryColor"
            fontSizeImg="14px"
            backgroundColor="#fff"
            :showSubContent="true"
            :subContent="
              data.basicInfo.displayRole ? data.basicInfo.displayRole : ''
            "
            :showRating="data.basicInfo.showRating"
            :ratingData="data.basicInfo.ratingData"
            :starSize="16"
            fontSizeSubContent="16px"
            subContentClass="textDecorationBold"
            :absolutePath="absolutePath"
          />
        </v-col>
      </v-row>

      <v-row align="center" class="mt-0" v-if="data.basicInfo.isImg != true">
        <v-col align="left" class="pt-0 pb-4">
          <HeaderTitle
            :title="
              editData.FirstName && editData.FirstName
                ? editData.FirstName + ' ' + editData.LastName
                : editData.FirstName
            "
            :subTitle="editData.displayRole"
          />
        </v-col>
      </v-row>
      <v-row class="mt-2" v-if="data.basicInfo.isImg != true">
        <v-col
          class="flex-grow-0 pt-0"
          @click="openDialog('upload')"
          style="cursor: pointer"
        >
          <v-avatar :size="131">
            <v-img :size="131" :src="imgUrl"> </v-img>
          </v-avatar>
        </v-col>
        <v-col align="left" class="align-self-center">
          <span
            class="primaryColor"
            style="font-family: CenturyGothic; font-size: 20px"
          >
            {{ $t("uploadYourPhoto") + "..." }}
          </span>
          <span
            class="primaryColor"
            style="font-family: CenturyGothic; font-size: 14px"
          >
            <br />{{ $t("photoAtLeast") + " 300px x 300px" }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9" align="left">
          <v-tabs :show-arrows="false" v-model="activeTab">
            <v-tabs-slider style="height: 4px" color="error"> </v-tabs-slider>
            <v-tab class="textTranformUnset textDecorationBold mr-1 cusTab">
              {{ $t("profile") }}
            </v-tab>
            <v-tab class="textTranformUnset textDecorationBold mr-1 cusTab">
              {{ $t("account") }}
            </v-tab>
            <v-tab
              v-if="
                data.basicInfo.displayRole
                  ? ['Associate', 'OfficeAdmin'].includes(
                      data.basicInfo.displayRole
                    )
                  : false
              "
              class="textTranformUnset textDecorationBold mr-1 cusTab"
            >
              {{ $t("subscriptionsCoins") }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col
          class="py-6"
          cols="12"
          xs="12"
          sm="3"
          md="3"
          lg="3"
          xl="3"
          align="right"
        >
          <v-btn
            max-width="40"
            max-height="40"
            key="fBtn2"
            outlined
            color="primary"
            @click="onSubmit"
          >
            <Icon icon="eva:save-outline" height="25" outline color="#263573" />
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mx-0 mt-n6">
        <v-col class="pb-0 pt-0">
          <v-divider />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <ProfileContact
                v-if="activeTab == 0"
                :data="data"
                :validations="this.$v"
                :updatePropAttribute="updateAttribute"
                :updateUploadedDoc="updateUploadedDocHandler"
                :initSliderInfo="initSliderInfo"
              />
            </v-tab-item>
            <v-tab-item>
              <AccountContact
                v-if="activeTab == 1"
                :data="data"
                :validations="this.$v"
                :updatePropAttribute="updateAttribute"
                :initSliderInfo="initSliderInfo"
              />
            </v-tab-item>
            <v-tab-item
              v-if="
                data.basicInfo.displayRole
                  ? ['Associate', 'OfficeAdmin'].includes(
                      data.basicInfo.displayRole
                    )
                  : false
              "
            >
              <Coins
                v-if="activeTab == 2"
                :data="data"
                :fetchSpecificContact="fetchSpecificContact"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialog.open"
        v-if="dialog.type == 'upload'"
        class="pa-6"
      >
        <Upload
          class="pa-6"
          :onlyImgRestrict="true"
          :singularUpload="true"
          :closeHandler="closeDialog"
          :submitHandler="setImage"
        />
      </v-dialog>
    </v-col>
  </v-navigation-drawer>
</template>
  
<script>
import { mapGetters, mapState } from "vuex";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import ProfileContact from "@/components/Contacts/EditContact/ProfileContact.vue";
import AccountContact from "@/components/Contacts/EditContact/AccountContact.vue";
import ContactModel from "@/static/contacts/contacts.js";
import { Icon } from "@iconify/vue2";
import Repository from "@/services/Repository";
import Coins from "@/components/Contacts/ContactSlider/Coins.vue";
import Upload from "@/components/Upload/Upload.vue";
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";
import firebase from "firebase";
import store from "@/store";
import axios from "axios";
//import CoreHelper from "@/helpers/Core/core.js";
import { required, email } from "vuelidate/lib/validators";
import moment from "moment";
const ContactsRepo = Repository.get("contacts");

/*
 Name:EditSlider
 Description: Component to render edit contact Slider

 API Property:
    closeHandler: Function to close the slider
    initSliderInfo: Object to initialize the data indo
    editData: Object with edit data 
    closeEditDialog: Function to close the slider
    fetchSpecificContact: Function to fetch specifi contact
    activePropTab: Number for active tab choice
    updateUploadedDoc: Function to update the doc
    
  Components Used:
    HeaderTitle
    ProfileContact
    Icon
    AccountContact
    Coins
    Upload
    AvatarField

  Endpoints Functions Called:
      -ContactsRepo
        editContact
        uploadContactPhoto
*/
export default {
  name: "EditSlider",
  props: {
    closeHandler: Function,
    initSliderInfo: Object,
    editData: Object,
    closeEditDialog: Function,
    fetchSpecificContact: Function,
    activePropTab: { type: Number, default: 0 },
    updateUploadedDoc: Function
  },
  components: {
    HeaderTitle,
    ProfileContact,
    Icon,
    AccountContact,
    Coins,
    Upload,
    AvatarField,
  },
  data() {
    return {
      activeTab: this.activePropTab,
      tempPhoto: null,
      absolutePath: false,
      show: true,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      loader: false,
      documents: {
        myDocuments: [
          {
            name: "1647076114178medium1648408023.jpg",
            path: "../Uploads/legalDocuments/ec4d8598b18853be46713017a4fc29e2.jpg",
            size: 301678,
            visibility: "public",
            timestamp: 1657788800,
            // "docType": "myDocuments",
            indx: 0,
          },
          {
            name: "274648626_1308204806258589_2890062298352653477_n274648626_1308204806258589_2890062298352653477_n274648626_1308204806258589_2890062298352653477_n.jpg",
            path: "../Uploads/legalDocuments/a3634aa325a5ab6d194614beefdf3e35.jpg",
            size: 511588,
            visibility: "public",
            timestamp: 1657789332,
            // "docType": "myDocuments",
            indx: 1,
          },
          {
            name: "pexels-photo-106399.jpeg",
            path: "../Uploads/legalDocuments/d583a7650490f7c64dccb206a87d5c90.jpeg",
            size: 354238,
            visibility: "public",
            timestamp: 1657797033,
            // "docType": "myDocuments",
            indx: 2,
          },
        ],
        agreements: [
          {
            name: "274648626_1308204806258589_2890062298352653477_n274648626_1308204806258589_2890062298352653477_n274648626_1308204806258589_2890062298352653477_n.jpg",
            path: "../Uploads/legalDocuments/0b050edfab8d8eec3b606f5b06cc3ed5.jpg",
            size: 511588,
            visibility: "public",
            timestamp: 1658734355,
            // "docType": "agreements",
            indx: 0,
          },
          {
            name: "hee hee.pdf",
            path: "../Uploads/legalDocuments/ed684bb779a7fccd4f43c1dbf95c4cf8.pdf",
            size: 1399999,
            visibility: "public",
            timestamp: 1658912290,
            // "docType": "agreements",
            indx: 1,
          },
        ],
      },
      docTypesList: ContactModel.docTypesList,
      data: {
        // accountInfo: ContactModel.accountInfoModel,
        basicInfo: { ...ContactModel.basicInfoModel },
        contactInfo: { ...ContactModel.contactInfoModel },
        socialInfo: { ...ContactModel.socialInfoModel },
        addressInfo: { ...ContactModel.addressInfoModel },
        personalInfo: { ...ContactModel.personalInfoModel },
        bioInfo: { ...ContactModel.bioInfoModel },
        createAccountInfo: { ...ContactModel.createAccountModel },
        addAdminBrokerInfo: { ...ContactModel.addAdminBrokerModel },
        accountEditInfo: { ...ContactModel.accountEditInfoModel },
        coinsInfo: { ...ContactModel.coinsEditModel },
        Source: null,
        Comments: null,
      },
    };
  },

  watch: {
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
    tempPhoto() {
      if (this.tempPhoto) {
        this.data.basicInfo.Photo = this.imgUrl;
        this.absolutePath = true;
      }
    },
    editData() {
      this.initialData();
    },
  },
  created() {
    this.initialData();
  },
  validations: {
    data: {
      basicInfo: {
        //organizationName: { required },
        FirstName: { required },
        LastName: { required },
      },
      contactInfo: {
        Email: { email, required },
        Email2: { email },
        Mobile: { required },
      },
      // bioInfo: {
      //   specLocations: { required },
      // },
    },
  },
  computed: {
    ...mapGetters(["getRefferalList"]),
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    imgUrl() {
      if (!this.tempPhoto) return require("@/assets/contact/upload.png");
      return URL.createObjectURL(this.tempPhoto);
      //        if (!["", null, undefined].includes(this.tempPhoto)) {
      //   return URL.createObjectURL(this.tempPhoto);
      // } else if (!["", null, undefined].includes(this.data.basicInfo.Photo)) {
      //   return CoreHelper.getImagePath({
      //     path: this.data.basicInfo.Photo,
      //     absolutePath: false,
      //   });
      // } else return require("@/assets/contact/upload.png");
    },
  },
  methods: {
    onCloseHandler() {
      this.closeHandler();
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    getPhoto(obj) {
      return obj.basicInfo.Photo
        ? obj.basicInfo.Photo
        : obj.basicInfo.FirstName && obj.LastName
        ? obj.basicInfo.FirstName.charAt(0) + obj.basicInfo.LastName.charAt(0)
        : obj.basicInfo.FirstName
        ? obj.basicInfo.FirstName.charAt(1)
        : obj.basicInfo.LastName
        ? obj.basicInfo.LastName.charAt(1)
        : "";
    },
    setImage(img) {
      if (img.length > 0) this.tempPhoto = img[0].file;
      else this.tempPhoto = null;
    },
    getMetaData(token) {
      return axios.get(
        `${process.env.VUE_APP_BASE_URL}/general/QueryContactMetadata`,
        {
          headers: {
            "X-AUTH-ID-TOKEN": token,
          },
        }
      );
    },
    initialData() {
      // Basic Info
      if (this.editData.FirstName) {
        this.data.basicInfo.FirstName = this.editData.FirstName;
      }
      if (this.editData.LastName) {
        this.data.basicInfo.LastName = this.editData.LastName;
      }
      if (this.editData.FatherName) {
        this.data.basicInfo.FatherName = this.editData.FatherName;
      }
      if (this.editData.Profession) {
        this.data.basicInfo.Profession = this.editData.Profession;
      }
      if (this.editData.Suffix) {
        this.data.basicInfo.Suffix = this.editData.Suffix;
      }
      if (this.editData.Photo) {
        this.data.basicInfo.Photo = this.editData.Photo;
      }
      if (this.editData.isImgAgent) {
        this.data.basicInfo.isImg = this.editData.isImgAgent;
      }
      if (this.editData.displayRole) {
        this.data.basicInfo.displayRole = this.editData.displayRole;
      }
      if (this.editData.showRating) {
        this.data.basicInfo.showRating = this.editData.showRating;
      }
      if (this.editData.ratingData) {
        this.data.basicInfo.ratingData = this.editData.ratingData;
      }

      // Coins Info
      if (this.editData.AvailableAdsNewDeal) {
        this.data.coinsInfo.AvailableAdsNewDeal =
          this.editData.AvailableAdsNewDeal;
      } else {
        this.data.coinsInfo.AvailableAdsNewDeal = 0;
      }
      if (this.editData.AvailableAdsSpitogatos) {
        this.data.coinsInfo.AvailableAdsSpitogatos =
          this.editData.AvailableAdsSpitogatos;
      } else {
        this.data.coinsInfo.AvailableAdsSpitogatos = 0;
      }
      if (this.editData.AvailableAdsXE) {
        this.data.coinsInfo.AvailableAdsXE = this.editData.AvailableAdsXE;
      } else {
        this.data.coinsInfo.AvailableAdsXE = 0;
      }
      if (this.editData.CommentAdsNewDeal) {
        this.data.coinsInfo.CommentAdsNewDeal = this.editData.CommentAdsNewDeal;
      }
      if (this.editData.CommentAdsSpitogatos) {
        this.data.coinsInfo.CommentAdsSpitogatos =
          this.editData.CommentAdsSpitogatos;
      }
      if (this.editData.CommentAdsXE) {
        this.data.coinsInfo.CommentAdsXE = this.editData.CommentAdsXE;
      }

      // Account Edit Info
      if (this.editData.Email) {
        this.data.accountEditInfo.Email = this.editData.Email;
      }
      if (this.editData.LastCompany) {
        this.data.accountEditInfo.LastCompany = this.editData.LastCompany;
      }
      if (this.editData.IsEnabled) {
        this.data.accountEditInfo.IsEnabled = this.editData.IsEnabled;
      }
      if (this.editData._id) {
        this.data.accountEditInfo._id = this.editData._id;
      }
      if (this.editData.Type) {
        this.data.accountEditInfo.Type = this.editData.Type;
      }

      // Contact Info
      if (this.editData.Email) {
        this.data.contactInfo.Email = this.editData.Email;
      }
      if (this.editData.Email2) {
        this.data.contactInfo.Email2 = this.editData.Email2;
      }
      if (this.editData.Mobile) {
        this.data.contactInfo.Mobile = this.editData.Mobile;
      }
      if (this.editData.Telephone) {
        this.data.contactInfo.Telephone = this.editData.Telephone;
      }
      if (this.editData.otherContactDetails) {
        this.data.contactInfo.otherContactDetails =
          this.editData.otherContactDetails;
      }

      // Social Info
      if (this.editData.Website) {
        this.data.socialInfo.Website = this.editData.Website;
      }
      if (this.editData.FB) {
        this.data.socialInfo.FB = this.editData.FB;
      }
      if (this.editData.Instagram) {
        this.data.socialInfo.Instagram = this.editData.Instagram;
      }
      if (this.editData.LinkedIn) {
        this.data.socialInfo.LinkedIn = this.editData.LinkedIn;
      }
      if (this.editData.Twitter) {
        this.data.socialInfo.Twitter = this.editData.Twitter;
      }
      if (this.editData.otherSocialHandles) {
        this.data.socialInfo.otherSocialHandles =
          this.editData.otherSocialHandles;
      }

      // Address Info
      if (this.editData.Country) {
        this.data.addressInfo.Country = this.editData.Country;
      }
      if (this.editData.City) {
        this.data.addressInfo.City = this.editData.City;
      }
      if (this.editData.Neighborhood) {
        this.data.addressInfo.Neighborhood = this.editData.Neighborhood;
      }
      if (this.editData.Street) {
        this.data.addressInfo.Street = this.editData.Street;
      }
      if (this.editData.Zip) {
        this.data.addressInfo.Zip = this.editData.Zip;
      }
      if (this.editData.otherAddress) {
        this.data.addressInfo.otherAddress = this.editData.otherAddress;
      }

      // Personal Info
      if (this.editData.Birthday) {
        this.data.personalInfo.Birthday = this.editData.Birthday;
      }
      if (this.editData.Nameday) {
        this.data.personalInfo.Nameday = this.editData.Nameday;
      }
      if (this.editData.AFM) {
        this.data.personalInfo.AFM = this.editData.AFM;
      }
      if (this.editData.DOY) {
        this.data.personalInfo.DOY = this.editData.DOY;
      }
      if (this.editData.personalId) {
        this.data.personalInfo.personalId = this.editData.personalId;
      }
      if (this.editData.otherField) {
        this.data.personalInfo.otherField = this.editData.otherField;
      }
      if (this.editData.chamberNo) {
        this.data.personalInfo.chamberNo = this.editData.chamberNo;
      }

      if (this.editData.UserPassword) {
        this.data.createAccountInfo.UserPassword = this.editData.UserPassword;
      }

      //Bio Info
      if (this.editData.real_estate_specialization) {
        this.data.bioInfo.specLocations =
          this.editData.real_estate_specialization;
      }
      if (this.editData.short_bio) {
        this.data.bioInfo.shortBio = this.editData.short_bio;
      }
      if (this.editData.qualifications) {
        this.data.bioInfo.qualifications = this.editData.qualifications;
      }
      if (this.editData.languages) {
        this.data.bioInfo.languages = this.editData.languages;
      }
      if (this.editData.cv_file_data) {
        this.data.bioInfo.cvInfo = this.editData.cv_file_data
      }
      // Personal Info
      // if (this.editData.Source) {
      //     this.data.Source = this.editData.Source;
      // }

      // Comment Info
      // if (this.editData.Comments) {
      //     this.data.Comments = this.editData.Comments;
      // }
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      var body = {};

      // Basic Info
      if (this.data.basicInfo.FirstName != null)
        body["FirstName"] = this.data.basicInfo.FirstName;
      if (this.data.basicInfo.LastName != null)
        body["LastName"] = this.data.basicInfo.LastName;
      if (this.data.basicInfo.FatherName != null)
        body["FatherName"] = this.data.basicInfo.FatherName;
      if (this.data.basicInfo.Profession != null)
        body["Profession"] = this.data.basicInfo.Profession;
      if (this.data.basicInfo.Suffix != null)
        body["Suffix"] = this.data.basicInfo.Suffix;
      if (this.data.basicInfo.displayRole != null)
        body["displayRole"] = this.data.basicInfo.displayRole;
      if (this.data.basicInfo.showRating != null)
        body["showRating"] = this.data.basicInfo.showRating;
      if (this.data.basicInfo.ratingData != null)
        body["ratingData"] = this.data.basicInfo.ratingData;

      // Coins Info
      if (this.data.coinsInfo.AvailableAdsNewDeal != null)
        body["AvailableAdsNewDeal"] = this.data.coinsInfo.AvailableAdsNewDeal;
      if (this.data.coinsInfo.AvailableAdsSpitogatos != null)
        body["AvailableAdsSpitogatos"] =
          this.data.coinsInfo.AvailableAdsSpitogatos;
      if (this.data.coinsInfo.AvailableAdsXE != null)
        body["AvailableAdsXE"] = this.data.coinsInfo.AvailableAdsXE;
      if (this.data.coinsInfo.CommentAdsNewDeal != null)
        body["CommentAdsNewDeal"] = this.data.coinsInfo.CommentAdsNewDeal;
      if (this.data.coinsInfo.CommentAdsSpitogatos != null)
        body["CommentAdsSpitogatos"] = this.data.coinsInfo.CommentAdsSpitogatos;
      if (this.data.coinsInfo.CommentAdsXE != null)
        body["CommentAdsXE"] = this.data.coinsInfo.CommentAdsXE;

      // Account Edit Info
      if (this.data.accountEditInfo.Email != null)
        body["Email"] = this.data.accountEditInfo.Email;
      if (this.data.accountEditInfo.LastCompany != null)
        body["LastCompany"] = this.data.accountEditInfo.LastCompany;
      if (this.data.accountEditInfo.IsEnabled != null)
        body["IsEnabled"] = this.data.accountEditInfo.IsEnabled.val;
      if (this.data.accountEditInfo._id != null)
        body["_id"] = this.data.accountEditInfo._id;

      // Contact Info
      if (this.data.contactInfo.Email != null)
        body["Email"] = this.data.contactInfo.Email;
      if (this.data.contactInfo.Email2 != null)
        body["Email2"] = this.data.contactInfo.Email2;
      if (this.data.contactInfo.Mobile != null)
        body["Mobile"] = this.data.contactInfo.Mobile;
      if (this.data.contactInfo.Telephone != null)
        body["Telephone"] = this.data.contactInfo.Telephone;
      if (this.data.contactInfo.otherContactDetails != null)
        body["otherContactDetails"] = this.data.contactInfo.otherContactDetails;

      // Social Info
      if (this.data.socialInfo.Website != null)
        body["Website"] = this.data.socialInfo.Website;
      if (this.data.socialInfo.FB != null) body["FB"] = this.data.socialInfo.FB;
      if (this.data.socialInfo.Instagram != null)
        body["Instagram"] = this.data.socialInfo.Instagram;
      if (this.data.socialInfo.LinkedIn != null)
        body["LinkedIn"] = this.data.socialInfo.LinkedIn;
      if (this.data.socialInfo.Twitter != null)
        body["Twitter"] = this.data.socialInfo.Twitter;
      if (this.data.socialInfo.otherSocialHandles != null)
        body["otherSocialHandles"] = this.data.socialInfo.otherSocialHandles;

      // Address Info
      if (this.data.addressInfo.Country != null)
        body["Country"] = this.data.addressInfo.Country;
      if (this.data.addressInfo.City != null)
        body["City"] = this.data.addressInfo.City;
      if (this.data.addressInfo.Neighborhood != null)
        body["Neighborhood"] = this.data.addressInfo.Neighborhood;
      if (this.data.addressInfo.Street != null)
        body["Street"] = this.data.addressInfo.Street;
      if (this.data.addressInfo.Zip != null)
        body["Zip"] = this.data.addressInfo.Zip;
      if (this.data.addressInfo.otherAddress != null)
        body["otherAddress"] = this.data.addressInfo.otherAddress;

      // Personal Info
      if (this.data.personalInfo.Birthday != null)
        body["Birthday"] = this.data.personalInfo.Birthday;
      if (this.data.personalInfo.Nameday != null)
        body["Nameday"] = this.data.personalInfo.Nameday;
      if (this.data.personalInfo.AFM != null)
        body["AFM"] = this.data.personalInfo.AFM;
      if (this.data.personalInfo.DOY != null)
        body["DOY"] = this.data.personalInfo.DOY;
      if (this.data.personalInfo.personalId != null)
        body["personalId"] = this.data.personalInfo.personalId;
      if (this.data.personalInfo.otherField != null)
        body["otherField"] = this.data.personalInfo.otherField;
      if (this.data.personalInfo.chamberNo != null)
        body["chamberNo"] = this.data.personalInfo.chamberNo;

      //Bio Info
      // if (this.data.bioInfo.bio != null) body["bio"] = this.data.bioInfo.bio;
      if (this.data.bioInfo.shortBio != null)
        body["short_bio"] = this.data.bioInfo.shortBio;
      if (this.data.bioInfo.specLocations != null)
        body["real_estate_specialization"] = this.data.bioInfo.specLocations;
      if (
        this.data.bioInfo.qualifications != null &&
        this.data.bioInfo.qualifications.length > 0
      ) {
        let ql = [];
        this.data.bioInfo.qualifications.map((m) => {
          if (
            ![null, undefined, ""].includes(m.school) ||
            ![null, undefined, ""].includes(m.specialization)
          )
            ql.push(m);
        });
        body["qualifications"] = ql;
      }
      if (
        this.data.bioInfo.languages != null &&
        this.data.bioInfo.languages.length > 0
      ) {
        let lng = [];
        lng = this.data.bioInfo.languages.filter((m) => {
          return ![null, undefined, ""].includes(m);
        });
        body["languages"] = lng;
      }

      // if (
      //   this.data.createAccountInfo.UserPassword != null &&
      //   this.data.createAccountInfo.UserPassword != this.editData.UserPassword
      // )
      //   body["UserPassword"] = this.data.createAccountInfo.UserPassword;

      // Source Info
      // if (this.data.Source != null)
      //     body['Source'] = this.data.Source;

      // // Comment Info
      // if (this.data.Comments != null)
      //     body['Comments'] = this.data.Comments;
      body["UpdatedDay"] = moment(new Date()).unix();
      await ContactsRepo.editContact(this.editData._id, body);
      if (this.tempPhoto) {
        let imageFormData = new FormData();
        imageFormData.append("file", this.tempPhoto);
        imageFormData.append("visibility", true);
        await ContactsRepo.uploadContactPhoto(this.editData._id, imageFormData);
      }
      if (this.data.accountEditInfo._id == this.userDetails._id) {
        let token = await firebase.auth().currentUser.getIdToken();
        let metaData = await this.getMetaData(token);
        store.dispatch("onSetUserDetails", metaData.data);
      }
      this.closeEditDialog("specificEdit", this.editData._id);
    },

    updateAttribute(kind, attribute, value) {
      this.data[kind][attribute] = value;
    },
    showInfoModel(model) {
      return this.pageModel.infoModels.includes(model);
    },
    updateUploadedDocHandler(cvInfo){
      try{ this.updateUploadedDoc(cvInfo) }catch{ true; }
    }
  },
};
</script>


<style lang="scss" scoped>
.textTranformUnset {
  text-transform: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
  