<template>
  <v-navigation-drawer width="806px" height="100%" :touchless="true" right fixed v-model="dialogValue" app temporary>
    <v-col class="pa-10">
      <v-row class="mt-n10">
        <v-col class="py-0" align="right">
          <v-btn fab color="white" elevation="0" @click="closeHandler()">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0 justify-center">
        <v-col class="pt-0 pl-0">
          <HeaderTitle :title="$t('generalSettings')" titleClass="text-no-wrap pl-5" subTitleClass="text-no-wrap"
            :subTitle="$t('realVoiceSettings')" />
        </v-col>
        <v-col class=" flex-grow-0">
          <v-btn class=" btn-custom" @click="closeHandler()" outlined color="primary" max-width="126" max-height="38"
            min-width="126" min-height="38">
            {{ $t("cancel") }}
          </v-btn>
        </v-col>
        <v-col class=" flex-grow-0">
          <v-btn class="btn-custom " @click="saveHandler()" color="error" max-width="126" max-height="38"
            min-width="126" min-height="38">
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-n10">
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="textDecorationBold font24 primaryColor" align="left">
          <span>{{ $t("areaSettings") }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-row>
            <v-col class="pb-0">
              <span class="font14 textDecorationBold" style="color: #253472">
                {{ $t("language") }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col> 
              <v-select class="pt-0" v-model="language" :items="languagesList" outlined hide-details="auto"
                item-text="label" item-value="value" dense>
                <template v-slot:selection="{ item }">
                  <v-row class="flex-grow-0">
                    <v-col class="pr-0">
                      <v-avatar size="32">
                        <v-img :src="item.image" />
                      </v-avatar>
                    </v-col>
                    <v-col style="display: flex; align-items: center">
                      {{ $t(item.label) }}
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item="{ item }">
                  <v-list>
                    <v-list-item-title>
                      <v-row>
                        <v-col>
                          <v-avatar size="24">
                            <v-img :src="item.image" />
                          </v-avatar>
                        </v-col>
                        <v-col style="display: flex; align-items: center">
                          {{ $t(item.label) }}
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-row>
            <v-col class="pb-0">
              <span class="font14 textDecorationBold" style="color: #253472">
                {{ $t("currency") }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select class="pt-0" v-model="currency" :items="currencysList" outlined hide-details="auto"
                item-text="label" item-value="value" dense>
                <template v-slot:selection="{ item }">
                  <v-row class="flex-grow-0">
                    <v-col class="pr-0">
                      <v-avatar size="20">
                        <v-img :src="item.image" />
                      </v-avatar>
                    </v-col>
                    <v-col>
                      <span style="
                          display: flex;
                          align-items: center;
                          white-space: nowrap;
                        ">
                        {{ $t(item.label) }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item="{ item }">
                  <v-list>
                    <v-list-item-title>
                      <v-row>
                        <v-col>
                          <v-avatar size="20">
                            <v-img :src="item.image" />
                          </v-avatar>
                        </v-col>
                        <v-col style="
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                          ">
                          {{ $t(item.label) }}
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="color: #253472" align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-row>
            <v-col class="pt-0 pb-0">
              <span class="font14 textDecorationBold" style="color: #253472">
                {{ $t("surfaceMeasurementUnit") }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select class="pt-0" v-model="distanceMU" :items="distanceMUsList"
                outlined hide-details="auto" item-text="label" item-value="value" dense>
                <template v-slot:selection="{ item }">
                  <v-row class="flex-grow-0">
                    <v-col>
                      <span style="
                          display: flex;
                          align-items: center;
                          white-space: nowrap;
                        ">
                        {{ $t(item.label) }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item="{ item }">
                  <v-list>
                    <v-list-item-title>
                      <v-row>
                        <v-col style="
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                          ">
                          {{ $t(item.label) }}
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col style="color: #253472" align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-row>
            <v-col class="pt-0 pb-0">
              <span class="font14 textDecorationBold" style="color: #253472">
                {{ $t("distanceMeasurementUnit") }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select class="pt-0" v-model="surfaceMU" :items="surfaceMUsList"
                outlined hide-details="auto" item-text="label" item-value="value" dense>
                <template v-slot:selection="{ item }">
                  <v-row class="flex-grow-0">
                    <v-col>
                      <span style="
                          display: flex;
                          align-items: center;
                          white-space: nowrap;
                        ">
                        {{ $t(item.label) }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item="{ item }">
                  <v-list>
                    <v-list-item-title>
                      <v-row>
                        <v-col style="
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                          ">
                          {{ $t(item.label) }}
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
/*
 Name:SettingsSlider
 Description: Settings slider when user press settins button from appbar

 API Property:
    Components Used:
    HeaderTitle
*/

import { mapState, mapActions } from "vuex";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import settingsData from "@/static/settings/settings";

export default {
  name: "SettingsSlider",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      languagesList: settingsData.languageList,
      currencysList: settingsData.currencyList,
      surfaceMUsList: settingsData.surfaceMUList,
      distanceMUsList: settingsData.distanceMUList,
      language: null,
      currency: null,
      surfaceMU: null,
      distanceMU: null
    };
  },
  created(){
    this.language = this.settings.language;
    this.currency = this.settings.currency;
    this.surfaceMU = this.settings.surfaceMU;
    this.distanceMU = this.settings.distanceMU;
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings,
    }),
    dialogValue: {
      get() {
        return this.settings.dialog;
      },
      set(value) {
        this.onSetDialog(value);
      },
    },
  },
  methods: {
    ...mapActions([
      "onSetDialog",
      "onSetLanguage",
      "onSetCurrency",
      "onSetSurfaceMU",
      "onSetDistanceMU",
    ]),
    closeHandler() {
      this.onSetDialog(false);
    },
    async saveHandler() {
      this.$i18n.locale = this.language;
      this.onSetLanguage(this.language);
      this.onSetCurrency(this.currency);
      this.onSetSurfaceMU(this.surfaceMU);
      this.onSetDistanceMU(this.distanceMU);
      location.reload();
    },
  },
};
</script>
<style scoped>
.btn-custom {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  background-color: white;
}

.headerTitle {
  text-align: initial;
}
</style>
