<template>
  <v-card class="pa-10">
    <v-row>
      <v-col>
        <v-row>
          <v-col class="textInterBold flex-grow-0 font15">
            <AvatarField
              :size="30"
              :showRating="false"
              :isImg="userData.img ? true : false"
              :showName="true"
              :nameContent="userData.FirstName + ' ' + userData.LastName"
              fontSizeName="13px"
              :data="getPhoto(userData)"
              rowClass="flex-nowrap"
              fontSizeImg="16p"
            />
          </v-col>
          <v-col v-if="!['', null, undefined].includes(evaluationDate)" class="flex-grow-0 text-no-wrap">
            <span
              class="font14 textInter"
              style="color: rgba(67, 66, 93, 0.49)"
            >
              {{
                $t("atForDate") + " " + evaluationDate
              }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="!hideAvgRate"
        class="font14 text-no-wrap flex-grow-0"
        align="right"
      >
        <span color="#000000" class="font14"> {{ $t("ratingAvg") }}</span>
        <v-icon
          class="pl-2 pr-1 orangeText"
          size="17"
          style="padding-bottom: 3px"
        >
          mdi-star-outline
        </v-icon>
        <span class="textDecorationBold font14 orangeText">{{
          userData.customer_evaluation.avgRate
        }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="textInterBold font14 orangeText" align="left">
        <span>{{ headerTitle }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        v-for="(d, i) in ratesArray"
        :key="'cus-eval-' + i"
      >
        <v-row v-if="d.title || d.textVal ? true : false">
          <v-col class="flex-grow-0 pr-0 pb-0">
            <div class="rounded-circle circleCustom font14 textInterBold">
              {{ i + 1 }}
            </div>
          </v-col>
          <v-col class="pb-0" style="padding-top: 9px" align="left">
            <span class="textInterBold primaryColor font14">
              {{ $t(d.question) }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="d.title || d.textVal ? true : false">
          <v-col class="flex-grow-0 pl-5 pt-0">
            <span class="font14 textInter goldText"> A: </span>
          </v-col>
          <v-col
            class="pt-0 goldText"
            style="padding-left: 2px !important"
            align="left"
          >
            <span class="font13 textInterMedium cusTab">
                {{ ![null,undefined,''].includes(d.title) ? $t(d.title) : '' }}  
                {{  ![null,undefined,''].includes(d.textVal) && ![null,undefined,''].includes(d.title) ? ', ' : '' }}
                {{ ![null,undefined,''].includes(d.textVal) ? d.textVal : '' }}  
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col class="flex-grow-0">
            <v-menu offset-x>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  class="font13 btn primaryColor"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  disabled
                  >{{ $t("send") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group v-model="menu" return-object color="primary">
                  <v-list-item v-for="item in items" :key="item.id" link>
                    <v-list-item-title
                      object-return
                      disabled
                      @click="onSend(item)"
                    >
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
          <v-col align="left">
            <v-btn class="btn primaryColor" outlined @click="asPdf" disabled
              >{{ $t("exportAsPdf") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col align="right">
        <v-btn
          v-if="allowEvaluation"
          class="font13 btn-custom"
          style="background-color: #263573 !important"
          @click="onEdit"
        >
          <span class="white--text"> {{ $t("edit") }} </span>
          </v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
/* 
  Name:DemandReviewCard
  Description: A component that displays image, name, ratings and Review of user. Also contains action buttons 
    
    API Property:
    userData: Object containing all the  data to be rendered on the component . For example:
                    { name: "Ioannis", surname: "Katsarakis", img: "/uploads/images/image1.png" }
    sendHandler:  Action button for send button,
    editHandler:  Action button for edit button,
    exportAsPdfHandler:  Action button for exsport as pdf button,
    firstGroup: Boolean to show the first group of answers (the first 6 questions) otherwise will display the rest of the questions
    hideAvgRate: Boolean to hide the avarage rate
    headerTile: String for the header title
    allowEvaluation: Boolean if user can edit/create evaluation          

    Components Used:
      AvatarField 
*/

import AvatarField from "@/components/Fields/AvatarField/AvatarField";
export default {
  name: "DemandReviewCard",
  props: {
    headerTitle: String,
    userData: Object,
    firstGroup: { type: Boolean, default: false },
    hideAvgRate: { type: Boolean, default: false },
    sendHandler: Function,
    editHandler: Function,
    exportAsPdfHandler: Function,
    allowEvaluation: { type: Boolean, default: true }, // If user can edit/create evaluation
  },
  components: {
    AvatarField,
  },
  data() {
    return {
      items: [
        { title: "byEmail" },
        { title: "bySms" },
        { title: "exportAsLink" },
      ],
      menu: null,
    };
  },
  computed: {
    evaluationDate() {
      return this.userData?.customer_evaluation?.updatedDate ? this.userData?.customer_evaluation?.updatedDate : null ;
    },
    ratesArray() {
      let arr = [];
      if (this.firstGroup) {
        for (let i = 1; i <= 5; i++) {
          arr.push(this.userData.customer_evaluation[i]);
        }
      } else {
        for (let i = 6; i <= 16; i++) {
          arr.push(this.userData.customer_evaluation[i]);
        }
      }
  
      return arr;
    },
  },
  methods: {
    onEdit() {
      this.editHandler();
    },
    onSend(data) {
      this.sendHandler(data);
    },
    asPdf() {
      this.exportAsPdfHandler();
    },
    getPhoto(obj) {
      if (obj.img) {
        return obj.img;
      } else {
        if (obj.FirstName && obj.LastName) {
          return obj.FirstName.charAt(0) + obj.LastName.charAt(0);
        } else if (obj.FirstName) {
          return obj.FirstName.charAt(1);
        } else if (obj.LastName) {
          return obj.LastName.charAt(1);
        }
        return "";
      }
    },
  },
};
</script>
<style scoped>
.circleCustom {
  height: 23px !important;
  width: 23px !important;
  font-size: 14px;
  color: #ffffff;
  background-color: #263573;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btn {
  text-transform: unset !important;
  color: #263573 !important;
  background-color: white;
  font-weight: 500 !important;
  border-color: rgba(112, 112, 112, 0.39);
  border-radius: 0;
  height: 25px !important;
}

.btn-custom {
  text-transform: capitalize !important;
  font-weight: 500 !important;
  font-size: 13px;
  border-radius: 0;
  color: white !important;
  background-color: #263573 !important;
  height: 25px !important;
}
</style>
