 <template>
  <v-col>
    <v-row class="main-row">
      <v-col align="left" class="pb-0">
        {{ this.content }}
      </v-col>
    </v-row>
    <v-row class="mt-0 align-center">
      <v-col class="left-col">
        <v-row>
          <v-col class="d-flex justify-start align-center pr-0">
            <span class="successColor text-decoration-underline text-no-wrap">
              {{ "@" + createdBy }}
            </span>
            &nbsp;
            <span class="dateClass"> {{ created }} </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pl-0 pr-0 right-col">
        <v-row class="justify-end mr-0 ml-0">
          <v-col class="flex-grow-0 pl-0 pr-0">
            <v-btn icon @click="onView" v-if="supportView">
              <v-icon class="primaryColorOnHover" v-if="visibility != 'public'"> mdi-eye-off </v-icon>
              <v-icon class="primaryColorOnHover" v-else> mdi-eye </v-icon>
            </v-btn>
          </v-col>
          <v-col class="flex-grow-0 pl-0 pr-0" v-if="supportEdit">
            <v-btn icon @click="onEdit">
              <v-icon class="primaryColorOnHover"> mdi-pencil-outline </v-icon>
            </v-btn>
          </v-col>
          <v-col class="flex-grow-0 pl-0 pr-0" v-if="supportDelete">
            <v-btn icon @click="onDelete">
              <v-icon class="primaryColorOnHover"> mdi-delete-outline </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template> 
 <script>
/*
 Name:CommentCard
 Description: Component to render a card with comments. Also supports delete and edit.

 API Property:
    id: String, Number or Object id
    content: String content of comment
    createdBy: String which contains who created the comment
    createdDate: String or Number created date for comment
    visibility: String visibility of comment
    supportEdit: Boolean with true show button ,false hide
    supportDelete: Boolean with true show button ,false hide
    supportView: Boolean with true show button ,false hide
    handleEdit: Function for edit
    handleDelete: Function for delete
    handleView: Function for view
*/

import CoreHelper from "@/helpers/Core/core";
export default {
  name: "CommentCard",
  props: {
    id: [String, Number, Object],
    content: String, //content of comment
    createdBy: String, //who created the comment
    createdDate: [String, Number], //created date for comment
    visibility: [String], //Visibility of comment
    supportEdit: Boolean, //true show button ,false hide
    supportDelete: Boolean, //true show button ,false hide
    supportView: Boolean, //true show button ,false hide
    handleEdit: Function, //function for edit
    handleDelete: Function, //function for delete
    handleView: Function, //function for view
  },
  computed: {
    created() {
      return isNaN(this.createdDate)
        ? this.createdDate
        : CoreHelper.EpochToDate(this.createdDate);
    },
  },
  methods: {
    onEdit() {
      this.handleEdit(this.id);
    },
    onDelete() {
      this.handleDelete(this.id);
    },
    onView() {
      this.handleView(this.id);
    },
  },
};
</script> 

 <style scoped>
 .dateClass {
   color: #a3a2b0;
 }
 
 .main-row {
   color: #263573;
 }
 
 @media screen and (max-width: 1450px) {
   .right-col {
     flex: 0 0 100%;
     max-width: 100%;
     padding-top: 0px;
     padding-bottom: 0px;
   }
 
   .left-col {
     padding-bottom: 0px;
   }
 }
 </style> 
