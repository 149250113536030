<template>
  <v-col>
    <v-row>
      <v-col align="center" class="pb-0">
        <v-img
          :src="require('@/assets/general/notRightSearch.png')"
          height="274"
          width="327"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="primaryColor textDecorationBold font20">
          {{ $t("nothingHere") }}
        </span>
      </v-col>
      <v-col cols="12 pt-0">
        <span class="primaryColor textFontDecoration font14">
          {{ $t("notRightSearch") }}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="showBtn">
      <v-col>
        <v-btn
          class="btn-custom"
          @click="btnClickedHandler"
          key="fBtn0"
          outlined
          color="primary"
        >
          {{ ![null,undefined,''].includes(btnTxt) ? btnTxt : '' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
/*
 Name: NoData
 Description: Component for no data display when there is not data available

 API Property:
    showBtn: Boolean to show or not a button
    btnTxt: String for button text display
    btnClicked: Function on button clicked
*/
export default {
  name: "NoData",
  props: {
    showBtn: { type: Boolean, default: false },
    btnTxt: String,
    btnClicked: Function,
  },
  methods: {
    btnClickedHandler() {
      this.btnClicked();
    },
  },
};
</script>