<template>
    <v-card class="pa-4" :elevation="0">
        <v-col>
            <v-row>
                <v-col class="pb-0 text-no-wrap">
                    <HeaderTitle :title="$t('loadUserCoins')" fontSizeTitle="20px"
                        titleClass=" primaryColor text-no-wrap" fontFamilyTitle="CenturyGothicBold" />
                </v-col>
                <v-col align="left" class="mt-2 mr-8">
                    <Icon icon="fa-solid:coins" height="23" color="#e89008" />
                </v-col>
            </v-row>
            <v-row class="mt-0 pb-6">
                <v-col cols="12">
                    <AvatarField :data="getPhoto(data.basicInfo)" :isImg="data.basicInfo.isImg ? true : false" :showName="true"
                        :nameContent="(data.basicInfo.FirstName ? data.basicInfo.FirstName + ' ' : '') + (data.basicInfo.LastName ? data.basicInfo.LastName : '')"
                        fontSizeName="14px" :size="56" nameContentClass="textDecorationBold primaryColor"
                        fontSizeImg="14px" backgroundColor="#263573"/> 
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-0">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-row class="align-center justify-start">
                        <v-col class="flex-grow-0" align="left">
                            <v-img size="30" :src="firstProvider.img" />
                        </v-col>
                        <v-col class="pl-0 align-self-center" align="left">
                            <span style="text-transform: none !important"> {{ firstProvider.name }} </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="flex-grow-0 pr-5" style="min-width:130px;">
                    <MinusPlusField fieldName="coins" :val="availableAdsNewDeal" :incrementBy="incrementBy"
                        valClass="font14 primaryColor" :disableInput="true"
                        :plusMinusChangeHandler="(i) => availableAdsNewDeal = i.textFieldValue" />
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col cols="12">
                    <v-text-field v-model="commentAdsNewDeal" :label="$t('reasonCoins') + '...'" clearable outlined
                        dense color="primary" class="inputLabelCustom" hide-details="auto" />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="pa-0">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-row class="align-center justify-start">
                        <v-col class="flex-grow-0" align="left">
                            <v-img size="30" :src="secondProvider.img" />
                        </v-col>
                        <v-col class="pl-0 align-self-center" align="left">
                            <span style="text-transform: none !important"> {{ secondProvider.name }} </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="flex-grow-0 pr-5" style="min-width:130px;">
                    <MinusPlusField fieldName="coins" :val="availableAdsSpitogatos" :incrementBy="incrementBy"
                        valClass="font14 primaryColor" :disableInput="true"
                        :plusMinusChangeHandler="(i) => availableAdsSpitogatos = i.textFieldValue" />
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col cols="12">
                    <v-text-field v-model="commentAdsSpitogatos" :label="$t('reasonCoins') + '...'" clearable outlined
                        dense color="primary" class="inputLabelCustom" hide-details="auto" />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="pa-0">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-row class="align-center justify-start">
                        <v-col class="flex-grow-0" align="left">
                            <v-img size="30" :src="thirdProvider.img" />
                        </v-col>
                        <v-col class="pl-0 align-self-center" align="left">
                            <span style="text-transform: none !important"> {{ thirdProvider.name }} </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="flex-grow-0 pr-5" style="min-width:130px;">
                    <MinusPlusField fieldName="coins" :val="availableAdsXE" :incrementBy="incrementBy"
                        valClass="font14 primaryColor" :disableInput="true"
                        :plusMinusChangeHandler="(i) =>  availableAdsXE = i.textFieldValue" />
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col cols="12">
                    <v-text-field v-model="commentAdsXE" :label="$t('reasonCoins') + '...'" clearable outlined dense
                        color="primary" class="inputLabelCustom" hide-details="auto" />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-0">
                    <v-divider />
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-col>
                    <v-row class="align-center justify-start">
                        <v-col class="flex-grow-0" align="left">
                            <v-img size="30" :src="fourthProvider.img" />
                        </v-col>
                        <v-col class="pl-0 align-self-center" align="left">
                            <span style="text-transform: none !important"> {{ fourthProvider.name }} </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="flex-grow-0 pr-5" style="min-width:130px;">
                    <MinusPlusField fieldName="coins" :val="userAvatar.fourthCoins" :incrementBy="incrementBy"
                        valClass="font14 primaryColor" :disableInput="true"
                        :plusMinusChangeHandler="(i) => userAvatar.fourthCoins = i.textFieldValue" />
                </v-col>
            </v-row> -->
            <!-- <v-row class="mt-0">
                <v-col cols="12">
                    <v-text-field v-model="fourthReason" :label="$t('reasonCoins') + '...'" clearable outlined dense
                        color="primary" class="inputLabelCustom" hide-details="auto" />
                </v-col>
            </v-row> -->
            <v-row>
                <v-col class="pa-0">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row class="pt-2">
                <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <v-btn :min-width="147" :min-height="42" :max-width="147" :max-height="42"
                        class="unsetCapitalize textDecorationBold" outlined color="primary" block @click="onExitButton">
                        {{ $t("exit")}}
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <v-btn :min-width="147" :min-height="42" :max-width="147" :max-height="42"
                        class="unsetCapitalize textDecorationBold" color="error" block @click="onSaveButton" :loading="addLoading">
                        {{ $t("add")}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-card>
</template>
<script>


/*
 Name:LoadCoinsPopup
 Description: Popup component with list of sites which someone can add coins for every advertisement the user have

 API Property:
    title: Title for the Popup
    incrementBy: Number containing the increment value
    onSaveHandler: Function for the save button
    onExitHandler: Function for the exit button
    addLoading: Loader for the add pop up
    userAvatar: Object with the user data
        Example:    
        userAvatar: {
                id: 10,
                name: "Κωνσταντίνος",
                surname: "Καντιώτης",
                img: "/Uploads/EstatePhotos/1661771704678.JPG",
                firstCoins: 123,
                secondCoins: 412,
                thirdCoins: 452,
                fourthCoins: 623,
            },

    Components Used:
        HeaderTitle
        AvatarField
        Icon
        MinusPlusField
*/
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import AvatarField from '@/components/Fields/AvatarField/AvatarField.vue';
import MinusPlusField from "@/components/Fields/MinusPlusField/MinusPlusField.vue";
import { Icon } from "@iconify/vue2";
import moment from "moment";


export default {
    name: "LoadCoinsPopup",
    props: {
        title: String, //Title for the Popup
        incrementBy: { type: Number, default: 1 },
        onSaveHandler: Function, // Function for the save button
        onExitHandler: Function, // Function for the exit button
        data: Object,
        addLoading: Boolean,
    },
    components: {
        HeaderTitle,
        AvatarField,
        Icon,
        MinusPlusField,
    },
    data() {
        return {
            show: true,
            availableAdsNewDeal: null,
            availableAdsSpitogatos: null,
            availableAdsXE: null,
            commentAdsNewDeal: null,
            commentAdsSpitogatos: null,
            commentAdsXE: null,
            firstProvider: {
                name: "newdeal.gr",
                surname: "",
                img: require("@/assets/realEstateSilder/newdeal.png"),
            },

            secondProvider: {
                name: "spitogatos",
                surname: "",
                img: require("@/assets/realEstateSilder/spitogatos.png"),
            },

            thirdProvider: {
                name: "Χρυσή",
                surname: "Ευκαιρία",
                img: require("@/assets/realEstateSilder/xe.png"),
            },

            fourthProvider: {
                name: "spiti360",
                surname: "",
                img: require("@/assets/realEstateSilder/spiti360.png"),
            },
        }
    },
    watch: {
        show() {
            if (!this.show) this.onCloseHandler();
        },
    },
    created() {

        if (this.data.coinsInfo.AvailableAdsNewDeal) {
            this.availableAdsNewDeal = this.data.coinsInfo.AvailableAdsNewDeal;
        } else {
            this.availableAdsNewDeal = 0;
        }
        if (this.data.coinsInfo.AvailableAdsSpitogatos) {
            this.availableAdsSpitogatos = this.data.coinsInfo.AvailableAdsSpitogatos;
        } else {
            this.availableAdsSpitogatos = 0;
        }
        if (this.data.coinsInfo.AvailableAdsXE) {
            this.availableAdsXE = this.data.coinsInfo.AvailableAdsXE;
        } else {
            this.availableAdsXE = 0;
        }
        if (this.data.coinsInfo.CommentAdsNewDeal) {
            this.commentAdsNewDeal = this.data.coinsInfo.CommentAdsNewDeal;
        }
        if (this.data.coinsInfo.CommentAdsSpitogatos) {
            this.commentAdsSpitogatos = this.data.coinsInfo.CommentAdsSpitogatos;
        }
        if (this.data.coinsInfo.CommentAdsXE) {
            this.commentAdsXE = this.data.coinsInfo.CommentAdsXE;
        }
    },
    methods: {
        getPhoto(obj) {
            return obj.Photo ? obj.Photo
                : obj.FirstName && obj.LastName ? obj.FirstName.charAt(0) + obj.LastName.charAt(0)
                    : obj.FirstName ? obj.FirstName.charAt(1)
                        : obj.LastName ? obj.LastName.charAt(1)
                            : ""
        },

        onExitButton() {
            this.onExitHandler();
        },

        onSaveButton() {
            let body = {};
            if (this.availableAdsNewDeal != null)
                body['AvailableAdsNewDeal'] = this.availableAdsNewDeal;
            if (this.availableAdsSpitogatos != null)
                body['AvailableAdsSpitogatos'] = this.availableAdsSpitogatos;
            if (this.availableAdsXE != null)
                body['AvailableAdsXE'] = this.availableAdsXE;
            if (this.commentAdsNewDeal != null)
                body['CommentAdsNewDeal'] = this.commentAdsNewDeal;
            if (this.commentAdsSpitogatos != null)
                body['CommentAdsSpitogatos'] = this.commentAdsSpitogatos;
            if (this.commentAdsXE != null)
                body['CommentAdsXE'] = this.commentAdsXE;    
            body["UpdatedDay"] = moment(new Date()).unix();
            this.onSaveHandler(body);
        },
    },
};
</script>>  