<template>
  <v-row>
    <v-col cols="12" align="left" class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" align="left">
          <span class="listHeader fontOrange">
            {{ $t("DataAndFeatures") }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('Type')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('type')" :data="this.data.Type" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('Kind')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('kind')" :data="this.data.Kind" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('levels')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('levels')" :data="this.data.levels" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="this.data.floor && this.data.floors.length > 0">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('floor')" :data="
              this.data.floors && this.data.floors.length > 0
                ? $t(data.floors[0].FloorLiteral)
                : null
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('Unit')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('sqft2')" :data="this.data.Unit" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('plot_unit')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('plotAreasqm')" :data="this.data.plot_unit" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('bathrooms')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('bathrooms')" :data="this.data.bathrooms" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('wc')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            label=" W/C" :data="this.data.wc" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('balconies')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('balconies')" :data="
              this.data.balconies != undefined &&
                this.data.balconies_in_sqm != undefined
                ? this.data.balconies +
                ' (' +
                this.data.balconies_in_sqm +
                ' τ.μ.)'
                : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('kitchen_rooms')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('kitchens')" :data="this.data.kitchen_rooms" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('living_rooms')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('livingRooms')" :data="this.data.living_rooms" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('parking_spots')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('vechParking')" :data="
              this.data.parking_spots != undefined 
                ? this.data.parking_spots + ( parkingType == null  ? '' : '(' + parkingType + ')') : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('BuildYear')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('yearOfRenovation')" :data="this.data.BuildYear" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('RenovationYear')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('yearOfInnovation')" :data="this.data.RenovationYear" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('CurrentUse')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('availability')" :data="this.data.CurrentUse" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('currentUseDateFrom')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('availableFrom')" :data="this.data.currentUseDateFrom" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="this.data.energy_class && this.data.energy_class[0]">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('energyClassDescr')" :data="this.data.energy_class[0]" :isUnderline="false" />
        </v-col>
      </v-row>
      <v-row v-if="mobileBreakpoint == true && topShow.showMore != undefined" class="mt-0 justify-center">
        <v-col class="pt-0 pb-0" align="center">
          <v-btn class="primaryColor unsetCapitalize opacity1 textDecorationBold" text plain
            @click="() => (topShow.showMore = !topShow.showMore)">
            {{ topShow.showMore == true ? $t("lessCharacteristics") : $t("moreCharacteristics") }}...
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
 /*
 Name: Characteristics
 Description: Component for real estate characteristics at the slider

 API Property: 
    data: Object for the data

    Components Used:
      ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
import CoreHelper from "@/helpers/Core/core.js";
import i18n from "@/i18n/i18n";

export default {
  name: "Characteristics",
  props: ["data"],
  components: {
    ReadField,
  },
  data() {
    return {
      topArray: [
        "Type",
        "Kind",
        "levels",
        "Unit",
        "plot_unit",
        "bathrooms",
        "wc",
        "balconies",
        "kitchen_rooms",
        "living_rooms",
        "parking_spots",
        "BuildYear",
        "RenovationYear",
        "CurrentUse",
        "currentUseDateFrom",
      ],
      topShow: { showKeys: [], showMore: undefined },
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
    };
  },
  created() {
    if (this.mobileBreakpoint == true)
      this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4);
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    parkingType() {
      let parkingString = "";
      if (CoreHelper.parseBoolean(this.data.enclosed_parking) == true) {
        parkingString += i18n.t("closed") + ', ';
      }
      if (CoreHelper.parseBoolean(this.data.underground_parking) == true) {
        parkingString += i18n.t("underground") + ', ';
      }
      if (CoreHelper.parseBoolean(this.data.pilot_parking) == true) {
        parkingString += i18n.t("pilot") + ', ';
      }
      if (CoreHelper.parseBoolean(this.data.open_parking) == true) {
        parkingString += i18n.t("open") + ', ';
      }
      if(parkingString == ''){
        return null;
      }
      return parkingString.substring(0, parkingString.length - 2);
    },
  },
  methods: {
    checkShow(objKey) {
      return this.data[objKey]
        ? this.mobileBreakpoint != true
          ? true
          : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
            this.topShow.showMore != false
            ? true
            : false
        : false;
    },
  },
};
</script>

<style scoped>
.listHeader {
  color: #263573;
  font-size: 14px;
}

.fontOrange {
  color: #e89008;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
</style>
