<template >
  <v-row>
    <v-col>
      <v-row>
        <v-col v-if="displayData || data.shortBio" align="left">
          <span class="textDecorationBold font16 goldText">
            {{ $t("curriculumVitae") + ":" }}
          </span>
        </v-col>
      </v-row>
      <v-row v-if="displayData && displayData.length > 0">
        <v-col align="left" class="textDecorationBold font15 primaryColor">
          <span> {{ $t("specialization") }} </span>
          <span> {{ $tc("atLocation", (displayData.length > 1) + 1) }}: </span>
          <span> {{ displayData.join(", ") }} </span>
        </v-col>
      </v-row>
      <v-row v-if="data.shortBio">
        <v-col align="left" class="py-0 textInterMedium font13 shadyBlue">
          <p>
            {{
              mobileBreakpoint != true
                ? data.shortBio
                : data.shortBio.length > 200 && showMoreDescription == false
                ? data.shortBio.slice(0, 200) + "..."
                : data.shortBio
            }}
          </p>
        </v-col>
      </v-row>
      <v-row
        v-if="
          mobileBreakpoint == true &&
          data &&
          data.shortBio &&
          data.shortBio.length > 200
        "
      >
        <v-col class="py-0">
          <v-btn
            class="primaryColor unsetCapitalize opacity1 textDecorationBold"
            text
            plain
            @click="() => (showMoreDescription = !showMoreDescription)"
          >
            {{ showMoreDescription ? $t("less") : $t("more") }}...
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showBtn">
        <v-col cols="12" align="left">
          <v-btn @click="cvClicked" class="btn" outlined>
            {{ $t("curriculumVitae") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
/*
    Name: BioInfo
    Description: A component that displays Biography Title and text with an action button 

    API Property:
    data: Object containing BiographyInfo data 
    exanple    Data: {
                title: "Εξειδίκευση: Property Management",
                Description: "Lorem Ipsum is simply dummy text
                 of the printing and typesetting industry...
            },
    showBtn: if true button will be showned, deafault is false
*/
import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "BioInfo",
  props: {
    data: Object, // Object that contains texts for BioInfo
    showBtn: { type: Boolean, default: false }, // if true button will be showned, deafault is false
  },

  created() {
    this.displayDistricts();
  },
  data() {
    return {
      displayData: null,
      showMoreDescription: false,
    };
  },
  computed: {
    getDescription() {
      return RealEstateHelper.generateDescription(this.data);
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    displayDistricts() {
      if(this.data.title){
      this.displayData = this.data.title.map(function (item) {
        return item.split(", ");
      });
      this.displayData = this.displayData.map(function (item) {
        return item[2];
      });
          }
          return null;
    },
    cvClicked() {
      window.open(CoreHelper.getImagePath({absolutePath: false, path: this.data.CV}), '_blank');
    },
  },
};
</script>
<style scoped>
.btn {
  text-transform: unset !important;
  color: #263573 !important;
  background-color: white;
  font-weight: 500 !important;
  font-family: CenturyGothicBold !important;
}
</style>