var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","align":"left"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"left"}},[_c('span',{staticClass:"listHeader fontOrange"},[_vm._v(_vm._s(_vm.$t("additionalSpaces")))])])],1),_c('v-row',[(true == _vm.checkShow('storage_room'))?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"6","xl":"4","lg":"4","md":"6","sm":"6","xs":"6"}},[_c('ReadField',{attrs:{"fontSize":_vm.fontSizeRF,"fontFamilyLabel":_vm.fontFamilyLabel,"fontFamilyValue":_vm.fontFamilyValue,"label":_vm.$t('warehouse'),"data":_vm.parseBoolean(this.data.storage_room) == true
              ? this.data.storage_room_sqm + ' τ.μ'
              : '',"isUnderline":false}})],1):_vm._e(),(true == _vm.checkShow('attic'))?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"6","xl":"4","lg":"4","md":"6","sm":"6","xs":"6"}},[_c('ReadField',{attrs:{"fontSize":_vm.fontSizeRF,"fontFamilyLabel":_vm.fontFamilyLabel,"fontFamilyValue":_vm.fontFamilyValue,"label":_vm.$t('attic'),"data":_vm.parseBoolean(this.data.attic) == true
              ? this.data.attic_in_sqm + ' τ.μ'
              : '',"isUnderline":false}})],1):_vm._e(),(true == _vm.checkShow('service_room'))?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"6","xl":"4","lg":"4","md":"6","sm":"6","xs":"6"}},[_c('ReadField',{attrs:{"fontSize":_vm.fontSizeRF,"fontFamilyLabel":_vm.fontFamilyLabel,"fontFamilyValue":_vm.fontFamilyValue,"label":_vm.$t('serviceRoom'),"data":_vm.parseBoolean(this.data.service_room) == true
              ? this.data.service_room_in_sqm + ' τ.μ'
              : '',"isUnderline":false}})],1):_vm._e(),(true == _vm.checkShow('owned_terrace'))?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"6","xl":"4","lg":"4","md":"6","sm":"6","xs":"6"}},[_c('ReadField',{attrs:{"fontSize":_vm.fontSizeRF,"fontFamilyLabel":_vm.fontFamilyLabel,"fontFamilyValue":_vm.fontFamilyValue,"label":_vm.$t('privateTerrace'),"data":_vm.parseBoolean(this.data.owned_terrace) == true
              ? this.data.owned_terrace_in_sqm + ' τ.μ'
              : '',"isUnderline":false}})],1):_vm._e(),(true == _vm.checkShow('playroom'))?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"6","xl":"4","lg":"4","md":"6","sm":"6","xs":"6"}},[_c('ReadField',{attrs:{"fontSize":_vm.fontSizeRF,"fontFamilyLabel":_vm.fontFamilyLabel,"fontFamilyValue":_vm.fontFamilyValue,"label":_vm.$t('playroom'),"data":_vm.parseBoolean(this.data.playroom) == true
              ? this.data.playroom_in_sqm + ' τ.μ'
              : '',"isUnderline":false}})],1):_vm._e(),(true == _vm.checkShow('garden'))?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"6","xl":"4","lg":"4","md":"6","sm":"6","xs":"6"}},[_c('ReadField',{attrs:{"fontSize":_vm.fontSizeRF,"fontFamilyLabel":_vm.fontFamilyLabel,"fontFamilyValue":_vm.fontFamilyValue,"label":_vm.$t('garden'),"data":_vm.parseBoolean(this.data.garden) == true
              ? this.data.garden_in_sqm + ' τ.μ'
              : '',"isUnderline":false}})],1):_vm._e(),(true == _vm.checkShow('pool'))?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"6","xl":"4","lg":"4","md":"6","sm":"6","xs":"6"}},[_c('ReadField',{attrs:{"fontSize":_vm.fontSizeRF,"fontFamilyLabel":_vm.fontFamilyLabel,"fontFamilyValue":_vm.fontFamilyValue,"label":_vm.$t('pool'),"data":_vm.parseBoolean(this.data.pool) == true
              ? this.data.pool_in_sqm + ' τ.μ'
              : '',"isUnderline":false}})],1):_vm._e()],1),(_vm.mobileBreakpoint == true && _vm.topShow.showMore != undefined)?_c('v-row',{staticClass:"mt-0 justify-center"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"align":"center"}},[_c('v-btn',{staticClass:"primaryColor unsetCapitalize opacity1 textDecorationBold",attrs:{"text":"","plain":""},on:{"click":function () { return (_vm.topShow.showMore = !_vm.topShow.showMore); }}},[_vm._v(" "+_vm._s(_vm.topShow.showMore == true ? _vm.$t("lessInfo") : _vm.$t("moreInfo"))+"... ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }