<template>
  <v-col class="pt-0" >
    <v-card
      outlined
      class="px-3 py-0"
      :max-width="90"
      :style="
        'background-color:transparent !important; display: flex; justify-content: center;' +
        (titleBox ? 'cursor: pointer;' : ' ') +
        'border:' +
        (titleBox ? '1' : '0') +
        'px solid; border-color:' +
        boxBorderColor +
        ' ;border-radius: 0px;'
      "
      :color="boxColor"
    >
      <span
        :class="titleClass"
        :style="hoverStyle"
        @mouseover="mouseOver()"
        @mouseleave="mouseOver()"
      >
        {{ title }}
      </span>
    </v-card>
  </v-col>
</template>

<script>
/*
 Name:ClickableBox
 Description: Component with a clickable box with title, color, size and on click, hover functions

 API Property:
 title: Title for the box
 titleSize: Title size for the title in the box
 titleClass: Title class for the title in the box
 titleColor: Title color for the title in the box
 titleOnHoverColor: Title on hover color for the title in the box
 boxColor: Box background color
 titleBox: If we want the box to be outlined (true/false)
 boxBorderColor: Box border color
*/

export default {
  name: "ClickableBox",
  props: {
    title: { type: String, default: "--" }, // Title for the box
    titleSize: { type: Number, default: 14 }, // Title size for the title in the box
    titleClass: { type: String, default: "textDecorationBold text-no-wrap" }, // Title class for the title in the box
    titleColor: { type: String, default: "" }, // Title color for the title in the box
    titleOnHoverColor: String, // Title on hover color for the title in the box
    boxColor: String, // Box background color
    titleBox: { type: Boolean, default: true }, // If we want the box to be outlined (true/false)
    boxBorderColor: { type: String, default: "#9b9b9b" }, // Box border color
  },
  data() {
    return {
      hovering: false,
    };
  },
  computed: {
    hoverStyle() {
      return (
        "font-size:" +
        this.titleSize +
        "px;  color: " +
        (this.hovering ? this.titleOnHoverColor : this.titleColor)
      );
    },
  },
  methods: {
    mouseOver() {
      this.hovering = !this.hovering;
    },
  },
};
</script>