<template>
  <v-card>
    <v-card-title>
      <v-col class="pa-0">
        <v-row>
          <v-col class="pb-0">
            <HeaderTitle
              :title="$tc('contacts', 1)"
              :subTitle="$t('newContact') + ' ' + subTitle"
            />
          </v-col>
          <v-col class="flex-grow-0">
            <v-btn depressed icon color="black" class="ml-auto" @click="close">
              <v-icon class="primaryColorOnHover"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col
            v-if="parent == 'estate'"
            class="flex-grow-0 pt-0"
            @click="openDialog('upload')"
            style="cursor: pointer"
          >
            <v-avatar :size="131">
              <v-img :size="131" :src="imgUrl"> </v-img>
            </v-avatar>
          </v-col>
          <v-col class="align-self-center">
            <v-combobox
              v-model="tags"
              multiple
              solo
              flat
              deletable-chips
              hide-no-data
              chips
              label="tags"
              append-icon="mdi-plus-box-outline"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col align="right" class="pt-0 pb-0">
            <v-btn
              elevation="5"
              align="center"
              :loader="loader"
              @click="onSubmit"
              style="text-transform: unset !important"
              color="error"
            >
              {{ $t("save") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ml-n6 mr-n6">
          <v-col class="pl-0 pr-0">
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="pb-0" align="left">
            <span style="color: #253472" class="textDecorationBold">
              {{ $t("basicInfo") }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1">
            <v-checkbox v-model="IsCompanyOrOrganization">
              <template v-slot:label>
                <span class="inputLabelCustom">
                  {{ $t("itsBusOrg") }}
                </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row class="mt-0" v-if="IsCompanyOrOrganization == true">
          <v-col class="pt-1 pb-1" cols="12" xs="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="busOrgName"
              :label="$t('busOrgName') + '*'"
              clearable
              :error-messages="busOrgNameErrors"
              color="primary"
              class="inputLabelCustom"
              hide-details="auto"
            />
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" xs="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="chamberNo"
              :label="$t('chamberNo')"
              clearable
              color="primary"
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-1 pb-1" cols="12" xs="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="FirstName"
              :label="$t('firstname') + '*'"
              clearable
              :error-messages="firstnameErrors"
              color="primary"
              class="inputLabelCustom"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" xs="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="LastName"
              :label="$t('lastname') + '*'"
              :error-messages="surnameErrors"
              required
              clearable
              hide-details="auto"
              class="inputLabelCustom"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0" v-if="IsCompanyOrOrganization == false">
          <v-col class="pt-1 pb-1" cols="12" xs="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="FatherName"
              :label="$t('fathersName')"
              clearable
              color="primary"
              class="inputLabelCustom"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col
            class="pt-1 pb-1"
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="6"
            v-if="parent == 'estate'"
          >
            <v-text-field
              v-model="Profession"
              :label="$t('profession')"
              required
              clearable
              hide-details="auto"
              class="inputLabelCustom"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0" v-if="IsCompanyOrOrganization == true">
          <!-- <v-col class="pt-1 pb-1" cols="12" xs="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="responsiblePerson"
              :label="$t('responsiblePerson') + '*'"
              :error-messages="responsiblePersonErrors"
              required
              clearable
              hide-details="auto"
              class="inputLabelCustom"
            />
          </v-col> -->

          <v-col class="pt-1 pb-1" cols="12" v-if="parent == 'estate'">
            <v-text-field
              v-model="busOrgDescription"
              :label="$t('description')"
              required
              clearable
              hide-details="auto"
              class="inputLabelCustom"
            />
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9 mt-7">
          <v-col class="pl-0 pr-0 pt-5 pb-5">
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" align="left">
            <span style="color: #253472" class="textDecorationBold">
              {{ $t("contactInfo") }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Email"
              :label="$t('email') + '*'"
              clearable
              :error-messages="emailErrors"
              color="primary"
              class="inputLabelCustom"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Email2"
              :label="$t('email') + ' 2'"
              :error-messages="email2Errors"
              clearable
              hide-details="auto"
              class="inputLabelCustom"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Mobile"
              :label="$t('telCom') + '*'"
              clearable
              :error-messages="mobileErrors"
              type="number"
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Telephone"
              :label="$t('otherTel')"
              clearable
              type="number"
              color="primary"
              hide-details="auto"
              :error-messages="telephoneErrors"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <!-- <v-text-field
              v-model="otherContactDetails"
              :label="$t('otherContactInfo')"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field> -->
            <v-combobox
              v-model="otherContactDetails"
              multiple
              flat
              deletable-chips
              hide-no-data
              chips
              :label="$t('otherContactInfo')"
              append-icon=""
              append-icon-cb=""
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9 mt-5">
          <v-col class="pl-0 pr-0 pt-5 pb-5">
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row class="mt-0" v-if="parent == 'estate'">
          <v-col align="left">
            <span style="color: #253472" class="textDecorationBold">
              {{ $t("socialMedia") }}
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-0" v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Website"
              label="Website"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="FB"
              label="facebook"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Instagram"
              label="instagram"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="LinkedIn"
              label="linkedIn"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Twitter"
              label="twitter"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <!-- <v-text-field
              v-model="otherSocialHandles"
              :label="$t('other') + '...'"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field> -->
            <v-combobox
              style="padding-top: 2px !important"
              v-model="otherSocialHandles"
              multiple
              flat
              deletable-chips
              hide-no-data
              chips
              :label="$t('other') + '...'"
              append-icon=""
              append-icon-cb=""
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9 mt-5" v-if="parent == 'estate'">
          <v-col class="pl-0 pr-0 pt-5 pb-5">
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" align="left">
            <span style="color: #253472" class="textDecorationBold">
              {{ $t("addressInfo") }}
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="6" lg="6">
            <Location
              :placeholder="$t('typeAddrNo') + '...'"
              :vuexProps="false"
              :setLocation="setACLocation"
              iconName="mdi-map-marker-outline"
              iconColor="primary"
            />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="gps"
              :label="$t('exactLocation')"
              clearable
              color="primary"
              hide-details="auto"
            />
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <!-- <v-select
              v-model="Country"
              :items="countries"
              :label="$t('greece')"
              item-text="text"
              item-value="value"
            >
            </v-select> -->
            <v-combobox
              style="padding-top: 2px !important"
              v-model="Country"
              flat
              :label="$t('greece')"
              :items="countries"
              item-text="text"
              item-value="value"
              append-icon=""
              append-icon-cb=""
            >
            </v-combobox>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <!-- <v-select
              v-model="Neighborhood"
              :items="areas"
              :label="$t('regions') + '*'"
              item-text="text"
              item-value="value"
            >
            </v-select> -->

            <v-combobox
              style="padding-top: 2px !important"
              v-model="Neighborhood"
              flat
              :label="$t('regions')"
              :items="areas"
              item-text="text"
              item-value="value"
              append-icon=""
              append-icon-cb=""
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <!-- <v-select
              v-model="City"
              :items="countries"
              :label="$t('municipality') + '*'"
              item-text="text"
              item-value="value"
            >
            </v-select> -->
            <v-combobox
              v-model="City"
              flat
              :label="$t('municipality')"
              :items="countries"
              item-text="text"
              item-value="value"
              append-icon=""
              append-icon-cb=""
            >
            </v-combobox>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Street"
              :label="$t('addressNumber2')"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="Zip"
              :label="$t('postalCode')"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col
            class="pt-1 pb-1"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            v-if="parent == 'estate'"
          >
            <!-- <v-text-field
              v-model="otherAddressDetails"
              :label="$t('otherAddressInfo')"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field> -->
            <v-combobox
              style="padding-top: 2px !important"
              v-model="otherAddressDetails"
              multiple
              flat
              deletable-chips
              hide-no-data
              chips
              :label="$t('otherAddressInfo')"
              append-icon=""
              append-icon-cb=""
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9 mt-6" v-if="parent == 'estate'">
          <v-col class="pl-0 pr-0 pt-6">
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col align="left" cols="12">
            <span class="textDecorationBold" style="color: #253472">
              {{ $t("morePersonalInfo") }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="birthdayFormatted"
                  :label="$t('dateOfBirth')"
                  clearable
                  color="primary"
                  hide-details="auto"
                  @blur="Birthday = parseDate(birthdayFormatted, 'Birthday')"
                >
                  <template v-slot:append>
                    <v-icon v-bind="attrs" v-on="on"> mdi-calendar </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="Birthday"
                scrollable
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-menu
              ref="menu"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="nameDayFormatted"
                  :label="$t('nameDay')"
                  clearable
                  color="primary"
                  hide-details="auto"
                  @blur="Nameday = parseDate(nameDayFormatted, 'Nameday')"
                >
                  <template v-slot:append>
                    <v-icon v-bind="attrs" v-on="on"> mdi-calendar </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="Nameday"
                scrollable
                @input="menu1 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="AFM"
              :label="$t('vatNumber')"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="DOY"
              :label="$t('publFinanceService')"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="personalId"
              :label="$t('idPassp')"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <!-- <v-text-field
              v-model="otherField"
              :label="$t('otherField')"
              clearable
              color="primary"
              hide-details="auto"
            >
            </v-text-field> -->

            <v-combobox
              style="padding-top: 2px !important"
              v-model="otherField"
              multiple
              flat
              deletable-chips
              hide-no-data
              chips
              :label="$t('otherField')"
              append-icon=""
              append-icon-cb=""
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9 mt-6" v-if="parent == 'estate'">
          <v-col class="pl-0 pr-0 pt-6">
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col cols="12" align="left">
            <span class="textDecorationBold" style="color: #253472">
              {{ $t("contactOrigin") }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-select
              v-model="Source"
              :items="getRefferalList"
              :label="$t('chooseOrigin') + '...'"
              style="color: #253472"
              item-text="text"
              clearable
              item-value="value"
            >
              <template v-slot:selection="{ item }">
                <v-row class="flex-grow-0">
                  <v-col style="display: flex; align-items: center">
                    {{ $t(item.text) }}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item="{ item }">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col style="display: flex; align-items: center">
                        {{ $t(item.text) }}
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9 mt-6" v-if="parent == 'estate'">
          <v-col class="pl-0 pr-0 pt-6">
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col align="left" cols="12">
            <span class="textDecorationBold" style="color: #253472">
              {{ $t("comments") }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="12" lg="12">
            <v-textarea
              :label="$t('writeMoreContactInfo') + '...'"
              v-model="Comments"
              class="mt-4"
              outlined
              rows="4"
              row-height="15"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9 mt-6" v-if="parent == 'estate'">
          <v-col class="pl-0 pr-0 pt-6">
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col cols="12" align="left">
            <span style="color: #253472" class="textDecorationBold">
              {{ $t("contactRights") }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="parent == 'estate'">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="12" lg="12">
            <v-checkbox v-model="accountCreate">
              <template v-slot:label>
                <span class="inputLabelCustom">
                  {{ $t("createSysAccount") }}
                </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mt-0" v-if="accountCreate">
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="12" lg="12">
            <v-text-field
              v-model="UserName"
              :label="$t('username') + '*'"
              clearable
              color="primary"
              :error-messages="usernameErrors"
              class="inputLabelCustom"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              hide-details="auto"
              v-model="password"
              :error-messages="passwordErrors"
              :label="$t('password') + '*'"
              @click:append="show = !show"
            >
            </v-text-field>
          </v-col>
          <v-col class="pt-1 pb-1" cols="12" sm="12" md="6" lg="6">
            <v-text-field
              :append-icon="showConf ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConf ? 'text' : 'password'"
              hide-details="auto"
              v-model="confirmPass"
              :error-messages="passwordConfErrors"
              :label="$t('confPass') + '*'"
              @click:append="showConf = !showConf"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="dialog.open" v-if="dialog.type == 'upload'" class="pa-6">
      <Upload
        class="pa-6"
        :onlyImgRestrict="true"
        :singularUpload="true"
        :closeHandler="closeDialog"
        :submitHandler="setImage"
      />
    </v-dialog>
  </v-card>
</template>

<script>
/*
Name:Contact
Description: Component to render a pop up to create a contact

API Property:
    subTitle: String for the sub title 
    parent: String to set the parent kind component
    type: String for the contact type
    closeHandler: Function to close the pop up
    searchOwner: Function to search owners 

    Components Used:
      HeaderTitle
      Upload
      Location 

    Endpoints Functions Called:
    -ContactsRepo
      createContactWithPass
      createContact
      uploadContactPhoto
*/
import { mapGetters, mapState /*, mapActions*/ } from "vuex";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import Repository from "@/services/Repository";
import Upload from "@/components/Upload/Upload.vue";
import i18n from "@/i18n/i18n";
import Location from "@/components/Maps/Location.vue";

import { required, sameAs, email, requiredIf } from "vuelidate/lib/validators";

const ContactsRepo = Repository.get("contacts");

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};
export default {
  name: "Contact",
  props: {
    subTitle: String,
    parent: String, // Parent name
    type: String,
    closeHandler: Function,
    searchOwner: Function,
  },
  components: {
    HeaderTitle,
    Upload,
    Location,
  },
  data() {
    return {
      loader: false,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      image: null,
      menu: false,
      menu1: false,
      tags: [],
      show: false,
      showConf: false,
      IsCompanyOrOrganization: false,
      busOrgName: "",
      responsiblePerson: "",
      chamberNo: "",
      busOrgDescription: "",
      FirstName: null,
      LastName: null,
      FatherName: null,
      Profession: null,
      Email: null,
      Email2: null,
      Mobile: null,
      Telephone: null,
      otherContactDetails: null,
      Website: null,
      FB: null,
      Instagram: null,
      LinkedIn: null,
      Twitter: null,
      otherSocialHandles: null,
      Country: null,
      Neighborhood: null,
      City: null,
      Street: null,
      Zip: null,
      otherAddressDetails: null,
      gps: [],
      Birthday: null,
      birthdayFormatted: null,
      Nameday: null,
      nameDayFormatted: null,
      AFM: null,
      DOY: null,
      personalId: null,
      otherField: null,
      Source: null,
      Comments: null,
      accountCreate: false,
      password: null,
      confirmPass: null,
      countries: [],
      areas: [],
      UserName: null,
    };
  },
  validations() {
    const self = this;
    return {
      FirstName: {
        required: requiredIf(function () {
          return self.IsCompanyOrOrganization == false;
        }),
      },
      LastName: {
        required: requiredIf(function () {
          return self.IsCompanyOrOrganization == false;
        }),
      },
      busOrgName: {
        required: requiredIf(function () {
          return self.IsCompanyOrOrganization == true;
        }),
      },
      // responsiblePerson: {
      //   required: requiredIf(function () {
      //     return self.IsCompanyOrOrganization == true;
      //   }),
      // },
      Email: {
        required,
        email,
      },
      Email2: {
        email,
      },
      Mobile: {
        required,
      },
      Telephone: {
        required,
      },
      UserName: {
        required: requiredIf(function () {
          return self.accountCreate;
        }),
      },
      password: {
        required: requiredIf(function () {
          return self.accountCreate;
        }),
      },
      confirmPass: {
        required: requiredIf(function () {
          return self.accountCreate;
        }),
        sameAsPassword: sameAs("password"),
      },
    };
  },
  computed: {
    ...mapGetters(["getRefferalList"]),
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    firstnameErrors() {
      const errors = [];
      if (!this.$v.FirstName.$dirty) return errors;
      if (!this.$v.FirstName.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.LastName.$dirty) return errors;
      if (
        (!this.$v.LastName.required && this.parent == "estate") ||
        (!this.$v.LastName.required &&
          this.parent != "estate" &&
          this.IsCompanyOrOrganization != true)
      )
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    busOrgNameErrors() {
      const errors = [];
      if (!this.$v.busOrgName.$dirty) return errors;
      if (!this.$v.busOrgName.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    // responsiblePersonErrors() {
    //   const errors = [];
    //   if (!this.$v.responsiblePerson.$dirty) return errors;
    //   if (!this.$v.responsiblePerson.required)
    //     errors.push(i18n.t("requiredField"));
    //   return errors;
    // },
    emailErrors() {
      const errors = [];
      if (!this.$v.Email.$dirty) return errors;
      if (!this.$v.Email.required) errors.push(i18n.t("requiredField"));
      if (!this.$v.Email.email) errors.push(i18n.t("validEmail"));
      return errors;
    },
    email2Errors() {
      const errors = [];
      if (!this.$v.Email2.$dirty) return errors;
      if (!this.$v.Email2.email) errors.push(i18n.t("validEmail"));
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.Mobile.$dirty) return errors;
      if (!this.$v.Mobile.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    telephoneErrors() {
      const errors = [];
      if (!this.$v.Telephone.$dirty) return errors;
      if (!this.$v.Telephone.required && this.parent == "estate")
        errors.push(i18n.t("requiredField"));
      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.UserName.$dirty) return errors;
      if (!this.$v.UserName.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    passwordConfErrors() {
      const errors = [];
      if (!this.$v.confirmPass.$dirty) return errors;
      if (!this.$v.confirmPass.required) errors.push(i18n.t("requiredField"));
      if (!this.$v.confirmPass.sameAsPassword)
        errors.push(i18n.t("noMatchPassword"));
      return errors;
    },
    imgUrl() {
      if (!this.image) return require("@/assets/contact/upload.png");
      return URL.createObjectURL(this.image);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "Birthday") {
          this.Birthday = null;
          this.birthdayFormatted = null;
        } else if (dateName == "Nameday") {
          this.Nameday = null;
          this.nameDayFormatted = null;
        }
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async onSubmit() {
      if (this.Telephone == null || this.Telephone.trim() == "") {
        this.Telephone = this.Mobile;
      }
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      this.loader = true;
      let body = {};

      if (this.tags) body.tags = this.tags; 
      if (this.FirstName) body.FirstName = this.FirstName; 
      if (this.LastName) body.LastName = this.LastName; 
      if (this.busOrgName) body.organizationName = this.busOrgName;
      if (this.chamberNo) body.chamberNo = this.chamberNo;
      if (this.busOrgDescription)
        body.organizationDescription = this.busOrgDescription;
      if (this.FatherName) body.FatherName = this.FatherName; 
      if (this.Profession) body.Profession = this.Profession; 
      if (this.Email) body.Email = this.Email; 
      if (this.Email2) body.Email2 = this.Email2; 
      if (this.Mobile) body.Mobile = this.Mobile; 
      if (this.Telephone) body.Telephone = this.Telephone; 
      if (this.otherContactDetails)
        body.other_contact_details = this.otherContactDetails; 
      if (this.Website) body.Website = this.Website; 
      if (this.FB) body.FB = this.FB; 
      if (this.Instagram) body.Instagram = this.Instagram; 
      if (this.LinkedIn) body.LinkedIn = this.LinkedIn; 
      if (this.Twitter) body.Twitter = this.Twitter; 
      if (this.otherSocialHandles)
        body.other_social_handles = this.otherSocialHandles; 
      if (this.Country) body.Country = this.Country; 
      if (this.City) body.City = this.City; 
      if (this.Neighborhood) body.Neighborhood = this.Neighborhood; 
      if (this.Street) body.Street = this.splitStreet(1, this.Street); 
      if (this.Street) body.StreetNumber = this.splitStreet(2, this.Street);
      if (this.Zip) body.Zip = this.Zip; 
      if (this.otherAddressDetails)
        body.other_address_details = this.otherAddressDetails; 
      if (this.Birthday) body.Birthday = this.Birthday; 
      if (this.Nameday) body.Nameday = this.Nameday; 
      if (this.AFM) body.AFM = this.AFM; 
      if (this.DOY) body.DOY = this.DOY; 
      if (this.personalId) body.personalId = this.personalId; 
      if (this.Source) body.Source = this.Source; 
      if (this.Comments) body.Comments = this.Comments; 
      if (this.IsCompanyOrOrganization)
        body.IsCompanyOrOrganization = this.IsCompanyOrOrganization; 
      if (this.password) body.UserPassword = this.password; 
      if (this.UserName) body.UserName = this.UserName; 
      body.create_account = this.accountCreate;
      let resp;
      if (this.parent == "estate") {
        body["Company"] = this.userDetails.Company;
        let type = "Ιδιοκτήτης για " + this.type;
        body.Type = [type];
      } else if (this.parent == "company") {
        body.Role = "company";
      } else if (this.parent == "cooperation") {
        body.Role = "Host";
      } else if (
        (this.parent && this.parent == "demands") ||
        this.parent == "newInterestedContact"
      ) {
        body.Role = "";
        body.Type = [
          "Ενδιαφερόμενος για Αγορά",
          "Ενδιαφερόμενος για Ενοικίαση",
          "Ενδιαφερόμενος με Αντιπαροχή",
        ];
        body.LastCompany = this.userDetails.LastCompany;
      } 

      if (this.accountCreate) {
        resp = await ContactsRepo.createContactWithPass(body);
      } else {
        resp = await ContactsRepo.createContact(body);
      }
      if (
        (this.parent && this.parent == "estate") ||
        this.parent == "cooperation"
      ) {
        if (
          resp.status &&
          resp.status == 200 &&
          resp &&
          resp.data &&
          resp.data._id
        ) {
          this.searchOwner({
            _id: resp.data._id,
            fullname: this.LastName + " " + this.FirstName,
          });
        }
      }
      if (
        (this.parent && this.parent == "demands") ||
        this.parent == "newInterestedContact"
      ) {
        if (
          resp.status &&
          resp.status == 200 &&
          resp &&
          resp.data &&
          resp.data._id
        ) {
          this.searchOwner({
            id: resp.data._id,
            fullname: this.LastName + " " + this.FirstName,
          });
        }
      }
      if (this.image && resp && resp.data && resp.data._id) {
        let imageFormData = new FormData();
        imageFormData.append("file", this.image);
        imageFormData.append("visibility", true);
        await ContactsRepo.uploadContactPhoto(resp.data._id, imageFormData);
      }
      this.closeHandler();
      this.loader = false;
    },
    setImage(img) {
      if (img.length > 0) this.image = img[0].file;
      else this.image = null;
    },
    close() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
      this.image = null;
      this.menu = false;
      this.menu1 = false;
      this.tags = [];
      this.show = false;
      this.showConf = false;
      this.IsCompanyOrOrganization = null;
      this.FirstName = null;
      this.LastName = null;
      this.busOrgName = null;
      this.responsiblePerson = null;
      this.chamberNo = null;
      this.busOrgDescription = null;
      this.FatherName = null;
      this.Profession = null;
      this.Email = null;
      this.Email2 = null;
      this.Mobile = null;
      this.Telephone = null;
      this.otherContactDetails = null;
      this.Website = null;
      this.FB = null;
      this.Instagram = null;
      this.LinkedIn = null;
      this.Twitter = null;
      this.otherSocialHandles = null;
      this.Country = null;
      this.Neighborhood = null;
      this.City = null;
      this.Street = null;
      this.Zip = null;
      this.otherAddressDetails = null;
      this.Birthday = null;
      this.birthdayFormatted = null;
      this.Nameday = null;
      this.nameDayFormatted = null;
      this.AFM = null;
      this.DOY = null;
      this.personalId = null;
      this.otherField = null;
      this.Source = null;
      this.Comments = null;
      this.accountCreate = false;
      this.password = null;
      this.confirmPass = null;
      this.UserName = null;
      this.countries = [];
      this.areas = [];
      this.closeHandler();
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    splitStreet(part, street) {
      try {
        let indexed = street.indexOf(street.match(/\d/));
        if (part == 1) {
          return street.slice(0, indexed);
        } else if (part == 2) {
          return street.slice(indexed + 1);
        } else return "";
      } catch {
        return street;
      }
    },
    setACLocation(sLocationDetails) {
      if (sLocationDetails && sLocationDetails.addressExtracted) {
        this.Country = sLocationDetails.addressExtracted.country
          ? sLocationDetails.addressExtracted.country
          : this.Country;
        this.City = sLocationDetails.addressExtracted.locality
          ? sLocationDetails.addressExtracted.locality
          : this.City;
        this.Neighborhood = sLocationDetails.addressExtracted
          .administrative_area_level_1
          ? sLocationDetails.addressExtracted.administrative_area_level_1
          : sLocationDetails.addressExtracted.administrative_area_level_2
          ? sLocationDetails.addressExtracted.administrative_area_level_2
          : sLocationDetails.addressExtracted.administrative_area_level_3
          ? sLocationDetails.addressExtracted.administrative_area_level_3
          : this.Neighborhood;
        this.Street =
          (sLocationDetails.addressExtracted.route
            ? sLocationDetails.addressExtracted.route
            : this.Street) +
          (sLocationDetails.addressExtracted.postal_code
            ? " " + sLocationDetails.addressExtracted.street_number
            : "");
        this.Zip = sLocationDetails.addressExtracted.postal_code
          ? sLocationDetails.addressExtracted.postal_code
          : this.Zip;
        this.otherAddressDetails = sLocationDetails.formatted_address
          ? [sLocationDetails.formatted_address]
          : this.otherAddressDetails;
      }
      let lat =
        sLocationDetails?.geometry &&
        sLocationDetails?.geometry?.location &&
        sLocationDetails?.geometry?.location?.lat
          ? sLocationDetails?.geometry?.location?.lat()
          : null;
      let lng =
        sLocationDetails?.geometry &&
        sLocationDetails?.geometry?.location &&
        sLocationDetails?.geometry?.location?.lng
          ? sLocationDetails?.geometry?.location?.lng()
          : null;
      this.gps = lat != null && lng != null ? lat + "," + lng : ",";
    },
  },
  watch: {
    Birthday() {
      this.birthdayFormatted = this.formatDate(this.Birthday);
    },
    Nameday() {
      this.nameDayFormatted = this.formatDate(this.Nameday);
    },
    Mobile() {
      if (this.Mobile != null || this.Mobile.trim() != "")
        this.Telephone = this.Mobile;
    },
  },
};
</script>
<style lang="scss" scoped>
v-text-field {
  font-family: CenturyGothic;
  font-size: 15px;
}

.btn-custom {
  text-transform: initial;
  font-family: CenturyGothicBold;
  font-weight: 500;
  background-color: white;
}

.inputLabelCustom {
  font-family: CenturyGothic;
  font-size: 14px;
}

.inputLabelCustom15 {
  font-family: CenturyGothic;
  font-size: 15px;
}

.v-input__icon.v-input__icon--append {
  display: none !important;
}
</style>
