<template>
  <v-col class="flex-grow-0 innerFilesList">
    <v-row v-if="isNotParent && hasSubCategories == true">
      <v-btn class="mb-2 primaryColor unsetCapitalize opacity1" text plain @click="clearCategory()">
        &lt; {{ $t("back") }}
      </v-btn>
    </v-row>
    <v-row>
      <v-card class="mx-auto noBoxShadow pa-0 ma-0 vCardClass" tile>
        <v-list dense class="noBoxShadow">
          <v-list-item-group v-model="selDocType" color="primary" mandatory
            style="overflow:auto; max-height: 250px !important;'">
            <v-list-item v-for="(docType, index) in docTypeList" :key="index" :value="docType.id"
              class="greyBottomBorder">
              <v-list-item-content>
                <v-list-item-title v-text="$t(docType.name)" align="left" class="fontSize14 titleColor">
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <div v-if="listIcon == 'hint'">
                  <InfoField v-if="docType.hoverText != undefined" color="#263573" :hoverText="docType.hoverText" />
                </div>
                <div v-else-if="listIcon == 'count'" class="rounded-circle circleCustom">
                  {{ filesCount(docType.id) }}
                </div>
                <!-- v-if="filesCount(docType.id) <= 10" -->
                <!-- <div v-else class="rounded-circle circleCustom">10+</div> -->
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
/* 
  Name:FileList
  Description: FileList component to generate quickly File lists. Support select document type and clear selected document type
  API Property 
  listIcon: String containing Type of list icon for example "count","hint"
  documents: Array containing documents
  parentDocTypes: Array containing parent document types
  docTypesList: Array containing document types
  isNotParent: Boolean indicating if selected document type is parent or not
  selectedDocType: String containing document type selected
  selectDocTypeHandler: Handler function from parent when select document type
  clearSelectedHandler: Handler function from parent when clearing the selected document type

  Components Used:
    InfoField
*/
import InfoField from "@/components/Fields/InfoField/InfoField.vue";
export default {
  name: "FileList",
  components: { InfoField },
  props: {
    listIcon: { type: String, default: "count" }, // Type of list icon "count","hint"
    documents: Array, // Array of documents
    parentDocTypes: Array, // Array of parentDocTypes
    docTypesList: Array, // Array of docTypesList
    isNotParent: { type: Boolean, default: false }, // If is not parent document type
    selectedDocType: String, // Whitch document type is selected
    selectDocTypeHandler: Function, // Function that runs on select document type
    clearSelectedHandler: Function, // Function that clears the selected document type
    hasSubCategories: { type: Boolean, default: true },
  },
  computed: {
    docTypeList() {
      if (this.isNotParent && this.hasSubCategories == true) {
        return this.docTypesList;
      } else {
        return this.parentDocTypes;
      }
    },
    docObjKeys() {
      let objKeys = []; // Get all the keys
      this.documents.map((m) => {
        objKeys.push(m.docType);
      });
      return objKeys;
    },
    selDocType: {
      get() {
        return this.selectedDocType;
      },
      set(newValue) {
        newValue != undefined ? this.selectDocTypeHandler(newValue) : true;
      },
    },
  },
  methods: {
    clearCategory() {
      try {
        this.clearSelectedHandler();
      } catch {
        null;
      }
    },
    filesCount(docType) {
      try {
        let count = 0;
        if (this.selectedDocType == "all" && this.hasSubCategories == true) {
          if (docType == "all") {
            count = this.documents.filter((m) => {
              return m.docType == docType;
            })[0].count;
          } else {
            count = this.findCountByParent(docType);
          }
        } else {
          let x = this.documents.filter((m) => {
            return m.docType == docType;
          });
          if (x.length > 0) {
            count = x[0].count;
          }
        }
        return count <= 10 ? count : "10+";
      } catch (err) {
        console.error(err);
        return 0;
      }
    },
    findCountByParent(docType) {
      let sum = 0;
      try {
        let parentDocType = this.parentDocTypes.filter((f) => {
          return f.id == docType;
        });
        parentDocType[0].subCats.map((subCat) => {
          if (this.docObjKeys.includes(subCat.id)) {
            let x = this.documents.filter((f) => {
              return f.docType == subCat.id;
            });
            if (x.length > 0) {
              sum = sum + x[0].count;
            }
          }
        });
      } catch (err) {
        console.error(err);
      }
      return sum;
    },
  },
};
</script>
<style lang="scss" scoped>
.greyBottomBorder {
  border-bottom: 1px solid #adb3ca40 !important;
}

.noBoxShadow {
  box-shadow: none !important;
}

.fontSize14 {
  font-size: 14px !important;
}

.titleColor {
  color: #3a3a40;
}

.circleCustom {
  height: 23px !important;
  width: 23px !important;
  font-size: 10px;
  color: #ffffff;
  background-color: #263573;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 619px) {

  .innerFilesList,
  .vCardClass {
    width: 100%;
  }
}
</style>
