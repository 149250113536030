<template>
  <v-col class="pb-0">
    <v-row>
      <v-col class="colTitle" align="left"> {{ title }} </v-col>
    </v-row>
    <v-row>
      <v-col align="center" class="pb-0">
        <v-textarea
          :label="label"
          :hide-details="true"
          class="textAreaContent"
          outlined
          v-model="content"
          rows="5"
          row-height="50"
        >
        </v-textarea>
        <v-btn
          v-if="commentType == 'edit'"
          elevation="5"
          align="center"
          class="textDecorationBold mr-1 flex-grow-0"
          :class="commentType == 'edit' ? 'btnTextArea2' : 'btnTextArea'"
          color="error"
          @click="onCancel"
          :loading="loading"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          v-if="supportSend"
          elevation="5"
          align="center"
          class="textDecorationBold flex-grow-0"
          :class="commentType == 'edit' ? 'btnTextArea2' : 'btnTextArea'"
          :color="btnColor"
          :loading="loading"
          @click="onSend"
        >
          {{ btnName }} <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:Comment Field
 Description: TextArea Field with inner button

 API Property:
 title:Title of field
 label:Default label to show in text area field
 btnName:Label of button
 supportSend: Boolean variable to show or hide button
 loading:Boolean to show loading on button
 handleSend:Function handler on click button
 handleCancel:Function handler on click button cancel
*/
export default {
  name: "CommentField",
  props: {
    title: String, //the Header
    label: String, //Default label shown in text area
    btnName: String, //the button Name
    supportSend: Boolean, //true shows button || false hides button
    loading: Boolean,
    handleSend: Function, //Function for Send button
    commentType: String, // values: edit/create
    btnColor: String, //Blue for edit red for Create
    defaultContent: String,
    handleCancel: Function,
  },
  watch: {
    commentType() {
      if (this.commentType == "edit") {
        this.content = this.defaultContent;
      }
    },
  },
  data() {
    return {
      content: null,
    };
  },
  methods: {
    onSend() {
      this.handleSend(this.content);
      this.content = null;
    },
    onCancel() {
      this.content = null;
      this.handleCancel();
    },
  },
};
</script>
<style scoped>
.colTitle {
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  color: #e89005;
}
.textAreaContent {
  color: #263573 !important;
  border: #263573 !important;
  border-color: #263573 !important;
}
.btnTextArea {
  position: relative;
  bottom: 50px;
  text-transform: none;
  left: 30%;
}
.btnTextArea2 {
  position: relative;
  bottom: 50px;
  text-transform: none;
  left: 15%;
}

@media screen and (max-width: 1470px) {
  .btnTextArea {
    left: 25%;
  }
}

@media screen and (max-width: 1270px) {
  .btnTextArea2 {
    left: 15%;
  }
}
@media screen and (max-width: 1215px) {
  .btnTextArea {
    left: 17%;
  }
}

@media screen and (max-width: 1215px) {
  .btnTextArea2 {
    left: 5%;
  }
}
@media screen and (max-width: 1005px) {
  .btnTextArea {
    left: 0%;
  }
}
@media screen and (max-width: 959px) {
  .btnTextArea {
    left: 30%;
  }
  .btnTextArea2 {
    left: 25%;
  }
}
@media screen and (max-width: 800px) {
  .btnTextArea {
    left: 25%;
  }
  .btnTextArea2 {
    left: 20%;
  }
}
@media screen and (max-width: 700px) {
  .btnTextArea {
    left: 17%;
  }
  .btnTextArea2 {
    left: 15%;
  }
}

@media screen and (max-width: 630px) {
  .btnTextArea2 {
    left: 10%;
  }
}
@media screen and (max-width: 520px) {
  .btnTextArea {
    left: 0%;
  }
  .btnTextArea2 {
    left: 0%;
    bottom: 35%;
  }
}
</style>

