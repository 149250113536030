import store from "../../store";
import moment from "moment";
import CoreHelper from "@/helpers/Core/core";
import i18n from "../../i18n/i18n";
export default {
  setFilterObject(filters, viewChoice) {
    let object = {};
    let tagFilterArray = [];
    let characteristics = {};
    let charArray = [];
    if (filters.available != null && filters.available.length > 0) {
      let val = [];
      let options = store.getters.availableForList;
      filters.available.map((a, index) => {
        val.push(options[a].val);
        tagFilterArray.push({
          display: i18n.t(options[a].text),
          attrHead: [{ name: "available", action: "onSetAvailable" }],
          attrValue: options[a].val,
          attrType: "array",
          attrIndex: index,
        });
      });
      object.Task = val; //filters.available.val;
    }
    if (filters.category != null) {
      object.Category = filters.category.val;
      tagFilterArray.push({
        display: i18n.t(filters.category.text),
        attrHead: [{ name: "category", action: "onSetCategory" }],
        attrValue: filters.category.val,
        attrType: "string",
      });
    }
    if (filters.subCategory != null) {
      let type = [];
      filters.category.subCategoriesList.map((sc) => {
        if (filters.subCategory.includes(sc.id)) {
          type.push(sc.val);
          tagFilterArray.push({
            display: i18n.t(sc.text),
            attrHead: [{ name: "subCategory", action: "onSetSubCategory" }],
            attrValue: sc.id,
            attrType: "arrayByVal",
          });
        }
      });
      if (type.length > 0) object.Type = type;
    }
    if (filters.priceMin != null && filters.priceMax != null) {
      object.Price = [filters.priceMin, filters.priceMax];
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " " +
          filters.priceMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.priceMax,
        attrHead: [
          { name: "priceMin", action: "onSetPriceMin" },
          { name: "priceMax", action: "onSetPriceMax" },
        ],
        attrType: "string",
        attrValue: [filters.priceMin, filters.priceMax],
      });
    } else if (filters.priceMin != null && filters.priceMax == null) {
      object.Price = [filters.priceMin, 99999999999];
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " " +
          filters.priceMin +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "priceMin", action: "onSetPriceMin" },
          { name: "priceMax", action: "onSetPriceMax" },
        ],
        attrType: "string",
        attrValue: [filters.priceMin, filters.priceMax],
      });
    } else if (filters.priceMin == null && filters.priceMax != null) {
      object.Price = [-99999999999, filters.priceMax];
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.priceMax,
        attrHead: [
          { name: "priceMin", action: "onSetPriceMin" },
          { name: "priceMax", action: "onSetPriceMax" },
        ],
        attrType: "string",
        attrValue: [filters.priceMin, filters.priceMax],
      });
    }
    if (filters.filterEstate != null) {
      if (filters.filterEstate == 1) {
        tagFilterArray.push({
          display: i18n.t("myEstates"),
          attrHead: [{ name: "UserID", action: "onSetFilterEstate" }],
          attrValue: true,
          attrType: "string",
        });
        object.UserID = store.state.auth.userDetails._id;
      } else if (filters.filterEstate == 2) {
        tagFilterArray.push({
          display:
            filters.filterEstate == 0
              ? i18n.t("all")
              : filters.filterEstate == 1
              ? i18n.t("myEstates")
              : filters.filterEstate == 2
              ? i18n.t("favorites")
              : "",
          attrHead: [{ name: "saved_by", action: "onSetFilterEstate" }],
          attrValue: true,
          attrType: "string",
        });
        object.saved_by = store.state.auth.userDetails._id;
      }
    }
    if (filters.floorMin != null && filters.floorMax != null) {
      object.Floor = [filters.floorMin, filters.floorMax];
      tagFilterArray.push({
        display:
          i18n.t("floors") +
          " " +
          filters.floorMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.floorMax,
        attrHead: [
          { name: "floorMin", action: "onSetFloorMin" },
          { name: "floorMax", action: "onSetFloorMax" },
        ],
        attrType: "string",
        attrValue: [filters.floorMin, filters.floorMax],
      });
    } else if (filters.floorMin == null && filters.floorMax != null) {
      object.Floor = [-99999999999, filters.floorMax];
      tagFilterArray.push({
        display:
          i18n.t("floors") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.floorMax,
        attrHead: [
          { name: "floorMin", action: "onSetFloorMin" },
          { name: "floorMax", action: "onSetFloorMax" },
        ],
        attrType: "string",
        attrValue: [filters.floorMin, filters.floorMax],
      });
    } else if (filters.floorMin != null && filters.floorMax == null) {
      object.Floor = [filters.floorMin, 99999999999];
      tagFilterArray.push({
        display:
          i18n.t("floors") +
          " " +
          filters.floorMin +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "floorMin", action: "onSetFloorMin" },
          { name: "floorMax", action: "onSetFloorMax" },
        ],
        attrType: "string",
        attrValue: [filters.floorMin, filters.floorMax],
      });
    }
    if (filters.status != null && filters.status.length > 0) {
      let options = store.getters.statusList;
      if (filters.status.includes("IsApprovedFalse")) object.IsApproved = false;
      object.Status = filters.status.filter((m) => {
        if (m != "IsApprovedFalse") return m;
      });
      filters.status.map((a, index) => {
        let indx = options.findIndex((option) => option.val === a);
        if (indx != -1)
          tagFilterArray.push({
            display: i18n.t(options[indx].text),
            attrHead: [{ name: "status", action: "onSetStatus" }],
            attrValue: options[indx].val,
            attrType: "array",
            attrIndex: index,
          });
      });
      // if body.isApproved == true
    }
    if (filters.areaMin != null && filters.areaMax != null) {
      object.Unit = [filters.areaMin, filters.areaMax];
      tagFilterArray.push({
        display:
          i18n.t("sqft") +
          " " +
          filters.areaMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areaMax,
        attrHead: [
          { name: "areaMin", action: "onSetAreaMin" },
          { name: "areaMax", action: "onSetAreaMax" },
        ],
        attrType: "string",
        attrValue: [filters.areaMin, filters.areaMax],
      });
    } else if (filters.areaMin != null && filters.areaMax == null) {
      object.Unit = [filters.areaMin, 99999999999];
      tagFilterArray.push({
        display:
          i18n.t("sqft") +
          " " +
          filters.areaMin +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "areaMin", action: "onSetAreaMin" },
          { name: "areaMax", action: "onSetAreaMax" },
        ],
        attrType: "string",
        attrValue: [filters.areaMin, filters.areaMax],
      });
    } else if (filters.areaMin == null && filters.areaMax != null) {
      object.Unit = [-99999999999, filters.areaMax];
      tagFilterArray.push({
        display:
          i18n.t("sqft") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areaMax,
        attrHead: [
          { name: "areaMin", action: "onSetAreaMin" },
          { name: "areaMax", action: "onSetAreaMax" },
        ],
        attrType: "string",
        attrValue: [filters.areaMin, filters.areaMax],
      });
    }
    if (filters.areaPriceMin != null && filters.areaPriceMax != null) {
      object.PricePerUnit = [filters.areaPriceMin, filters.areaPriceMax];
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " / " +
          i18n.t("sqft") +
          " " +
          filters.areaPriceMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areaPriceMax,
        attrHead: [
          { name: "areaPriceMin", action: "onSetAreaPriceMin" },
          { name: "areaPriceMax", action: "onSetAreaPriceMax" },
        ],
        attrType: "string",
        attrValue: [filters.areaPriceMin, filters.areaPriceMax],
      });
    } else if (filters.areaPriceMin != null && filters.areaPriceMax == null) {
      object.PricePerUnit = [filters.areaPriceMin, 99999999999];
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " / " +
          i18n.t("sqft") +
          " " +
          filters.areaPriceMin +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "areaPriceMin", action: "onSetAreaPriceMin" },
          { name: "areaPriceMax", action: "onSetAreaPriceMax" },
        ],
        attrType: "string",
        attrValue: [filters.areaPriceMin, filters.areaPriceMax],
      });
    } else if (filters.areaPriceMin == null && filters.areaPriceMax != null) {
      object.PricePerUnit = [-99999999999, filters.areaPriceMax];
      tagFilterArray.push({
        display:
          i18n.t("price") +
          " / " +
          i18n.t("sqft") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.areaPriceMax,
        attrHead: [
          { name: "areaPriceMin", action: "onSetAreaPriceMin" },
          { name: "areaPriceMax", action: "onSetAreaPriceMax" },
        ],
        attrType: "string",
        attrValue: [filters.areaPriceMin, filters.areaPriceMax],
      });
    }
    if (filters.classEnergy != null) {
      object.EnergyClass = filters.classEnergy;
      tagFilterArray.push({
        display: i18n.t("energyClassDescr") + " " + filters.classEnergy,
        attrHead: [{ name: "classEnergy", action: "onSetClassEnergy" }],
        attrType: "string",
        attrValue: filters.classEnergy,
      });
      // }
    }
    if (filters.codeEstate != null) {
      object.EstateCode = filters.codeEstate.split(",");
      object.EstateCode.map((m, index) => {
        tagFilterArray.push({
          display: i18n.t("propertyCode") + ": " + m,
          attrHead: [{ name: "codeEstate", action: "onSetCodeEstate" }],
          attrType: "commaSeparated",
          attrValue: m,
          attrIndex: index,
        });
      });
    }
    if (filters.commandType != null && filters.commandType.length > 0) {
      object.Assignation = filters.commandType;
      filters.commandType.map((m, index) => {
        tagFilterArray.push({
          display: i18n.t(m),
          attrHead: [{ name: "commandType", action: "onSetCommandType" }],
          attrType: "array",
          attrValue: m,
          attrIndex: index,
        });
      });
    }
    if (filters.user != null) {
      object.UserID = filters.user["_id"];
      let lastname =
        filters && filters.user && filters.user.LastName
          ? filters.user.LastName
          : "";
      let firstname =
        filters && filters.user && filters.user.FirstName
          ? filters.user.FirstName
          : "";
      tagFilterArray.push({
        display: lastname + " " + firstname,
        attrHead: [{ name: "user", action: "onSetUser" }],
        attrType: "string",
        attrValue: filters.user,
      });
    }
    /* ---- */
    if (filters.phone != null) {
      object.CustomerTel = [filters.phone];
      tagFilterArray.push({
        display: filters.phone,
        attrHead: [{ name: "phone", action: "onSetPhone" }],
        attrType: "string",
        attrValue: filters.phone,
      });
    }

    if (filters.statusType != null) {
      let type = [];
      let array = store.state.listRealEstate.availabilityList;
      array.map((sc) => {
        if (filters.statusType.includes(sc.id)) {
          type.push(sc.val);
          tagFilterArray.push({
            display: i18n.t(sc.text),
            attrHead: [{ name: "statusType", action: "onSetStatusType" }],
            attrType: "arrayByVal",
            attrValue: sc.id,
          });
        }
      });
      if (type.length > 0) object.CurrentUse = type;
    }
    if (filters.refreshDateFrom != null && filters.refreshDateTo == null) {
      object.UpdatedDay = [moment(filters.refreshDateFrom).unix(), 99999999999];
      tagFilterArray.push({
        display:
          i18n.t("renewalDateF") +
          " " +
          filters.refreshDateFrom +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "refreshDateFrom", action: "onSetRefreshDateFrom" },
          { name: "refreshDateTo", action: "onSetRefreshDateTo" },
        ],
        attrType: "string",
        attrValue: [filters.refreshDateFrom, filters.refreshDateTo],
      });
    } else if (
      filters.refreshDateFrom != null &&
      filters.refreshDateTo != null
    ) {
      object.UpdatedDay = [
        moment(filters.refreshDateFrom).unix(),
        moment(filters.refreshDateTo).unix(),
      ];
      tagFilterArray.push({
        display:
          i18n.t("renewalDateF") +
          " " +
          filters.refreshDateFrom +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.refreshDateTo,
        attrHead: [
          { name: "refreshDateFrom", action: "onSetRefreshDateFrom" },
          { name: "refreshDateTo", action: "onSetRefreshDateTo" },
        ],
        attrType: "string",
        attrValue: [filters.refreshDateFrom, filters.refreshDateTo],
      });
    } else if (
      filters.refreshDateFrom == null &&
      filters.refreshDateTo != null
    ) {
      object.UpdatedDay = [99999999999, moment(filters.refreshDateTo).unix()];
      tagFilterArray.push({
        display:
          i18n.t("renewalDateF") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.refreshDateTo,
        attrHead: [
          { name: "refreshDateFrom", action: "onSetRefreshDateFrom" },
          { name: "refreshDateTo", action: "onSetRefreshDateTo" },
        ],
        attrType: "string",
        attrValue: [filters.refreshDateFrom, filters.refreshDateTo],
      });
    }
    if (filters.createdDateFrom != null && filters.createdDateTo == null) {
      object.RegisterationDay = [
        moment(filters.createdDateFrom).unix(),
        99999999999,
      ];
      tagFilterArray.push({
        display:
          i18n.t("registrationDateF") +
          " " +
          filters.createdDateFrom +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "createdDateFrom", action: "onSetCreatedDateFrom" },
          { name: "createdDateTo", action: "onSetCreatedDateTo" },
        ],
        attrType: "string",
        attrValue: [filters.createdDateFrom, filters.createdDateTo],
      });
    } else if (
      filters.createdDateFrom != null &&
      filters.createdDateTo != null
    ) {
      object.RegisterationDay = [
        moment(filters.createdDateFrom).unix(),
        moment(filters.createdDateTo).unix(),
      ];
      tagFilterArray.push({
        display:
          i18n.t("registrationDateF") +
          " " +
          filters.createdDateFrom +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.createdDateTo,
        attrHead: [
          { name: "createdDateFrom", action: "onSetCreatedDateFrom" },
          { name: "createdDateTo", action: "onSetCreatedDateTo" },
        ],
        attrType: "string",
        attrValue: [filters.createdDateFrom, filters.createdDateTo],
      });
    } else if (
      filters.createdDateFrom == null &&
      filters.createdDateTo != null
    ) {
      object.RegisterationDay = [
        -99999999999,
        moment(filters.createdDateTo).unix(),
      ];
      tagFilterArray.push({
        display:
          i18n.t("registrationDateF") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.createdDateTo,
        attrHead: [
          { name: "createdDateFrom", action: "onSetCreatedDateFrom" },
          { name: "createdDateTo", action: "onSetCreatedDateTo" },
        ],
        attrType: "string",
        attrValue: [filters.createdDateFrom, filters.createdDateTo],
      });
    }
    if (
      filters.createdProviderDateFrom != null &&
      filters.createdProviderDateTo == null
    ) {
      object.ProviderRegisterationDay = [
        moment(filters.createdProviderDateFrom).unix(),
        99999999999,
      ];
      tagFilterArray.push({
        display:
          i18n.t("registrationDateProvidersF") +
          " " +
          filters.createdProviderDateFrom +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          {
            name: "createdProviderDateFrom",
            action: "onSetCreatedProviderDateFrom",
          },
          {
            name: "createdProviderDateTo",
            action: "onSetCreatedProviderDateTo",
          },
        ],
        attrType: "string",
        attrValue: [
          filters.createdProviderDateFrom,
          filters.createdProviderDateTo,
        ],
      });
    } else if (
      filters.createdProviderDateFrom != null &&
      filters.createdProviderDateTo != null
    ) {
      object.ProviderRegisterationDay = [
        moment(filters.createdProviderDateFrom).unix(),
        moment(filters.createdProviderDateTo).unix(),
      ];
      tagFilterArray.push({
        display:
          i18n.t("registrationDateProvidersF") +
          " " +
          filters.createdProviderDateFrom +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.createdProviderDateTo,
        attrHead: [
          {
            name: "createdProviderDateFrom",
            action: "onSetCreatedProviderDateFrom",
          },
          {
            name: "createdProviderDateTo",
            action: "onSetCreatedProviderDateTo",
          },
        ],
        attrType: "string",
        attrValue: [
          filters.createdProviderDateFrom,
          filters.createdProviderDateTo,
        ],
      });
    } else if (
      filters.createdProviderDateFrom == null &&
      filters.createdProviderDateTo != null
    ) {
      object.ProviderRegisterationDay = [
        -99999999999,
        moment(filters.createdProviderDateTo).unix(),
      ];
      tagFilterArray.push({
        display:
          i18n.t("registrationDateProvidersF") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.createdProviderDateTo,
        attrHead: [
          {
            name: "createdProviderDateFrom",
            action: "onSetCreatedProviderDateFrom",
          },
          {
            name: "createdProviderDateTo",
            action: "onSetCreatedProviderDateTo",
          },
        ],
        attrType: "string",
        attrValue: [
          filters.createdProviderDateFrom,
          filters.createdProviderDateTo,
        ],
      });
    }
    if (filters.yearMin != null && filters.yearMax == null) {
      characteristics["Έτος κατασκευής"] = [
        Number(filters.yearMin),
        99999999999,
      ];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("yearOfConstruction") +
          " " +
          filters.yearMin +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "yearMin", action: "onSetYearMin" },
          { name: "yearMax", action: "onSetYearMax" },
        ],
        attrType: "string",
        attrValue: [filters.yearMin, filters.yearMax],
      });
    } else if (filters.yearMin != null && filters.yearMax != null) {
      characteristics["Έτος κατασκευής"] = [
        Number(filters.yearMin),
        Number(filters.yearMax),
      ];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("yearOfConstruction") +
          " " +
          filters.yearMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.yearMax,
        attrHead: [
          { name: "yearMin", action: "onSetYearMin" },
          { name: "yearMax", action: "onSetYearMax" },
        ],
        attrType: "string",
        attrValue: [filters.yearMin, filters.yearMax],
      });
    } else if (filters.yearMin == null && filters.yearMax != null) {
      characteristics["Έτος κατασκευής"] = [
        -99999999999,
        Number(filters.yearMax),
      ];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("yearOfConstruction") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.yearMax,
        attrHead: [
          { name: "yearMin", action: "onSetYearMin" },
          { name: "yearMax", action: "onSetYearMax" },
        ],
        attrType: "string",
        attrValue: [filters.yearMin, filters.yearMax],
      });
    }
    if (filters.roomMin != null && filters.roomMax == null) {
      characteristics["Υ/Δ"] = [filters.roomMin, 99999999999];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("bedrooms") +
          " " +
          filters.roomMin +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "roomMin", action: "onSetRoomMin" },
          { name: "roomMax", action: "onSetRoomMax" },
        ],
        attrType: "string",
        attrValue: [filters.roomMin, filters.roomMax],
      });
    } else if (filters.roomMin != null && filters.roomMax != null) {
      characteristics["Υ/Δ"] = [filters.roomMin, filters.roomMax];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("bedrooms") +
          " " +
          filters.roomMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.roomMax,
        attrHead: [
          { name: "roomMin", action: "onSetRoomMin" },
          { name: "roomMax", action: "onSetRoomMax" },
        ],
        attrType: "string",
        attrValue: [filters.roomMin, filters.roomMax],
      });
    } else if (filters.roomMin == null && filters.roomMax != null) {
      characteristics["Υ/Δ"] = [-99999999999, filters.roomMax];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("bedrooms") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.roomMax,
        attrHead: [
          { name: "roomMin", action: "onSetRoomMin" },
          { name: "roomMax", action: "onSetRoomMax" },
        ],
        attrType: "string",
        attrValue: [filters.roomMin, filters.roomMax],
      });
    }
    if (filters.bathroomMin != null && filters.bathroomMax == null) {
      characteristics["Μπάνια"] = [filters.bathroomMin, 99999999999];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("bathrooms") +
          " " +
          filters.bathroomMin +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "bathroomMin", action: "onSetBathroomMin" },
          { name: "bathroomMax", action: "onSetBathroomMax" },
        ],
        attrType: "string",
        attrValue: [filters.bathroomMin, filters.bathroomMax],
      });
    } else if (filters.bathroomMin != null && filters.bathroomMax != null) {
      characteristics["Μπάνια"] = [filters.bathroomMin, filters.bathroomMax];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("bathrooms") +
          " " +
          filters.bathroomMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.bathroomMax,
        attrHead: [
          { name: "bathroomMin", action: "onSetBathroomMin" },
          { name: "bathroomMax", action: "onSetBathroomMax" },
        ],
        attrType: "string",
        attrValue: [filters.bathroomMin, filters.bathroomMax],
      });
    } else if (filters.bathroomMin == null && filters.bathroomMax != null) {
      characteristics["Μπάνια"] = [-99999999999, filters.bathroomMax];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("bathrooms") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.bathroomMax,
        attrHead: [
          { name: "bathroomMin", action: "onSetBathroomMin" },
          { name: "bathroomMax", action: "onSetBathroomMax" },
        ],
        attrType: "string",
        attrValue: [filters.bathroomMin, filters.bathroomMax],
      });
    }
    if (filters.rateMin != null && filters.rateMax == null) {
      characteristics["Αξιολόγηση"] = [filters.rateMin, 99999999999];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("valuationF") +
          " " +
          filters.rateMin +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "rateMin", action: "onSetRateMin" },
          { name: "rateMax", action: "onSetRateMax" },
        ],
        attrType: "string",
        attrValue: [filters.rateMin, filters.rateMax],
      });
    } else if (filters.rateMin != null && filters.rateMax != null) {
      characteristics["Αξιολόγηση"] = [filters.rateMin, filters.rateMax];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("valuationF") +
          " " +
          filters.rateMin +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.rateMax,
        attrHead: [
          { name: "rateMin", action: "onSetRateMin" },
          { name: "rateMax", action: "onSetRateMax" },
        ],
        attrType: "string",
        attrValue: [filters.rateMin, filters.rateMax],
      });
    } else if (filters.rateMin == null && filters.rateMax != null) {
      characteristics["Αξιολόγηση"] = [-99999999999, filters.rateMax];
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display:
          i18n.t("valuationF") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.rateMax,
        attrHead: [
          { name: "rateMin", action: "onSetRateMin" },
          { name: "rateMax", action: "onSetRateMax" },
        ],
        attrType: "string",
        attrValue: [filters.rateMin, filters.rateMax],
      });
    }
    if (filters.buildingStatus != null) {
      characteristics[filters.buildingStatus] = true;
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display: i18n.t(filters.buildingStatus),
        attrHead: [{ name: "buildingStatus", action: "onSetBuildingStatus" }],
        attrValue: filters.buildingStatus,
        attrType: "string",
      });
    }
    if (
      filters.genericCharacters != null &&
      filters.genericCharacters.length > 0
    ) {
      let array = store.state.listRealEstate.generalFeaturesList;
      array.map((h) => {
        if (filters.genericCharacters.includes(h.id)) {
          characteristics[h.val] = true;
          charArray.push(characteristics);
          characteristics = {};
          tagFilterArray.push({
            display: i18n.t(h.text),
            attrHead: [
              { name: "genericCharacters", action: "onSetGenericCharact" },
            ],
            attrValue: h.text,
            attrType: "arrayByVal",
          });
        }
      });
    }
    if (filters.homeCharacters != null && filters.homeCharacters.length > 0) {
      filters.homeCharacters.map((h) => {
        characteristics[h] = true;
        charArray.push(characteristics);
        characteristics = {};
        tagFilterArray.push({
          display: i18n.t(h),
          attrHead: [{ name: "homeCharacters", action: "onSetHomeCharacters" }],
          attrValue: h,
          attrType: "arrayByVal",
        });
      });
    }
    if (
      filters.heatingCharacters != null &&
      filters.heatingCharacters.length > 0
    ) {
      filters.heatingCharacters.map((h) => {
        characteristics[h] = true;
        charArray.push(characteristics);
        characteristics = {};
        tagFilterArray.push({
          display: i18n.t(h),
          attrHead: [
            { name: "heatingCharacters", action: "onSetHeatingCharacters" },
          ],
          attrValue: h,
          attrType: "arrayByVal",
        });
      });
    }
    if (filters.published != null && filters.published.length > 0) {
      filters.published.map((h, index) => {
        let options = store.getters.publishedList;
        let indx = options.findIndex((option) => option.val === h);
        let currentOption = options[indx];
        if (h == "XE")
          object["providers"] = {
            $elemMatch: { Characteristic: "ΧΕ", IsActive: true },
          };
        else if (h == "SP") object["spListingID"] = { $exists: true };
        tagFilterArray.push({
          display: i18n.t(currentOption.text),
          attrHead: [{ name: "published", action: "onSetPublished" }],
          attrType: "array",
          attrValue: h,
          attrIndex: index,
        });
      });
    }
    if (filters.extraCharacters != null) {
      filters.extraCharacters.map((h) => {
        characteristics[h] = true;
        charArray.push(characteristics);
        characteristics = {};
        tagFilterArray.push({
          display: i18n.t(h),
          attrHead: [
            { name: "extraCharacters", action: "onSetExtraCharacters" },
          ],
          attrValue: h,
          attrType: "arrayByVal",
        });
      });
    }
    if (
      filters.securityCharacters != null &&
      filters.securityCharacters.length > 0
    ) {
      filters.securityCharacters.map((h) => {
        characteristics[h] = true;
        charArray.push(characteristics);
        characteristics = {};
        tagFilterArray.push({
          display: i18n.t(h),
          attrHead: [
            { name: "securityCharacters", action: "onSetSecurityCharacters" },
          ],
          attrValue: h,
          attrType: "arrayByVal",
        });
      });
    }
    if (filters.viewCharacters != null && filters.viewCharacters.length > 0) {
      filters.viewCharacters.map((h) => {
        characteristics[h] = true;
        charArray.push(characteristics);
        characteristics = {};
        tagFilterArray.push({
          display: i18n.t(h),
          attrHead: [{ name: "viewCharacters", action: "onSetViewCharacters" }],
          attrValue: h,
          attrType: "arrayByVal",
        });
      });
    }
    if (filters.frames != null) {
      characteristics[filters.frames] = true;
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display: i18n.t(filters.frames),
        attrHead: [{ name: "frames", action: "onSetframes" }],
        attrValue: filters.frames,
        attrType: "string",
      });
    }
    if (filters.typeFloor != null) {
      characteristics[filters.typeFloor] = true;
      charArray.push(characteristics);
      characteristics = {};
      tagFilterArray.push({
        display: i18n.t(filters.typeFloor),
        attrHead: [{ name: "typeFloor", action: "onSetTypeFloor" }],
        attrValue: filters.typeFloor,
        attrType: "string",
      });
    }
    if (filters.publicCharacters != null) {
      let type = [];
      let array = store.state.listRealEstate.adsWithList;
      array.map((sc) => {
        if (filters.publicCharacters.includes(sc.id)) {
          type.push(sc.val);
          tagFilterArray.push({
            display: i18n.t(sc.text),
            attrHead: [
              { name: "publicCharacters", action: "onSetPublicCharacters" },
            ],
            attrValue: sc.id,
            attrType: "arrayByVal",
          });
        }
      });

      if (type.length > 0) object.Characteristic = type;
    }
    /* --------------------- Area Functions --------------------------------*/
    let area;
    if (
      ![null, undefined, ""].includes(filters.searchNewDeal) &&
      filters.searchNewDeal.length > 0
    ) {
      let locND;
      locND = filters.searchNewDeal.join(", ");
      area = locND;
      filters.searchNewDeal.map((m, index) => {
        tagFilterArray.push({
          display: i18n.t(m),
          attrHead: [{ name: "searchNewDeal", action: "onSetSearchNewDeal" }],
          attrValue: m,
          attrType: "array",
          attrIndex: index,
        });
      });
    }
    if (filters.locationGoogle) {
      let state = "";
      let region = "";
      let locG = ""; /*let latMin; let latMax; let lngMin; let lngMax;*/
      if (
        filters.locationGoogle &&
        filters.locationGoogle.geometry &&
        filters.locationGoogle.geometry.viewport
      ) {
        try {
          let values = JSON.parse(
            JSON.stringify(filters.locationGoogle.geometry.viewport)
          );
          object["GPS.0"] = { $gte: values.south, $lte: values.north };
          object["GPS.1"] = { $gte: values.west, $lte: values.east };
        } catch (e) {
          console.error(e);
        }
      }
      state =
        filters.locationGoogle && filters.locationGoogle.vicinity
          ? filters.locationGoogle.vicinity
          : "";
      if (filters.locationGoogle && filters.locationGoogle.addressExtracted)
        region = filters.locationGoogle.addressExtracted
          .administrative_area_level_1
          ? filters.locationGoogle.addressExtracted.administrative_area_level_1
          : filters.locationGoogle.addressExtracted.administrative_area_level_2
          ? filters.locationGoogle.addressExtracted.administrative_area_level_2
          : filters.locationGoogle.addressExtracted.administrative_area_level_3
          ? filters.locationGoogle.addressExtracted.administrative_area_level_3
          : "";
      else region = "";
      if (
        ![null, undefined, ""].includes(state) ||
        ![null, undefined, ""].includes(region)
      )
        locG = state + " | " + region;
      if (![null, undefined, " | ", ""].includes(locG)) {
        tagFilterArray.push({
          display: locG,
          attrHead: [{ name: "locationGoogle", action: "onSetLocationGoogle" }],
          attrValue: locG,
          attrType: "string",
        });
      }
    }
    if (area) object.Area = area;
    else if (
      !filters.locationGoogle &&
      filters.googleCoordinates &&
      viewChoice == 2
    ) {
      try {
        let values = JSON.parse(JSON.stringify(filters.googleCoordinates));
        object["GPS.0"] = { $gte: values.south, $lte: values.north };
        object["GPS.1"] = { $gte: values.west, $lte: values.east };
      } catch (e) {
        console.error(e);
      }
    }
    /* --------------------- End of Area Functions -------------------------*/
    if (charArray.length > 0)
      return [
        { ...object, characteristics: { $and: charArray } },
        tagFilterArray,
      ];
    else return [object, tagFilterArray];

    // return object;
    // return combine;
  },
  checkIfFieldExist(category, fieldName) {
    let result = store.state.listRealEstate.realEstateRules.filter((f) => {
      if (f.category.includes(category)) return f;
    });
    if (result.length > 0) return result[0].rules.includes(fieldName);
    return false;
  },
  getAdditionalSpacesInfo(data) {
    let res = {};
    if (data.characteristics && data.characteristics.storage_room)
      res["storage_room"] = data.characteristics.storage_room;
    if (data.characteristics && data.characteristics.attic)
      res["attic"] = data.characteristics.attic;
    if (data.characteristics && data.characteristics.attic_in_sqm)
      res["attic_in_sqm"] = data.characteristics.attic_in_sqm;
    if (data.characteristics && data.characteristics.service_room)
      res["service_room"] = data.characteristics.service_room;
    if (data.characteristics && data.characteristics.service_room_in_sqm)
      res["service_room_in_sqm"] = data.characteristics.service_room_in_sqm;
    if (data.characteristics && data.characteristics.storage_room_sqm)
      res["storage_room_sqm"] = data.characteristics.storage_room_sqm;
    if (data.characteristics && data.characteristics.owned_terrace)
      res["owned_terrace"] = data.characteristics.owned_terrace;
    if (data.characteristics && data.characteristics.owned_terrace_in_sqm)
      res["owned_terrace_in_sqm"] = data.characteristics.owned_terrace_in_sqm;
    if (data.characteristics && data.characteristics.playroom)
      res["playroom"] = data.characteristics.playroom;
    if (data.characteristics && data.characteristics.playroom_in_sqm)
      res["playroom_in_sqm"] = data.characteristics.playroom_in_sqm;
    if (data.characteristics && data.characteristics.garden)
      res["garden"] = data.characteristics.garden;
    if (data.characteristics && data.characteristics.garden_in_sqm)
      res["garden_in_sqm"] = data.characteristics.garden_in_sqm;
    if (data.characteristics && data.characteristics.pool)
      res["pool"] = data.characteristics.pool;
    if (data.characteristics && data.characteristics.pool_in_sqm)
      res["pool_in_sqm"] = data.characteristics.pool_in_sqm;
    return res;
  },
  getFinancialInfo(data) {
    function getDate(date) {
      switch (typeof date) {
        case "number":
          return moment.unix(date).format("YYYY-MM-DD");
        case "string": {
          return date;
        }
        default:
          return date;
      }
    }
    let res = {};
    if (data.Task) res["Task"] = data.Task;
    if (data.Price) res["Price"] = data.Price;
    if (data.ObjectivePrice) res["ObjectivePrice"] = data.ObjectivePrice;
    if (data.AgentPrice) res["AgentPrice"] = data.AgentPrice;
    if (data.Unit) res["Unit"] = data.Unit;
    if (data.PricePerUnit) res["PricePerUnit"] = data.PricePerUnit;
    if (data.estimated_rent_price)
      res["estimated_rent_price"] = data.estimated_rent_price;
    if (data.suitable_for_investment)
      res["suitable_for_investment"] = data.suitable_for_investment;
    if (data.characteristics && data.characteristics.payment_in_advance_date)
      res["payment_in_advance_date"] = getDate(
        data.characteristics.payment_in_advance_date
      );
    if (data.characteristics && data.characteristics.payment_in_advance_amount)
      res["payment_in_advance_amount"] =
        data.characteristics.payment_in_advance_amount;
    if (data.characteristics && data.characteristics.monthly_rent_price)
      res["monthly_rent_price"] = data.characteristics.monthly_rent_price;
    if (data.characteristics && data.characteristics.date_of_lease_expiration)
      res["date_of_lease_expiration"] = getDate(
        data.characteristics.date_of_lease_expiration
      );
    if (data.characteristics && data.characteristics.shared_fees_monthly_price)
      res["shared_fees_monthly_price"] =
        data.characteristics.shared_fees_monthly_price;
    if (data.characteristics && data.characteristics.yearly_tax)
      res["yearly_tax"] = data.characteristics.yearly_tax;
    if (data.characteristics && data.characteristics.yearly_yield)
      res["yearly_yield"] = data.characteristics.yearly_yield;
    if (data.characteristics && data.characteristics.yearly_income_before_tax)
      res["yearly_income_before_tax"] =
        data.characteristics.yearly_income_before_tax;
    //authorize
    if (data.UserID) res["agentId"] = data.UserID;
    return res;
  },
  getCharacteristicsInfo(data) {
    let res = {};
    if (data.Type) res["Type"] = data.Type;
    if (data.Kind) res["Kind"] = data.Kind;
    if (data.characteristics && data.characteristics.levels)
      res["levels"] = data.characteristics.levels;
    if (data.floor && data.floors.length > 0) res["floors"] = data.floors;
    if (data.Unit) res["Unit"] = data.Unit;
    if (data.plot_unit) res["plot_unit"] = data.plot_unit;
    if (data.characteristics && data.characteristics.bathrooms)
      res["bathroom"] = data.characteristics.bathrooms;
    if (data.characteristics && data.characteristics.wc)
      res["wc"] = data.characteristics.wc;
    if (data.characteristics && data.characteristics.balconies)
      res["balconies"] = data.characteristics.balconies;
    if (data.characteristics && data.characteristics.balconies_in_sqm)
      res["balconies_in_sqm"] = data.characteristics.balconies_in_sqm;
    if (data.characteristics && data.characteristics.kitchen_rooms)
      res["kitchen_rooms"] = data.characteristics.kitchen_rooms;
    if (data.characteristics && data.characteristics.living_rooms)
      res["living_rooms"] = data.characteristics.living_rooms;
    if (data.characteristics && data.characteristics.parking_spots)
      res["parking_spots"] = data.characteristics.parking_spots;
    if (data.characteristics && data.characteristics.enclosed_parking)
      res["enclosed_parking"] = data.characteristics.enclosed_parking;
    if (data.characteristics && data.characteristics.underground_parking)
      res["underground_parking"] = data.characteristics.underground_parking;
    if (data.characteristics && data.characteristics.pilot_parking)
      res["pilot_parking"] = data.characteristics.pilot_parking;
    if (data.characteristics && data.characteristics.open_parking)
      res["open_parking"] = data.characteristics.open_parking;
    if (data.BuildYear != undefined) res["BuildYear"] = data.BuildYear;
    if (data.RenovationYear != undefined)
      res["RenovationYear"] = data.RenovationYear;
    if (data.CurrentUse) res["CurrentUse"] = data.CurrentUse;
    if (data.characteristics && data.characteristics.energy_class)
      res["energy_class"] = data.characteristics.energy_class;
    return res;
  },

  getCharacteristicsEstates(data) {
    let array = {};
    let bedrooms = 0;
    let bathrooms = 0;
    let unit = 0;
    if (data.Unit) unit = data.Unit;
    if (data.characteristics && data.characteristics.bathrooms)
      bathrooms = data.characteristics.bathrooms;
    if (data.characteristics && data.characteristics.bedrooms)
      bedrooms = data.characteristics.bedrooms;
    array = [
      {
        image: "realEstateSilder/bedroom.png",
        value: bedrooms,
      },
      {
        image: "realEstateSilder/bathroom.png",
        value: bathrooms,
      },
      {
        image: "realEstateSilder/sqft.png",
        value: unit,
      },
    ];
    return array;
  },
  getDistanceInfo(data) {
    let res = {};
    if (data.characteristics) {
      if (
        data.characteristics.distance_from_sea &&
        !isNaN(data.characteristics.distance_from_sea)
      )
        res["distance_from_sea"] = data.characteristics.distance_from_sea;
      if (
        data.characteristics.distance_from_airport &&
        !isNaN(data.characteristics.distance_from_airport)
      )
        res["distance_from_airport"] =
          data.characteristics.distance_from_airport;
      if (
        data.characteristics.distance_from_acropolis &&
        !isNaN(data.characteristics.distance_from_acropolis)
      )
        res["distance_from_acropolis"] =
          data.characteristics.distance_from_acropolis;
      if (
        data.characteristics.distance_from_bank &&
        !isNaN(data.characteristics.distance_from_bank)
      )
        res["distance_from_bank"] = data.characteristics.distance_from_bank;
      if (
        data.characteristics.distance_from_schools &&
        !isNaN(data.characteristics.distance_from_schools)
      )
        res["distance_from_schools"] =
          data.characteristics.distance_from_schools;
      if (
        data.characteristics.distance_from_super_market &&
        !isNaN(data.characteristics.distance_from_super_market)
      )
        res["distance_from_super_market"] =
          data.characteristics.distance_from_super_market;
      if (
        data.characteristics.distance_from_metro &&
        !isNaN(data.characteristics.distance_from_metro)
      )
        res["distance_from_metro"] = data.characteristics.distance_from_metro;
      if (
        data.characteristics.distance_from_commuting_stops &&
        !isNaN(data.characteristics.distance_from_commuting_stops)
      )
        res["distance_from_commuting_stops"] =
          data.characteristics.distance_from_commuting_stops;
      if (
        data.characteristics.distance_from_train &&
        !isNaN(data.characteristics.distance_from_train)
      )
        res["distance_from_train"] = data.characteristics.distance_from_train;
      if (
        data.characteristics.distance_from_square &&
        !isNaN(data.characteristics.distance_from_square)
      )
        res["distance_from_square"] = data.characteristics.distance_from_square;
      if (
        data.characteristics.distance_from_park &&
        !isNaN(data.characteristics.distance_from_park)
      )
        res["distance_from_park"] = data.characteristics.distance_from_park;
      if (
        data.characteristics.distance_from_hospital &&
        !isNaN(data.characteristics.distance_from_hospital)
      )
        res["distance_from_hospital"] =
          data.characteristics.distance_from_hospital;
      if (
        data.characteristics.distance_from_suburban &&
        !isNaN(data.characteristics.distance_from_suburban)
      )
        res["distance_from_suburban"] =
          data.characteristics.distance_from_suburban;
      if (
        data.characteristics.distance_from_tram &&
        !isNaN(data.characteristics.distance_from_tram)
      )
        res["distance_from_tram"] = data.characteristics.distance_from_tram;
      if (
        data.characteristics.distance_from_international_schools &&
        !isNaN(data.characteristics.distance_from_international_schools)
      )
        res["distance_from_international_schools"] =
          data.characteristics.distance_from_international_schools;
    }
    res["distanceUnit"] =
      data.DistanceMeasure == "μέτρα" || data.DistanceMeasure == 0
        ? "μ."
        : data.DistanceMeasure == "χλμ" || data.DistanceMeasure == 1
        ? "χλμ"
        : null;
    return res;
  },
  getAssignemntsInfo(data) {
    function getDate(date) {
      switch (typeof date) {
        case "number":
          return moment.unix(date).format("YYYY-MM-DD");
        case "string": {
          return date;
        }
        default:
          return date;
      }
    }
    let res = {};
    data.AgentName ? (res["AgentName"] = data.AgentName) : null;
    data.AgentSurName ? (res["AgentSurName"] = data.AgentSurName) : null;
    data.AgentPhoto ? (res["AgentPhoto"] = data.AgentPhoto) : null;
    data.Company ? (res["Company"] = data.Company) : null;
    data.ClientName ? (res["ClientName"] = data.ClientName) : null;
    data.ClientSurName ? (res["ClientSurName"] = data.ClientSurName) : null;
    data.ClientPhoto ? (res["ClientPhoto"] = data.ClientPhoto) : null;
    data.CustomerID ? (res["ClientID"] = data.CustomerID) : null;
    data.Status ? (res["Status"] = data.Status) : null;
    data.Category ? (res["Category"] = data.Category) : null;
    //authorize
    if (data.UserID) res["agentId"] = data.UserID;
    //Αγοραστής
    data.BuyersName ? (res["BuyersName"] = data.BuyersName) : null;
    data.BuyersSurName ? (res["BuyersSurName"] = data.BuyersSurName) : null;
    //Δικηγόρος
    data.LawyersName ? (res["LawyersName"] = data.LawyersName) : null;
    data.LawyersSurName ? (res["LawyersSurName"] = data.LawyersSurName) : null;
    data.AttorneyID ? (res["LawyersID"] = data.AttorneyID) : null;
    //Συμβολαιογράφος
    data.NotarysName ? (res["NotarysName"] = data.NotarysName) : null;
    data.NotarysSurName ? (res["NotarysSurName"] = data.NotarysSurName) : null;
    data.NotaryID ? (res["NotarysID"] = data.NotaryID) : null;
    data.Assignation ? (res["Assignation"] = data.Assignation) : null;
    //Ημερομηνία Listing
    data.RegisterationDay
      ? (res["RegisterationDay"] = getDate(data.RegisterationDay))
      : null;
    //Ημερομηνία Ανανέωσης
    data.UpdatedDay ? (res["UpdatedDay"] = getDate(data.UpdatedDay)) : null;
    //Ημερομηνία Λήξης
    data.AssignationEnd
      ? (res["AssignationEnd"] = getDate(data.AssignationEnd))
      : null;
    //Τελευταία επεξεργασία
    data.UpdatedDay ? (res["lastUpdateDate"] = getDate(data.UpdatedDay)) : null;
    if (data.characteristics) {
      data.characteristics.keys
        ? (res["keys"] = data.characteristics.keys)
        : null;
      data.characteristics.pano
        ? (res["pano"] = data.characteristics.pano)
        : null;
      data.characteristics.banner
        ? (res["banner"] = data.characteristics.banner)
        : null;
      data.characteristics.banners_external
        ? (res["banners_external"] = data.characteristics.banners_external)
        : null;
    }
    //Κωδικός Ανάθεσης
    res["assignCode"] = data.EstateCode;
    data.EstateCode ? (res["EstateCode"] = data.EstateCode) : null;
    data.Task ? (res["Task"] = data.Task) : null;
    data.Status ? (res["Status"] = data.Status) : null;
    return res;
  },
  initialData(data) {
    var body = {
      buildingStatusSelected: null,
      selectBoxesSelected: [],
      floorSelected: [],
      chooseAViewSelected: [],
      selectPositionSelected: [],
      orientationSelected: [],
      selectedSecurity: null,
      greekDescription: "",
      englishDescription: "",
      arabicDescription: "",
      chinesseDescription: "",
    };
    body["category"] = data["Category"] != undefined ? data["Category"] : null;
    body["task"] = data["Task"] != undefined ? data["Task"] : null;
    body["id"] = data["_id"] != undefined ? data["_id"] : null;
    body["type"] = data["Type"] != undefined ? data["Type"] : null;
    body["kind"] = data["Kind"] != undefined ? data["Kind"] : null;
    body["floor"] = data["floors"] != undefined ? data["floors"] : null;
    body["price"] = data["Prices"] != undefined ? data["Prices"] : null;
    body["air"] = data["air"] != undefined ? data["air"] : null;
    body["objectivePrice"] =
      data["ObjectivePrice"] != undefined ? data["ObjectivePrice"] : null;
    body["agentPrice"] =
      data["AgentPrice"] != undefined ? data["AgentPrice"] : null;
    body["unit"] = data["Unit"] != undefined ? data["Unit"] : null;
    body["plot_unit"] =
      data["plot_unit"] != undefined ? data["plot_unit"] : null;
    body["currentUse"] =
      data["CurrentUse"] != undefined ? data["CurrentUse"] : null;
    body["currentUseDateFrom"] =
      data["CurrentUseDateFrom"] != undefined
        ? data["CurrentUseDateFrom"]
        : null;
    body["buildYear"] =
      data["BuildYear"] != undefined ? data["BuildYear"] : null;
    body["renovateYear"] =
      data["RenovationYear"] != undefined ? data["RenovationYear"] : null;
    body["selectedDistanceUnit"] =
      data["DistanceMeasure"] != undefined ? data["DistanceMeasure"] : null;
    body["negotiable_price"] = CoreHelper.parseBoolean(data.negotiable_price);
    body["proUse"] =
      data.characteristics.proUse != undefined
        ? this.data.characteristics.proUse
        : null;
    body["outOfPlan"] =
      data.characteristics.outOfPlan != undefined
        ? this.data.characteristics.outOfPlan
        : null;
    body["storage"] =
      data.characteristics.storage != undefined
        ? this.data.characteristics.storage
        : null;
    body["fromManufacturer"] =
      data.characteristics.fromManufacturer != undefined
        ? this.data.characteristics.fromManufacturer
        : null;
    body["levels"] =
      data.characteristics.levels != undefined
        ? this.data.characteristics.levels
        : null;
    body["bedrooms"] =
      data.characteristics.bedrooms != undefined
        ? data.characteristics.bedrooms
        : null;
    body["kitchen_rooms"] =
      data.characteristics.kitchen_rooms != undefined
        ? data.characteristics.kitchen_rooms
        : null;
    body["bathrooms"] =
      data.characteristics.bathrooms != undefined
        ? data.characteristics.bathrooms
        : null;
    body["numberOfPlaces"] =
      data.characteristics.numberOfPlaces != undefined
        ? data.characteristics.numberOfPlaces
        : null;
    body["balconies"] =
      data.characteristics.balconies != undefined
        ? data.characteristics.balconies
        : null;
    body["balconies_in_sqm"] =
      data.characteristics.balconies_in_sqm != undefined
        ? data.characteristics.balconies_in_sqm
        : null;
    body["living_rooms"] =
      data.characteristics.living_rooms != undefined
        ? data.characteristics.living_rooms
        : null;
    body["wc"] =
      data.characteristics.wc != undefined ? data.characteristics.wc : null;
    body["storage_room"] =
      data.characteristics.storage_room != undefined
        ? data.characteristics.storage_room
        : null;
    body["attic"] =
      data.characteristics.attic != undefined
        ? data.characteristics.attic
        : null;
    body["attic_in_sqm"] =
      data.characteristics.attic_in_sqm != undefined
        ? data.characteristics.attic_in_sqm
        : null;
    body["service_room"] =
      data.characteristics.service_room != undefined
        ? data.characteristics.service_room
        : null;
    body["service_room_in_sqm"] =
      data.characteristics.service_room_in_sqm != undefined
        ? data.characteristics.service_room_in_sqm
        : null;
    body["owned_terrace"] =
      data.characteristics.owned_terrace != undefined
        ? data.characteristics.owned_terrace
        : null;
    body["owned_terrace_in_sqm"] =
      data.characteristics.owned_terrace_in_sqm != undefined
        ? data.characteristics.owned_terrace_in_sqm
        : null;
    body["playroom"] =
      data.characteristics.playroom != undefined
        ? data.characteristics.playroom
        : null;
    body["playroom_in_sqm"] =
      data.characteristics.playroom_in_sqm != undefined
        ? data.characteristics.playroom_in_sqm
        : null;
    body["payment_in_advance_date"] =
      data.characteristics.payment_in_advance_date != undefined
        ? data.characteristics.payment_in_advance_date
        : null;
    body["payment_in_advance_amount"] =
      data.characteristics.payment_in_advance_amount != undefined
        ? data.characteristics.payment_in_advance_amount
        : null;
    body["monthly_rent_price"] =
      data.characteristics.monthly_rent_price != undefined
        ? data.characteristics.monthly_rent_price
        : null;
    body["deposit_amount"] =
      data.characteristics.deposit_amount != undefined
        ? data.characteristics.deposit_amount
        : null;
    body["date_of_lease_expiration"] =
      data.characteristics.date_of_lease_expiration != undefined
        ? data.characteristics.date_of_lease_expiration
        : null;
    body["yearly_tax"] =
      data.characteristics.yearly_tax != undefined
        ? data.characteristics.yearly_tax
        : null;
    body["yearly_yield"] =
      data.characteristics.yearly_yield != undefined
        ? data.characteristics.yearly_yield
        : null;
    body["yearly_income_before_tax"] =
      data.characteristics.yearly_income_before_tax != undefined
        ? data.characteristics.yearly_income_before_tax
        : null;
    body["mortgage"] = CoreHelper.parseBoolean(data.characteristics.mortgage);
    body["bank_name"] =
      data.characteristics.bank_name != undefined
        ? data.characteristics.bank_name
        : null;
    body["amount_or_percentage"] =
      data.characteristics.amount_or_percentage != undefined
        ? data.characteristics.amount_or_percentage
        : null;
    body["energy_class"] =
      data.characteristics.energy_class != undefined
        ? data.characteristics.energy_class[0]
        : null;
    body["suitable_for_investment"] =
      data.characteristics.suitable_for_investment != undefined
        ? data.characteristics.suitable_for_investment[0]
        : null;
    body["autonomous_heating"] = CoreHelper.parseBoolean(
      data.characteristics.autonomous_heating
    );
    body["air_conditioning"] = CoreHelper.parseBoolean(
      data.characteristics.air_conditioning
    );
    body["solar_water_heater"] = CoreHelper.parseBoolean(
      data.characteristics.solar_water_heater
    );
    body["fireplace"] = CoreHelper.parseBoolean(data.characteristics.fireplace);
    body["gas"] = CoreHelper.parseBoolean(data.characteristics.gas);
    body["stove"] = CoreHelper.parseBoolean(data.characteristics.stove);
    body["central_heating"] = CoreHelper.parseBoolean(
      data.characteristics.central_heating
    );
    body["under_floor_heating"] = CoreHelper.parseBoolean(
      data.characteristics.under_floor_heating
    );
    body["natural_gas"] = CoreHelper.parseBoolean(
      data.characteristics.natural_gas
    );
    body["oil"] = CoreHelper.parseBoolean(data.characteristics.oil);
    body["electrical_heating"] = CoreHelper.parseBoolean(
      data.characteristics.electrical_heating
    );
    body["heat_accumulator"] = CoreHelper.parseBoolean(
      data.characteristics.heat_accumulator
    );
    // Κατάσταση
    if (CoreHelper.parseBoolean(data.characteristics.perfect_condition))
      body["buildingStatusSelected"] = "Άριστη κατάσταση";
    if (CoreHelper.parseBoolean(data.characteristics.good_condition))
      body["buildingStatusSelected"] = "Καλή κατάσταση";
    if (CoreHelper.parseBoolean(data.characteristics.under_construction))
      body["buildingStatusSelected"] = "Υπο κατασκευή";
    if (CoreHelper.parseBoolean(data.characteristics.newly_built))
      body["buildingStatusSelected"] = "Νεόδμητο";
    if (CoreHelper.parseBoolean(data.characteristics.unfinished))
      body["buildingStatusSelected"] = "Ημιτελές";
    if (CoreHelper.parseBoolean(data.characteristics.needs_renovation))
      body["buildingStatusSelected"] = "Χρήζει ανακαίνισης";
    if (CoreHelper.parseBoolean(data.characteristics.double_layer_windows))
      body["selectBoxesSelected"].push("Διπλά τζάμια");
    if (CoreHelper.parseBoolean(data.characteristics.wooden_frames))
      body["selectBoxesSelected"].push("Ξύλινα κουφώματα");
    if (CoreHelper.parseBoolean(data.characteristics.synthetic_frames))
      body["selectBoxesSelected"].push("Συνθετικά κουφώματα");
    if (CoreHelper.parseBoolean(data.characteristics.electrical_frames))
      body["selectBoxesSelected"].push("Ηλεκτρικά κουφώματα");
    if (CoreHelper.parseBoolean(data.characteristics.aluminum_frames))
      body["selectBoxesSelected"].push("Κουφώματα αλουμίνιου");
    if (CoreHelper.parseBoolean(data.characteristics.sliding_rollers))
      body["selectBoxesSelected"].push("Συρόμενα Ρολά");
    if (CoreHelper.parseBoolean(data.characteristics.screens))
      body["selectBoxesSelected"].push("Σίτες");
    //Δάπεδο
    if (CoreHelper.parseBoolean(data.characteristics.marble_floors))
      body["floorSelected"].push("Μάρμαρο");
    if (CoreHelper.parseBoolean(data.characteristics.wooden_floor))
      body["floorSelected"].push("Ξύλο");
    if (CoreHelper.parseBoolean(data.characteristics.stone_floors))
      body["floorSelected"].push("Πέτρα");
    if (CoreHelper.parseBoolean(data.characteristics.tile_floors))
      body["floorSelected"].push("Πλακάκι");
    if (CoreHelper.parseBoolean(data.characteristics.mosaic_floor))
      body["floorSelected"].push("Μωσαϊκό");
    if (CoreHelper.parseBoolean(data.characteristics.marble_wooden_floor))
      body["floorSelected"].push("Μάρμαρο - Ξύλο");
    if (CoreHelper.parseBoolean(data.characteristics.marble_tile_floor))
      body["floorSelected"].push("Μάρμαρο-Πλακάκι");
    if (CoreHelper.parseBoolean(data.characteristics.stone_wooden_floors))
      body["floorSelected"].push("Πέτρα-Ξύλο");
    if (CoreHelper.parseBoolean(data.characteristics.stone_marble_floors))
      body["floorSelected"].push("Μάρμαρο-Ξύλο");
    if (CoreHelper.parseBoolean(data.characteristics.tile_wooden_floor))
      body["floorSelected"].push("Πλακάκι-Ξύλο");
    if (CoreHelper.parseBoolean(data.characteristics.mosaic_wooden_floors))
      body["floorSelected"].push("Μωσαϊκό-Ξύλο");
    if (CoreHelper.parseBoolean(data.characteristics.industrial_floor))
      body["floorSelected"].push("Βιομηχανικό δάπεδο");
    if (CoreHelper.parseBoolean(data.characteristics.laminate))
      body["floorSelected"].push("Laminate");
    // Τεχνικά γνωρίσματα
    body["furnished"] = CoreHelper.parseBoolean(data.characteristics.furnished);
    body["bright"] = CoreHelper.parseBoolean(data.characteristics.bright);
    body["painted"] = CoreHelper.parseBoolean(data.characteristics.painted);
    body["electrical_equipment"] = CoreHelper.parseBoolean(
      data.characteristics.electrical_equipment
    );
    body["satellite_antenna"] = CoreHelper.parseBoolean(
      data.characteristics.satellite_antenna
    );
    body["luxurious"] = CoreHelper.parseBoolean(data.characteristics.luxurious);
    body["screens"] = CoreHelper.parseBoolean(data.characteristics.screens);
    body["pets_allowed"] = CoreHelper.parseBoolean(
      data.characteristics.pets_allowed
    );
    body["internal_staircase"] = CoreHelper.parseBoolean(
      data.characteristics.internal_staircase
    );
    body["elevator"] = CoreHelper.parseBoolean(data.characteristics.elevator);
    body["safety_door"] = CoreHelper.parseBoolean(
      data.characteristics.safety_door
    );
    body["alarm"] = CoreHelper.parseBoolean(data.alarm);
    body["security_cam"] = CoreHelper.parseBoolean(data.security_cam);
    body["cctv"] = CoreHelper.parseBoolean(data.cctv);
    body["security_systems"] = CoreHelper.parseBoolean(data.security_systems);
    // Ασφάλεια
    body["security_systems"] = CoreHelper.parseBoolean(data.security_systems);
    // Τεχνικά Γνωρίσματα - θέα
    if (CoreHelper.parseBoolean(data.characteristics.city_view))
      body["chooseAViewSelected"].push("Πόλη");
    if (CoreHelper.parseBoolean(data.characteristics.mountain_view))
      body["chooseAViewSelected"].push("Βουνό");
    if (
      CoreHelper.parseBoolean(data.characteristics.acropolis_or_monuments_view)
    )
      body["chooseAViewSelected"].push("Μνημεία");
    if (CoreHelper.parseBoolean(data.characteristics.lake_view))
      body["chooseAViewSelected"].push("Λίμνη");
    if (CoreHelper.parseBoolean(data.characteristics.sea_view))
      body["chooseAViewSelected"].push("Θάλασσα");
    if (CoreHelper.parseBoolean(data.characteristics.river_view))
      body["chooseAViewSelected"].push("Ποτάμι");
    if (CoreHelper.parseBoolean(data.characteristics.park_view))
      body["chooseAViewSelected"].push("Πάρκο");
    // Ζώνη
    if (CoreHelper.parseBoolean(data.characteristics.residential_zone))
      body["zoneSelected"].push("Οικιστική ζώνη");
    if (CoreHelper.parseBoolean(data.characteristics.commercial_zone))
      body["zoneSelected"].push("Εμπορική ζώνη");
    if (CoreHelper.parseBoolean(data.characteristics.rural_zone))
      body["zoneSelected"].push("Αγροτική ζώνη");
    if (CoreHelper.parseBoolean(data.characteristics.industrial_zone))
      body["zoneSelected"].push("Βιομηχανική ζώνη");
    if (CoreHelper.parseBoolean(data.characteristics.regeneration_zone))
      body["zoneSelected"].push("Ζώνη ανάπλασης");
    if (CoreHelper.parseBoolean(data.characteristics.outside_city_plan))
      body["zoneSelected"].push("Εκτός σχεδίου");
    // Θεση
    if (CoreHelper.parseBoolean(data.characteristics.eastern_western_coverage))
      body["selectPositionSelected"].push("Ανατολικοδυτικός");
    if (CoreHelper.parseBoolean(data.characteristics.internal))
      body["selectPositionSelected"].push("Εσωτερικό");
    if (CoreHelper.parseBoolean(data.characteristics.facade))
      body["selectPositionSelected"].push("Προσόψεως");
    if (CoreHelper.parseBoolean(data.characteristics.loft))
      body["selectPositionSelected"].push("Ρετιρέ");
    if (CoreHelper.parseBoolean(data.characteristics.double_frontage))
      body["selectPositionSelected"].push("Διαμπερές");
    if (CoreHelper.parseBoolean(data.characteristics.on_main_road))
      body["selectPositionSelected"].push("Σε κεντρικό δρόμο");
    if (CoreHelper.parseBoolean(data.characteristics.on_sidewalk))
      body["selectPositionSelected"].push("Σε πεζόδρομο");
    if (CoreHelper.parseBoolean(data.characteristics.corner))
      body["selectPositionSelected"].push("Γωνιακό");
    //Προσανατολισμος
    if (CoreHelper.parseBoolean(data.characteristics.eastern_coverage))
      body["orientationSelected"].push(i18n.t("eastern"));
    if (CoreHelper.parseBoolean(data.characteristics.eastern_western_coverage))
      body["orientationSelected"].push(i18n.t("eastWest"));
    if (CoreHelper.parseBoolean(data.characteristics.eastern_meridian_coverage))
      body["orientationSelected"].push(i18n.t("easternMeridian"));
    if (CoreHelper.parseBoolean(data.characteristics.northern_coverage))
      body["orientationSelected"].push(i18n.t("north"));
    if (CoreHelper.parseBoolean(data.characteristics.northern_eastern_coverage))
      body["orientationSelected"].push(i18n.t("northEast"));
    if (CoreHelper.parseBoolean(data.characteristics.northern_western_coverage))
      body["orientationSelected"].push(i18n.t("northWest"));
    if (CoreHelper.parseBoolean(data.characteristics.western_coverage))
      body["orientationSelected"].push(i18n.t("west"));
    if (CoreHelper.parseBoolean(data.characteristics.meridian_coverage))
      body["orientationSelected"].push(i18n.t("meridian"));
    if (CoreHelper.parseBoolean(data.characteristics.southern_coverage))
      body["orientationSelected"].push(i18n.t("south"));
    if (CoreHelper.parseBoolean(data.characteristics.southern_eastern_coverage))
      body["orientationSelected"].push(i18n.t("southEast"));
    if (CoreHelper.parseBoolean(data.characteristics.southern_western_coverage))
      body["orientationSelected"].push(i18n.t("southWest"));
    if (CoreHelper.parseBoolean(data.characteristics.western_meridian_coverage))
      body["orientationSelected"].push(i18n.t("westMeridian"));
    // parking
    body["parking"] = CoreHelper.parseBoolean(data.characteristics.parking);
    body["parking_spots"] = CoreHelper.parseBoolean(
      data.characteristics.parking_spots
    );
    if (CoreHelper.parseBoolean(data.characteristics.enclosed_parking))
      body["typeOfParkingSelected"].push(i18n.t("closed"));
    if (CoreHelper.parseBoolean(data.characteristics.underground_parking))
      body["typeOfParkingSelected"].push(i18n.t("basement"));
    if (CoreHelper.parseBoolean(data.characteristics.pilot_parking))
      body["typeOfParkingSelected"].push(i18n.t("pilot"));
    if (CoreHelper.parseBoolean(data.characteristics.open_parking))
      body["typeOfParkingSelected"].push(i18n.t("open"));
    body["garden"] = CoreHelper.parseBoolean(data.characteristics.garden);
    body["garden_in_sqm"] = CoreHelper.parseBoolean(
      data.characteristics.garden_in_sqm
    );
    body["pool"] = CoreHelper.parseBoolean(data.characteristics.pool);
    body["pool_in_sqm"] = CoreHelper.parseBoolean(
      data.characteristics.pool_in_sqm
    );
    body["veranda"] = CoreHelper.parseBoolean(data.characteristics.veranda);
    body["access_for_disabled_people"] = CoreHelper.parseBoolean(
      data.characteristics.access_for_disabled_people
    );
    body["awnings"] = CoreHelper.parseBoolean(data.characteristics.awnings);
    body["bbq"] = CoreHelper.parseBoolean(data.characteristics.bbq);
    body["separate_entrance"] = CoreHelper.parseBoolean(
      data.characteristics.separate_entrance
    );
    body["suitable_for_students"] = CoreHelper.parseBoolean(
      data.characteristics.suitable_for_students
    );
    body["airbnb"] = CoreHelper.parseBoolean(data.characteristics.airbnb);
    body["suitable_for_commercial_use"] = CoreHelper.parseBoolean(
      data.characteristics.suitable_for_commercial_use
    );
    body["suitable_for_commercial_use"] = CoreHelper.parseBoolean(
      data.characteristics.suitable_for_commercial_use
    );
    body["distance_from_sea"] =
      data.characteristics.distance_from_sea != undefined
        ? data.characteristics.distance_from_sea
        : null;
    body["distance_from_airport"] =
      data.characteristics.distance_from_airport != undefined
        ? data.characteristics.distance_from_airport
        : null;
    body["distance_from_acropolis"] =
      data.characteristics.distance_from_acropolis != undefined
        ? data.characteristics.distance_from_acropolis
        : null;
    body["distance_from_bank"] =
      data.characteristics.distance_from_bank != undefined
        ? data.characteristics.distance_from_bank
        : null;
    body["distance_from_schools"] =
      data.characteristics.distance_from_schools != undefined
        ? data.characteristics.distance_from_schools
        : null;
    body["distance_from_super_market"] =
      data.characteristics.distance_from_super_market != undefined
        ? data.characteristics.distance_from_super_market
        : null;
    body["distance_from_metro"] =
      data.characteristics.distance_from_metro != undefined
        ? data.characteristics.distance_from_metro
        : null;
    body["distance_from_commuting_stops"] =
      data.characteristics.distance_from_commuting_stops != undefined
        ? data.characteristics.distance_from_commuting_stops
        : null;
    body["distance_from_train"] =
      data.characteristics.distance_from_train != undefined
        ? data.characteristics.distance_from_train
        : null;
    body["distance_from_square"] =
      data.characteristics.distance_from_square != undefined
        ? data.characteristics.distance_from_square
        : null;
    body["distance_from_park"] =
      data.characteristics.distance_from_park != undefined
        ? data.characteristics.distance_from_park
        : null;
    body["distance_from_hospital"] =
      data.characteristics.distance_from_hospital != undefined
        ? data.characteristics.distance_from_hospital
        : null;
    body["distance_from_suburban"] =
      data.characteristics.distance_from_suburban != undefined
        ? data.characteristics.distance_from_suburban
        : null;
    body["distance_from_tram"] =
      data.characteristics.distance_from_tram != undefined
        ? data.characteristics.distance_from_tram
        : null;
    body["distance_from_international_schools"] =
      data.characteristics.distance_from_international_schools != undefined
        ? data.characteristics.distance_from_international_schools
        : null;
    body["key"] = CoreHelper.parseBoolean(data.characteristics.keys) ? 1 : 0;
    body["pano"] = CoreHelper.parseBoolean(data.characteristics.pano) ? 1 : 0;
    body["banners_external"] = CoreHelper.parseBoolean(
      data.characteristics.banners_external
    )
      ? 1
      : 0;
    body["banner"] = CoreHelper.parseBoolean(data.characteristics.banner)
      ? 1
      : 0;
    // (σε ποια άτομα θα είναι ορατό)
    if (
      CoreHelper.parseBoolean(data.characteristics.visible_only_to_me) == true
    )
      body["selectedSecurity"] = 0;
    else if (
      CoreHelper.parseBoolean(data.characteristics.branch_visibility) == true
    )
      body["selectedSecurity"] = 1;
    else if (
      CoreHelper.parseBoolean(data.characteristics.network_visibility) == true
    )
      body["selectedSecurity"] = 2;
    else if (CoreHelper.parseBoolean(data.characteristics.visible) == true)
      body["selectedSecurity"] = 3;
    // Περιγραφή
    if (data.descriptions) {
      body["greekDescription"] = data.descriptions.filter((d) => {
        if (d.Language == "gre") return d;
      })[0]?.Description;
      body["englishDescription"] = data.descriptions.filter((d) => {
        if (d.Language == "eng") return d;
      })[0]?.Description;
      body["arabicDescription"] = data.descriptions.filter((d) => {
        if (d.Language == "ara") return d;
      })[0]?.Description;
      body["chinesseDescription"] = data.descriptions.filter((d) => {
        if (d.Language == "chn") return d;
      })[0]?.Description;
    }
    body["estateCode"] = data.EstateCode != undefined ? data.EstateCode : null;
    body["orderCode"] = data.EstateCode != undefined ? data.EstateCode : null;
    body["owner"] = data.CustomerID != undefined ? data.CustomerID : null;
    body["agent"] = data.UserID != undefined ? data.UserID : null;
    body["commandType"] =
      data.Assignation != undefined ? data.Assignation : null;
    body["status"] = data.Status != undefined ? data.Status : null;
    body["outlandCommission"] =
      data.OutlandCommission != undefined ? data.OutlandCommission : null;
    body["inlandCommission"] =
      data.InlandCommission != undefined ? data.InlandCommission : null;
    body["outlandCommissionNoTax"] =
      data.OutlandCommissionNoTax != undefined
        ? data.OutlandCommissionNoTax
        : null;
    body["personalComments"] =
      data.personalComments != undefined ? data.personalComments : null;
    body["youtubeLink"] = data.Youtube != undefined ? data.Youtube : null;
    body["gps"] = data.GPS != undefined ? data.GPS : null;
    body["address"] =
      data.GoogleAddress != undefined ? data.GoogleAddress : null;
    // Location
    if (data.Area) {
      let splitArea = data.Area.split("/");
      body["region"] = splitArea[0] ? splitArea[0] : null;
      body["state"] = splitArea[1] ? splitArea[1] : null;
      body["district"] = splitArea[2] ? splitArea[2] : null;
      body["borough"] = splitArea[3] ? splitArea[3] : null;
    }
    return body;
  },
  generateDescription(data) {
    if (data && data.Description && data.Description != "") {
      return data.Description;
    } else {
      if (i18n.locale == "gr" && data && data.descriptions) {
        const result = data.descriptions.find(
          ({ Language }) => Language == "gre"
        );
        return result && result.Description
          ? result.Description
          : this.automatedDescription(data);
      } else if (i18n.locale == "en" && data && data.descriptions) {
        const result = data.descriptions.find(
          ({ Language }) => Language == "eng"
        );
        return result && result.Description
          ? result.Description
          : this.automatedDescription(data);
      }
    }
  },

  automatedDescription(estateExport) {
    let desc = [];
    //let handwrittenValueGreekDesc = $('#handwrittenValueGreekDesc').val();
    //let handwrittenValueEnglishDesc = $('#handwrittenValueEnglishDesc').val();
    // for(let i=0;i<dummyArray.length;i++){desc.push(dummyArray[i].normalize("NFD")
    // .replace(/[\u0300-\u036f]/g, "")
    // .toUpperCase());}
    if (estateExport["Area"]) {
      let dummyArray = estateExport["Area"].split("/");
      if (dummyArray) {
        desc.push(dummyArray[dummyArray.length - 1]);
      }
    }
    //ChatacteristicsInfo
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport["Category"]
      )
    ) {
      desc.push(estateExport["Category"]);
    }
    if (
      !["", null, undefined, false, "false", 0].includes(estateExport["Type"])
    ) {
      desc.push(estateExport["Type"]);
    }
    if (
      !["", null, undefined, false, "false", 0].includes(estateExport["Kind"])
    ) {
      desc.push(estateExport["Kind"]);
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["suitable_for_students"]
      )
    ) {
      desc.push("Φοιτητικό");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["airbnb"]
      )
    ) {
      desc.push("Airbnb");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["suitable_for_commercial_use"]
      )
    ) {
      desc.push("Επαγγελματική χρήση");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(estateExport["Unit"])
    ) {
      desc.push(estateExport["Unit"] + " τμ");
    }

    let floors = [];

    if (
      !["", null, undefined, false, "false", 0].includes(estateExport["floors"])
    ) {
      for (let i = 0; i < estateExport["floors"].length; i++) {
        floors.push(estateExport["floors"][i]["FloorLiteral"]);
      }
      if (floors.length > 0) {
        if (floors.length > 1) {
          desc.push("Όροφοι : " + floors.join(", "));
        } else {
          desc.push("Όροφος : " + floors.join(", "));
        }
      }
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["levels"]
      )
    ) {
      desc.push(
        estateExport.characteristics["levels"] > 1
          ? estateExport.characteristics["levels"] + " Επίπεδα"
          : estateExport.characteristics["levels"] + " Επίπεδο"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["bathrooms"]
      )
    ) {
      desc.push(
        estateExport.characteristics["bathrooms"] > 1
          ? estateExport.characteristics["bathrooms"] + " Μπάνια"
          : estateExport.characteristics["bathrooms"] + " Μπάνιο"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["balconies"]
      )
    ) {
      desc.push(
        estateExport.characteristics["balconies"] > 1
          ? estateExport.characteristics["balconies"] + " Μπαλκόνια"
          : estateExport.characteristics["balconies"] + " Μπαλκόνι"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["balconies_in_sqm"]
      )
    ) {
      desc.push(
        estateExport.characteristics["balconies_in_sqm"] +
          " τμ Εμβαδόν Μπαλκονιών"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["kitchen_rooms"]
      )
    ) {
      desc.push(
        estateExport.characteristics["kitchen_rooms"] > 1
          ? estateExport.characteristics["kitchen_rooms"] + " Κουζίνες"
          : estateExport.characteristics["kitchen_rooms"] + " Κουζίνα"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["living_rooms"]
      )
    ) {
      desc.push(
        estateExport.characteristics["living_rooms"] > 1
          ? estateExport.characteristics["living_rooms"] + " Σαλόνια"
          : estateExport.characteristics["living_rooms"] + " Σαλόνι"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["wc"]
      )
    ) {
      desc.push(estateExport.characteristics["wc"] + " WC");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["parking_spots"]
      )
    ) {
      desc.push(
        estateExport.characteristics["parking_spots"] > 1
          ? estateExport.characteristics["parking_spots"] + " Θέσεις Πάρκινγκ"
          : estateExport.characteristics["parking_spots"] + " Θέση Πάρκινγκ"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["underground_parking"]
      )
    ) {
      desc.push("Υπόγειο Parking");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["pilot_parking"]
      )
    ) {
      desc.push("Πιλοτική στάθμευση");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["open_parking"]
      )
    ) {
      desc.push("Ανοιχτό Parking");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport["yearOfRenovation"]
      )
    ) {
      desc.push(estateExport["BuildYear"] + " Έτος κατασκευής");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport["yearOfInnovation"]
      )
    ) {
      desc.push(estateExport["RenovationYear"] + " Έτος ανακαίνισης");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport["CurrentUse"]
      )
    ) {
      desc.push(estateExport["CurrentUse"]);
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["energy_class"]
      )
    ) {
      desc.push(
        estateExport.characteristics["energy_class"] + " Ενεργειακή Κλάση"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport["storage_room"]
      )
    ) {
      desc.push("Aποθήκη");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(estateExport["attic"])
    ) {
      desc.push("Σοφίτα");
    }

    //otherFeatures
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["renovate"]
      )
    ) {
      desc.push("Ανακαινισμένο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["perfect_condition"]
      )
    ) {
      desc.push("Άριστη κατάσταση");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["under_construction"]
      )
    ) {
      desc.push("Υπο κατασκευή");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["good_condition"]
      )
    ) {
      desc.push("Καλή κατάσταση");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["unfinished"]
      )
    ) {
      desc.push("Ημιτελές");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["needs_renovation"]
      )
    ) {
      desc.push("Χρήζει ανακαίνισης");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["furnished"]
      )
    ) {
      desc.push("Επιπλωμένο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["bright"]
      )
    ) {
      desc.push("Φωτεινό");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["painted"]
      )
    ) {
      desc.push("Βαμμένο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["electrical_equipment"]
      )
    ) {
      desc.push("Ηλεκτρικός Εξοπλισμός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["satellite_antenna"]
      )
    ) {
      desc.push("Δορυφορική Κεραία");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["luxurious"]
      )
    ) {
      desc.push("Πολυτελές");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["pets_allowed"]
      )
    ) {
      desc.push("Κατοικίδια Ευπρόσδεκτα");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["internal_staircase"]
      )
    ) {
      desc.push("Εσωτερική Σκάλα");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["elevator"]
      )
    ) {
      desc.push("Ασανσέρ");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["dimensions"]
      )
    ) {
      desc.push(estateExport.characteristics["dimensions"] + " μ.");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["can_build"]
      )
    ) {
      desc.push(estateExport.characteristics["can_build"] + " τ.μ.");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["coverage_coefficient"]
      )
    ) {
      desc.push(estateExport.characteristics["coverage_coefficient"] + " τ.μ.");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["structure_coefficient"]
      )
    ) {
      desc.push(
        estateExport.characteristics["structure_coefficient"] + " τ.μ."
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["benefits"]
      )
    ) {
      desc.push(estateExport.characteristics["benefits"]);
    }

    //AdditionalSpacesInfo
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["attic_in_sqm"]
      )
    ) {
      desc.push(
        estateExport.characteristics["attic_in_sqm"] + " τμ Εμβαδόν Σοφίτας"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["service_room"]
      )
    ) {
      desc.push("Δωμάτιο Υπηρεσίας");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["service_room_in_sqm"]
      )
    ) {
      desc.push(
        estateExport.characteristics["service_room_in_sqm"] +
          " τμ Εμβαδόν Υπηρεσίας"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["storage_room_sqm"]
      )
    ) {
      desc.push(
        estateExport.characteristics["storage_room_sqm"] +
          " τμ Εμβαδόν Αποθηκευτικού Χώρου"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport["owned_terrace"]
      )
    ) {
      desc.push("Βεράντα");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["owned_terrace_in_sqm"]
      )
    ) {
      desc.push(
        estateExport.characteristics["owned_terrace_in_sqm"] +
          " τμ Εμβαδόν Βεράντας"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport["playroom"]
      )
    ) {
      desc.push("Playroom");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["playroom_in_sqm"]
      )
    ) {
      desc.push(
        estateExport.characteristics["playroom_in_sqm"] + " τμ Εμβαδόν Playroom"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(estateExport["garden"])
    ) {
      desc.push("Κήπος");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["garden_in_sqm"]
      )
    ) {
      desc.push(
        estateExport.characteristics["garden_in_sqm"] + " τμ Eμβαδόν Κύπου"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(estateExport["pool"])
    ) {
      desc.push("Πισίνα");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["pool_in_sqm"]
      )
    ) {
      desc.push(
        estateExport.characteristics["pool_in_sqm"] + " τμ Εμβαδόν Πισίνας"
      );
    }

    //DistanceInfo
    let distances = [];
    let distanceUnit =
      estateExport.DistanceMeasure == "μέτρα" ||
      estateExport.DistanceMeasure == 0
        ? "μ."
        : estateExport.DistanceMeasure == "χλμ" ||
          estateExport.DistanceMeasure == 1
        ? "χλμ"
        : "";
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_sea"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_sea"] +
          " " +
          distanceUnit +
          " από την θάλασσα"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_airport"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_airport"] +
          " " +
          distanceUnit +
          " από το αεροδρόμιο"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_acropolis"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_acropolis"] +
          " " +
          distanceUnit +
          " από αξιοθέατα"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_bank"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_bank"] +
          " " +
          distanceUnit +
          " από την τράπεζα"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_schools"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_schools"] +
          " " +
          distanceUnit +
          " από σχολεία"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_super_market"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_super_market"] +
          " " +
          distanceUnit +
          " από super market"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_metro"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_metro"] +
          " " +
          distanceUnit +
          " από το μετρό"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_commuting_stops"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_commuting_stops"] +
          " " +
          distanceUnit +
          " από στάσεις μετακίνησης"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_train"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_train"] +
          " " +
          distanceUnit +
          " από στάση τρένου"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_square"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_square"] +
          " " +
          distanceUnit +
          " από πλατεία"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_park"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_park"] +
          " " +
          distanceUnit +
          " από πάρκο"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_hospital"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_hospital"] +
          " " +
          distanceUnit +
          " από νοσοκομείο"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_suburban"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_suburban"] +
          " " +
          distanceUnit +
          " από στάση προαστιακού"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_tram"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_tram"] +
          " " +
          distanceUnit +
          " από στάση tram"
      );
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["distance_from_international_schools"]
      )
    ) {
      distances.push(
        estateExport.characteristics["distance_from_international_schools"] +
          " " +
          distanceUnit +
          " από διεθνή σχολεία"
      );
    }
    if (distances.length > 0) {
      desc.push("Αποστάσεις : " + distances.join(", "));
    }

    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["internal"]
      )
    ) {
      desc.push("Εσωτερική Θέση");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["facade"]
      )
    ) {
      desc.push("Θέση Προσόψεως");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["penthouse"]
      )
    ) {
      desc.push("Ρετιρέ");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["double_frontage"]
      )
    ) {
      desc.push("Διαμπερές");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["on_main_road"]
      )
    ) {
      desc.push("Σε Κεντρικό Δρόμο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["on_sidewalk"]
      )
    ) {
      desc.push("Σε Πεζόδρομο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["corner"]
      )
    ) {
      desc.push("Γωνιακή Θέση");
    }

    //otherFeatures
    let orientation = [];
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["eastern_coverage"]
      )
    ) {
      orientation.push("Ανατολικός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["eastern_western_coverage"]
      )
    ) {
      orientation.push("Ανατολικοδυτικός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["eastern_meridian_coverage"]
      )
    ) {
      orientation.push("Ανατολικομεσημβρινός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["northern_coverage"]
      )
    ) {
      orientation.push("Βόρειος");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["northern_eastern_coverage"]
      )
    ) {
      orientation.push("Βορειοανατολικός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["north_west"]
      )
    ) {
      orientation.push("Βορειοδυτικός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["western_coverage"]
      )
    ) {
      orientation.push("Δυτικός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["meridian_coverage"]
      )
    ) {
      orientation.push("Μεσημβρινός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["southern_coverage"]
      )
    ) {
      orientation.push("Βορειοδυτικός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["southern_eastern_coverage"]
      )
    ) {
      orientation.push("Νοτιοανατολικός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["southern_western_coverage"]
      )
    ) {
      orientation.push("Νοτιοδυτικός");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["western_meridian_coverage"]
      )
    ) {
      orientation.push("Δυτικομεσημβρινός");
    }
    if (orientation.length > 0) {
      desc.push("Προσανατολισμός : " + orientation.join(", "));
    }

    let ground = [];
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["marble_floors"]
      )
    ) {
      ground.push("Μάρμαρο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["wooden_floor"]
      )
    ) {
      ground.push("Ξύλο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["stone_floors"]
      )
    ) {
      ground.push("Πέτρα");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["tile_floors"]
      )
    ) {
      ground.push("Πλακάκι");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["mosaic_floor"]
      )
    ) {
      ground.push("Μωσαϊκό");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["marble_wooden_floor"]
      )
    ) {
      ground.push("Μάρμαρο - Ξύλο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["marble_tile_floor"]
      )
    ) {
      ground.push("Μάρμαρο - Πλακάκι");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["stone_wooden_floors"]
      )
    ) {
      ground.push("Πέτρα - Ξύλο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["stone_marble_floors"]
      )
    ) {
      ground.push("Πέτρα - Μάρμαρο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["tile_wooden_floor"]
      )
    ) {
      ground.push("Πλακάκι - Ξύλο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["mosaic_wooden_floors"]
      )
    ) {
      ground.push("Μωσαϊκό - Ξύλο");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["industrial_floor"]
      )
    ) {
      ground.push("Βιομηχανικό");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["laminate"]
      )
    ) {
      ground.push("Laminate");
    }
    if (ground.length > 0) {
      desc.push("Δάπεδο : " + ground.join(", "));
    }

    let zone = [];
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["residential_zone"]
      )
    ) {
      zone.push("Οικιστική");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["commercial_zone"]
      )
    ) {
      zone.push("Εμπορική");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["rural_zone"]
      )
    ) {
      zone.push("Αγροτική");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["industrial_zone"]
      )
    ) {
      zone.push("Βιομηχανική");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["regeneration_zone"]
      )
    ) {
      zone.push("Ανάπλασης");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["outside_city_plan"]
      )
    ) {
      zone.push("Εκτός σχεδίου");
    }
    if (zone.length > 0) {
      desc.push("Ζώνη : " + zone.join(", "));
    }

    let frames = [];
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["double_layer_windows"]
      )
    ) {
      frames.push("Διπλά Τζάμια");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["wooden_frames"]
      )
    ) {
      frames.push("Ξύλινα");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["synthetic_frames"]
      )
    ) {
      frames.push("Συνθετικά");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["electrical_frames"]
      )
    ) {
      frames.push("Ηλεκτρικά");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["aluminum_frames"]
      )
    ) {
      frames.push("Αλουμινίου");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["sliding_rollers"]
      )
    ) {
      frames.push("Συρόμενα Ρολά");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["screens"]
      )
    ) {
      frames.push("Σίτες");
    }
    if (frames.length > 0) {
      desc.push("Κουφώματα : " + frames.join(", "));
    }

    let view = [];
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["urban_view"]
      )
    ) {
      view.push("Πόλη");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["mountain_view"]
      )
    ) {
      view.push("Βουνό");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["acropolis_or_monuments_view"]
      )
    ) {
      view.push("Ακρόπολη / Μνημεία");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["lake_view"]
      )
    ) {
      view.push("Λίμνη");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["sea_view"]
      )
    ) {
      view.push("Θάλασσα");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["river_view"]
      )
    ) {
      view.push("Ποτάμι");
    }
    if (
      !["", null, undefined, false, "false", 0].includes(
        estateExport.characteristics["park_view"]
      )
    ) {
      view.push("Πάρκο");
    }
    if (view.length > 0) {
      desc.push("Θέα : " + view.join(", "));
    }

    return desc.join(", ");
  },
};
