export default {
  state: {
    fromAgent: null,
    toAgent: null,
    createdFrom: null,
    createdTo: null,
    estateCode: null,
    approveStatus: null,
    requestStatus: null,
  },
  getters: {},
  mutations: {
    clearFilters(state) {
      state.fromAgent = null;
      state.toAgent = null;
      state.createdFrom = null;
      state.createdTo = null;
      state.estateCode = null;
      state.approveStatus = null;
      state.requestStatus = null;
    },
    setFromAgent(state, payload) {
      state.fromAgent = payload;
    },
    setToAgent(state, payload) {
      state.toAgent = payload;
    },
    setCreatedFrom(state, payload) {
      state.createdFrom = payload;
    },
    setCreatedTo(state, payload) {
      state.createdTo = payload;
    },
    setEstateCode(state, payload) {
      state.estateCode = payload;
    },
    setApproveStatus(state, payload) {
      state.approveStatus = payload;
    },
    setRequestStatus(state, payload) {
      state.requestStatus = payload;
    },
  },
  actions: {
    onClearFilters({ commit }) {
      commit("clearFilters");
    },
    onSetFromAgent({ commit }, payload) {
      commit("setFromAgent", payload);
    },
    onSetToAgent({ commit }, payload) {
      commit("setToAgent", payload);
    },
    onSetCreatedFrom({ commit }, payload) {
      commit("setCreatedFrom", payload);
    },
    onSetCreatedTo({ commit }, payload) {
      commit("setCreatedTo", payload);
    },
    onSetEstateCode({ commit }, payload) {
      commit("setEstateCode", payload);
    },
    onSetApproveStatus({ commit }, payload) {
      commit("setApproveStatus", payload);
    },
    onSetRequestStatus({ commit }, payload) {
      commit("setRequestStatus", payload);
    },
  },
};
