 <template>
  <v-col>
    <v-row class="align-center">
      <v-col class="pr-0 pb-0 headerTitle titleClass">
        <h3 id="title" class="headerTitleContent">
          {{ title }}
        </h3>
      </v-col>
      <v-col v-if="isVip" class="pb-0 pl-4 mt-1">
        <span class="vipClass textDecorationBold"> VIP </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pr-0 textDecorationBold headerSubTitle titleClass">
        <span class="headerSubTitleContent"> {{ subtitle }} </span>
      </v-col>
      <v-col class="align-self-center" cols="12" xl="2" lg="2" md="2" sm="2" xs="12">
        <v-row class="align-center flex-nowrap">
          <v-col class="flex-grow-0 pr-1">
            <v-img style="width: 20px !important" :src="require('@/assets/realEstateCard/star.svg')">
            </v-img>
          </v-col>
          <v-col style="color: #e89008" class="pl-0 pr-1 flex-grow-0 textDecorationBold font14">
            {{ rating }}
          </v-col>
          <v-col align="left" class="pl-0 pr-0 font-italic font14" style="font-family: Inter">
            ({{ ratingCount }})
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-2 align-center">
      <v-col v-if="![undefined, null, ''].includes(bedrooms)" class="pr-0 flex-grow-0 mr-4">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/bedroom.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ bedrooms }}
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="![undefined, null, ''].includes(bathrooms)" class="pr-0 flex-grow-0 mr-4">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/bathroom.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ bathrooms }}
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="![undefined, null, ''].includes(size)" class="pr-0 flex-grow-0 mr-4">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/sqft.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ size + " " + $t("sqftTable") }}
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="![undefined, null, ''].includes(constructionYear)" class="pr-0 flex-grow-0 mr-4">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/calendar.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ $t("yearOfConstruction") + ": " + constructionYear }}
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="![undefined, null, ''].includes(floor)" class="pr-0 flex-grow-0 mr-4">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/realEstateSilder/floor.png')">
            </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap interSemiBlack">
            {{ floor }}
            <!-- <span style="text-transform: lowercase"> {{ $t("floor") }} </span> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template> 

 <script>
 /*
Name:RealEstateHeader
Description: Component to render the real estate headers
API Property:
    title: String for header title
    isVip: Boolean if is vip
    subtitle: String for the header subtitle
    rating: Number, String for the property Rating
    ratingCount: Number, String for the property rating count
    bedrooms: Number, String for the property bedrooms
    bathrooms: Number, String for the property bathrooms
    size: String, Number for the property Sqft size
    constructionYear: Number, String for the property Construction year
    floor: Number, String for the property floor
*/
export default {
  name: "RealEstateHeader",
  props: {
    title: String, // Header title
    isVip: Boolean, // isVip
    subtitle: String, // Header subtitle
    rating: [Number, String], // Property Rating
    ratingCount: [Number, String], // Property Rating Count
    bedrooms: [Number, String], //Property bedrooms
    bathrooms: [Number, String], //Property Bathrooms
    size: [String, Number], // Property Sqft size
    constructionYear: [Number, String], //Property Construction year
    floor: [Number, String], //Property floor
  },
};
</script> 

 <style lang="scss" scoped>
 #title {
   color: #263573;
   font-family: CenturyGothicBold;
   font-weight: 500;
   font-size: 24px !important;
 }
 
 .subtitle {
   font-size: 15px !important;
   color: #3a3a40 !important;
   font-family: CenturyGothicBold;
   font-weight: 500;
 }
 
 .vipClass {
   background: rgba(232, 144, 8, 0.75);
   color: #ffffff;
   width: 40px;
   height: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .titleClass {
   flex-grow: 0;
   text-align: left;
   white-space: nowrap;
 }
 
 .interSemiBlack {
   font-family: InterSemiBold;
   color: #3a3a40;
 }
 </style> 
