<template>
  <div class="appBar">
    <!-- appbar -->
    <v-app-bar
      app
      color="primary"
      :class="
        mobileBreakpoint == true
          ? ''
          : mini == true
          ? 'appbarMiniContainer'
          : ''
      "
    >
      <v-app-bar-nav-icon color="white" @click="appBarNavIconClicked">
      </v-app-bar-nav-icon>
      <v-row
        class="ml-auto align-center flex-nowrap justify-end"
        :class="mobileBreakpoint == true ? 'mobileBreakpointAppBar' : ''"
      >
        <v-col class="pl-0 pr-0 flex-grow-0">
          <NotificationList
            menuIcon="mdi-phone-outline"
            :headerTitle="$t('myCalls')"
            menuPosition="onIcon"
            :data="callsData"
            :onHandleClick="onClickNotifPhone"
            :onHandleAll="onClickAllNotifPhone"
          />
        </v-col>
        <v-col class="pl-0 pr-0 flex-grow-0">
          <v-btn depressed icon color="white">
            <v-icon> mdi-clipboard-edit-outline </v-icon>
          </v-btn>
        </v-col>
        <v-col class="pl-0 pr-0 flex-grow-0">
          <v-btn depressed icon color="white">
            <v-icon> mdi-message-outline </v-icon>
          </v-btn>
        </v-col>
        <v-col class="pl-0 pr-0 flex-grow-0">
          <NotificationList
            menuIcon="mdi-bell-outline"
            :headerTitle="$t('myNotifications')"
            notificationPosition="onIcon"
            :data="notificationData"
            :onHandleClick="onClickNotifications"
            :onHandleAll="onClickAllNotifications"
          />
        </v-col>
        <v-col class="flex-grow-0 mr-8">
          <ProfileMenu
            :openProfile="openProfile"
            :openEditProfile="openEditProfile"
          />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :width="250"
      :touchless="true"
      height="100%"
      :permanent="!mobileBreakpoint"
      :mini-variant="!mobileBreakpoint ? mini : false"
      :expand-on-hover="mini ? true : false"
      app
    >
      <v-list-item class="list-height brandContainer">
        <v-list-item-avatar
          :class="mini ? 'mr-1 imgContainer' : 'mr-1 ml-8 imgContainer'"
        >
          <v-img :src="require('@/assets/general/logo.png')"> </v-img>
        </v-list-item-avatar>
        <v-list-item-title id="brandName">
          <span> REALTOR </span>
          <span class="pl-1" style="font-family: CenturyGothic !important">
            VOICE
          </span>
        </v-list-item-title>
      </v-list-item>
      <div class="menu">
        <v-list nav dense>
          <v-list-item-group>
            <v-list-item
              v-for="item in menu"
              :key="item.title"
              color="primary"
              class="menuContainer"
              :to="item.to"
            >
              <v-list-item-icon
                :class="mini ? 'ml-2' : 'mr-2 ml-10'"
                style="align-items: center"
              >
                <!--  <v-icon
                  :color="currentRoute == item.to ? 'primary' : ''"
                  class="links"
                  size="32"
                  > {{
                    currentRoute == item.to ? item.iconOpen : item.iconClosed
                  }} </v-icon
                >  -->
                <v-img
                  v-if="item.isImg"
                  :src="
                    currentRoute == item.to
                      ? require(`@/assets/menu/opened/${item.imgPath}`)
                      : require(`@/assets/menu/closed/${item.imgPath}`)
                  "
                />
                <Icon
                  v-else
                  :icon="
                    currentRoute == item.to ? item.iconOpen : item.iconClosed
                  "
                  :color="currentRoute == item.to ? '#263573' : '#707070'"
                  height="32"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :style="
                    currentRoute == item.to
                      ? 'align: left; font-family: CenturyGothicBold; font-weight: 500;'
                      : 'align: left; font-family: CenturyGothic;'
                  "
                >
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div dense>
          <v-divider />
          <v-list dense>
            <v-list-item
              style="cursor: pointer"
              @click="onLogoutHandler"
              class="menuContainer"
            >
              <v-list-item-icon
                :class="mini ? 'iconMiniContainer' : 'iconContainer'"
              >
                <Icon icon="carbon:logout" height="32" color="#707070" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  style="align: left; font-family: CenturyGothic"
                >
                  {{ $t("logout") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              style="cursor: pointer"
              @click="onSettingsHandler"
              class="menuContainer"
            >
              <v-list-item-icon
                :class="mini ? 'iconMiniContainer' : 'iconContainer'"
              >
                <Icon icon="carbon:settings" height="32" color="#707070" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  style="align: left; font-family: CenturyGothic"
                >
                  {{ $t("settings") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
/*
 Name:AppBar
 Description: Component to render the main app-bar for the app. The app-bar have profile settings and many other options.

 API Property:
    openProfile: Function from the parent to open the profile slider
    openEditProfile: Function from the parent to open the edit profile slider

    Endpoints Functions Called:
      -AuthRepo
        logout

    Components Used:
      Icon
      NotificationList
      ProfileMenu
*/
import { mapActions, mapGetters, mapState } from "vuex";
import NotificationList from "../NotificationList/NotificationList.vue";
import Repository from "../../services/Repository";
import notificationData from "../../static/notifications/notifications";
import ProfileMenu from "@/components/ProfileMenu/ProfileMenu";
import i18n from "@/i18n/i18n";
import { Icon } from "@iconify/vue2";

const AuthRepo = Repository.get("auth");

export default {
  name: "AppBar",
  components: { Icon, NotificationList, ProfileMenu },
  props: {
    openProfile: Function,
    openEditProfile: Function,
  },
  data() {
    return {
      tempImg: null,
      drawer: false,
      activeRoot: null,
      notificationData: notificationData.notificationData,
      callsData: notificationData.callsData,
      menuArray: [
        // {
        //   title: "Αρχική",
        //   iconOpen: "bx:bxs-dashboard",
        //   iconClosed: "lucide:layout-dashboard",
        //   to: "/dashboard",
        //   isImg: true,
        //   imgPath: "start.png",
        // },
        // {
        //   title: i18n.t("secretariat"),
        //   iconOpen: "bx:bxs-calendar-edit",
        //   iconClosed: "bx:bx-calendar-edit",
        //   to: "/secretary",
        //   isImg: true,
        //   imgPath: "secr.png",
        // },

        // {
        //   title: "Αναφορές/Στατιστικά",
        //   iconOpen: "bi:pie-chart-fill",
        //   iconClosed: "bi:pie-chart",
        //   to: "/statistics",
        //   isImg: false,
        // },
        {
          title: i18n.tc("realEstate", 2),
          iconOpen: "clarity:home-solid",
          iconClosed: "clarity:home-line",
          to: "/real_estate",
          isImg: false,
        },
        {
          title: i18n.t("requests"),
          iconOpen: "mdi:hand-extended",
          iconClosed: "mdi:hand-extended-outline",
          to: "/requests",
          isImg: true,
          imgPath: "request.png",
        },
        {
          title: i18n.tc("contacts", 2),
          iconOpen: "mdi-account-box",
          iconClosed: "cil:contact",
          to: "/contacts",
          isImg: false,
        },
        {
          title: "Ιδιοκτήτες",
          iconOpen: "mdi:account-clock",
          iconClosed: "mdi:account-clock-outline",
          to: "/owners",
          isImg: false,
        },
        // {
        //   title: i18n.t("Secretary"),
        //   // i18n
        //   iconOpen: "bxs:calendar-edit",
        //   iconClosed: "bx:calendar-edit",
        //   to: "/secretary",
        //   isImg: false,
        //  // imgPath: "request.png",
        // },
        // {
        //   title: "Έγγραφα",
        //   iconOpen: "ant-design:file-text-filled",
        //   iconClosed: "ant-design:file-text-outlined",
        //   to: "/files",
        //   isImg: false,
        // },
        {
          title: i18n.t("library"),
          iconOpen: "fluent:library-28-filled",
          iconClosed: "clarity:library-line",
          to: "/libraries",
          isImg: false,
        },
        // {
        //   title: "Marketing",
        //   iconOpen: "bx:bxs-like",
        //   iconClosed: "bx:bx-like",
        //   to: "/marketing",
        //   isImg: false,
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(["getFirstname", "getLastname" /*, "getUserPhoto"*/]),
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
      mapMini: (state) => state.menu.mini,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    userPhotoUrl() {
      return this.userDetails && this.userDetails.Photo
        ? `https://my.newdeal.gr/${this.userDetails.Photo}`
        : require("@/assets/general/no_img_found.png");
    },
    myBreakPoint() {
      return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
    },
    mini: {
      get() {
        return this.mapMini;
      },
      set(newValue) {
        try {
          this.$store.dispatch("setMenuMini", newValue);
        } catch (err) {
          console.error(err);
        }
      },
    },
    currentRoute() {
      return "/" + this.$route.path.split("/")[1];
    },
    menu() {
      // if (this.userDetails.Role != "Admin") {
      //   return this.menuArray.filter((val) => val.title != "Γραμματεία");
      // } else {
      //   return this.menuArray;
      // }
      return this.menuArray;
    },
  },
  created() {
    this.mini = this.myBreakPoint;
    this.tempImg = this.userPhotoUrl;
  },
  watch: {
    myBreakPoint() {
      this.mini = this.myBreakPoint;
    },
    mobileBreakpoint() {
      this.mini = true;
      this.drawer = false;
    },
    userPhotoUrl() {
      this.tempImg = this.userPhotoUrl;
    },
  },
  methods: {
    ...mapActions(["onLogout", "onSetDialog"]),
    appBarNavIconClicked() {
      if (!this.mobileBreakpoint) this.mini = !this.mini;
      else this.drawer = !this.drawer;
    },
    onClickAllNotifPhone() {
      true;
    },
    onClickNotifPhone(data) {
      data;
    },
    onClickAllNotifications() {
      true;
    },
    onClickNotifications(data) {
      data;
    },
    async onLogoutHandler() {
      let lMini = this.mini;
      try {
        this.mini = this.myBreakPoint;
        await AuthRepo.logout();
      } catch (e) {
        this.mini = lMini;
        this.$store.dispatch("onSetSnackbar", {
          open: true,
          text: e.message,
          color: "error",
        });
      }
    },

    async onSettingsHandler() {
      this.onSetDialog(true);
    },
    imageError() {
      this.tempImg = require("@/assets/general/no_img_found.png");
    },
  },
};
</script>

<style scoped lang="scss">
#brandName {
  font-family: CenturyGothicBold;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
}

.mobileBreakpointAppBar {
  overflow: auto;
}

.brandContainer {
  background-color: #263573;
  height: 64px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.imgContainer {
  background-color: white;
  border-radius: 5px;
  padding-top: 3px;
}

.v-list-item--active {
  // background: #e1e0e0;
  background: #edededba;
}

.v-list-item--active:before {
  color: inherit;
  background: red !important;
  width: 3%;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  opacity: 1.12 !important;
}

.v-list-item__content {
  text-align: start !important;
}

.v-list-item--link:before {
  background-color: #2635734f;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  padding: 6px !important;
}

.v-list--nav .v-list-item {
  padding: 6px !important;
}

.v-navigation-drawer--mini-variant {
  width: 80px !important;
}

.menu {
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textDecoration {
  font-family: century-gothic, sans-serif;
  color: white;
}

.menuContainer {
  margin-bottom: 8px;
}

.iconContainer {
  margin-left: 40px !important;
  margin-right: 8px !important;
  align-items: center;
}

.iconMiniContainer {
  margin-left: 8px !important;
  align-items: center;
}

.appbarMiniContainer {
  left: 80px !important;
}

@media screen and (max-width: 970px) {
  .brandContainer {
    height: 56px;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1360px) {
  .brandContainer {
    margin-bottom: 5px;
  }

  .v-list--nav .v-list-item:not(:last-child):not(:only-child),
  .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
    padding: 2px !important;
  }

  .v-list--nav .v-list-item {
    padding: 2px !important;
  }

  .menuContainer {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 1280px) {
  .brandContainer {
    margin-bottom: 5px;
  }

  .v-list--nav .v-list-item:not(:last-child):not(:only-child),
  .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
    padding: 0px !important;
  }

  .v-list--nav .v-list-item {
    padding: 0px !important;
  }

  .menuContainer {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
}
</style>
