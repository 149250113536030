<template>
  <v-card :class="cardClass">
    <v-img
      :src="getImage(item)"
      class="white--text"
      :class="innerClass"
      height="234"
      width="250"
    >
      <v-row class="h70">
        <v-img
          v-if="index == 0 && topLeftLabel != undefined"
          :src="topLeftLabel"
          class="topLeftLabel"
          :contain="true"
        >
          &nbsp;
        </v-img>
        <v-app-bar flat color="rgba(0, 0, 0, 0)" class="pl-4 pr-4 pt-2">
          <v-btn
            v-if="
              (index != 0 || topLeftLabel == undefined) && supportTopLeftCircle
            "
            color="white"
            class="text-h6 white--text pl-0 imgBtn"
            icon
          >
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="supportEdit && !disable"
            class="imgBtn actionBtn mr-2"
            color="white"
            icon
            @click.stop="editObject(item)"
          >
            <v-icon> mdi-pencil-outline </v-icon>
          </v-btn>
          <v-btn
            v-if="supportDelete && !disable"
            class="imgBtn actionBtn"
            color="white"
            icon
            @click.stop="deleteObject()"
          >
            <v-icon> mdi-delete-outline </v-icon>
          </v-btn>
        </v-app-bar>
      </v-row>
      <v-row class="h30">
        <v-col class="pl-5 pt-5">
          <v-row
            v-if="
              itemImageNameObj &&
              itemImageTitleProp &&
              itemImageNameObj[itemImageTitleProp]
            "
          >
            <v-col class="text-color-white ml-2 mb-0 mt-0" align="left">
              {{ itemImageNameObj[itemImageTitleProp] }}
            </v-col>
          </v-row>
          <v-row class="ma-0" v-if="itemCheckBoxPropName != undefined">
            <v-checkbox
              hide-details
              :disabled="disable"
              v-model="item[itemCheckBoxPropName]"
              :false-value="itemCheckBoxFalseVal"
              :true-value="itemCheckBoxTrueVal"
              class="ml-1 mt-0 mb-0 pa-0"
              align="left"
              :dark="true"
              @change="updateObject(item)"
            >
              <template v-slot:label>
                <span class="whiteText"> {{ itemCheckBoxDescr }} </span>
              </template>
            </v-checkbox>
          </v-row>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>
<script>
/* 
  Name:DraggableCard
  Description: DraggableCard component to generate quickly Draggable cards. Support two custom fields (title and boolean or custom boolean) and also supports Re-order,Edit,Update,Delete,Upload
  API Property 
    item: Object containing the data of the image. For example 
          {
            path: require('@/assets/draggable/addImageCard.png'),
            absolutePath: false, -- If true then "https://my.newdeal.gr/" will not be added in the beginning
            title: { gr: "Ελληνικός τίτλος", en:"English title"}
          }
    index: Number containing the index of the item in the array
    disable: Boolean variable to set card as disabled or not
    itemImageNameObj: Object containing image title. Example {gr: "Ελληνικός τίτλος", en: "English title"}
    itemImageTitleProp: String containing image title property name for the object itemImageNameObj. Example "gr"
    itemCheckBoxPropName: String with the name of the Property containing checkbox value
    itemCheckBoxDescr: String with Description of checkBox
    itemCheckBoxFalseVal: Boolean or String containing the false value of checkBox,
    itemCheckBoxTrueVal: Boolean or String containing the true value of checkBox,
    topLeftLabel: String containing the Path for {{require}} top left label
    supportTopLeftCircle: Boolean variable to show/hide the circle on first card
    supportEdit: Boolean variable to show/hide the Edit button
    supportDelete: Boolean variable to show/hide the Delete button
    innerClass: String containing Class name of inner area
    cardClass: String containing Class name of inner area of card or outer area
    editObjHandler: Handler function from parent when edit
    deleteObjHandler: Handler function from parent when delete
    updateObjHandler: Handler function from parent when update  
*/
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "DraggableCard",
  components: {},
  props: {
    item: Object,
    /*  {
          path: require('@/assets/draggable/addImageCard.png'),
          absolutePath: false, -- If true then "https://my.newdeal.gr/" will not be added in the beginning
          title: { gr: "Ελληνικός τίτλος", en:"English title"}
        }
    */
    index: Number, // Index of object position in array
    disable: { type: Boolean, default: false },
    itemImageNameObj: Object, // Object containing image title
    itemImageTitleProp: String, // String containing image title property name
    itemCheckBoxPropName: String, // Property containing checkbox value
    itemCheckBoxDescr: String, // Description of checkBox
    itemCheckBoxFalseVal: { type: [Boolean, String], default: false },
    itemCheckBoxTrueVal: { type: [Boolean, String], default: true },
    topLeftLabel: String, // Path for {{require}} top left label
    supportTopLeftCircle: { type: Boolean, default: true }, // Hide the circle on top left region
    supportEdit: { type: Boolean, default: false }, // Show the Edit button
    supportDelete: { type: Boolean, default: false }, // Show the Delete button
    innerClass: String, // Class of inner area
    cardClass: String, // Class of card
    editObjHandler: Function, // Function on edit
    deleteObjHandler: Function, // Function on delete
    updateObjHandler: Function, // Function on update
  },
  methods: {
    editObject(obj) {
      try {
        this.editObjHandler(obj, this.index);
      } catch {
        null;
      }
    },
    deleteObject() {
      try {
        this.deleteObjHandler(this.index);
      } catch {
        null;
      }
    },
    updateObject(obj) {
      try {
        this.updateObjHandler(obj, this.index);
      } catch {
        null;
      }
    },
    getImage(item) {
      return CoreHelper.getImagePath(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.ib {
  display: inline-block;
}
.grab {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbing {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}
.noPad {
  padding: unset !important;
}
.noBorderRad {
  border-radius: unset !important;
}
.h30 {
  height: 30%;
}
.h70 {
  height: 70%;
}
.imgBtn {
  height: 35px !important;
  width: 35px !important;
}
.whiteText {
  color: #ffffff !important;
}
.actionBtn {
  background-color: #253472;
  border-radius: 5px;
}
.topLeftLabel {
  flex: unset;
  position: absolute;
  display: block !important;
  margin-left: 10px;
}

.noShadow {
  box-shadow: unset !important;
}
</style>
