import store from "@/store";
import i18n from "@/i18n/i18n";
export default {
    getEstateProviders(data) {
        let estArray = [];
        if (data.providers && data.providers.length > 0)
            estArray = data.providers;
        let index = estArray.findIndex(x => x && x == "Spitogatos.gr");
        if (index == -1 && data.spListingID)
            estArray.push({ Characteristic: "Spitogatos.gr", published: true })
        return estArray
    },
    getEstateProvidersDescr(data) {
        let estArray = [];
        if (data.providers && data.providers.length > 0)
            data.providers.map((m) => {
                m.Characteristic && m.IsActive == true ? estArray.push(m.Characteristic) : true;
            })
        let index = estArray.findIndex(x => x && x == "Spitogatos.gr");
        if (index == -1 && data.spListingID)
            estArray.push("Spitogatos.gr")
        return estArray
    },
    getProviderCoins(providerId) {
        switch (providerId) {
            case "newdeal.gr":
                return store.state.auth.userDetails.AvailableAdsNewDeal ? store.state.auth.userDetails.AvailableAdsNewDeal : 0;
            case "Spitogatos.gr":
                return store.state.auth.userDetails.AvailableAdsSpitogatos ? store.state.auth.userDetails.AvailableAdsSpitogatos : 0;
            case "ΧΕ":
                return store.state.auth.userDetails.AvailableAdsXE ? store.state.auth.userDetails.AvailableAdsXE : 0;
            case "spiti360":
                return store.state.auth.userDetails.AvailableAdsRealtorgr ? store.state.auth.userDetails.AvailableAdsRealtorgr : 0;
            default:
                return 0;
        }
    },
    getProviderCoinProp(providerId) {
        switch (providerId) {
            case "newdeal.gr":
                return 'AvailableAdsNewDeal';
            case "Spitogatos.gr":
                return 'AvailableAdsSpitogatos';
            case "ΧΕ":
                return 'AvailableAdsXE';
            case "spiti360":
                return 'AvailableAdsRealtorgr';
            default:
                return null;
        }
    },
    getLeftBtnActionText(provider) {
        switch (provider.status) {
            case "published":
                return i18n.t("published");
            case "toPublish":
                return i18n.t("action") + ":";
            case "toDelete":
                return i18n.t("action") + ":";
            case "noCoins":
                return i18n.t("noBalance") + "...";
            default:
                return "";
        }
    },
    getRIghtBtnActionText(provider) {
        switch (provider.status) {
            case "toPublish":
                return i18n.t("publication");
            case "toDelete":
                return i18n.t("delete");
            default:
                return "";
        }
    },
    updateProvider(provider) {
        switch (provider.status) {
            case "default":
                if (provider.coins - 1 >= 0) {
                    provider.status = "toPublish";
                    provider.coinText = (provider.coins - 1) + '(-1)';
                } else {
                    provider.status = "noCoins";
                }
                return provider;
            case "published":
                provider.status = "toDelete";
                return provider;
            case "toPublish":
                provider.status = "default";
                provider.coinText = provider.coins;
                return provider;
            case "toDelete":
                provider.status = "published";
                return provider;
            case "noCoins":
                provider.status = "default";
                return provider;
            default:
                return provider;
        }
    }
};
