export default {
    state: {
        contact: {},
    },
    getters: {},
    mutations: {
        setLastCreatedContact(state, payload) {
            state.contact = payload;
        },
    },
    actions: {
        setLastCreatedContact({ commit }, payload) {
            commit("setLastCreatedContact", payload);
        },
    },
};