export default {
  state: {
    snackbar: {
      open: false,
      text: null,
      color: null,
    },
  },

  mutations: {
    setSnackbar(state, payload) {
      state.snackbar = {
        open: payload.open,
        text: payload.text,
        color: payload.color,
      };
    },
  },
  actions: {
    onSetSnackbar({ commit }, payload) {
      commit("setSnackbar", payload);
    },
  },
};
