import axios from "axios";
// import firebase from "firebase";
import store from "@/store";
import router from "../../router";

const baseDomain = `${process.env.VUE_APP_BASE_URL}`;
const baseURL = `${baseDomain}`;

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const AxiosClientToken = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Step-2: Create request, response & error handlers
const requestHandler = async (request) => {
  request.headers["X-AUTH-ID-TOKEN"] = store.getters.getToken;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = async (err) => {
  const originalConfig = err.config;
  if (err.response) {
    // Access Token was expired
    if (err.response.status === 401 && !originalConfig._retry) {
       if (['empty','', null, undefined].includes(store.getters.getRefreshToken)){
        store.dispatch("onLogout").then(() => {
          router.push("/login");
        });
       } else {
      try {
        originalConfig._retry = true;
        // let token = await firebase.auth().currentUser.getIdToken(true);
        let refreshToken = store.getters.getRefreshToken
          ? store.getters.getRefreshToken
          : '';

        let resp = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/general/RefreshToken`,
          null,
          {
            headers: {
              "X-AUTH-REFRESH-TOKEN": refreshToken,
            },
          }
        );
        store.dispatch("onSetRefreshToken",resp.data.message.refresh_token);
        store.dispatch("onSetToken", resp.data.message.access_token)
          .then(() => {
            // return AxiosClientToken(originalConfig).then((res) => {
            //   console.log("retry resp", res);
            //   return Promise.resolve(res);
            // });
            // err.config.headers['X-AUTH-ID-TOKEN']= resp.data.message.access_token;
            return AxiosClientToken.request(originalConfig);
          });
      } catch (_error) {
        if (_error.response && _error.response.data) {
          return Promise.reject(_error.response.data);
        }
        return Promise.reject(_error);
      }
    }
    }
    if (err.response.status === 403 && err.response.data) {
      // console.log("error 403");
      return Promise.reject(err.response.data);
    }
    if (err.response.status === 500) {
      store.dispatch("onSetSnackbar", {
        open: true,
        text: "Κάτι πήγε στραβά! Παρακαλώ προσπαθήστε αργότερα",
        color: "error",
      });
    }
    if (err.response.status == 400 || err.response.status == 423) {
      store.dispatch("onSetSnackbar", {
        open: true,
        text: err.response.data.error,
        color: "error",
      });
    }
    // setTimeout(() => {
    //   store.dispatch("onSetSnackbar", {
    //     open: false,
    //     text: null,
    //     color: null,
    //   }), 5000
    // })
    return Promise.reject(err.response);
  }
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
AxiosClientToken.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => {
    return Promise.reject(error);
  }
);

AxiosClientToken.interceptors.response.use(
  (response) => responseHandler(response),
  (err) => errorHandler(err)
);
// Step-4: Export the newly created Axios instance to be used in different locations.
export default AxiosClientToken;
