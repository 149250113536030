var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticStyle:{"height":"100%"},attrs:{"width":"866px","fixed":"","right":"","app":"","temporary":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-col',{staticClass:"px-12"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"align":"right"}},[_c('v-icon',{staticClass:"primaryColorOnHover xClass",on:{"click":_vm.onCloseHandler}},[_vm._v(" mdi-close ")])],1)],1),(_vm.data.basicInfo.isImg == true)?_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"},on:{"click":function($event){return _vm.openDialog('upload')}}},[_c('AvatarField',{staticClass:"cursor-pointer",attrs:{"data":_vm.getPhoto(_vm.data),"isImg":_vm.data.basicInfo.isImg ? true : false,"showName":true,"nameContent":(_vm.data.basicInfo.FirstName ? _vm.data.basicInfo.FirstName + ' ' : '') +
            (_vm.data.basicInfo.LastName ? _vm.data.basicInfo.LastName : ''),"fontSizeName":"24px","size":135,"nameContentClass":"textDecorationBold primaryColor","fontSizeImg":"14px","backgroundColor":"#fff","showSubContent":true,"subContent":_vm.data.basicInfo.displayRole ? _vm.data.basicInfo.displayRole : '',"showRating":_vm.data.basicInfo.showRating,"ratingData":_vm.data.basicInfo.ratingData,"starSize":16,"fontSizeSubContent":"16px","subContentClass":"textDecorationBold","absolutePath":_vm.absolutePath}})],1)],1):_vm._e(),(_vm.data.basicInfo.isImg != true)?_c('v-row',{staticClass:"mt-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pt-0 pb-4",attrs:{"align":"left"}},[_c('HeaderTitle',{attrs:{"title":_vm.editData.FirstName && _vm.editData.FirstName
              ? _vm.editData.FirstName + ' ' + _vm.editData.LastName
              : _vm.editData.FirstName,"subTitle":_vm.editData.displayRole}})],1)],1):_vm._e(),(_vm.data.basicInfo.isImg != true)?_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"flex-grow-0 pt-0",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openDialog('upload')}}},[_c('v-avatar',{attrs:{"size":131}},[_c('v-img',{attrs:{"size":131,"src":_vm.imgUrl}})],1)],1),_c('v-col',{staticClass:"align-self-center",attrs:{"align":"left"}},[_c('span',{staticClass:"primaryColor",staticStyle:{"font-family":"CenturyGothic","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.$t("uploadYourPhoto") + "...")+" ")]),_c('span',{staticClass:"primaryColor",staticStyle:{"font-family":"CenturyGothic","font-size":"14px"}},[_c('br'),_vm._v(_vm._s(_vm.$t("photoAtLeast") + " 300px x 300px")+" ")])])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"9","md":"9","lg":"9","xl":"9","align":"left"}},[_c('v-tabs',{attrs:{"show-arrows":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tabs-slider',{staticStyle:{"height":"4px"},attrs:{"color":"error"}}),_c('v-tab',{staticClass:"textTranformUnset textDecorationBold mr-1 cusTab"},[_vm._v(" "+_vm._s(_vm.$t("profile"))+" ")]),_c('v-tab',{staticClass:"textTranformUnset textDecorationBold mr-1 cusTab"},[_vm._v(" "+_vm._s(_vm.$t("account"))+" ")]),(
              _vm.data.basicInfo.displayRole
                ? ['Associate', 'OfficeAdmin'].includes(
                    _vm.data.basicInfo.displayRole
                  )
                : false
            )?_c('v-tab',{staticClass:"textTranformUnset textDecorationBold mr-1 cusTab"},[_vm._v(" "+_vm._s(_vm.$t("subscriptionsCoins"))+" ")]):_vm._e()],1)],1),_c('v-col',{staticClass:"py-6",attrs:{"cols":"12","xs":"12","sm":"3","md":"3","lg":"3","xl":"3","align":"right"}},[_c('v-btn',{key:"fBtn2",attrs:{"max-width":"40","max-height":"40","outlined":"","color":"primary"},on:{"click":_vm.onSubmit}},[_c('Icon',{attrs:{"icon":"eva:save-outline","height":"25","outline":"","color":"#263573"}})],1)],1)],1),_c('v-row',{staticClass:"mx-0 mt-n6"},[_c('v-col',{staticClass:"pb-0 pt-0"},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',[_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[(_vm.activeTab == 0)?_c('ProfileContact',{attrs:{"data":_vm.data,"validations":this.$v,"updatePropAttribute":_vm.updateAttribute,"updateUploadedDoc":_vm.updateUploadedDocHandler,"initSliderInfo":_vm.initSliderInfo}}):_vm._e()],1),_c('v-tab-item',[(_vm.activeTab == 1)?_c('AccountContact',{attrs:{"data":_vm.data,"validations":this.$v,"updatePropAttribute":_vm.updateAttribute,"initSliderInfo":_vm.initSliderInfo}}):_vm._e()],1),(
              _vm.data.basicInfo.displayRole
                ? ['Associate', 'OfficeAdmin'].includes(
                    _vm.data.basicInfo.displayRole
                  )
                : false
            )?_c('v-tab-item',[(_vm.activeTab == 2)?_c('Coins',{attrs:{"data":_vm.data,"fetchSpecificContact":_vm.fetchSpecificContact}}):_vm._e()],1):_vm._e()],1)],1)],1),(_vm.dialog.type == 'upload')?_c('v-dialog',{staticClass:"pa-6",model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[_c('Upload',{staticClass:"pa-6",attrs:{"onlyImgRestrict":true,"singularUpload":true,"closeHandler":_vm.closeDialog,"submitHandler":_vm.setImage}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }