<template>
  <v-row>
    <v-col>
      <v-row>
        <CommentField :title="$t('comments')" :label="$t('saySmth')"
          :btnName="editComment == null ? $t('send') : $t('save')" :supportSend="true" :loading="loading"
          :handleSend="onCreateComment" :commentType="commentType" :btnColor="editComment == null ? 'error' : 'primary'"
          :defaultContent="editComment != null ? editComment.text : null" :handleCancel="onHandleCancel" />
      </v-row>
      <v-row>
        <v-col align="left" class="listContent list-container">
          <v-row v-for="(item, i) in reverseArray" :key="i" class="mb-3">
            <CommentCard :content="item.text" :createdBy="item.user_full_name" :createdDate="item.timestamp"
              :visibility="item.visibility" :supportEdit="
                item.user_id ? item.user_id == userDetails['_id'] : false
              " :supportDelete="
  item.user_id ? item.user_id == userDetails['_id'] : false
" :supportView="
  item.user_id
    ? item.user_id == userDetails['_id'] ||
    ['Admin', 'Secretary'].includes(userDetails['Role'])
    : false
" :handleEdit="onHandleEdit" :handleDelete="() => openDialog(item, $t('deleteCommentSure'))"
              :handleView="onHandleView" :id="item" />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="dialog.open" width="500">
      <DeletePopup :onRemove="onDeleteComment" :onCancel="closeDialog" :cancelText="$t('cancel')"
        :deleteText="$t('imSure')" :content="dialog.content" :item="dialog.data" :loading="deleteLoading" />
    </v-dialog>
  </v-row>
</template>

<script>
 /*
 Name: CommentsInfo
 Description: Component for real estate characteristics at the slider

 API Property: 
    estateId: Number for the real estate id
    data: Array for the data
    onSave: Function on save
    viewAllAccess: Boolean for the view all access

    Components Used:
      CommentField
      CommentCard
      DeletePopup
*/
import CommentField from "@/components/Fields/CommentField/CommentField.vue";
import CommentCard from "@/components/Comments/CommentCard.vue";
import DeletePopup from "@/components/Popups/DeletePopup.vue";
import { mapState } from "vuex";
import Repository from "@/services/Repository";

const CommentRepo = Repository.get("realEstate");
export default {
  name: "CommentsInfo",
  props: {
    estateId: Number,
    data: Array,
    onSave: Function,
    viewAllAccess: { type: Boolean, default: false },
  },
  components: {
    CommentField,
    CommentCard,
    DeletePopup,
  },
  created() {
    let newArray = this.data.filter((d) => {
      if (!d.is_deleted) {
        return d;
      }
    });
    if (this.viewAllAccess == false) {
      newArray = newArray.filter((m) => {
        if (m.visibility != "private") {
          return m;
        }
      });
    }
    this.reverseArray = [...newArray].reverse();
  },
  watch: {
    data() {
      var newArray = this.data.filter((d) => {
        if (!d.is_deleted) {
          return d;
        }
      });
      if (this.viewAllAccess == false) {
        newArray = newArray.filter((m) => {
          if (m.visibility != "private") {
            return m;
          }
        });
      }
      this.reverseArray = [...newArray].reverse();
    },
  },
  data() {
    return {
      loading: false,
      commentType: "create",
      reverseArray: [],
      editIndex: 0,
      editComment: null,
      dialog: {
        open: false,
        data: null,
        type: null,
        content: null,
      },
      deleteLoading: false,
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
  },
  methods: {
    async onCreateComment(content) {
      try {
        this.loading = true;
        let cType = this.commentType;
        this.commentType = "create";
        if (cType == "create") {
          let body = {
            text: content,
            visibility: "public",
          };
          await CommentRepo.createComment(this.estateId, body);
        } else if (cType == "edit") {
          let body = { ...this.editComment, text: content };
           await CommentRepo.updateComment(
            this.estateId,
            body,
            this.editComment.comment_index
          );
        } else if (cType == "publish") {
          let body = { ...content };
          await CommentRepo.updateComment(
            this.estateId,
            body,
            this.editIndex
          );
        }
        this.$emit("fetchEstate");
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },
    onHandleEdit(data) {
      this.editIndex = data.comment_index;
      this.commentType = "edit";
      this.editComment = data;
    },
    onHandleCancel() {
      this.commentType = "create";
      this.editComment = null;
    },
    onHandleView(data) {
      this.editIndex = data.comment_index;
      this.commentType = "publish";
      let content = data;
      content.visibility =
        content.visibility != "public" ? "public" : "private";
      this.onCreateComment(content);
    },
    async onDeleteComment(data) {
      try {
        this.deleteLoading = true;
        await CommentRepo.deleteComment(
          this.estateId,
          data.comment_index
        );
        this.deleteLoading = false;
      } catch (e) {
        this.deleteLoading = false;
        throw e;
      }
      this.closeDialog();
      this.$emit("fetchEstate");
    },
    closeDialog() {
      this.dialog = {
        open: false,
        data: null,
        content: null,
      };
    },
    openDialog(data, content) {
      this.dialog = {
        open: true,
        data: data,
        content: content,
      };
    },
  },
};
</script>

<style>
.list-container {
  max-height: 450px;
  overflow-y: auto;
}
</style>
