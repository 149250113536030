var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"menu",attrs:{"offset-y":"","transition":"scroll-y-transition","max-height":"423","max-width":"241","left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"justify-end flex-nowrap"},'v-row',attrs,false),on),[(_vm.mobileBreakpoint != true)?_c('v-col',{staticClass:"flex-grow-0 text-no-wrap align-self-center pr-0"},[_c('span',{staticClass:"textDecorationBold",staticStyle:{"color":"white","font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.getFirstname)+" ")])]):_vm._e(),(_vm.mobileBreakpoint != true)?_c('v-col',{staticClass:"flex-grow-0 text-no-wrap align-self-center pl-1 pr-0"},[_c('span',{staticClass:"textDecoration",staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.getLastname)+" ")])]):_vm._e(),_c('v-col',{staticClass:"flex-grow-0 text-no-wrap align-self-center"},[_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.tempImg,"size":"50","alt":"John"},on:{"error":_vm.imageError}})],1)],1)],1)]}}])},[_c('v-container',{staticClass:"notifContainer"},[_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"text-no-wrap menu-selection",attrs:{"align":"left"},on:{"click":_vm.openProfileHandler}},[_c('span',{staticClass:"font15 textInter"},[_vm._v(" "+_vm._s(_vm.$t("myProfile"))+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-no-wrap menu-selection",attrs:{"align":"left"},on:{"click":function($event){return _vm.openEditProfile(0)}}},[_c('span',{staticClass:"font15 textInter"},[_vm._v(" "+_vm._s(_vm.$t("editProfile"))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"text-no-wrap menu-selection",attrs:{"align":"left"},on:{"click":function($event){return _vm.openEditProfile(1)}}},[_c('span',{staticClass:"font15 textInter"},[_vm._v(" "+_vm._s(_vm.$t("myAccount"))+" ")])])],1),(
        _vm.userDetails.Role
          ? ['Associate', 'OfficeAdmin'].includes(_vm.userDetails.Role)
          : false
      )?_c('v-row',[_c('v-col',{staticClass:"text-no-wrap menu-selection",attrs:{"align":"left"},on:{"click":function($event){return _vm.openEditProfile(2)}}},[_c('span',{staticClass:"font15 textInter"},[_vm._v(" "+_vm._s(_vm.$t("coinsSubscriptions"))+" ")])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-no-wrap menu-selection",attrs:{"align":"left"},on:{"click":_vm.onLogoutHandler}},[_c('span',{staticClass:"font15 textInter"},[_vm._v(" "+_vm._s(_vm.$t("exit"))+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }