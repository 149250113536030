<template>
  <v-col>
    <v-row>
      <v-col align="center" class="pb-0">
        <v-img
          :src="require('@/assets/general/serverProblem.png')"
          height="209"
          width="399"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span class="primaryColor textDecorationBold font20">{{
          $t("serverProblem")
        }}</span>
      </v-col>
      <v-col cols="12 pt-0">
        <span class="primaryColor textFontDecoration font14">{{
          $t("pageRefresh")
        }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="">
        <v-btn
          class="btn-custom"
          @click="openEmail"
          key="fBtn0"
          outlined
          color="primary"
        >
          {{ $t("sendError") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
/*
 Name:ServerProblem
 Description: Component for server problem message display
*/
export default {
  name: "ServerProblem",
  methods: {
    openEmail() {
      window.location.href = "mailto:";
    },
  },
};
</script>