import firebase from "firebase";
import store from "../../store";
import router from "../../router";
import axios from "axios";

import AxiosClientToken from "../Client/AxiosClientToken";

// const resource = "/general/";

export default {
  login(payload) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(payload.username, payload.password)
      .then(async (resp) => {
        let token = await firebase.auth().currentUser.getIdToken();

        let metaData = await this.getMetaData(token);
        store.dispatch("onSetUserDetails", metaData.data);
        store.dispatch("onLogin", { token, user: resp.user }).then(async () => {
          router.push("/real_estate");
        });
        return true;
      })
      .catch((error) => {
        store.dispatch("onSetSnackbar", {
          open: true,
          text: error.message,
          color: "error",
        });
        return true;
      });
  },
  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        store.dispatch("onLogout").then(() => {
          router.push("/login");
        });
      });
  },
  register(payload, register) {
    //type --> 1:Register 0:Contact
    return firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then(() => {
        // alert("Successfully registered! Please login.");
        AxiosClientToken.post(
          `https://newdealgr.com/api/newdeal_crm/general/register`,
          register
        ).then(() => {
          router.push("/registerSuccess");
        });
        return true;
      })
      .catch((error) => {
        store.dispatch("onSetSnackbar", {
          open: true,
          text: error.message,
          color: "error",
        });
        return true;
      });
  },
  refreshToken(refreshToken) {
    axios.post(
      `${process.env.VUE_APP_BASE_URL}/general/RefreshToken`,
      {
        headers: {
          "X-AUTH-REFRESH-TOKEN": refreshToken,
        },
      },
      null
    );
  },
  getMetaData(token) {
    return axios.get(
      `https://newdealgr.com/api/newdeal_crm/query_contact_metadata`,
      {
        headers: {
          "X-AUTH-ID-TOKEN": token,
        },
      }
    );
  },
};
