<template>
  <v-col v-bind:class="{ 'pt-0 pb-0 fill-height': fullScreen }">
    <v-row v-if="showTitle || showPagination" class="align-center pa-0">
      <v-col
        v-if="
          showTitle &&
          data &&
          data.length > 0 &&
          data[currentCarouselImage].title &&
          data[currentCarouselImage].title.gr
        "
        align="left"
        class="flex-grow-0 text-no-wrap pl-0 pt-3 textDecorationBold font-size-14"
      >
        {{ data[currentCarouselImage].title.gr }}
      </v-col>
      <v-col
        v-if="
          showTitle &&
          data &&
          data.length > 0 &&
          data[currentCarouselImage].public != 'Public'
        "
        align="left"
        class="flex-grow-0 text-no-wrap pl-0 pb-0 pt-3"
      >
        <HintField
          icon="mdi-eye-off"
          color="#000000"
          :data="$t('visibleOnlyToYou')"
        />
      </v-col>
      <v-col align="right" v-if="showPagination">
        <b>{{ currentCarouselImage + 1 + "/" + data.length }}</b>
      </v-col>
    </v-row>
    <v-row
      class="carousArea"
      v-bind:class="{ 'mt-0 mb-0 fill-height': fullScreen }"
    >
      <v-col
        @click="
          () => {
            fullScreenOnClick ? openMediaFullScreen() : true;
          }
        "
        class="pl-0"
        v-bind:class="{
          'pb-2 pt-4 fill-height': fullScreen,
          'pb-6 pt-0 leftImg': !fullScreen,
        }"
      >
        <v-carousel
          v-model="currentCarouselImage"
          class="imgBorder blueBackground"
          :style="carouselStyle"
          v-bind:class="{
            'height-100': fullScreen,
          }"
          hide-delimiters
          :show-arrows="data && data.length > 1 ? true : false"
        >
          <v-carousel-item
            v-for="(item, i) in data"
            v-bind:class="{
              'height-100': fullScreen,
              overFlow: showBottomSlider,
            }"
            :key="i"
          >
            <v-img
              :src="getImage(item)"
              :lazy-src="getImage(item)"
              v-bind:class="{
                'height-100': fullScreen,
              }"
              contain
              v-bind:style="[
                showBottomSlider
                  ? { width: sliderWidth + '%' }
                  : { width: 'auto', height: '500px' },
              ]"
              :alt="getImage(item)"
            />
          </v-carousel-item>
        </v-carousel>
        <div v-if="showBottomSlider" class="blueBackground ma-0">
          <v-row>
            <v-col cols="1">
              <b style="color: white">
                {{ currentCarouselImage + 1 + "/" + data.length }}
              </b>
            </v-col>
            <v-col cols="9">
              <v-slider
                color="white"
                v-model="currentCarouselImage"
                step="1"
                min="0"
                :max="data.length"
              >
                >
              </v-slider>
            </v-col>
            <v-col cols="1">
              <v-slider
                color="white"
                v-model="sliderWidth"
                class="align-self-stretch"
                min="1"
                max="100"
                step="1"
              >
              </v-slider>
            </v-col>
            <v-col>
              <v-icon @click="openMediaFullScreen" color="white">
                mdi-fullscreen
              </v-icon>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col v-if="showImgDetails" class="carousDetArea">
        <v-row class="overflow-y-auto galleryScrollbar">
          <v-col
            v-for="(item, i) in data"
            :key="i"
            class="d-flex child-flex pb-4 pl-2 pr-2 pt-0 noFlexGrow"
            @click="currentCarouselImage = i"
          >
            <v-img
              max-height="168"
              max-width="270"
              min-height="168"
              height="168"
              width="270"
              :src="getImage(item)"
              :lazy-src="getImage(item)"
              aspect-ratio="1:2"
              class="grey lighten-2 imgBorder"
              style="cursor: pointer"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>


<script>
/*
  Name:CarouselField
  Description: Carousel field to display multiple images
  API Property
    data: Array of images
    showTitle: Boolean that shows the title
    showImgDetails: Boolean that shows the array of images
    showBottomSlider: Boolean that shows bottom area with sliders and fullscreen
    showPagination: Boolean that shows the pagination on top
    carouselStyle: String for style
    fullScreen: Boolean that shows fullscreen classes
    currentImage: Number for current image index
    currentPropName: (String, Number) Incoming Field Name For currentImage, (Will update this field in parent)
    setCurrentImage: Function that updates parent with current image index
    fullScreenOnClick: Boolean that enables or disables onclick fullscreen
    setFullScreen: Function that updates parent and calls fullscreen slider

    Components Used:
      HintField
*/
import HintField from "@/components/Fields/HintField/HintField";
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "CarouselField",
  components: {
    HintField,
    //  MediaGalleryItem
  },
  props: {
    data: Array, // Array of images
    showTitle: { type: Boolean, default: false }, // Boolean that shows the title
    showImgDetails: { type: Boolean, default: false }, // Boolean that shows the array of images
    showBottomSlider: { type: Boolean, default: false }, // Boolean that shows bottom area with sliders and fullscreen
    showPagination: { type: Boolean, default: false }, // Boolean that shows the pagination on top
    carouselStyle: String,
    fullScreen: { type: Boolean, default: false }, // Boolean that shows fullscreen classes
    currentImage: { type: Number, default: 0 }, // Current image index
    currentPropName: [String, Number], // Incoming Field Name For currentImage, (Will update this field in parent)
    setCurrentImage: Function, // Function that updates parent with current image index
    fullScreenOnClick: { type: Boolean, default: false }, // Boolean that enables or disables onclick fullscreen
    setFullScreen: Function, // Function that updates parent and calls fullscreen slider
  },
  data() {
    return {
      sliderWidth: 50,
    };
  },
  created() {
    this.currentCarouselImage = this.currentImage;
  },
  computed: {
    currentCarouselImage: {
      get() {
        return this.currentImage;
      },
      set(val) {
        try {
          this.setCurrentImage(this.currentPropName, val);
        } catch {
          null;
        }
      },
    },
  },
  methods: {
    openMediaFullScreen() {
      this.setFullScreen("MediaFullScreen", {
        currentImage: this.currentCarouselImage,
        images: this.data,
      });
    },
    getImage(item) {
      return CoreHelper.getImagePath(item);
    },
  },
};
</script>


<style scoped>
.font-size-14 {
  font-size: 14px;
}
.galleryScrollbar {
  overflow: auto;
  max-height: 513px;
}
.imgBorder {
  border-radius: 5px;
}
.blueBackground {
  background-color: #21244b;
}
.carousDetArea {
  max-width: 590px;
  min-width: 400px;
}
.carousArea {
  flex-wrap: nowrap;
}
.leftImg {
  min-width: 752px;
}
@media only screen and (max-width: 1204px) {
  .carousDetArea {
    max-width: unset !important;
    min-width: unset !important;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .carousArea {
    flex-wrap: wrap;
  }
  .leftImg {
    min-width: unset;
  }
}
.galleryScrollbarMarketingFiles {
  max-height: 577px;
}
</style>