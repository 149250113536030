<template>
    <v-col>
        <v-row v-if="showAttribute('accountCreate')">
            <v-col class="pt-1 pb-1" cols="12" sm="12" md="12" lg="12">
                <v-checkbox v-model="accountCreate">
                    <template v-slot:label>
                        <span class="inputLabelCustom">
                            {{ $t("createSysAccount") }}
                        </span>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-row class="mt-0" v-if="accountCreate">
            <v-col class="pt-1 pb-1"  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('UserPassword')" >
                <v-text-field :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
                    hide-details="auto" autocomplete="new-password"  v-model="UserPassword" :label="$t('password') + '*'"
                    @click:append="show = !show">
                </v-text-field>
            </v-col>
            <v-col class="pt-1 pb-1"  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('confirmPassword')">
                <v-text-field :append-icon="showConf ? 'mdi-eye' : 'mdi-eye-off'" :type="showConf ? 'text' : 'password'"
                    hide-details="auto" autocomplete="new-password" v-model="confirmPassword" :label="$t('confPass') + '*'"
                    @click:append="showConf = !showConf">
                </v-text-field>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
/*
 Name:CreateAccount
 Description: Component to render create account  info fields

 API Property:
    attributes: Object with the data 
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the info type like "contactInfo"
*/
export default {
    name: "CreateAccount",
    props: {
        attributes: Object,
        updateAttribute: Function,
        validations: Object,
        attributeModel: Array,
        kind: { type: String, default: "createAccountInfo" },
    },
    data() {
        return {
            show: false,
            showConf: false,
        }
    },
    computed: {
        accountCreate: {
            get() {
                return this.attributes.accountCreate;
            },
            set(value) {
                this.updateAttribute(this.kind, "accountCreate", value);
            },
        },
        UserPassword: {
            get() {
                return this.attributes.UserPassword;
            },
            set(value) {
                this.updateAttribute(this.kind, "UserPassword", value);
            },
        },
        confirmPassword: {
            get() {
                return this.attributes.confirmPassword;
            },
            set(value) {
                this.updateAttribute(this.kind, "confirmPassword", value);
            },
        },
    },
    methods: {
        showAttribute(attribute) {
            return this.attributeModel.includes(attribute);
        }
    }
};
</script>
