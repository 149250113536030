export default {
    state: {
        mini: false,
    },
    getters: {},
    mutations: {
        setMenuMini(state, payload) {
            state.mini = payload;
        },
    },
    actions: {
        setMenuMini({ commit }, payload) {
            commit("setMenuMini", payload);
        },
    },
};