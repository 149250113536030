<template>
  <v-card :max-width="maxWidth" :min-width="minWidth" elovation="2">
    <v-col align="left">
      <v-row>
        <v-col class="primaryColor textDecorationBold font19">
          <span> {{  $t("informationProfessional")  }} </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col  class="font14 pr-0 pb-0 pt-1" align="left">
          <span>{{  $t("address")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-0 pt-1 textDecorationBold" align="left">
          <span> {{  dataList.Address  }} </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col  class="font14 pr-0 pb-0 pt-1" align="left">
          <span>{{  $t("telephoneFilter")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-0 pt-1 textDecorationBold" align="left">
          <span>{{  telephone  }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col  class="font14 pr-0 pb-0 pt-1" align="left">
          <span>{{  $t("mobile2")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-0 pt-1 textDecorationBold" align="left">
          <span>{{  dataList.Mobile  }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col  class="font14 pr-0 pb-0 pt-1" align="left">
          <span>{{  $t("email")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-0 pt-1 textDecorationBold" align="left">
          <span>{{  dataList.Email  }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col  class="font14 pr-0 pb-0 pt-1" align="left">
          <span>{{  $t("websites")  }}</span><span>:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-0 pt-2" align="left">
          <v-row>
            <v-col v-if="dataList.Website || true" class="flex-grow-0 pr-0">
              <Icon class="linkIcon cPointer" @click.native="openExternalPage(dataList.Website)" icon="bx:link-alt"
                width="22" height="22" />
            </v-col>
            <v-col class="flex-grow-0 pr-0" v-if="dataList.FB">
              <Icon class="cPointer" @click.native="openExternalPage(dataList.FB)"
                icon="fa-brands:facebook-square" color="#263573" width="26" height="26" />
            </v-col>
            <v-col class="flex-grow-0 pr-0" v-if="dataList.Instagram">
              <Icon class="cPointer" @click.native="openExternalPage(dataList.Instagram)"
                icon="akar-icons:instagram-fill" color="#263573" width="25" height="25" />
            </v-col>
            <v-col class="flex-grow-0 pr-0" v-if="dataList.LinkedIn">
              <Icon class="cPointer" @click.native="openExternalPage(dataList.LinkedIn)"
                icon="akar-icons:linkedin-box-fill" color="#263573" width="25" height="25" />
            </v-col>
            <v-col class="flex-grow-0 pr-0" v-if="dataList.Twitter">
              <Icon class="cPointer" @click.native="openExternalPage(dataList.Twitter)"
                icon="fa6-brands:square-twitter" color="#263573" width="26" height="26" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col  class="font14 pr-0 pb-0 pt-1" align="left">
          <span>{{  $t("collaboratorCode")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-0 pt-1 textDecorationBold" align="left">
          <span>{{  dataList.Suffix  }}</span>
        </v-col>
      </v-row>
      <v-row v-if="dataList.languages && dataList.languages.length > 0">
        <v-col  class="font14 pr-0 pb-0 pt-1" align="left">
          <span>{{  $t("languages")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-0 pt-1 textDecorationBold" align="left">
          <span>{{  dataList.languages.join(', ')  }}</span>
        </v-col>
      </v-row>
      <!--
      <v-row>
        <v-col  class="font14 pr-0 pb-0 pt-1" align="left">
          <span>{{  $t("memberfrom")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-0 pt-1 textDecorationBold" align="left">
          <span>{{  dataList.memberFrom  }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col  class="font14 pr-0 pb-2 pt-2" align="left">
          <span>{{  $t("awards")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-0 pb-2 pt-2 textDecorationBold" align="left" style=" text-no-wrap">
          <div v-for="(item, index) in dataList.awards" :key="index">
            <span>{{  item.title  }}</span>
          </div>
        </v-col>
      </v-row> -->
      <v-row v-if="dataList.qualifications && dataList.qualifications.length > 0">
        <v-col  class="font14 pr-0 pb-6 pt-2" align="left">
          <span>{{  $t("studies")  }}:</span>
        </v-col>
        <v-col cols="7" class="font14 pl-3 pb-6 pt-5 textDecorationBold" align="left">
          <v-row v-for="qualification,i in dataList.qualifications" :key="i"> 
            <v-col class="pa-0">
              <span>{{  ![null,undefined,''].includes(qualification.school ? qualification.school : '-- ' )  }} | {{ ![null,undefined,''].includes(qualification.specialization) ? qualification.specialization : ' --'}}</span>
            </v-col>
          </v-row>          
        </v-col>
      </v-row> 
    </v-col>
  </v-card>
</template>
<script>
/*
 Name:PersonInfo
 Description: PersonInfo is a component that have informations about the professional. The information comes from props object.
 API Property
 dataList: Object tha contains professional information For example:
  {
    address: "Θεσσαλονίκη, Τσιμισκή 33, Τ.Κ. 54634",
    mainPhone: "210 3390500",
    secondPhone: "693 390 5000",
    email: "papanikolopoulou@newdeal.gr",
    linkUrl: "https://www.apt.gr/el/archiki/",
    facebookUrl: "https://www.apt.gr/el/archiki/",
    instagramUrl: "https://www.apt.gr/el/archiki/",
    linkedinUrl: "https://www.apt.gr/el/archiki/",
    twitterUrl: "https://www.apt.gr/el/archiki/",
    collaboratorCode: "FPR",
    languages: "Ελληνικά, Αγγλικά, Γαλλικά",
    memberFrom: "2/2/2020",
    awards: [
     { title: 'Top Agent of the Year' },
     { title: 'Top Agent of the Month (Ιανουάριος 2022)' }
    ],
    studies: "Απόφοιτος Οικονομικών Σπουδών | University of Alberta",
   }

  Components Used:
    Icon
*/
import { Icon } from "@iconify/vue2";

export default {
  name: "PersonInfo",

  props: {
    dataList: Object,
    maxWidth: { type: [String, Number], default: 409 },
    minWidth: { type: [String, Number], default: 409 },
  },
  components: {
    Icon,
  },
  computed:{
    telephone(){
            return Array.isArray(this.dataList.Telephone) ? this.dataList.Telephone.join(', '): this.dataList.Telephone;
        }
  },
  methods: {
    openExternalPage(url) {
      return window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.linkIcon {
  color: white;
  background-color: #263573;
  border-radius: 3px;
  margin-top: 2px;
}

.cPointer {
  cursor: pointer
}
</style>
