import AxiosClientToken from "../Client/AxiosClientToken";
import firebase from "firebase";

// const resource = "/list";
const resourceCud = "/general";
export default {
  get(filters, params) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/query-contacts/`,
      filters,
      {
        params: params,
      }
    );
  },
  getId(id, params) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/query-contacts/`,
      { _id: id },
      { params: params }
    );
  },
  getOwners(filters, params) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/query_owners/`,
      filters,
      { params: params }
    );
  },
  getAgents(filters, params) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/query-agents-search/`,
      filters,
      { params: params }
    );
  },
  getCustomers(filters, params) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/query-contacts-search/`,
      filters,
      { params: params }
    );
  },
  // getId() { },
  // create(payload) {
  //   return AxiosClientToken.post(
  //     `${process.env.VUE_APP_BASE_URL}/${resourceCud}/InsertEstateTemplate`,
  //     payload
  //   );
  // },

  createContact(payload) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/insert-contact/`,
      payload
    );
  },
  editContact(id, payload) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/edit-contact/` + id + `/`,
      payload
    );
  },
  async createContactWithPass(payload) {
    try {
      var resp = await AxiosClientToken.post(
        `https://newdealgr.com/api/newdeal_crm/insert-contact/`,
        payload
      );
      await firebase
        .auth()
        .createUserWithEmailAndPassword(payload.Email, payload.UserPassword)
        .then(() => {
          true;
        });
      return resp;
    } catch (e) {
      throw e;
    }
  },
  uploadContactPhoto(contact_id, payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/UploadContactPhoto/${contact_id}`,
      payload,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  uploadContactCV(contact_id, payload) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}${resourceCud}/UploadContactCV/${contact_id}`,
      payload,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
};
