<template>
  <v-col>
    <v-row>
      <v-col align="left" class="py-0">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("basicInfo") }}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="showAttribute('isOrganization')">
      <v-col class="py-0">
        <v-checkbox v-model="isOrganization">
          <template v-slot:label>
            <span class="inputLabelCustom">
              {{ $t("itsBusOrg") }}
            </span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="isOrganization == true">
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('organizationName')"
      >
        <v-text-field
          v-model="organizationName"
          :error-messages="organizationErrors"
          :label="$t('busOrgName') + '*'"
          clearable
          color="primary"
          class="inputLabelCustom"
          hide-details="auto"
        />
      </v-col>
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('organizationArea')"
      >
        <v-text-field
          v-model="organizationArea"
          :label="$t('organizationArea')"
          clearable
          color="primary"
          class="inputLabelCustom"
          hide-details="auto"
        />
      </v-col>
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('organizationWebsite')"
      >
        <v-text-field
          v-model="organizationWebsite"
           :label="$t('organizationWebsite')"
          clearable
          color="primary"
          class="inputLabelCustom"
          hide-details="auto"
        />
      </v-col>
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('chamberNo')"
      >
        <v-text-field
          v-model="chamberNo"
          :label="$t('chamberNo')"
          clearable
          color="primary"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('FirstName')"
      >
        <v-text-field
          v-model="FirstName"
          :label="$t('firstname') + '*'"
          :error-messages="firstNameErrors"
          clearable
          @blur="flNameInitSuffix"
          color="primary"
          class="inputLabelCustom"
          hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('LastName')"
      >
        <v-text-field
          v-model="LastName"
          :label="$t('lastname') + '*'"
          :error-messages="lastNameErrors"
          required
          clearable
          @blur="flNameInitSuffix"
          hide-details="auto"
          class="inputLabelCustom"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="isOrganization != true">
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('FatherName')"
      >
        <v-text-field
          v-model="FatherName"
          :label="$t('fathersName')"
          clearable
          color="primary"
          class="inputLabelCustom"
          hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('Profession')"
      >
        <v-text-field
          v-model="Profession"
          :label="$t('profession')"
          required
          clearable
          hide-details="auto"
          class="inputLabelCustom"
        >
        </v-text-field>
      </v-col>
      <v-col
        class="pt-1 pb-1"
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        v-if="
          (showAttribute('Suffix') && type == 'create') ||
          (showAttribute('Suffix') && type != 'create' && !['', undefined, null].includes(suffix))
        "
      >
        <v-text-field
          v-model="suffix"
          :label="$t('collaboratorCode')"
          required
          clearable
          hide-details="auto"
          class="inputLabelCustom"
          :loading="checkSuffixLoader"
          :disabled="
            type == 'create' && !checkSuffixLoader ? false : true
          "
          :append-icon="
            type == 'create' && !checkSuffixLoader ? 'mdi-refresh' : ''
          "
          @click:append="
            type == 'create' && !checkSuffixLoader ? regSuffix() : ''
          "
          :error-messages="checkSuffixLoader ? [] : checkSuffixErrors"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="isOrganization == true">
      <v-col
        class="pt-1 pb-1"
        cols="12"
        v-if="showAttribute('organizationDescription')"
      >
        <v-text-field
          v-model="organizationDescription"
          :label="$t('description')"
          required
          clearable
          hide-details="auto"
          class="inputLabelCustom"
        />
      </v-col>
    </v-row>
    <v-row v-if="showAttribute('memberTitle')">
      <v-col>
        <v-select
          v-model="memberTitle"
          :items="memberTitleList"
          :label="$t('memberTitle')"
          dense
          clearable
          hide-details="auto"
          item-value="val"
          item-text="val"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:BasicInfo
 Description: Component to render basic info fields for contact

 API Property:
    type: String containing if we create the contact
    role: String if contact is company
    attributes: Object with the data
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the info type like "addressInfo"
    regSuffix: Function to generate the suffix
    intRegSuffix: Function to generate the suffix based the contact informations
    checkSuffixLoader: Boolean for suffix loader
*/
import i18n from "@/i18n/i18n";
export default {
  name: "BasicInfo",
  props: {
    type: String,
    role: String,
    attributes: Object,
    updateAttribute: Function,
    validations: Object,
    attributeModel: Array,
    kind: { type: String, default: "basicInfo" },
    regSuffix: Function,
    intRegSuffix: Function,
    checkSuffixLoader: { type: Boolean, default: false },
  },
  data() {
    return {
      memberTitleList: [
        { val: "Συμβολαιογράφος" },
        { val: "Δικηγόρος" },
        { val: "Άλλος" },
      ],
    };
  },
  created() {
    if (this.role == "company") {
      this.isOrganization = true;
    }
  },
  computed: {
    checkSuffixErrors() {
      const errors = [];
      if (
        this.validations.data.basicInfo.checkSuffix &&
        this.validations.data.basicInfo.checkSuffix.$dirty != undefined
      ) {
        if (!this.validations.data.basicInfo.checkSuffix.$dirty) return errors;
        if (!this.validations.data.basicInfo.checkSuffix.required)
          errors.push("Υπάρχει ήδη ο Κωδικός Συνεργάτη");
      }
      if (
        this.validations.data.basicInfo.Suffix &&
        this.validations.data.basicInfo.Suffix.$dirty != undefined
      ) {
        if (!this.validations.data.basicInfo.Suffix.$dirty) return errors;
        if (!this.validations.data.basicInfo.Suffix.required)
          errors.push(i18n.t("requiredField"));
      }
      // if ([null,undefined,''].includes(this.attributes.checkSuffix)) {
      // errors.push("Υπάρχει ήδη ο Κωδικός Συνεργάτη");
      // }
      return errors;
    },
    organizationErrors() {
      const errors = [];
      if (
        this.validations.data.basicInfo.organizationName.$dirty != undefined
      ) {
        if (!this.validations.data.basicInfo.organizationName.$dirty)
          return errors;
        if (!this.validations.data.basicInfo.organizationName.required)
          errors.push(i18n.t("requiredField"));
      }
      return errors;
    },

    firstNameErrors() {
      const errors = [];
      if (this.validations.data.basicInfo.FirstName.$dirty != undefined) {
        if (!this.validations.data.basicInfo.FirstName.$dirty) return errors;
        if (!this.validations.data.basicInfo.FirstName.required)
          errors.push(i18n.t("requiredField"));
      }
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (this.validations.data.basicInfo.LastName.$dirty != undefined) {
        if (!this.validations.data.basicInfo.LastName.$dirty) return errors;
        if (!this.validations.data.basicInfo.LastName.required)
          errors.push(i18n.t("requiredField"));
      }
      return errors;
    },

    isOrganization: {
      get() {
        return this.attributes.isOrganization;
      },
      set(value) {
        this.updateAttribute(this.kind, "isOrganization", value);
      },
    },
    organizationName: {
      get() {
        return this.attributes.organizationName;
      },
      set(value) {
        this.updateAttribute(this.kind, "organizationName", value);
      },
    },
    organizationArea: {
      get() {
        return this.attributes.organizationArea;
      },
      set(value) {
        this.updateAttribute(this.kind, "organizationArea", value);
      },
    },
    organizationWebsite: {
      get() {
        return this.attributes.organizationWebsite;
      },
      set(value) {
        this.updateAttribute(this.kind, "organizationWebsite", value);
      },
    },
    organizationDescription: {
      get() {
        return this.attributes.organizationDescription;
      },
      set(value) {
        this.updateAttribute(this.kind, "organizationDescription", value);
      },
    },
    chamberNo: {
      get() {
        return this.attributes.chamberNo;
      },
      set(value) {
        this.updateAttribute(this.kind, "chamberNo", value);
      },
    },
    FirstName: {
      get() {
        return this.attributes.FirstName;
      },
      set(value) {
        this.updateAttribute(this.kind, "FirstName", value);
      },
    },
    LastName: {
      get() {
        return this.attributes.LastName;
      },
      set(value) {
        this.updateAttribute(this.kind, "LastName", value);
      },
    },
    FatherName: {
      get() {
        return this.attributes.FatherName;
      },
      set(value) {
        this.updateAttribute(this.kind, "FatherName", value);
      },
    },
    Profession: {
      get() {
        return this.attributes.Profession;
      },
      set(value) {
        this.updateAttribute(this.kind, "Profession", value);
      },
    },
    suffix: {
      get() {
        return this.attributes.Suffix;
      },
      set(value) {
        this.updateAttribute(this.kind, "Suffix", value);
      },
    },
    memberTitle: {
      get() {
        return this.attributes.memberTitle;
      },
      set(value) {
        this.updateAttribute(this.kind, "memberTitle", value);
      },
    },
  },
  methods: {
    flNameInitSuffix() {
      if (
        this.FirstName &&
        this.LastName &&
        (this.suffix == null || this.suffix == undefined)
      ) {
        this.intRegSuffix();
      }
    },
    genarateNewSuffix() {
      this.regSuffix();
    },
    showAttribute(attribute) {
      return this.attributeModel.includes(attribute);
    },
  },
};
</script>
