export default {
  headers: [
    {
      text: "firstname",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Name",
      width: "340px",
    },
    {
      text: "role2",
      align: "center",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "displayRole",
    },
    {
      text: "companyName2",
      align: "center",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "LastCompany",
    },
    {
      text: "mobile2",
      align: "center",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "Mobile",
    },
    {
      text: "telephoneFilter",
      align: "center",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "Telephone",
    },
    {
      text: "Email",
      align: "center",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "Email",
    },
    {
      text: "choices",
      align: "start",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "actions",
    },
  ],

  templates: [
    {
      column: "LastCompany",
      dataObject: "company_name",
      elementType: "coloredTextBox",
    },
    {
      column: "displayRole",
      title: "displayRole",
      elementType: "customSpan",
      rowClass: "textDecorationBold",
    },
    {
      column: "Name",
      content: "title",
      subContent: "",
      dataObject: "Photo",
      isImg: "isImgAgent",
      size: 38,
      rowClass: "primaryColor ",
      elementType: "avatar",
    },
  ],
  filterValues: {
    FirstName: null,
    LastName: null,
    Telephone: null,
    Email: null,
    Suffix: null,
    deteleContacts: false,
    contactUser: false,
    contactEstate: false,
    contactRequest: false,
    contactNewDeal: false,
    assigneeTo: null,
    newsletter: false,
    createdDateFrom: null,
    createdDateTo: null,
    rolesSelected: null,
    companySelected: null,
    myCustomer: null,
    faveContacts: null,
    tagsFilt: null,
    agent_customers: null,
  },
  accountInfoModel: {
    UserName: null,
    UserPassword: null,
    IsEnabled: null,
    confirmPassword: null,
  },
  basicInfoModel: {
    isOrganization: false,
    organizationName: null,
    organizationDescription: null,
    chamberNo: null,
    FirstName: null,
    LastName: null,
    FatherName: null,
    Profession: null,
    organizationArea: null,
    organizationWebsite: null,
    Suffix: null,
    checkSuffix: null,
    Photo: null,
    isImg: null,
    displayRole: null,
    showRating: null,
    ratingData: null,
  },
  contactInfoModel: {
    Email: null,
    Email2: null,
    Mobile: null,
    Telephone: null,
    otherContactDetails: null,
    secretaryEmail: null,
    Type: null,
    address: {
      country: null,
      city: null,
      neighborhood: null,
      street: null,
      zip: null,
      otherAddress: null,
      gps: null,
      lat: null,
      lng: null,
    },
    address2:  {
      country: null,
      city: null,
      neighborhood: null,
      street: null,
      zip: null,
      otherAddress: null,
      gps: null,
      lat: null,
      lng: null,
    },
  },
  socialInfoModel: {
    Website: null,
    FB: null,
    Instagram: null,
    LinkedIn: null,
    Twitter: null,
    otherSocialHandles: null,
  },
  addressInfoModel: {
    Country: null,
    City: null,
    Neighborhood: null,
    Street: null,
    Zip: null,
    otherAddress: null,
    gps: null,
  },
  personalInfoModel: {
    Birthday: null,
    Nameday: null,
    AFM: null,
    DOY: null,
    personalId: null,
    otherField: null,
    chamberNo: null,
  },
  bioInfoModel: {
    specLocations: [],
    cvInfo: null,
    shortBio: null,
    qualifications: [],
    languages: [],
  },
  createAccountModel: {
    accountCreate: false,
    UserPassword: null,
    confirmPassword: null
  },
  addAdminBrokerModel: {
    owner: null,
  },
  accountEditInfoModel: {
    Email: null,
    LastCompany: null,
    IsEnabled: null,
    memberTitle: null,
    _id: null,
  },
  coinsEditModel: {
    AvailableAdsNewDeal: null,
    AvailableAdsSpitogatos: null,
    AvailableAdsXE: null,
    CommentAdsNewDeal: null,
    CommentAdsSpitogatos: null,
    CommentAdsXE: null,
  },

  createPageModel: [
    {
      roles: 'Associate',
      infoModels: ['accountInfoModel', 'basicInfoModel', 'contactInfoModel', "bioInfoModel", 'createAccountModel', 'socialInfoModel', 'addressInfoModel', 'personalInfoModel', 'filesInfoModel', 'sourceInfoModel', 'commentInfoModel', 'accountEditInfoModel', 'coinsEditModel'],
      accountInfoModel: ["UserName", "UserPassword", "IsEnabled","confirmPassword"],
      basicInfoModel: ["FirstName", "LastName", "FatherName", "Profession","Suffix", "Photo", "isImg", "showRating", "ratingData"],
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails"],
      bioInfoModel: ["cvInfo","languages","qualifications","shortBio","specLocations"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addressInfoModel: ["Country", "City", "Neighborhood", "Street", "Zip", "otherAddress"],
      personalInfoModel: ["Birthday", "Nameday", "AFM", "DOY", "personalId", "otherField", "chamberNo"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id"],
      createAccountModel: ["accountCreate", "UserPassword", "confirmPassword"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },
    {
      roles: 'Admin',
      infoModels: ['accountInfoModel', 'basicInfoModel', 'contactInfoModel', 'createAccountModel', 'socialInfoModel', 'addressInfoModel', 'personalInfoModel', 'filesInfoModel', 'sourceInfoModel', 'commentInfoModel', 'accountEditInfoModel', 'coinsEditModel'],
      accountInfoModel: ["UserName", "UserPassword",  "confirmPassword"],
      basicInfoModel: ["FirstName", "LastName", "FatherName", "Profession", "Photo", "isImg", "displayRole", "showRating", "ratingData"],
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addressInfoModel: ["Country", "City", "Neighborhood", "Street", "Zip", "otherAddress"],
      personalInfoModel: ["Birthday", "Nameday", "AFM", "DOY", "personalId", "otherField","chamberNo"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id"],
      createAccountModel: ["accountCreate", "UserPassword", "confirmPassword"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },
    {
      roles: 'Broker',
      infoModels: ['accountInfoModel', 'basicInfoModel', 'contactInfoModel', 'createAccountModel', 'socialInfoModel', 'addressInfoModel', 'personalInfoModel', 'filesInfoModel', 'sourceInfoModel', 'commentInfoModel', 'accountEditInfoModel', 'coinsEditModel'],
      accountInfoModel: ["UserName", "UserPassword", "IsEnabled", "confirmPassword"],
      basicInfoModel: ["FirstName", "LastName", "FatherName", "Profession","Suffix", "Photo", "isImg", "displayRole", "showRating", "ratingData"],
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addressInfoModel: ["Country", "City", "Neighborhood", "Street", "Zip", "otherAddress"],
      personalInfoModel: ["Birthday", "Nameday", "AFM", "DOY", "personalId", "otherField","chamberNo"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id"],
      createAccountModel: ["accountCreate", "UserPassword", "confirmPassword"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },
    {
      roles: 'Secretary',
      infoModels: ['accountInfoModel', 'basicInfoModel', 'contactInfoModel', 'createAccountModel', 'socialInfoModel', 'addressInfoModel', 'personalInfoModel', 'filesInfoModel', 'sourceInfoModel', 'commentInfoModel', 'accountEditInfoModel', 'coinsEditModel'],
      accountInfoModel: ["UserName", "UserPassword",  "confirmPassword"],
      basicInfoModel: ["FirstName", "LastName", "FatherName", "Profession", "Photo", "isImg", "displayRole", "showRating", "ratingData"],
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addressInfoModel: ["Country", "City", "Neighborhood", "Street", "Zip", "otherAddress"],
      personalInfoModel: ["Birthday", "Nameday", "AFM", "DOY", "personalId", "otherField"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id"],
      createAccountModel: ["accountCreate", "UserPassword", "confirmPassword"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },
    {
      roles: "Member",
      infoModels: ["basicInfoModel", "contactInfoModel", "socialInfoModel", 'createAccountModel', "addressInfoModel", "personalInfoModel", "sourceInfoModel", "commentInfoModel", "createAccountInfoModel", "accountEditInfoModel", "coinsEditModel"],
      basicInfoModel: ["isOrganization", "organizationName", "organizationDescription", "chamberNo", "FirstName", "LastName", "FatherName", "Profession", "Photo", "isImg", "displayRole", "showRating", "ratingData"],
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addressInfoModel: ["Country", "City", "Neighborhood", "Street", "Zip", "otherAddress"],
      personalInfoModel: ["Birthday", "Nameday", "AFM", "DOY", "personalId", "otherField"],
      createAccountModel: ["accountCreate", "UserPassword", "confirmPassword"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id", "memberTitle"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },
    {
      roles: "owner",
      infoModels: ["basicInfoModel", "contactInfoModel", "socialInfoModel", "addressInfoModel", "createAccountModel", "personInfoModel", "sourceInfoModel", "commentInfoModel", "createAccountInfoModel","accountEditInfoModel", "coinsEditModel"],
      basicInfoModel: ["isOrganization", "organizationName", "organizationDescription", "chamberNo", "FirstName", "LastName", "FatherName", "Profession", "Photo", "isImg", "displayRole", "showRating", "ratingData"],
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addressInfoModel: ["Country", "City", "Neighborhood", "Street", "Zip", "otherAddress"],
      personalInfoModel: ["Birthday", "Nameday", "AFM", "DOY", "personalId", "otherField"],
      createAccountModel: ["accountCreate", "UserPassword", "confirmPassword"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },
    {
      roles: "other",
      infoModels: ["basicInfoModel", "contactInfoModel", "socialInfoModel", "addressInfoModel", "personInfoModel", "sourceInfoModel", "createAccountModel", "commentInfoModel", "createAccountInfoModel", "accountEditInfoModel", "coinsEditModel"],
      basicInfoModel: ["isOrganization", "organizationName", "organizationDescription", "chamberNo", "FirstName", "LastName", "FatherName", "Profession", "Photo", "isImg", "displayRole", "showRating", "ratingData"],
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addressInfoModel: ["Country", "City", "Neighborhood", "Street", "Zip", "otherAddress"],
      personalInfoModel: ["Birthday", "Nameday", "AFM", "DOY", "personalId", "otherField"],
      createAccountModel: ["accountCreate", "UserPassword", "confirmPassword"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },
    {
      roles: "ifAssociate",
      infoModels: ["basicInfoModel", "contactInfoModel", "bioInfoModel", "socialInfoModel", "addressInfoModel", "personalInfoModel", "createAccountModel", "sourceInfoModel", "commentInfoModel", "createAccountInfoModel", "accountEditInfoModel", "coinsEditModel"],
      basicInfoModel: ["isOrganization", "organizationName", "organizationDescription", "chamberNo", "FirstName", "LastName", "FatherName", "Profession", "Photo", "isImg", "displayRole", "showRating", "ratingData"],
      bioInfoModel: ["cvInfo","languages","qualifications","shortBio","specLocations"],
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addressInfoModel: ["Country", "City", "Neighborhood", "Street", "Zip", "otherAddress"],
      personalInfoModel: ["Birthday", "Nameday", "AFM", "DOY", "personalId", "otherField"],
      createAccountModel: ["accountCreate", "UserPassword", "confirmPassword"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id", "memberTitle"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },
    {
      roles: "company",
      infoModels: ["basicInfoModel", "contactInfoModel", "socialInfoModel", "filesInfoModel", "addAdminBrokerModel", "accountEditInfoModel", "coinsEditModel"],
      //basicInfoModel: ["isOrganization", "organizationName"],
      basicInfoModel: ["organizationName", "organizationArea", "organizationWebsite", "Photo", "isImg", "displayRole", "showRating", "ratingData"], 
      contactInfoModel: ["Email", "Email2", "Mobile", "Telephone", "otherContactDetails", "address", "address2", "secretaryEmail"],
      socialInfoModel: ["Website", "FB", "Instagram", "LinkedIn", "Twitter", "otherSocialHandles"],
      addAdminBrokerModel: ["owner"],
      accountEditInfoModel: ["Email", "LastCompany", "IsEnabled", "_id"],
      coinsEditModel: ["AvailableAdsNewDeal", "AvailableAdsSpitogatos", "AvailableAdsXE","CommentAdsNewDeal", "CommentAdsSpitogatos", "CommentAdsXE"]
    },

  ],
  docTypesList: [
    {
      name: "allFiles",
      id: "all",
    },
    {
      name: "agreements",
      id: "agreements",
    },
    {
      name: "myDocuments",
      id: "myDocuments",
    },
  ],
};
