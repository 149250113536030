<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="12" align="left">
          <span class="textInterBold font14 goldText">
            {{ $t("accountInformation") }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="pt-4"
          v-if="true == checkShow('RegistrationDate')"
        >
          <ReadField
            :fontSize="fontSizeRF"
            :fontSizeValue="fontSizeValue"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('addContact')"
            :data="regDate"
            :isUnderline="false"
          />
        </v-col>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="pt-4"
          v-if="true == checkShow('UpdatedDay')"
        >
          <ReadField
            :fontSize="fontSizeRF"
            :fontSizeValue="fontSizeValue"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('lastEdited')"
            :data="UpdatedDay"
            :isUnderline="false"
          />
        </v-col>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="pt-4"
          v-if="true == checkShow('Type')"
        >
          <ReadField
            :fontSize="fontSizeRF"
            :fontSizeValue="fontSizeValue"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('contactType')"
            :showHtml="true"
            :htmlVal="createHtmlVal(roleType)"
            :isUnderline="false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="pt-4"
          v-if="true == checkShow('IsEnabled')"
        >
          <ReadField
            :fontSize="fontSizeRF"
            :fontSizeValue="fontSizeValue"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('condition')"
            :data="data.IsEnabled"
            :iconObj="
              parseBoolean(data.active) == true
                ? { icon: 'mdi:circle', height: 17, color: '#77BD86' }
                : { icon: 'mdi:circle', height: 17, color: '#E52628' }
            "
            dataClass=""
          />
        </v-col>
        <!-- :data="parseBoolean(data.active) == true ? 'Ενεργός' : 'Ανενεργός'" :showIcon="true" -->
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="pt-4"
          v-if="true == checkShow('Source')"
        >
          <ReadField
            :fontSize="fontSizeRF"
            :fontSizeValue="fontSizeValue"
            :fontFamilyLabel="fontFamilyLabel + ' !important; : underline;'"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('contactOrigin')"
            :data="data.Source"
            dataClass="fontUnderline successColor"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          xs="12"
          class="pt-4"
          v-if="true == checkShow('Suffix')"
        >
          <ReadField
            :fontSize="fontSizeRF"
            :fontSizeValue="fontSizeValue"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="'User ID'"
            :data="data.Suffix"
            :isUnderline="false"
          />
        </v-col>
        <!-- <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4"
                    v-if="true == checkShow('newsletters')">
                    <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="'Newsletters'" :isUnderline="false"
                        :data="parseBoolean(data.newsletters) == true ? '' : 'X'"
                        :showIcon="parseBoolean(data.newsletters) == true"
                        :iconObj="parseBoolean(data.newsletters) == true ? { icon: 'bi:check', height: 28, color: '#77BD86', } : {}" /> -->
        <!-- </v-col> -->
        <!-- <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4"
                    v-if="true == checkShow('viberOrSms')"> -->
        <!-- <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                        :fontFamilyValue="fontFamilyValue" :label="'Viber / SMS'"
                        :data="parseBoolean(data.viberOrSms) == true ? '' : 'X'"
                        :showIcon="parseBoolean(data.viberOrSms) == true"
                        :iconObj="parseBoolean(data.viberOrSms) == true ? { icon: 'bi:check', height: 28, color: '#77BD86', } : {}"
                        :isUnderline="false" /> -->
        <!-- </v-col> -->
      </v-row>
      <v-row
        v-if="mobileBreakpoint == true && topShow.showMore != undefined"
        class="mt-0 justify-center"
      >
        <v-col class="pt-0 pb-0" align="center">
          <v-btn
            class="primaryColor unsetCapitalize opacity1 textDecorationBold"
            text
            plain
            @click="() => (topShow.showMore = !topShow.showMore)"
          >
            {{ topShow.showMore == true ? $t("lessInfo") : $t("moreInfo") }}...
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
/*
Name:AccountInfo
Description: Component to render Account Information using ReadFields 

API Property:
Data: Object containing AccountInfo data 
            data: {
                created: "29-03-2021",
                updated: "29-03-2021",
                contactType: "Admin",
                active: "true",
                contactOrigin: "Προστέθηκε από Γραμματεία",
                userId: "FPR",
                newsletters: "true",
                viberOrSms: "false",

            },
Components Used:
    ReadField   
*/
import ReadField from "@/components/Fields/ReadField/ReadField.vue";
import CoreHelper from "@/helpers/Core/core.js";
import i18n from "@/i18n/i18n";

export default {
  name: "AccountInfo",
  components: {
    ReadField,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      roleType: [], //Array to save role/types for display at profile contact
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
      fontSizeValue: "13px",
      topArray: [
        "created",
        "updated",
        "contactType",
        "active",
        "contactOrigin",
        "contactType",
        "userId",
        "newsletters",
        "viberOrSms",
      ],
      topShow: { showKeys: [], showMore: undefined },
    };
  },
  created() {
    this.roleType.push(this.getTypeContact());
    if (this.data.IsEnabled) {
      if (["true", true, "active"].includes(this.data.IsEnabled)) {
        this.data.IsEnabled = i18n.t("active2");
      }
      if (["false", false, "inactive"].includes(this.data.IsEnabled)) {
        this.data.IsEnabled = i18n.t("inactive2");
      }
      if (["semi-active"].includes(this.data.IsEnabled)) {
        this.data.IsEnabled = i18n.t("semiActive2");
      }
    }

    if (this.mobileBreakpoint == true)
      this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4);
  },
  computed: {
    UpdatedDay() {
      return CoreHelper.EpochToDate(this.data.UpdatedDay);
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    regDate() {
      return CoreHelper.EpochToDate(this.data.RegistrationDate);
    },
  },

  methods: {
    createHtmlVal(arr) {
      let spans = "";
      arr.map((m) => {
        spans +=
          " <span class='font13' style='font-family: InterMedium; !important'> " +
          m +
          "</span> <br/>";
      });
      return spans;
    },
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    checkShow(objKey) {
      return this.data[objKey]
        ? this.mobileBreakpoint != true
          ? true
          : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
            this.topShow.showMore != false
          ? true
          : false
        : false;
    },
    getTypeContact() {
      var result;
      if (this.data.Role) {
        if (this.data.Role == "Admin") {
          result = i18n.t("admin");
        } else if (this.data.Role == "OfficeAdmin") {
          result = "Broker";
        } else if (this.data.Role == "Secretary") {
          result = i18n.t("secretariat");
        } else if (this.data.Role == "HR") {
          result = "HR Specialist";
        } else if (this.data.Role == "Associate") {
          result = "Agent Pro";
        }
      } else {
        if (
          [
            "Πελάτης",
            "Πελάτης για Αγορά",
            "Πελάτης για Ενοικίαση",
            "Ενδιαφερόμενος για Αγορά",
            "Ενδιαφερόμενος για Ενοικίαση",
            "Ενδιαφερόμενος με Αντιπαροχή",
          ].some((ai) => this.data.Type.includes(ai))
        ) {
          result = i18n.t("customer");
        } else if (["Member"].includes(this.data.Type)) {
          result = "Member";
        } else if (["Υποψήφιος Συνεργάτης"].includes(this.data.Type)) {
          result = i18n.t("associateCandidate");
        } else if (
          [
            "Ιδιοκτήτης για Πώληση",
            "Ιδιοκτήτης για Ενοικίαση",
            "Ιδιοκτήτης με Αντιπαροχή",
          ].some((ai) => this.data.Type.includes(ai))
        ) {
          result = i18n.t("owner");
        }
      }
      return result;
    },
  },
};
</script>