<template>
  <v-navigation-drawer width="100%" style="height: 100%; max-width: 100%" right v-model="drawer" app temporary
    :permanent="mobileBreakpoint == true">
    <v-container fluid>
      <v-row class="flex-nowrap mr-10">
        <v-col>
          <v-tabs show-arrows="always" v-model="activeTab">
            <v-tabs-slider style="height: 4px" color="error"> </v-tabs-slider>
            <v-tab class="textTranformUnset textDecorationBold mr-1 cusTab">
              {{ $t("photos") }}
            </v-tab>
            <v-tab class="textTranformUnset textDecorationBold mr-1 cusTab"
              :disabled="[undefined, '', null].includes(this.data.Youtube)">
              {{ $t("video") }}
            </v-tab>
            <v-tab :disabled="documentsArray('marketing').length == 0" class="textTranformUnset textDecorationBold mr-1 cusTab">
              {{ $t("marketing") }}
            </v-tab>
            <v-tab v-if="isAuthorized('viewAll', data)" :disabled="documentsArray('estate_docs').length == 0"
              class="textTranformUnset textDecorationBold mr-1 cusTab">
              {{ $t("propertyDocuments") }}
            </v-tab>
            <v-tab disabled class="textTranformUnset textDecorationBold mr-1 cusTab">
              Virtual Tour 360
            </v-tab>
            <v-tab class="textTranformUnset textDecorationBold mr-1 cusTab" :disabled="
              [undefined, '', null].includes(this.data.GPS) ||
              this.data.GPS.length == 0 ||
              [undefined, '', null].includes(this.data.GPS[0]) ||
              [undefined, '', null].includes(this.data.GPS[1])
            ">
              {{ $t("map") }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col class="flex-grow-0">
          <v-icon class="primaryColorOnHover" @click="closeHandler" style="color: #263573">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row v-if="activeTab == 0" class="ma-0">
        <CarouselField v-if="mobileBreakpoint != true" :showImgDetails="true" :data="photos" :showTitle="true"
          :showPagination="true" :currentImage="currentPhoImg" currentPropName="currentPhoImg"
          :setCurrentImage="setCurrentImage" :fullScreenOnClick="true" :setFullScreen="openDialog" />
        <v-col v-else class="pl-0 pr-0">
          <v-row v-for="(photo, index) in photos" :key="index">
            <v-col>
              <v-row>
                <v-col align="left" class="textDecorationBold font14 cusTab pl-6">
                  <span>
                    {{
                        photo && photo.title && photo.title[$i18n.locale]
                          ? photo.title[$i18n.locale]
                          : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-img :height="286" :src="getImage(photo)" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-2 blueBackground" v-if="activeTab == 1">
        <VideoField :url="this.data.Youtube" width="900" height="642" />
      </v-row>
      <v-row v-if="activeTab == 2" class="mb-6">
        <PdfViewer :data="documentsArray('marketing')" :showTitle="true" :showPdfDetails="true"
          :showPagination="true" />
      </v-row>
      <v-row v-if="activeTab == 3" class="mb-6">
        <PdfViewer :data="documentsArray('estate_docs')" :showTitle="true" :showPdfDetails="true"
          :showPagination="true" />
      </v-row>
      <v-row v-if="
        (isAuthorized('viewAll', data) == true && activeTab == 5) ||
        (isAuthorized('viewAll', data) == false && activeTab == 4)
      ">
        <v-col class="mt-3">
          <v-row class="">
            <v-col>
              <Map :locationMarkers="locationMarkers" :showDetails="true" :isMedia="true" :showNIS="showNIS"
                mapHeight="681px" mapWidth="1200" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="align-self-center flex-grow-0">
              <v-btn @click="showNIS = !showNIS" :class="showNIS ? 'activeBtn' : false" :loading="loadingShowNIS"
                class="ma-1 btn" outlined>
                {{ $t("ViewInternationalSchools") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-between headerArea" v-if="mobileBreakpoint != true">
        <v-col>
          <RealEstateHeader :title="
            this.data.Category + ' ' + $t('propTo') + ' ' + this.data.Task
          " :isVip="
  data.characteristic &&
  parseBoolean(data.characteristics.vip) == true
" :subtitle="
  this.data.Location != undefined
    ? this.data.Location
    : this.data.Address != undefined
      ? this.data.Address
      : ''
" :rating="avgReviewsRating.revAvg" :ratingCount="avgReviewsRating.revCnt" :bedrooms="
  this.data.characteristics && this.data.characteristics.bedrooms
    ? this.data.characteristics.bedrooms
    : 0
" :bathrooms="
  this.data.characteristics && this.data.characteristics.bathrooms
    ? this.data.characteristics.bathrooms
    : 0
" :size="this.data.Unit" :constructionYear="this.data.BuildYear" :floor="
  this.data.floors && this.data.floors.length > 0
    ? $t(this.data.floors[0].FloorLiteral)
    : null
" />
        </v-col>
        <v-col align="right" class="headerRightArea pt-5">
          <v-row class="justify-end">
            <v-col class="pb-0 font18" style="font-family: InterSemiBold; color: #3a3a40">
              <span> {{ this.data.EstateCode }} </span>
            </v-col>
          </v-row>
          <v-row class="justify-end">
            <v-col class="pt-0 font24 text-no-wrap" style="font-family: InterSemiBold; color: #263573">
              <span> {{ this.data.Price }} </span>
            </v-col>
          </v-row>
          <v-row class="justify-end">
            <v-col class="flex-grow-0">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="21"
                viewBox="0 0 20 21">
                <image id="share" width="20" height="21"
                  xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAABHNCSVQICAgIfAhkiAAAAW9JREFUOE+tlc0rBVEYh40slHyWhZSVbrEVOysLSylkYWFpcUuy8VFcFqwo/AVKQlGU1U3dlZIkKWV3V6wkZaF8Pr86U+dqDuee5tTTnTlz3mfOO+87c6NM93RFwKghZhmGoRauYBYuowBhJYEF6P21kXfNhQgHCTxyZHUWIlwx6SU5X0KEU5jWHTsslivsQ7QFHQ5hzlfYhmANhuAQTs0uGyzxLsfjsbCKkxHoglfYhzuoBvXVPBRhEvJG0shvP0h6Adeal7DOLOqx7vbJ8SYMQDOo5zZArfHnkFDPJOtYdcL8BDz+J4qvS/jASYsjYI75VV9ZnPITB02OoEWTrrdTO1TXq/uTxj2TOTiALx+rhO0sPAc9fHuo0m8wBrewBMfwbRapuqqyql1SZV1vhRlQpZ9hD7ZNsG6oKo/CjRF/8LsD9dYOSvrQJ5tOFi2A+jVyBHi/KXa8pEo/aZT9LkuS+tcm9e9h6l9spe38T/kBf8tWiDAk0IAAAAAASUVORK5CYII=" />
              </svg>
            </v-col>
            <v-col class="flex-grow-0">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="19"
                viewBox="0 0 22 19">
                <image id="Layer_38" data-name="Layer 38" width="22" height="19"
                  xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAATCAYAAACUef2IAAAABHNCSVQICAgIfAhkiAAAAT1JREFUOE/tlD1LQzEUhr1W7OBWBOlQ2kFF7SY4FH9M90KH4qS7bkLFXfxBDg5SEUGrUBChkzpZP573kpRwuTc3cTbwkuTkPQ/h5COZbG4tOO2AcR81TWxMf4aGrolxDw3Quok/0Z+jU+tLDPiSQDeT7E6V2DKBG/p2gVcb6aAXC35nsuIBa+kafaD9At838UX0itYEPmZwVAINXbbwocA/oVkxvn/wvFoqxQmzw5j6BXgvYq5bAC+16GZULVjPb4SS0GyPb4+1KwuWbxvpVemS/7WlUCW7YM030C2qRJL1FnaRXmfasmDFGugxAi6o/g5taN7ywBb+wGCpZOc6qB10l/UVgeWrozFaLoB/Ede53Oet+8Dyr6JJDnxGTH+xvtPcVgZWUg1NnexPA332lSkEbPNVc/3Z2umbD6q1X0z4SQmGTy9wAAAAAElFTkSuQmCC" />
              </svg>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="align-self-center" cols="4" xs="4" sm="4" md="4" lg="4">
          <v-row class="justify-end align-center">
            <v-col class="flex-grow-0" v-if="isAuthorized('edit', data)  && editEstate">
              <v-btn elevation="1" align="center" class="ma-1 btn" color="error" @click="onEditEstate">
                {{ $t("processingOfProperty") }}
              </v-btn>
            </v-col>
            <v-col class="flex-grow-0" v-if="isAuthorized('edit', data) == true">
              <v-btn class="ma-1 btn" elevation="1" outlined :loading="loading" @click="openCooperation">
                {{ $t("requestForCooperation") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else class="pa-3">
        <v-col>
          <RealEstateHeaderMobile class="sliderHeader" :title="data.Category + ' ' + $t('propTo') + ' ' + data.Task"
            :code="data.EstateCode ? data.EstateCode : ''" :price="data.Price ? data.Price : ''" :isVip="
              data.characteristic &&
              parseBoolean(data.characteristics.vip) == true
            " :subtitle="
              data.Location != undefined
                ? data.Location
                : data.Address != undefined
                  ? data.Address
                  : ''
            " :rating="avgReviewsRating.revAvg" :ratingCount="avgReviewsRating.revCnt" :bedrooms="
              data.characteristics && data.characteristics.bedrooms
                ? data.characteristics.bedrooms
                : 0
            " :bathrooms="
              data.characteristics && data.characteristics.bathrooms
                ? data.characteristics.bathrooms
                : 0
            " :size="data.Unit" :constructionYear="data.BuildYear" :floor="
              data.floors && data.floors.length > 0
                ? $t(data.floors[0].FloorLiteral)
                : null
            " />
        </v-col>
      </v-row>
    </v-container>
    <MediaFullScreen :drawer="this.dialog.open && this.dialog.type == 'MediaFullScreen'"
      :title="this.data.Category + ' ' + $t('propTo') + ' ' + this.data.Task" :propertyCode="this.data.EstateCode"
      :images="this.dialog.data != undefined ? this.dialog.data.images : []"
      :current="this.dialog.data != undefined ? this.dialog.currentImage : null" :close="this.closeDialog" />

    <v-dialog v-model="dialog.open" scrollable v-if="dialog.type == 'cooperation'" max-width="690">
      <Cooperation v-if="this.dialog.type == 'cooperation'" :data="this.dialog.data" :closeHandler="this.closeDialog"
        :submitHandler="this.closeDialog" />
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
/*
 Name: MediaSlider
 Description: Component for the real estate media slider
 API Property: 
    data: Object for the slider data
    onEdit: Functio for the action on edit
    close: Function on close 
    editEstate: Boolean to enable or disable edit estate

    Endpoints Functions Called:
    -RealEstateRepo
      getInternationalSchools
*/
import RealEstateHeader from "@/components/RealEstate/RealEstateHeader";
import MediaFullScreen from "@/components/RealEstate/MediaFullScreen";
import CarouselField from "@/components/Fields/CarouselField/CarouselField";
import VideoField from "@/components/Fields/VideoField/VideoField";
import PdfViewer from "@/components/PdfViewer/PdfViewer";
import Map from "../Maps/Map";
import Cooperation from "@/components/RealEstate/RealEstateSlider/Cooperation.vue";
import Repository from "@/services/Repository";
import RealEstateModel from "@/static/realEstate/realEstate.js";
import AuthHelper from "@/helpers/Core/auth.js";
import CoreHelper from "@/helpers/Core/core.js";
import { mapState } from "vuex";
import RealEstateHeaderMobile from "@/components/RealEstate/RealEstateHeaderMobile";


const RealEstateRepo = Repository.get("realEstate");

export default {
  name: "MediaSlider",
  props: {
    data: Object, // Slider data
    onEdit: Function, // Action on edit
    close: Function, // Close dialog function
    editEstate: { type: Boolean, default: true }, //Enable or disable edit estate
  },
  components: {
    RealEstateHeader,
    RealEstateHeaderMobile,
    MediaFullScreen,
    Map,
    CarouselField,
    VideoField,
    PdfViewer,
    Cooperation,
  },
  created() {
    this.fetchIntSchools();
  },
  watch: {
    showNIS() {
      this.fetchIntSchools();
    },
  },

  data() {
    return {
      drawer: true,
      activeTab: 0,
      fullscreen: false,
      showNIS: false,
      loadingShowNIS: false,
      interSchoolsLocMarkers: [],
      dialog: {
        open: false,
        data: null,
        type: null,
      },
      dialog2: false,
      currentPhoImg: 0,
      currentMarktPdf: 0,
      currentFileImg: 0,
      fileWidth: 600,
      marketingWidth: 600,
      locPlaces: [],
      existingPlace: null,
      showNearestInternationalSchool: false,
      loading: false,
      docTypesList: RealEstateModel.docTypesList,
      propertiesArray: [
        {
          image: "realEstateSilder/bedroom.png",
          value: 10,
        },
        {
          image: "realEstateSilder/bathroom.png",
          value: 20,
        },
        {
          image: "realEstateSilder/sqft.png",
          value: "30 τμ",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    locationMarkers() {
      let locMarkers = [];
      try {
        locMarkers = JSON.parse(
          JSON.stringify([
            {
              position: { lat: this.data.GPS[0], lng: this.data.GPS[1] },
              data: this.data,
              type: "data",
            },
          ])
        );
      } catch {
        locMarkers = [{ position: { lat: "", lng: "" }, type: "data" }];
      }
      this.showNIS
        ? locMarkers.push(...this.interSchoolsLocMarkers)
        : locMarkers;
      return locMarkers;
    },
    photos() {
      var photos=[];
      if(this.data?.selectedPhotos=='watermarked'){
        photos=this.data.watermarkedphotos
      }else{
        photos=this.data.photos
      }
      if (photos) {
        if (this.isAuthorized("viewAll", this.data) == true) {
          return photos;
        } else {
          return photos.filter((p) => {
            if (p.public) {
              if (p.public == "Public") return p;
            }
          });
        }
      } else return [];
    },
    avgReviewsRating() {
      let reviewsSum = 0;
      let revCnt = 0;
      if (this.data.reviews && this.data.reviews.length > 0) {
        this.data.reviews.map((m) => {
          if (m.rating_average) {
            reviewsSum += m.rating_average;
            revCnt += 1;
          }
        });
      }
      let revAvg = 0;
      revAvg =
        revCnt != 0 ? parseFloat(reviewsSum / revCnt).toFixed(2) : revAvg;
      return {
        revAvg: revAvg,
        revCnt: revCnt,
      };
    },
  },
  methods: {
    closeHandler() {
      this.close("card", this.data);
    },
    closeFullscreen() {
      this.fullscreen = false;
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    getImage(item) {
      return CoreHelper.getImagePath(item);
    },
    setCurrentImage(prop, val) {
      this[prop] = val;
    },
    actionHandler(message) {
      message;
    },
    closePopHandler() {
      this.dialog2 = false;
    },
    onEditEstate() {
      this.onEdit("edit", this.data);
    },
    async fetchIntSchools() {
      if (this.showNIS) {
        this.loadingShowNIS = true;
        const resp = await RealEstateRepo.getInternationalSchools();
        let internationalSchools = [];
        try {
          resp.data.map((school) => {
            internationalSchools.push({
              position: {
                lat: school["GPS"]["coordinates"][0],
                lng: school["GPS"]["coordinates"][1],
              },
              type: "school",
            });
          });
        } catch (err) {
          internationalSchools = [];
        }
        this.interSchoolsLocMarkers = internationalSchools;
        this.loadingShowNIS = false;
      }
    },
    openCooperation() {
      this.openDialog("cooperation", this.data);
    },
    documentsArray(docType) {
      let indx = this.docTypesList.findIndex(
        (docTypes) => docTypes.id == docType
      );
      let docsArray = [];
      let docTypesKeys = [];
      if (this.data && this.data.documents) {
        this.docTypesList[indx].subCats.map((m) => {
          docTypesKeys.push(m.id);
        });
        let objKeys = Object.keys(this.data.documents).filter((i) =>
          docTypesKeys.includes(i)
        );
        objKeys.map((o) => {
          this.data.documents[o]
            ? docsArray.push(...this.data.documents[o])
            : true;
        });
      }
      return docsArray;
    },
    isAuthorized(action, data) {
      let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
      return AuthHelper.authorization(
        action,
        data[key] ? data[key] : null,
        data["userID"] ? data["userID"] : null
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tab--active {
  color: #eba02c !important;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.galleryScrollbar::-webkit-scrollbar {
  display: none;
}

.galleryScrollbar {
  overflow: auto;
  max-height: 513px;
}
p {
  text-align: left;
}

.textTranformUnset {
  text-transform: unset !important;
}

.blueBackground {
  background-color: #21244b;
}

.headerRightArea {
  flex: 0 0 10%;
  max-width: 10%;
}

@media only screen and (max-width: 835px) {
  .headerArea {
    flex-wrap: wrap;
  }

  .headerRightArea {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.activeBtn {
  background-color: #263573 !important;
  color: white !important;
  text-transform: unset !important;
  background-color: #263573 !important;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}
</style>
