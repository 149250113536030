import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCFMVxCoZMyafZGttcPMO-MabmITDAQ-Co",
  authDomain: "newdeal-crm.firebaseapp.com",
  projectId: "newdeal-crm",
  storageBucket: "newdeal-crm.appspot.com",
  messagingSenderId: "145998569314",
  appId: "1:145998569314:web:b4d1e5c0b1a71c9449563b",
  measurementId: "G-VNB5TNFK5K",
};

export default firebase.initializeApp(firebaseConfig);
