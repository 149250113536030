<template>
  <v-navigation-drawer width="100%" style="max-width: 100%" class="fill-height" right v-model="drawer" app temporary>
    <v-container fluid class="ma-0 pa-0 height-100">
      <v-row class="align-end ma-0">
        <v-col>
          <v-row>
            <v-col>
              <v-row class="align-end">
                <v-col class="header text-no-wrap textDecorationBold">
                  {{ title }}
                </v-col>
                <v-col align="left" class="subHeader">
                  {{ propertyCode }}
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="font14 textDecorationBold text-no-wrap pt-2 align-end">
                <v-col align="left" v-if="
                  images[currentImage] &&
                  images[currentImage].title &&
                  images[currentImage].title.gr
                ">
                  {{ images[currentImage].title.gr }}
                </v-col>
                <v-col align="left">
                  {{ currentImage + 1 }} /
                  {{ images == undefined || images == null ? 0 : images.length }}
                </v-col>
                <v-col class="pl-0 mr-3" align="right" cols="1">
                  <v-icon class="primaryColorOnHover" @click="closeDialog()" style="color: #263573">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="blueBackground mt-0 height-88">
        <v-col cols="12" class="pt-0 fill-height">
          <CarouselField :data="images" :currentImage="currentImage" currentPropName="currentImage"
            :setCurrentImage="setCurrentImage" :fullScreen="true" />
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
/*
 Name: MediaFullScreen
 Description: Component for the real estate media full screen
 API Property: 
    title: String for the slider title
    propertyCode: String for the property code
    drawer: Boolean if ss on Fullscreen or No
    images: Array for the images array to show on fullscreen
    current: Number for the current image index
    close: Function on close function

    Components Used:
      CarouselField 
*/
import CarouselField from "@/components/Fields/CarouselField/CarouselField";

export default {
  name: "MediaFullScreen",
  components: {
    CarouselField,
  },
  props: {
    title: String, //Slider Title
    propertyCode: String, // Property Code
    drawer: Boolean, // Is on Fullscreen or No
    images: Array, // Images array to show on fullscreen
    current: Number, // Current image index
    close: Function, // Close function
  },
  created() {
    this.currentImage = this.current;
  },
  data() {
    return { currentImage: 0 };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.close();
    },
    setCurrentImage(prop, val) {
      this[prop] = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  color: #263573;
  font-size: 23.99px !important;
}

.subHeader {
  color: #3a3a40;
  font-size: 18px;
}

.blueBackground {
  background-color: #21244b;
}

.height-88 {
  height: 88%;
}
</style>
