<template>
  <v-col>
    <v-row>
      <v-col align="left">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("contactInfo") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('address')">
        <Location :placeholder="$t('address')" :vuexProps="false" :setLocation="setFirstLocation"
          iconName="mdi-map-marker-outline" iconColor="primary" class="pb-0" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('address2')">
        <Location :placeholder="$t('address2')" :vuexProps="false" :setLocation="setSecondLocation"
          iconName="mdi-map-marker-outline" iconColor="primary" class="pb-0" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Email')">
        <v-text-field v-model="Email" label="Email*" clearable dense color="primary" :error-messages="emailErrors" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Email2')">
        <v-text-field v-model="Email2" label="Email 2" clearable dense color="primary" :error-messages="email2Errors" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Mobile')">
        <v-text-field v-model="Mobile" :label="$t('telCom') + '*'" :error-messages="mobileErrors" clearable dense color="primary"
          hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Telephone')">
        <v-text-field v-model="Telephone" :label="$t('otherTel')" clearable dense color="primary" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('otherContactDetails')">
        <v-text-field v-model="otherContactDetails" :label="$t('otherContactInfo')" clearable dense color="primary"
          hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('secretaryEmail')">
        <v-text-field v-model="secretaryEmail" :label="$t('secretaryEmail')" clearable dense color="primary"
          hide-details="auto" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

/*
 Name:ContactInfo
 Description: Component to render contact info fields for contact

 API Property:
    attributes: Object with the data 
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the info type like "contactInfo"

    Components Used:
      Location
*/
import Location from "@/components/Maps/Location.vue";
import i18n from "@/i18n/i18n";

export default {
  name: "ContactInfo",
  components: {
    Location,
  },
  props: {
    attributes: Object,
    updateAttribute: Function,
    validations: Object,
    attributeModel: Array,
    kind: { type: String, default: "contactInfo" },
  },
  computed: {
    
    emailErrors() {
      const errors = [];
      if (this.validations.data.contactInfo.Email.$dirty != undefined) {
        if (!this.validations.data.contactInfo.Email.$dirty) return errors;
        if (!this.validations.data.contactInfo.Email.email) errors.push("Λάθος Email");
        if (!this.validations.data.contactInfo.Email.required)
        errors.push(i18n.t("requiredField"));
      }
      return errors;
    },
    email2Errors() {
      const errors = [];
      if (this.validations.data.contactInfo.Email2.$dirty != undefined) {
        if (!this.validations.data.contactInfo.Email2.$dirty) return errors;
        if (!this.validations.data.contactInfo.Email2.email) errors.push("Λάθος Email");
      }
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (this.validations.data.contactInfo.Mobile.$dirty != undefined) {
        if (!this.validations.data.contactInfo.Mobile.$dirty) return errors;
        if (!this.validations.data.contactInfo.Mobile.required)
        errors.push(i18n.t("requiredField"));
      }
      return errors;
    },
    Email: {
      get() {
        return this.attributes.Email;
      },
      set(value) {
        this.updateAttribute(this.kind, "Email", value);
      },
    },
    Email2: {
      get() {
        return this.attributes.Email2;
      },
      set(value) {
        this.updateAttribute(this.kind, "Email2", value);
      },
    },
    Mobile: {
      get() {
        return this.attributes.Mobile;
      },
      set(value) {
        this.updateAttribute(this.kind, "Mobile", value);
      },
    },
    Telephone: {
      get() {
        return this.attributes.Telephone;
      },
      set(value) {
        this.updateAttribute(this.kind, "Telephone", value);
      },
    },
    otherContactDetails: {
      get() {
        return this.attributes.otherContactDetails;
      },
      set(value) {
        this.updateAttribute(this.kind, "otherContactDetails", value);
      },
    },
    secretaryEmail: {
      get() {
        return this.attributes.secretaryEmail;
      },
      set(value) {
        this.updateAttribute(this.kind, "secretaryEmail", value);
      },
    },
    address: {
      get() {
        return this.attributes.address;
      },
      set(value) {
        this.updateAttribute(this.kind, "address", value);
      },
    },
    address2: {
      get() {
        return this.attributes.address2;
      },
      set(value) {
        this.updateAttribute(this.kind, "address2", value);
      },
    },
  },
  methods: {
    setFirstLocation(sLocationDetails) {
      if (sLocationDetails && sLocationDetails.addressExtracted) {
        this.address.country = sLocationDetails.addressExtracted.country ? sLocationDetails.addressExtracted.country : this.Country;
        this.address.city = sLocationDetails.addressExtracted.locality ? sLocationDetails.addressExtracted.locality : this.City;
        this.address.neighborhood = sLocationDetails.addressExtracted.administrative_area_level_1 ? sLocationDetails.addressExtracted.administrative_area_level_1
          : sLocationDetails.addressExtracted.administrative_area_level_2 ? sLocationDetails.addressExtracted.administrative_area_level_2
            : sLocationDetails.addressExtracted.administrative_area_level_3 ? sLocationDetails.addressExtracted.administrative_area_level_3 : this.Neighborhood;
        this.address.street = (sLocationDetails.addressExtracted.route ? sLocationDetails.addressExtracted.route : this.Street) + (sLocationDetails.addressExtracted.postal_code ? ' ' + sLocationDetails.addressExtracted.street_number : '');
        this.address.zip = sLocationDetails.addressExtracted.postal_code ? sLocationDetails.addressExtracted.postal_code : this.Zip;
        this.address.otherAddress = sLocationDetails.formatted_address ? [sLocationDetails.formatted_address] : this.otherAddress;
      }
      this.address.lat =
        sLocationDetails?.geometry &&
          sLocationDetails?.geometry?.location &&
          sLocationDetails?.geometry?.location?.lat
          ? sLocationDetails?.geometry?.location?.lat()
          : null;
      this.address.lng =
        sLocationDetails?.geometry &&
          sLocationDetails?.geometry?.location &&
          sLocationDetails?.geometry?.location?.lng
          ? sLocationDetails?.geometry?.location?.lng()
          : null;
      this.address.gps = this.address.lat != null && this.address.lng != null ? this.address.lat + "," + this.address.lng : ",";
    },

    setSecondLocation(sLocationDetails) {
      if (sLocationDetails && sLocationDetails.addressExtracted) {
        this.address2.country = sLocationDetails.addressExtracted.country ? sLocationDetails.addressExtracted.country : this.Country;
        this.address2.city = sLocationDetails.addressExtracted.locality ? sLocationDetails.addressExtracted.locality : this.City;
        this.address2.neighborhood = sLocationDetails.addressExtracted.administrative_area_level_1 ? sLocationDetails.addressExtracted.administrative_area_level_1
          : sLocationDetails.addressExtracted.administrative_area_level_2 ? sLocationDetails.addressExtracted.administrative_area_level_2
            : sLocationDetails.addressExtracted.administrative_area_level_3 ? sLocationDetails.addressExtracted.administrative_area_level_3 : this.Neighborhood;
        this.address2.street = (sLocationDetails.addressExtracted.route ? sLocationDetails.addressExtracted.route : this.Street) + (sLocationDetails.addressExtracted.postal_code ? ' ' + sLocationDetails.addressExtracted.street_number : '');
        this.address2.zip = sLocationDetails.addressExtracted.postal_code ? sLocationDetails.addressExtracted.postal_code : this.Zip;
        this.address2.otherAddress = sLocationDetails.formatted_address ? [sLocationDetails.formatted_address] : this.otherAddress;
      }
      this.address2.lat =
        sLocationDetails?.geometry &&
          sLocationDetails?.geometry?.location &&
          sLocationDetails?.geometry?.location?.lat
          ? sLocationDetails?.geometry?.location?.lat()
          : null;
      this.address2.lng =
        sLocationDetails?.geometry &&
          sLocationDetails?.geometry?.location &&
          sLocationDetails?.geometry?.location?.lng
          ? sLocationDetails?.geometry?.location?.lng()
          : null;
      this.address2.gps = this.address.lat != null && this.address.lng != null ? this.address.lat + "," + this.address.lng : ",";
    },
    showAttribute(attribute) {
      return this.attributeModel.includes(attribute);
    }
  }
};
</script>
