<template>
  <v-card
    class="masterContainer"
    :elevation="0"
    style="display: flex; justify-content: center; cursor: pointer"
  >
    <v-col>
      <v-row>
        <v-col align="center">
          <v-img
            :max-height="imgHeight"
            :max-width="imgWidth"
            :src="require('@/assets/general/congrats.png')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          align="center"
          class="textDecorationBold pt-0 font20 primaryColor"
        >
          <span> {{ title }} </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="pt-6 font14 primaryColor">
          <span> {{ subtitle }} </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            class="btn ma-1 textDecorationBold"
            style="height: 42px; width: 188px"
            outlined
            @click="() => onClose()"
            >{{ $t("exit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>


/*
 Name:CongratsPopup
 Description: Popup component with congrats image and a title and a subtitle.

 API Property:
    title: String below the image
    subtitle: String below the title
    imgWidth: Image width 
    imgHeight: Image height 
    onCloseHandler: Function for popup close
*/

export default {
  name: "CongratsPopup",
  props: {
    onCloseHandler: Function, // Function for popup close
    title: String, //String below the image
    subtitle: String, // String below the title
    imgWidth:{ type: Number, default: 500 }, // Image width 
    imgHeight:{ type: Number, default: 74 }, // Image height 
  },
  components: {},
      data() {
        return {
            show: true,
        }
    },
  watch: {
    show() {
      if (!this.show) this.onCloseHandler();
    },
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    onClose() {
      this.onCloseHandler();
    },
  },
};
</script>
>
<style scoped>
.masterContainer {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn {
  text-transform: unset !important;
  color: #263573;
  background-color: white;
  font-weight: 500 !important ;
}
</style>
