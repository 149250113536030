<template>
  <v-menu
    offset-y
    transition="scroll-y-transition"
    class="menu"
    v-bind:[menuPosition]="true"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed icon color="white" v-bind="attrs" v-on="on">
        <v-badge color="red" overlap dot>
          <v-icon>{{ menuIcon }}</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-container class="notifContainer">
      <v-row class="pl-5 pt-2">
        <HeaderTitle :title="headerTitle" />
      </v-row>
      <NotificationCard
        v-for="(d, index) in data"
        :key="index"
        :data="d"
        :notificationPosition="notificationPosition"
        :onHandleClick="() => onClickCard(d)"
      />
      <v-divider class="mt-5 mb-5"></v-divider>
      <v-row align="center" justify="center">
        <v-btn
          class="table-button mb-2 red-btn"
          text
          plain
          @click="onClickAll()"
        >
          {{ $t("viewAll") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-menu>
</template>
<script>
/* 
  Name: NotificationList
  Description: Component with a notifications list
  API Property 
    menuIcon: String for icon of menu button
    menuPosition: String ("left") to open menu on left or right
    notificationPosition: String for notification position (left or right)
    headerTitle: String for the title
    data: Array for the data
    onHandleClick: Function on click handler
    onHandleAll: Function on click all

  Components Used:
    HeaderTitle
    NotificationCard
*/
import HeaderTitle from "../HeaderTitle/HeaderTitle.vue";
import NotificationCard from "./NotificationCard.vue";
export default {
  name: "NotificationList",
  components: {
    HeaderTitle,
    NotificationCard,
  },
  props: {
    menuIcon: String, // Icon of menu button
    menuPosition: { type: String, default: "left" }, // Open menu on left or right
    notificationPosition: { type: String, default: "right" }, // Notification position (left or right)
    headerTitle: String, // Title
    data: Array,
    onHandleClick: Function,
    onHandleAll: Function,
  },
  methods: {
    onClickCard(d) {
      this.onHandleClick(d);
    },
    onClickAll() {
      this.onHandleAll();
    },
  },
};
</script>
<style lang="scss" scoped>
.notifContainer {
  background-color: white;
  border-radius: 10px;
  min-width: 300px;
  max-width: 300px;
}
.red-btn {
  color: #e7002b !important;
}
</style>