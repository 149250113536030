<template>
  <v-col>
    <v-row>
      <v-col align="left">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("morePersonalInfo") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Birthday')">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="birthdayFormatted" :label="$t('dateOfBirth')" clearable color="primary"
              hide-details="auto" @blur="Birthday = parseDate(birthdayFormatted, 'Birthday')">
              <template v-slot:append>
                <v-icon v-bind="attrs" v-on="on"> mdi-calendar </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker locale="el" v-model="Birthday" scrollable @input="menu = false">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Nameday')">
        <!-- <v-menu ref="menu" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y -->
          <!-- min-width="auto"> -->
          <!-- <template v-slot:activator="{ on, attrs }"> {{  nameDayFormatted  }} {{ Nameday }} -->
            <v-text-field v-model="Nameday" :label="$t('nameDay')" clearable color="primary"
              hide-details="auto" >
              <!-- @blur="Nameday = parseDate(nameDayFormatted, 'Nameday')" -->
              <!-- <template v-slot:append>
                <v-icon v-bind="attrs" v-on="on"> mdi-calendar </v-icon>
              </template> -->
            </v-text-field>
          <!-- </template> -->
          <!-- <v-date-picker locale="el" v-model="Nameday" scrollable @input="menu1 = false"> -->
          <!-- </v-date-picker> -->
        <!-- </v-menu> -->
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('AFM')">
        <v-text-field v-model="AFM" :label="$t('vatNumber')" clearable dense color="primary" class="inputLabelCustom"
          hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('DOY')">
        <v-text-field v-model="DOY" :label="$t('publFinanceService')" clearable dense color="primary"
          class="inputLabelCustom" hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('personalId')">
        <v-text-field v-model="personalId" :label="$t('idPassp')" clearable dense color="primary"
          class="inputLabelCustom" hide-details="auto">
        </v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" v-if="showAttribute('chamberNo')">
        <v-text-field v-model="chamberNo" :label="$t('chamberNo')" clearable dense color="primary"
          class="inputLabelCustom" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('otherField')">
        <v-text-field v-model="otherField" :label="$t('otherField')" clearable dense color="primary"
          class="inputLabelCustom" hide-details="auto">
        </v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:PersonalInfo
 Description: Component to render personal info fields for contact

 API Property:
    attributes: Object with the data 
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the info type like "addressInfo"
*/
const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  name: "PersonalInfo",
  props: {
    attributes: Object,
    updateAttribute: Function,
    validation: Object,
    attributeModel: Array,
    kind: { type: String, default: "personalInfo" },
  },
  data() {
    return {
      menu: false,
      menu1: false,
      birthdayFormatted: null,
      // nameDayFormatted: null,
    };
  },
  created() {

    this.birthdayFormatted = this.formatDate(this.attributes.Birthday);
    // this.nameDayFormatted = this.formatDate(this.attributes.Nameday);
  },
  computed: {
    Birthday: {
      get() {
        return this.attributes.Birthday;
      },
      set(value) {
        this.birthdayFormatted = this.formatDate(value);
        this.updateAttribute(this.kind, "Birthday", value);
      },
    },
    Nameday: {
      get() {
        return this.attributes.Nameday;
      },
      set(value) {
        // this.nameDayFormatted = this.formatDate(value);
        this.updateAttribute(this.kind, "Nameday", value);
      },
    },
    AFM: {
      get() {
        return this.attributes.AFM;
      },
      set(value) {
        this.updateAttribute(this.kind, "AFM", value);
      },
    },
    DOY: {
      get() {
        return this.attributes.DOY;
      },
      set(value) {
        this.updateAttribute(this.kind, "DOY", value);
      },
    },
    personalId: {
      get() {
        return this.attributes.personalId;
      },
      set(value) {
        this.updateAttribute(this.kind, "personalId", value);
      },
    },
    otherField: {
      get() {
        return this.attributes.otherField;
      },
      set(value) {
        this.updateAttribute(this.kind, "otherField", value);
      },
    },
    chamberNo: {
      get() {
        return this.attributes.chamberNo;
      },
      set(value) {
        this.updateAttribute(this.kind, "chamberNo", value);
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      date = date.split(' ')[0].replaceAll('/','-');
      if (date.split('-').length != 3) return null
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "Birthday") {
          this.Birthday = null;
          this.birthdayFormatted = null;
        } 
        // else if (dateName == "Nameday") {
        //   this.Nameday = null;
        //   this.nameDayFormatted = null;
        // }
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    showAttribute(attribute) {
      return this.attributeModel.includes(attribute);
    }
  },
};
</script>
