import i18n from "../../i18n/i18n";

export default {
  headers: [
    {
      text: "",
      align: "start",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "MainPhoto",
    },
    {
      text: "propertyType",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Type",
    },
    {
      text: "regions",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Location",
    },
    {
      text: "sqftTable",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Unit",
    },
    {
      text: "availableForTable",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Task",
    },
    {
      text: "propertyCode",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "EstateCode",
    },
    {
      text: "kind",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Assignation",
    },
    {
      text: "status",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Status",
    },
    {
      text: "priceTable",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Price",
    },
    {
      text: "choices",
      align: "start",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "actions",
    },
  ],
  templates: [
    { column: "MainPhoto", dataObject: "MainPhoto", elementType: "img" },
  ],
  headersMatching: [
    {
      text: "interestedParty",
      align: "start",
      sortable: false,
      sort: "",
      value: "ClientFullName",
    },
    {
      text: "correspondence",
      align: "center",
      sortable: false,
      sort: "",
      value: "rate",
      width: '210px'
    },
    {
      text: "Agent",
      align: "center",
      sortable: false,
      sort: "",
      value: "AgentFullName",
      width: '305px'
    },
    {
      text: "PriceUpTo",
      align: "start",
      sortable: false,
      sort: "",
      value: "Price",
      width: '210px'
    },
    {
      text: "choices",
      align: "center",
      sortable: false,
      sort: "",
      value: "actions",
      width: '30px'
    },
  ],

  headersShowcases: [
    {
      text: "interested",
      align: "center",
      sortable: false,
      sort: "",
      value: "AgentFullName",
    },
    {
      text: "condition",
      align: "center",
      sortable: false,
      sort: "",
      value: "Status",
    },
    {
      text: "dateΟfAppointment",
      align: "center",
      sortable: false,
      sort: "",
      value: "AssignationStart",
    },
    {
      text: "offer",
      align: "center",
      sortable: false,
      sort: "",
      value: "Offer",
    },
    {
      text: "evaluation",
      align: "center",
      sortable: false,
      sort: "",
      value: "Evaluation",
    },
    {
      text: "choices",
      align: "center",
      sortable: false,
      sort: "",
      value: "Choices",
    },
  ],
  templatesMatching: [
    {
      column: "rate",
      dataObject: "rate",
      elementType: "progress",
      colorBar: "colorBar",
    },
    {
      column: "AgentFullName",
      dataObject: "AgentPhoto",
      content: "AgentFullName",
      subContent: "Company",
      elementType: "avatar",
      rowClass: "justify-start matchingAvatarClass flex-nowrap"
    },
  ],
  headersOffers: [
    {
      text: "offerFrom",
      align: "start",
      sortable: false,
      sort: "",
      value: "contact_name",
    },
    {
      text: "offerPrice",
      align: "start",
      sortable: false,
      sort: "",
      value: "price",
    },
    {
      text: "offerDate",
      align: "start",
      sortable: false,
      sort: "",
      value: "timestamp",
    },
    {
      text: "counterOfferPrice",
      align: "start",
      sortable: false,
      sort: "",
      value: "counter_offer_offer_type",
    },
    {
      text: "counterOfferDate",
      align: "start",
      sortable: false,
      sort: "",
      value: "counter_offer_timestamp",
    },
    {
      text: "choices",
      align: "start",
      sortable: false,
      sort: "",
      value: "actions",
    },
  ],
  templateOffers: [
    {
      column: "contact_name",
      dataObject: "contact_photo",
      content: "contact_name",
      elementType: "avatar",
    },
  ],
  dataOffers: [
    {
      client: "Niki Altani",
      offerPrice: 100000,
      offerDate: "19/03/2022",
      reofferPrice: 110000,
      reofferDate: "27/03/2022",
      isImg: false,
      dataImg: "N.A",
    },
  ],
  headersCooperations: [
    {
      text: "partnerOffice",
      align: "start",
      sortable: false,
      sort: "",
      value: "AgentName",
    },
    {
      text: "condition",
      align: "start",
      sortable: false,
      sort: "",
      value: "status",
    },
    {
      text: "requestDate",
      align: "start",
      sortable: false,
      sort: "",
      value: "RegistrationDate",
    },
    {
      text: "acceptanceDate",
      align: "start",
      sortable: false,
      sort: "",
      value: "acceptDate",
    },
    {
      text: "expirationDate2",
      align: "start",
      sortable: false,
      sort: "",
      value: "endDate",
    },
    // {
    //   text: "choices",
    //   align: "start",
    //   sortable: false,
    //   sort: "",
    //   value: "actions",
    // },
  ],
  templateCooperations: [
    {
      column: "AgentName",
      dataObject: "AgentPhoto",
      content: "AgentName",
      elementType: "avatar",
      ratingData: "rating",
      subContent: "AgentCompany",
      isImg: "isImg",
    },
    {
      column: "status",
      dataObject: "status",
      elementType: "textBox",
    },
  ],
  dataCooperations: [
    {
      agent: "Μάρκος Αποστόλου",
      status: "ΕΝΕΡΓΗ",
      requestDate: "19/4/2021",
      acceptDate: "",
      endDate: "",
      agentPhoto: "N.A",
      rating: 4.5,
      isImg: false,
      company: "New Deal",
    },
  ],
  ratingTitles: [
    "interestInTheProperty",
    "satisfactionWithThePrice",
    "satisfactionWithTheOutdoorSpace",
    "satisfactionWithTheInteriorSpace",
  ],
  docTypesList: [
    {
      name: "allFiles",
      id: "all",
      subCats: [],
    },
    {
      name: "legalTasks",
      id: "legal_tasks",
      hoverText: "privateClass",
      subCats: [
        {
          name: "legalTasks",
          id: "legal_tasks",
        },
        {
          name: "showcaseTaskDocuments",
          id: "showcase_task_documents",
        },
      ],
    },
    {
      name: "estateDocs",
      id: "estate_docs",
      hoverText: "privateClass",
      subCats: [
        {
          name: "layout",
          id: "layout",
        },
        {
          name: "titles",
          id: "titles",
        },
        {
          name: "privateAgreement",
          id: "private_agreement",
        },
        {
          name: "sellerTaxAwarenessDocument",
          id: "seller_tax_awareness_document",
          hoverText: "sellerTaxAwarenessDocumentHover",
        },
        {
          name: "efkaSellerInsuranceAwareness",
          id: "efka_seller_insurance_awareness",
          hoverText: "efkaSellerInsuranceAwarenessHover",
        },
        {
          name: "enfiaCertificates",
          id: "enfia_certificate",
        },
        {
          name: "engineerCertificate",
          id: "engineers_certificate",
        },
        {
          name: "energyClassCertificate",
          id: "energy_class_certificate",
        },
        {
          name: "propertyFee",
          id: "property_fee",
          hoverText:
            "propertyFeeHover",
        },
        {
          name: "topographic",
          id: "topographic_document",
        },
        {
          name: "personalDocuments",
          id: "personal_documents",
          hoverText: "personalDocumentsHover",
        },
      ],
    },
    {
      name: "marketing",
      id: "marketing",
      subCats: [
        { name: "leaflets", id: "marketing" },
        { name: "presentations", id: "presentations" },
      ],
    },
  ],

  providers: [
    {
      title: "newdeal.gr",
      id: "newdeal.gr",
      src: require("@/assets/realEstateSilder/newdeal.png"),
      coins: null,
      status: null,
    },
    {
      title: "spitogatos",
      id: "Spitogatos.gr",
      src: require("@/assets/realEstateSilder/spitogatos.png"),
      coins: null,
      status: null,
    },
    {
      title: "Χρυσή ευκαιρία",
      id: "ΧΕ",
      src: require("@/assets/realEstateSilder/xe.png"),
      coins: null,
      status: null,
    },
    // {
    //   title: "spiti360",
    //   id: "spiti360",
    //   src: require("@/assets/realEstateSilder/spiti360.png"),
    //   coins: null,
    //   status: null,
    // },
  ],

  editData: {
    category: null,
    task: null,
    distanceRadioFields: [
      { label: i18n.t("meters2"), value: 0 },
      { label: i18n.t("km2"), value: 1 },
    ],
    yesOrNoFields: [
      {
        label: i18n.t("no"),
        value: 0,
      },
      {
        label: i18n.t("yes"),
        value: 1,
      },
    ],
    securityFields: [
      {
        label: "toMeOnly",
        value: 0,
      },
      {
        label: "office",
        value: 1,
      },
      {
        label: "network",
        value: 2,
      },
      {
        label: "public",
        value: 3,
      },
    ],
    owner: null,
    NotaryID: null,
    AttorneyID: null,
    agent: null,
    unit: null,
    id: null,
    propertyCategories: null,
    type: null,
    buildingStatusSelected: null,
    objectivePrice: null,
    esign: null,
    esignCheckbox: false,
    orderExpire: null,
    orderStart: null,
    companyName: null,
    propertySubCategories: null,
    available: null,
    levels: 0,
    bathrooms: 0,
    bedrooms: 0,
    balconies: 0,
    numberOfPlaces: 0,
    livingRooms: 0,
    wc: 0,
    plot_unit: null,
    commandType: null,
    balconies_in_sqm: null,
    parking: false,
    parking_spots: null,
    storage_room: false,
    storage_room_sqm: null,
    attic: false,
    attic_in_sqm: null,
    service_room: false,
    service_room_in_sqm: null,
    autonomous_heating: false,
    solar_water_heater: false,
    air_conditioning: false,
    fireplace: false,
    gas: false,
    stove: false,
    central_heating: false,
    under_floor_heating: false,
    natural_gas: false,
    oil: false,
    electrical_heating: false,
    heat_accumulator: false,
    owned_terrace: false,
    owned_terrace_in_sqm: null,
    garden_in_sqm: null,
    garden: false,
    playroom: false,
    playroom_in_sqm: null,
    pool: false,
    poolSqft: null,
    personalComments: null,
    kitchens: 0,
    item: null,
    monthly_rent_price: null,
    deposit_amount: null,
    yearly_tax: null,
    yearly_yield: null,
    yearly_income_before_tax: null,
    bank_name: null,
    underConstraction: undefined,
    buildYear: null,
    renovateYear: null,
    floor: null,
    floorSelected: [],
    selectBoxesSelected: [],
    orientationSelected: [],
    energy_class: null,
    suitable_for_investment: null,
    typeOfParkingSelected: [],
    amount_or_percentage: null,
    shared_fees_monthly_price: null,
    selectPositionSelected: [],
    zoneSelected: [],
    estateCode: null,
    chooseAViewSelected: [],
    currentUse: null,
    kind: null,
    date_of_lease_expiration: null,
    date_of_lease_expiration_formatted: null,
    currentUseDateFrom: null,
    currentUseDateFromFormatted: null,
    payment_in_advance_date: null,
    payment_in_advance_date_formatted: null,
    payment_in_advance_amount: null,
    price: null,
    air: null,
    selectedSecurity: 0,
    negotiable_price: false,
    proUse: false,
    outOfPlan: false,
    storage: false,
    fromManufacturer: false,
    technicalFeaturesInteriorSelected: null,
    suitableForSelected: null,
    outdoorSpacesSelected: null,
    status: null,
    typeOfMandateSelected: null,
    securitySelected: null,
    propertyResponsiblePerson: null,
    inlandCommission: null,
    inlandCommissionNoTax: null,
    orderCode: null,
    outlandCommission: null,
    outlandCommissionNoTax: null,
    youtubeLink: null,
    selectedDistanceUnit: 0,
    mortgage: false,
    addressCheckbox: false,
    watermark: false,
    address: null,
    exactLocationCheckbox: false,
    gps: null,
    keys: 0,
    bannerAtTheEntrance: 0,
    pano: 0,
    bannerOnStreets: 0,
    activeLangTab: 0,
    greekDescription: "",
    englishDescription: "",
    arabicDescription: "",
    chinesseDescription: "",
    agentPrice: null,
    furnished: null,
    luxurious: null,
    screens: null,
    bright: null,
    painted: null,
    electrical_equipment: null,
    satellite_antenna: null,
    pets_allowed: null,
    internal_staircase: null,
    elevator: null,
    safety_door: null,
    alarm: null,
    security_cam: null,
    cctv: null,
    security_systems: null,
    veranda: false,
    access_for_disabled_people: false,
    awnings: false,
    bbq: false,
    separate_entrance: false,
    suitable_for_students: false,
    airbnb: false,
    suitable_for_commercial_use: false,
    distance_from_sea: null,
    distance_from_airport: null,
    distance_from_acropolis: null,
    distance_from_bank: null,
    distance_from_schools: null,
    distance_from_super_market: null,
    distance_from_metro: null,
    distance_from_commuting_stops: null,
    distance_from_train: null,
    distance_from_square: null,
    distance_from_park: null,
    distance_from_hospital: null,
    distance_from_suburban: null,
    distance_from_tram: null,
    pool_in_sqm: null,
    region: null,
    state: null,
    district: null,
    borough: null,
    distance_from_international_schools: false,
  },
};
