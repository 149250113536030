var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-container',[_c('v-row',[_c('draggable',{attrs:{"draggable":".item:not(.exclude-this-item)","scroll-sensitivity":200,"force-fallback":true,"options":{ disabled: _vm.disable },"tag":"v-row"},on:{"start":_vm.startDrag,"end":_vm.endDrag},model:{value:(_vm.imageArray),callback:function ($$v) {_vm.imageArray=$$v},expression:"imageArray"}},[_vm._l((_vm.imageArray),function(item,index){return _c('v-col',{key:item.id,staticClass:"noFlexGrow ma-2 HW item"},[_c('DraggableCard',{class:{ grabbing: _vm.dragging, grab: !_vm.dragging },attrs:{"disable":_vm.disable,"item":item,"index":index,"itemImageNameObj":item[_vm.itemImageObjName]
                ? item[_vm.itemImageObjName]
                : { itemImageTitleProp: '' },"itemImageTitleProp":_vm.itemImageTitleProp,"itemCheckBoxPropName":_vm.itemCheckBoxPropName,"itemCheckBoxDescr":_vm.itemCheckBoxDescr,"itemCheckBoxFalseVal":_vm.itemCheckBoxFalseVal,"itemCheckBoxTrueVal":_vm.itemCheckBoxTrueVal,"topLeftLabel":_vm.topLeftLabel,"supportTopLeftCircle":_vm.supportTopLeftCircle,"supportEdit":_vm.supportEdit,"supportDelete":_vm.supportDelete,"innerClass":_vm.innerClass,"editObjHandler":_vm.editObj,"deleteObjHandler":_vm.deleteObj,"updateObjHandler":_vm.updateObj}})],1)}),(_vm.uploadLoading || _vm.deleteLoading)?_c('v-col',{staticClass:"noFlexGrow ma-2 uploadCard item exclude-this-item"},[_c('v-card',{staticClass:"noShadow"},[_c('v-img',{staticClass:"white--text d-flex align-end",attrs:{"src":_vm.deleteLoading
                ? require('@/assets/draggable/deletingImage.png')
                : require('@/assets/draggable/uploadingImage.png'),"height":"234","width":"250"}},[_c('v-progress-linear',{attrs:{"color":"red accent-4","indeterminate":"","rounded":"","height":"6"}})],1)],1)],1):_vm._e(),(_vm.supportUpload && !_vm.disable)?_c('v-col',{staticClass:"noFlexGrow ma-2 uploadCard item exclude-this-item",on:{"click":function($event){return _vm.openDialog('upload')}}},[_c('DraggableCard',{attrs:{"cardClass":"noShadow","item":{
            path: require('@/assets/draggable/addImageCard.png'),
            absolutePath: true,
          },"supportDelete":false,"supportEdit":false,"supportTopLeftCircle":false}})],1):_vm._e()],2)],1),(_vm.dialog.type == 'upload')?_c('v-dialog',{staticClass:"pa-6",model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[_c('Upload',{staticClass:"pa-6",attrs:{"onlyImgRestrict":true,"supportCheckBox":true,"itemCheckBoxPropName":_vm.itemCheckBoxPropName,"itemCheckBoxDescr":_vm.itemCheckBoxDescr,"itemCheckBoxFalseVal":_vm.itemCheckBoxFalseVal,"itemCheckBoxTrueVal":_vm.itemCheckBoxTrueVal,"supportTxt":true,"itemTxtPropName":_vm.uploadImagePropName,"itemTxtDescr":_vm.$t('photoDescr'),"itemTxtMaxLength":30,"closeHandler":_vm.closeDialog,"submitHandler":_vm.uploadImages}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }