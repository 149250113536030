<template>
  <v-card>
    <v-row>
      <v-col cols="12" xl="9" lg="9" md="9" sm="7" xs="6" class="pr-0">
        <CarouselField
          :data="[data.image]"
          :carouselStyle="carouselStyle"
          :fullScreen="true"
        />
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="5" xs="6" class="mt-4">
        <v-row>
          <v-col class="pr-0">
            <HeaderTitle
              fontFamilyTitle="CenturyGothicBold"
              :subTitle="$t('editPhoto')"
            />
          </v-col>
          <v-col class="flex-grow-0 pr-4 mr-4 pl-0">
            <v-icon @click="close()" class="primaryColorOnHover xClass">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pr-4 mr-3 pb-0 pt-0">
            <v-textarea
              v-model="titleGr"
              :label="$t('photoDescrGr')"
              auto-grow
              class="mt-4"
              :placeholder="$t('photoDescrPlaceHolder')"
              persistent-placeholder
              outlined
              rows="8"
              row-height="15"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pr-4 mr-3 pt-0 pb-0">
            <v-textarea
              v-model="titleEn"
              :label="$t('photoDescrEn')"
              auto-grow
              class="mt-4"
              :placeholder="$t('photoDescrPlaceHolder')"
              persistent-placeholder
              outlined
              rows="8"
              row-height="15"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pr-4 mr-3 pt-0 pb-0">
            <v-checkbox
              class="pt-0 pb-0 ma-0"
              :label="$t('public')"
              v-model="lPublic"
              false-value="Private"
              true-value="Public"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pr-4 mr-3 pt-0 pb-0">
            <v-btn
              elevation="5"
              align="center"
              @click="onSubmit"
              style="
                text-transform: unset !important;
                width: 100%;
                height: 40px;
              "
              class="ma-1"
              color="error"
            >
              {{ $t("save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
 /*
 Name: EditImage
 Description: Component for the image edit

 API Property: 
    data: Object for the data
    index: Number for the image index
    submitHandler: Function on submit
    closeHandler: Function for the close handler

    Components Used:
      CarouselField
      HeaderTitle
*/
import CarouselField from "@/components/Fields/CarouselField/CarouselField.vue";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
export default {
  props: {
    data: Object,
    index: Number,
    submitHandler: Function,
    closeHandler: Function,
  },
  components: {
    CarouselField,
    HeaderTitle,
  },
  data() {
    return {
      titleGr: "",
      titleEn: "",
      lPublic: "Public",
      carouselStyle:
        "padding-top: clamp(20px, 10%, 60px) !important;" +
        "padding-left: clamp(60px, 10%, 180px) !important;" +
        "padding-right: clamp(60px, 10%, 180px) !important;" +
        "padding-bottom: clamp(60px, 10%, 180px) !important;",
    };
  },
  created() {
    this.titleGr =
      this.data.image.title && this.data.image.title.gr
        ? this.data.image.title.gr
        : "";
    this.titleEn =
      this.data.image.title && this.data.image.title.en
        ? this.data.image.title.en
        : "";
    this.lPublic = this.data.image.public;
  },
  methods: {
    onSubmit() {
      let object = {};
      object = this.data.image;
      object.title = { gr: this.titleGr, en: this.titleEn };
      object.public = this.lPublic;
      this.submitHandler(object, this.index);
      this.closeHandler();
    },
    close() {
      this.closeHandler();
    },
  },
  computed: {},
};
</script>

<style scoped>
</style>
