 <template>
  <v-col class="pl-0 pr-0 pt-0 pb-0">
    <v-row class="justify-space-around flexColMedia">
      <v-col>
        <gmap-map class="map" :options="{
          styles: style,
          mapTypeControl: false,
          scaleControl: true,
          scrollwheel: true,
          navigationControl: false,
          streetViewControl: true,
          fullscreenControl: true,
        }" :zoom="zoomComp" :center="center" :style="`height: ${this.mapHeight}; width: ${this.mapWidth};` + mapStyle"
          @bounds_changed="setCoordinates" @zoom_changed="searchByCoords" @dragend="searchByCoords">
          <GmapCluster :zoom-on-click="true" :maxZoom="16" :averageCenter="true">
            <gmap-marker :key="index" v-for="(m, index) in filteredLocationMarkers" :position="m.position"
              :icon="markerIcon(m.icon, m.type)" @click="center = mClicked(m)">
            </gmap-marker>
          </GmapCluster>
        </gmap-map>
      </v-col>
      <v-col v-if="showDetails && oneMarker" class="align-self-top mt-3 ml-5" :xs="12" :sm="12" :md="6" :lg="4" :xl="4">
        <v-row v-if="isMedia">
          <v-col align="left">
            <HeaderTitle :title="$t('theLocationOfTheProperty')" />
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-4" v-if="
          ![undefined, '', null].includes(
            filteredLocationMarkers[0].data.Country
          )
        ">
          <v-col align="left">
            <v-row class="listHeader"> {{ $t("country") }} </v-row>
            <v-row class="listContent">
              {{ filteredLocationMarkers[0].data.Country }}
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-4" v-if="![undefined, '', null].includes(filteredLocationMarkers[0].data.AreaSP)">
          <v-col align="left">
            <v-row class="listHeader"> {{ $t("region") }} </v-row>
            <v-row class="listContent">
              {{ filteredLocationMarkers[0].data.AreaSP }}
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-4" v-if="![undefined, '', null].includes(filteredLocationMarkers[0].data.Area)">
          <v-col align="left">
            <v-row class="listHeader"> {{ $t("city") }} </v-row>
            <v-row class="listContent">
              {{ filteredLocationMarkers[0].data.Area }}
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-4" v-if="![undefined, '', null].includes(filteredLocationMarkers[0].data.Address)">
          <v-col align="left">
            <v-row class="listHeader"> {{ $t("addressNumber") }} </v-row>
            <v-row class="listContent">
              {{ filteredLocationMarkers[0].data.Address }}
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-4" v-if="![undefined, '', null].includes(filteredLocationMarkers[0].data.PostalCode)">
          <v-col align="left">
            <v-row class="listHeader text-uppercase">
              {{ $t("postalCode") }}
            </v-row>
            <v-row class="listContent">
              {{ filteredLocationMarkers[0].data.PostalCode }}
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template> 

 <script>
 /* 
  Name:Map
  Description: Component with google map ui and markers on the map
  API Property 
    locationMarkers: Array of locationmarkers
    showDetails: Boolean for showing details or not
    isMedia: Boolean for showing the details as media or as normal
    showNIS: Boolean showing if international schools are showed or not
    markerClicked: Function on marker clicked
    zoom: Zoom of the map
    mapCenter: Object for the map center
    updateCenterHandler: Function to update center
    mapHeight: String for the height of the map
    mapWidth: String for the width of the map
    mapStyle: String for map style
    textFillColor: String for the text fill color
    textStrokeColor: String for the text stroke color
    showIcons: : String ("off") for the show icons
    landScapeColor: String for the land scape color
    roadColors: String for the road colors
    searchByCoordinates: Boolean for the search by coordinates
    fetchDataHandler: Function for fetch data

  Components Used:
    GmapCluster
    HeaderTitle
*/
import { mapActions, mapState } from "vuex";
import MapHelpers from "../../helpers/Core/mapHelper";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.vue";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
export default {
  name: "Map",
  components: { GmapCluster, HeaderTitle },
  props: {
    locationMarkers: Array, // Array of locationmarkers
    showDetails: { type: Boolean, default: false }, // Boolean for showing details or not
    isMedia: { type: Boolean, default: false }, // Boolean for showing the details as media or as normal
    showNIS: { type: Boolean, default: false }, // Boolean showing if international schools are showed or not
    markerClicked: Function, //Function on marker clicked
    zoom: { type: Number, default: 14 }, //Zoom of the map
    mapCenter: Object,
    updateCenterHandler: Function,
    mapHeight: { type: String, default: "381px" }, //Height of the map
    mapWidth: { type: String, default: "729px" }, //Width of the map
    mapStyle: String,
    textFillColor: {
      type: String,
      default: "#FFFFFF",
    },
    textStrokeColor: {
      type: String,
      default: "#000000",
    },
    showIcons: {
      type: String,
      default: "off",
    },
    landScapeColor: {
      type: String,
      default: "#29446b",
    },
    roadColors: {
      type: String,
      default: "#193a55",
    },
    searchByCoordinates: { type: Boolean, default: false },
    fetchDataHandler: Function,
  },
  methods: {
    ...mapActions(["onSetGoogleCoordinates"]),
    /* markerIcon, method that returns the marker of the map
        If the location is type school then by default it returns school-marker-icon.png
        If the location is not type school then it checks the path send as var, if path is empty then by default the marker is marker-a.png
     */
    markerIcon(path, type) {
      let defaultPath =
        type == "school" ? { url: require("@/assets/maps/school-marker-icon.png") }
          : type == "Πώληση" ? { url: require("@/assets/maps/marker-b.png") }
            : type == "Επωλήθη" ? { url: require("@/assets/maps/marker-c.png") }
              : type == "Company" ? { url: require("@/assets/maps/marker-nd.png") }
                : { url: require("@/assets/maps/marker-a.png") };
      return type == "school" ? defaultPath
        : path == "" ? defaultPath
          : path == undefined ? defaultPath
            : path;
    },
    setCoordinates(newCoordinates) {
      if (this.searchByCoordinates == true) this.googleCoords = newCoordinates;
    },
    searchByCoords() {
      try {
        if (
          [null, undefined, ""].includes(this.realEstateFilter.locationGoogle)
          && ([null, undefined, ""].includes(this.realEstateFilter.searchNewDeal) || this.realEstateFilter.searchNewDeal.length == 0)
          && ![null, undefined, ""].includes(this.realEstateFilter.googleCoordinates)
        )
          this.fetchDataHandler();
      } catch {
        true;
      }
    },
    mClicked(marker) {
      try {
        this.markerClicked(marker);
      } catch {
        null;
      }
      return marker.position;
    },
  },
  computed: {
    ...mapState({
      realEstateFilter: (state) => state.realEstate,
    }),
    googleCoords: {
      get() {
        return this.realEstateFilter.googleCoordinates;
      },
      set(val) {
        this.onSetGoogleCoordinates(val);
      },
    },
    /* filteredLocationMarkers filters the locationMarkers array 
       and removes and locations that has null or undefined values in lat and long
    */
    filteredLocationMarkers() {
      return MapHelpers.filterMarkers(this.locationMarkers);
    },
    /* oneMarker returns true if the locationMarker length is 1 
       or if show international schools is true
    */
    oneMarker() {
      return (
        1 == MapHelpers.filterMarkers(this.locationMarkers).length ||
        this.showNIS
      );
    },
    zoomComp: {
      get() {
        return this.oneMarker || this.showNIS ? this.zoom : 7;
      },
      set(val) {
        return val;
      },
    },
    center: {
      get() {
        return MapHelpers.getCenter(this.locationMarkers, this.mapCenter);
      },
      set(val) {
        try {
          this.updateCenterHandler(val);
        } catch {
          null;
        }
      },
    },
    colSize() {
      if (this.isMedia) {
        return 4;
      } else return null;
    },
    style() {
      return MapHelpers.getStyles(
        this.textFillColor,
        this.textStrokeColor,
        this.showIcons,
        this.landScapeColor,
        this.roadColors
      );
    },
  },
};
</script> 
 <style lang="scss" scoped>
 .listHeader {
   color: #263573;
   font-size: 14px;
 }
 
 .listContent {
   font-size: 14px;
 }
 
 @media screen and (max-width: 600px) {
   .flexColMedia {
     flex-direction: column;
   }
 }
 </style> 
