import AuthRepo from "./Auth/auth";
import RealEstateRepo from "./RealEstate/realEstate";
import ContactRepo from "./Contacts/contacts";
import CompaniesRepo from "./Companies/companies";
import RequestsCoopRepo from "./Requests/cooperations";
import RequestsDemandRepo from "./Requests/demand"
import ProvidersRepo from "./Providers/providers"

const repositories = {
  auth: AuthRepo,
  realEstate: RealEstateRepo,
  contacts: ContactRepo,
  companies: CompaniesRepo,
  reqCooperation: RequestsCoopRepo,
  reqDemand: RequestsDemandRepo,
  reqProviders: ProvidersRepo,
};

export default {
  get: (name) => repositories[name],
};
