<template>
  <v-card
    :min-width="widthCard"
    :min-height="heightCard"
    :width="widthCard"
    :height="heightCard"
    :elevation="0"
    class="pt-6 cursorPointer"
    color="transparent"
  >
    <v-row class="ma-0">
      <v-col class="pa-0 flex-grow-0">
        <v-card class="rounded-sm pa-0 ma-0" :elevation="0">
          <v-img
            :max-height="imgHeight"
            :max-width="imgWidth"
            :height="imgHeight"
            :width="imgWidth"
            :src="tempImage"
            :lazy-src="tempImage"
            class="rounded-md"
          >
            <v-row class="h70">
              <v-img
                v-if="vip == true"
                :src="require('@/assets/' + topLeftLabel)"
                class="topLeftLabel"
                :contain="true"
              >
                &nbsp;
              </v-img>
            </v-row>
          </v-img>
        </v-card>
      </v-col>

      <v-col class="pl-2 pt-2 flex-grow-0">
        <v-row class="ma-0">
          <v-col class="pa-0 text-no-wrap flex-grow-0" align="left">
            <v-tooltip
              bottom
              :disabled="txtLength(title != undefined ? title : '') < 22"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="font16 pb-0 primaryColor textDecorationBold"
                  align="left"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{
                    textOverFlow(
                      ![undefined, "", null].includes(title) ? title : "--",
                      22
                    )
                  }}
                </div>
              </template>
              <span
                >{{ ![undefined, "", null].includes(title) ? title : "--" }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="pa-0 text-no-wrap flex-grow-0" align="left">
            <v-tooltip
              bottom
              :disabled="txtLength(subTitle != undefined ? subTitle : '') < 22"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="font14 pb-0 textDecorationBold"
                  align="left"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{
                    textOverFlow(
                      ![undefined, "", null].includes(subTitle)
                        ? subTitle
                        : "--",
                      22
                    )
                  }}
                </div>
              </template>
              <span
                >{{
                  ![undefined, "", null].includes(subTitle) ? subTitle : "--"
                }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col class="pa-0 text-no-wrap flex-grow-0" align="left">
            <v-tooltip
              bottom
              :disabled="
                txtLength(description != undefined ? description : '') < 22
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="font14 pb-0" align="left" v-bind="attrs" v-on="on">
                  {{
                    textOverFlow(
                      ![undefined, "", null].includes(description)
                        ? description
                        : "--",
                      22
                    )
                  }}
                </div>
              </template>
              <span
                >{{
                  ![undefined, "", null].includes(description)
                    ? description
                    : "--"
                }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/*
 Name:EstateInfo
 Description: Component to render card with a image, title, subtitle and a description under the subtitle.

 API Property:
 widthCard: Width of card component
 heightCard: Height of card component
 image: String: Image of card
 imgWidth: Image width
 imgHeight: Image height
 title: Title to describe the image
 subTitle: Subtitle to describe the image
 description: Description under the subtitle to describe the image
*/

import CoreHelper from "@/helpers/Core/core.js";

export default {
  name: "EstateInfo",
  props: {
    widthCard: { type: Number, default: 340 }, // Width of card component
    heightCard: { type: Number, default: 130 }, // Height of card component
    image: String, // Image of card
    imgWidth: { type: Number, default: 116 }, // Image width
    imgHeight: { type: Number, default: 85 }, // Image height
    title: { type: String, default: "" }, // Title to describe the image
    subTitle: { type: String, default: "" }, // Subtitle to describe the image
    description: { type: String, default: "" }, // Description under the subtitle to describe the image
    vip: { type: Boolean, default: false }, // If it is vip then a image will appear on the top left
  },
  data() {
    return {
      topLeftLabel: "realEstateSilder/vipLogo.png",
    };
  },

  computed: {
    tempImage() {
      return CoreHelper.getImagePath({ path: this.image });
    },
  },
  methods: {
    txtLength(txt) {
      try {
        return txt.length;
      } catch {
        return 0;
      }
    },
    textOverFlow(text, length) {
      try {
        if (text.length <= length) return text;
        else return text.substr(0, length) + " ..";
      } catch {
        return text;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.h70 {
  height: 70%;
}
.topLeftLabel {
  flex: unset;
  position: absolute;
  display: block !important;
  margin-left: 10px;
  height: 40px;
  width: 40px;
}
.cursorPointer {
  cursor: pointer;
}
</style>
