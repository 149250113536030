<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="pl-0 listHeader fontOrange">
          <span>{{ $t("priceHistory") }}</span>
        </v-col>
      </v-row>
      <v-row style="overflow:auto">
        <v-col>
          <v-row class="flex-nowrap">
            <v-col class="listHeader">
              <span> {{ $t("date") }} </span>
            </v-col>
            <v-col class="listHeader">
              <span> {{ $t("event") }} </span>
            </v-col>
            <v-col class="listHeader">
              <span> {{ $t("priceTable") }} </span>
            </v-col>
          </v-row>
          <v-row v-for="(item, i) in this.data.prices" :key="i" class="flex-nowrap">
            <v-col class="listContent">
              <span>
                {{ dateFormat("format", item.Date) }}
              </span>
            </v-col>
            <v-col class="listContent">
              <span>
                {{
                    item.change_literal == "initial_price"
                      ? $t("initialPrice")
                      : item.change_literal == "price_increase"
                        ? $t("priceIncrease")
                        : $t("priceReduction")
                }}
              </span>
            </v-col>
            <v-col class="listContent">
              <span>
                {{ item.Price != undefined ? item.Price + " €" : "" }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
 /*
 Name: HistoryPriceInfo
 Description: Component for real estate history price info at the slider

 API Property: 
    data: Array for the data
*/
import CoreHelper from "@/helpers/Core/core";
export default {
  name: "HistoryPriceInfo",
  props: ["data"],
  data() {
    return {
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
    };
  },
  methods: {
    dateFormat(type, date) {
      if (type == "format") {
        return CoreHelper.EpochToDate(date);
      } else {
        return CoreHelper.dateToEpoch(date);
      }
    },
  },
};
</script>

<style scoped>
.listHeader {
  color: #263573;
  font-size: 14px;
  text-align: start;
}

.fontOrange {
  color: #e89008;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}

.listContent {
  font-size: 14px;
  text-align: start;
  white-space: nowrap;
}
</style>