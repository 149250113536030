<template>
  <v-col class="text-center flex-grow-0" :cols="viewBreakpoint ? 5 : 2">
    <v-row class="mb-n6">
      <v-col align="center">
        <v-img
          :src="
            white
              ? require('@/assets/general/whiteLoading.gif')
              : require('@/assets/general/grayLoading.gif')
          "
          
          :height=" viewBreakpoint ? 92 : 200"
          width="200"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0 px-2" align="center">
        <v-progress-linear color="primary" indeterminate />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="textDecorationBold font20">
          Your virtual office is loading...
        </span>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name: LoaderGif
 Description: Component for loader with gif display

 API Property:
   white: Boolean if the gif will be white or not
*/
export default {
  name: "LoaderGif",
  props: {
    white: { type: Boolean, default: false },
  },
  computed: {
    viewBreakpoint() {
      return this.$vuetify.breakpoint.width < 1316;
    },
  },
};
</script>