<template>
  <v-navigation-drawer
    width="866px"
    style="height: 100%"
    fixed
    right
    v-model="show"
    app
    temporary
  >
    <v-col :class="mobileBreakpoint != true ? 'pl-12 pr-12' : ''">
      <v-row>
        <v-col align="right" class="pb-0">
          <v-icon @click="closeHandler" class="primaryColorOnHover xClass">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0">
          <v-row>
            <v-col>
              <HeaderTitle
                :title="$tc('realEstate', 1)"
                :subTitle="$t('createRealEstateSubHeader')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left">
              <v-row>
                <v-col class="pb-0">
                  <span class="text-capitalize">
                    {{ $t("availableForTable") + "*" }}
                  </span>
                  <span class="errorColor" style="font-size: 12px">
                    {{ taskErrors[0] }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn-toggle v-model="Task" flex mandatory>
                    <v-btn
                      v-for="button in availableForList"
                      :key="button.id"
                      class="ma-1 mr-3 btn recBtnWidth"
                      outlined
                    >
                      {{ $t(button.text) }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <span>
                    {{ $t("propertyCategories") + "*" }}
                  </span>
                  <span class="errorColor" style="font-size: 12px">
                    {{ categoryErrors[0] }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-1">
                  <v-btn-toggle v-model="Category" mandatory>
                    <v-btn
                      v-for="button in getCategoryList"
                      :key="button.id"
                      class="ma-1 mr-3 btn recBtn"
                      outlined
                    >
                      <v-col class="align-center">
                        <v-row>
                          <v-col align="center" class="pb-0">
                            <v-img
                              :src="
                                require('@/assets/CreateEditRealEstate/' +
                                  button.id +
                                  '.svg')
                              "
                              height="48"
                              width="48"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span> {{ $t(button.text) }} </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="8"
                  sm="12"
                  xs="12"
                  class="pt-0"
                >
                  <v-select
                    v-model="Type"
                    :items="getSubCategoryList()"
                    :label="$t('type') + '...'"
                    item-text="text"
                    item-value="val"
                    :error-messages="typeErrors"
                  >
                    <template v-slot:selection="{ item }">
                      <v-row class="flex-grow-0">
                        <v-col style="display: flex; align-items: center">
                          {{ $t(item.text) }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list>
                        <v-list-item-title>
                          <v-row>
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.text) }}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <span class="textDecorationBold" style="color: black">
                    {{ $t("entrOwnerDets") }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="pt-0"
                  v-if="!ownerCreated"
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <v-autocomplete
                    v-model="owner"
                    :label="$t('ownerProfile') + '...'"
                    :items="ownerList"
                    item-text="name"
                    item-value="_id"
                    return-object
                    :search-input.sync="search"
                    :loading="autocompleteLoader"
                    hide-no-data
                    clearable
                    flat
                    attach
                    hide-selected
                  />
                </v-col>
                <v-col v-else cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <v-text-field
                    v-model="owner.name"
                    :label="$t('ownerProfile') + '...'"
                    disabled
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  v-if="!ownerObject"
                >
                  <v-btn
                    class="ma-1 btn textDecorationBold"
                    style="height: 45px"
                    outlined
                    @click="() => (this.showContact = true)"
                  >
                    {{ $t("addNewOwner") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="ImportedFromEsign"
                    :label="$t('impOrderEsign')"
                    class="cusCheckBox"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  v-if="ImportedFromEsign"
                >
                  <v-text-field
                    v-model="esign"
                    :label="$t('findEsignCode')"
                    clearable
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  v-if="
                    [
                      'OfficeAdmin',
                      'Secretary',
                      'Admin',
                      'Leader',
                      'Attorney',
                    ].includes(userDetails.Role)
                  "
                >
                  <v-select
                    v-model="Company"
                    :items="companyList"
                    :label="$t('companyName2') + '*'"
                    item-value="name_id"
                    item-text="name"
                    :error-messages="companyErrors"
                  >
                    <template v-slot:selection="{ item }">
                      <v-row class="flex-grow-0">
                        <v-col style="display: flex; align-items: center">
                          {{ $t(item.name) }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list>
                        <v-list-item-title>
                          <v-row>
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.name) }}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="Assignation"
                    :items="getAssignationsList"
                    :label="$t('orderType') + '*'"
                    item-text="text"
                    item-value="val"
                    :error-messages="assignationErrors"
                  >
                    <template v-slot:selection="{ item }">
                      <v-row class="flex-grow-0">
                        <v-col style="display: flex; align-items: center">
                          {{ $t(item.text) }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list>
                        <v-list-item-title>
                          <v-row>
                            <v-col style="display: flex; align-items: center">
                              {{ $t(item.text) }}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  v-if="
                    [
                      'OfficeAdmin',
                      'Secretary',
                      'Admin',
                      'Leader',
                      'Attorney',
                    ].includes(userDetails.Role)
                  "
                  cols="12"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <v-autocomplete
                    v-model="agent"
                    :label="$t('responsiblePartner')"
                    :items="agentsList"
                    :filter="filterAgents"
                    item-value="_id"
                    item-text="fullname"
                    return-object
                    :search-input.sync="searchAgent"
                    :loading="autocompleteLoaderAgent"
                    :error-messages="agentErrors"
                    hide-no-data
                    flat
                    clearable
                    hide-selected
                    attach
                  >
                    <template v-slot:selection="{ item }">
                      <v-col class="flex-grow-0 text-no-wrap pa-0">
                        <span>
                          {{ item.LastName + " " + item.FirstName }}
                        </span>
                      </v-col>
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list>
                        <v-list-item-title>
                          <v-row>
                            <v-col>
                              <span>
                                {{
                                  data.item.LastName + " " + data.item.FirstName
                                }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        :label="$t('startOfOrder')"
                        v-model="AssignationStartFormatted"
                        prepend-inner-icon="mdi-calendar"
                        class="mb-4"
                        @blur="
                          AssignationStart = parseDate(
                            AssignationStartFormatted,
                            'AssignationStart'
                          )
                        "
                      >
                        <template v-slot:prepend-inner>
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-calendar
                          </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="el"
                      v-model="AssignationStart"
                      scrollable
                      @input="menu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="2"
                  xl="2"
                  lg="2"
                  md="2"
                  sm="2"
                  xs="2"
                  class="minusField d-flex justify-center align-start flex-grow-0 pt-5 pb-0"
                >
                  <v-icon> mdi-minus </v-icon>
                </v-col>
                <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        :label="$t('endOfOrder')"
                        v-model="AssignationEndFormatted"
                        prepend-inner-icon="mdi-calendar"
                        class="mb-4"
                        @blur="
                          AssignationEnd = parseDate(
                            AssignationEndFormatted,
                            'AssignationEnd'
                          )
                        "
                      >
                        <template v-slot:prepend-inner>
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-calendar
                          </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="el"
                      v-model="AssignationEnd"
                      scrollable
                      @input="menu1 = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="6">
          <v-btn
            elevation="5"
            @click="createRealEstate"
            align="center"
            class="btn textDecorationBold"
            color="error"
            :style="
              mobileBreakpoint != true
                ? 'max-width: 256px'
                : 'max-width: 180px; font-size:13px; '
            "
            :loading="loader"
          >
            {{ $t("createRealEstate") }}
          </v-btn>
        </v-col>
        <v-col
          class="align-self-center"
          cols="6"
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="6"
          :style="mobileBreakpoint != true ? '' : 'font-size:13px;'"
        >
          <a class="textDecorationBold errorColor" @click="clearFilters">
            {{ $t("clear") }}
          </a>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="showContact" max-width="690px" scrollable>
      <Contact
        parent="estate"
        :type="availableForList[this.Task].val"
        :subTitle="$t('ofOwner')"
        :searchOwner="initOwner"
        :closeHandler="this.closeContactModal"
      />
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
/*
 Name: CreateRealEstateCard
 Description: Component for the create estate slider
 API Property: 
    onCreate: Function on create
    ownerObject: Object for the owner data

    Components Used:
      HeaderTitle
      Contact
    
    Endpoints Functions Called:
    -ContactRepo
      getOwners
      get
      getAgents

    -RealEstateRepo
      create
    
    -CompaniesRepo
      get
*/
import { mapGetters, mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";

import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import Repository from "@/services/Repository";
import CoreHelper from "@/helpers/Core/core";
import i18n from "@/i18n/i18n";

import Contact from "@/components/Contacts/Contact.vue";

const ContactRepo = Repository.get("contacts");
const RealEstateRepo = Repository.get("realEstate");
const CompaniesRepo = Repository.get("companies");

export default {
  name: "NewRealEstate",
  components: {
    HeaderTitle,
    Contact,
  },
  props: {
    drawer: Boolean,
    onCreate: Function,
    ownerObject: Object,
  },
  async created() {
    if (this.ownerObject) {
      this.owner = this.ownerObject;
      this.ownerCreated = true;
    } else {
      await this.fetchOwnerList(null);
    }
    await this.fetchAgentsList(null);
    this.companyList = await this.fetchCompany();
  },
  data() {
    return {
      show: true,
      Category: 0,
      Type: null,
      esign: null,
      ImportedFromEsign: false,
      AssignationEnd: null,
      AssignationEndFormatted: null,
      AssignationStart: null,
      AssignationStartFormatted: null,
      Company: null,
      Task: 0,
      Assignation: null,
      menu: false,
      menu1: false,
      owner: null,
      ownerCreated: false,
      ownerList: [],
      agentsList: [],
      agent: null,
      search: null,
      searchAgent: null,
      autocompleteLoader: false,
      autocompleteLoaderAgent: false,
      loader: false,
      showContact: false,
      companyList: [],
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
    AssignationStart() {
      this.AssignationStartFormatted = CoreHelper.formatDate(
        this.AssignationStart
      );
    },
    AssignationEnd() {
      this.AssignationEndFormatted = CoreHelper.formatDate(this.AssignationEnd);
    },

    search(val) {
      val && val != this.owner && this.fetchOwnerList(val);
    },

    searchAgent(val) {
      val && val != this.agent && this.fetchAgentsList(val);
    },
  },
  validations() {
    const self = this;
    return {
      Task: { required },
      Category: { required },
      Type: { required },
      Company: {
        required: requiredIf(function () {
          return self.userDetails.Role != "Associate";
        }),
      },
      Assignation: { required },
      agent: {
        required: requiredIf(function () {
          return self.userDetails.Role != "Associate";
        }),
      },
    };
  },
  computed: {
    ...mapGetters([
      "availableForList",
      "realEstateCategoriesList",
      "getAssignationsList",
    ]),
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    taskErrors() {
      const errors = [];
      if (!this.$v.Task.$dirty) return errors;
      if (!this.$v.Task.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.Category.$dirty) return errors;
      if (!this.$v.Category.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.Type.$dirty) return errors;
      if (!this.$v.Type.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.Company.$dirty) return errors;
      if (!this.$v.Company.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    assignationErrors() {
      const errors = [];
      if (!this.$v.Assignation.$dirty) return errors;
      if (!this.$v.Assignation.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    agentErrors() {
      const errors = [];
      if (!this.$v.agent.$dirty) return errors;
      if (!this.$v.agent.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    getCategoryList() {
      return this.realEstateCategoriesList.filter(function (el, i) {
        return 0 !== i;
      });
    },
  },
  methods: {
    getSubCategoryList() {
      let subCatList = [];
      this.realEstateCategoriesList.forEach((element) => {
        if (this.Category + 1 == element.id) {
          subCatList.push.apply(subCatList, element.subCategoriesList);
        }
      });
      return subCatList;
    },
    initOwner(owner) {
      this.owner = { _id: owner._id, name: owner.fullname };
      this.ownerCreated = true;
    },
    async fetchOwnerList(val) {
      this.autocompleteLoader = true;
      let filters;
      if (this.userDetails.Role == "Admin") {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
        ];
      } else if (
        ["Secretary", "OfficeAdmin", "HR"].includes(this.userDetails.Role)
      ) {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [{ LastCompany: this.userDetails.Company }],
        ];
      } else {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [{ Creator: this.userDetails._id }],
        ];
      }
      try {
        if (val != null) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }

        let resp = await ContactRepo.getOwners(filters, { page: 0, items: 60 });
        this.ownerList = resp.data.owners.map((d) => {
          return {
            ...d,
            name: d.name
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
          };
        });
        this.autocompleteLoader = false;
      } catch (e) {
        this.autocompleteLoader = false;
        throw e;
      }

      if (![undefined, null, ""].includes(this.owner)) {
        this.ownerList.push(this.owner);
      }
    },
    async fetchCompany() {
      try {
        const resp = await CompaniesRepo.get({
          action: "get_companies",
        });
        return resp.data;
      } catch (err) {
        console.error(err, "Error");
      }
    },
    async fetchAgentsList(val) {
      let filters;
      if (this.userDetails.Role == "Admin") {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
        ];
      } else if (
        ["Secretary", "OfficeAdmin", "HR"].includes(this.userDetails.Role)
      ) {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [{ LastCompany: this.userDetails.Company }],
        ];
      } else if (this.userDetails.Role == "Leader") {
        let ids = [];
        if (this.userDetails.Team && this.userDetails.Team.length > 0)
          ids = [...this.userDetails.Team, this.userDetails._id];
        else ids = [this.userDetails._id];
        filters = {
          _id: { $in: ids },
        };
        if (val != null) {
          filters.LastName = {
            $regex: CoreHelper.toRegex(val.split(" ")[0]),
            $options: "i",
          };
        }
        let resp = await ContactRepo.get(filters, {
          page: 0,
          items: 60,
        });
        this.agentsList = resp.data.contacts.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        return false;
      } else {
        this.agentsList = [];
        return false;
      }
      try {
        if (val != null) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }
        let resp = await ContactRepo.getAgents(filters, { page: 0, items: 60 });
        this.agentsList = resp.data.agents.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        this.autocompleteLoaderAgent = false;
      } catch (e) {
        this.autocompleteLoaderAgent = false;
        throw e;
      }
    },
    clearFilters() {
      this.Category = 0;
      this.Type = [];
      this.Task = 0;
      this.ImportedFromEsign = false;
      this.esign = null;
      this.owner = null;
      this.agent = null;
      this.Assignation = null;
      this.AssignationEnd = null;
      this.AssignationEndFormatted = null;
      this.AssignationStart = null;
      this.AssignationStartFormatted = null;
      this.Company = null;
    },
    closeHandler() {
      this.$emit("close");
    },
    async createRealEstate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loader = true;
      let body = {
        ImportedFromEsign: !this.ImportedFromEsign ? false : true,
        Status: "Μη δημοσιευμένο",
      };
      if (this.Assignation) body.Assignation = this.Assignation;
      if (this.Category != null) {
        body.Category = this.getCategoryList[this.Category].val;
      }
      if (this.userDetails.Role == "Associate")
        body.Company = this.userDetails["Company"];
      else if (this.Company) body.Company = this.Company;
      if (this.Task != null) {
        body.Task = this.availableForList[this.Task].val;
      }
      if (this.Type != null) body.Type = this.Type;
      if (this.userDetails.Role == "Associate")
        body.UserID = this.userDetails["_id"];
      else if (this.agent != null) body.UserID = this.agent["_id"];
      if (this.owner != null) body.owner = this.owner._id;
      // if (this.agent != null) body.UserID = this.agent;
      // if (this.owner != null) body.CustomerID = this.owner;
      if (this.AssignationStart != null)
        body.AssignationStart = this.AssignationStart;
      if (this.AssignationEnd != null)
        body.AssignationEnd = this.AssignationEnd;

      if (this.esign != null) body.EstateCode = this.esign;
      let resp = await RealEstateRepo.create(body);
      this.onCreate(resp.data["id"]);

      this.loader = false;
    },
    parseDate(date, dateName) {
      if (!date || !CoreHelper.isFormattedDate(date)) {
        if (dateName == "AssignationStart") {
          this.AssignationStart = null;
          this.AssignationStartFormatted = null;
        } else if (dateName == "AssignationEnd") {
          this.AssignationEnd = null;
          this.AssignationEndFormatted = null;
        }
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    filterAgents(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
    closeContactModal() {
      this.showContact = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  text-transform: unset !important;
  color: #263573;
}

.v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
}

.v-text-input {
  font-size: 14px;
}

.v-btn-toggle {
  border-radius: 4px;
  display: block;
  max-width: 100%;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 48px;
  min-height: 0;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle > .v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.v-input--selection-controls {
  margin: 0px 0px -16px 0px;
  padding-top: 0px;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

.v-label {
  font-size: 13px;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 35px;
}

#active-button {
  background-color: #263573 !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #fff;
  color: #263573;
}

.theme--light.v-btn-toggle.v-btn-toggle--group {
  background: #fff;
  color: #263573;
}

span {
  padding-left: 4px;
}

.containter {
  padding: 37px 45px 19px 45px;
}

.recBtn {
  height: 108px !important;
  width: 118px !important;
}

.recBtnWidth {
  width: 118px !important;
}

@media only screen and (max-width: 960px) {
  .minusField {
    display: none !important;
  }
}
</style>
