<template >
  <v-row>
    <v-col cols="12" align="left">
      <span class="textInterBold font14 goldText">
        {{ $t("personalInformation") }}
      </span>
    </v-col>
    <v-col
      cols="12"
      xl="12"
      lg="12"
      md="12"
      sm="12"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('agentData')"
    >
      <v-row>
        <v-col class="pb-0" cols="12" align="left">
          <span class="textInterBold font14">
            {{ $t("assignedTo") }}
          </span>
        </v-col>
        <v-col cols="12">
          <AvatarField
            :data="getPhoto(data.agentData)"
            :isImg="data.agentData.Photo ? true : false"
            :showName="true"
            :nameContent="
              (data.agentData.FirstName ? data.agentData.FirstName + ' ' : '') +
              (data.agentData.LastName ? data.agentData.LastName : '')
            "
            :showSubContent="true"
            :subContent="
              data.agentData.LastCompany ? data.agentData.LastCompany + ' ' : ''
            "
            subNameContentClass=""
            subContentClass="textFontDecoration"
            fontSizeName="14px"
            :size="31"
            nameContentClass="textDecorationBold primaryColor mb-n1"
            fontSizeImg="14px"
            backgroundColor="#263573"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4">
      <ReadField
        v-if="true == checkShow('FirstName')"
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('firstname')"
        :data="data.FirstName"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('LastName')"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('lastname')"
        :data="data.LastName"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('FatherName')"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('fathersName')"
        :data="data.FatherName"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('Profession')"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('profession')"
        :data="data.Profession"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('Birthday') && authorizedContact"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('dateOfBirth')"
        :data="dob"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('Nameday')"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('nameDay')"
        :data="data.Nameday"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('AFM') && authorizedContact"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('vatNumber')"
        :data="data.AFM"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('municipality')"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('publFinanceService')"
        :data="data.municipality"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('DOY') && authorizedContact"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('DOY')"
        :data="data.DOY"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('personalId') && authorizedContact"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('idPassp')"
        :data="data.personalId"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="true == checkShow('chamberNo') && authorizedContact"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('chamberNo')"
        :data="data.registrationNumber"
        :isUnderline="false"
      />
    </v-col>
    <v-row
      v-if="mobileBreakpoint == true && topShow.showMore != undefined"
      class="mt-0 justify-center"
      cols="12"
    >
      <v-col class="pt-0 pb-0" align="center">
        <v-btn
          class="primaryColor unsetCapitalize opacity1 textDecorationBold"
          text
          plain
          @click="() => (topShow.showMore = !topShow.showMore)"
        >
          {{ topShow.showMore == true ? $t("lessInfo") : $t("moreInfo") }}...
        </v-btn>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
/*
Name:PersonalInfo
Description: Component to render PersonalInfo using ReadFields 

API Property:
Data: Object containing PersonalInfo data 
        data: {
                name: "Αλεξάνδρα",
                surname: "Παπανικοπούλου",
                fathersName: "Πατρώνυμο",
                profession: "Αρχιτέκτονας",
                dateOfBirth: "29-03-1996 (26 ετών)",
                nameDay: "01-01",
                 afm: "125125156",
                taxOffice: "Παλλήνης",
                 passportNumber: "ABC12345",
                 registrationNumber: "AB12345"
             },

Components Used:
  AvatarField
  ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField.vue";
import CoreHelper from "@/helpers/Core/core.js";
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";

export default {
  name: "PersonalInfo",
  components: { ReadField, AvatarField },
  props: {
    data: Object,
    authorizedContact: { type: Boolean, default: false },
  },
  data() {
    return {
      topArray: [
        "name",
        "surname",
        "fathersName",
        "profession",
        "dateOfBirth",
        "nameDay",
        "afm",
        "taxOffice",
        "passportNumber",
        "registrationNumber",
      ],
      topShow: { showKeys: [], showMore: undefined },
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
      fontSizeValue: "13px",
      dob: null,
    };
  },
  created() {
    if (this.mobileBreakpoint == true) {
      this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4);
    }

    if (!this.data.Birthday) this.dob = null;
    else {
      this.data.Birthday = this.data.Birthday.split(" ")[0].replaceAll(
        "/",
        "-"
      );
      if (this.data.Birthday.split("-").length != 3) this.dob = null;
      else {
        const [year, month, day] = this.data.Birthday.split("-");
        this.dob = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      }
    }
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getPhoto(obj) {
      return obj.Photo
        ? obj.Photo
        : obj.FirstName && obj.LastName
        ? obj.FirstName.charAt(0) + obj.LastName.charAt(0)
        : obj.FirstName
        ? obj.FirstName.charAt(1)
        : obj.LastName
        ? obj.LastName.charAt(1)
        : "";
    },

    checkShow(objKey) {
      return this.data[objKey]
        ? this.mobileBreakpoint != true
          ? true
          : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
            this.topShow.showMore != false
          ? true
          : false
        : false;
    },
  },
};
</script>
<style >
</style>