import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth/auth";
import genericList from "./lists/generic";
import listRealEstate from "./lists/listRealEstate";
import callsList from "./lists/callsList";
import realEstate from "./realEstate/realEstate";
import settings from "./settings/settings";
import location from "./generic/location";
import contact from "./generic/contact";
import menu from "./generic/menu";
import request from "./requests/filters";
import snackbar from "./snackbar/snackbar";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    listRealEstate,
    realEstate,
    genericList,
    location,
    contact,
    menu,
    snackbar,
    request,
    settings,
    callsList,
  },
  plugins: [createPersistedState()],
});
