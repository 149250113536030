<template>
  <v-col>
    <v-row>
      <v-col align="left">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("socialMedia") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Website')">
        <v-text-field v-model="Website" label="Website" clearable dense color="primary"
          prepend-inner-icon="mdi-attachment" hide-details="auto" />
      </v-col>
      <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('FB')">
        <v-text-field v-model="FB" label="Facebook" clearable dense color="primary" prepend-inner-icon="mdi-facebook"
          hide-details="auto" />
      </v-col>
      <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Instagram')">
        <v-text-field v-model="Instagram" label="Instagram" clearable dense color="primary"
          prepend-inner-icon="mdi-instagram" hide-details="auto" />
      </v-col>
      <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('LinkedIn')">
        <v-text-field v-model="LinkedIn" label="LinkedIn" clearable dense color="primary"
          prepend-inner-icon="mdi-linkedin" hide-details="auto" />
      </v-col>
      <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Twitter')">
        <v-text-field v-model="Twitter" label="Twitter" clearable dense color="primary" prepend-inner-icon="mdi-twitter"
          hide-details="auto" />
      </v-col>
      <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('otherSocialHandles')">
        <v-text-field v-model="otherSocialHandles" :label="$t('other')" clearable dense color="primary"
          prepend-inner-icon="mdi-attachment" hide-details="auto" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:SocialMediaInfo
 Description: Component to render social media info fields for contact

 API Property:
    attributes: Object with the data 
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the info type like "socialInfo"
*/
export default {
  name: "SocialMediaInfo",
  props: {
    attributes: Object,
    updateAttribute: Function,
    validation: Object,
    attributeModel: Array,
    kind: { type: String, default: "socialInfo" },
  },
  computed: {
    Website: {
      get() {
        return this.attributes.Website;
      },
      set(value) {
        this.updateAttribute(this.kind, "Website", value);
      },
    },
    FB: {
      get() {
        return this.attributes.FB;
      },
      set(value) {
        this.updateAttribute("socialInfo", "FB", value);
      },
    },
    Instagram: {
      get() {
        return this.attributes.Instagram;
      },
      set(value) {
        this.updateAttribute("socialInfo", "Instagram", value);
      },
    },
    LinkedIn: {
      get() {
        return this.attributes.LinkedIn;
      },
      set(value) {
        this.updateAttribute("socialInfo", "LinkedIn", value);
      },
    },
    Twitter: {
      get() {
        return this.attributes.Twitter;
      },
      set(value) {
        this.updateAttribute("socialInfo", "Twitter", value);
      },
    },
    otherSocialHandles: {
      get() {
        return this.attributes.otherSocialHandles;
      },
      set(value) {
        this.updateAttribute("socialInfo", "otherSocialHandles", value);
      },
    },
  },
  methods: {
    showAttribute(attribute) {
      return this.attributeModel.includes(attribute);
    }
  }
};
</script>
