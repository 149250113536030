<template>
  <v-col class="noShadow" id="fakeClick">
    <v-row class="masterRow">
      <v-col class="mr-2 mb-3 align-items-center gSearch" :class="showNewDealList == true ? 'colWidth' : ''">
        <v-row>
          <v-col class="flex-grow-0 pr-0 align-self-center">
            <Icon :icon="iconName" :height="iconHeight" :color="iconColor" />
          </v-col>
          <v-col class="pl-0 pr-0 pt-1 align-self-center">
            <gmap-autocomplete id="gMap" name="gMapName" :options="{
              fields: fields,
            }" @place_changed="selectMarker" style="width: 95%">
            </gmap-autocomplete>
          </v-col>
          <v-col class="flex-grow-0 pl-0 pr-0 pt-2">
            <v-icon v-if="locationGoogle && searchNewDeal" @click="clearLocGoogle" class="primaryColorOnHover"
              style="margin-left: 13px !important; cursor: pointer" v-text="'mdi-close'" />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="showNewDealList" class="pl-0 pr-0 pt-0 colWidth rightCol">
        <v-autocomplete v-model="searchNewDeal" 
        :items="areasListSorted" 
        @focusout="updateSearch('ND')"
          @focus="viewLabel(false)" 
          :search-input.sync="searchInput" 
          :filter="filterAreas"
          :label="$t('ListOfLocations')"
           color="primary" 
           hide-details="auto" 
           prepend-inner-icon="mdi-web" 
           class="mCard"
          min-height="40" 
          min-width="312" 
          :dense="true" 
          solo 
          multiple 
          flat 
          clearable 
          item-color="greenish" 
          ref="loc" 
          attach>
          <div slot="prepend-item" v-if="mobileBreakpoint">
            <v-btn color="primary" @click="closeList">{{$t("closed")}}</v-btn>
          </div>
          <template v-slot:selection="{ item, index }">
            <span class="text-truncate text-caption" v-if="
              searchNewDeal.length == 1 && index == 0 && viewLabelVal == true
            ">
              {{ item.length > 24 ? item.substring(0, 24) + "..." : item }}
            </span>
            <span v-if="
              searchNewDeal.length > 1 && index == 0 && viewLabelVal == true
            " class="grey--text text-caption">
              {{ searchNewDeal.length + " " + $t("selectedAreas") }}
            </span>
          </template>
          <template slot="item" slot-scope="{ item }">
            <v-list class="newDealLocItem">
              <v-list-item-icon>
                <v-icon class="newDealLoc newDealLocIcon primaryColorOnHover" style="margin-left: 13px !important"
                  v-text="'mdi-close'" />
              </v-list-item-icon>
              <v-list-item-title class="newDealLoc" v-html="myGenFilteredText(item)" />
            </v-list>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
/*
 Name:Location
 Description: Component to render location with google map

 API Property:
    label: String for the label
    iconName: String for icon name
    iconHeight: (String, Number) for icon height
    placeholder: String for the place
    iconColor: String for the icon color
    vuexProps: Boolean for the vuex props
    showNewDealList: Boolean for the 
    setLocation: Function on set location
 
    Components Used:
      Icon
*/
import Repository from "@/services/Repository";
import { mapActions, mapState } from "vuex";
const RealEstateRepo = Repository.get("realEstate");
import MapHelpers from "@/helpers/Core/mapHelper";
import CoreHelpers from "@/helpers/Core/core";
import { Icon } from "@iconify/vue2";
export default {
  props: {
    label: String,
    iconName: { type: String, default: "ant-design:google-outlined" },
    iconHeight: { type: [String, Number], default: "20" },
    placeholder: String,
    iconColor: String,
    vuexProps: { type: Boolean, default: true },
    showNewDealList: { type: Boolean, default: false },
    setLocation: Function,
  },
  components: {
    Icon,
  },
  data() {
    return {
      areasList: [],
      viewLabelVal: true,
      showList: false,
      searchInput: "",
      locationDetails: null,
      lSearchNewDeal: null,
      lOldSearchNewDeal: null,
      lSearchGoogleText: null,
      lLocationGoogle: null,
      fields: [
        "geometry",
        "address_components",
        "formatted_address",
        "name",
        "address_component",
        "adr_address",
        "business_status",
        "icon",
        "icon_mask_base_uri",
        "icon_background_color",
        "name",
        "photo",
        "place_id",
        "plus_code",
        "type",
        "url",
        "vicinity",
      ],
    };
  },
  created() {
    this.showNewDealList == true ? this.fetchAreas() : null;
    this.updateGMapLabel(this.placeholder);
  },
  watch: {
    searchInput() {
      this.searchInput != "" ? this.viewLabel(false) : null;
    },
    placeholder() {
      this.updateGMapLabel(this.placeholder);
    },
    locationGoogle() {
      if (this.locationGoogle == "") {
        this.updateGMapLabel(this.placeholder);
        this.updateGMapValue();
      }
    },
  },
  computed: {
    ...mapState({
      realEstateFilter: (state) => state.realEstate,
      locationGoogle: (state) => state.realEstate.locationGoogle,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    searchNewDeal: {
      get() {
        return this.vuexProps == true
          ? this.realEstateFilter.searchNewDeal
          : this.lSearchNewDeal;
      },
      set(value) {
        if (this.vuexProps == true) this.onSetSearchNewDeal(value);
        else this.lSearchNewDeal = value;
      },
    },
    locationGoogle: {
      get() {
        return this.vuexProps == true
          ? this.realEstateFilter.locationGoogle
          : this.lLocationGoogle;
      },
      set(value) {
        if (this.vuexProps == true) this.onSetLocationGoogle(value);
        else this.lLocationGoogle = value;
      },
    },
    searchText: {
      get() {
        return this.vuexProps == true
          ? this.realEstateFilter.searchGoogleText
          : this.lSearchGoogleText;
      },
      set(value) {
        if (this.vuexProps == true) this.onSetSearchGoogleText(value);
        else this.lSearchGoogleText = value;
      },
    },
    areasListSorted() {
      return CoreHelpers.sortByArray(this.areasList, this.searchNewDeal);
    },
  },
  methods: {
    ...mapActions([
      "onSetSearchNewDeal",
      "onSetLocationGoogle",
      "onSetSearchGoogleText",
    ]),
    closeList() {
      this.$refs.loc.blur();
    },
    updateGMapLabel(newLabel) {
      try {
        this.$nextTick(() => {
          document.getElementsByName("gMapName")[0].placeholder = newLabel;
        });
      } catch {
        true;
      }
    },
    updateGMapValue() {
      try {
        this.$nextTick(() => {
          document.getElementsByName("gMapName")[0].value = "";
        });
      } catch {
        true;
      }
    },
    selectMarker(loc) {
      loc["addressExtracted"] = MapHelpers.getAddressObj(
        loc.address_components
      );
      this.locationGoogle = loc;
      this.updateGMapLabel(this.locationGoogle.formatted_address);
      this.updateSearch("GO");
    },
    clearLocGoogle() {
      this.locationGoogle = null;
      this.updateGMapLabel(this.placeholder);
      this.updateGMapValue();
      this.updateSearch("GO");
    },
    updateSearch(from) {
      if (from == "GO") {
        this.showList = false;
        this.setLocation(
          this.vuexProps == false && this.locationGoogle
            ? this.locationGoogle
            : null
        );
      } else if (from == "ND") {
        this.viewLabel(true);
        console.log(this.searchNewDeal,this.lOldSearchNewDeal)
        if (this.lOldSearchNewDeal != this.searchNewDeal) {
          this.searchInput = "";
          this.setLocation(
            this.vuexProps == false && this.searchNewDeal
              ? this.searchNewDeal
              : null
          );
        }
        this.lOldSearchNewDeal = this.searchNewDeal;
      }
    },
    viewLabel(val) {
      this.viewLabelVal = val;
    },
    filterAreas(item, queryText, itemText) {
      if (queryText.length >= 1) {
        let queryWord = queryText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .split(" ");
        let itemWord = itemText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase();
        return queryWord.every((item) => itemWord.includes(item));
      }
    },
    genHighlight(text) {
      return `<span class="v-list-item__mask searchLocationMask">${CoreHelpers.escapeHTML(
        text
      )}</span>`;
    },
    myGenFilteredText(text) {
      text = text || "";
      if (!this.searchInput || this.noFilter)
        return CoreHelpers.escapeHTML(text);
      const { start, middle, end, whiteSpaceBefore, whiteSpaceAfter } =
        CoreHelpers.getMaskedCharacters(text, this.searchInput);
      return `${CoreHelpers.escapeHTML(start)} ${whiteSpaceBefore ? "&nbsp" : ""
        } ${this.genHighlight(middle)} ${whiteSpaceAfter ? "&nbsp" : ""
        } ${CoreHelpers.escapeHTML(end)}`;
    },
    async fetchAreas() {
      try {
        const resp = await RealEstateRepo.getAreas();
        if (resp.data) this.areasList = resp.data;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>
<style scoped>
.mCard {
  border: solid 1px #263573;
  background-color: white;
  border-radius: 6px;
}

.gSearch {
  border: solid 1px #263573;
  background-color: white;
  border-radius: 6px;
  min-height: 42px;
  max-height: 42px;
  height: 42px;
}

input {
  outline: none !important;
}

input::placeholder {
  font-size: 14px;
}

.noShadow {
  box-shadow: unset !important;
}

.colWidth {
  max-width: 314px;
  min-width: 312px;
}

@media only screen and (max-width: 1215px) {
  .masterRow {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .colWidth {
    max-width: 100%;
    min-width: 100%;
  }
}
</style>
