import moment from "moment";
import i18n from "@/i18n/i18n";
import store from "@/store";
export default {
  setFilterObject(filters) {
    function toRegex(string = "") {
      let regObj = {
        Ά: "[Α|Ά]",
        Έ: "[Ε|Έ]",
        Ή: "[Η|Ή]",
        Ί: "[Ι|Ί]",
        Ό: "[Ο|Ό]",
        Ύ: "[Υ|Ύ]",
        Ώ: "[Ω|Ώ]",
        Α: "[Α|Ά]",
        Ε: "[Ε|Έ]",
        Η: "[Η|Ή]",
        Ι: "[Ι|Ί]",
        Ο: "[Ο|Ό]",
        Υ: "[Υ|Ύ]",
        Ω: "[Ω|Ώ]",
      };
      let chars = [];
      try {
        if (string != null) {
          chars = string.toUpperCase().split("");
          chars.map((e, i) => {
            if (regObj[e]) {
              chars[i] = regObj[e];
            }
          });

          string = chars.join("");

          return string.trim();
        } else {
          return "";
        }
      } catch (ex) {
        console.error(ex);
        return undefined;
      }
    }
    let tagFilterArray = [];
    let object = {
      $and: [
        {
          $or: [{ IsDeleted: false },
          { IsDeleted: { $exists: false } }],
        },
      ],
    };
    object["$and"].push({
      $or: [ { IsApproved: true },
        { IsApproved: { $exists: false } },],
    });
    // b = {
    //   $and: [
    //     { $or: [{ my_customers: true }, { Role: "Admin" }] },
    //     { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
    //   ],
    // };
    if (filters.FirstName != null && filters.FirstName != "") {
      object.FirstName = {
        $regex: toRegex(filters.FirstName),
        $options: "i",
      };
      tagFilterArray.push({
        display: i18n.t("firstname") + ' ' + filters.FirstName,
        attrHead: [{ name: "FirstName", action: "updateFieldValue" }],
        attrValue: filters.FirstName,
        attrType: "string",
      });
    }

    if (filters.agent_customers != null && filters.agent_customers != "") {
      object.agent_customers = filters.agent_customers._id;
      tagFilterArray.push({
        display:
        i18n.t("assignedTo") + ' ' +
          filters.agent_customers.FirstName +
          " " +
          filters.agent_customers.LastName,
        attrHead: [{ name: "agent_customers", action: "updateFieldValue" }],
        attrValue: filters.agent_customers,
        attrType: "string",
      });
    }

    if (filters.LastName != null && filters.LastName != "") {
      object.LastName = { $regex: toRegex(filters.LastName), $options: "i" };
      tagFilterArray.push({
        display: i18n.t("lastname") + ' ' + filters.LastName,
        attrHead: [{ name: "LastName", action: "updateFieldValue" }],
        attrValue: filters.LastName,
        attrType: "string",
      });
    }
    if (filters.Telephone != null && filters.Telephone != "") {
      let mobile = {
        $or: [
          { Mobile: { $regex: filters.Telephone, $options: "i" } },
          { Telephone: { $regex: filters.Telephone, $options: "i" } },
        ],
      };
      object["$and"].push(mobile);
      tagFilterArray.push({
        display: i18n.t("telephoneFilter") + ' ' + filters.Telephone,
        attrHead: [{ name: "Telephone", action: "updateFieldValue" }],
        attrValue: filters.Telephone,
        attrType: "string",
      });
    }

    // if (filters.agent_customers != null && filters.agent_customers != "") {
    //   let mobile = {
    //     $or: [
    //       { agent_customers: { $regex: filters.agent_customers, $options: "i" } },
    //     ],
    //   };
    //   object["$and"].push(mobile);
    //   tagFilterArray.push({
    //     display: "Ανατέθηκε σε " + filters.agent_customers,
    //     attrHead: [{ name: "agent_customers", action: "updateFieldValue" }],
    //     attrValue: filters.agent_customers,
    //     attrType: "string",
    //   });
    // }

    if (filters.Email != null && filters.Email != "") {
      object.Email = { $regex: toRegex(filters.Email), $options: "i" };
      tagFilterArray.push({
        display: "Email " + filters.Email,
        attrHead: [{ name: "Email", action: "updateFieldValue" }],
        attrValue: filters.Email,
        attrType: "string",
      });
    }
    if (filters.Suffix != null && filters.Suffix != "") {
      object.Suffix = { $regex: toRegex(filters.Suffix), $options: "i" };
      tagFilterArray.push({
        display: i18n.t("collaboratorCode") + ' ' + filters.Suffix,
        attrHead: [{ name: "Suffix", action: "updateFieldValue" }],
        attrValue: filters.Suffix,
        attrType: "string",
      });
    }
    if (filters.deteleContacts) {
      object.IsDeleted = false;
      tagFilterArray.push({
        display: i18n.t("deletedContacts") + ' ',
        attrHead: [{ name: "deteleContacts", action: "updateFieldValue" }],
        attrValue: filters.deteleContacts,
        attrType: "string",
      });
    }
    if (filters.contactUser) {
      tagFilterArray.push({
        display: i18n.t("contactAccount"),
        attrHead: [{ name: "contactUser", action: "updateFieldValue" }],
        attrValue: filters.contactUser,
        attrType: "string",
      });
    }
    if (filters.contactEstate) {
      tagFilterArray.push({
        display: i18n.t("contactOwner"),
        attrHead: [{ name: "contactEstate", action: "updateFieldValue" }],
        attrValue: filters.contactEstate,
        attrType: "string",
      });
    }
    if (filters.contactRequest) {
      tagFilterArray.push({
        display: i18n.t("contactActiveRequests"),
        attrHead: [{ name: "contactRequest", action: "updateFieldValue" }],
        attrValue: filters.contactRequest,
        attrType: "string",
      });
    }
    if (filters.contactNewDeal) {
      tagFilterArray.push({
        display: i18n.t("personalNewDeal"),
        attrHead: [{ name: "contactNewDeal", action: "updateFieldValue" }],
        attrValue: filters.contactNewDeal,
        attrType: "string",
      });
    }
    if (filters.assigneeTo != null && filters.assigneeTo != "") {
      object.Suffix = { $regex: filters.Suffix, $options: "i" };
      tagFilterArray.push({
        display: i18n.t("assignmentTo"),
        attrHead: [{ name: "assigneeTo", action: "updateFieldValue" }],
        attrValue: filters.assigneeTo,
        attrType: "string",
      });
    }
    if (filters.newsletter) {
      tagFilterArray.push({
        display: i18n.t("notReceiveNews"),
        attrHead: [{ name: "newsletter", action: "updateFieldValue" }],
        attrValue: filters.newsletter,
        attrType: "string",
      });
    }
    if (filters.createdDateFrom != null && filters.createdDateTo == null) {
      object.RegistrationDate = {
        $gte: moment(filters.createdDateFrom).unix(),
      };
      tagFilterArray.push({
        display:
          i18n.t("systemDate") +
          " " +
          filters.createdDateFrom +
          " " +
          i18n.t("toDt").toLowerCase(),
        attrHead: [
          { name: "createdDateFrom", action: "updateFieldValue" },
          { name: "createdDateTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.createdDateFrom, filters.createdDateTo],
      });
    } else if (
      filters.createdDateFrom != null &&
      filters.createdDateTo != null
    ) {
      object.RegistrationDate = {
        $gte: moment(filters.createdDateFrom).unix(),
        $lte: moment(filters.createdDateTo).unix(),
      };
      tagFilterArray.push({
        display:
          i18n.t("systemDate") +
          " " +
          filters.createdDateFrom +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.createdDateTo,
        attrHead: [
          { name: "createdDateFrom", action: "updateFieldValue" },
          { name: "createdDateTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.createdDateFrom, filters.createdDateTo],
      });
    } else if (
      filters.createdDateFrom == null &&
      filters.createdDateTo != null
    ) {
      object.RegistrationDate = { $lte: moment(filters.createdDateTo).unix() };
      tagFilterArray.push({
        display:
          i18n.t("systemDate") +
          " " +
          i18n.t("toDt").toLowerCase() +
          " " +
          filters.createdDateTo,
        attrHead: [
          { name: "createdDateFrom", action: "updateFieldValue" },
          { name: "createdDateTo", action: "updateFieldValue" },
        ],
        attrType: "string",
        attrValue: [filters.createdDateFrom, filters.createdDateTo],
      });
    }
    if (filters.myCustomer) {
      object["my_customers"] = true;
      tagFilterArray.push({
        display: "Οι Πελάτες Μου",
        attrHead: [{ name: "myCustomer", action: "updateFieldValue" }],
        attrValue: filters.myCustomer,
        attrType: "string",
        noDelete: true,
      });
    } else if (store.state.auth.userDetails.Role != "Admin") {
      object["$and"].push({
        $or: [{ Role: { $exists: true } }, { my_customers: true }],
      });
    }
    if (filters.faveContacts) {
      if (
        store.state.auth.userDetails &&
        store.state.auth.userDetails &&
        store.state.auth.userDetails.favorite_contacts &&
        store.state.auth.userDetails.favorite_contacts.length > 0
      )
        object["_id"] = {
          $in: [...store.state.auth.userDetails.favorite_contacts],
        };
      else {
        object["_id"] = {
          $in: [],
        };
      }

      tagFilterArray.push({
        display: "Αγαπημένες Επαφές",
        attrHead: [{ name: "faveContacts", action: "updateFieldValue" }],
        attrValue: filters.faveContacts,
        attrType: "string",
      });
    }
    if (filters.rolesSelected && filters.rolesSelected.length > 0) {
      let roles = [];
      // let types = [];
      filters.rolesSelected.map((m, i) => {
        let role;
        if (!["", null, undefined].includes(m.role)) {
          if (!["", null, undefined].includes(m.type))
            role = { $and: [{ Role: m.role }, { Type: m.type }] };
          else role = { $and: [{ Role: m.role }] };
        } else {
          role = { $and: [{ Role: { $exists: false } }, { Type: m.type }] };
        }
        roles.push(role);

        if (!["", null, undefined].includes(m.name)) {
          tagFilterArray.push({
            display: m.name,
            attrHead: [{ name: "rolesSelected", action: "updateFieldValue" }],
            attrValue: m,
            attrType: "array",
            attrIndex: i,
          });
        }
      });

      object["$and"].push({ $or: roles });
    }
    if (filters.companySelected && filters.companySelected.length > 0) {
      let compNames = [];
      filters.companySelected.map((m, i) => {
        if (!["", null, undefined].includes(m.name_id)) {
          compNames.push(m.name_id);
          tagFilterArray.push({
            display: m.name,
            attrHead: [{ name: "companySelected", action: "updateFieldValue" }],
            attrValue: m,
            attrType: "array",
            attrIndex: i,
          });
        }
      });
      object["LastCompany"] = { $in: [...compNames] };
    }
    if (filters.tagsFilt && filters.tagsFilt.length > 0) {
      filters.tagsFilt.map((m, i) => {
        tagFilterArray.push({
          display: "Tag: " + m,
          attrHead: [{ name: "tagsFilt", action: "updateFieldValue" }],
          attrValue: m,
          attrType: "array",
          attrIndex: i,
        });
      });
      object["tags"] = { $in: [...filters.tagsFilt] };
    }
    return [tagFilterArray, object];
  },
  isAuthorizedContact(contactObject) {
    /*
      contactObject:{role : '', 
              type: '', 
              _id: '' , 
              lastCompany: ''
            }
    */
    if (store.state.auth.userDetails.Role && store.state.auth.userDetails.Role == "Admin") return true;
    if (contactObject._id == store.state.auth.userDetails._id) return true;
    if (store.state.auth.userDetails.Type.includes("Συνεργάτης")) {
      if (store.state.auth.userDetails.Role && store.state.auth.userDetails.Role == "Associate") {
        if (contactObject.type.includes("Συνεργάτης")) return false;
        else return true;
      } else if (
        store.state.auth.userDetails.Role && store.state.auth.userDetails.Role == "Secretary" ||
        store.state.auth.userDetails.Role && store.state.auth.userDetails.Role == "OfficeAdmin"
      ) {
        if (
          contactObject.lastCompany == store.state.auth.userDetails.LastCompany
        )
          return true;
        else return false;
      } else if (store.state.auth.userDetails.Role && store.state.auth.userDetails.Role == "Leader") {
        if (contactObject.type.includes("Συνεργάτης"))
          return store.state.auth.userDetails.Team.includes(contactObject._id);
        else return true;
      }
    }
    return false;
  },
  initDataContact(contactObject) {
    if (contactObject.FirstName) {
      if (contactObject.LastName) {
        contactObject.title = contactObject.FirstName + " " + contactObject.LastName;
      }
    } else if (contactObject.FirstName) {
      contactObject.title = contactObject.FirstName;
    } else {
      contactObject.title = contactObject.LastName;
    }
    // if (contactObject.LastCompany) {
    //   let result = companyList.findIndex(
    //     (company) => company.name_id == contactObject.LastCompany
    //   );
    //   if (result >= 0) {
    //     contactObject.displayCompany = companyList[result].name;
    //   } else {
    //}
    //}
    if (contactObject.Role) {
      contactObject.displayRole = contactObject.Role;
    } else if (contactObject.Type) {
      let varType;
      varType = contactObject.Type instanceof Array;
      if (varType) {
        contactObject.displayRole = contactObject.Type[0];
      } else {
        contactObject.displayRole = contactObject.Type;
      }
    }

    if (store.state.auth.userDetails.favorite_contacts) {
      if (store.state.auth.userDetails.favorite_contacts.indexOf(contactObject._id) === -1) {
        contactObject.isSaved = false;
      } else {
        contactObject.isSaved = true;
      }
    } else {
      contactObject.isSaved = false;
    }
    if (
      contactObject.Photo == undefined ||
      contactObject.Photo == "" ||
      contactObject.Photo == "/uploads/associate/noAgent.png" ||
      (contactObject.Photo != undefined && contactObject.Photo.split("/")[0] != "Uploads")
    ) {
      let name;
      if (contactObject.title) {
        name = contactObject.title
          .split(/\s/)
          .reduce(
            (response, word) => (response += word.slice(0, 1)),
            ""
          );
      }
      contactObject.Photo = name;
      contactObject.isImgAgent = false;
    } else {
      contactObject.isImgAgent = true;
    }
    return contactObject;
  },
};
