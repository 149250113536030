<template>
  <v-card>
    <v-row v-if="files.length == 0 || singularUpload">
      <v-col align="right">
        <v-btn depressed icon color="black" class="ml-auto" @click="close">
          <v-icon class="primaryColorOnHover xClass">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="align-self-center cursorPointer pt-0 pb-0 pr-0 pl-0" @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true" @dragenter.prevent="dragover = true" @dragleave.prevent="dragover = false"
        @click="$refs.file.click()" :class="{ 'grey lighten-2': dragover }">
        <v-row>
          <v-col align="center">
            <v-img width="138px" height="93px" :src="require('@/assets/documents/upload.svg')" />
            <input type="file" ref="file" style="display: none" @change="getFile" :multiple="!singularUpload" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="header textDecorationBold"> {{ $t("addNewDocuments") }} </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="content"> {{ $t("addFilesTextP1") }} <u class="successColor fontUnderline"> {{
                $t("addFilesTextP2")
            }} </u>
              {{ $t("addFilesTextP3") }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-divider class="myDivider" v-if="files.length > 0 && !singularUpload" vertical>
      </v-divider>
      <v-col class="mt-3" v-if="files.length > 0 && !singularUpload">
        <v-row>
          <v-col align="left">
            <span class="orangeText font14 textDecorationBold"> {{ $t("documentsMedia") }} </span>
          </v-col>
        </v-row>
        <v-row class="overflow-y-auto galleryScrollbar">
          <v-col v-for="(item, i) in files" class="flex-grow-0" :key="i">
            <v-row style="max-width: 206px !important">
              <v-col class="pt-1 pl-5 pr-0">
                <v-card elevation="0">
                  <v-container class="fileCard pa-0">
                    <v-card-title class="pr-2 pt-1 pb-0 pl-0">
                      <v-row>
                        <v-col align="right">
                          <v-btn @click="removeFile(i)" icon>
                            <v-icon color="white" medium> mdi-trash-can-outline </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text class="pl-1 pt-0 pb-0 pr-1">
                      <v-row class="align-center justify-center">
                        <v-col class="pb-0 d-flex justify-center"
                          :class="!getSrc(item.file) ? 'itemIcon pt-0 mt-3 max-width-36 align-center textDecorationBold' : ''">
                          <v-img v-if="getSrc(item.file)" class="iconSize" :src="getSrc(item.file)" />
                          <span v-else> .{{ getFileType(item.file).toLowerCase() }} </span>
                        </v-col>
                      </v-row>
                      <v-row class="pr-4">
                        <v-col class="fontWhite txtOverFlow" align="left">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div align="left" v-bind="attrs" v-on="on"> {{ item.file.name }} </div>
                            </template>
                            <span> {{ item.file.name != undefined ? item.file.name : "" }} </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-container>
                  <v-container class="pa-0" v-if="supportDocType || supportCheckBox || supportTxt">
                    <v-row v-if="supportDocType">
                      <v-col class="pb-0 ma-0">
                        <v-select :items="docTypesList" item-text="name" item-value="id" v-model="item.docType"
                          :label="$t('documentType')">
                          <template v-slot:selection="{ item }">
                            <v-row class="flex-grow-0">
                              <v-col style="display: flex; align-items: center">
                                {{ $t(item.name) }}
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list>
                              <v-list-item-title>
                                <v-row>
                                  <v-col style="display: flex; align-items: center">
                                    {{ $t(item.name) }}
                                  </v-col>
                                </v-row>
                              </v-list-item-title>
                            </v-list>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="supportCheckBox" v-bind:class="{ 'pt-5': !supportDocType, }">
                      <v-col align="left" class="pt-0 pb-0 ma-0">
                        <v-checkbox class="pt-0 pb-0 ma-0" :label="itemCheckBoxDescr"
                          v-model="item[itemCheckBoxPropName]" :false-value="itemCheckBoxFalseVal"
                          :true-value="itemCheckBoxTrueVal" />
                      </v-col>
                    </v-row>
                    <v-row v-if="supportTxt" class="mt-0">
                      <v-col class="pt-0">
                        <v-text-field class="pt-0" v-model="item[itemTxtPropName]" :label="itemTxtDescr"
                          :maxlength="itemTxtMaxLength" clearable color="primary" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="files.length > 0 && !singularUpload">
      <v-col>
        <u> <a class="errorColor" @click="close"> {{ $t("exit") }} </a> </u>
        <v-btn elevation="5" @click="submit" align="center" class="ma-1 btn ml-10" color="error"
          :disabled="this.hasNullCategories">
          {{ $t("submit") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/* 
  Name:Upload
  Description: Upload component to generate quickly upload areas. Support three fields and custom field names and values 
  API Property 
    singularUpload: Boolean variable to set as uploadable only one document
    onlyImgRestrict: Boolean variable to set as uploadable only images
    supportDocType: Boolean variable to show/hide the docType select list
    docTypeRequired: Boolean variable to set docType as required
    docType: String containing the selected docType
    docTypesList: Array of document types
    supportCheckBox: Boolean variable to show/hide the checkbox
    itemCheckBoxPropName: String containing the Checkbox property name
    itemCheckBoxDescr: String containing the Checkbox description
    itemCheckBoxFalseVal: String,Boolean containing the false value of the checkbox
    itemCheckBoxTrueVal: String,Boolean containing the true value of the checkbox
    supportTxt: Boolean variable to show/hide the Textfield
    itemTxtPropName: String containing the TextField Property name
    itemTxtDescr: String containing the TextField description
    itemTxtMaxLength: Number containing the max length of the TextField
    imgTypes: Array containing image types
    imgExtensions: Array containing image extensions
    docExtensionAllowed: Array containing the documents extensions allowed for upload
    closeHandler: Handler function from parent when close
    submitHandler: Handler function from parent when submit
*/
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "Upload",
  props: {
    singularUpload: { type: Boolean, default: false }, //Flag that uploads only one document
    onlyImgRestrict: { type: Boolean, default: false }, //Flag that allows only images to be uploaded
    supportDocType: { type: Boolean, default: false }, // Flag that shows or hides the docType select list
    docTypeRequired: { type: Boolean, default: false }, // Flag that shows if docType is required
    docType: String, //documentType name
    docTypesList: Array, //documentType lists
    supportCheckBox: { type: Boolean, default: false }, // Flag that shows or hides checkbox
    itemCheckBoxPropName: String, // Property containing checkbox value
    itemCheckBoxDescr: String, // Description of checkBox
    itemCheckBoxFalseVal: { type: [Boolean, String], default: false }, // True value of checkbox
    itemCheckBoxTrueVal: { type: [Boolean, String], default: true }, // False value of checkbox
    supportTxt: { type: Boolean, default: false }, // Flag that shows or hides text field
    itemTxtPropName: String, // Textfield property name
    itemTxtDescr: String, // Text field Description
    itemTxtMaxLength: { type: Number, default: 4000 }, // Textfield max length
    imgTypes: {
      type: Array,
      default: function () {
        return ["image/jpeg", "image/png", "image/jpg", "image/png"];
      },
    }, // Array containing image types
    imgExtensions: {
      type: Array,
      default: function () {
        return ["jpeg", "png", "jpg"];
      },
    }, // Array containing image extensions
    docExtensionAllowed: {
      type: Array,
      default: function () {
        return [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/pdf",
          "image/jpeg",
          "image/jpg",
          "image/webp",
          "image/png",
        ];
      },
    }, // Array containing the documents extensions allowed for upload
    closeHandler: Function, //Function that closes if opened as dialog
    submitHandler: Function, // Function that submits
  },
  data() {
    return {
      dragover: false,
      files: [],
    };
  },
  methods: {
    getFile(e) {
      this.initFiles(e.target.files);
    },
    onDrop(e) {
      this.dragover = false;
      this.initFiles(e.dataTransfer.files);
    },
    initFiles(data) {
      if (!this.singularUpload) {
        Array.from(data).forEach((file) => {
          if (((this.onlyImgRestrict && this.imgTypes.includes(file.type)) || !this.onlyImgRestrict) && this.docExtensionAllowed.includes(file.type))
            this.files.push({
              file: file,
              docType: this.docType,
              public: this.itemCheckBoxTrueVal,
            });
        });
      } else {
        if (((this.onlyImgRestrict && this.imgTypes.includes(data[0].type)) || !this.onlyImgRestrict) && this.docExtensionAllowed.includes(data[0].type)) {
          this.files = [
            {
              file: data[0],
              docType: this.docType,
              public: this.itemCheckBoxTrueVal,
            },
          ];
          this.submit();
        }
      }
    },
    getFileType(document) { return document.name != undefined ? CoreHelper.getFileType(document.name).toUpperCase() : ""; },
    getSrc(document) {
      let type = document.name != undefined ? CoreHelper.getFileType(document.name).toLowerCase() : "";
      return type == "pdf" ? require("@/assets/documents/pdfCircleIcon.png") : this.imgExtensions.includes(type.toLowerCase()) ? require("@/assets/documents/img-icon.png") : false;
    },
    removeFile(fileId) { this.files.splice(fileId, 1); },
    close() { this.closeHandler(); },
    submit() {
      if (!this.hasNullCategories) {
        this.submitHandler(this.files);
        this.close();
        this.files = [];
      }
    },
  },
  computed: {
    hasNullCategories() {
      if (this.docTypeRequired) {
        let result = this.files.filter((file) => { return !file.docType; });
        return result.length > 0 ? true : false;
      } else
        return false;
    },
  },
};
</script>

<style scoped>
.fontUnderline {
  text-decoration: underline;
}

.header {
  color: #263573;
  font-size: 20px;
}

/* .iconSize {
  width: 70px !important;
  height: 94px !important;
  z-index: 99999 !important;
} */

.fontWhite {
  color: white;
  font-size: 16px;
}

.content {
  font-size: 16px;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.fileCard {
  border-bottom: 5px solid red;
  background-color: #dadada;
  width: 186px;
  height: 130px;
}

.galleryScrollbar {
  max-height: 340px;
}

.txtOverFlow {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.iconSize {
  max-width: 35.58px !important;
  max-height: 47.44px !important;
}

.cursorPointer {
  cursor: pointer;
}

@media only screen and (max-width: 506px) {
  .myDivider {
    display: none;
  }
}

.itemIcon {
  background-color: #e52628;
  color: #ffffff !important;
  font-size: 11px !important;
  width: 36.61px !important;
  height: 35.58px !important;
  border-radius: 50%;
  margin-right: 12px;
}

.max-width-36 {
  max-width: 36px !important;
}
</style>
