import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import Vuelidate from "vuelidate";
import i18n from "./i18n/i18n.js";
import "@/plugins/firebase";
import * as VueGoogleMaps from "vue2-google-maps";
// import CustomGoogleAutocomplete from "vue-custom-google-autocomplete";
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA159uGZ68sOFYLfWHmAzkRrDYUHnL706E",
    libraries: "places",
    language: "el"
  },
});
// Vue.use(CustomGoogleAutocomplete);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(DatetimePicker)
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
