var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pb-2 pt-0",attrs:{"align":"center"}},[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"flex-grow-0",attrs:{"align":"left"}},[_c('v-select',{attrs:{"items":_vm.filterList,"outlined":"","hide-details":"auto","item-text":"label","item-value":"id","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)],1)],1)]}}]),model:{value:(_vm.filterChoice),callback:function ($$v) {_vm.filterChoice=$$v},expression:"filterChoice"}})],1),_c('v-col',{attrs:{"align":"right"}},[_c('v-select',{staticClass:"textDecorationBold font14",staticStyle:{"max-width":"75px"},attrs:{"items":_vm.pagesList,"dense":"","color":"primary","item-color":"primary","height":"35","hide-details":"","outlined":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])],1)],1)],1)]}}]),model:{value:(_vm.itemsOptions),callback:function ($$v) {_vm.itemsOptions=$$v},expression:"itemsOptions"}})],1)],1)],1)],1),_c('v-row',[_c('CustomTable',{staticClass:"row-height-75 pl-0 pr-0",staticStyle:{"overflow-y":"auto"},attrs:{"headers":_vm.headers,"data":_vm.estatesData,"itemsPerPage":10,"totalPages":199,"page":1,"templates":_vm.templates,"loading":_vm.loading,"handleEstateInfoView":_vm.openRealEstateSlider}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }