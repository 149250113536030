<template>
  <v-col>
    <v-card :max-width="maxWidth" :min-width="minWidth" elovation="2">
      <v-col class="pb-0">
        <v-row>
          <v-col
            align="left"
            class="pl-4 pb-2 textDecorationBold font19 primaryColor"
          >
            <span> Tags </span>
          </v-col>
          <v-col class="flex-grow-0 pb-2">
            <v-menu
              offset-y
              left
              nudge-width="300"
              nudge-height="230"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-if="authorizedContact"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  icon
                  color="black"
                  @click="() => {}"
                >
                  <v-icon class="primaryColorOnHover" color="#263573">
                    mdi-plus-box-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-col class="tagsMenu pl-6 pr-6">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="newTag"
                      color="#43425D"
                      append-icon="mdi-close"
                      :placeholder="$t('newTag')"
                      v-on:keyup.enter="addTag"
                    >
                      <template slot="append">
                        <v-btn
                          align="center"
                          color="error"
                          class="arrowRight"
                          @click="addTag"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" class="pl-4 pb-0 pt-0" align="left">
                    <span> {{$t("savedTags") + ':'}} </span>
                  </v-col>
                  <v-col class="pl-0">
                    <v-virtual-scroll
                      :items="tagsArray"
                      height="110"
                      item-height="35"
                    >
                      <!-- 
                    <v-virtual-scroll
                      :items="savedTags" -->
                      <template v-slot:default="{ item, i }">
                        <v-list-item :key="i">
                          <v-list-item-content
                            @click="selectTag(i)"
                            class="virtualItem primaryColorOnHover"
                          >
                            <v-list-item-title>
                              <span>
                                {{ item }}
                              </span>
                              <!-- <v-btn align="left">
                                <v-icon
                                  @click="removeItem(i)"
                                  class="topIcons primaryColorOnHover"
                                >
                                  mdi-close
                                </v-icon>
                              </v-btn> -->
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-col>
                </v-row>
              </v-col>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0 pl-0">
            <v-combobox
              v-model="tagsArray"
              multiple
              solo
              flat
              deletable-chips
              hide-no-data
              chips
              :disabled ="!authorizedContact"
              label=""
              append-icon=""
              class="boxedChips"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-col>
</template>
<script>
/*
 Name:TagsInfo
 Description: Component to render area with tags with creata and delete options for the tags

 API Property:
 tags: Array containing current tags
 savedTags: Array containing saved tags for selection
 updateAttribute: Function that updates tags in parent component
 authorizedContact: Boolean from the parent to authorize users for edit tags
*/

export default {
  name: "TagsInfo",
  props: {
    maxWidth: { type: [String, Number], default: 409 },
    minWidth: { type: [String, Number], default: 409 },
    tags: {
      type: Array,
    }, // Array of tags
    savedTags: {
      type: Array,
    }, // Array of saved tags
    updateAttribute: Function, // Function for update tags
    authorizedContact: Boolean, // Boolean from the parent to authorize users for edit tags
  },
  data() {
    return {
      newTag: null,
    };
  },
  computed: {
    tagsArray: {
      get() {
        return this.tags ? this.tags : [];
      },
      set(value) {
        this.updateAttribute("tags", value);
      },
    },
  },
  methods: {
    addTag() {
      let clonedArr = [...this.tagsArray];
      if (this.newTag) {
        if (this.tagsArray.indexOf(this.newTag) === -1) {
          clonedArr.push(this.newTag);
          this.tagsArray = [...clonedArr];
        } else {
          true;
        }
        this.newTag = null;
      }
    },
    selectTag(item) {
      item;
    },
  },
};
</script>
<style scoped>
.arrowRight {
  width: 27px !important;
  height: 27px !important;
  min-width: 27px !important;
  margin-bottom: 6px;
}

.tagsMenu {
  background-color: white !important;
}

.virtualItem {
  cursor: pointer;
}

.virtualItem:hover {
  background: #edededba;
}
</style>