<template>
  <v-col class="pr-0">
    <v-row
      class="mt-3 mb-0 listHeader fontOrange"
      :class="mobileBreakpoint == true ? 'textInterBold' : 'textDecorationBold'"
    >
      {{ $t("assignmentInformation") }}
    </v-row>
    <v-row class="mb-0">
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        class="pl-0 pr-0"
        v-if="true == checkTopShow(0)"
      >
        <v-row>
          <v-col class="listHeader textInterBold" align="left">
            {{ $t("propertyConsultant") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-0 pr-0"
            align="left"
            @click="() => avatarClicked(data.agentId)"
          >
            <AvatarField
              class="cursor-pointer"
              :size="72"
              :data="
                data.AgentPhoto
                  ? data.AgentPhoto
                  : (data.AgentName && data.AgentName.charAt(0)
                      ? data.AgentName.charAt(0)
                      : '') +
                    (data.AgentSurName && data.AgentSurName.charAt(0)
                      ? data.AgentSurName.charAt(0)
                      : '')
              "
              :isImg="data.AgentPhoto ? true : false"
              backgroundColor="#77BD86"
              :showName="true"
              :nameContent="
                (data.AgentName ? data.AgentName : '') +
                ' ' +
                (data.AgentSurName ? data.AgentSurName : '')
              "
              :showRating="true"
              :ratingData="0.0"
              :showSubContent="true"
              :subContent="this.data.Company"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        class="pl-0 pr-0"
        v-if="true == checkTopShow(1) && isAuthorized('viewAll', authorizedData) == true"
      >
        <v-row>
          <v-col class="listHeader textInterBold" align="left">
            {{ $t("owner") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-0 pr-0"
            align="left"
            @click="() => avatarClicked(data.ClientID)"
          >
            <AvatarField
              class="cursor-pointer"
              :size="72"
              :data="
                data.ClientPhoto
                  ? data.ClientPhoto
                  : (data.ClientName && data.ClientName.charAt(0)
                      ? data.ClientName.charAt(0)
                      : '') +
                    (data.ClientSurName && data.ClientSurName.charAt(0)
                      ? data.ClientSurName.charAt(0)
                      : '')
              "
              :isImg="data.ClientPhoto ? true : false"
              backgroundColor="#77BD86"
              :showName="true"
              :nameContent="
                (data.ClientName ? data.ClientName : '') +
                ' ' +
                (data.ClientSurName ? data.ClientSurName : '')
              "
              :showSubContent="true"
              :showIcon="false"
              :iconObj="{
                icon: 'eva:alert-circle-outline',
                height: 20,
                attr: 'outline',
                color: 'red',
              }"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        class="pl-0 pr-0"
        v-if="true == checkTopShow(2) && isAuthorized('viewAll', authorizedData) == true"
      >
        <v-row>
          <v-col class="listHeader textInterBold" align="left">
            {{ $t("buyer") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-0 pr-0"
            align="left"
            @click="() => avatarClicked(data.BuyersID)"
          >
            >
            <AvatarField
              class="cursor-pointer"
              :size="72"
              :data="
                data.BuyersPhoto
                  ? data.BuyersPhoto
                  : (data.BuyersName && data.BuyersName.charAt(0)
                      ? data.BuyersName.charAt(0)
                      : '') +
                    (data.BuyersSurName && data.BuyersSurName.charAt(0)
                      ? data.BuyersSurName.charAt(0)
                      : '')
              "
              :isImg="data.BuyersPhoto ? true : false"
              backgroundColor="#77BD86"
              :showName="true"
              :nameContent="
                (data.BuyersName ? data.BuyersName : '') +
                ' ' +
                (data.BuyersSurName ? data.BuyersSurName : '')
              "
              :showSubContent="true"
              :subContent="$t('condition') + ':' + data.Status"
              :showDealInfo="false"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        class="pl-0 pr-0"
        v-if="true == checkTopShow(3) && isAuthorized('viewAll', authorizedData) == true"
      >
        <v-row>
          <v-col class="listHeader textInterBold" align="left">
            {{ $t("lawyer") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-0 pr-0"
            align="left"
            @click="() => avatarClicked(data.LawyersID)"
          >
            <AvatarField
              class="cursor-pointer"
              :size="72"
              :data="
                data.LawyersPhoto
                  ? data.LawyersPhoto
                  : (data.LawyersName && data.LawyersName.charAt(0)
                      ? data.LawyersName.charAt(0)
                      : '') +
                    (data.LawyersSurName && data.LawyersSurName.charAt(0)
                      ? data.LawyersSurName.charAt(0)
                      : '')
              "
              :isImg="data.LawyersPhoto ? true : false"
              backgroundColor="#77BD86"
              :showName="true"
              :nameContent="
                (data.LawyersName ? data.LawyersName : '') +
                ' ' +
                (data.LawyersSurName ? data.LawyersSurName : '')
              "
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        class="pl-0 pr-0"
        v-if="true == checkTopShow(4) && isAuthorized('viewAll', authorizedData) == true"
      >
        <v-row>
          <v-col class="listHeader textInterBold" align="left">
            {{ $t("notary") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-0 pr-0"
            align="left"
            @click="() => avatarClicked(data.NotarysID)"
          >
            <AvatarField
              class="cursor-pointer"
              :size="72"
              :data="
                ![undefined, '', null].includes(data.NotarysPhoto)
                  ? data.NotarysPhoto
                  : (data.NotarysName && data.NotarysName.charAt(0)
                      ? data.NotarysName.charAt(0)
                      : '') +
                    (data.NotarysSurName && data.NotarysSurName.charAt(0)
                      ? data.NotarysSurName.charAt(0)
                      : '')
              "
              :isImg="data.NotarysPhoto ? true : false"
              backgroundColor="#77BD86"
              :showName="true"
              :nameContent="
                (data.NotarysName ? data.NotarysName : '') +
                ' ' +
                (data.NotarysSurName ? data.NotarysSurName : '')
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="mobileBreakpoint == true && topShow.showMore != undefined && isAuthorized('viewAll', authorizedData) == true"
      class="mt-0"
    >
      <v-col class="pt-0 pb-0">
        <v-btn
          class="primaryColor unsetCapitalize opacity1 textDecorationBold"
          text
          plain
          @click="() => (topShow.showMore = !topShow.showMore)"
        >
          {{
            topShow.showMore == true ? $t("lessPersons") : $t("morePersons")
          }}...
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="mobileBreakpoint == true && isAuthorized('viewAll', authorizedData) == true"
      class="mt-3 mb-0 listHeader fontOrange textInterBold"
    >
      {{ $t("assignmentInformation") }}
    </v-row>
    <v-row v-if="isAuthorized('viewAll', authorizedData) == true">
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('Assignation')"
      >
        <v-row>
          <ReadField
            :label="$t('TypeOfAssignment')"
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :data="data.Assignation"
            dataClass="fontUnderline successColor"
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('RegisterationDay')"
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('listingDate')"
            :data="data.RegisterationDay"
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('UpdatedDay')"
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('renewalDateF')"
            :data="data.UpdatedDay"
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('AssignationEnd')"
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('expirationDate')"
            :data="data.AssignationEnd"
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('UpdatedDay')"
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('lastEdited')"
            :data="data.UpdatedDay"
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="
          mobileBreakpoint != true
            ? true
            : (botShow.showKeys && botShow.showKeys.includes('keys')) ||
              botShow.showMore != false
            ? true
            : false
        "
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('keys')"
            :data="parseBoolean(data.keys) == true ? '' : 'X'"
            :showIcon="parseBoolean(data.keys) == true"
            :iconObj="
              parseBoolean(data.keys) == true
                ? {
                    icon: 'bi:check',
                    height: 28,
                    color: '#77BD86',
                  }
                : {}
            "
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="
          mobileBreakpoint != true
            ? true
            : (botShow.showKeys && botShow.showKeys.includes('pano')) ||
              botShow.showMore != false
            ? true
            : false
        "
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('banner')"
            :data="parseBoolean(data.pano) == true ? '' : 'X'"
            :showIcon="parseBoolean(data.pano) == true"
            :iconObj="
              parseBoolean(data.pano) == true
                ? {
                    icon: 'bi:check',
                    height: 28,
                    color: '#77BD86',
                  }
                : {}
            "
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="
          mobileBreakpoint != true
            ? true
            : (botShow.showKeys && botShow.showKeys.includes('banner')) ||
              botShow.showMore != false
            ? true
            : false
        "
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('bannerAtTheEntrance')"
            :data="parseBoolean(data.banner) == true ? '' : 'X'"
            :showIcon="parseBoolean(data.banner) == true"
            :iconObj="
              parseBoolean(data.banner) == true
                ? {
                    icon: 'bi:check',
                    height: 28,
                    color: '#77BD86',
                  }
                : {}
            "
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="
          mobileBreakpoint != true
            ? true
            : (botShow.showKeys &&
                botShow.showKeys.includes('banners_external')) ||
              botShow.showMore != false
            ? true
            : false
        "
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('bannerOnStreets')"
            :data="parseBoolean(data.banners_external) == true ? '' : 'X'"
            :showIcon="parseBoolean(data.banners_external) == true"
            :iconObj="
              parseBoolean(data.banners_external) == true
                ? {
                    icon: 'bi:check',
                    height: 28,
                    color: '#77BD86',
                  }
                : {}
            "
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('assignCode')"
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('assignmentCode')"
            :data="data.assignCode"
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('EstateCode')"
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('propertyCode')"
            :data="data.EstateCode"
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('Task')"
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="
              data.Category != undefined
                ? data.Category + ' ' + $t('propTo')
                : $t('propTo')
            "
            :data="data.Task"
          />
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xl="2"
        lg="4"
        md="4"
        sm="6"
        xs="6"
        class="pt-4 mt-3 mb-3 pb-4"
        v-if="true == checkBotShow('Status')"
      >
        <v-row>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="$t('adStatus')"
            :data="data.Status"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="mobileBreakpoint == true && botShow.showMore != undefined && isAuthorized('viewAll', authorizedData) == true"
      class="mt-0"
    >
      <v-col class="pt-0 pb-0">
        <v-btn
          class="primaryColor unsetCapitalize opacity1 textDecorationBold"
          text
          plain
          @click="() => (botShow.showMore = !botShow.showMore)"
        >
          {{ botShow.showMore == true ? $t("lessInfo") : $t("moreInfo") }}...
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
 /*
 Name: Assignments
 Description: Component for real estate assignments at the slider

 API Property: 
    data: Object for the data
    authorizedData: Object for the authorized data
    avatarClickedHandler: Function on avatar click

    Components Used:
      ReadField
      AvatarField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
import AvatarField from "@/components/Fields/AvatarField/AvatarField";
import ContactsModel from "@/static/contacts/contacts.js";
import CoreHelper from "@/helpers/Core/core.js";
import AuthHelper from "@/helpers/Core/auth";
import { mapState } from "vuex";

export default {
  name: "Assignments",
  props: {
    data: Object,
    authorizedData: Object,
    avatarClickedHandler: Function,
  },
  components: {
    ReadField,
    AvatarField,
  },
  data() {
    return {
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
      topArray: [
        ["Agent", "AgentName", "AgentSurName"],
        ["Client", "ClientName", "ClientSurName"],
        ["Buyer", "BuyersName", "BuyersSurName"],
        ["Lawyer", "LawyersName", "LawyersSurName"],
        ["Notary", "NotarysName", "NotarysSurName"],
      ],
      topShow: { showKeys: [], showMore: undefined },
      filterValues: ContactsModel.filterValues,
      botArray: [
        "Assignation",
        "RegisterationDay",
        "UpdatedDay",
        "UpdatedDay",
        "AssignationEnd",
        "assignCode",
        "EstateCode",
        "Task",
        "Status",
      ],
      botExtraArray: ["keys", "pano", "banner", "banners_external"],
      botShow: { showKeys: [], showMore: undefined },
    };
  },
  created() {
    if (this.mobileBreakpoint == true) {
      this.topShow = CoreHelper.showDualObjects(this.data, this.topArray, 2);
      this.botShow = CoreHelper.showObjects(
        this.data,
        this.botArray,
        this.botExtraArray,
        4
      );
    }
  },
  computed: {
        ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    avatarClicked(id) {
      this.avatarClickedHandler(id);
    },
    checkTopShow(objIndex) {
      return this.data[this.topArray[objIndex][1]] ||
        this.data[this.topArray[objIndex][2]]
        ? this.mobileBreakpoint != true
          ? true
          : (this.topShow.showKeys &&
              this.topShow.showKeys.includes(this.topArray[objIndex][0])) ||
            this.topShow.showMore != false
          ? true
          : false
        : false;
    },
    checkBotShow(objKey) {
      return this.data[objKey]
        ? this.mobileBreakpoint != true
          ? true
          : (this.botShow.showKeys && this.botShow.showKeys.includes(objKey)) ||
            this.botShow.showMore != false
          ? true
          : false
        : false;
    },
    isAuthorized(action, data) {
      let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
      return AuthHelper.authorization(
        action,
        data[key] ? data[key] : null,
        data["userID"] ? data["userID"] : null
      );
    },
  },
};
</script>
<style scoped>
.listHeader {
  color: #263573;
  font-size: 14px;
}

.fontOrange {
  color: #e89008;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
