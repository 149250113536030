// import AxiosClientToken from "../Client/AxiosClientToken";
import AxiosClient from "../Client/AxiosClient";
// import axios from "axios";
export default {
    pubishSpitogatos(payload) {
        return AxiosClient.post(
            `https://my.newdeal.gr/scripts/spitogatosrevisited.php`,
            JSON.stringify(payload)
        );
    },
    pubishΧΕ(payload) {
        return AxiosClient.post(
            `https://my.newdeal.gr/scripts/send_properties_xe.php`,
            JSON.stringify(payload)
        );
    },

};
