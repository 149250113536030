var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"greyLeftBorder",class:_vm.$vuetify.breakpoint.xsOnly == true ? '' : 'pl-7'},[_c('v-row',[_c('v-col',{staticClass:"font14 primaryColor pl-5",attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.title + " (" + _vm.filesLength + ")")+" ")])],1),(_vm.filesLength > 0)?_c('v-row',[_c('v-col',{staticStyle:{"overflow":"auto","max-height":"300px !important"}},_vm._l((_vm.documentsArray),function(document,index){return _c('v-row',{key:index,staticClass:"justify-space-between",class:{ greyBottomBorder: _vm.checkIfIsLast(index) }},[_c('v-col',{},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{class:!_vm.getSrc(document) ? 'flex-grow-0' : 'flex-grow-0 pb-0'},[(_vm.getSrc(document))?_c('v-img',{staticClass:"iconSize",attrs:{"src":_vm.getSrc(document)}}):_c('span',{staticClass:"itemIcon textDecorationBold iconSize"},[_vm._v(" ."+_vm._s(_vm.getFileType(document).toLowerCase())+" ")])],1),_c('v-col',{staticClass:"mt-3"},[_c('v-row',{staticClass:"textDecorationBold font14 titleColor"},[_c('v-tooltip',{attrs:{"top":"","open-on-hover":_vm.getFileName(document).length > _vm.maxChar},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getFileName(document).slice(0, _vm.maxChar) + (_vm.getFileName(document).length > _vm.maxChar ? ".." : ""))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFileName(document)))])])],1),_c('v-row',{staticClass:"primaryColor fontSize12"},[(_vm.mobileBreakpoint != true && _vm.getFileType(document))?_c('span',{staticClass:"textDecorationBold"},[_vm._v(" "+_vm._s(_vm.getFileType(document))+" | ")]):_vm._e(),(_vm.getFileSize(document))?_c('span',[_vm._v(" "+_vm._s(_vm.getFileSize(document))+" | ")]):_vm._e(),(_vm.getDate(document))?_c('span',[_vm._v(" "+_vm._s(_vm.getDate(document))+" ")]):_vm._e()])],1)],1)],1),_c('v-col',{staticClass:"flex-grow-0"},[_c('v-row',{staticClass:"justify-space-between flex-nowrap"},[(
              document.visibility != 'public' &&
              _vm.mobileBreakpoint != true &&
              _vm.supportUpdate != true
            )?_c('v-col',{staticClass:"pb-0 pl-0 pr-0"},[_c('HintField',{staticClass:"noTextWrap",attrs:{"icon":"mdi-eye-off","color":"#000000","data":_vm.$t('visibleOnlyToYou')}})],1):_vm._e(),(
              document.visibility != 'public' &&
              _vm.mobileBreakpoint == true &&
              _vm.supportUpdate != true
            )?_c('v-col',{staticClass:"mt-4 pl-0"},[_c('Icon',{attrs:{"icon":"mdi:eye-off","height":"19","width":"28","color":"#707070"}})],1):_vm._e(),_c('v-col',{attrs:{"align":"right"}},[_c('v-row',{staticClass:"justify-end flex-nowrap"},[(_vm.supportUpdate == true)?_c('v-col',{staticClass:"pb-0 pt-5 pl-0 pr-0 flex-grow-0"},[_c('v-btn',{staticClass:"flex-grow-0 pl-0 pr-0",attrs:{"plain":"","icon":""},on:{"click":function($event){return _vm.publishDoc(document)}}},[_c('Icon',{staticClass:"primaryColorOnHover",attrs:{"icon":document.visibility == 'public'
                        ? 'bi:eye-fill'
                        : 'mdi:eye-off',"height":"19","width":"28","color":"#707070"}})],1)],1):_vm._e(),(_vm.supportDownload)?_c('v-col',{staticClass:"pb-0 pt-5 pl-0 pr-0 flex-grow-0"},[_c('v-btn',{staticClass:"flex-grow-0 pl-0 pr-0",attrs:{"plain":"","icon":""},on:{"click":function($event){return _vm.downloadDoc(document)}}},[_c('Icon',{staticClass:"primaryColorOnHover",attrs:{"icon":"bxs:download","height":"19","width":"28","color":"#707070"}})],1)],1):_vm._e(),(_vm.supportDelete)?_c('v-col',{staticClass:"pb-0 pt-5 pl-0 pr-0 flex-grow-0"},[_c('v-btn',{staticClass:"flex-grow-0 pl-0 pr-0",attrs:{"plain":"","icon":""},on:{"click":function($event){return _vm.deleteDoc(document)}}},[_c('Icon',{staticClass:"primaryColorOnHover",attrs:{"icon":"ph:trash-light","height":"19","width":"28","color":"#707070"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)}),1)],1):_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"justify-center"},[_c('v-img',{attrs:{"max-width":"50","max-height":"62","src":require('@/assets/documents/emptyFile.png')}})],1),_c('v-row',{staticClass:"mt-4 justify-center textDecorationBold fontSize22"},[_vm._v(" "+_vm._s(_vm.$t("noFilesFound"))+" ")]),_c('v-row',{staticClass:"mt-2 justify-center font15"},[_vm._v(" "+_vm._s(_vm.$t("noFilesUploadedYet"))+" ")])],1)],1),_c('v-row',{staticClass:"mt-5 justify-center"},[(_vm.filesLength > 3)?_c('v-btn',{staticClass:"mb-2 primaryColor unsetCapitalize opacity1",attrs:{"text":"","plain":""},on:{"click":function($event){return _vm.onClickMore()}}},[_vm._v(" "+_vm._s(_vm.moreOrLess)+"... ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }