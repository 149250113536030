var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-6 cursorPointer",attrs:{"min-width":_vm.widthCard,"min-height":_vm.heightCard,"width":_vm.widthCard,"height":_vm.heightCard,"elevation":0,"color":"transparent"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0 flex-grow-0"},[_c('v-card',{staticClass:"rounded-sm pa-0 ma-0",attrs:{"elevation":0}},[_c('v-img',{staticClass:"rounded-md",attrs:{"max-height":_vm.imgHeight,"max-width":_vm.imgWidth,"height":_vm.imgHeight,"width":_vm.imgWidth,"src":_vm.tempImage,"lazy-src":_vm.tempImage}},[_c('v-row',{staticClass:"h70"},[(_vm.vip == true)?_c('v-img',{staticClass:"topLeftLabel",attrs:{"src":require('@/assets/' + _vm.topLeftLabel),"contain":true}},[_vm._v("   ")]):_vm._e()],1)],1)],1)],1),_c('v-col',{staticClass:"pl-2 pt-2 flex-grow-0"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0 text-no-wrap flex-grow-0",attrs:{"align":"left"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.txtLength(_vm.title != undefined ? _vm.title : '') < 22},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font16 pb-0 primaryColor textDecorationBold",attrs:{"align":"left"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textOverFlow( ![undefined, "", null].includes(_vm.title) ? _vm.title : "--", 22 ))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(![undefined, "", null].includes(_vm.title) ? _vm.title : "--")+" ")])])],1)],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0 text-no-wrap flex-grow-0",attrs:{"align":"left"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.txtLength(_vm.subTitle != undefined ? _vm.subTitle : '') < 22},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font14 pb-0 textDecorationBold",attrs:{"align":"left"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textOverFlow( ![undefined, "", null].includes(_vm.subTitle) ? _vm.subTitle : "--", 22 ))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(![undefined, "", null].includes(_vm.subTitle) ? _vm.subTitle : "--")+" ")])])],1)],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0 text-no-wrap flex-grow-0",attrs:{"align":"left"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.txtLength(_vm.description != undefined ? _vm.description : '') < 22},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font14 pb-0",attrs:{"align":"left"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textOverFlow( ![undefined, "", null].includes(_vm.description) ? _vm.description : "--", 22 ))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(![undefined, "", null].includes(_vm.description) ? _vm.description : "--")+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }