
export default {
  state: {
    language: "gr",
    currency: 0,
    surfaceMU: 0,
    distanceMU: 0,
    langChanged: false,
    dialog: false,
  },
  getters: {
    getDialog(state) {
      return state.dialog;
    },
  },
  mutations: {
    setLanguage(state, payload) {
      state.language = payload;
    },
    setCurrency(state, payload) {
      state.currency = payload;
    },
    setSurfaceMU(state, payload) {
      state.surfaceMU = payload;
    },
    setDistanceMU(state, payload) {
      state.distanceMU = payload;
    },
    setDialog(state, payload) {
      state.dialog = payload;
    },
    setLangChanged(state, payload) {
      state.langChanged = payload;
    },
  },
  actions: {
    onSetLanguage({ commit }, payload) {
      commit("setLanguage", payload);
      commit("setLangChanged", true)
    },
    onSetCurrency({ commit }, payload) {
      commit("setCurrency", payload);
    },
    onSetSurfaceMU({ commit }, payload) {
      commit("setSurfaceMU", payload);
    },
    onSetDistanceMU({ commit }, payload) {
      commit("setDistanceMU", payload);
    },
    onSetDialog({ commit }, payload) {
      commit("setDialog", payload);
    },
  },
};
