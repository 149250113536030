<template>
  <v-col>
    <v-row>
      <v-col align="left" class="pb-0">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("accountInfoSecur") }}
        </span>
      </v-col>
    </v-row>
    <v-row class="mx-n12">
      <v-col class="pt-0 px-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row v-if="showCreateAccount">
      <!-- <v-row> -->
      <v-col class="pl-0">
        <AccountEditInfo
          :attributes="data.accountEditInfo"
          :attributeModel="
            pageModel.accountEditInfoModel != undefined
              ? pageModel.accountEditInfoModel
              : []
          "
          :updateAttribute="updateAttribute"
          :validations="validations"
        />
      </v-col>
    </v-row>
    <v-row v-if="showCreateAccount">
      <!-- <v-row> -->
      <v-col class="pl-0">
        <SecurityEditInfo
          :attributes="data.accountEditInfo"
          :attributeModel="
            pageModel.accountEditInfoModel != undefined
              ? pageModel.accountEditInfoModel
              : []
          "
          :updateAttribute="updateAttribute"
          :validations="validations"
          :dataId="data.accountEditInfo._id"
          :openCongratsDialog="openCongratsDialog"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pl-0" v-if="!showCreateAccount">
        <CreateAccount
          :attributes="data.createAccountInfo"
          :attributeModel="
            pageModel.createAccountModel != undefined
              ? pageModel.createAccountModel
              : []
          "
          :updateAttribute="updateAttribute"
          :validations="validations"
        />
      </v-col>
    </v-row>

    <!-- <v-row class="mt-10" ref="actions">
            <Files :documents="getDocuments" :docTypesList="getDocTypeList" :btnText="$t('edit')"
                :btnClickedHandler="filesBtnClicked" :supportUpload="isAuthorized('upload', data) == true"
                :supportDownload="true" :downloadHandler="downloadDocument" :labelContent="$t('documentsMedia')"
                labelClasses="orangeColor fontSize14 text-left" :data="data"
                :excludeObjectKeys='["_id", "estate_id"]' />
        </v-row> -->
    <v-dialog
      v-model="dialog.open"
      v-if="dialog.type == 'openCongrats'"
      :max-width="625"
      :max-height="213"
    >
      <CongratsPopup
        :content="dialog.content"
        :type="dialog.type"
        :title="$t('passwordChange2')"
        :onCloseHandler="closeDialog"
      />
    </v-dialog>
  </v-col>
</template>
  
<script>
/*
 Name:AccountContact
 Description: Component to render the account tab for the security and acount details for the contact

 API Property:
    data: Object for the data
    validations: Object for the validation variables
    updatePropAttribute: Function to update the data
    initSliderInfo: Object to initialize the data info
    
  Components Used:
    AccountEditInfo,
    SecurityEditInfo,
    CongratsPopup,
    CreateAccount,
*/
// import Files from "@/components/Files/Files";
import ContactModel from "@/static/contacts/contacts.js";
import AccountEditInfo from "@/components/Contacts/ContactFields/AccountEditInfo.vue";
import SecurityEditInfo from "@/components/Contacts/ContactFields/SecurityEditInfo.vue";
import AuthHelper from "@/helpers/Core/auth";
import CongratsPopup from "@/components/Popups/CongratsPopup";
import CreateAccount from "@/components/Contacts/ContactFields/CreateAccount.vue";
// import CoreHelper from "@/helpers/Core/core.js";

export default {
  name: "AccountContact",
  props: {
    data: Object,
    validations: Object,
    updatePropAttribute: Function,
    initSliderInfo: Object,
  },
  components: {
    AccountEditInfo,
    SecurityEditInfo,
    CongratsPopup,
    CreateAccount,
    //  Files,
  },
  data() {
    return {
      showCreateAccount: false,
      dialog: {
        type: null,
        open: false,
        content: null,
      },
    };
  },
created(){
if(this.data.createAccountInfo.UserPassword != null){
  this.showCreateAccount = true;
}else{
  this.showCreateAccount = false;
}
},
  computed: {
    pageModel() {
      return ContactModel.createPageModel.filter((m) => {
        // if (m.roles == this.initSliderInfo.role.value) {
        //     return m;
        // }
        return m;
      })[0];
    },
    getDocuments() {
      if (this.data.documents != undefined) {
        if (this.isAuthorized("viewAll", this.data) == true)
          return this.data.documents;
        else {
          let keys = ["marketing", "presentations"];
          let x = {};
          x["_id"] = this.data.documents["_id"];
          x["estate_id"] = this.data.documents.estate_id;
          x["marketing"] = this.data.documents?.marketing;
          x["presentations"] = this.data.documents?.presentations;
          for (let i = 0; i < keys.length; i++) {
            if (x[keys[i]])
              x[keys[i]] = x[keys[i]].filter((f) => {
                if (f.visibility != "private") return f;
              });
          }
          return x;
        }
      }
      return {};
    },
    getDocTypeList() {
      if (this.isAuthorized("viewAll", this.data) == true)
        return this.docTypesList;
      else
        return this.docTypesList.filter((t) => {
          if (t.id == "all" || t.id == "marketing") {
            return t;
          }
        });
    },
  },
  methods: {
    updateAttribute(kind, attribute, value) {
      this.updatePropAttribute(kind, attribute, value);
    },
    openCongratsDialog() {
      this.openDialog("openCongrats", null);
    },
    isAuthorized(action, data) {
      let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
      return AuthHelper.authorization(
        action,
        data[key] ? data[key] : null,
        data["userID"] ? data["userID"] : null
      );
    },
    filesBtnClicked(selectedDocType, docTypesList) {
      this.dialog = {
        open: true,
        type: "upload",
        uploadType: "document",
        data: {
          selectedDocType: selectedDocType,
          docTypesList: docTypesList,
        },
      };
    },
    openDialog(type, content) {
      this.dialog = {
        open: true,
        type: type,
        content: content,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        content: null,
      };
    },
    // async downloadDocument(doc) {
    //     let realFilename = doc.name != undefined ? doc.name : doc.path != undefined ? CoreHelper.getFileNameFromUrl(doc.path) : "";
    //     let filename = doc.path != undefined ? CoreHelper.getFileNameFromUrl(doc.path) : "";
    //     await RealEstateRepo.downloadEstateDocuments(this.data._id, filename, realFilename);
    // },
  },
};
</script>
  