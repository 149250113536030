 <template>
  <v-col align="center" class="pb-2">
    <v-row>
      <v-col class="pt-7" align="left">
        <span class="textFontDecoration font14">{{
          $t("contactEstates")
        }}</span>
      </v-col>
      <v-col>
        <v-row class="justify-end">
          <v-col v-if="isAuthAddEstate" class="flex-grow-0 pr-2">
            <v-btn
              style="height: 38px"
              class="btn-custom unsetCapitalize"
              @click="createBtn"
              color="error"
            >
              {{ $t("addRealEstate") }}
            </v-btn>
          </v-col>
          <v-col class="flex-grow-0">
            <v-select
              :items="pagesList"
              dense
              v-model="options.items"
              color="primary"
              item-color="primary"
              @change="optionsRefresh"
              class="textDecorationBold font14"
              style="max-width: 75px"
              height="35"
              hide-details
              outlined
              :loading="loading"
            >
              <template v-slot:selection="{ item }">
                <v-row>
                  <v-col align="center" class="text-no-wrap">
                    <span> {{ $t(item.text) }} </span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item="{ item }">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col align="center" class="text-no-wrap">
                        <span> {{ $t(item.text) }} </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row v-if="loading || dataFoundStatus != 'true'" justify="center">
          <LoaderGif v-if="loading" :white="true" />
          <NoData v-else-if="this.dataFoundStatus == 'false'" />
          <ServerProblem v-else-if="this.dataFoundStatus == 'error'" />
        </v-row>
        <v-row v-else>
          <RealEstateCard
            v-for="d in data"
            :class="d['_id'] == estateOpened ? ' estateOpened' : ''"
            :id="d._id"
            :key="d.EstateCode"
            :image="d.MainPhoto ? d.MainPhoto : ''"
            :title="d.Type"
            :unit="d.Unit"
            :price="d.Price"
            :location="d.Location"
            :code="d.EstateCode"
            :comments="d.comments ? d.comments : 0"
            :rating="d.reviews ? d.reviews : []"
            :status="d.Status"
            :typeExtraContent="d.Assignation"
            :typeName="d.Task"
            :openRealEstateSliderHandler="() => openRealEstateSlider(d)"
            :openRealEstateEditHandler="() => onEdit(d)"
            :onDelete="() => onDelete(d)"
            :approvalActionHandler="changeStatus"
            :isApproved="d.IsApproved"
            :prices="d.prices"
            :booleanVip="
              d.characteristics && parseBoolean(d.characteristics.vip)
            "
            :RegisterationDay="d.RegisterationDay ? d.RegisterationDay : ''"
            :authObj="{
              UserID: d.UserID,
              CustomerID: d.CustomerID,
              Company: d.Company,
            }"
          />
        </v-row>
        <v-row class="pb-9">
          <v-col
            cols="12"
            align="left"
            class="pl-0 pr-0 pb-0"
            :class="mobileBreakpoint == true ? '' : 'flex-grow-0'"
          >
            <v-pagination
              class="main-pgt"
              v-model="options.page"
              :total-visible="6"
              @input="fetchRealEstate"
              :length="totalPages"
            >
            </v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <CreateRealEstateCard
      @createNewContact="openContactModal"
      @close="closeDialogSimple"
      :onCreate="onCreateEstate"
      :ownerObject="ownerObject"
      v-if="dialog.type == 'create' && dialog.open == true"
    />
    <RealEstateSlider
      v-if="dialog.type == 'card'"
      :data="dialog.data"
      :onEdit="onEdit"
      :openMediaSlider="openDialog"
      :fetchContactById="fetchContactById"
      :uploadSuccessHandler="uploadDocSuccess"
      :updateEstate="updateEstate"
      :refreshEstate="fetchSpecificEstate"
      :onHandleDelete="onDelete"
      @close="closeDialogSimple"
    />
    <MediaSlider
      v-if="dialog.type == 'media'"
      :data="dialog.data"
      :drawer="dialog.open"
      :close="openDialog"
      :onEdit="openDialog"
    />
    <EditRealEstateCard
      :onCloseSimple="closeDialogSimple"
      :onClose="closeDialogEdit"
      :refreshEstate="fetchSpecificEstate"
      :data="dialog.data"
      :onView="openRealEstateSlider"
      :updateEstate="updateEstate"
      :uploadDocSuccessHandler="uploadDocSuccess"
      :updateDocSuccessHandler="updateDocSuccess"
      :deleteDocSuccessHandler="deleteDocSuccess"
      v-if="dialog.type == 'edit'"
    />
    <ContactSlider
      v-if="dialog.type == 'profileSlider' && dialog.open"
      :data="personalData[0]"
      :editProfile="true"
      :closeHandler="() => (dialog.type = 'card')"
      :updateIsSavedHandler="() => true"
      :openEditSlider="() => (dialog.type = 'editContactSlider')"
    />
    <v-dialog
      v-model="deleteDialog.open"
      v-if="deleteDialog.type == 'delete'"
      width="500"
    >
      <DeletePopup
        :onRemove="onDeleteEstate"
        :onCancel="closeDeleteDialog"
        cancelText="Ακύρωση"
        deleteText="Ναι, είμαι σίγουρος"
        :content="deleteDialog.content"
        :item="deleteDialog.data"
        :loading="deleteLoading"
      />
    </v-dialog>
    <v-dialog v-model="messageDialog.open" width="500">
      <PopupMessage
        :content="messageDialog.content"
        :type="messageDialog.type"
      />
    </v-dialog>
    <v-row>
      <v-col class="pt-4">
        <v-footer
          inset
          padless
          absolute
          style="background-color: white"
          v-if="data.length > 0"
          :class="mobileBreakpoint == true ? 'pb-10' : ''"
        >
          <v-row class="mb-0" style="max-width: 100% !important">
            <v-col align="left" class="main-pgt pt-4 footerCl">
              {{ $t("footerP1") }} {{ options.page ? options.page : 0 }}
              {{ $t("footerP2") }} {{ options.items ? options.items : 0 }}
              {{ $t("footerP3") }} {{ totalResult ? totalResult : 0 }}
              {{ $t("footerP4") }}
            </v-col>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>
  </v-col>
</template>
 
<script>
/*
 Name:CustomerEstates
 Description: Component to render costumer estates

  API Property:
    customerData: Object with costumer data
    pagesList: Array with display count options

    Components Used:
      RealEstateCard
      LoaderGif
      CreateRealEstateCard
      RealEstateSlider
      MediaSlider
      EditRealEstateCard
      ContactSlider
      DeletePopup
      PopupMessage
      ServerProblem
      NoData

    Endpoints Functions Called:
      -RealEstateRepo
        update
        delete
        getId
        get

      -ContactRepo
        get
*/
// import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
import CreateRealEstateCard from "@/components/RealEstate/CreateRealEstateCard.vue";
import RealEstateCard from "@/components/RealEstate/RealEstateCard.vue";
import RealEstateSlider from "@/components/RealEstate/RealEstateSlider/RealEstateSlider.vue";
import LoaderGif from "@/components/Popups/LoaderGif.vue";
import realEstateData from "@/static/realEstate/realEstate";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import MediaSlider from "@/components/RealEstate/MediaSlider";
import EditRealEstateCard from "@/components/RealEstate/EditRealEstateCard.vue";
import ContactSlider from "@/components/Contacts/ContactSlider/ContactSlider";
import CoreHelper from "@/helpers/Core/core";
import Repository from "@/services/Repository";
import DeletePopup from "@/components/Popups/DeletePopup.vue";
import PopupMessage from "@/components/Popups/PopupMessage.vue";
import ServerProblem from "@/components/Popups/ServerProblem.vue";
import NoData from "@/components/Popups/NoData.vue";
import { mapState } from "vuex";
const ContactRepo = Repository.get("contacts");
const RealEstateRepo = Repository.get("realEstate");

export default {
  name: "CustomerEstates",
  components: {
    RealEstateCard,
    LoaderGif,
    CreateRealEstateCard,
    RealEstateSlider,
    MediaSlider,
    EditRealEstateCard,
    ContactSlider,
    DeletePopup,
    PopupMessage,
    ServerProblem,
    NoData,
  },
  props: {
    customerData: Object,
    pagesList: {
      type: Array,
      default: () => [
        {
          id: 0,
          text: "3",
          value: 3,
        },
        {
          id: 1,
          text: "5",
          value: 5,
        },
        {
          id: 2,
          text: "10",
          value: 10,
        },
      ],
    },
  },
  async created() {
    await this.fetchRealEstate();
    this.ownerObject = {
      _id: this.customerData._id,
      mobile_phone_number: this.customerData.Mobile,
      name: this.customerData.title,
    };
  },
  data() {
    return {
      headers: realEstateData.headers,
      data: [],
      ownerObject: null,
      personalData: null,
      deleteDialog: {
        open: false,
        data: null,
        type: null,
        content: null,
      },
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      options: {
        page: 1,
        items: 3,
      },
      messageDialog: {
        type: null,
        open: false,
        content: null,
      },
      deleteLoading: false,
      dataFoundStatus: null,
      totalResult: 0,
      totalPages: 0,
      contactDialogStatus: false,
      loading: false,
      estateOpened: null,
    };
  },

  watch: {
    mobileBreakpoint() {
      if (this.mobileBreakpoint == true) this.viewChoice = 0;
    },
  },

  computed: {
    ...mapState({
      realEstateFilter: (state) => state.realEstate,
      userDetails: (state) => state.auth.userDetails,
    }),
    getReportHeight() {
      return this.windowHeight - this.height - 90;
    },
    sort() {
      let lSort = this.headers.filter((f) => {
        return f.sort;
      });
      return lSort.length == 0 ? [{}] : lSort;
    },
    filterEstate: {
      get() {
        return this.realEstateFilter.filterEstate;
      },
      set(value) {
        this.onSetFilterEstate(value);
      },
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isAuthAddEstate() {
      return (
        this.userDetails &&
        this.userDetails.Type &&
        this.userDetails.Type.type.length > 0 &&
        this.userDetails.Type.includes("Συνεργάτης")
      );
    },
  },
  methods: {
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    openEmail() {
      window.location.href = "mailto:";
    },
    async optionsRefresh() {
      this.options.page = 1;
      await this.fetchRealEstate();
    },
    async fetchContactById(id) {
      let object = { _id: id };
      let params;
      params = {
        page: 0,
        items: 1,
      };
      var resp = await ContactRepo.get(object, params);
      if (resp.data != undefined && resp.data.contacts != undefined) {
        if (resp.data.contacts.length > 0) {
          this.personalData = resp.data.contacts.map((c) => {
            return ContactsHelper.initDataContact(c);
          });
        }
      }
      this.dialog.type = "profileSlider";
    },
    async changeStatus(id, action) {
      let body = {};
      body["Status"] = action ? "Ενεργό" : "Μη Ενεργό";
      body["IsApproved"] = true;
      await RealEstateRepo.update(id, body);
      await this.fetchRealEstate();
    },
    async onDeleteEstate(item) {
      try {
        this.deleteLoading = true;
        await RealEstateRepo.delete(item["_id"]);
        this.fetchRealEstate();
        this.deleteLoading = false;
      } catch (e) {
        this.deleteLoading = false;
        throw e;
      }
      this.closeDeleteDialog();
      this.openMessageDialog(
        "success",
        `Μόλις διαγράψατε το ακίνητο με κωδικό ${item.EstateCode}`
      );
      setTimeout(() => {
        this.closeMessageDialog();
      }, 2400);
    },
    async fetchRealEstate() {
      this.loading = true;
      try {
        let object = { $and: { CustomerID: this.customerData._id } };

        this.realEstateFilter.refreshPage ? (this.options.page = 1) : null;
        let params;
        if (Object.keys(this.sort[0]).length === 0) {
          params = {
            page: this.options.page - 1,
            items: this.options.items,
          };
        } else {
          let lSort = await CoreHelper.customStringify(
            this.sort[0].value,
            this.sort[0].sort == "asc" ? 1 : -1 /* 1 Asc, -1 Desc */
          );
          params = {
            page: this.options.page - 1,
            items: this.options.items,
            sort: lSort,
          };
        }

        var resp = await RealEstateRepo.get(object, params);
        this.data =
          resp == undefined ||
          resp.data == undefined ||
          resp.data.estates == undefined
            ? []
            : resp.data.estates;
        if (this.data.length > 0) {
          this.dataFoundStatus = "true";
          this.totalResult = resp.data.results;
          this.totalPages = Math.ceil(this.totalResult / this.options.items);
          this.data.map((m) => {
            if (m.floors && m.floors.length > 0) {
              m.floors.map((mf) => {
                if (![null, undefined, ""].includes(mf.FloorLiteral)) return mf;
                else if (![null, undefined, ""].includes(mf.FloorValue)) {
                  mf.FloorLiteral =
                    this.$store.getters.getFloorDescriptionByVal(
                      Number(mf.FloorValue)
                    );
                } else if (![null, undefined, ""].includes(mf.Value)) {
                  mf.FloorLiteral =
                    this.$store.getters.getFloorDescriptionByVal(
                      Number(mf.Value)
                    );
                } else if (!isNaN(mf)) {
                  mf = {
                    FloorLiteral: this.$store.getters.getFloorDescriptionByVal(
                      Number(mf)
                    ),
                    FloorValue: mf,
                  };
                }
                return mf;
              });
            }
          });
        } else {
          this.dataFoundStatus = "false";
          this.totalResult = 0;
          this.totalPages = 0;
        }
        if (this.realEstateFilter.refreshPage) this.onSetRefreshPage(false);
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "error";
        this.data = [];
        this.loading = false;
        this.totalResult = 0;
        this.totalPages = 0;
        if (this.realEstateFilter.refreshPage) this.onSetRefreshPage(false);
        throw e;
      }
    },

    openMessageDialog(type, content) {
      this.messageDialog = {
        open: true,
        type: type,
        content: content,
      };
    },
    closeMessageDialog() {
      this.messageDialog = {
        open: false,
        type: null,
        content: null,
      };
    },
    uploadDocSuccess(estateId, docTypeProp, objVal) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      if (index != -1) {
        if (this.data[index]["documents"] == undefined) {
          this.data[index] = {
            ...this.data[index],
            documents: {},
          };
        }
        if (this.data[index]["documents"][docTypeProp])
          this.data[index]["documents"][docTypeProp].push(objVal);
        else {
          let newObj = {};
          newObj[docTypeProp] = [objVal];
          this.data[index]["documents"] = {
            ...this.data[index]["documents"],
            ...newObj,
          };
          this.dialog.data = {
            ...this.dialog.data,
            documents: this.data[index]["documents"],
          };
        }
      }
    },
    updateEstate(estateId, objProp, objVal) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      if (index != -1) {
        this.data[index][objProp] = objVal;
        try {
          this.dialog.data = {
            ...this.dialog.data,
            [objProp]: objVal,
          };
        } catch (err) {
          console.error(err);
        }
      }
    },
    updateDocSuccess(estateId, docTypeProp, objIndx, objVal) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      if (
        index != -1 &&
        this.data[index]["documents"][docTypeProp] != undefined
      )
        this.data[index]["documents"][docTypeProp][objIndx].visibility =
          objVal.visibility;
    },
    deleteDocSuccess(estateId, docTypeProp, objIndx) {
      const index = this.data.findIndex((d) => {
        return d._id === estateId;
      });
      this.data[index]["documents"][docTypeProp].splice(objIndx, 1);
      this.dialog.data = {
        ...this.dialog.data,
        documents: this.data[index]["documents"],
      };
    },

    async fetchSpecificEstate(id, action) {
      let resp = await RealEstateRepo.getId(id);
      if (resp && resp.data && resp.data.estates) {
        if (action == "add") this.onEdit(resp.data.estates[0]);
        const index = this.data.findIndex((d) => {
          return d._id === resp.data.estates[0]?._id;
        });
        if (index != -1) {
          this.data[index] = resp.data.estates[0];
          this.dialog.data = this.data[index];
        } else {
          this.data.unshift(resp.data.estates[0]);
          this.dataFoundStatus = "true";
        }
      }
    },
    onDelete(item) {
      let content = `Πρόκειται να διαγράψετε το ακίνητο με κωδικό ${item.EstateCode}`;
      this.openDeleteDialog("delete", item, content);
    },

    openDeleteDialog(type, data, content) {
      this.deleteDialog = {
        open: true,
        type: type,
        data: data,
        content: content,
      };
    },
    closeDialogEdit(data) {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
      this.openDialog("card", data);
    },

    closeDialogSimple() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    closeDialog(type, id) {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
      if (type == "genericSearch") this.fetchRealEstate();
      else if (type == "specificSearch" && id != undefined && id != null)
        this.fetchSpecificEstate(id);
    },
    closeDeleteDialog() {
      this.deleteDialog = {
        open: false,
        type: null,
        data: null,
        content: null,
      };
    },
    async onCreateEstate(id) {
      if (id) {
        await this.closeDialogSimple();
        await this.fetchSpecificEstate(id, "add");
      }
    },
    createBtn() {
      this.openDialog("create", null);
    },
    onEdit(item) {
      this.estateOpened = item["_id"] ? item["_id"] : null;
      this.openDialog("edit", item);
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    openRealEstateSlider(data) {
      this.estateOpened = data["_id"] ? data["_id"] : null;
      this.openDialog("card", data);
    },
    openContactModal() {
      this.contactDialogStatus = true;
    },
    closeContactModal() {
      this.contactDialogStatus = false;
    },
  },
};
</script>
<style scoped>
</style>