<template>
  <v-navigation-drawer
    width="1300"
    right
    v-model="show"
    app
    temporary
    :permanent="mobileBreakpoint == true"
  >
    <v-col
      v-if="mobileBreakpoint != true"
      class="pb-0"
      style="z-index: 99999"
      align="right"
    >
      <v-icon @click="closeSimpleHandler" class="primaryColorOnHover xClass">
        mdi-close
      </v-icon>
    </v-col>
    <v-col class="masterEditSliderContainer">
      <v-row>
        <v-col>
          <v-row class="justify-between headerArea">
            <RealEstateHeader
              v-if="mobileBreakpoint != true"
              class="sliderHeader"
              :title="
                data.Category
                  ? data.Category + ' ' + $t('propTo') + ' ' + data.Task
                  : $t('propTo') + ' ' + data.Task
              "
              :isVip="
                data.characteristics && parseBoolean(data.characteristics.vip)
              "
              :subtitle="
                this.data.Location != undefined
                  ? this.data.Location
                  : this.data.Address != undefined
                  ? this.data.Address
                  : ''
              "
              :rating="avgReviewsRating.revAvg"
              :ratingCount="avgReviewsRating.revCnt"
              :bedrooms="
                this.data.characteristics && this.data.characteristics.bedrooms
                  ? this.data.characteristics.bedrooms
                  : 0
              "
              :bathrooms="
                this.data.characteristics && this.data.characteristics.bathrooms
                  ? this.data.characteristics.bathrooms
                  : 0
              "
              :size="this.data.Unit"
              :constructionYear="this.data.BuildYear"
              :floor="
                this.data.floors && this.data.floors.length > 0
                  ? displayFloors
                  : null
              "
            />
            <v-col class="headerRightArea pt-5">
              <v-row class="headerRightSubArea" v-if="mobileBreakpoint != true">
                <v-col class="pb-0 font18 textInterSemiBold blueishText">
                  <span> {{ data.EstateCode }} </span>
                </v-col>
              </v-row>
              <v-row
                class="headerRightSubArea text-no-wrap font24"
                v-if="mobileBreakpoint != true"
              >
                <v-col class="pt-0 textInterSemiBold primaryColor">
                  <span> {{ data.Price }} </span>
                </v-col>
              </v-row>
              <v-row class="headerRightSubArea">
                <v-col
                  v-if="mobileBreakpoint == true"
                  class="pb-0 pt-1"
                  style="z-index: 99999"
                  align="left"
                >
                  <v-icon
                    @click="closeSimpleHandler"
                    class="primaryColorOnHover primaryColor"
                  >
                    mdi-close
                  </v-icon>
                </v-col>
                <v-col
                  align="center"
                  class="flex-grow-0 pt-0"
                  v-if="isAuthorized('edit', data) == true && editEstate"
                >
                  <v-btn
                    elevation="1"
                    align="center"
                    class="btn"
                    color="error"
                    @click="onEditEstate"
                  >
                    <v-icon
                      v-if="smAndDownBreakpoint == true"
                      class="primaryColorOnHover"
                    >
                      mdi-pencil-outline
                    </v-icon>
                    <span v-else> {{ $t("processingOfProperty") }} </span>
                  </v-btn>
                </v-col>
                <v-col
                  class="flex-grow-0 pt-0"
                  align="center"
                  :class="smAndDownBreakpoint == true ? 'pl-0' : ''"
                  v-if="isAuthorized('delete', data) == true && deleteEstate"
                >
                  <!-- <v-btn
                    class="ma-1 btn"
                    elevation="1"
                    outlined
                    @click="openCooperation"
                    :loading="loadingRequest"
                    v-if="userDetails.Role != 'Admin'"
                  >
                    {{ $t("requestForCooperation") }}
                  </v-btn> -->
                  <v-btn
                    class="btn"
                    elevation="1"
                    outlined
                    @click="onDeleteEstate"
                  >
                    <v-icon
                      v-if="smAndDownBreakpoint == true"
                      class="primaryColorOnHover"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                    <span v-else> {{ $t("delete") }} </span>
                  </v-btn>
                  <!-- <v-btn
                    elevation="1"
                    align="center"
                    class="ma-1 btn"
                    color="error"
                    @click="zktest"
                  >
                    ZK TEST
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="mobileBreakpoint == true" class="align-center flex-nowrap">
        <v-col
          class="pb-0 flex-grow-0 text-no-wrap IntertextInterSemiBoldSemiBold font14"
          align="left"
        >
          <span class="blueishText"> {{ $t("views") }}: </span>
          <span class="fontOrangeOnly">
            {{ data.uniqueviews ? data.uniqueviews : 0 }}
          </span>
        </v-col>
        <v-col
          class="pb-0 flex-grow-0 text-no-wrap textInterSemiBold font14"
          align="left"
        >
          <span class="blueishText"> {{ $t("stores") }}: </span>
          <span class="fontOrangeOnly">
            {{ data.save ? data.save : 0 }}
          </span>
        </v-col>
        <v-col class="flex-grow-0 pb-0 pr-0">
          <v-btn icon class="primaryColorOnHover">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="20"
              height="21"
              viewBox="0 0 20 21"
            >
              <image
                id="share"
                width="20"
                height="21"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAABHNCSVQICAgIfAhkiAAAAW9JREFUOE+tlc0rBVEYh40slHyWhZSVbrEVOysLSylkYWFpcUuy8VFcFqwo/AVKQlGU1U3dlZIkKWV3V6wkZaF8Pr86U+dqDuee5tTTnTlz3mfOO+87c6NM93RFwKghZhmGoRauYBYuowBhJYEF6P21kXfNhQgHCTxyZHUWIlwx6SU5X0KEU5jWHTsslivsQ7QFHQ5hzlfYhmANhuAQTs0uGyzxLsfjsbCKkxHoglfYhzuoBvXVPBRhEvJG0shvP0h6Adeal7DOLOqx7vbJ8SYMQDOo5zZArfHnkFDPJOtYdcL8BDz+J4qvS/jASYsjYI75VV9ZnPITB02OoEWTrrdTO1TXq/uTxj2TOTiALx+rhO0sPAc9fHuo0m8wBrewBMfwbRapuqqyql1SZV1vhRlQpZ9hD7ZNsG6oKo/CjRF/8LsD9dYOSvrQJ5tOFi2A+jVyBHi/KXa8pEo/aZT9LkuS+tcm9e9h6l9spe38T/kBf8tWiDAk0IAAAAAASUVORK5CYII="
              />
            </svg>
          </v-btn>
        </v-col>
        <v-col class="flex-grow-0 pr-0 pb-0">
          <v-btn @click="addEstateToFavorites" icon>
            <v-icon color="red" v-if="isSave"> mdi-cards-heart </v-icon>
            <v-icon v-else class="primaryColorOnHover">
              mdi-cards-heart-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        :class="mobileBreakpoint != true ? 'mr-n12' : 'mobileImgContainer'"
      >
        <ImageContainerField
          :imgs="photos"
          :mainPhoto="data.MainPhoto ? data.MainPhoto : ''"
          :displayType="
            !data.MainPhoto || photos.length == 0
              ? 0
              : photos.length == 1 && mobileBreakpoint != true
              ? 1
              : 2
          "
          :onClickPhoto="
            !data.MainPhoto || photos.length == 0
              ? null
              : () => openMedia('media', data)
          "
          :supportUpload="isAuthorized('upload', data) == true"
          :uploadHandler="uploadPhotoOpen"
          :isUploadingImg="isUploadingImg"
          :class="mobileBreakpoint != true ? '' : 'pl-0 pr-0'"
        />
      </v-row>
      <v-row v-if="mobileBreakpoint == true">
        <v-col class="pl-0">
          <RealEstateHeaderMobile
            class="sliderHeader"
            :title="
              data.Category
                ? data.Category + ' ' + $t('propTo') + ' ' + data.Task
                : $t('propTo') + ' ' + data.Task
            "
            :code="data.EstateCode ? data.EstateCode : ''"
            :price="data.Price ? data.Price : ''"
            :isVip="
              data.characteristics && parseBoolean(data.characteristics.vip)
            "
            :subtitle="
              data.Location ? data.Location : data.Address ? data.Address : ''
            "
            :rating="avgReviewsRating.revAvg"
            :ratingCount="avgReviewsRating.revCnt"
            :bedrooms="
              data.characteristics && data.characteristics.bedrooms
                ? data.characteristics.bedrooms
                : 0
            "
            :bathrooms="
              data.characteristics && data.characteristics.bathrooms
                ? data.characteristics.bathrooms
                : 0
            "
            :size="data.Unit"
            :constructionYear="data.BuildYear"
            :floor="
              data.floors && data.floors.length > 0
                ? $t(data.floors[0].FloorLiteral)
                : null
            "
          />
        </v-col>
      </v-row>
      <v-row class="mt-2" v-if="mobileBreakpoint == true">
        <v-col
          class="flex-grow-0 pl-0"
          v-if="isAuthorized('requestCoopetration', data) == true"
        >
          <v-btn
            class="blueBtn"
            color="priamry"
            elevation="1"
            outlined
            @click="openCooperation"
            :loading="loadingRequest"
          >
            {{ $t("requestForCooperation") }}
          </v-btn>
        </v-col>
        <v-col
          class="flex-grow-0 pl-0"
          v-if="isAuthorized('viewAll', data) == true"
        >
          <v-btn class="blackBtn" elevation="1" outlined>
            <v-icon right dark class="pr-5"> mdi-message-reply-text </v-icon>
            {{ this.unreadReq }}
          </v-btn>
        </v-col>
        <!-- <v-col class="flex-grow-0 pl-0">
          <v-btn @click="sendEmail" class="btn" elevation="1" outlined>
            {{ $t("forwardByEmail") }}
          </v-btn>
        </v-col>
        <v-col class="flex-grow-0 pl-0">
          <v-btn class="btn" elevation="1" outlined>
            {{ $t("presentation") }}
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row class="mt-6">
        <v-col
          class="flex-grow-0 pl-0"
          :class="mobileBreakpoint == true ? 'pb-0' : ''"
        >
          <v-row class="flex-nowrap">
            <v-col class="pl-0">
              <v-btn-toggle v-model="headerTags" flex c style="overflow: auto">
                <v-btn
                  value="description"
                  class="headerTag"
                  :class="
                    mobileBreakpoint == true
                      ? 'textInterBold'
                      : 'textDecorationBold'
                  "
                  text
                  v-ripple="false"
                  @click="scrollToTag('description')"
                >
                  {{ $t("description") }}
                </v-btn>
                <v-btn
                  value="features"
                  class="headerTag textDecorationBold"
                  text
                  v-ripple="false"
                  @click="scrollToTag('features')"
                  v-if="mobileBreakpoint != true"
                >
                  {{ $t("features") }}
                </v-btn>
                <v-btn
                  value="distances"
                  class="headerTag textDecorationBold"
                  text
                  v-ripple="false"
                  @click="scrollToTag('distances')"
                  v-if="mobileBreakpoint != true"
                >
                  {{ $t("distances") }}
                </v-btn>
                <v-btn
                  value="actions"
                  class="headerTag textDecorationBold"
                  text
                  v-ripple="false"
                  @click="scrollToTag('actions')"
                  v-if="mobileBreakpoint != true"
                >
                  {{ $t("actions") }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-col>
        <v-col align="right" v-if="mobileBreakpoint != true">
          <v-row class="justify-end align-center rightTagsArea">
            <v-col class="flex-grow-0 text-no-wrap">
              {{ $t("views") }}:
              <span class="fontOrangeOnly">
                {{
                  ![null, undefined, ""].includes(data.uniqueviews)
                    ? data.uniqueviews
                    : 0
                }}
              </span>
            </v-col>
            <v-col class="flex-grow-0 text-no-wrap">
              {{ $t("stores") }}:
              <span class="fontOrangeOnly">
                {{ ![null, undefined, ""].includes(data.save) ? data.save : 0 }}
              </span>
            </v-col>
            <v-col class="flex-grow-0">
              <v-btn icon class="primaryColorOnHover">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                >
                  <image
                    id="share"
                    width="20"
                    height="21"
                    xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAABHNCSVQICAgIfAhkiAAAAW9JREFUOE+tlc0rBVEYh40slHyWhZSVbrEVOysLSylkYWFpcUuy8VFcFqwo/AVKQlGU1U3dlZIkKWV3V6wkZaF8Pr86U+dqDuee5tTTnTlz3mfOO+87c6NM93RFwKghZhmGoRauYBYuowBhJYEF6P21kXfNhQgHCTxyZHUWIlwx6SU5X0KEU5jWHTsslivsQ7QFHQ5hzlfYhmANhuAQTs0uGyzxLsfjsbCKkxHoglfYhzuoBvXVPBRhEvJG0shvP0h6Adeal7DOLOqx7vbJ8SYMQDOo5zZArfHnkFDPJOtYdcL8BDz+J4qvS/jASYsjYI75VV9ZnPITB02OoEWTrrdTO1TXq/uTxj2TOTiALx+rhO0sPAc9fHuo0m8wBrewBMfwbRapuqqyql1SZV1vhRlQpZ9hD7ZNsG6oKo/CjRF/8LsD9dYOSvrQJ5tOFi2A+jVyBHi/KXa8pEo/aZT9LkuS+tcm9e9h6l9spe38T/kBf8tWiDAk0IAAAAAASUVORK5CYII="
                  />
                </svg>
              </v-btn>
            </v-col>
            <v-col class="flex-grow-0">
              <v-btn @click="addEstateToFavorites" icon>
                <v-icon color="red" v-if="isSave"> mdi-cards-heart </v-icon>
                <v-icon v-else class="primaryColorOnHover">
                  mdi-cards-heart-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-divider />
      </v-row>
      <v-row v-if="getDescription" ref="description">
        <v-col
          class="pl-1 pr-1 pb-0 leftTextAlign font14 textInterSemiBold shadyBlue"
          style="line-height: 2"
        >
          <span>
            {{
              mobileBreakpoint != true
                ? getDescription
                : getDescription.length > 200 && showMoreDescription == false
                ? getDescription.slice(0, 200) + "..."
                : getDescription
            }}
          </span>
        </v-col>
      </v-row>
      <v-row
        v-if="
          getDescription &&
          getDescription.length > 200 &&
          mobileBreakpoint == true
        "
      >
        <v-col class="pt-0 pb-0">
          <v-btn
            class="primaryColor unsetCapitalize opacity1 textDecorationBold"
            text
            plain
            @click="() => (showMoreDescription = !showMoreDescription)"
          >
            {{ showMoreDescription == true ? $t("less") : $t("more") }}...
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-2" v-if="mobileBreakpoint != true">
        <v-col
          class="flex-grow-0 pl-0 pr-7"
          v-if="isAuthorized('requestCoopetration', data) == true"
        >
          <v-btn
            class="blueBtn"
            color="priamry"
            elevation="1"
            outlined
            @click="openCooperation"
            :loading="loadingRequest"
          >
            {{ $t("requestForCooperation") }}
          </v-btn>
        </v-col>
        <v-col
          class="flex-grow-0 pl-0 pr-7"
          v-if="isAuthorized('viewAll', data) == true"
        >
          <v-btn class="blackBtn" elevation="1" outlined>
            <v-icon right dark class="pr-5"> mdi-message-reply-text </v-icon>
            {{ this.unreadReq }}
            <!-- {{ $t("unrecognisedRequests") }} -->
          </v-btn>
        </v-col>
        <!-- <v-col class="flex-grow-0 pl-0 pr-7">
          <v-btn @click="sendEmail" class="btn" elevation="1" outlined>
            {{ $t("forwardByEmail") }}
          </v-btn>
        </v-col>
        <v-col class="flex-grow-0 pl-0">
          <v-btn class="btn" elevation="1" outlined>
            {{ $t("presentation") }}
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <Assignments
          :avatarClickedHandler="avatarClicked"
          :data="assignemntsInfo"
          :authorizedData="data"
        />
      </v-row>
      <v-row
        class="mt-10 pt-4"
        :class="smAndDownBreakpoint == true ? 'flex-column-reverse' : ''"
      >
        <v-col align="left" cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
          <v-row
            v-if="
              Object.keys(financialInfo).length > 1 &&
              isAuthorized('viewAll', data) == true
            "
          >
            <v-col>
              <FinancialInfo :data="financialInfo" />
            </v-col>
          </v-row>
          <v-row
            class="mt-10"
            v-if="Object.keys(characteristicsInfo).length != 0"
            ref="features"
          >
            <v-col>
              <Characteristics :data="characteristicsInfo" />
            </v-col>
          </v-row>
          <v-row
            class="mt-10"
            v-if="Object.keys(additionalSpacesInfo).length != 0"
          >
            <v-col>
              <AdditionalSpaces :data="additionalSpacesInfo" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ExtraCharacteristics :data="this.data" />
            </v-col>
          </v-row>
          <v-row
            class="mt-10"
            v-if="
              this.data.prices &&
              this.data.prices.length > 0 &&
              isAuthorized('viewAll', data) == true
            "
          >
            <v-col>
              <HistoryPriceInfo :data="{ prices: this.data.prices }" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="vertDivider flex-grow-0 pa-0"
          cols="1"
          xs="0"
          sm="0"
          md="1"
          lg="1"
          xl="1"
        >
          <v-divider vertical />
        </v-col>
        <v-col
          class="pl-0 pr-0"
          align="left"
          cols="12"
          xs="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-row>
            <v-col class="font14 textDecorationBold fontOrangeOnly">
              <span> Newdeal Network </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="font14 pt-0">
              <span> {{ $t("entryInProvider") }} </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-2 pb-2">
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(provider, i) in providers"
              :key="i"
              cols="6"
              align="left"
            >
              <v-row class="align-center justify-start">
                <v-col class="flex-grow-0" align="left">
                  <v-img size="30" :src="provider.src" />
                </v-col>
                <v-col
                  class="pl-0 align-self-center"
                  align="left"
                  :class="
                    estateProviders.includes(provider.id)
                      ? 'selectedProvider'
                      : ''
                  "
                >
                  <span style="text-transform: none !important">
                    {{ provider.title }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="isAuthorized('edit', data) == true">
            <v-col align="center" class="font14">
              <v-btn
                width="100%"
                max-width="388"
                min-width="260"
                height="40"
                class="btn textDecorationBold"
                elevation="1"
                outlined
                @click="openProviders"
              >
                {{ $t("submissionOfEntryToProvider") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="isAuthorized('edit', data) == true">
            <v-col class="pt-0">
              <VipInfo :estateId="data['_id']" :data="data.vip" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CommentsInfo
                :estateId="data['_id']"
                :data="comments"
                :viewAllAccess="isAuthorized('viewAll', data) == true"
                @fetchEstate="fetchEstate"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="
          this.data.GPS &&
          this.data.GPS.length > 0 &&
          this.data.GPS[0] &&
          this.data.GPS[1]
        "
        class="mt-10"
      >
        <MapInfo :title="$t('theLocationOfTheProperty')" :data="data" />
      </v-row>
      <v-row
        class="mt-10"
        ref="distances"
        v-if="Object.keys(distanceInfo).length > 1"
      >
        <v-col align="left">
          <DistanceInfo :data="distanceInfo" />
        </v-col>
      </v-row>
      <v-row class="mt-10" ref="actions">
        <Files
          :documents="getDocuments"
          :docTypesList="getDocTypeList"
          :btnText="$t('edit')"
          :btnClickedHandler="filesBtnClicked"
          :supportUpload="isAuthorized('upload', data) == true"
          :supportDownload="true"
          :downloadHandler="downloadDocument"
          :labelContent="$t('documentsMedia')"
          labelClasses="orangeColor fontSize14 text-left"
          :data="data"
          :excludeObjectKeys="['_id', 'estate_id']"
        />
      </v-row>
      <v-row class="mr-n7 ml-n7">
        <v-col class="pl-0 pr-0">
          <v-tabs show-arrows="always" v-model="activeTab" class="mt-10">
            <v-tabs-slider style="height: 4px" color="error"> </v-tabs-slider>
            <v-tab class="textTranformUnset bold mr-15">
              <!-- v-if="isAuthorized('viewAll', data) == true" -->
              Matching Leads
            </v-tab>
            <!-- <v-tab class="textTranformUnset bold mr-15" 
          v-if="isAuthorized('viewAll', data) == true"> Υποδείξεις </v-tab>
        <v-tab class="textTranformUnset bold mr-15" 
          v-if="isAuthorized('viewAll', data) == true"> Προσφορές </v-tab>
        <v-tab class="textTranformUnset bold mr-15"> Αξιολογήσεις </v-tab> -->
            <v-tab
              class="textTranformUnset bold mr-15"
              v-if="isAuthorized('viewAll', data) == true"
            >
              {{ $t("collaborations") }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row class="mr-n7 ml-n7">
        <v-col class="pl-0 pr-0 pb-15">
          <v-row
            v-if="loadingTable || dataFoundStatus != 'true'"
            justify="center"
          >
            <LoaderGif v-if="loadingTable" :white="true" />
            <NoData
              v-else-if="this.dataFoundStatus == 'false'"
              :showBtn="false"
            />
            <ServerProblem v-else-if="this.dataFoundStatus == 'error'" />
          </v-row>
          <v-tabs-items v-else v-model="activeTab">
            <v-tab-item>
              <CustomTable
                class="row-height-75 pl-0 pr-0"
                style="overflow-y: auto"
                :headers="reportMatchingHeader"
                :data="matchingData"
                :itemsPerPage="10"
                :totalPages="199"
                :page="1"
                :templates="templatesMatching"
                :loading="loadingTable"
                :supportView="true"
                :handleView="(d) => openDemand(d)"
                :fixedHeader="true"
              />
              <!-- v-if="isAuthorized('viewAll', data) == true" -->
            </v-tab-item>
            <!-- <v-tab-item>
          <CustomTable
          v-if="isAuthorized('viewAll', data) == true"
            :headers="showcasesHeader"
            :data="dataShowcases"
            :itemsPerPage="10"
            :totalPages="199"
            :page="1"
            :templates="templatesMatching"
            :loading="loadingTable"
          />
        </v-tab-item> -->
            <!-- <v-tab-item>
          <CustomTable
          v-if="isAuthorized('viewAll', data) == true"
            :headers="offerHeaders"
            :data="offerData"
            :itemsPerPage="10"
            :totalPages="199"
            :page="1"
            :templates="templatesOffer"
            :supportEdit="true"
            :supportDelete="true"
            :loading="loadingTable"
          />
        </v-tab-item> -->
            <!-- <v-tab-item>
          <v-row class="justify-end mt-10">
            <v-col class="interBold" align="left">
              <span>
                {{ $t("reviewTopMsg") }}
              </span>
            </v-col>
            <v-col class="flex-grow-0">
              <v-row class="justify-end flex-nowrap align-center">
                <v-col>
                  <v-btn
                    class="btn textDecorationBold"
                    height="40"
                    width="172"
                    @click="addReview"
                    color="error"
                  >
                    {{ $t("add") }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-select
                    :items="getItemsList"
                    dense
                    v-model="reviewOptions.items"
                    color="primary"
                    item-color="primary"
                    style="
                      max-width: 75px;
                      font-size: 14px;
                      font-family: CenturyGothicBold;
                    "
                    height="40"
                    @change="fetchReviews"
                    hide-details
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-for="(item, i) in reviews" :key="i">
            <v-col>
              <Review
                :name="item.name"
                :date="item.date"
                :comment="item.comment"
                :visibleToEveryone="item.visibleToEveryone"
                :ratingAverage="item.rating_average"
                :multipleChoice="item.multiple_choice"
                :ratings="item.ratings"
              />
            </v-col>
          </v-row>
        </v-tab-item> -->
            <v-tab-item>
              <CustomTable
                class="row-height-75 pl-0 pr-0"
                style="overflow-y: auto"
                :headers="reportCooperationHeaders"
                :data="dataCooperations"
                :itemsPerPage="10"
                :totalPages="199"
                :page="1"
                :templates="cooperationTemplates"
                :loading="loadingTable"
              />
            </v-tab-item>
            <!-- v-if="isAuthorized('viewAll', data) == true" -->
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row
        class="mt-1"
        v-if="data.personalComments && isAuthorized('viewAll', data) == true"
      >
        <v-col align="left" cols="12">
          <v-row>
            <v-col>
              <v-icon class="bold"> mdi-eye-off </v-icon>
              <span class="bold font14"> {{ $t("notes") }} </span>
            </v-col>
          </v-row>
          <v-divider class="mt-6 mb-6"> </v-divider>
          <v-row>
            <v-col align="left" cols="12" xs="12" sm="12" md="12" lg="12">
              <v-row>
                <!-- <v-col align="left" cols="8" xs="8" sm="8" md="8" lg="8"> -->
                <v-col align="left">
                  <span class="listHeader"> {{ data.personalComments }} </span>
                </v-col>
                <!-- <v-col cols="4" xs="4" sm="4" md="4" lg="4">
                  <v-row>
                    <v-col align="right">
                      <v-btn icon @on:click="console.log('hi')">
                        <v-icon small> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn icon @on:click="console.log('hi')">
                        <v-icon small> mdi-delete-outline </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col align="right">
                      <span class="font10 listHeader">
                        Δημιουργήθηκε 02.01.2022
                      </span>
                    </v-col>
                  </v-row>
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-divider class="ml-8 mr-8"> </v-divider>
      <v-row
        class="mt-1"
        v-if="isAuthorized('personalComments', [data.UserID])"
      >
        <v-col align="left" cols="12" xs="12" sm="12" md="12" lg="12">
          <v-row>
            <v-col align="left" cols="8" xs="8" sm="8" md="8" lg="8">
              <v-textarea
                :label="$t('inputDescription') + '321312'"
                v-model="privateNotes"
                auto-grow
                class="mt-1"
                outlined
                rows="10"
                row-height="15"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="ml-0"
              style="background-color: #e8e8ea; border-radius: 10px"
              align="left"
              cols="8"
              xs="8"
              sm="8"
              md="8"
              lg="8"
            >
              <div>
                <v-icon class="bold mr-1"> mdi-eye-off </v-icon>
                <span class="bold" style="font-size: 12px">
                  Οι συγκεκριμένες πληροφορίες ΔΕΝ εμφανίζονται στις ιστοσελίδες
                  και είναι μόνο για εσωτερική χρήση.
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
    </v-col>
    <v-dialog
      v-model="dialog.open"
      scrollable
      v-if="dialog.type == 'providers'"
      max-width="690"
      @click:outside="closeDialog"
      persistent
    >
      <Providers
        v-if="this.dialog.type == 'providers'"
        :data="this.dialog.data"
        :closeHandler="closeDialog"
        :submitHandler="refreshEstateHandler"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.open"
      scrollable
      v-if="dialog.type == 'cooperation'"
      max-width="690"
    >
      <Cooperation
        v-if="this.dialog.type == 'cooperation'"
        :data="this.dialog.data"
        :closeHandler="this.closeDialog"
        :submitHandler="this.closeDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.open"
      scrollable
      v-if="dialog.type == 'addReview'"
      max-width="690"
    >
      <ReviewModal
        v-if="this.dialog.type == 'addReview'"
        :data="this.dialog.data"
        @close="this.closeDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.open"
      scrollable
      v-if="dialog.type == 'showcases'"
      max-width="690"
    >
      <ShowcaseModal
        v-if="dialog.type == 'showcases'"
        :data="dialog.data"
        :submitHandler="selectShowcase"
        :closeHandler="closeDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.open"
      v-if="dialog.type == 'upload'"
      class="pa-6"
      @click:outside="true"
      persistent
    >
      <Upload
        class="pa-6"
        :supportDocType="dialog.uploadType == 'photo' ? false : true"
        :docTypeRequired="dialog.uploadType == 'document' ? true : false"
        :docType="
          dialog.uploadType == 'document' ? dialog.data.selectedDocType : null
        "
        :docTypesList="
          dialog.uploadType == 'document' ? dialog.data.docTypesList : null
        "
        :supportCheckBox="true"
        itemCheckBoxPropName="public"
        :itemCheckBoxDescr="$t('public')"
        itemCheckBoxFalseVal="Private"
        itemCheckBoxTrueVal="Public"
        :supportTxt="dialog.uploadType == 'photo' ? true : false"
        itemTxtPropName="titleGr"
        :itemTxtDescr="$t('photoDescr')"
        :itemTxtMaxLength="30"
        :closeHandler="closeDialog"
        :submitHandler="
          dialog.uploadType == 'document' ? uploadDocuments : uploadPhoto
        "
        :onlyImgRestrict="dialog.uploadType == 'photo' ? true : false"
      />
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
/*
 Name: RealEstateSlider
 Description: Component for the main real estate slider with all the informations
 API Property: 
    data: Object for the data
    onEdit: Function on edit
    openMediaSlider: Function on open media slider
    uploadSuccessHandler: Function for success upload
    updateEstate: Function on real estate update
    openDemandSlider: Function to open demands slider
    refreshEstate: Function  on real estate refresh
    onHandleDelete: Function on real estate delete
    fetchContactById: Function for the specific contact fetch
    editEstate: Boolean to enable or disable edit estate
    deleteEstate: Boolean to enable or disable edit estate

    Components Used:
      Files
      RealEstateHeader
      RealEstateHeaderMobile
      MapInfo
      ShowcaseModal
      Upload
      ImageContainerField
      CustomTable
      FinancialInfo
      Characteristics
      AdditionalSpaces
      ExtraCharacteristics
      HistoryPriceInfo
      DistanceInfo
      Assignments
      Providers
      Cooperation
      CommentsInfo
      VipInfo
      LoaderGif
      ServerProblem
      NoData
    
    Endpoints Functions Called:
    -RealEstateRepo
      uploadEstatePhotos
      uploadEstateDocuments
      downloadEstateDocuments
      sendEmail
      uniqueView
      saveEstate
      getUnreadCooperation
      setCooperationRequest
      getOffers
      getReviews
      getMatchingLead
      getShowcases
      getCooperationRequest
      getId
*/
import { mapGetters, mapState } from "vuex";
import Files from "@/components/Files/Files";
import RealEstateHeader from "@/components/RealEstate/RealEstateHeader";
import RealEstateHeaderMobile from "@/components/RealEstate/RealEstateHeaderMobile";
import CustomTable from "@/components/CustomTable/CustomTable.vue";
import Upload from "@/components/Upload/Upload.vue";
import ImageContainerField from "@/components/Fields/ImageContainerField/ImageContainerField.vue";
// import Review from "@/components/Reviews/Review";
import FinancialInfo from "@/components/RealEstate/RealEstateSlider/FinancialInfo.vue";
import Characteristics from "@/components/RealEstate/RealEstateSlider/Characteristics.vue";
import AdditionalSpaces from "@/components/RealEstate/RealEstateSlider/AdditionalSpaces.vue";
import ExtraCharacteristics from "@/components/RealEstate/RealEstateSlider/ExtraCharacteristics.vue";
import HistoryPriceInfo from "@/components/RealEstate/RealEstateSlider/HistoryPriceInfo.vue";
import DistanceInfo from "@/components/RealEstate/RealEstateSlider/DistanceInfo.vue";
import CommentsInfo from "@/components/RealEstate/RealEstateSlider/CommentsInfo.vue";
import Assignments from "@/components/RealEstate/RealEstateSlider/Assignments.vue";
import Providers from "@/components/RealEstate/RealEstateSlider/Providers.vue";
import Cooperation from "@/components/RealEstate/RealEstateSlider/Cooperation.vue";
import ShowcaseModal from "@/components/Showcases/ShowcaseModal.vue";
// import ReviewModal from "@/components/Reviews/ReviewModal.vue";
import MapInfo from "@/components/RealEstate/RealEstateSlider/MapInfo";
import VipInfo from "@/components/RealEstate/RealEstateSlider/VipInfo";
import Repository from "@/services/Repository";
import RealEstateModel from "@/static/realEstate/realEstate.js";
import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
import ProviderHelper from "@/helpers/RealEstate/providers.js";
import CoreHelper from "@/helpers/Core/core.js";
import AuthHelper from "@/helpers/Core/auth";
import i18n from "@/i18n/i18n";
import LoaderGif from "@/components/Popups/LoaderGif.vue";
import ServerProblem from "@/components/Popups/ServerProblem.vue";
import NoData from "@/components/Popups/NoData.vue";

const RealEstateRepo = Repository.get("realEstate");

export default {
  name: "RealEstateSlider",
  props: {
    data: Object,
    onEdit: Function,
    openDemandSlider: Function,
    openMediaSlider: Function,
    uploadSuccessHandler: Function,
    updateEstate: Function,
    refreshEstate: Function,
    onHandleDelete: Function,
    fetchContactById: Function,
    editEstate: { type: Boolean, default: true }, //Enable or disable edit estate
    deleteEstate: { type: Boolean, default: true }, //Enable or disable edit estate
  },
  components: {
    Files,
    RealEstateHeader,
    RealEstateHeaderMobile,
    MapInfo,
    // Review,
    // ReviewModal,
    ShowcaseModal,
    Upload,
    ImageContainerField,
    CustomTable,
    FinancialInfo,
    Characteristics,
    AdditionalSpaces,
    ExtraCharacteristics,
    HistoryPriceInfo,
    DistanceInfo,
    Assignments,
    Providers,
    Cooperation,
    CommentsInfo,
    VipInfo,
    LoaderGif,
    ServerProblem,
    NoData,
  },
  async created() {
    this.comments = this.data?.comments;
    this.isSave = this.data.saved_by_user;
    if (this.data.floors) {
      this.displayFloors = this.getDisplayFloors();
    }
    await this.postUniqueView();
    await this.getUnreadRequest();
  },
  data() {
    return {
      activeTab: null,
      headerTags: "description",
      isUploadingImg: false,
      privateNotes: null,
      matchingHeader: RealEstateModel.headersMatching,
      showcasesHeader: RealEstateModel.headersShowcases,
      templatesMatching: RealEstateModel.templatesMatching,
      offerHeaders: RealEstateModel.headersOffers,
      cooperationHeaders: RealEstateModel.headersCooperations,
      cooperationTemplates: RealEstateModel.templateCooperations,
      dataCooperations: [],
      dataShowcases: [],
      providers: RealEstateModel.providers,
      displayFloors: null,
      matchingData: [],
      offerData: [],
      templatesOffer: RealEstateModel.templateOffers,
      unreadReq: 0,
      reviewOptions: {
        page: 1,
        items: 10,
      },
      reviews: [],
      documents: RealEstateModel.files,
      docTypesList: RealEstateModel.docTypesList,
      locPlaces: [],
      existingPlace: null,
      dialog: {
        open: false,
        data: null,
        type: null,
      },
      dataFoundStatus: null,
      loadingTable: false,
      loadingRequest: false,
      comments: [],
      isSave: false,
      show: true,
      showMoreDescription: false,
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeSimpleHandler();
      }
    },
    activeTab() {
      if (this.activeTab == 0) {
        this.fetchMatchingLead();
        // } else if (this.activeTab == 1) {
        //   this.fetchShowcases();
        // } else if (this.activeTab == 2) {
        //   this.fetchOffers();
        // } else if (this.activeTab == 3) {
        //   this.fetchReviews();
      } else if (this.activeTab == 1) {
        this.fetchCooperation();
      }
    },
  },
  computed: {
    ...mapGetters(["getItemsList"]),
    ...mapState({
      listRealEstate: (state) => state.listRealEstate,
      userDetails: (state) => state.auth.userDetails,
    }),
    smAndDownBreakpoint() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    reportCooperationHeaders() {
      return this.cooperationHeaders.map((m) => {
        m.text = i18n.t(m.text);
        return m;
      });
    },
    reportMatchingHeader() {
      return this.matchingHeader.map((m) => {
        m.text = i18n.t(m.text);
        return m;
      });
    },
    financialInfo() {
      return RealEstateHelper.getFinancialInfo(this.data);
    },
    characteristicsInfo() {
      return RealEstateHelper.getCharacteristicsInfo(this.data);
    },
    distanceInfo() {
      return RealEstateHelper.getDistanceInfo(this.data);
    },
    assignemntsInfo() {
      return RealEstateHelper.getAssignemntsInfo(this.data);
    },
    additionalSpacesInfo() {
      return RealEstateHelper.getAdditionalSpacesInfo(this.data);
    },
    estateProviders() {
      return ProviderHelper.getEstateProvidersDescr(this.data);
    },
    photos() {
      var photos = [];
      if (this.data?.selectedPhotos == "watermarked") {
        photos = this.data.watermarkedphotos;
      } else {
        photos = this.data.photos;
      }

      if (photos)
        if (this.isAuthorized("viewAll", this.data) == true) return photos;
        else
          return photos.filter((p) => {
            if (p.public && p.public == "Public") return p;
          });
      else return [];
    },
    getDocTypeList() {
      if (this.isAuthorized("viewAll", this.data) == true)
        return this.docTypesList;
      else
        return this.docTypesList.filter((t) => {
          if (t.id == "all" || t.id == "marketing") {
            return t;
          }
        });
    },
    getDocuments() {
      if (this.data.documents != undefined) {
        if (this.isAuthorized("viewAll", this.data) == true)
          return this.data.documents;
        else {
          let keys = ["marketing", "presentations"];
          let x = {};
          x["_id"] = this.data.documents["_id"];
          x["estate_id"] = this.data.documents.estate_id;
          x["marketing"] = this.data.documents?.marketing;
          x["presentations"] = this.data.documents?.presentations;
          for (let i = 0; i < keys.length; i++) {
            if (x[keys[i]])
              x[keys[i]] = x[keys[i]].filter((f) => {
                if (f.visibility != "private") return f;
              });
          }
          return x;
        }
      }
      return {};
    },
    avgReviewsRating() {
      let reviewsSum = 0;
      let revCnt = 0;
      if (this.data.reviews && this.data.reviews.length > 0) {
        this.data.reviews.map((m) => {
          if (m.rating_average) {
            reviewsSum += m.rating_average;
            revCnt += 1;
          }
        });
      }
      let revAvg = 0;
      revAvg =
        revCnt != 0 ? parseFloat(reviewsSum / revCnt).toFixed(2) : revAvg;
      return {
        revAvg: revAvg,
        revCnt: revCnt,
      };
    },
    getDescription() {
      return RealEstateHelper.generateDescription(this.data);
    },
  },
  methods: {
    openDemand(data) {
      this.openDemandSlider(data._id);
    },
    getDisplayFloors() {
      let res;
      if (this.data.floors && this.data.floors.length > 1) {
        res = this.data.floors.sort(
          ({ FloorValue: a }, { FloorValue: b }) => b - a
        );
        let returnValue =
          i18n.t(res[res.length - 1].FloorLiteral) +
          "-" +
          i18n.t(res[0].FloorLiteral);
        return returnValue;
      } else {
        return i18n.t(this.data.floors[0].FloorLiteral);
      }
    },
    scrollToTag(tag) {
      this.$refs[tag].scrollIntoView({ behavior: "smooth" });
    },
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    addReview() {
      this.openDialog("addReview", null);
    },
    openMedia(type, data) {
      this.openMediaSlider(type, data);
    },
    openContact(type, data) {
      this.openContactSlider(type, data);
    },
    avatarClicked(id) {
      this.fetchContactById(id);
    },

    selectShowcase(item) {
      item;
      this.closeDialog();
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    uploadPhotoOpen() {
      this.dialog = {
        open: true,
        type: "upload",
        uploadType: "photo",
      };
    },
    async uploadPhoto(newImages) {
      this.isUploadingImg = true;
      let formData = new FormData();
      newImages.map((m, index) => {
        formData.append("visibility_" + (index + 1), m.public);
        if (m.titleGr) formData.append("title_gr_" + (index + 1), m.titleGr);
        if (m.titleEn) formData.append("title_en_" + (index + 1), m.titleEn);
        m.file != undefined
          ? formData.append("file_" + (index + 1), m.file)
          : null;
        return m;
      });
      try {
        let resp = await RealEstateRepo.uploadEstatePhotos(
          formData,
          this.data._id
        );
        if (resp.status != undefined && resp.status == 200) {
          resp.data.photos != undefined
            ? this.updateEstate(this.data._id, "photos", resp.data.photos)
            : console.error("No photos returned");
          resp.data.main_photo != undefined
            ? this.updateEstate(
                this.data._id,
                "main_photo",
                resp.data.main_photo
              )
            : console.error("No main photo returned");
        }
      } catch (err) {
        console.error(err);
      }
      this.isUploadingImg = false;
    },
    filesBtnClicked(selectedDocType, docTypesList) {
      this.dialog = {
        open: true,
        type: "upload",
        uploadType: "document",
        data: {
          selectedDocType: selectedDocType,
          docTypesList: docTypesList,
        },
      };
    },
    async uploadDocuments(documents) {
      for (let index = 0; index < documents.length; index++) {
        try {
          let formData = new FormData();
          formData.append("file", documents[index].file);
          let resp = await RealEstateRepo.uploadEstateDocuments(
            formData,
            this.data._id,
            documents[index].docType
          );
          if (resp.status != undefined && resp.status == 200) {
            let obj = Object.keys(resp.data)[0];
            let docType = Object.keys(resp.data[obj])[0];
            let newDoc = resp.data[obj][docType];
            try {
              this.uploadSuccessHandler(
                this.data._id,
                documents[index].docType,
                newDoc
              );
            } catch {
              false;
            }
          }
        } catch (err) {
          console.error("Error on", documents[index], err);
        }
      }
    },
    async downloadDocument(doc) {
      let realFilename =
        doc.name != undefined
          ? doc.name
          : doc.path != undefined
          ? CoreHelper.getFileNameFromUrl(doc.path)
          : "";
      let filename =
        doc.path != undefined ? CoreHelper.getFileNameFromUrl(doc.path) : "";
      await RealEstateRepo.downloadEstateDocuments(
        this.data._id,
        filename,
        realFilename
      );
    },
    closeSimpleHandler() {
      this.$emit("close", false);
    },
    refreshEstateHandler() {
      this.refreshEstate(this.data._id);
    },
    async sendEmail() {
      await RealEstateRepo.sendEmail();
    },
    async postUniqueView() {
      await RealEstateRepo.uniqueView(this.data["_id"]);
    },
    async addEstateToFavorites() {
      try {
        await RealEstateRepo.saveEstate(this.data._id);
        this.isSave = true;
      } catch (e) {
        throw e;
      }
    },
    onEditEstate() {
      this.onEdit(this.data);
    },
    async getUnreadRequest() {
      let result = await RealEstateRepo.getUnreadCooperation(this.data["_id"]);
      this.unreadReq = result.data.unread;
    },
    openCooperation() {
      this.openDialog("cooperation", this.data);
    },
    async setCooperationRequest() {
      try {
        this.loadingRequest = true;
        let customerFirst = this.data.ClientName ? this.data.ClientName : "";
        let customerLast = this.data.ClientSurName
          ? this.data.ClientSurName
          : "";
        let body = {
          ApplicantID: this.userDetails["_id"], // id tou user,
          AgentID: this.data["UserID"],
          TaskType: "Συνεργασία",
          CustomerName: customerFirst + " " + customerLast,
        };
        await RealEstateRepo.setCooperationRequest(body, this.data["_id"]);
        this.loadingRequest = false;
      } catch (e) {
        this.loadingRequest = false;
        throw e;
      }
    },
    openProviders() {
      this.openDialog("providers", {
        estateCode: this.data.EstateCode,
        estateId: this.data._id,
        task: this.data.Task,
        category: this.data.Category,
        providers: this.data.providers,
      });
    },
    async fetchOffers() {
      try {
        this.offerData = [];
        this.loadingTable = true;
        let resp = await RealEstateRepo.getOffers(this.data["_id"]);
        this.offerData = resp.data.offers.map((o) => {
          if (o.contact_photo == "") {
            let name = o.contact_name
              .split(/\s/)
              .reduce((response, word) => (response += word.slice(0, 1)), "");
            let newObj = { ...o, contact_photo: name };
            newObj.timestamp = CoreHelper.EpochToDate(o.timestamp);
            newObj.counter_offer_timestamp = CoreHelper.EpochToDate(
              o.counter_offer_timestamp
            );
            return newObj;
          }
          let newObj = { ...o, timestamp: CoreHelper.EpochToDate(o.timestamp) };
          newObj.counter_offer_timestamp = CoreHelper.EpochToDate(
            o.counter_offer_timestamp
          );
          return newObj;
        });
        this.dataFoundStatus = this.offerData.length > 0 ? "true" : "false";
        this.loadingTable = false;
      } catch (e) {
        this.dataFoundStatus = "false";
        this.loadingTable = false;
        throw e;
      }
    },
    async fetchReviews() {
      try {
        this.reviews = [];
        let resp = await RealEstateRepo.getReviews(this.data["_id"]);
        this.reviews = resp.data.reviews;
      } catch (e) {
        throw e;
      }
    },
    async fetchMatchingLead() {
      this.loadingTable = true;
      this.matchingData = [];
      try {
        let resp = await RealEstateRepo.getMatchingLead(this.data["_id"]);
        if (resp && resp.data && resp.data.estates) {
          this.matchingData = resp.data.estates.map((e) => {
            let agentName = e.Agent.FirstName ? e.Agent.FirstName : "";
            let agentSurname = e.Agent.LastName ? e.Agent.LastName : "";
            let clientName = e.Client.FirstName ? e.Client.FirstName : "";
            let clientSurname = e.Client.LastName ? e.Client.LastName : "";
            let newObj = { ...e, rate: e.rate * 10 };

            if ([null, undefined, ""].includes(newObj.AgentPhoto))
              newObj.AgentPhoto =
                agentName.slice(0, 1) + "" + agentSurname.slice(0, 1);
            newObj.AgentFullName = agentName + " " + agentSurname;
            newObj.ClientFullName = clientName + " " + clientSurname;
            newObj.colorBar = "success";
            newObj.rate = e.EstateCode != "" ? 100 : 90;
            return newObj;
          });
        }
        this.dataFoundStatus = this.matchingData.length > 0 ? "true" : "false";
        this.loadingTable = false;
      } catch (e) {
        this.dataFoundStatus = "false";
        this.loadingTable = false;
        throw e;
      }
    },
    async fetchShowcases() {
      this.loadingTable = true;
      this.dataShowcases = [];
      try {
        await RealEstateRepo.getShowcases(this.data["_id"]);
        this.loadingTable = false;
      } catch (e) {
        this.loadingTable = false;
        throw e;
      }
    },
    async fetchCooperation() {
      try {
        this.dataCooperations = [];
        this.loadingTable = true;
        let res = await RealEstateRepo.getCooperationRequest(this.data["_id"]);
        this.dataCooperations = res.data.cooperation_requests.map((c) => {
          if (c.AgentPhoto == undefined || c.AgentPhoto == "") {
            let name = c.AgentName.split(/\s/).reduce(
              (response, word) => (response += word.slice(0, 1)),
              ""
            );
            c.AgentPhoto = name;
            c.isImg = false;
          } else {
            c.isImg = true;
            c.AgentPhoto = `https://my.newdeal.gr/${c.AgentPhoto}`;
          }
          c.status = c.isDone ? "ΟΛΟΚΛΗΡΩΘΗΚΕ" : "ΕΝΕΡΓΗ";
          c.RegistrationDate = CoreHelper.EpochToDate(c.RegistrationDate);
          return c;
        });
        this.dataFoundStatus =
          this.dataCooperations.length > 0 ? "true" : "false";
        this.loadingTable = false;
      } catch (e) {
        this.dataFoundStatus = "false";
        this.loadingTable = false;
        throw e;
      }
    },
    async fetchEstate() {
      let resp = await RealEstateRepo.getId(this.data["_id"]);
      if (resp && resp.data && resp.data.estates)
        this.comments = resp.data.estates[0]?.comments;
    },
    onDeleteEstate() {
      this.onHandleDelete(this.data);
    },
    isAuthorized(action, data) {
      let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
      return AuthHelper.authorization(
        action,
        data[key] ? data[key] : null,
        data["userID"] ? data["userID"] : null
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  text-transform: unset !important;
  color: #263573;
}

.headerTag {
  text-transform: unset !important;
  border: unset !important;
  background-color: unset !important;
  font-size: 16px;
  color: #3a3a40;
}

.headerTag.v-btn:before {
  background-color: unset !important;
}

.v-btn-toggle > .headerTag.v-btn.v-btn--active {
  color: #e89005;
}

.v-btn-toggle > .v-btn.v-btn--active .listHeader {
  color: #263573;
  font-size: 14px;
}

.fontOrangeOnly {
  color: #e89008;
}

.bold {
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}

.blackBtn.v-btn {
  background-color: #707070;
  color: white;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  text-transform: unset !important;
  border-color: #707070;
}

.blueBtn.v-btn {
  background-color: #263573;
  color: white;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  text-transform: unset !important;
  border-color: #707070;
}

.selectedProvider {
  color: green !important;
  text-decoration: underline;
}

p {
  text-align: left;
}

.textTranformUnset {
  text-transform: unset !important;
}

.interBold {
  font-size: 14px;
  font-family: InterBold;
  color: #263573;
}

.blueishText {
  color: #3a3a40;
}

.headerRightArea {
  flex: 0 0 40%;
  max-width: 40%;
  text-align: -webkit-right;
}

.headerRightSubArea {
  justify-content: flex-end;
}

.rightTagsArea {
  flex-wrap: nowrap;
}

.masterEditSliderContainer {
  padding-left: 60px;
  padding-right: 60px;
  // padding-top: 40px;
}

.mobileImgContainer {
  margin-left: -30px;
  margin-right: -30px;
}

.headerArea {
  flex-wrap: nowrap;
}

@media only screen and (max-width: 500px) {
  .rightTagsArea {
    flex-wrap: wrap;
  }

  .masterEditSliderContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 959px) {
  .vertDivider {
    display: none !important;
  }
}

@media only screen and (max-width: 700px) {
  .headerArea {
    flex-wrap: wrap;
  }

  .headerRightArea {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
    justify-content: flex-start;
    text-align: -webkit-left;
  }

  .headerRightSubArea {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  .headerRightArea {
    margin-bottom: 0px;
  }

  .headerRightSubArea {
    justify-content: flex-end;
  }
}
</style>
