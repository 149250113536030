<template>
  <v-app id="app">
    <Appbar
      v-if="isAuthenticated"
      :openProfile="openProfile"
      :openEditProfile="openEditProfile"
    />
    <Snackbar />
    <SettingsSlider />
    <v-main
      :class="
        mobileBreakpoint == true
          ? 'mobileBreakpoint'
          : mini == true
          ? 'miniTrue'
          : ''
      "
    >
      <v-col class="pl-0">
        <router-view></router-view>
      </v-col>
      <ContactSlider
        v-if="dialog.type == 'viewSlider' && dialog.open"
        :closeHandler="closeDialog"
        :openEditSlider="openEditProfile"
        :data="dialog.data"
      />
      <EditSlider
        v-if="dialog.type == 'edit' && dialog.open"
        :editData="dialog.data"
        :activePropTab="activeTab"
        :closeHandler="closeDialog"
        :closeEditDialog="closeDialog"
      />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Appbar from "./components/Appbar/Appbar.vue";
import Snackbar from "./components/Popups/Snackbar.vue";
import SettingsSlider from "@/components/Settings/SettingsSlider.vue";
import CoreHelper from "@/helpers/Core/core.js";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import ContactSlider from "@/components/Contacts/ContactSlider/ContactSlider";
import EditSlider from "@/components/Contacts/EditContact/EditSlider.vue";

// const ContactsRepo = Repository.get("contacts");

export default {
  name: "App",
  components: {
    Appbar,
    Snackbar,
    SettingsSlider,
    ContactSlider,
    EditSlider,
  },
  data() {
    return {
      activeTab: 0,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
    };
  },
  async created() {
    if (!this.settings.langChanged) {
      let lang = await CoreHelper.getBrowserLanguage();
      this.onSetLanguage(lang);
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
      settings: (state) => state.settings,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    mini() {
      return (
        this.$store.state.menu.mini &&
        ![
          "/login",
          "/register",
          "/registerSuccess",
          "/forgotPassword",
          "/newPassword",
          "/newPasswordSuccess",
          "/forgotPasswordSent",
        ].includes(this.$route.path)
      );
    },
  },
  methods: {
    ...mapActions(["onSetLanguage"]),
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    async openProfile() {
      this.dialog.data = ContactsHelper.initDataContact(this.userDetails);
      this.openDialog("viewSlider", this.dialog.data);
    },
    openEditProfile(InputActiveTab) {
      this.activeTab = InputActiveTab;
      this.dialog.data = ContactsHelper.initDataContact(this.userDetails);
      this.dialog.data["UserPassword"] = " ";
      this.openDialog("edit", this.dialog.data);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "CenturyGothic";
  src: url("./assets/fonts/CenturyGothic.eot");
  src: url("./assets/fonts/CenturyGothic.ttf") format("truetype");
  /* src: local("CenturyGothic"), url(./assets/fonts/GOTHIC.TTF) format("truetype"); */
}

@font-face {
  font-family: "CenturyGothicBold";
  src: url("./assets/fonts/CenturyGothic-Bold.eot");
  src: url("./assets/fonts/GOTHICB0.TTF") format("truetype");
  /* src: local("CenturyGothicBold"), url(./assets/fonts/GOTHICB0.TTF) format("truetype"); */
}

@font-face {
  font-family: "InterBold";
  src: url("./assets/fonts/InterBold.TTF") format("truetype");
  /*src: local("InterBold"), url(./assets/fonts/InterBold.TTF) format("truetype");*/
}

@font-face {
  font-family: "InterMedium";
  src: url("./assets/fonts/InterMedium.TTF") format("truetype");
  /* src: local("InterMedium"), url(./assets/fonts/InterMedium.TTF) format("truetype"); */
}

@font-face {
  font-family: "InterSemiBold";
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
  /* src: local("InterMedium"), url(./assets/fonts/InterMedium.TTF) format("truetype"); */
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
  /* src: local("InterMedium"), url(./assets/fonts/InterMedium.TTF) format("truetype"); */
}

/* html {
  min-width: 690px !important;
} */
#app {
  font-family: CenturyGothic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f8f8f8 !important;
}

/* .theme--light.v-list-item.v-list-item--highlighted::before{
  opacity:0 !important;
}
.theme--light.v-list-item.v-list-item--active{
opacity:.12 !important;
} */

.miniTrue {
  padding: 64px 0px 0px 80px !important;
}

.textFontDecoration {
  font-family: CenturyGothic;
}

.textDecorationBold {
  font-family: CenturyGothicBold;
  font-weight: 500;
}

.textInterBold {
  font-family: InterBold;
}

.textInterSemiBold {
  font-family: InterSemiBold;
}

.textInter {
  font-family: Inter;
}

.textInterMedium {
  font-family: InterMedium;
}

.xClass {
  cursor: pointer;
  position: relative;
  z-index: 5;
  color: #263573;
}

.whiteColor,
.whiteColor input {
  color: #ffffff !important;
}

.primaryColor,
.primaryColor input,
.primaryColorOnHover:hover {
  color: #263573 !important;
}

.shadyBlue,
.shadyBlue input {
  color: #686d82 !important;
}

.errorColor,
.errorColor input {
  color: #e52628 !important;
}

.successColor,
.successColor input {
  color: #77bd86 !important;
}

.goldText,
.goldText input {
  color: #e89005 !important;
}
.overflow-auto {
  overflow-y: auto;
}
.orangeText,
.orangeTextInput {
  color: #e89008 !important;
}

.greyToOrgangeTextOnHover:hover {
  color: #e89008 !important;
}

.greyToOrgangeText,
.greyToOrgangeTextInput {
  color: #404045;
}

.gunPowderColor,
.gunPowderColor input {
  color: #43425d !important;
}

.cusTab,
.cusTab input {
  color: #3a3a40 !important;
}

.veryDarkGrayColor,
.veryDarkGrayColor .input {
  color: #616161 !important;
}

.unsetCapitalize {
  text-transform: unset !important;
}

.pt2 {
  padding-top: 2px !important;
}

.centered-input input {
  text-align: center;
}

.font14 input {
  font-size: 14px;
}

.mediumSizeBadge .v-badge__badge {
  height: 13px !important;
  width: 13px !important;
  border-radius: 10.5px !important;
}

.blueishInnerShadow > .v-image__image.v-image__image--cover {
  box-shadow: inset 0 0 30px 5px #253472 !important;
}

.v-btn {
  letter-spacing: normal !important;
}

.custom--checkbox--text label.v-label.theme--light {
  color: rgb(38 53 115) !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}

.height-100 {
  height: 100% !important;
}

.v-window-item.height-100 .v-image.v-responsive.v-carousel__item.theme--light {
  height: 100% !important;
}

.v-window-item.overFlow .v-responsive__content {
  overflow-y: auto;
}

.v-window-item.overFlow .v-responsive__content::-webkit-scrollbar {
  display: none;
}

.imgBorder {
  border-radius: 5px;
}

.theme--light.v-pagination .v-pagination__item {
  background: unset !important;
  box-shadow: unset !important;
}

.theme--light.v-pagination .v-pagination__navigation {
  background: unset !important;
  box-shadow: unset !important;
}

.theme--light.v-pagination .v-pagination__item--active {
  background: #263573 !important;
}

.providerBtn.v-btn:hover:before,
.providerBtn.v-btn:before,
.providerBtn.v-btn:focus {
  background-color: transparent;
}

.reviewRatings
  button.v-icon.notranslate.v-icon--link.mdi.mdi-star-outline.theme--light.primary--text,
.reviewRatings
  button.v-icon.notranslate.v-icon--link.mdi.mdi-star.theme--light.primary--text {
  padding: 0;
}

.cusCheckBox.v-input--is-label-active.v-input--checkbox.primary--text
  label.v-label.theme--light {
  color: #263573;
  font-family: CenturyGothicBold;
  font-weight: 500;
}

.cusCheckBox label.v-label.theme--light {
  font-size: 15px;
}

.mCard label.v-label.theme--light {
  font-size: 14px;
}

.v-tooltip__content {
  color: #263573 !important;
  background-color: #e8e8ea !important;
  opacity: 1 !important;
}

.opacity1 span.v-btn__content {
  opacity: 1 !important;
}

.v-overlay__scrim {
  background-color: #263573 !important;
  border-color: #263573 !important;
}

.v-list-item__title {
  display: flex;
  justify-content: flex-start;
}

.dateClearable .v-input__append-inner {
  margin-right: -14px;
}

.dateClearable .v-input__prepend-inner {
  margin-left: -10px;
}

.row-height-50 tbody tr {
  height: 50px;
}

.v-data-table__mobile-table-row {
  height: unset !important;
}

.v-data-table__mobile-row__header {
  text-align: start;
}

.row-height-75 tbody tr {
  height: 75px;
}

.row-height-80 tbody tr {
  height: 80px;
}

.row-height-85 tbody tr {
  height: 85px;
}

.row-height-100 tbody tr {
  height: 100px;
}

.row-height-120 tbody tr {
  height: 120px;
}

.row-height-150 tbody tr {
  height: 150px;
}

.row-height-200 tbody tr {
  height: 200px;
}

.lighWhiteTab .v-tabs-bar {
  background-color: #f8f8f8 !important;
}

.newDealLoc span.v-list-item__mask {
  background: rgba(38, 53, 115, 0.2) !important;
  color: #43425d !important;
}

.newDealLocItem {
  font-size: 14px !important;
  font-family: Inter !important;
  color: #43425d;
}

.v-list.newDealLocItem.v-sheet.theme--light {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  width: 100%;
}
.iconFlip {
  transform: rotateY(180deg);
}
.newDealLocItem .v-list-item__icon {
  margin: unset !important;
  display: none;
}

.v-list-item--active .newDealLocItem {
  background-color: #deebe1 !important;
  z-index: 1;
}

.v-application .greenish--text {
  margin: 10px !important;
  padding-right: 5px !important;
}

.greenish--text .newDealLocItem .v-list-item__icon {
  display: unset;
}

.v-list-item.greenish--text.v-list-item--active.v-list-item--link.theme--light:before,
.v-list-item.greenish--text.v-list-item--active.v-list-item--link.theme--light:focus:before {
  opacity: unset !important;
}

.leftTextAlign {
  text-align: start;
}

.font11 {
  font-size: 11px;
}

.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font14 {
  font-size: 14px;
}

.font15 {
  font-size: 15px;
}

.font16 {
  font-size: 16px;
}

.font17 {
  font-size: 17px;
}

.font18 {
  font-size: 18px;
}

.font19 {
  font-size: 19px;
}

.font20 {
  font-size: 20px;
}

.font24 {
  font-size: 24px;
}

.font25 {
  font-size: 25px;
}

.font26 {
  font-size: 26px;
}

.font27 {
  font-size: 27px;
}

.font28 {
  font-size: 28px;
}

.font29 {
  font-size: 29px;
}

.redAlert .theme--light.v-icon {
  color: #e52628 !important;
}

.blueAlert .theme--light.v-icon {
  color: #263573 !important;
}

.blueBorderedSelect .v-input__slot {
  border: solid 1px #263573;
}

.blueBorderedSelect label {
  color: #263573 !important;
  font-size: 14px;
  font-family: CenturyGothicBold;
}

.boxedChips .v-chip {
  border-radius: 2px;
  color: #43425d;
  font-family: InterMedium;
  font-size: 13px;
}

.autocompletePadding14 input {
  padding-top: 0px !important;
  padding-bottom: 14px !important;
}

/* ---------------------   */
.matchingAvatarClass .avatarNameContent,
.matchingAvatarClass .avatarSubContent {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.pac-container.pac-logo {
  width: 312px !important;
  transform: translateX(-39px);
}

.hideInput input {
  display: none !important;
}

/* --------------------- */
.reportCardClass {
  cursor: pointer;
}

.reportCardClass:focus:before {
  opacity: 0 !important;
}

.v-card--link:focus:before {
  opacity: 0 !important;
}
/* Real estate cards css */
.defaultEstateCard,
.mapCard {
  max-width: 300px !important;
  max-height: 435px !important;
  min-width: 300px !important;
  min-height: 435px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  margin-left: 11px !important;
  margin-right: 11px !important;
}

.defaultDemandCard,
.mapCard {
  max-width: 300px !important;
  max-height: 435px !important;
  min-width: 300px !important;
  min-height: 273px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  margin-left: 11px !important;
  margin-right: 11px !important;
}

@media only screen and (max-width: 1603px) {
  .mapCard {
    margin-left: 11px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 1579px) {
  .defaultEstateCard {
    max-width: 250px !important;
    max-height: 450px !important;
    min-width: 250px !important;
  }
  .defaultDemandCard {
    max-width: 250px !important;
    max-height: 450px !important;
    min-width: 250px !important;
  }
}

@media only screen and (max-width: 1586px) {
  .mapCard {
    margin-left: 11px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 1379px) {
  .defaultEstateCard {
    margin-left: 11px !important;
    margin-right: 0px !important;
  }
  .defaultDemandCard {
    margin-left: 11px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 1320px) {
  .mapCard {
    max-width: 250px !important;
    max-height: 450px !important;
    min-width: 250px !important;
  }
}

@media only screen and (max-width: 1180px) {
  .mapCard {
    max-width: 300px !important;
    max-height: 435px !important;
    min-width: 300px !important;
    min-height: 435px !important;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    margin-left: 11px !important;
    margin-right: 11px !important;
  }
}

@media only screen and (max-width: 1135px) {
  .defaultEstateCard {
    max-width: 48% !important;
    max-height: 450px !important;
    min-width: 48% !important;
    min-height: 450px !important;
  }
  .defaultDemandCard {
    max-width: 48% !important;
    max-height: 450px !important;
    min-width: 48% !important;
    min-height: 273px !important;
  }
}

@media only screen and (max-width: 880px) {
  .mapCard {
    max-width: 46% !important;
    max-height: 450px !important;
    min-width: 45% !important;
  }
}

@media only screen and (max-width: 654px) {
  .mapCard {
    max-width: 100% !important;
    max-height: 450px !important;
    min-width: 100% !important;
    min-height: 450px !important;
  }
}

@media only screen and (max-width: 642px) {
  .defaultEstateCard {
    max-width: 100% !important;
    max-height: 450px !important;
    min-width: 100% !important;
    min-height: 450px !important;
  }
  .defaultDemandCard {
    max-width: 100% !important;
    max-height: 450px !important;
    min-width: 100% !important;
    min-height: 273px !important;
  }
}

/* @media only screen and (max-width: 345px) {
  .mapCard {
    max-width: 250px !important;
    max-height: 450px !important;
    min-width: 250px !important;
    margin-left: 11px !important;
    margin-right: 0px !important;
  }
} */
/*-------------------------*/

@media only screen and (max-width: 460px) {
  .sliderHeader .headerTitle,
  .sliderHeader .headerSubTitle {
    flex-grow: 1 !important;
  }

  .sliderHeader .headerTitle,
  .sliderHeader .headerSubTitle {
    white-space: pre-wrap !important;
    word-break: break-word !important;
  }
}

.greenStatusBox {
  font-size: 13px !important;
  padding: 5px;
  background-color: rgba(119, 189, 134, 0.18);
}

.blueStatusBox {
  font-size: 13px !important;
  padding: 5px;
  background-color: rgba(38, 53, 115, 0.18);
}

.yellowStatusBox {
  font-size: 13px !important;
  padding: 5px;
  background-color: rgba(229, 167, 22, 0.18);
}
</style>
