import i18n from "@/i18n/i18n";
export default {
  firstFilterList: [
    {
      id: 0,
      text: "latestSales",
    },
    {
      id: 1,
      text: "oldestSales",
    },
    {
      id: 2,
      text: "sortAscending",
    },
    {
      id: 3,
      text: "sortDescending",
    },
  ],
  secondSoldList: [
    {
      id: 0,
      text: "viewGroupRealEstates",
    },
    {
      id: 1,
      text: "viewAgentRealEstates",
    },
  ],
  headersSoldEstates: [
    {
      text: "realEstate2",
      align: "start",
      sortable: false,
      sort: "",
      value: "RealEstate",
    },
    {
      text: "dateOfSale",
      align: "center",
      sortable: false,
      sort: "",
      value: "soldDate",
    },
    {
      text: "propertyCode",
      align: "center",
      sortable: false,
      sort: "",
      value: "codeEstate",
    },
    {
      text: "salePrice",
      align: "end",
      sortable: false,
      sort: "",
      value: "soldValue",
    },
  ],
  templateSoldEstates: [
    {
      column: "RealEstate",
      title: "title",
      subTitle: "subTitle",
      description: "description",
      image: "image",
      vip: "vip",
      elementType: "estateInfo",
    },
  ],

  availableFilterList: [
    {
      id: 0,
      text: "forSale",
    },
    {
      id: 1,
      text: "forRent",
    },
  ],
  headersAvailableEstates: [
    {
      text: i18n.t("realEstate2"),
      align: "start",
      sortable: false,
      sort: "",
      value: "RealEstate",
    },
    {
      text: i18n.t("characteristics"),
      align: "center",
      sortable: false,
      sort: "",
      value: "Characteristics",
    },
    {
      text: i18n.t("propertyCode"),
      align: "center",
      sortable: false,
      sort: "",
      value: "EstateCode",
    },
    {
      text: i18n.t("priceTable"),
      align: "end",
      sortable: false,
      sort: "",
      value: "Price",
    },
  ],
  templateAvailableEstates: [
    {
      column: "RealEstate",
      title: "Category",
      subTitle: "Area",
      description: "Address",
      image: "MainPhoto",
      vip: "vip",
      elementType: "estateInfo",
    },
    {
      column: "Characteristics",
      characteristicsArray: "characteristicsArray",
      elementType: "characteristics",
    },
  ],

  pagesList: [
    {
      id: 0,
      text: "5",
      value: 5,
    },
    {
      id: 1,
      text: "10",
      value: 10,
    },
    {
      id: 2,
      text: "15",
      value: 15,
    },
    {
      id: 3,
      text: "20",
      value: 20,
    },
  ],
};
