export default {
languageList: [
    {
      label: "greek",
      image: require("@/assets/settings/greekFlag.png"),
      value: "gr",
    },
    {
      label: "english",
      image: require("@/assets/settings/britishUsaFlag.png"),
      value: "en",
    },
  ], 
  currencyList: [
    {
      label: "euro",
      image: require("@/assets/settings/euro.png"),
      value: 0,
    },
    {
      label: "usDollar",
      image: require("@/assets/settings/dollar.png"),
      value: 1,
    },
    {
      label: "britishPound",
      image: require("@/assets/settings/britishPound.png"),
      value: 2,
    },
  ],
  surfaceMUList: [
    {
      label: "kilometersMeters",
      value: 0,
    },
    {
      label: "miles",
      value: 1,
    },
  ], 

  distanceMUList: [
    {
      label: "squareMeters",
      value: 0,
    },
    {
      label: "squareFeet",
      value: 1,
    },
  ]
}