 <template>
  <v-col class="customImage imgBorder" style="height: 100%">
    <v-row style="height: 55%" class="align-center">
      <v-col align="center" class="mt-5">
        <v-img :max-width="95" :max-height="95" :src="require('@/assets/realEstateSilder/iconEmpty.png')">
        </v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center" class="pb-0 pt-0">
        <span class="textDecorationBold fontColorBlue" style="font-size: 20px">
          {{ $t("adNoPhoto") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center" class="pb-1 pt-0">
        <span style="font-size: 14px" class="fontColorBlue">
          {{ $t("photoBetterDeal") }}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="supportUpload == true">
      <v-col align="center">
        <v-btn color="error" :loading="isUploadingImg" class="textDecorationBold unsetCapitalize" @click="btnClicked">
          {{ buttonTxt }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template> 

 <script>
  /*
  Name:NoPhotoContainer
  Description: Field with container images
  API Property
    buttonTxt: String with button text
    buttonAction: Function for button action
    isUploadingImg:Boolean, if image is uploading
    supportUpload: Boolean to support upload
*/
export default {
  name: "NoPhotoContainer",
  props: {
    buttonTxt: String,
    buttonAction: Function,
    isUploadingImg: { type: Boolean, default: false },
    supportUpload: { type: Boolean, default: false },
  },
  methods: {
    btnClicked() {
      try {
        this.buttonAction();
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script> 


 <style scoped>
 .customImage {
   /* width: 561px; */
   /* height: 332px; */
   max-width: 561px;
   background-color: rgba(38, 53, 115, 0.17);
 }
 
 .fontColorBlue {
   color: #263573;
 }
 </style> 

