 <template>
  <v-col align="center" class="pb-2 pt-0">
    <v-row>
      <v-col>
        <v-row>
          <v-col class="pt-7" align="left">
            <span class="textFontDecoration primaryColor font14">{{
              $t("contactDemands")
            }}</span>
          </v-col>
          <v-col>
            <v-row class="justify-end">
              <v-col align="right">
                <v-select
                  :items="pagesList"
                  dense
                  v-model="demandsOptions"
                  color="primary"
                  item-color="primary"
                  class="textDecorationBold font14"
                  style="max-width: 75px"
                  height="35"
                  hide-details
                  outlined
                  :loading="loading"
                >
                  <template v-slot:selection="{ item }">
                    <v-row>
                      <v-col align="center" class="text-no-wrap">
                        <span> {{ $t(item.text) }} </span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list>
                      <v-list-item-title>
                        <v-row>
                          <v-col align="center" class="text-no-wrap">
                            <span> {{ $t(item.text) }} </span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row  class= "pb-7" v-if="loading || dataFoundDemands != 'true'" justify="center">
          <LoaderGif v-if="loading" :white="true" />
          <NoData v-else-if="this.dataFoundDemands == 'false'" />
          <ServerProblem v-else-if="this.dataFoundDemands == 'error'" />
        </v-row>
        <v-row v-else-if="!loading && dataFoundDemands == 'true'" class="noScrollBar" style="min-height: 320px !important; overflow: auto;">
          <CustomTable
            class="row-height-75 pl-0 pr-0"
            style="overflow-y: auto"
            :headers="reportHeaderColumns"
            :templates="templates"
            :itemsPerPage="demandOptions.items"
            :data="demandsData"
            :loading="loading"
            :fixedHeader="true"
            :tableHeight="getTableHeight"
            :supportView="true"
            :handleView="openDemandSlider"
          />
            </v-row>
          </v-col>
    </v-row>
  </v-col>
</template>
 <script>
 /* 
  Name: Demands
  Description: Demands display with report at the agent and owner contacts.

  API Property:
    demandsData: Array with the demands data
    openDemandSlider: Function to open the demand slider
    fetchDemands: Function to fetch the demands
    demandOptions: Object with pages options
    setDemandsItems: Function to set demands option
    dataFoundDemands: String with true/false when data is found
    loading: Boolean with loader

  Components Used:
    CustomTable
    ServerProblem
    LoaderGif
    NoData

*/
 import DemandsModel from "@/static/demands/demands.js";
 import CustomTable from "@/components/CustomTable/CustomTable.vue";
 import LoaderGif from "@/components/Popups/LoaderGif.vue";
 import ServerProblem from "@/components/Popups/ServerProblem.vue";
 import NoData from "@/components/Popups/NoData.vue";
 import i18n from "@/i18n/i18n";

export default {
  name: "DemandsContact",
  props: {
    demandsData: Array, 
    openDemandSlider: Function, 
    fetchDemands: Function, 
    demandOptions: Object,
    setDemandsItems: Function,
    dataFoundDemands: String,
    loading: Boolean,
        pagesList: {
      type: Array,
      default: () => [
        {
          id: 0,
          text: "10",
          value: 10,
        },
        {
          id: 1,
          text: "15",
          value: 15,
        },
        {
          id: 2,
          text: "20",
          value: 20,
        },
      ],
    }, 
  },
  components: {
    CustomTable,
    ServerProblem,
    LoaderGif,
    NoData,
  },
  data() {
    return {
      headers: DemandsModel.contactHeaders,
      templates: DemandsModel.contactTemplates,
    };
  },
  computed: {
        getTableHeight() {
      return this.windowHeight - this.height - 265;
    },
    demandsDisplay(){
      return this.demandsData;
    },
    reportHeaderColumns() {
      return this.headers.map((m) => {
        m.text = i18n.t(m.text);
        return m;
      });
    },
     demandsOptions: {
      get() {
        return this.demandOptions.items;
      },
      set(newValue) {
        this.setDemandsItems(newValue);
      },
    },

  },
};
</script> 

 <style lang="scss" scoped>

 </style> 
