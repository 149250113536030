var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"align":"left"}},[_c('span',{staticClass:"primaryColor textDecorationBold font15"},[_vm._v(" "+_vm._s(_vm.$t("publicInformation"))+" ")])])],1),_c('v-row',{staticClass:"mx-n12"},[_c('v-col',{staticClass:"pb-6 pt-0 px-0"},[_c('v-divider')],1)],1),_c('v-row',[_c('BasicInfo',{attrs:{"attributes":_vm.data.basicInfo,"updateAttribute":_vm.updateAttribute,"validations":_vm.validations,"attributeModel":_vm.pageModel.basicInfoModel != undefined ? _vm.pageModel.basicInfoModel : []}})],1),_c('v-row',{staticClass:"mx-n12"},[_c('v-col',{staticClass:"pt-12 px-0"},[_c('v-divider')],1)],1),_c('v-row',[_c('ContactInfo',{attrs:{"attributes":_vm.data.contactInfo,"updateAttribute":_vm.updateAttribute,"validations":_vm.validations,"attributeModel":_vm.pageModel.contactInfoModel != undefined
          ? _vm.pageModel.contactInfoModel
          : []}})],1),_c('v-row',{staticClass:"mx-n12"},[_c('v-col',{staticClass:"px-0"},[_c('v-divider')],1)],1),(_vm.data && _vm.data.basicInfo && _vm.data.basicInfo.displayRole && ['Associate', 'Υποψήφιος Συνεργάτης'].includes(_vm.data.basicInfo.displayRole))?_c('v-row',[_c('BioInfo',{attrs:{"attributes":_vm.data.bioInfo,"updateAttribute":_vm.updateAttribute,"attributeModel":_vm.pageModel.bioInfoModel,"validations":_vm.validations,"uploadCVHandler":_vm.uploadCVInit}})],1):_vm._e(),_c('v-row',{staticClass:"mx-n12"},[_c('v-col',{staticClass:"px-0"},[_c('v-divider')],1)],1),_c('v-row',[_c('SocialInfo',{attrs:{"attributes":_vm.data.socialInfo,"attributeModel":_vm.pageModel.socialInfoModel != undefined
          ? _vm.pageModel.socialInfoModel
          : [],"updateAttribute":_vm.updateAttribute,"validations":_vm.validations}})],1),_c('v-row',{staticClass:"mx-n12"},[_c('v-col',{staticClass:"px-0"},[_c('v-divider')],1)],1),_c('v-row',[_c('AddressInfo',{attrs:{"attributes":_vm.data.addressInfo,"attributeModel":_vm.pageModel.addressInfoModel != undefined
          ? _vm.pageModel.addressInfoModel
          : [],"updateAttribute":_vm.updateAttribute,"validations":_vm.validations}})],1),_c('v-row',{staticClass:"mx-n12"},[_c('v-col',{staticClass:"px-0"},[_c('v-divider')],1)],1),_c('v-row',[_c('PersonalInfo',{attrs:{"attributes":_vm.data.personalInfo,"attributeModel":_vm.pageModel.personalInfoModel != undefined
          ? _vm.pageModel.personalInfoModel
          : [],"updateAttribute":_vm.updateAttribute,"validations":_vm.validations}})],1),_c('v-row',{staticClass:"mx-n12"},[_c('v-col',{staticClass:"px-0"},[_c('v-divider')],1)],1),(_vm.dialog.type == 'upload')?_c('v-dialog',{staticClass:"pa-6",model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[_c('Upload',{staticClass:"pa-6",attrs:{"singularUpload":true,"closeHandler":_vm.closeDialog,"submitHandler":_vm.uploadDocument}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }