<template>
  <v-col>
    <!-- <v-row class="mx-n12">
                <v-col class="px-0">
                    <v-divider />
                </v-col>
            </v-row>
            <v-row>
                <AccountInfo :attributes="data.accountInfo"
                    :updateAttribute="updateAttribute" :validations="this.$v"
                    :attributeModel="pageModel.accountInfoModel != undefined ? pageModel.accountInfoModel : []" />
            </v-row> 
            <v-row class="mx-n12">
                <v-col class="px-0">
                    <v-divider />
                </v-col>
            </v-row> -->
    <v-row>
      <v-col align="left" class="pb-0">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("publicInformation") }}
        </span>
      </v-col>
    </v-row>
    <v-row class="mx-n12">
      <v-col class="pb-6 pt-0 px-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <BasicInfo
        :attributes="data.basicInfo"
        :updateAttribute="updateAttribute"
        :validations="validations"
        :attributeModel="
          pageModel.basicInfoModel != undefined ? pageModel.basicInfoModel : []
        "
      />
    </v-row>
    <v-row class="mx-n12">
      <v-col class="pt-12 px-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <ContactInfo
        :attributes="data.contactInfo"
        :updateAttribute="updateAttribute"
        :validations="validations"
        :attributeModel="
          pageModel.contactInfoModel != undefined
            ? pageModel.contactInfoModel
            : []
        "
      />
    </v-row>
    <v-row class="mx-n12">
      <v-col class="px-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row v-if="data && data.basicInfo && data.basicInfo.displayRole && ['Associate', 'Υποψήφιος Συνεργάτης'].includes(data.basicInfo.displayRole)">
      <BioInfo
        :attributes="data.bioInfo"
        :updateAttribute="updateAttribute"
        :attributeModel="pageModel.bioInfoModel"
        :validations="validations"
        :uploadCVHandler="uploadCVInit"
      />
    </v-row>
    <v-row class="mx-n12">
      <v-col class="px-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <SocialInfo
        :attributes="data.socialInfo"
        :attributeModel="
          pageModel.socialInfoModel != undefined
            ? pageModel.socialInfoModel
            : []
        "
        :updateAttribute="updateAttribute"
        :validations="validations"
      />
    </v-row>
    <v-row class="mx-n12">
      <v-col class="px-0">
        <v-divider />
      </v-col>
    </v-row>
    <!-- <v-row>
            <addAdminBrokerInfo :attributes="data.companyInfo"
                :attributeModel="pageModel.addAdminBrokerModel != undefined ? pageModel.addAdminBrokerModel : []"
                :updateAttribute="updateAttribute" :validations="this.$v" />
        </v-row>
        <v-row class="mx-n12">
            <v-col class="px-0">
                <v-divider />
            </v-col>
        </v-row> -->
    <v-row>
      <AddressInfo
        :attributes="data.addressInfo"
        :attributeModel="
          pageModel.addressInfoModel != undefined
            ? pageModel.addressInfoModel
            : []
        "
        :updateAttribute="updateAttribute"
        :validations="validations"
      />
    </v-row>
    <v-row class="mx-n12">
      <v-col class="px-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <PersonalInfo
        :attributes="data.personalInfo"
        :attributeModel="
          pageModel.personalInfoModel != undefined
            ? pageModel.personalInfoModel
            : []
        "
        :updateAttribute="updateAttribute"
        :validations="validations"
      />
    </v-row>
    <v-row class="mx-n12">
      <v-col class="px-0">
        <v-divider />
      </v-col>
    </v-row>
    <!-- Προέλευση επαφής -->
    <!-- <v-row>
            <v-col align="left">
                <span class="primaryColor textDecorationBold font15">
                    {{ $t("contactOrigin") }}
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="left" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-select v-model="data.Source" :items="getRefferalList" :label="$t('chooseOrigin') + '...'"
                    style="color: #253472" item-text="text" outlined color="primary" clearable item-value="value">
                    <template v-slot:selection="{ item }">
                        <v-row class="flex-grow-0">
                            <v-col style="display: flex; align-items: center">
                                {{ $t(item.text) }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-list>
                            <v-list-item-title>
                                <v-row>
                                    <v-col style="display: flex; align-items: center">
                                        {{ $t(item.text) }}
                                    </v-col>
                                </v-row>
                            </v-list-item-title>
                        </v-list>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row class="mx-n12">
            <v-col class="px-0">
                <v-divider />
            </v-col>
        </v-row> -->
    <!-- <v-row>
            <v-col align="left">
                <span class="primaryColor textDecorationBold font15">
                    {{ $t("comments") }}
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-textarea :label="$t('writeMoreContactInfo') + '...'" v-model="data.Comments" class="mt-4" outlined
                    rows="4" row-height="15">
                </v-textarea>
            </v-col>
        </v-row>
        <v-row class="mx-n12">
            <v-col class="px-0">
                <v-divider />
            </v-col>
        </v-row> -->
    <!-- <v-row>
            <v-col class="pb-6">
                <FilesInfo :documents="documents" :docTypesList="docTypesList" />
            </v-col>
        </v-row>
        <v-row class="mx-n12">
            <v-col class="px-0">
                <v-divider />
            </v-col>
        </v-row> -->
        
    <v-dialog v-model="dialog.open" v-if="dialog.type == 'upload'" class="pa-6">
      <Upload
        class="pa-6"
        :singularUpload="true"
        :closeHandler="closeDialog"
        :submitHandler="uploadDocument"
      />
    </v-dialog>
  </v-col>
</template>
  
<script>

/*
 Name:ProfileContact
 Description: Component to render the sub components for the edit info in profile tab

 API Property:
    initSliderInfo: Object to initialize the slider info
    data: Object for the data 
    validations: Object for the validation variabled
    updatePropAttribute: Function to update the prop data
    updateUploadedDoc: Function to update the doc files
    
  Components Used:
    BasicInfo,
    ContactInfo,
    SocialInfo,
    AddressInfo,
    PersonalInfo,
    BioInfo,
    Upload

  Endpoints Functions Called:
      -ContactsRepo
        uploadContactCV
*/
import { mapGetters } from "vuex";
// import AccountInfo from "@/components/Contacts/ContactFields/AccountInfo";
import BasicInfo from "@/components/Contacts/ContactFields/BasicInfo";
import ContactInfo from "@/components/Contacts/ContactFields/ContactInfo";
import SocialInfo from "@/components/Contacts/ContactFields/SocialMediaInfo";
import AddressInfo from "@/components/Contacts/ContactFields/AddressInfo.vue";
import PersonalInfo from "@/components/Contacts/ContactFields/PersonalInfo.vue";
// import FilesInfo from "@/components/Contacts/ContactFields/FilesInfo.vue";
import BioInfo from "@/components/Contacts/ContactFields/BioInfo.vue";
// import addAdminBrokerInfo from "@/components/Contacts/ContactFields/AddAdminBroker.vue";
import ContactModel from "@/static/contacts/contacts.js";
import Upload from "@/components/Upload/Upload.vue";

import Repository from "@/services/Repository";
const ContactsRepo = Repository.get("contacts");
export default {
  name: "ProfileContact",
  props: {
    initSliderInfo: Object,
    data: Object,
    validations: Object,
    updatePropAttribute: Function,
    updateUploadedDoc: Function,
  },
  components: {
    // AccountInfo,
    BasicInfo,
    ContactInfo,
    SocialInfo,
    AddressInfo,
    PersonalInfo,
    // FilesInfo,
    BioInfo,
    // addAdminBrokerInfo
    Upload
  },
  data() {
    return {
      dialog: {
        open: false,
        type: null,
        data: null,
      },
    };
  },

  computed: {
    ...mapGetters(["getRefferalList"]),
    imgUrl() {
      //code for image contact
      return require("@/assets/contact/upload.png");
    },
    pageModel() {
      return ContactModel.createPageModel.filter((m) => {
        return m;
      })[0];
    },
  },
  methods: {
    updateAttribute(kind, attribute, value) {
      this.updatePropAttribute(kind, attribute, value);
    },
    async uploadDocument(documents) {
      for (let index = 0; index < documents.length; index++) {
        try {
          let formData = new FormData();
          formData.append("file", documents[index].file);
          let resp = await ContactsRepo.uploadContactCV( this.data.accountEditInfo._id, formData );
          if (resp.status != undefined && resp.status == 200) {
            this.updateAttribute('bioInfo', "cvInfo", resp.data);
            this.updateUploadedDoc(resp.data)
          }
        } catch (err) {
          console.error("Error on", documents[index], err);
        }
      }
    },
    uploadCVInit(){
      this.openDialog('upload')
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
  },
};
</script>
  