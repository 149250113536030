<template>
  <v-snackbar v-model="snackbar.open" :color="snackbar.color" top right>
    {{ snackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-icon v-bind="attrs" @click="closeSnackbar" class="topIcons">
        mdi-close
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script>


/*
 Name:Snackbar
 Description: Component for snackbar display
*/
import { mapActions, mapState } from "vuex";

export default {
  name: "snackbar",
  computed: {
    ...mapState({
      snackbar: (state) => state.snackbar.snackbar,
    }),
  },
  watch: {
    snackbar() {
      if (this.snackbar.open == true) setTimeout(this.closeSnackbar, 5500);
    },
  },
  methods: {
    ...mapActions(["onSetSnackbar"]),
    closeSnackbar() {
      this.onSetSnackbar({ open: false, text: null, color: null });
    },
  },
};
</script>