<template>
  <v-col>
    <v-container>
      <v-row>
        <draggable v-model="imageArray" @start="startDrag" @end="endDrag" draggable=".item:not(.exclude-this-item)"
          :scroll-sensitivity="200" :force-fallback="true" :options="{ disabled: disable }" tag="v-row">
          <v-col class="noFlexGrow ma-2 HW item" v-for="(item, index) in imageArray" :key="item.id">
            <DraggableCard v-bind:class="{ grabbing: dragging, grab: !dragging }" :disable="disable" :item="item"
              :index="index" :itemImageNameObj="
                item[itemImageObjName]
                  ? item[itemImageObjName]
                  : { itemImageTitleProp: '' }
              " :itemImageTitleProp="itemImageTitleProp" :itemCheckBoxPropName="itemCheckBoxPropName"
              :itemCheckBoxDescr="itemCheckBoxDescr" :itemCheckBoxFalseVal="itemCheckBoxFalseVal"
              :itemCheckBoxTrueVal="itemCheckBoxTrueVal" :topLeftLabel="topLeftLabel"
              :supportTopLeftCircle="supportTopLeftCircle" :supportEdit="supportEdit" :supportDelete="supportDelete"
              :innerClass="innerClass" :editObjHandler="editObj" :deleteObjHandler="deleteObj"
              :updateObjHandler="updateObj" />
          </v-col>
          <v-col v-if="uploadLoading || deleteLoading" class="noFlexGrow ma-2 uploadCard item exclude-this-item">
            <v-card class="noShadow">
              <v-img :src="
                deleteLoading
                  ? require('@/assets/draggable/deletingImage.png')
                  : require('@/assets/draggable/uploadingImage.png')
              " class="white--text d-flex align-end" height="234" width="250">
                <v-progress-linear color="red accent-4" indeterminate rounded height="6">
                </v-progress-linear>
              </v-img>
            </v-card>
          </v-col>
          <v-col v-if="supportUpload && !disable" class="noFlexGrow ma-2 uploadCard item exclude-this-item"
            @click="openDialog('upload')">
            <DraggableCard cardClass="noShadow" :item="{
              path: require('@/assets/draggable/addImageCard.png'),
              absolutePath: true,
            }" :supportDelete="false" :supportEdit="false" :supportTopLeftCircle="false" />
          </v-col>
        </draggable>
      </v-row>
      <v-dialog v-model="dialog.open" v-if="dialog.type == 'upload'" class="pa-6">
        <Upload class="pa-6" :onlyImgRestrict="true" :supportCheckBox="true"
          :itemCheckBoxPropName="itemCheckBoxPropName" :itemCheckBoxDescr="itemCheckBoxDescr"
          :itemCheckBoxFalseVal="itemCheckBoxFalseVal" :itemCheckBoxTrueVal="itemCheckBoxTrueVal" :supportTxt="true"
          :itemTxtPropName="uploadImagePropName" :itemTxtDescr="$t('photoDescr')" :itemTxtMaxLength="30"
          :closeHandler="closeDialog" :submitHandler="uploadImages" />
      </v-dialog>
    </v-container>
  </v-col>
</template>

<script>
/* 
  Name:DraggableImages
  Description: DraggableImages component to generate quickly Draggable Images. Support two custom fields (title and boolean or custom boolean) and also supports Re-order,Edit,Update,Delete,Upload
  API Property 
    images: Array containing images
    disable: Boolean variable to set card as disabled or not
    supportUpload: Boolean variable to show/hide the upload card
    uploadLoading: Boolean variable to show/hide the uploading loader
    deleteLoading: Boolean variable to show/hide the deleting loader
    itemImageObjName: String with object name containing image title
    itemImageTitleProp: String containing image title property name from object itemImageObjName
    uploadImagePropName: String containing the property name for the title for upload
    itemCheckBoxPropName: String with the name of the Property containing checkbox value
    itemCheckBoxDescr: String with Description of checkBox
    itemCheckBoxFalseVal: Boolean or String containing the false value of checkBox,
    itemCheckBoxTrueVal: Boolean or String containing the true value of checkBox,
    topLeftLabel: String containing the Path for {{require}} top left label
    supportTopLeftCircle: Boolean variable to show/hide the circle on first card
    supportEdit: Boolean variable to show/hide the Edit button
    supportDelete: Boolean variable to show/hide the Delete button
    innerClass: String containing Class name of inner area
    updateArrayHandler: Handler function from parent when updating array
    uploadImagesHandler: Handler function from parent when uploading image
    startDragHandler: Handler function from parent when dragging is starting
    endDragHandler: Handler function from parent when dragging is ending
    editObjHandler: Handler function from parent when edit is pressed
    deleteObjHandler: Handler function from parent when delete is pressed
    updateObjHandler: Handler function from parent when something gets updated
*/
import draggable from "vuedraggable";
import DraggableCard from "./DraggableCard.vue";
import Upload from "@/components/Upload/Upload.vue";
export default {
  name: "DraggableImages",
  components: {
    draggable,
    DraggableCard,
    Upload,
  },
  props: {
    images: Array, // Array of images
    disable: { type: Boolean, default: false }, //Enable or disable actions
    supportUpload: { type: Boolean, default: true }, //Show or hide upload card
    uploadLoading: { type: Boolean, default: false }, //Show or hide is uploading loader
    deleteLoading: { type: Boolean, default: false }, //Show or hide is deleting loader
    itemImageObjName: String, // String containing image title object name
    itemImageTitleProp: String, // String containing image title property name for object
    uploadImagePropName: String, // String containing the property name for the title for upload
    itemCheckBoxPropName: String, // Property containing checkbox value
    itemCheckBoxDescr: String, // Description of checkBox
    itemCheckBoxFalseVal: { type: [Boolean, String], default: false },
    itemCheckBoxTrueVal: { type: [Boolean, String], default: true },
    topLeftLabel: String, // Path for require top left label
    supportTopLeftCircle: { type: Boolean, default: true }, // Show the circle on top left region
    supportEdit: { type: Boolean, default: false }, //Show the Edit button
    supportDelete: { type: Boolean, default: false }, // Show the Delete button
    innerClass: String, //inner class of card
    updateArrayHandler: Function, // Handler for when updating array
    uploadImagesHandler: Function,
    startDragHandler: Function, // Handler for when dragging is starting
    endDragHandler: Function, // Handler for when dragging is ending
    editObjHandler: Function, //Handler for when edit is pressed
    deleteObjHandler: Function, // Handler for when delete is pressed
    updateObjHandler: Function, // Handler for when something gets updated
  },
  data() {
    return {
      dragging: false,
      showUpload: false,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
    };
  },
  methods: {
    editObj(obj, index) {
      try {
        this.editObjHandler(obj, index);
      } catch {
        null;
      }
    },
    deleteObj(index) {
      try {
        this.deleteObjHandler(index);
      } catch {
        null;
      }
    },
    updateObj(obj, index) {
      try {
        this.updateObjHandler(obj, index);
      } catch {
        null;
      }
    },
    startDrag(evt) {
      this.dragging = true;
      try {
        this.startDragHandler(evt);
      } catch {
        null;
      }
    },
    endDrag(evt) {
      this.dragging = false;
      try {
        this.endDragHandler(evt);
      } catch {
        null;
      }
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    uploadImages(newImages) {
      this.uploadImagesHandler(newImages);
    },
  },
  computed: {
    imageArray: {
      get() {
        return this.images;
      },
      set(newValue) {
        try {
          this.updateArrayHandler(newValue);
        } catch (err) {
          console.error(err);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.HW {
  height: 240px !important;
  width: 256px !important;
  padding: unset !important;
  border: solid 3px #253472 !important;
  border-radius: 7px;
}

.grab {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbing {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}

.uploadCard {
  height: 240px !important;
  width: 256px !important;
  padding: unset !important;
  border: unset !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  cursor: pointer;
}

.noShadow {
  box-shadow: unset !important;
}

.noFlexGrow {
  flex-grow: 0 !important;
}
</style>
