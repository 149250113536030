 <template>
  <v-col>
    <v-row @click="onClickPhotoContainer" v-if="displayType == 2" class="imagesRow">
      <v-col class="bigLeftPic mr-2 mb-2 flex-grow-0">
        <v-img max-height="358" min-height="358" :class="$vuetify.breakpoint.xsOnly == true ? '' : 'imgBorder'"
          :src="getImage(1)" :lazy-src="getImage(1)">
          <v-row class="align-end fill-height" v-if="imgs && imgs.length > 0">
            <v-col>
              <v-row class="justify-end mr-3 align-center">
                <v-col class="flex-grow-0 pt-1 pr-0 pl-1 iconsCnt iconsCntLeft">
                  <span>
                    <Icon icon="charm:image" height="23" color="#263573" />
                  </span>
                </v-col>
                <v-col class="flex-grow-0 pl-0 pr-1 iconsCnt iconsCntRight">
                  <span class="textDecorationBold" style="font-size: 14px !important">
                    {{ imgs.length }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
      <v-col class="smallPicsCol">
        <v-row>
          <v-col v-for="index in 4" :key="index" class="smallRightPic mb-2 pr-0 flex-grow-0"
            :class="'smallRightPic_' + index">
            <v-img max-height="175" min-height="175" class="imgBorder" :src="getImage(index + 1)"
              :lazy-src="getImage(index + 1)">
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="displayType == 1">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
        <v-img class="imgBorder" :src="getImage(1)" :lazy-src="getImage(1)">
        </v-img>
      </v-col>
      <v-col class="emptyImageContainer" cols="12" xs="12" sm="12" md="6" lg="6">
        <v-col class="emptyImage imgBorder d-flex align-center justify-center" style="height: 100%">
          <v-img :max-width="95" :max-height="95" min-height="95" :src="getImage(-1)" :lazy-src="getImage(-1)">
          </v-img>
        </v-col>
      </v-col>
    </v-row>
    <v-row v-else-if="displayType == 0">
      <v-col class="emptyImageContainer" cols="12" xs="12" sm="12" md="6" lg="6" style="height: 358px">
        <v-col class="emptyImage imgBorder d-flex align-center justify-center" style="height: 100%">
          <v-img :max-width="95" :max-height="95" min-height="95" :src="getImage(-1)" :lazy-src="getImage(-1)">
          </v-img>
        </v-col>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" style="height: 358px">
        <NoPhotoContainer :buttonTxt="$t('upload')" :supportUpload="supportUpload" :buttonAction="upload"
          :isUploadingImg="isUploadingImg" />
      </v-col>
    </v-row>
  </v-col>
</template> 

 <script>
 /*
  Name:ImageContainerField
  Description: Field with container images
  API Property
    imgs: Array with images data
    mainPhoto: String with main photo path
    displayType: Number, 0:No Photos 1:One photo 2:Multi Photos
    isUploadingImg:Boolean, if image is uploading
    onClickPhoto: Function on click photo
    uploadHandler: Function on upload
    supportUpload: Boolean to support upload

    Components Used:
      NoPhotoContainer
      Icon
*/
import NoPhotoContainer from "./NoPhotoContainer.vue";
import CoreHelper from "@/helpers/Core/core.js";
import { Icon } from "@iconify/vue2";
export default {
  name: "ImageContainerField",
  props: {
    imgs: Array,
    mainPhoto: { type: String, default: "" },
    displayType: Number, //0:No Photos 1:One photo 2:Multi Photos
    isUploadingImg: { type: Boolean, default: false },
    onClickPhoto: Function,
    uploadHandler: Function,
    supportUpload: { type: Boolean, default: false },
  },
  components: {
    NoPhotoContainer,
    Icon,
  },
  methods: {
    upload() {
      try {
        this.uploadHandler();
      } catch {
        true;
      }
    },
    onClickPhotoContainer() {
      this.onClickPhoto();
    },
    getImage(number) {
      if (number == 1) {
        return this.mainPhoto != ""
          ? CoreHelper.getImagePath({ path: this.mainPhoto })
          : require("@/assets/realEstateSilder/nd_icon.png");
      } else if (number == -1) {
        return require("@/assets/realEstateSilder/nd_icon.png");
      } else if (number != 1) {
        if (this.imgs && this.imgs.length >= number) {
          return CoreHelper.getImagePath(this.imgs[number - 1]);
        } else {
          return require("@/assets/realEstateCard/no-image.png");
        }
      }
    },
  },
};
</script> 
<style scoped>
.bigLeftPic {
  max-width: 561px !important;
  max-height: 358px !important;
}

.smallRightPic {
  max-width: 270px !important;
  max-height: 175px !important;
}

.emptyImage {
  max-width: 561px;
  background-color: #e8e8ea;
}

.iconsCnt {
  background-color: rgba(255, 255, 255, 0.85);
  height: 30px !important;
}

.iconsCntLeft {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.iconsCntRight {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding-top: 3px !important;
}

@media only screen and (max-width: 1202px) {

  .smallRightPic_2,
  .smallRightPic_4 {
    display: none;
  }
}

@media only screen and (max-width: 685px) {
  .smallPicsCol {
    display: none;
  }

  .bigLeftPic {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .emptyImageContainer {
    display: none;
  }

  .imgBorder {
    border-radius: 0px !important;
  }
}
</style>