<template>
  <v-col>
    <v-row>
      <v-col class="flex-grow-0 pa-" align="left">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("specialization") }}
        </span>
      </v-col>
      <v-col class="flex-grow-0 pa-0" align="left">
        <v-tooltip :max-width="400" top>
          <template v-slot:activator="{ on, attrs }">
            <div class="mt-3 ml-n1" v-bind="attrs" v-on="on">
              <v-icon color="primary" size="20">
                mdi-information-outline
              </v-icon>
            </div>
          </template>
          <span class="font12">{{ $t("areasMaxSelect") }} </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0 mt-n3 font14" align="left">
        <span v-if="enableLocsError" class="errorColor">
          {{ errorMessage }}
        </span>
        <!-- <span class="errorColor"> {{ locationsErrorMessages[0] }} </span> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-combobox
          v-model="region"
          :items="regionsList"
          @focusout="fetchLocation('states')"
          :loading="regionsLoading"
          :label="$t('area') + ' *'"
          clearable
          color="primary"
          hide-details="auto"
          :error-messages="regionErrorMessages"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-combobox
          v-model="state"
          :items="statesList"
          :loading="statesLoading"
          @focusout="fetchLocation('districts')"
          :label="$t('city') + ' *'"
          clearable
          color="primary"
          hide-details="auto"
          :error-messages="statesErrorMessages"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-combobox
          v-model="district"
          :items="districtsList"
          :loading="districtsLoading"
          @focusout="fetchLocation('boroughs')"
          :label="$t('municipality') + ' *'"
          clearable
          color="primary"
          hide-details="auto"
          :error-messages="districtErrorMessages"
        />
      </v-col>

      <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-btn
          class="py-0 mt-4 unsetCapitalize btn"
          :min-width="170"
          :max-width="170"
          outlined
          color="primary"
          @click="addLocations"
          style="height: 38px"
          :ripple="false"
        >
          {{ $t("add") }}
        </v-btn>
      </v-col>
      <v-col
        class="py-0 mt-n2"
        v-if="districtError"
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        align="left"
      >
        <span class="errorColor"> {{ $t("requiredField") }} </span>
      </v-col>
    </v-row>
    <v-row class="mt-0 pb-2 pr-3">
      <v-col align="left" class="d-flex flex-nowrap overflow-auto">
        <FilterTag
          v-for="(tag, index) in displayLocations"
          :key="index"
          :display="tag.text"
          :attrValue="tag.text"
          attrType="array"
          :deleteHandler="deleteLocationsFilter"
        />
        <a
          v-if="displayLocations && displayLocations.length > 0"
          class="text-decoration-underline text-no-wrap"
          color="#43425D"
          @click="clearAllFilter"
        >
          {{ $t("clear") }}</a
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col align="left">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("briefCurriculumVitae") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col s="12" sm="12" md="12" lg="12">
        <v-textarea
          :placeholder="$t('writeCv')"
          v-model="shortBio"
          class="mt-4"
          outlined
          rows="4"
          row-height="15"
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col align-self="center" class="flex-grow-0">
        <span>{{ $t("curriculumVitae2") }}</span>
      </v-col>
      <v-col class="pt-2" v-if="this.attributes.cvInfo && this.attributes.cvInfo.file_name">
        <v-row class="align-center flex-nowrap">
          <v-col :class="!getSrc ? 'flex-grow-0' : 'flex-grow-0 pb-0'" >
            <v-img v-if="getSrc" class="iconSize" :src="getSrc"/>
            <span v-else class="itemIcon textDecorationBold iconSize">
              .{{ getFileType() }}
            </span>
          </v-col>
          <v-col class="mt-3">
               <v-tooltip
                top
                :open-on-hover="getFileName.length > maxChar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                  <v-row class="flex-nowrap">
                    <v-col align="left" class="textDecorationBold font14 titleColor flex-grow-0 text-no-wrap pl-0">
                      <span> {{ getFileName }} </span>
                    </v-col>
                    <v-col class="flex-grow-0 text-no-wrap">
                      <span class="primaryColor fontSize12">
                        {{ getFileSize }}
                      </span>
                    </v-col>
                    </v-row>
                  </span>
                </template> 
                <v-row class="flex-nowrap">
                  <v-col align="left" class="textDecorationBold font14 titleColor flex-grow-0 text-no-wrap">
                    <span> {{ getFileName }} </span>
                  </v-col>
                  <v-col class="flex-grow-0 text-no-wrap">
                    <span class="primaryColor fontSize12">
                      {{ getFileSize }}
                    </span>
                  </v-col>
                </v-row>
              </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col align-self="center" class="flex-grow-0">
        <v-btn outlined color="primary" class="btn" @click="uploadCV">{{ this.attributes.cvInfo && this.attributes.cvInfo.file_name ? $t("replace") : $t("addFile")  }}</v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col>
        <v-row>
          <v-col align-self="center" class="flex-grow-0">
            <span class="primaryColor textDecorationBold font15">
              {{ $t("studies") }}
            </span>
          </v-col>
          <v-col align-self="center" class="flex-grow-0 pl-1">
            <v-btn
              outlined
              color="primary"
              class="pa-0"
              min-width="22px"
              max-width="22px"
              max-height="22px"
              min-height="22px"
              @click="addQualification"
            >
              <Icon icon="akar-icons:plus" color="#263573" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-for="qualification, i in qualifications" :key="i">
          <v-col> 
            <v-text-field
              v-model="qualification.school"
              :label="$t('universitySchool')"
              clearable
              color="primary"
              hide-details="auto"
            />
          </v-col>
          <v-col> 
            <v-text-field
              v-model="qualification.specialization"
              :label="$t('specialty')"
              clearable
              color="primary"
              hide-details="auto"
            />
           </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col>
        <v-row>
      <v-col align-self="center" class="flex-grow-0">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("languages") }}
        </span>
      </v-col>
      <v-col align-self="center" class="flex-grow-0 pl-1">
        <v-btn
          outlined
          color="primary"
          class="pa-0"
          min-width="22px"
          max-width="22px"
          max-height="22px"
          min-height="22px"
              @click="addLanguage"
        >
          <Icon icon="akar-icons:plus" color="#263573" />
        </v-btn>
      </v-col>
        </v-row>
        <v-row>
          <v-col  v-for="language, i in languages" :key="i" 
                  cols="12" xs="12" sm="6" md="6" lg="6" xl="6"> 
            <v-text-field
              v-model="languages[i]"
              :label="$t('language')"
              clearable
              color="primary"
              hide-details="auto"
            /> <!-- :error-messages="busOrgNameErrors" -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:BioInfo
 Description: Component to render bio info fields for contact

 API Property:
    attributes: Object with the data 
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the info type like "addressInfo"

    Components Used:
      FilterTag
      Icon

    Endpoints Functions Called:
      -RealEstateRepo
        getRegions
        getStates
        getDistricts
        getBoroughs
*/

import Repository from "@/services/Repository";
import i18n from "@/i18n/i18n";
import FilterTag from "@/components/Filters/FilterTag.vue";
import CoreHelper from "@/helpers/Core/core.js";
import { Icon } from "@iconify/vue2";
import { required, requiredIf } from "vuelidate/lib/validators";
const RealEstateRepo = Repository.get("realEstate");
export default {
  name: "BioInfo",
  components: {
    FilterTag,
    Icon,
  },
  props: {
    attributes: Object,
    updateAttribute: Function,
    validations: Object,
    attributeModel: Array,
    kind: { type: String, default: "bioInfo" },
    uploadCVHandler: Function
  },
  created() {
    this.fetchLocation("regions");
    if (this.attributes.specLocations) {
      for (let i = 0; i < this.specLocations.length; i++) {
        let object = {
          val: i,
          text: this.specLocations[i],
        };
        this.displayLocations.push(object);
      }
    }
  },
  data() {
    return {
      region: null,
      state: null,
      district: null,
      borough: null,
      regionsList: [],
      regionsLoading: false,
      statesList: [],
      statesLoading: false,
      districtsList: [],
      districtsLoading: false,
      boroughsList: [],
      boroughsLoading: false,
      displayLocations: [],
      maxLocs: null,
      enableLocsError: false,
      districtError: false,
      errorMessage: null,
      imgExtensions: ["jpeg", "png", "jpg"]
    };
  },
  watch: {},
  validations() {
    return {
      region: {
        required,
      },
      state: {
        required,
      },
      district: {
        required,
      },
      maxLocs: {
        required: requiredIf(function () {
          return this.displayLocations.length >= 3;
        }),
      },
    };
  },
  computed: {
    specLocations: {
      get() {
        return this.attributes.specLocations;
      },
      set(value) {
        this.updateAttribute(this.kind, "specLocations", value);
      },
    },
    shortBio: {
      get() {
        return this.attributes.shortBio;
      },
      set(value) {
        this.updateAttribute(this.kind, "shortBio", value);
      },
    },
    qualifications: {
      get() {
        return this.attributes.qualifications;
      },
      set(value) {
        this.updateAttribute(this.kind, "qualifications", value);
      },
    },
    languages: {
      get() {
        return this.attributes.languages;
      },
      set(value) {
        this.updateAttribute(this.kind, "languages", value);
      },
    },
    locale() {
      return this.$i18n.locale != undefined ? this.$i18n.locale : "gr";
    },
    maxLocsErrorMessages() {
      const errors = [];
      if (!this.$v.maxLocs.$dirty) return errors;
      if (!this.$v.maxLocs.required)
        errors.push(i18n.t("maxLocations") + " : 3");
      return errors;
    },
    regionErrorMessages() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      if (!this.$v.region.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    statesErrorMessages() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      if (!this.$v.state.required) errors.push(i18n.t("requiredField"));
      return errors;
    },
    districtErrorMessages() {
      const errors = [];
      if (!this.$v.district.$dirty) return errors;
      if (!this.$v.district.required) errors.push(i18n.t("requiredField"));
      return errors;
    },    
    maxChar() {
      return this.$vuetify.breakpoint.width >= 665 ||
        (this.$vuetify.breakpoint.width < 620 &&
          this.$vuetify.breakpoint.width > 375)
        ? 27
        : 20;
    },
    getFileName() {
      return this.attributes.cvInfo && this.attributes.cvInfo.file_name ? this.attributes.cvInfo.file_name : '';
    },
    getFileSize(){
      return this.attributes.cvInfo.file_size && this.attributes.cvInfo.file_size ? this.attributes.cvInfo.file_size : '';
    },
    getSrc() {
      let type = CoreHelper.getFileType(this.getFileName).toLowerCase();
      return type == "pdf" ? require("@/assets/documents/pdfCircleIcon.png") 
              : this.imgExtensions.includes(type.toLowerCase()) ? require("@/assets/documents/img-icon.png") 
              : false;
    },    
    getFileType() {
      return this.getFileName != '' ? CoreHelper.getFileType(this.getFileName).toLowerCase() : "";
    },
  },
  methods: {
    showAttribute(attribute) {
      return this.attributeModel.includes(attribute);
    },
    clearAllFilter() {
      this.displayLocations = [];
      this.specLocations = [];
    },
    addLocations() {
      let locs = this.region + ", " + this.state + ", " + this.district;
      if (this.displayLocations.length == 3) {
        this.enableLocsError = true;
        this.errorMessage = i18n.t("maxLocations") + " : 3";
        setTimeout(() => {
          this.enableLocsError = false;
        }, 1000);
        return false;
      }

      if (this.specLocations.includes(locs)) {
        this.enableLocsError = true;
        this.errorMessage = i18n.t("addedLocation");
        setTimeout(() => {
          this.enableLocsError = false;
        }, 1000);
        return false;
      }

      this.$v.$touch();
      if (this.$v.$invalid) return false;

      let object = {
        val: this.displayLocations.length.toString(),
        text: locs,
      };
      this.displayLocations.push(object);
      let newArray = [...this.specLocations];
      newArray.push(locs);
      this.specLocations = newArray;
      this.region = null;
      this.state = null;
      this.district = null;
      this.$v.$reset();
    },
    async deleteLocationsFilter(attrHead, attrType, attrValue) {
      this.displayLocations = this.displayLocations.filter((obj) => {
        return obj.text != attrValue;
      });

      this.specLocations = this.specLocations.filter((name) => {
        return name != attrValue;
      });
    },
    fetchLocation(type) {
      switch (type) {
        case "regions":
          this.fetchRegions(this.locale);
          break;
        case "states":
          this.fetchStates(this.region, this.locale);
          break;
        case "districts":
          this.fetchDistricts(this.state, this.region, this.locale);
          break;
        case "boroughs":
          this.fetchBoroughs(
            this.district,
            this.state,
            this.region,
            this.locale
          );
          break;
        default:
          true;
      }
    },
    async fetchRegions(locale) {
      this.regionsLoading = true;
      try {
        const resp = await RealEstateRepo.getRegions(locale);
        if (resp.data.regions) this.regionsList = resp.data.regions;
        this.regionsLoading = false;
      } catch (e) {
        this.regionsLoading = false;
        throw e;
      }
    },
    async fetchStates(region, locale) {
      this.statesLoading = true;
      try {
        const resp = await RealEstateRepo.getStates(region, locale);
        if (resp.data.states) this.statesList = resp.data.states;
        this.statesLoading = false;
      } catch (e) {
        this.statesLoading = false;
        throw e;
      }
    },
    async fetchDistricts(state, region, locale) {
      this.districtsLoading = true;
      try {
        const resp = await RealEstateRepo.getDistricts(region, state, locale);
        if (resp.data.districts) this.districtsList = resp.data.districts;
        this.districtsLoading = false;
      } catch (e) {
        this.districtsLoading = false;
        throw e;
      }
    },
    async fetchBoroughs(district, state, region, locale) {
      this.boroughsLoading = true;
      try {
        const resp = await RealEstateRepo.getBoroughs(
          region,
          state,
          district,
          locale
        );
        if (resp.data.boroughs) this.boroughsList = resp.data.boroughs;
        this.boroughsLoading = false;
      } catch (e) {
        this.boroughsLoading = false;
        throw e;
      }
    },
    addQualification(){
      this.qualifications = [...this.qualifications, {school: '', specialization: ''}]
    },
    addLanguage(){
      this.languages = [ ...this.languages, '']
    },
    uploadCV(){
      this.uploadCVHandler();
    }
  },
};
</script>
<style scoped>
.btn {
  text-transform: unset !important;
  font-family: CenturyGothicBold !important;
  color: #263573;
  background-color: white;
  font-weight: 500 !important;
}

.itemIcon {
  background-color: #e52628;
  color: #ffffff !important;
  font-size: xx-small;
  border-radius: 50%;
}

.iconSize {
  max-width: 34px !important;
  max-height: 35px !important;
}

@media only screen and (max-width: 600px) {
  .iconSize {
    max-width: 32px !important;
    max-height: 32px !important;
  }
}
</style>
