<template>
  <v-row>
    <v-col cols="12" align="left" class="pa-0">
      <v-row>
        <v-col cols="12" align="left">
          <span class="listHeader fontOrange">{{
              $t("additionalSpaces")
          }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('storage_room')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('warehouse')" :data="
              parseBoolean(this.data.storage_room) == true
                ? this.data.storage_room_sqm + ' τ.μ'
                : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('attic')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('attic')" :data="
              parseBoolean(this.data.attic) == true
                ? this.data.attic_in_sqm + ' τ.μ'
                : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('service_room')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('serviceRoom')" :data="
              parseBoolean(this.data.service_room) == true
                ? this.data.service_room_in_sqm + ' τ.μ'
                : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('owned_terrace')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('privateTerrace')" :data="
              parseBoolean(this.data.owned_terrace) == true
                ? this.data.owned_terrace_in_sqm + ' τ.μ'
                : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('playroom')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('playroom')" :data="
              parseBoolean(this.data.playroom) == true
                ? this.data.playroom_in_sqm + ' τ.μ'
                : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('garden')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('garden')" :data="
              parseBoolean(this.data.garden) == true
                ? this.data.garden_in_sqm + ' τ.μ'
                : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('pool')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('pool')" :data="
              parseBoolean(this.data.pool) == true
                ? this.data.pool_in_sqm + ' τ.μ'
                : ''
            " :isUnderline="false" />
        </v-col>
      </v-row>
      <v-row v-if="mobileBreakpoint == true && topShow.showMore != undefined" class="mt-0 justify-center">
        <v-col class="pt-0 pb-0" align="center">
          <v-btn class="primaryColor unsetCapitalize opacity1 textDecorationBold" text plain
            @click="() => (topShow.showMore = !topShow.showMore)">
            {{ topShow.showMore == true ? $t("lessInfo") : $t("moreInfo") }}...
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
 /*
 Name: AdditionalSpaces
 Description: Component for real estate addition spaces at the slider

 API Property: 
    data: Object for the data

    Components Used:
      ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
import CoreHelper from "@/helpers/Core/core.js";

export default {
  name: "AdditionalSpaces",
  props: ["data"],
  components: {
    ReadField,
  },
  data() {
    return {
      topArray: [
        "storage_room",
        "attic",
        "service_room",
        "owned_terrace",
        "playroom",
        "garden",
        "pool",
      ],
      topShow: { showKeys: [], showMore: undefined },
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
    };
  },
  created() {
    if (this.mobileBreakpoint == true)
      this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4);
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    parseBoolean(val) {
      return CoreHelper.parseBoolean(val);
    },
    checkShow(objKey) {
      return this.data[objKey]
        ? this.mobileBreakpoint != true
          ? true
          : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
            this.topShow.showMore != false
            ? true
            : false
        : false;
    },
  },
};
</script>

<style scoped>
.listHeader {
  color: #263573;
  font-size: 14px;
}

.fontOrange {
  color: #e89008;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
</style>
