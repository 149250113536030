<template>
  <v-row>
    <v-col cols="12" align="left" class="pa-0">
      <v-row>
        <v-col cols="12" align="left">
          <span class="listHeader fontOrange"> {{ $t("financialData") }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('Price')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="
              data.Task == 'Ενοικίαση'
                ? $t('rentPrice')
                : data.Task == 'Αντιπαροχή'
                  ? $t('considerationPrice')
                  : $t('salePrice')
            " :data="this.data.Price" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('ObjectivePrice')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('objectiveValue')" :data="this.data.ObjectivePrice + ' €'" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('AgentPrice')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('valuationAgent')" :data="this.data.AgentPrice + ' €'" dataClass="fontUnderline successColor" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('PricePerUnit')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('priceSqM')" :data="this.data.PricePerUnit + ' €'" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('estimated_rent_price')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('estimatedRent')" :data="
              this.data.estimated_rent_price != undefined
                ? this.data.estimated_rent_price + ' €'
                : ''
            " :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('suitable_for_investment')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('investmentLeased')" :data="this.data.suitable_for_investment" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('payment_in_advance_date')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('dateOfAdvancePayment')" :data="this.data.payment_in_advance_date" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('payment_in_advance_amount')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('advancePaymentAmount')" :data="this.data.payment_in_advance_amount" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('monthly_rent_price')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('monthlyRent')" :data="this.data.monthly_rent_price" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('date_of_lease_expiration')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('endOfLease')" :data="this.data.date_of_lease_expiration" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('shared_fees_monthly_price')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('monthlyUtilities')" :data="this.data.shared_fees_monthly_price" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('yearly_tax')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('annualTaxENFIA')" :data="this.data.yearly_tax" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4" v-if="true == checkShow('yearly_yield')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('annualReturn')" :data="this.data.yearly_yield" :isUnderline="false" />
        </v-col>
        <v-col cols="6" xl="4" lg="4" md="6" sm="6" xs="6" class="pt-4"
          v-if="true == checkShow('yearly_income_before_tax')">
          <ReadField :fontSize="fontSizeRF" :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue"
            :label="$t('annualIncomeBeforeTax')" :data="this.data.yearly_income_before_tax" :isUnderline="false" />
        </v-col>
      </v-row>
      <v-row v-if="mobileBreakpoint == true && topShow.showMore != undefined" class="mt-0 justify-center">
        <v-col class="pt-0 pb-0" align="center">
          <v-btn class="primaryColor unsetCapitalize opacity1 textDecorationBold" text plain
            @click="() => (topShow.showMore = !topShow.showMore)">
            {{ topShow.showMore == true ? $t("lessCharacteristics") : $t("moreCharacteristics") }}...
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
 /*
 Name: FinancialInfo
 Description: Component for real estate financial info at the slider

 API Property: 
    Components Used:
      ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField";
import CoreHelper from "@/helpers/Core/core.js";
export default {
  name: "FinancialInfo",
  props: ["data"],
  components: {
    ReadField,
  },
  data() {
    return {
      topArray: [
        "Price",
        "ObjectivePrice",
        "AgentPrice",
        "PricePerUnit",
        "estimated_rent_price",
        "suitable_for_investment",
        "payment_in_advance_date",
        "payment_in_advance_amount",
        "monthly_rent_price",
        "date_of_lease_expiration",
        "shared_fees_monthly_price",
        "yearly_tax",
        "yearly_yield",
        "yearly_income_before_tax",
      ],
      topShow: { showKeys: [], showMore: undefined },
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
    };
  },
  created() {
    if (this.mobileBreakpoint == true)
      this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4);
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    checkShow(objKey) {
      return this.data[objKey]
        ? this.mobileBreakpoint != true
          ? true
          : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
            this.topShow.showMore != false
            ? true
            : false
        : false;
    },
  },
};
</script>

<style scoped>
.listHeader {
  color: #263573;
  font-size: 14px;
}

.fontOrange {
  color: #e89008;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}
</style>
