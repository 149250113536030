<template>
    <v-card class="masterContainer pa-9" :elevation="0" style="display: flex; justify-content: center; cursor: pointer">
        <v-col class="pa-8">
            <v-row>
                <v-col align="center">
                    <v-img :max-height="imgHeight" :max-width="imgWidth"
                        :src="require('@/assets/general/congrats.png')" />
                </v-col>
            </v-row>
            <v-row>
                <v-col align="center"  class="textDecorationBold font20 primaryColor">
                    <span style="display:inline;"> {{ title + ' '}} </span>
                    <span style="display:inline;" class="goldText"> {{ propertyCode }} </span>
                    <span> {{'.'}} </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="center">
                    <v-btn width="100%" :min-width="192" :min-height="42" :max-width="192" :max-height="42"
                        class="unsetCapitalize textDecorationBold" outlined color="primary" block @click="onFirstButton">
                        {{ $t("backToList")}}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-btn width="100%" :min-width="192" :min-height="42" :max-width="192" :max-height="42"
                        class="unsetCapitalize textDecorationBold" color="error" block @click="onSecondButton">
                        {{ $t("viewProperty")}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-card>
</template>
<script>


/*
 Name:PropertyCongrats
 Description: Popup component with congrats image and a title with two buttons below
 
 API Property:
    title: String below the image
    propertyCode: Property code for the title
    imgWidth: Image width 
    imgHeight: Image height 
    onFirstHandler: Function for the first (left) button
    onSecondHandler: Function for the second (right) button
*/

export default {
    name: "PropertyCongrats",
    props: {
        title: String, //String below the image
        propertyCode: String, //Property code for the title
        imgWidth: { type: Number, default: 500 }, // Image width 
        imgHeight: { type: Number, default: 74 }, // Image height 
        onFirstHandler: Function, // Function for the first (left) button
        onSecondHandler: Function, // Function for the second (right) button
    },
    components: {},
    data() {
        return {
            show: true,
        }
    },
    watch: {
        show() {
            if (!this.show) this.onCloseHandler();
        },
    },
    computed: {
        mobileBreakpoint() {
            return this.$vuetify.breakpoint.xsOnly;
        },
    },
    methods: {
        onFirstButton() {
            this.onFirstHandler();
        },
        onSecondButton() {
            this.onSecondHandler();
        }
    },
};
</script>
  >
<style scoped>
.masterContainer {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.btn {
    text-transform: unset !important;
    color: #263573;
    background-color: white;
    font-weight: 500 !important;
}
</style>
  