<template>
  <v-navigation-drawer
    width="1280"
    style="height: 100%"
    fixed
    right
    v-model="show"
    app
    temporary
  >
    <v-col>
      <v-row>
        <v-col v-if="type != 'Customer'" align="left" class="px-0 pt-0">
          <v-icon
            @click="onCloseHandler"
            style="
              cursor: pointer;
              position: absolute;
              right: 30px;
              top: 10px;
              z-index: 5;
              color: white;
            "
          >
            mdi-close
          </v-icon>
          <PersonAppBar
            :photo="getPhoto(data)"
            :title="data.FirstName + ' ' + data.LastName"
            :isImg="data.isImgAgent"
            logoTitle=""
            :imageTitle="splitCompany()"
            :avatarImage="avatarPic"
          />
          <!-- <BrokerAppBar
            v-if="type == 'Broker'"
            title="newdeal house brokers"
            :subTitle="data.Role"
            logoTitle="House Brokers"
          />
          <PartnersAppBar
            v-if="type == 'Partners'"
            title="Παπαδημούλη & Αγγελόπουλος"
            :subTitle="data.Role"
            logoTitle=""
          /> -->
        </v-col>
      </v-row>
      <v-row class="px-6">
        <v-col>
          <v-row>
            <v-col
              v-if="type != 'Customer'"
              :style="
                'min-width: ' +
                (type == 'Person'
                  ? '190px'
                  : type == 'Broker'
                  ? '255px'
                  : type == 'Partners'
                  ? '160px'
                  : '') +
                ' !important; min-height: 80px;'
              "
              class="flex-grow-0"
            ></v-col>
            <v-col v-if="type != 'Customer'" align="left">
              <!-- <v-row class="mt-n5 flex-nowrap">
                <v-col class="pt-0 pl-0 flex-grow-0">
                  <RatingField
                    colorRating="#E89008"
                    :size="5"
                    :val="2.5"
                    :halfIncrements="true"
                    :disabled="true"
                  />
                </v-col>
                <v-col class="font14 cusTab pt-1 pl-0 text-no-wrap">
                  14 Κριτικές
                </v-col>
              </v-row> -->
              <v-row v-if="statusFieldEnabled">
                <v-col class="flex-grow-0">
                  <StatusField
                    :description="statusFieldEnabled"
                    :backgroundColor="statusFieldBackColor"
                    :color="statusFieldColor"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="type == 'Customer'">
              <v-row :class="'align-center'">
                <v-col class="flex-grow-0" align="center">
                  <v-avatar color="#77BD86" :size="106">
                    <span
                      class="white--text textDecorationBold"
                      style="font-size: 41px"
                      v-if="!isImg"
                    >
                      ΜΠ
                    </span>
                    <!-- <v-img v-else :src="getImage(data)" :lazy-src="getImage(data)" alt="John" /> -->
                  </v-avatar>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col class="flex-grow-0 pb-0 pr-0 align-self-end">
                            <v-btn icon class="primaryColorOnHover">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="20" height="21" viewBox="0 0 20 21">
                                    <image id="share" width="20" height="21"
                                        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAABHNCSVQICAgIfAhkiAAAAW9JREFUOE+tlc0rBVEYh40slHyWhZSVbrEVOysLSylkYWFpcUuy8VFcFqwo/AVKQlGU1U3dlZIkKWV3V6wkZaF8Pr86U+dqDuee5tTTnTlz3mfOO+87c6NM93RFwKghZhmGoRauYBYuowBhJYEF6P21kXfNhQgHCTxyZHUWIlwx6SU5X0KEU5jWHTsslivsQ7QFHQ5hzlfYhmANhuAQTs0uGyzxLsfjsbCKkxHoglfYhzuoBvXVPBRhEvJG0shvP0h6Adeal7DOLOqx7vbJ8SYMQDOo5zZArfHnkFDPJOtYdcL8BDz+J4qvS/jASYsjYI75VV9ZnPITB02OoEWTrrdTO1TXq/uTxj2TOTiALx+rhO0sPAc9fHuo0m8wBrewBMfwbRapuqqyql1SZV1vhRlQpZ9hD7ZNsG6oKo/CjRF/8LsD9dYOSvrQJ5tOFi2A+jVyBHi/KXa8pEo/aZT9LkuS+tcm9e9h6l9spe38T/kBf8tWiDAk0IAAAAAASUVORK5CYII=" />
                                </svg>
                            </v-btn>
                        </v-col> -->
            <v-col class="flex-grow-0 pr-0 pb-0 align-self-end">
              <v-btn @click="addToFavorites" icon>
                <v-icon color="red" v-if="isSaved"> mdi-cards-heart </v-icon>
                <v-icon v-else class="primaryColorOnHover">
                  mdi-cards-heart-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <v-btn-toggle style="overflow: auto" class="d-flex justify-start">
                <!-- <v-btn value="description" class="headerTag textDecorationBold" text v-ripple="false">
                                    <v-icon color="green">mdi-message-outline </v-icon>
                                    <span class="textFontDecoration primaryColor font14 mb-1 ml-1">{{ $t("message") }}</span>
                                </v-btn> -->
                <v-btn
                  value="features"
                  class="headerTag textDecorationBold"
                  text
                  v-ripple="false"
                  @click="openPhone"
                >
                  <v-icon color="green">mdi-phone-outline </v-icon>
                  <span
                    class="textFontDecoration primaryColor font14 mb-1 ml-1"
                    >{{ $t("call") }}</span
                  >
                </v-btn>
                <!-- <v-btn value="distances" class="headerTag textDecorationBold" text v-ripple="false">
                                    <v-icon color="green">mdi-message-outline </v-icon>
                                    <span class="textFontDecoration primaryColor font14 mb-1 ml-1">SMS</span>
                                </v-btn> -->
                <v-btn
                  value="actions"
                  class="headerTag textDecorationBold"
                  text
                  v-ripple="false"
                  @click="openEmail"
                >
                  <v-icon color="green">mdi-email-outline </v-icon>
                  <span class="textFontDecoration primaryColor font14 mb-1 ml-1"
                    >E-mail</span
                  >
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-divider />
          </v-row>
          <!-- <v-row>
                        <v-col>
                            <WhoWeAre :data="whoWeAreData" />
                        </v-col>

                    </v-row> -->
          <!-- <v-row>
                        <v-col>
                            <BrokersInfo :data="brokersInfoData" />
                        </v-col>
                    </v-row> -->
          <!-- <v-row>
                        <v-col>
                            <SecretaryInfo :data="secretaryInfoData" />
                        </v-col>
                    </v-row> -->
          <!-- <v-row>
                        <v-col>
                            <TeamMemberInfo :data="teamMemberInfoData" />
                        </v-col>
                    </v-row> -->
          <!-- <v-row>
                        <v-col>
                            <CompanyTeamAgent :data="companyTeamAgentData" />
                        </v-col>
                    </v-row> -->
          <!-- <v-row>
                        <v-col>
                            <MapInfo :locations="locations" />
                        </v-col>
                    </v-row> -->
          <!-- <v-row>
                        <v-col>
                            <ProviderInfo :data="prividerInfoData" />
                        </v-col>
                    </v-row> -->
          <!-- <v-row>
                        <v-col>
                            <OtherAdmins :data="otherAdminData" />
                        </v-col>
                    </v-row> -->
          <v-row
            v-if="
              'Associate' == data.Role ||
              data.Type.includes('Υποψήφιος Συνεργάτης')
            "
          >
            <v-col>
              <BioInfo
                :data="{
                  title: data.real_estate_specialization,
                  shortBio: data.short_bio,
                  CV:
                    data &&
                    data.cv_file_data &&
                    data.cv_file_data.file_path &&
                    ![null, undefined, ''].includes(data.cv_file_data.file_path)
                      ? data.cv_file_data.file_path
                      : null,
                }"
                :showBtn="
                  data &&
                  data.cv_file_data &&
                  data.cv_file_data.file_path &&
                  ![null, undefined, ''].includes(data.cv_file_data.file_path)
                "
              />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <ProfAcademicInfo :data="profAcademicInfoData" />
            </v-col>
          </v-row> -->

          <v-row v-if="data.Comments">
            <v-col align="left" class="pt-4" cols="12">
              <span class="textInterBold font14 goldText">
                {{ $t("comment") }}
              </span>
            </v-col>
            <v-col
              align="left"
              class="pt-0"
              :style="'font-size: 13px; font-family: InterMedium!important; color: #686D82'"
            >
              <span>{{ data.Comments }}</span>
            </v-col>
          </v-row>

          <v-row
            v-if="
              data.FirstName ||
              data.LastName ||
              data.FatherName ||
              data.Profession ||
              data.Birthday ||
              data.Nameday ||
              data.AFM ||
              data.DOY ||
              data.personalId
            "
          >
            <v-col>
              <PersonalInfo
                :authorizedContact="authorizedContact"
                :data="{
                  FirstName: data.FirstName,
                  LastName: data.LastName,
                  FatherName: data.FatherName,
                  Profession: data.Profession,
                  Birthday: data.Birthday,
                  Nameday: data.Nameday,
                  AFM: data.AFM,
                  DOY: data.DOY,
                  personalId: data.personalId,
                  agentData: data.agent_data ? data.agent_data[0] : null,
                }"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="
              authorizedContact == true &&
              (data.Country ||
                data.City ||
                data.Neighborhood ||
                data.Street ||
                data.Zip)
            "
          >
            <v-col>
              <AddressInfo
                :data="{
                  Country: data.Country,
                  City: data.City,
                  Neighborhood: data.Neighborhood,
                  Street: data.Street,
                  Zip: data.Zip,
                }"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="
              data.RegistrationDate ||
              data.Type ||
              data.IsEnabled ||
              data.Source ||
              data.Suffix ||
              data.UpdatedDay
            "
          >
            <v-col>
              <AccountInfo
                :data="{
                  Role: data.Role ? data.Role : '',
                  RegistrationDate: data.RegistrationDate,
                  Type: data.Type,
                  IsEnabled: data.IsEnabled,
                  Source: data.Source,
                  Suffix: data.Suffix,
                  UpdatedDay: data.UpdatedDay,
                }"
              />
            </v-col>
          </v-row>

          <v-row
            v-if="
              authorizedContact == true &&
              ![
                'Admin',
                'Associate',
                'Secretary',
                'Broker',
                'OfficeAdmin',
              ].includes(data.Role)
            "
          >
            <v-col>
              <ContactInfo
                :data="{
                  phoneNumber: data.Mobile,
                  otherPhoneNumber: data.Telephone,
                  email: data.Email,
                  otherEmail: data.Email2,
                }"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
                        <v-col>
                            <SocialMediaInfo :data="socailMediaInfoData" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <CooperationBetween :data="cooperationBetweenData" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <CommentInfo :subComment="true" :data="commentInfoData" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <OtherBrokersInfo :data="otherBrokersInfoData" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <ContactDetails :data="contactDetailsData" />
                        </v-col>
                    </v-row> -->
        </v-col>
        <v-col class="flex-grow-0">
          <v-row>
            <!-- <v-col>
                            <v-btn class="unsetCapitalize textDecorationBold" color="error" block>
                                {{ $t("recommendation") }}
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn class="unsetCapitalize textDecorationBold" outlined color="primary" block>
                                {{ $t("cooperation") }}
                            </v-btn>
                        </v-col> -->
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="3"
              sm="3"
              xs="3"
              v-if="authorizedContact == true && editProfile"
            >
              <v-btn
                class="unsetCapitalize textDecorationBold"
                outlined
                color="primary"
                block
                v-if="isAuthAddContact"
                @click="openEdit('edit', data)"
              >
                {{ $t("edit") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <TagsInfo
              :tags="data.tags"
              :savedTags="data.tags"
              :updateAttribute="updateTags"
              :authorizedContact="authorizedContact"
            />
          </v-row>
          <!-- <v-row>
                        <v-col>
                            <TeamInfo team="Team addAPT" :ratingData="2.5" :numberOfRatings="12" :showBtn="false" />
                        </v-col>
                    </v-row> -->
          <v-row
            v-if="
              [
                'Admin',
                'Associate',
                'Secretary',
                'Broker',
                'OfficeAdmin',
              ].includes(data.Role) &&
              (data.Address ||
                data.Telephone ||
                data.Mobile ||
                data.Email ||
                data.FB ||
                data.Instagram ||
                data.LinkedIn ||
                data.Twitter ||
                data.Suffix ||
                data.Website)
            "
          >
            <v-col>
              <PersonInfo
                :dataList="{
                  Address: data.Address,
                  Telephone: data.Telephone,
                  Mobile: data.Mobile,
                  Email: getEmail,
                  FB: data.FB,
                  Instagram: data.Instagram,
                  LinkedIn: data.LinkedIn,
                  Twitter: data.Twitter,
                  Suffix: data.Suffix,
                  Website: data.Website,
                  languages: data.languages,
                  qualifications: data.qualifications,
                }"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
                        <v-col>
                            <v-card>
                                <ActionInfo :dataList="dataList2" />
                            </v-card>
                        </v-col>
                    </v-row> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row class="px-6 mt-0">
        <v-col cols="12" align="left">
          <v-tabs :show-arrows="false" v-model="activeTab">
            <v-tabs-slider color="error"> </v-tabs-slider>
            <!-- <v-tab class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab">
              {{ $t("deals") }}
            </v-tab> -->
            <v-tab
              v-show="isAgentType"
              class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab"
            >
              {{ $t("availableEstates") }}
            </v-tab>
            <v-tab
              v-show="isAgentType"
              class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab"
            >
              {{ $t("soldEstates") }}
            </v-tab>
            <v-tab
              v-show="isOwnerType"
              class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab"
            >
              {{ $tc("realEstate", 2) }}
            </v-tab>
            <v-tab
              v-show="isOwnerType || isAgentType"
              class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab"
            >
              {{ $t("demands") }}
            </v-tab>
            <!-- <v-tab class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab">
              {{ $t("sentEstates") }}
            </v-tab> -->
            <!-- <v-tab class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab">
              {{ $t("offers") }}
            </v-tab> -->
            <v-tab
              v-show="allowDemands"
              class="textTranformUnset textDecorationBold unsetCapitalize mr-1 cusTab"
            >
              {{ $t("reviews") }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col class="px-0">
          <v-row>
            <v-col class="py-0 mt-n3 px-0">
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tabs-items v-model="activeTab">
                <v-tab-item v-show="isAgentType">
                  <AvailableEstates
                    :estatesData="estatesData"
                    :openRealEstateSlider="openRealEstateSlider"
                    :fetchRealEstate="fetchRealEstate"
                    :estateOptions="estateOptions"
                    :setEstatesItems="setEstatesItems"
                    :availableFilter="availableFilter"
                    :setAvailableFilter="setAvailableFilter"
                    :loading="loading"
                  />
                  <v-row>
                    <v-col class="py-5">
                      <v-pagination
                        class="main-pgt"
                        v-model="estateOptions.page"
                        :total-visible="6"
                        @input="fetchRealEstate"
                        :length="totalEstatesPages"
                      >
                      </v-pagination>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item v-show="isAgentType">
                  <SoldEstates
                    :estatesData="estatesData"
                    :openRealEstateSlider="openRealEstateSlider"
                    :fetchRealEstate="fetchRealEstate"
                    :estateOptions="estateOptions"
                    :setEstatesItems="setEstatesItems"
                    :loading="loading"
                  />
                  <v-row>
                    <v-col class="py-5">
                      <v-pagination
                        class="main-pgt"
                        v-model="estateOptions.page"
                        :total-visible="6"
                        @input="fetchRealEstate"
                        :length="totalEstatesPages"
                      >
                      </v-pagination>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item v-show="isOwnerType">
                  <CustomerEstates :customerData="data" />
                </v-tab-item>
                <v-tab-item v-show="isOwnerType || isAgentType">
                  <DemandsContact
                    :demandsData="demandsData"
                    :openDemandSlider="openDemandSlider"
                    :fetchDemands="fetchDemands"
                    :demandOptions="demandOptions"
                    :setDemandsItems="setDemandsItems"
                    :dataFoundDemands="dataFoundDemands"
                    :loading="loading"
                  />
                  <v-row>
                    <v-col class="py-5">
                      <v-pagination
                        class="main-pgt"
                        v-model="demandOptions.page"
                        :total-visible="6"
                        @input="fetchDemands"
                        :length="totalDemandsPages"
                      >
                      </v-pagination>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item v-show="allowDemands">
                  <ValuationDemands
                    :userData="data"
                    :editHandler="
                      (newData) => editValuationHandler(data, newData)
                    "
                    :allowEvaluation="authorizedContact == true && editProfile"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- <v-row class="px-6">
        <v-col class="pb-6">
          <FilesInfo :documents="documents" :docTypesList="docTypesList" />
        </v-col>
      </v-row> -->
    </v-col>
  </v-navigation-drawer>
</template>

<script>
/*
Name:ContactSlider
Description: Component to render the main slider for a contact. This slider contains all the information for a contact with edit and delete functions.

API Property:
  data: Object with data 
  openEditSlider: Function to open the edit for contact slider
  closeHandler: Function to close the slider
  updateIsSavedHandler: Function to update the contact
  openRealEstateSlider: Function to open real estate slider
  editProfile: Boolean to enable or disable edit profile

  Components Used:
    PersonAppBar
    TagsInfo
    PersonInfo
    StatusField
    BioInfo
    PersonalInfo
    AddressInfo
    AccountInfo
    CustomerEstates
    ContactInfo
    AvailableEstates
    SoldEstates

  Endpoints Functions Called:
    -RealEstateRepo
      get
    
    -ContactsRepo
      editContact
*/

//import BrokerAppBar from "@/components/Persons/BrokerAppBar.vue";
//import PartnersAppBar from "@/components/Persons/PartnersAppBar.vue";
//import MapInfo from '@/components/Contacts/ContactSlider/MapInfo.vue'
import PersonAppBar from "@/components/Persons/PersonAppBar.vue";
import PersonInfo from "@/components/Persons/PersonInfo.vue";
import TagsInfo from "@/components/Fields/TagsInfo/TagsInfo.vue";
//import TeamInfo from "@/components/Fields/TeamInfo/TeamInfo.vue"
//import RatingField from "@/components/Fields/RatingField/RatingField";
//import ActionInfo from "@/components/Fields/ActionInfo/ActionInfo.vue"
import StatusField from "@/components/Fields/StatusField/StatusField.vue";
//import WhoWeAre from '@/components/Contacts/ContactSlider/WhoWeAre.vue';
//import BrokersInfo from '@/components/Contacts/ContactSlider/BrokersInfo.vue';
//import SecretaryInfo from '@/components/Contacts/ContactSlider/SecretaryInfo.vue';
//import OtherAdmins from '@/components/Contacts/ContactSlider/OtherAdmins.vue';
import BioInfo from "@/components/Contacts/ContactSlider/BioInfo.vue";
import PersonalInfo from "@/components/Contacts/ContactSlider/PersonalInfo.vue";
import AddressInfo from "@/components/Contacts/ContactSlider/AddressInfo.vue";
import AccountInfo from "@/components/Contacts/ContactSlider/AccountInfo.vue";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import ContactInfo from "@/components/Contacts/ContactSlider/ContactInfo.vue";
//import SocialMediaInfo from '@/components/Contacts/ContactSlider/SocialMediaInfo.vue';
//import CooperationBetween from '@/components/Contacts/ContactSlider/CooperationBetween.vue';
//import CommentInfo from '@/components/Contacts/ContactSlider/CommentInfo.vue';
//import OtherBrokersInfo from '@/components/Contacts/ContactSlider/OtherBrokersInfo.vue';
//import ContactDetails from '@/components/Contacts/ContactSlider/ContactDetails.vue';
// import FilesInfo from "@/components/Contacts/ContactSlider/FilesInfo.vue";
// import ContactModel from "@/static/contacts/contacts.js";
//import CompanyTeamAgent from '@/components/Contacts/ContactSlider/CompanyTeamAgent.vue';
//import TeamMemberInfo from '@/components/Contacts/ContactSlider/TeamMemberInfo.vue';
//import ProviderInfo from '@/components/Contacts/ContactSlider/ProvidersInfo.vue';
//import ProfAcademicInfo from '@/components/Contacts/ContactSlider/ProfAcademicInfo.vue';
import Repository from "@/services/Repository";
import moment from "moment";
import i18n from "@/i18n/i18n";
// import store from "@/store";
import CustomerEstates from "@/components/Contacts/ContactSlider/CustomerEstates.vue";
import AvailableEstates from "@/components/Contacts/ContactSlider/AvailableEstates.vue";
import DemandsContact from "@/components/Contacts/ContactSlider/DemandsContact.vue";
import SoldEstates from "@/components/Contacts/ContactSlider/SoldEstates.vue";
import { mapState, mapActions } from "vuex";
import ContactModal from "@/static/reviews/contactEstates";
import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
import DemandHelper from "@/helpers/Demand/demand.js";
import CoreHelper from "@/helpers/Core/core";
import ValuationDemands from "@/components/Demands/Slider/ValuationDemands.vue";

const RealEstateRepo = Repository.get("realEstate");
const RequestsDemandRepo = Repository.get("reqDemand");
const ContactsRepo = Repository.get("contacts");
export default {
  name: "ContactSlider",
  props: {
    data: Object,
    openEditSlider: Function,
    closeHandler: Function,
    updateIsSavedHandler: Function,
    openRealEstateSlider: Function,
    openDemandSlider: Function,
    editProfile: { type: Boolean, default: true }, //Enable or disable edit profile
  },
  components: {
    //BrokerAppBar,
    //PartnersAppBar,
    PersonAppBar,
    TagsInfo,
    PersonInfo,
    DemandsContact,
    //TeamInfo,
    //RatingField,
    //ActionInfo,
    StatusField,
    //WhoWeAre,
    //BrokersInfo,
    //SecretaryInfo,
    //MapInfo,
    //OtherAdmins,
    BioInfo,
    PersonalInfo,
    AddressInfo,
    AccountInfo,
    CustomerEstates,
    ContactInfo,
    AvailableEstates,
    SoldEstates,
    //SocialMediaInfo,
    //CooperationBetween,
    //CommentInfo,
    //OtherBrokersInfo,
    //ContactDetails,
    // FilesInfo,
    //CompanyTeamAgent,
    //TeamMemberInfo,
    //ProviderInfo,
    //ProfAcademicInfo,
    ValuationDemands,
  },
  async created() {
    this.activeTab = this.isAgentType ? 0 : this.isOwnerType ? 2 : 4;
    await this.fetchDemands();
    this.estatesData = await this.fetchRealEstate();
    if (this.data.IsEnabled) {
      if (["true", true, "active"].includes(this.data.IsEnabled)) {
        this.statusFieldEnabled = i18n.t("active2");
        this.statusFieldColor = "#77BD86";
        this.statusFieldBackColor = "rgba(119, 189, 134, 0.18)";
      }
      if (["false", false, "inactive"].includes(this.data.IsEnabled)) {
        this.statusFieldEnabled = i18n.t("inactive2");
        this.statusFieldColor = "#E52628";
        this.statusFieldBackColor = "rgba(229, 38, 40, 0.15)";
      }
      if (["semi-active"].includes(this.data.IsEnabled)) {
        this.statusFieldEnabled = i18n.t("semiActive2");
        this.statusFieldColor = "#E89008";
        this.statusFieldBackColor = "rgba(232, 144, 8, 0.18)";
      }
    } else {
      if (!["", null, undefined].includes(this.data.Type)) {
        this.statusFieldEnabled =
          this.data.Type[0] +
          (this.data.Type.length > 1 ? " +" + this.data.Type.length : "");
        this.statusFieldColor = "#77BD86";
        this.statusFieldBackColor = "rgba(119, 189, 134, 0.18)";
      } else {
        this.statusFieldEnabled = "";
      }
    }
    this.type = "Person";
  },
  data() {
    return {
      headers: ContactModal.headersAvailableEstates,
      templates: ContactModal.templateAvailableEstates,
      statusFieldEnabled: null,
      statusFieldColor: null,
      statusFieldBackColor: null,
      show: true,
      type: null,
      totalResult: 0,
      totalPages: 0,
      activeTab: this.isAgentType ? 0 : this.isOwnerType ? 2 : 4,
      dataFoundDemands: null,
      totalDemandsResult: 0,
      totalDemandsPages: 0,
      demandsData: [],
      demandOptions: {
        page: 1,
        items: 10,
      },
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      estateOptions: {
        page: 1,
        items: 3,
      },
      estatesData: [],
      availableEstates: [],
      totalEstatesPages: 0,
      totalEstatesResult: 0,
      fetchEstateType: "available",
      availableFilter: 0,
      loading: false,
    };
  },
  watch: {
    activeTab(newVal, oldVal) {
      if (newVal != oldVal && this.isAgentType) {
        this.fetchRealEstate();
      }
    },
    show() {
      if (!this.show) {
        this.closeHandler();
      }
    },
  },
  computed: {
    ...mapState({
      realEstateFilter: (state) => state.realEstate,
      userDetails: (state) => state.auth.userDetails,
    }),
    sort() {
      let lSort = this.headers.filter((f) => {
        return f.sort;
      });
      return lSort.length == 0 ? [{}] : lSort;
    },
    getEmail() {
      if (this.data.Email) {
        var result = this.data.Email.substring(
          this.data.Email.lastIndexOf("@") + 1
        );
        if (result == "newdeal.gr") {
          return this.data.Email;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    authorizedContact() {
      return this.isAuthorizedContact({
        role: this.data.Role ? this.data.Role : "",
        type: this.data.Type && this.data.Type.length > 0 ? this.data.Type : [],
        _id: this.data._id ? this.data._id : "",
        lastCompany: this.data.LastCompany ? this.data.LastCompany : "",
      });
    },

    getTypeContact() {
      var result;
      if (this.data.Role) {
        if (this.data.Role == "Admin") {
          result = "admin";
        } else if (this.data.Role == "OfficeAdmin") {
          result = "Broker";
        } else if (this.data.Role == "Secretary") {
          result = i18n.t("secretariat");
        } else if (this.data.Role == "HR") {
          result = "HR Specialist";
        } else if (this.data.Role == "Associate") {
          result = "Agent Pro";
        }
      } else {
        if (
          [
            "Πελάτης",
            "Πελάτης για Αγορά",
            "Πελάτης για Ενοικίαση",
            "Ενδιαφερόμενος για Αγορά",
            "Ενδιαφερόμενος για Ενοικίαση",
            "Ενδιαφερόμενος με Αντιπαροχή",
          ].some((ai) => this.data.Type.includes(ai))
        ) {
          result = i18n.t("customer");
        } else if (this.data.Type.includes("Member")) {
          result = "Member";
        } else if (this.data.Type.includes("Υποψήφιος Συνεργάτης")) {
          result = "associateCandidate";
        } else if (
          [
            "Ιδιοκτήτης για Πώληση",
            "Ιδιοκτήτης για Ενοικίαση",
            "Ιδιοκτήτης για Αγορά",
          ].some((ai) => this.data.Type.includes(ai))
        ) {
          result = "owner";
        }
      }
      return result;
    },
    allowDemands() {
      let result = true;
      if (
        [
          "Ιδιοκτήτης για Πώληση",
          "Ιδιοκτήτης για Ενοικίαση",
          "Ιδιοκτήτης για Αγορά",
          "Ιδιοκτήτης με Αντιπαροχή",
          "Πελάτης",
          "Πελάτης για Αγορά",
          "Πελάτης για Ενοικίαση",
          "Ενδιαφερόμενος για Αγορά",
          "Ενδιαφερόμενος για Ενοικίαση",
          "Ενδιαφερόμενος με Αντιπαροχή",
        ].some((obj) => this.data.Type.includes(obj))
      ) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    isAgentType() {
      let result = false;
      if (["OfficeAdmin", "Associate"].includes(this.data.Role)) {
        result = true;
      }
      return result;
    },
    isOwnerType() {
      let result = false;
      if (
        [
          "Ιδιοκτήτης για Πώληση",
          "Ιδιοκτήτης για Ενοικίαση",
          "Ιδιοκτήτης για Αγορά",
          "Ιδιοκτήτης με Αντιπαροχή",
        ].some((obj) => this.data.Type.includes(obj))
      ) {
        result = true;
      }
      return result;
    },
    avatarPic() {
      return !["", null, undefined].includes(this.data.Photo)
        ? { path: this.data.Photo, absolutePath: false }
        : null;
    },
    userFavorites: {
      get() {
        return this.userDetails;
      },
      set(newValue) {
        try {
          this.$store.dispatch("onSetUserDetails", newValue);
        } catch (err) {
          console.error(err);
        }
      },
    },
    isSaved: {
      get() {
        return this.data.isSaved;
      },
      set(newValue) {
        try {
          this.updateIsSavedHandler(newValue);
        } catch (err) {
          console.error(err);
        }
      },
    },
    isAuthAddContact() {
      return (
        this.userDetails.Type &&
        this.userDetails.Type.type.includes("Συνεργάτης")
      );
    },
  },
  methods: {
    ...mapActions(["onSetRefreshPage"]),
    characteristicsInfo(object) {
      return RealEstateHelper.getCharacteristicsEstates(object);
    },
    openEmail() {
      window.location.href = "mailto:" + this.data.Email;
    },
    openPhone() {
      window.location.href = "tel:" + this.data.Telephone;
    },
    editValuationHandler(data, newEval) {
      data.customer_evaluation = newEval;
    },
    async setEstatesItems(val) {
      this.estateOptions.items = val;
      await this.fetchRealEstate();
    },
    async setDemandsItems(val) {
      this.demandOptions.items = val;
      await this.fetchDemands();
    },
    isAuthorizedContact(contactObject) {
      return ContactsHelper.isAuthorizedContact(contactObject);
    },
    async optionsRefresh() {
      this.options.page = 1;
      await this.fetchRealEstate();
    },

    async setAvailableFilter(val) {
      this.availableFilter = val;
      await this.fetchRealEstate();
    },

    getPhoto(obj) {
      return obj.Photo
        ? obj.Photo
        : obj.FirstName && obj.LastName
        ? obj.FirstName.charAt(0) + obj.LastName.charAt(0)
        : obj.FirstName
        ? obj.FirstName.charAt(1)
        : obj.LastName
        ? obj.LastName.charAt(1)
        : "";
    },
    async updateTags(atrr, val) {
      let body = {
        tags: val,
      };
      try {
        await ContactsRepo.editContact(this.data._id, body);
        this.loading = false;
        this.data[atrr] = val;
        //return resp.data.tags;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },

    async fetchDemands(type, id) {
      try {
        let object = {};
        let array = [];
        let resp = [];

        this.loading = true;
        let params;
        if (type != "specific") {
          if (this.isAgentType) {
            object = { $and: [{ _id: this.data._id }] };
          } else if (this.isOwnerType) {
            object = { $and: [{ ClientID: this.data._id }] };
          }
          if (Object.keys(this.sort[0]).length === 0) {
            params = {
              page: this.demandOptions.page - 1,
              items: this.demandOptions.items,
            };
          } else {
            let lSort = await CoreHelper.customStringify(
              this.sort[0].value,
              this.sort[0].sort == "asc" ? 1 : -1 /* 1 Asc, -1 Desc */
            );
            params = {
              page: this.demandOptions.page - 1,
              items: this.demandOptions.items,
              sort: lSort,
            };
          }
          resp = await RequestsDemandRepo.get(object, params);
        } else {
          params = {
            page: 0,
            items: 1,
          };
          object = { $and: [{ _id: id }] };
          resp = await RequestsDemandRepo.get(object, params);
        }
        if (resp.data != undefined && resp.data.requests != undefined) {
          if (resp.data.requests.length > 0) {
            this.dataFoundDemands = "true";
            array = DemandHelper.initData(resp.data, this.currency);
            if (type == "specific") {
              // Change the data to child
              //this.dialogArray[this.dialogArray.length - 1].data = array[0];
              // Change the data to parent for the specific demand
              let index = this.demandsData.findIndex((d) => {
                return d._id === array[0]._id;
              });
              this.demandsData[index] = array[0];
            } else {
              this.demandsData = array[0];
              this.totalDemandsResult = resp.data.results;
              this.totalDemandsPages = Math.ceil(
                this.totalDemandsResult / this.demandOptions.items
              );
              if (this.totalDemandsPages == 0) this.totalDemandsPages = 1;
            }
          } else {
            if (type != "specific") {
              this.dataFoundDemands = "false";
              this.demandsData = [];
              this.totalDemandsResult = 0;
              this.totalDemandsPages = 0;
            }
          }
        }
        this.loading = false;
      } catch (e) {
        this.dataFoundDemands = "false";
        this.loading = false;
        throw e;
      }
    },

    async fetchRealEstate() {
      this.estatesData = [];
      this.loading = true;
      let object;
      // if (this.fetchEstateType == "available") {
      if (this.activeTab == 0) {
        if (this.availableFilter == 0) {
          object = { $and: { UserID: this.data._id, Task: ["Πώληση"] } };
        } else {
          object = { $and: { UserID: this.data._id, Task: ["Ενοικίαση"] } };
        }
        // } else if (this.fetchEstateType == "sold") {
      } else if (this.activeTab == 1) {
        object = {
          $and: {
            UserID: this.data._id,
            Status: { $in: ["Πουλήθηκε", "Ενοικιάστηκε"] },
          },
        };
      }
      try {
        this.realEstateFilter.refreshPage
          ? (this.estateOptions.page = 1)
          : null;
        let params;
        if (Object.keys(this.sort[0]).length === 0) {
          params = {
            page: this.estateOptions.page - 1,
            items: this.estateOptions.items,
          };
        } else {
          let lSort = await CoreHelper.customStringify(
            this.sort[0].value,
            this.sort[0].sort == "asc" ? 1 : -1 /* 1 Asc, -1 Desc */
          );
          params = {
            page: this.estateOptions.page - 1,
            items: this.estateOptions.items,
            sort: lSort,
          };
        }

        var resp = await RealEstateRepo.get(object, params);
        this.estatesData =
          resp == undefined ||
          resp.data == undefined ||
          resp.data.estates == undefined
            ? []
            : resp.data.estates;
        if (this.estatesData.length > 0) {
          this.dataFoundStatus = "true";
          this.totalEstatesResult = resp.data.results;
          this.totalEstatesPages = Math.ceil(
            this.totalEstatesResult / this.estateOptions.items
          );
          this.estatesData.map((m) => {
            if (m.floors && m.floors.length > 0) {
              m.floors.map((mf) => {
                if (![null, undefined, ""].includes(mf.FloorLiteral)) return mf;
                else if (![null, undefined, ""].includes(mf.FloorValue)) {
                  mf.FloorLiteral =
                    this.$store.getters.getFloorDescriptionByVal(
                      Number(mf.FloorValue)
                    );
                } else if (![null, undefined, ""].includes(mf.Value)) {
                  mf.FloorLiteral =
                    this.$store.getters.getFloorDescriptionByVal(
                      Number(mf.Value)
                    );
                } else if (!isNaN(mf)) {
                  mf = {
                    FloorLiteral: this.$store.getters.getFloorDescriptionByVal(
                      Number(mf)
                    ),
                    FloorValue: mf,
                  };
                }

                return mf;
              });
            }
            m["characteristicsArray"] = this.characteristicsInfo(m);
          });
        } else {
          this.dataFoundStatus = "false";
          this.totalEstatesResult = 0;
          this.totalEstatesPages = 0;
        }
        if (this.realEstateFilter.refreshPage) this.onSetRefreshPage(false);
        this.loading = false;
        return this.estatesData;
      } catch (e) {
        this.dataFoundStatus = "error";
        this.estatesData = [];
        this.loading = false;
        this.totalEstatesResult = 0;
        this.totalEstatesPages = 0;
        if (this.realEstateFilter.refreshPage) this.onSetRefreshPage(false);
        throw e;
      }
    },
    openEdit(type, data) {
      this.openEditSlider(type, data);
    },

    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    // closeEditDialog(type, id) {
    //   this.dialog = {
    //     open: false,
    //     type: null,
    //     data: null,
    //   };
    //   if (type == "specificEdit" && id != undefined && id != null)
    //     this.fetchSpecificContact(id);
    // },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    splitCompany() {
      if (this.data.company_name) {
        let array = this.data.company_name.split(" ");
        array.shift();
        array = array.join(" ");
        return array;
      } else if (this.data.Company) {
        let array = this.data.Company.split(" ");
        array.shift();
        array = array.join(" ");
        return array;
      } else {
        return this.data;
      }
    },
    onCloseHandler() {
      this.closeHandler();
    },
    async addToFavorites() {
      this.isSaved = !this.isSaved;
      let favorite_contacts = [];
      if (this.isSaved) {
        if (this.userFavorites.favorite_contacts) {
          favorite_contacts = [
            ...this.userFavorites.favorite_contacts,
            this.data._id,
          ];
        } else {
          favorite_contacts.push(this.data._id);
        }
        this.userFavorites.favorite_contacts = favorite_contacts;
      } else {
        // favorite_contacts = [...this.userFavorites.favorite_contacts];
        let id2 = this.data._id;
        favorite_contacts = this.userFavorites.favorite_contacts.filter(
          function (id) {
            return id != id2;
          }
        );
        this.userFavorites.favorite_contacts = favorite_contacts;
      }
      try {
        let body = {};
        body["UpdatedDay"] = moment(new Date()).unix();
        body["favorite_contacts"] = this.userFavorites.favorite_contacts;
        await ContactsRepo.editContact(this.userDetails._id, body);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },
  },
};
</script>

<style>
.headerTag {
  text-transform: unset !important;
  border: unset !important;
  background-color: unset !important;
  font-size: 16px;
  color: #3a3a40;
}

.headerTag.v-btn:before {
  background-color: unset !important;
}
</style>