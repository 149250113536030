import axios from "axios";


const baseDomain = `${process.env.VUE_APP_BASE_URL}`;
const baseURL = `${baseDomain}`;

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const AxiosClient = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",

    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE"
  },
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  return request;
};

const responseHandler = (response) => {
  return response;
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
AxiosClient.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => {
    return Promise.reject(error);
  }
);
AxiosClient.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    return Promise.reject(error);
  }
);
// Step-4: Export the newly created Axios instance to be used in different locations.
export default AxiosClient;
