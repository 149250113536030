// import i18n from "../../i18n/i18n";

export default {
    callsData: [
        {
            dateTime: "2022-03-16 13:56:20",
            isRead: false,
            content:
                "<span style='color:#f44336'> Γιάννης Παπαπέτρου </span> <br> <span> Απλή επικοινωνία </span>",
            viewType: "img", //"icon"
            img: require("@/assets/notifications/green_phone.png"),
        },
        {
            dateTime: "2022-03-14 13:56:20",
            isRead: false,
            content:
                "<span style='color:#f44336'> Νικόλαος Τζερεμές </span> <br> <span> Ζήτηση </span>",
            img: require("@/assets/notifications/red_phone.png"),
        },
        {
            dateTime: "2022-03-15 09:56:20",
            isRead: true,
            content:
                "<span style='color:#f44336'> Νικόλαος Τζερεμές </span> <br> <span> Ζήτηση </span>",
            img: require("@/assets/notifications/green_phone.png"),
        },
    ],
    notificationData: [
        {
            dateTime: "2022-03-13 13:56:20",
            isRead: false,
            content:
                "Ο χρήστης <span style='color:#f44336'> Γιώργος Χατζής </span> ανέλαβε το ακίνητο με κωδικό <span style='color:#f44336'> ΠΧΑ5574 </span> ",
            img: require("@/assets/general/Ellipse_1.png"),
        },
        {
            dateTime: "2022-03-15 15:56:20",
            isRead: false,
            content:
                "Ο χρήστης <span style='color:#f44336'> Νίκος Παπαδόπουλος </span> θέλει να συνεργαστείτε.",
            img: require("@/assets/general/Ellipse_1.png"),
        },
        {
            dateTime: "2022-03-15 13:53:20",
            isRead: true,
            content:
                "Ο χρήστης <span style='color:#f44336'> Νίκος Παπαδόπουλος </span> θέλει να συνεργαστείτε.",
            img: require("@/assets/general/Ellipse_1.png"),
        },
    ],
};
