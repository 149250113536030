<template>
  <v-row class="align-center" :class="masterRowClass">
    <v-col
      class="flex-grow-0"
      :class="iconColClass"
      v-if="iconObj && iconObj.icon"
    >
      <Icon
        :icon="iconObj.icon"
        :height="iconObj.height ? iconObj.height : '30'"
        v-bind:[iconObj.attr]="true"
        :color="iconObj.color ? iconObj.color : color ? color : '#77BD86'"
      />
    </v-col>
    <v-col v-if="nameContent" align="left" class="pl-1 pt-0 flex-grow-0">
      <v-row class="align-center">
        <v-col
          class="pb-0 text-no-wrap textDecorationBold font16"
          :class="nameContentClass"
          :style="'color:' + color"
        >
          <span> {{ nameContent }} </span>
        </v-col>
      </v-row>
      <v-row v-if="subContent" class="align-center">
        <v-col
          class="py-0 text-no-wrap font16"
          :class="subContentClass"
          :style="'color:' + color"
        >
          <span> {{ subContent }} </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
/* 
   Name:Avatar Field
   Desc
   masterRowClass: String containing classes for master row
   nameContent: String containing the nameContent data
   nameContentClass: String containing classes for name
   subContent: String containing the sub content data
   subContentClass: String containing classes for subContent
   iconObj: object of icon
              iconObj: {
                  icon: "ant-design:up-outlined",
                  height: 15,
                  attr: outline,
                  color: "white"
              }
    iconColClass: String containing the classes for icon col
    color: String containing the color of the data

  Components Used:
    Icon
*/
import { Icon } from "@iconify/vue2";
export default {
  name: "AvatarField",
  props: {
    masterRowClass: String,
    nameContent: String,
    nameContentClass: String,
    subContent: String,
    subContentClass: String,
    iconObj: Object,
    iconColClass: String,
    color: String,
  },
  components: {
    Icon,
  },
};
</script>