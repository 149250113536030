 <template>
  <v-col @click="clickFunc()" align="left">
    <v-row>
      <v-col>
        <v-img :src="require('@/assets/realEstateSilder/nd_icon.png')"
          :lazy-src="require('@/assets/realEstateSilder/nd_icon.png')" width="200" class="grey lighten-2 imgBorder">
        </v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="left" class="pb-0 pt-0 textDecorationBold font14 titleColor">
        <span>
          <v-icon small class="bold mr-1 textBlack" v-if="visibility == 'private'">
            mdi-eye-off
          </v-icon>
        </span>
        <span>
          {{ name }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="left" class="pt-0 pb-0 primaryColor font12">
        <span class="textDecorationBold"> {{ type }} </span>
        <span> | {{ size }} | {{ $t("created") }} {{ created }} </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pl-0 pb-0 pr-0">
        <v-divider> </v-divider>
      </v-col>
    </v-row>
  </v-col>
</template> 

 <script>
 
/*
 Name: MediaGalleryItem
 Description: Component for the real estate media gallery item
 API Property: 
    name: String for name of file
    size: String for the size of file
    visibility: String for the visibility of file
    created: String for the date created of file
    type: String for the type of file
    clickHandler: Function on click handler
*/
export default {
  name: "MediaGalleryItem",
  props: {
    name: { type: String, default: "" }, // Name of file
    size: { type: String, default: "" }, // Size of file
    visibility: { type: String, default: "private" }, //Visibility of file
    created: { type: String, default: "" }, // Date created of file
    type: { type: String, default: "" }, // type of file
    clickHandler: Function,
  },
  methods: {
    clickFunc() {
      try {
        this.clickHandler();
      } catch {
        true;
      }
    },
  },
};
</script> 

 <style lang="scss" scoped>
 .imgBorder {
   border-radius: 5px;
 }
 
 .font12 {
   font-size: 12px;
 }
 
 .textBlack {
   color: black !important;
 }
 
 .titleColor {
   color: #3a3a40;
 }
 </style> 
