<template>
  <v-col class="greyLeftBorder" :class="$vuetify.breakpoint.xsOnly == true ? '' : 'pl-7'">
    <v-row>
      <v-col class="font14 primaryColor pl-5" align="left">
        {{ title + " (" + filesLength + ")" }}
      </v-col>
    </v-row>
    <v-row v-if="filesLength > 0">
      <v-col style="overflow:auto; max-height: 300px !important;'">
        <v-row v-for="(document, index) in documentsArray" :key="index" class="justify-space-between"
          :class="{ greyBottomBorder: checkIfIsLast(index) }">
          <v-col class="">
            <v-row class="align-center">
              <v-col :class="!getSrc(document) ? 'flex-grow-0' : 'flex-grow-0 pb-0'">
                <!-- 
                    ? 'itemIcon iconSize d-flex justify-center align-center textDecorationBold' -->
                <v-img v-if="getSrc(document)" class="iconSize" :src="getSrc(document)" />
                <span v-else class="itemIcon textDecorationBold iconSize">
                  .{{ getFileType(document).toLowerCase() }}
                </span>
              </v-col>
              <v-col class="mt-3">
                <v-row class="textDecorationBold font14 titleColor">
                  <v-tooltip top :open-on-hover="getFileName(document).length > maxChar">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{
                            getFileName(document).slice(0, maxChar) +
                            (getFileName(document).length > maxChar ? ".." : "")
                        }}
                      </span>
                    </template>
                    <span>{{ getFileName(document) }}</span>
                  </v-tooltip>
                </v-row>
                <v-row class="primaryColor fontSize12">
                  <span v-if="mobileBreakpoint != true && getFileType(document)" class="textDecorationBold">
                    {{ getFileType(document) }} |
                  </span>
                  <span v-if="getFileSize(document)">
                    {{ getFileSize(document) }} |
                  </span>
                  <span v-if="getDate(document)">
                    {{ getDate(document) }}
                  </span>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="flex-grow-0">
            <v-row class="justify-space-between flex-nowrap">
              <v-col class="pb-0 pl-0 pr-0" v-if="
                document.visibility != 'public' &&
                mobileBreakpoint != true &&
                supportUpdate != true
              ">
                <HintField icon="mdi-eye-off" color="#000000" class="noTextWrap" :data="$t('visibleOnlyToYou')" />
              </v-col>
              <v-col v-if="
                document.visibility != 'public' &&
                mobileBreakpoint == true &&
                supportUpdate != true
              " class="mt-4 pl-0">
                <Icon icon="mdi:eye-off" height="19" width="28" color="#707070" />
              </v-col>
              <v-col align="right">
                <v-row class="justify-end flex-nowrap">
                  <v-col v-if="supportUpdate == true" class="pb-0 pt-5 pl-0 pr-0 flex-grow-0">
                    <v-btn plain icon class="flex-grow-0 pl-0 pr-0" @click="publishDoc(document)">
                      <Icon class="primaryColorOnHover" :icon="
                        document.visibility == 'public'
                          ? 'bi:eye-fill'
                          : 'mdi:eye-off'
                      " height="19" width="28" color="#707070" />
                    </v-btn>
                  </v-col>
                  <v-col v-if="supportDownload" class="pb-0 pt-5 pl-0 pr-0 flex-grow-0">
                    <v-btn plain icon class="flex-grow-0 pl-0 pr-0" @click="downloadDoc(document)">
                      <Icon class="primaryColorOnHover" icon="bxs:download" height="19" width="28" color="#707070" />
                    </v-btn>
                  </v-col>
                  <v-col v-if="supportDelete" class="pb-0 pt-5 pl-0 pr-0 flex-grow-0">
                    <v-btn plain icon class="flex-grow-0 pl-0 pr-0" @click="deleteDoc(document)">
                      <Icon class="primaryColorOnHover" icon="ph:trash-light" height="19" width="28" color="#707070" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-row class="justify-center">
          <v-img max-width="50" max-height="62" :src="require('@/assets/documents/emptyFile.png')">
          </v-img>
        </v-row>
        <v-row class="mt-4 justify-center textDecorationBold fontSize22">
          {{ $t("noFilesFound") }}
        </v-row>
        <v-row class="mt-2 justify-center font15">
          {{ $t("noFilesUploadedYet") }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-5 justify-center">
      <v-btn v-if="filesLength > 3" class="mb-2 primaryColor unsetCapitalize opacity1" text plain @click="onClickMore()">
        {{ moreOrLess }}...
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
/* 
  Name:FilesTable
  Description: FilesTable component to generate quickly File tables. Supports update,delete,download
  API Property 
  title: String containing title of selected category/subCategory
  documents: Array containing documents
  imgExtensions: Array containing image extensions
  supportUpdate:  Boolean variable to show/hide the update button
  updateHandler: Handler function from parent when updating file
  supportDelete: Boolean variable to show/hide the delete button
  deleteHandler: Handler function from parent when deleting file
  supportDownload: Boolean variable to show/hide the download button
  downloadHandler: Handler function from parent when downloading file  

  Components Used:
    HintField
    Icon
*/
import HintField from "@/components/Fields/HintField/HintField.vue";
import CoreHelper from "@/helpers/Core/core.js";

import { Icon } from "@iconify/vue2";
import i18n from "@/i18n/i18n";
export default {
  name: "FilesTable",
  props: {
    title: String, // Title of selected category/subCategory
    documents: Array, // Array of documents
    imgExtensions: {
      type: Array,
      default: function () {
        return ["jpeg", "png", "jpg"];
      },
    }, // Array containing image extensions
    supportUpdate: { type: Boolean, default: false },
    updateHandler: Function, //Function that updates file
    supportDelete: { type: Boolean, default: false },
    deleteHandler: Function, //Function that deleted file
    supportDownload: { type: Boolean, default: false },
    downloadHandler: Function, // Function that downloads file
  },
  components: {
    HintField,
    Icon,
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.width < 800;
    },
    maxChar() {
      return this.$vuetify.breakpoint.width >= 665 ||
        (this.$vuetify.breakpoint.width < 620 &&
          this.$vuetify.breakpoint.width > 375)
        ? 27
        : 20;
    },
    documentsArray() {
      try {
        if (!this.showAll) {
          return this.documents[0].docs.slice(0, 3);
        } else {
          return this.documents[0].docs;
        }
      } catch {
        return [1];
      }
    },
    moreOrLess() {
      if (this.showAll) {
        return i18n.t("less");
      } else {
        return i18n.t("more");
      }
    },
    filesLength() {
      try {
        return this.documents[0].count;
      } catch {
        return 0;
      }
    },
  },
  methods: {
    publishDoc(doc) {
      try {
        this.updateHandler(doc);
      } catch (e) {
        throw e;
      }
    },
    downloadDoc(doc) {
      try {
        this.downloadHandler(doc);
      } catch {
        true;
      }
    },
    deleteDoc(doc) {
      try {
        this.deleteHandler(doc);
      } catch {
        true;
      }
    },
    onClickMore() {
      this.showAll = !this.showAll;
    },
    checkIfIsLast(index) {
      try {
        return index < this.documentsArray.length - 1;
      } catch {
        return false;
      }
    },
    getFileName(document) {
      return document.name != undefined
        ? document.name
        : document.path != undefined
          ? CoreHelper.getFileNameFromUrl(document.path)
          : "";
    },
    getFileType(document) {
      return document.name != undefined
        ? CoreHelper.getFileType(document.name).toUpperCase()
        : document.path != undefined
          ? CoreHelper.getFileType(document.path).toUpperCase()
          : "";
    },
    getSrc(document) {
      let type =
        document.name != undefined
          ? CoreHelper.getFileType(document.name).toLowerCase()
          : document.path != undefined
            ? CoreHelper.getFileType(document.path).toLowerCase()
            : "";
      return type == "pdf"
        ? require("@/assets/documents/pdfCircleIcon.png")
        : this.imgExtensions.includes(type.toLowerCase())
          ? require("@/assets/documents/img-icon.png")
          : false;
    },
    getFileSize(document) {
      return document.size != undefined
        ? !isNaN(document.size)
          ? CoreHelper.getFileSize(document.size)
          : ""
        : "";
    },
    getDate(document) {
      return document.timestamp != undefined
        ? CoreHelper.EpochToDate(document.timestamp)
        : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.greyBottomBorder {
  border-bottom: 1px solid #adb3ca40 !important;
}

.greyLeftBorder {
  border-left: 1px solid #adb3ca40 !important;
}

@media only screen and (max-width: 600px) {
  .greyLeftBorder {
    border-left: 0px !important;
  }
}

.noTdBorder {
  border-bottom: 0px !important;
}

.noPadding {
  padding: 0px !important;
}

.noPadding-left {
  padding-left: 0px !important;
}

.circleIconSize {
  width: 19.34px;
  height: 19.34px;
}

.eyeIcon {
  width: 28.37px !important;
  height: 19.34px !important;
}

.noVisibleIcon {
  width: 14.06px !important;
  height: 12.05px !important;
}

td:first-child {
  width: 22px;
}

.fontSize22 {
  font-size: 22px;
}

.fontSize12 {
  font-size: 12px;
}

.noVisibleCss {
  text-transform: unset !important;
  font-size: 10px;
}

.noTextWrap {
  white-space: nowrap;
}

.titleColor {
  color: #3a3a40;
}

.theme--light.v-btn.v-btn--disabled {
  color: #43425d !important;
}

.iconSize {
  max-width: 34px !important;
  max-height: 35px !important;
}

.itemIcon {
  background-color: #e52628;
  color: #ffffff !important;
  font-size: xx-small;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .iconSize {
    max-width: 32px !important;
    max-height: 32px !important;
  }
}
</style>
