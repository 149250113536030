 <template>
  <v-col align="center" class="pb-2 pt-0">
    <v-row>
      <v-col>
        <v-row>
          <v-col class="" align="right">
            <v-select
              :items="pagesList"
              dense
              v-model="itemsOptions"
              color="primary"
              item-color="primary"
              class="textDecorationBold font14"
              style="max-width: 75px"
              height="35"
              hide-details
              outlined
              :loading="loading"
            >
              <template v-slot:selection="{ item }">
                <v-row>
                  <v-col align="center" class="text-no-wrap">
                    <span> {{ $t(item.text) }} </span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item="{ item }">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col align="center" class="text-no-wrap">
                        <span> {{ $t(item.text) }} </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <CustomTable
        class="row-height-75 pl-0 pr-0 pt-0"
        style="overflow-y: auto"
        :headers="headers"
        :data="estatesData"
        :itemsPerPage="10"
        :totalPages="199"
        :page="1"
        :templates="templates"
        :loading="loading"
        :handleEstateInfoView="openRealEstateSlider"
      />
    </v-row>
  </v-col>
</template>
 
<script>
/*
 Name:SoldEstates
 Description: Component to render sold estates for the contact.

  API Property:
    estatesData: Array with estate data
    fetchRealEstate: Function from parent to fetch the estates
    pagesList: Array with items count display option
    openRealEstateSlider: Function from parent to open real estate slider
    estateOptions: Object with filter options
    totalEstatesPages: Number with total estates count
    setEstatesItems: Function to change number of diplay estates
    loading: Boolean for the loading

    Components Used:
      CustomTable
*/
import CustomTable from "@/components/CustomTable/CustomTable.vue";
import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
import { mapState } from "vuex";
import ContactModal from "@/static/reviews/contactEstates";

export default {
  name: "SoldEstates",
  components: {
    CustomTable,
  },
  props: {
    estatesData: Array,
    fetchRealEstate: Function,
    pagesList: {
      type: Array,
      default: () => [
        {
          id: 0,
          text: "3",
          value: 3,
        },
        {
          id: 1,
          text: "5",
          value: 5,
        },
        {
          id: 2,
          text: "10",
          value: 10,
        },
      ],
    },
    openRealEstateSlider: Function,
    estateOptions: Object,
    totalEstatesPages: Number,
    setEstatesItems: Function,
    loading: Boolean,
  },
  data() {
    return {
      headers: ContactModal.headersAvailableEstates,
      templates: ContactModal.templateAvailableEstates,

      pageOptions: 1,
    };
  },

  watch: {
    mobileBreakpoint() {
      if (this.mobileBreakpoint == true) this.viewChoice = 0;
    },
  },

  computed: {
    ...mapState({
      realEstateFilter: (state) => state.realEstate,
      userDetails: (state) => state.auth.userDetails,
    }),
    getReportHeight() {
      return this.windowHeight - this.height - 90;
    },
    sort() {
      let lSort = this.headers.filter((f) => {
        return f.sort;
      });
      return lSort.length == 0 ? [{}] : lSort;
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    itemsOptions: {
      get() {
        return this.estateOptions.items;
      },
      set(newValue) {
        this.setEstatesItems(newValue);
      },
    },
  },
  methods: {
    // async optionsRefresh() {
    //   this.options.page = 1;
    //   await this.fetchRealEstate();
    // },
    characteristicsInfo(object) {
      return RealEstateHelper.getCharacteristicsEstates(object);
    },
  },
};
</script>
<style scoped>
</style>