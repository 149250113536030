export default {
  state: {
    available: null,
    category: null,
    subCategory: null,
    roomMin: null,
    roomMax: null,
    bathroomMin: null,
    bathroomMax: null,
    floorMin: null,
    floorMax: null,
    priceMin: null,
    priceMax: null,
    areaMin: null,
    areaMax: null,
    areaPriceMin: null,
    areaPriceMax: null,
    yearMin: null,
    yearMax: null,
    buildingStatus: null,
    classEnergy: null,
    genericCharacters: [],
    homeCharacters: [],
    heatingCharacters: [],
    published: [],
    frames: null,
    typeFloor: null,
    securityCharacters: [],
    viewCharacters: [],
    extraCharacters: [],
    codeEstate: null,
    commandType: null,
    position: null,
    user: null,
    phone: null,
    public: [],
    refreshDateFrom: null,
    refreshDateTo: null,
    createdDateFrom: null,
    createdDateTo: null,
    createdProviderDateFrom: null,
    createdProviderDateTo: null,
    status: ['Ενεργό'],
    publicCharacters: null,
    rateMin: null,
    rateMax: null,
    owner: null,
    energyButton: null,
    drawer: null,
    activeTab: null,
    agentPrice: null,
    plot_unit: null,
    updatePlusMinus: null,
    yearly_tax: null,
    energy_class: null,
    autonomous_heating: null,
    air_conditioning: null,
    solar_water_heater: null,
    fireplace: null,
    gas: null,
    stove: null,
    central_heating: null,
    under_floor_heating: null,
    natural_gas: null,
    oil: null,
    electrical_heating: null,
    heat_accumulator: null,
    furnished: null,
    electrical_equipment: null,
    satellite_antenna: null,
    luxurious: null,
    pets_allowed: null,
    internal_staircase: null,
    elevator: null,
    safety_door: null,
    security_cam: null,
    security_systems: null,
    alarm: null,
    cctv: null,
    pool_in_sqm: null,
    separate_entrance: null,
    access_for_disabled_people: null,
    bbq: null,
    suitable_for_students: null,
    suitable_for_commercial_use: null,
    distance_from_sea: null,
    distance_from_acropolis_or_monuments: null,
    distance_from_schools: null,
    distance_from_metro: null,
    distance_from_train: null,
    distance_from_park: null,
    distance_from_suburban: null,
    distance_from_international_schools: null,
    distance_from_airport: null,
    distance_from_bank: null,
    distance_from_super_market: null,
    distance_from_commuting_stops: null,
    distance_from_square: null,
    distance_from_hospital: null,
    distance_from_tram: null,
    locationDetails: null,
    getRegion: null,
    getMunicipality: null,
    getNeighborhood: null,
    watermark: null,
    youtubeLink: null,
    objectivePrice: null,
    unit: null,
    bright: null,
    painted: null,
    veranda: null,
    awnings: null,
    airbnb: null,
    area: null,
    filterEstate: 0, 
    googleCoordinates: {
        Ra: {
            "lo": 23.69046957338046,
            "hi": 23.772523711564055
        },
        wb: {
            "lo": 37.92053914006598,
            "hi": 38.009319323616886
        }
    },
    locationGoogle: "",
    searchNewDeal: [],
    searchGoogleText: "",
    refreshPage: false,
  },
  getters: {},
  mutations: {
    clearFilter(state) {
      state.available = null;
      state.category = null;
      state.roomMin = null;
      state.subCategory = null;
      state.roomMax = null;
      state.bathroomMin = null;
      state.bathroomMax = null;
      state.floorMin = null;
      state.floorMax = null;
      state.priceMin = null;
      state.priceMax = null;
      state.areaMin = null;
      state.areaMax = null;
      state.areaPriceMin = null;
      state.areaPriceMax = null;
      state.yearMin = null;
      state.yearMax = null;
      state.buildingStatus = null;
      state.classEnergy = null;
      state.genericCharacters = [];
      state.homeCharacters = [];
      state.heatingCharacters = [];
      state.published = [];
      state.frames = null;
      state.typeFloor = null;
      state.securityCharacters = [];
      state.viewCharacters = [];
      state.extraCharacters = [];
      state.codeEstate = null;
      state.position = null;
      state.user = null;
      state.phone = null;
      state.public = [];
      state.refreshDateFrom = null;
      state.refreshDateTo = null;
      state.createdDateFrom = null;
      state.createdDateTo = null;
      state.createdProviderDateFrom = null;
      state.createdProviderDateTo = null;
      state.status = ['Ενεργό'];
      state.publicCharacters = null;
      state.statusType = null;
      state.rateMin = null;
      state.rateMax = null;
      state.commandType = null;
      state.owner = null;
      state.energyButton = null;

      state.drawer = null;
      state.activeTab = null;
      state.agentPrice = null;
      state.plot_unit = null;
      state.updatePlusMinus = null;
      state.yearly_tax = null;
      state.energy_class = null;
      state.autonomous_heating = null;
      state.air_conditioning = null;
      state.solar_water_heater = null;
      state.fireplace = null;
      state.gas = null;
      state.stove = null;
      state.central_heating = null;
      state.under_floor_heating = null;
      state.natural_gas = null;
      state.oil = null;
      state.electrical_heating = null;
      state.heat_accumulator = null;
      state.furnished = null;
      state.electrical_equipment = null;
      state.satellite_antenna = null;
      state.luxurious = null;
      state.pets_allowed = null;
      state.internal_staircase = null;
      state.elevator = null;
      state.safety_door = null;
      state.security_cam = null;
      state.security_systems = null;
      state.alarm = null;
      state.cctv = null;
      state.pool_in_sqm = null;
      state.separate_entrance = null;
      state.access_for_disabled_people = null;
      state.bbq = null;
      state.suitable_for_students = null;
      state.suitable_for_commercial_use = null;
      state.distance_from_sea = null;
      state.distance_from_acropolis_or_monuments = null;
      state.distance_from_schools = null;
      state.distance_from_metro = null;
      state.distance_from_train = null;
      state.distance_from_park = null;
      state.distance_from_suburban = null;
      state.distance_from_international_schools = null;
      state.distance_from_airport = null;
      state.distance_from_bank = null;
      state.distance_from_super_market = null;
      state.distance_from_commuting_stops = null;
      state.distance_from_square = null;
      state.distance_from_hospital = null;
      state.distance_from_tram = null;
      state.locationDetails = null;
      state.getRegion = null;
      state.getMunicipality = null;
      state.getNeighborhood = null;
      state.watermark = null;
      state.youtubeLink = null;
      state.objectivePrice = null;
      state.unit = null;
      state.bright = null;
      state.painted = null;
      state.veranda = null;
      state.awnings = null;
      state.airbnb = null;
      state.area = null;
      state.locationGoogle = "";
      state.searchNewDeal = [];
      state.searchGoogleText = "";
      state.filterEstate = 0;
    },
    setAvailable(state, payload) {
      state.available = payload;
      if (!state.refreshPage) state.refreshPage = true;
    },
    setCategory(state, payload) {
      state.category = payload;
      if (!state.refreshPage) state.refreshPage = true;
    },
    setSubCategory(state, payload) {
      state.subCategory = payload;
      if (!state.refreshPage) state.refreshPage = true;
    },
    setRoomMin(state, payload) {
      state.roomMin = payload;
      if (!state.refreshPage) state.refreshPage = true;
    },
    setRoomMax(state, payload) {
      state.roomMax = payload;
      if (!state.refreshPage) state.refreshPage = true;
    },
    setBathroomMin(state, payload) {
      state.bathroomMin = payload;
      if (!state.refreshPage) state.refreshPage = true;
    },
    setBathroomMax(state, payload) {
      state.bathroomMax = payload;
      if (!state.refreshPage) state.refreshPage = true;
    },
    setFloorMax(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.floorMax = payload;
    },
    setFloorMin(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.floorMin = payload;
    },
    setPriceMax(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.priceMax = payload;
    },
    setPriceMin(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.priceMin = payload;
    },
    setAreaMax(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.areaMax = payload;
    },
    setAreaMin(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.areaMin = payload;
    },
    setAreaPriceMax(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.areaPriceMax = payload;
    },
    setAreaPriceMin(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.areaPriceMin = payload;
    },
    setYearMax(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.yearMax = payload;
    },
    setYearMin(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.yearMin = payload;
    },
    setBuildingStatus(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.buildingStatus = payload;
    },
    setClassEnergy(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.classEnergy = payload;
    },
    setGenericCharact(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.genericCharacters = payload;
    },
    setHomeCharacters(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.homeCharacters = payload;
    },
    setHeatingCharacters(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.heatingCharacters = payload;
    },
    setPublished(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.published = payload;
    },
    setExtraCharacters(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.extraCharacters = payload;
    },
    setSecurityCharacters(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.securityCharacters = payload;
    },
    setViewCharacters(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.viewCharacters = payload;
    },
    setFrames(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.frames = payload;
    },
    setTypeFloor(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.typeFloor = payload;
    },
    setCodeEstate(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.codeEstate = payload;
    },
    setCommandType(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.commandType = payload;
    },
    setStatus(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.status = payload /*== null || payload == undefined || payload == "" || payload == []
        ? ["Ενεργό"]
        : payload*/
    },
    setUser(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.user = payload;
    },
    setPhone(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.phone = payload;
    },
    setRefreshDateFrom(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.refreshDateFrom = payload;
    },
    setRefreshDateTo(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.refreshDateTo = payload;
    },
    setStatusType(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.statusType = payload;
    },
    setOwner(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.owner = payload;
    },
    setCreatedDateFrom(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.createdDateFrom = payload;
    },
    setCreatedDateTo(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.createdDateTo = payload;
    },
    setCreatedProviderDateFrom(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.createdProviderDateFrom = payload;
    },
    setCreatedProviderDateTo(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.createdProviderDateTo = payload;
    },
    setPublicCharacters(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.publicCharacters = payload;
    },
    setRateMin(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.rateMin = payload;
    },
    setRateMax(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.rateMax = payload;
    },
    setArea(state, payload) {
      if (!state.refreshPage) state.refreshPage = true;
      state.area = payload;
    },
    setGoogleCoordinates(state, payload) {
        state.googleCoordinates = payload;
    },
    setLocationGoogle(state, payload) {
      state.locationGoogle = payload;
    },
    setSearchNewDeal(state, payload) {
      state.searchNewDeal = payload;
    },
    setSearchGoogleText(state, payload) {
      state.searchGoogleText = payload;
    },
    setRefreshPage(state, payload) {
      state.refreshPage = payload;
    },
    setFilterEstate(state, payload) {
      state.filterEstate = payload;
    },
  },
  actions: {
    clearFilter({ commit }) {
      commit("clearFilter");
    },
    onSetAvailable({ commit }, payload) {
      commit("setAvailable", payload);
    },
    onSetCategory({ commit }, payload) {
      commit("setCategory", payload);
    },
    onSetSubCategory({ commit }, payload) {
      commit("setSubCategory", payload);
    },
    onSetRoomMin({ commit }, payload) {
      commit("setRoomMin", payload);
    },
    onSetRoomMax({ commit }, payload) {
      commit("setRoomMax", payload);
    },
    onSetBathroomMin({ commit }, payload) {
      commit("setBathroomMin", payload);
    },
    onSetBathroomMax({ commit }, payload) {
      commit("setBathroomMax", payload);
    },
    onSetFloorMax({ commit }, payload) {
      commit("setFloorMax", payload);
    },
    onSetFloorMin({ commit }, payload) {
      commit("setFloorMin", payload);
    },
    onSetPriceMax({ commit }, payload) {
      commit("setPriceMax", payload);
    },
    onSetPriceMin({ commit }, payload) {
      commit("setPriceMin", payload);
    },
    onSetAreaMax({ commit }, payload) {
      commit("setAreaMax", payload);
    },
    onSetAreaMin({ commit }, payload) {
      commit("setAreaMin", payload);
    },
    onSetAreaPriceMax({ commit }, payload) {
      commit("setAreaPriceMax", payload);
    },
    onSetAreaPriceMin({ commit }, payload) {
      commit("setAreaPriceMin", payload);
    },
    onSetYearMax({ commit }, payload) {
      commit("setYearMax", payload);
    },
    onSetYearMin({ commit }, payload) {
      commit("setYearMin", payload);
    },
    onSetBuildingStatus({ commit }, payload) {
      commit("setBuildingStatus", payload);
    },
    onSetClassEnergy({ commit }, payload) {
      commit("setClassEnergy", payload);
    },
    onSetGenericCharact({ commit }, payload) {
      commit("setGenericCharact", payload);
    },
    onSetHomeCharacters({ commit }, payload) {
      commit("setHomeCharacters", payload);
    },
    onSetHeatingCharacters({ commit }, payload) {
      commit("setHeatingCharacters", payload);
    },
    onSetPublished({ commit }, payload) {
      commit("setPublished", payload);
    },
    onSetExtraCharacters({ commit }, payload) {
      commit("setExtraCharacters", payload);
    },
    onSetSecurityCharacters({ commit }, payload) {
      commit("setSecurityCharacters", payload);
    },
    onSetViewCharacters({ commit }, payload) {
      commit("setViewCharacters", payload);
    },
    onSetframes({ commit }, payload) {
      commit("setFrames", payload);
    },
    onSetTypeFloor({ commit }, payload) {
      commit("setTypeFloor", payload);
    },
    onSetCodeEstate({ commit }, payload) {
      commit("setCodeEstate", payload);
    },
    onSetCommandType({ commit }, payload) {
      commit("setCommandType", payload);
    },
    onSetStatus({ commit }, payload) {
      commit("setStatus", payload);
    },
    onSetUser({ commit }, payload) {
      commit("setUser", payload);
    },
    onSetPhone({ commit }, payload) {
      commit("setPhone", payload);
    },
    onSetRefreshDateFrom({ commit }, payload) {
      commit("setRefreshDateFrom", payload);
    },
    onSetRefreshDateTo({ commit }, payload) {
      commit("setRefreshDateTo", payload);
    },
    onSetStatusType({ commit }, payload) {
      commit("setStatusType", payload);
    },
    onSetOwner({ commit }, payload) {
      commit("setOwner", payload);
    },
    onSetCreatedDateFrom({ commit }, payload) {
      commit("setCreatedDateFrom", payload);
    },
    onSetCreatedDateTo({ commit }, payload) {
      commit("setCreatedDateTo", payload);
    },
    onSetCreatedProviderDateFrom({ commit }, payload) {
      commit("setCreatedProviderDateFrom", payload);
    },
    onSetCreatedProviderDateTo({ commit }, payload) {
      commit("setCreatedProviderDateTo", payload);
    },
    onSetPublicCharacters({ commit }, payload) {
      commit("setPublicCharacters", payload);
    },
    onSetRateMin({ commit }, payload) {
      commit("setRateMin", payload);
    },
    onSetRateMax({ commit }, payload) {
      commit("setRateMax", payload);
    },
    onSetArea({ commit }, payload) {
      commit("setArea", payload);
    },
    onSetGoogleCoordinates({ commit }, payload) {
        commit("setGoogleCoordinates", payload)
    },
    onSetLocationGoogle({ commit }, payload) {
      commit("setLocationGoogle", payload);
    },
    onSetSearchNewDeal({ commit }, payload) {
      commit("setSearchNewDeal", payload);
    },
    onSetSearchGoogleText({ commit }, payload) {
      commit("setSearchGoogleText", payload);
    },
    onSetRefreshPage({ commit }, payload) {
      commit("setRefreshPage", payload);
    },
    onSetFilterEstate({ commit }, payload) {
      if(payload == null){
        payload = 0;
      }
      commit("setFilterEstate", payload);
    },
  },
};
