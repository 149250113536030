<template>
  <v-col>
    <v-row>
      <v-col align="left">
        <span class="primaryColor textDecorationBold font15">
          {{ $t("addressInfo") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <Location :placeholder="$t('typeAddrNo') + '...'" :vuexProps="false" :setLocation="setACLocation"
          iconName="mdi-map-marker-outline" iconColor="primary" class="pb-0 pt-5" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('gps')">
        <v-text-field v-model="gps" :label="$t('exactLocation')" clearable color="primary" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Country')">
        <v-combobox style="padding-top: 2px !important" v-model="Country" flat dense :label="$t('country')"
          item-text="text" item-value="value" append-icon="" append-icon-cb="" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Neighborhood')">
        <v-combobox style="padding-top: 2px !important" v-model="Neighborhood" flat dense :label="$t('regions')"
          item-text="text" item-value="value" append-icon="" append-icon-cb="" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('City')">
        <v-combobox style="padding-top: 2px !important" v-model="City" flat dense :label="$t('municipality')"
          item-text="text" item-value="value" append-icon="" append-icon-cb="" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Street')">
        <v-combobox style="padding-top: 2px !important" v-model="Street" flat dense :label="$t('addressNumber2')"
          item-value="value" append-icon="" append-icon-cb="" hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('Zip')">
        <v-text-field v-model="Zip" :label="$t('postalCode')" clearable dense color="primary" class="inputLabelCustom"
          hide-details="auto" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" v-if="showAttribute('otherAddress')">
        <v-combobox style="padding-top: 2px !important" v-model="otherAddress" flat dense
          :label="$t('otherAddressInfo')" hide-no-data hide-details="auto" deletable-chips append-icon=""
          append-icon-cb="" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
/*
 Name:AddressInfo
 Description: Component to render address info for a contact

 API Property:
    attributes: Object with the data
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the info type like "addressInfo"

    Components Used:
      Location
*/
import Location from "@/components/Maps/Location.vue";
export default {
  name: "AddressInfo",
  components: {
    Location,
  },
  props: {
    attributes: Object,
    updateAttribute: Function,
    validation: Object,
    attributeModel: Array,
    kind: { type: String, default: "addressInfo" },
  },
  computed: {
    Country: {
      get() {
        return this.attributes.Country;
      },
      set(value) {
        this.updateAttribute(this.kind, "Country", value);
      },
    },
    City: {
      get() {
        return this.attributes.City;
      },
      set(value) {
        this.updateAttribute(this.kind, "City", value);
      },
    },
    Neighborhood: {
      get() {
        return this.attributes.Neighborhood;
      },
      set(value) {
        this.updateAttribute(this.kind, "Neighborhood", value);
      },
    },
    Street: {
      get() {
        return this.attributes.Street;
      },
      set(value) {
        this.updateAttribute(this.kind, "Street", value);
      },
    },
    Zip: {
      get() {
        return this.attributes.Zip;
      },
      set(value) {
        this.updateAttribute(this.kind, "Zip", value);
      },
    },
    otherAddress: {
      get() {
        return this.attributes.otherAddress;
      },
      set(value) {
        this.updateAttribute(this.kind, "otherAddress", value);
      },
    },
    gps: {
      get() {
        return this.attributes.gps;
      },
      set(value) {
        this.updateAttribute(this.kind, "gps", value);
      },
    }
  },
  methods: {
    setACLocation(sLocationDetails) {
      if (sLocationDetails && sLocationDetails.addressExtracted) {
        this.Country = sLocationDetails.addressExtracted.country ? sLocationDetails.addressExtracted.country : this.Country;
        this.City = sLocationDetails.addressExtracted.locality ? sLocationDetails.addressExtracted.locality : this.City;
        this.Neighborhood = sLocationDetails.addressExtracted.administrative_area_level_1 ? sLocationDetails.addressExtracted.administrative_area_level_1
          : sLocationDetails.addressExtracted.administrative_area_level_2 ? sLocationDetails.addressExtracted.administrative_area_level_2
            : sLocationDetails.addressExtracted.administrative_area_level_3 ? sLocationDetails.addressExtracted.administrative_area_level_3 : this.Neighborhood;
        this.Street = (sLocationDetails.addressExtracted.route ? sLocationDetails.addressExtracted.route : this.Street) + (sLocationDetails.addressExtracted.postal_code ? ' ' + sLocationDetails.addressExtracted.street_number : '');
        this.Zip = sLocationDetails.addressExtracted.postal_code ? sLocationDetails.addressExtracted.postal_code : this.Zip;
        this.otherAddress = sLocationDetails.formatted_address ? [sLocationDetails.formatted_address] : this.otherAddress;
      }
      let lat =
        sLocationDetails?.geometry &&
          sLocationDetails?.geometry?.location &&
          sLocationDetails?.geometry?.location?.lat
          ? sLocationDetails?.geometry?.location?.lat()
          : null;
      let lng =
        sLocationDetails?.geometry &&
          sLocationDetails?.geometry?.location &&
          sLocationDetails?.geometry?.location?.lng
          ? sLocationDetails?.geometry?.location?.lng()
          : null;
      this.gps = lat != null && lng != null ? lat + "," + lng : ",";
    },
    showAttribute(attribute) {
      return this.attributeModel.includes(attribute);
    }

  }
};
</script>
