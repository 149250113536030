export default {
  state: {
    items: [5, 10, 20, 30, 40, 50],
  },
  getters: {
    getItemsList(state) {
      return state.items;
    },
  },
};
