var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"noShadow",attrs:{"id":"fakeClick"}},[_c('v-row',{staticClass:"masterRow"},[_c('v-col',{staticClass:"mr-2 mb-3 align-items-center gSearch",class:_vm.showNewDealList == true ? 'colWidth' : ''},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-0 pr-0 align-self-center"},[_c('Icon',{attrs:{"icon":_vm.iconName,"height":_vm.iconHeight,"color":_vm.iconColor}})],1),_c('v-col',{staticClass:"pl-0 pr-0 pt-1 align-self-center"},[_c('gmap-autocomplete',{staticStyle:{"width":"95%"},attrs:{"id":"gMap","name":"gMapName","options":{
            fields: _vm.fields,
          }},on:{"place_changed":_vm.selectMarker}})],1),_c('v-col',{staticClass:"flex-grow-0 pl-0 pr-0 pt-2"},[(_vm.locationGoogle && _vm.searchNewDeal)?_c('v-icon',{staticClass:"primaryColorOnHover",staticStyle:{"margin-left":"13px !important","cursor":"pointer"},domProps:{"textContent":_vm._s('mdi-close')},on:{"click":_vm.clearLocGoogle}}):_vm._e()],1)],1)],1),(_vm.showNewDealList)?_c('v-col',{staticClass:"pl-0 pr-0 pt-0 colWidth rightCol"},[_c('v-autocomplete',{ref:"loc",staticClass:"mCard",attrs:{"items":_vm.areasListSorted,"search-input":_vm.searchInput,"filter":_vm.filterAreas,"label":_vm.$t('ListOfLocations'),"color":"primary","hide-details":"auto","prepend-inner-icon":"mdi-web","min-height":"40","min-width":"312","dense":true,"solo":"","multiple":"","flat":"","clearable":"","item-color":"greenish","attach":""},on:{"focusout":function($event){return _vm.updateSearch('ND')},"focus":function($event){return _vm.viewLabel(false)},"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [(
            _vm.searchNewDeal.length == 1 && index == 0 && _vm.viewLabelVal == true
          )?_c('span',{staticClass:"text-truncate text-caption"},[_vm._v(" "+_vm._s(item.length > 24 ? item.substring(0, 24) + "..." : item)+" ")]):_vm._e(),(
            _vm.searchNewDeal.length > 1 && index == 0 && _vm.viewLabelVal == true
          )?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" "+_vm._s(_vm.searchNewDeal.length + " " + _vm.$t("selectedAreas"))+" ")]):_vm._e()]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-list',{staticClass:"newDealLocItem"},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"newDealLoc newDealLocIcon primaryColorOnHover",staticStyle:{"margin-left":"13px !important"},domProps:{"textContent":_vm._s('mdi-close')}})],1),_c('v-list-item-title',{staticClass:"newDealLoc",domProps:{"innerHTML":_vm._s(_vm.myGenFilteredText(item))}})],1)]}}],null,false,3446886049),model:{value:(_vm.searchNewDeal),callback:function ($$v) {_vm.searchNewDeal=$$v},expression:"searchNewDeal"}},[(_vm.mobileBreakpoint)?_c('div',{attrs:{"slot":"prepend-item"},slot:"prepend-item"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.closeList}},[_vm._v(_vm._s(_vm.$t("closed")))])],1):_vm._e()])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }