<template>
  <v-row
    :class="{ isRead: !data.isRead, 'pl-1 pr-2 rowContainer': true }"
    @click="onClickRow"
  >
    <v-col cols="3" sm="3" md="3" lg="3">
      <v-badge
        :color="notificationColor"
        overlap
        bordered
        v-if="notificationPosition == 'onIcon' && !data.isRead"
      >
        <v-avatar v-if="(notifType = 'img')">
          <v-img :src="data.img" lazy-src="data.img" alt="John" />
        </v-avatar>
        <Icon
          v-else-if="(notifType = 'icon')"
          :icon="data.iconObj.icon"
          :height="data.iconObj.height"
          v-bind:[data.iconObj.attr]="true"
          :color="data.iconObj.color"
        />
      </v-badge>
      <v-avatar v-else-if="(notifType = 'img')">
        <v-img :src="data.img" :lazy-src="data.img" alt="John" />
      </v-avatar>
      <Icon
        v-else-if="(notifType = 'icon')"
        :icon="data.iconObj.icon"
        :height="data.iconObj.height"
        v-bind:[data.iconObj.attr]="true"
        :color="data.iconObj.color"
      />
    </v-col>
    <v-col>
      <v-row>
        <v-col v-html="data.content" class="content"> </v-col>
        <v-col
          cols="1"
          sm="1"
          md="1"
          lg="1"
          v-if="notificationPosition == 'right' && !data.isRead"
        >
          <v-badge :color="notificationColor" dot class="mediumSizeBadge">
          </v-badge>
        </v-col>
      </v-row>
      <v-row justify="end" class="time textDecorationBold"> {{ timeAgo(data.dateTime) }}</v-row>
    </v-col>
  </v-row>
</template>
<script>
/* 
  Name: NotificationCard
  Description: Component with a notification of a card
  API Property 
    notificationPosition: String for the location of notification ( Left or Right )
    notificationColor: String for the color of notification
    notifType: String for the type of notification (img or icon)
    headerTitle: String for the title
    data: Object for the data
    onHandleClick: Function on click handler

  Components Used:
    Icon
*/
import Helpers from "../../helpers/Core/core";
import { Icon } from "@iconify/vue2";
export default {
  name: "NotificationCard",
  components: { Icon },
  props: {
    notificationPosition: { type: String, default: "right" }, // Location of notification ( Left or Right )
    notificationColor: { type: String, default: "red" }, // Color of notification
    notifType: { type: String, default: "img" }, // Type of notification (img or icon)
    headerTitle: String, //Title
    data: Object,
    onHandleClick: Function,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onClickRow() {
      this.onHandleClick();
    },
    timeAgo(date) {
      try {
        return Helpers.time_ago(date);
      } catch {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rowContainer {
  background-color: white;
  border-radius: 10px;
  min-height: 102px;
  cursor: pointer;
  margin-bottom: 1px;
}
.time {
  font-size: 12px;
  color: #263573;
}
.isRead {
  background-color: #f8f8f8 !important;
}
.content {
  font-size: 12px;
  color: #343a40;
  text-align: left;
}
.v-avatar {
  min-height: unset !important;
  min-width: unset !important;
  width: unset !important;
  height: unset !important;
  max-height: 50px !important;
  max-width: 50px !important;
}
// .mediumSizeBadge .v-badge__badge {
//   height: 13px !important;
//   width: 13px !important;
//   border-radius: 10.5px;
// }
</style>
