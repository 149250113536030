<template>
  <v-card>
    <v-card-title class="mt-3 mb-0">
      <v-row>
        <v-col>
          <HeaderTitle :title="$t('requestForCooperation')" :subTitle="data.Location" fontSizeSub="14px"
            :extraContent="data.EstateCode"
            extraContentClass="flex-grow-0 text-no-wrap cusTab font14 textDecorationBold goldText" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-row>
              <v-col class="listHeader intBold" align="left">
                {{ $t("coopFrom") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pr-0" align="left">
                <AvatarField :size="32" :data="getApplicantPhoto" :isImg="
                  ![undefined, '', null].includes(ApplicantPhoto)
                    ? true
                    : false
                " backgroundColor="#77BD86" :showName="true" :nameContent="ApplicationFullName"
                  :showSubContent="true" :subContent="ApplicantCompany" fontSizeImg="16px" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-row>
              <v-col class="listHeader intBold" align="left">
                {{ $t("coopTo") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pr-0" align="left">
                <AvatarField :size="32" :data="agentPhoto" :isImg="
                  ![undefined, '', null].includes(data.AgentPhoto)
                    ? true
                    : false
                " backgroundColor="#77BD86" :showName="true" :nameContent="agentName" :showSubContent="true"
                  :subContent="this.data.Company ? this.data.Company : ''" fontSizeImg="16px" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9">
          <v-col class="pl-0 pr-0">
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-autocomplete v-model="customer" :label="$t('customer') + '...'" :items="ownerList" item-text="name"
              return-object :search-input.sync="search" :loading="autocompleteLoader"
              append-icon="mdi-pencil-box-outline" @click:append="addCustomer()" hide-no-data clearable flat
              hide-selected attach>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-datetime-picker v-model="dateTimeNote" :label="$t('dateTimeNote') + '...'" scrollable show-current
              append-outer-icon="mdi-pencil-box-outline" dateFormat="dd/MM/yyyy" :textFieldProps="{
                label: 'Append',
                ['append-icon']: 'mdi-calendar-month',
              }">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon> mdi-clock </v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea :label="$t('addCommentQuestion')" v-model="comments" class="mt-4" outlined rows="4"
              row-height="15">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 pb-0">
            <v-checkbox v-model="acceptTermsAndConditions" class="">
              <template v-slot:label>
                <span class="acceptTermsCoopClass">
                  {{ $t("acceptTermsCoop") }}
                </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="justify-end">
          <v-col align="right" class="flex-grow-0">
            <v-btn @click="close" align="center" class="textDecorationBold bottomBtns unsetCapitalize" text
              color="error">
              {{ $t("exit") }}
            </v-btn>
          </v-col>
          <v-col align="left" class="flex-grow-0">
            <v-btn elevation="5" @click="submit" :disabled="!acceptTermsAndConditions" align="center"
              class="textDecorationBold bottomBtns unsetCapitalize" color="#43425D" style="color: white">
              {{ $t("send") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="dialog.open" v-if="dialog.type == 'customers'" max-width="690px" scrollable
      @click:outside="closeDialog" persistent>
      <Contact parent="cooperation" :subTitle="$t('ofPotentialCus')" :searchOwner="initCustomer"
        :closeHandler="this.closeDialog" />
    </v-dialog>
  </v-card>
</template>

 <script>
  /*
 Name: Cooperation
 Description: Component for real estate cooperation at the slider

 API Property: 
    data: Array for the data
    closeHandler: Function that closes if opened as dialog
    submitHandler: Function that submits

    Components Used:
      HeaderTitle
      AvatarField
      Contact
*/
import { mapState } from "vuex";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import AvatarField from "@/components/Fields/AvatarField/AvatarField";
import Contact from "@/components/Contacts/Contact.vue";
import moment from "moment";
import Repository from "@/services/Repository";
import CoreHelper from "@/helpers/Core/core";
const RealEstateRepo = Repository.get("realEstate");
const ContactRepo = Repository.get("contacts");
export default {
  name: "Cooperation",
  props: {
    data: Object,
    closeHandler: Function, //Function that closes if opened as dialog
    submitHandler: Function, // Function that submits
  },
  components: {
    HeaderTitle,
    AvatarField,
    Contact,
  },
  data() {
    return {
      acceptTermsAndConditions: false,
      ApplicantID: null,
      ApplicantName: null,
      ApplicantSurName: null,
      ApplicantPhoto: null,
      ApplicantCompany: null,
      ApplicationFullName: null,
      search: null,
      ownerList: [],
      autocompleteLoader: false,
      customer: "",
      comments: "",
      menuDateTimeNote: false,
      dateTimeNote: null,
      dateTimeNoteFormatted: null,
      showcaseDate: null,
      showcaseTime: null,
      dialog: {
        open: false,
        data: null,
        type: null,
      },
    };
  },
  created() {
    this.fetchOwnerList(null);
    this.ApplicantID = this.userDetails["_id"];
    this.ApplicantName = this.userDetails["FirstName"]
      ? this.userDetails["FirstName"]
      : "";
    this.ApplicantSurName = this.userDetails["LastName"]
      ? this.userDetails["LastName"]
      : "";
    this.ApplicationFullName = this.ApplicantName + " " + this.ApplicantSurName;
    this.ApplicantPhoto = this.userDetails["Photo"]
      ? this.userDetails["Photo"]
      : null;
    this.ApplicantCompany = this.userDetails["Company"]
      ? this.userDetails["Company"]
      : null;
  },
  watch: {
    dateTimeNote() {
      let date = moment(this.dateTimeNote).format("DD-MM-YYYY HH:mm:ss");
      this.showcaseTime = date.split(" ")[1];
      this.showcaseDate = date.split(" ")[0];
    },
    search(val) {
      val && val != this.customer && this.fetchOwnerList(val);
    },
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    agentPhoto() {
      if (![undefined, "", null].includes(this.data.AgentPhoto)) {
        return this.data.AgentPhoto;
      } else {
        if (this.data.AgentName && this.data.AgentSurName) {
          return (
            this.data.AgentName.charAt(0) + this.data.AgentSurName.charAt(0)
          );
        } else if (this.data.AgentName) {
          return this.data.AgentName.charAt(1);
        } else if (this.data.AgentSurName) {
          return this.data.AgentSurName.charAt(1);
        }
        return "";
      }
    },
    agentName() {
      if (this.data.AgentName && this.data.AgentSurName) {
        return this.data.AgentName + " " + this.data.AgentSurName.charAt(0);
      } else if (this.data.AgentName) {
        return this.data.AgentName;
      } else if (this.data.AgentSurName) {
        return this.data.AgentSurName;
      }
      return "";
    },
    getApplicantPhoto() {
      if (![undefined, "", null].includes(this.data.ApplicantPhoto)) {
        return this.data.ApplicantPhoto;
      } else {
        if (this.data.ApplicantName && this.data.ApplicantSurName) {
          return (
            this.data.ApplicantName.charAt(0) +
            this.data.ApplicantSurName.charAt(0)
          );
        } else if (this.data.ApplicantName) {
          return this.data.ApplicantName.charAt(1);
        } else if (this.data.ApplicantSurName) {
          return this.data.ApplicantSurName.charAt(1);
        }
        return "";
      }
    },
  },
  methods: {
    close() {
      try {
        this.closeHandler();
      } catch (err) {
        console.error(err);
      }
    },
    submit() {
      try {
        let obj = {};
        obj["ApplicantID"] = this.userDetails["_id"];
        obj["AgentID"] = this.data["UserID"];
        obj["TaskType"] = "Συνεργασία";
        obj["CustomerName"] = this.customer.name ? this.customer.name : "";
        obj["CustomerID"] = this.customer["_id"] ? this.customer["_id"] : "";
        obj["showcaseDate"] = this.showcaseDate;
        obj["showcaseTime"] = this.showcaseTime;
        obj["comments"] = this.comments;
        this.setCooperationRequest(obj);
      } catch (err) {
        console.error(err);
      }
    },
    async setCooperationRequest(obj) {
      try {
        await RealEstateRepo.setCooperationRequest(
        obj,
        this.data["_id"]
      );
        this.closeHandler();
      } catch (e) {
        throw e;
      }
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type: type,
        data: data,
      };
    },
    addCustomer() {
      this.openDialog("customers", {});
    },
    initCustomer(customer) {
      if (customer && customer.id && customer.fullname) {
        this.customer = {
          _id: customer._id,
          name: customer.fullname,
        };
        this.ownerList.push(this.customer);
      }
    },
    async fetchOwnerList(val, ownerId) {
      this.autocompleteLoader = true;
      let word;
      let filters;

      if (val != null) {
        word = CoreHelper.toRegex(val);
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [
            { FirstName: { $regex: word, $options: "i" } },
            { LastName: { $regex: word, $options: "i" } },
          ],
        ];
      } else if (ownerId != null) {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          [{ _id: ownerId }],
        ];
      } else {
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
        ];
      }

      try {
        let resp = await ContactRepo.getOwners(filters, { page: 0, items: 20 });
        if (
          resp &&
          resp.data &&
          resp.data.owners &&
          resp.data.owners.length > 0
        ) {
          this.ownerList = resp.data.owners.map((d) => {
            return {
              ...d,
              name: d.name
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, ""),
            };
          });
        } else {
          this.ownerList = [];
        }
        this.autocompleteLoader = false;
      } catch (e) {
        this.autocompleteLoader = false;
        throw e;
      }
    },
  },
};
</script> 
<style scoped>
.acceptTermsCoopClass {
  color: #43425d !important;
  font-size: 14px !important;
}

.intBold {
  font-family: "interBold";
  font-size: 14px;
  color: #263573;
}
</style>
