import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#263573",
        secondary: "#4FC3F7",
        accent: "#7460EE",
        greyish: "#3E5569",
        error: "#E52628",
        info: "#2196F3",
        success: "#36BEA6",
        warning: "#FB8C00",
        greenish: "#deebe1"
      },
    },
  },
});
