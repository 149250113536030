<template>
  <v-col class="masterContainer">
    <v-row>
      <v-col align="left" class="textDecorationBold font16 flex-grow-0 pr-0">
        <span> Coins </span>
      </v-col>
      <v-col class="flex-grow-0 pt-2 pl-2">
        <InfoField color="#263573" :hoverText="coinHoverText" />
      </v-col>
    </v-row>
    <v-row class="ml-n6 mr-n6 mt-0">
      <v-col class="pl-0 pr-0 pt-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="gunPowderColor textInter font12 pt-0" align="left">
        <span> {{ $t("loadManageCoins") }} </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="left">
        <span class="goldText textInterBold font14"> {{ $t("myCoins") }} </span>
      </v-col>
    </v-row>
    <v-row class="flex-nowrap" v-if="mobileBreakpoint != true">
      <v-col
        align="left"
        class="cusTab font14 textDecorationBold"
        style="min-width: 190px"
      >
        <span> Provider </span>
      </v-col>
      <v-col
        align="left"
        class="cusTab font14 textDecorationBold"
        style="min-width: 76px"
      >
        <span> {{ $t("availableCoins") }} </span>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pt-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      :class="mobileBreakpoint != true ? 'flex-nowrap' : 'flex-column'"
      v-for="(item, i) in coinsAvailable"
      :key="i"
    >
      <v-col>
        <v-row class="flex-nowrap">
          <v-col v-if="mobileBreakpoint == true" class="flex-grow-0">
            <v-row align="center" style="min-width: 140px; min-height: 56px">
              <v-col
                align="left"
                class="cusTab font14 textDecorationBold align-self-center"
              >
                <span> Provider: </span>
              </v-col>
            </v-row>
            <v-row style="min-width: 140px; min-height: 62px">
              <v-col
                align="left"
                class="cusTab font14 textDecorationBold pb-2"
              >
                <span> {{ $t("availableCoins") + ":" }} </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row
              :class="mobileBreakpoint != true ? 'flex-nowrap' : 'flex-column'"
            >
              <v-col align="left" style="min-width: 190px">
                <v-row class="flex-nowrap">
                  <v-col class="flex-grow-0" align="left">
                    <v-img size="30" :src="item.src" />
                  </v-col>
                  <v-col
                    class="pl-0 align-self-center text-no-wrap"
                    align="left"
                  >
                    <span
                      style="text-transform: none !important; color: #43425d"
                    >
                      {{ item.title }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                align="left"
                :class="item.coins <= 0 ? 'errorColor' : 'successColor'"
                class="textDecorationBold font14 align-self-center pl-4"
                style="min-width: 76px"
              >
                <span> {{ item.coins }} </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="mobileBreakpoint == true">
          <v-col class="pt-0 pb-0 pr-0">
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="mobileBreakpoint != true">
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      class="justify-end"
      v-if="
        ['Secretary', 'OfficeAdmin', 'Admin'].includes(this.userDetails.Role)
      "
    >
      <v-col class="flex-grow-0">
        <v-btn
          class="btn textDecorationBold"
          elevation="0"
          outlined
          @click="openTheDialog"
        >
          <v-row>
            <v-col class="align-self-center pt-5 pr-0 pl-2 flex-grow-0">
              <Icon icon="fa-solid:coins" size="18" color="#e89008" />
            </v-col>
            <v-col class="align-self-center pl-0">
              <span> {{ $t("loadCoins") }} </span>
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <!-- <v-col class="flex-grow-0">
                <v-btn class="btn textDecorationBold" elevation="0" outlined>
                    <v-row>
                        <v-col class="align-self-center pt-5 pr-0 pl-2 flex-grow-0">
                            <Icon icon="fa-solid:coins" size="18" color="#e89008" />
                        </v-col>
                        <v-col class="align-self-center pl-0">
                            <span> {{ $t("loadCoins") }}</span> 
                        </v-col>
                    </v-row>
                </v-btn>
            </v-col> -->
    </v-row>
    <v-row v-if="mobileBreakpoint != true">
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.open" :max-width="569" :max-height="763">
      <LoadCoinsPopup
        :title="$t('requestSubmitted')"
        :onExitHandler="closeDialog"
        :onSaveHandler="onSaveButtonCo"
        :data="data"
        :addLoading="addLoading"
      />
    </v-dialog>
  </v-col>
</template>
<script>

/*
 Name:Coins
 Description: Component to render the coins informations with load pop up

  API Property:
    data: Object with data for display
    fetchSpecificContact: Function from the parent to update the contact

    Components Used:
      InfoField
      Icon
      LoadCoinsPopup

    Endpoints Functions Called:
      -ContactsRepo
        editContact
*/
import LoadCoinsPopup from "@/components/Popups/LoadCoinsPopup";
import InfoField from "@/components/Fields/InfoField/InfoField.vue";
import RealEstateModel from "@/static/realEstate/realEstate.js";
import { mapState } from "vuex";

import i18n from "@/i18n/i18n";
import { Icon } from "@iconify/vue2";
import Repository from "@/services/Repository";

const ContactsRepo = Repository.get("contacts");
export default {
  name: "Coins",
  props: {
    data: Object,
    fetchSpecificContact: Function,
  },
  components: { InfoField, Icon, LoadCoinsPopup },

  data() {
    return {
      coinsAvailable: [],
      addLoading: false,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
    };
  },
  created() {
    this.fentchCoins();
  },
  methods: {
    fentchCoins() {
      let typeCoins;
      this.coinsAvailable = [];
      RealEstateModel.providers.map((m) => {
        if (m.title == "newdeal.gr") {
          typeCoins = this.data.coinsInfo.AvailableAdsNewDeal;
        } else if (m.title == "spitogatos") {
          typeCoins = this.data.coinsInfo.AvailableAdsSpitogatos;
        } else if (m.title == "Χρυσή ευκαιρία") {
          typeCoins = this.data.coinsInfo.AvailableAdsXE;
        }
        this.coinsAvailable.push({
          coins: typeCoins,
          pricePerCoin: this.pricePerCoin,
          id: m.id,
          title: m.title,
          src: m.src,
        });
      });
    },
    openTheDialog() {
      this.openDialog("loadCoins");
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type,
        data,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    async onSaveButtonCo(body) {
      try {
        this.addLoading = true;
        await ContactsRepo.editContact(this.data.accountEditInfo._id, body);
        await this.fetchSpecificContact(this.data.accountEditInfo._id, true);
        this.fentchCoins();
        this.addLoading = false;
        this.closeDialog();
      } catch (e) {
        this.addLoading = false;
      }
    },
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    coinHoverText() {
      return i18n.t("coinsPublish");
    },
  },
};
</script>
<style scoped>
.btn {
  text-transform: unset !important;
  color: #263573;
  background-color: white !important;
  min-width: 160px !important;
}
</style>