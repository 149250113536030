<template>
  <v-col>
    <iframe :width="this.width" :height="this.height" :src="getEmbedLink(url)" title="YouTube video player"
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
    </iframe>
  </v-col>
</template>

<script>

/*
  Name:VideoField
  Description: Field with video via iframe
  API Property
    url: String for url of video
    width: String for width of area
    height: String for height if area
*/
import CoreHelper from "@/helpers/Core/core";
export default {
  name: "VideoField",
  props: {
    url: String, // Url of video
    width: String, // Width of area
    height: String, // Height if area
  },
  methods: {
    getEmbedLink(link) {
      return link
        ? "https://www.youtube.com/embed/" + CoreHelper.getVideoEmbedLink(link)
        : "";
    },
  },
};
</script>