<template >
  <v-row>
    <v-col cols="12" align="left">
      <span class="textInterBold font14 goldText">{{$t("contactInfo")}}</span>
    </v-col>

    <v-col>
      <ReadField
        v-if="data.phoneNumber"
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('telCom')"
        :data="data.phoneNumber"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="telephone"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="$t('otherTel')"
        :data="telephone"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="data.email"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="'E-mail'"
        :data="data.email"
        :isUnderline="false"
      />
    </v-col>
    <v-col
      cols="12"
      xl="4"
      lg="4"
      md="4"
      sm="4"
      xs="12"
      class="pt-4"
      v-if="data.otherEmail"
    >
      <ReadField
        :fontSize="fontSizeRF"
        :fontSizeValue="fontSizeValue"
        :fontFamilyLabel="fontFamilyLabel"
        :fontFamilyValue="fontFamilyValue"
        :label="'E-mail 2'"
        :data="data.otherEmail"
        :isUnderline="false"
      />
    </v-col>
  </v-row>
</template>
<script>
/*
Name:ContactInfo
Description: Component to render ContactInfo using ReadFields 

API Property:
Data: Object containing ContactInfo data 
            data: {
                phoneNumber: "2100000000",
                otherPhoneNumber: "690000000",
                email: "pelatis@gmail.com",
                otherEmail: "pelatis@gmail.com",
            },

Components Used:
  ReadField
*/
import ReadField from "@/components/Fields/ReadField/ReadField.vue";
export default {
  name: "ContactInfo",
  components: { ReadField },
  props: ["data"],
  data() {
    return {
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
      fontSizeValue: "13px",
    };
  },
  computed: {
    telephone() {
      return Array.isArray(this.data.otherPhoneNumber)
        ? this.data.otherPhoneNumber.join(", ")
        : this.data.otherPhoneNumber;
    },
  },
};
</script>