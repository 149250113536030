var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{class:{ 'pt-0 pb-0 fill-height': _vm.fullScreen }},[(_vm.showTitle || _vm.showPagination)?_c('v-row',{staticClass:"align-center pa-0"},[(
        _vm.showTitle &&
        _vm.data &&
        _vm.data.length > 0 &&
        _vm.data[_vm.currentCarouselImage].title &&
        _vm.data[_vm.currentCarouselImage].title.gr
      )?_c('v-col',{staticClass:"flex-grow-0 text-no-wrap pl-0 pt-3 textDecorationBold font-size-14",attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.data[_vm.currentCarouselImage].title.gr)+" ")]):_vm._e(),(
        _vm.showTitle &&
        _vm.data &&
        _vm.data.length > 0 &&
        _vm.data[_vm.currentCarouselImage].public != 'Public'
      )?_c('v-col',{staticClass:"flex-grow-0 text-no-wrap pl-0 pb-0 pt-3",attrs:{"align":"left"}},[_c('HintField',{attrs:{"icon":"mdi-eye-off","color":"#000000","data":_vm.$t('visibleOnlyToYou')}})],1):_vm._e(),(_vm.showPagination)?_c('v-col',{attrs:{"align":"right"}},[_c('b',[_vm._v(_vm._s(_vm.currentCarouselImage + 1 + "/" + _vm.data.length))])]):_vm._e()],1):_vm._e(),_c('v-row',{staticClass:"carousArea",class:{ 'mt-0 mb-0 fill-height': _vm.fullScreen }},[_c('v-col',{staticClass:"pl-0",class:{
        'pb-2 pt-4 fill-height': _vm.fullScreen,
        'pb-6 pt-0 leftImg': !_vm.fullScreen,
      },on:{"click":function () {
          _vm.fullScreenOnClick ? _vm.openMediaFullScreen() : true;
        }}},[_c('v-carousel',{staticClass:"imgBorder blueBackground",class:{
          'height-100': _vm.fullScreen,
        },style:(_vm.carouselStyle),attrs:{"hide-delimiters":"","show-arrows":_vm.data && _vm.data.length > 1 ? true : false},model:{value:(_vm.currentCarouselImage),callback:function ($$v) {_vm.currentCarouselImage=$$v},expression:"currentCarouselImage"}},_vm._l((_vm.data),function(item,i){return _c('v-carousel-item',{key:i,class:{
            'height-100': _vm.fullScreen,
            overFlow: _vm.showBottomSlider,
          }},[_c('v-img',{class:{
              'height-100': _vm.fullScreen,
            },style:([
              _vm.showBottomSlider
                ? { width: _vm.sliderWidth + '%' }
                : { width: 'auto', height: '500px' } ]),attrs:{"src":_vm.getImage(item),"lazy-src":_vm.getImage(item),"contain":"","alt":_vm.getImage(item)}})],1)}),1),(_vm.showBottomSlider)?_c('div',{staticClass:"blueBackground ma-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('b',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.currentCarouselImage + 1 + "/" + _vm.data.length)+" ")])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-slider',{attrs:{"color":"white","step":"1","min":"0","max":_vm.data.length},model:{value:(_vm.currentCarouselImage),callback:function ($$v) {_vm.currentCarouselImage=$$v},expression:"currentCarouselImage"}},[_vm._v(" > ")])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-slider',{staticClass:"align-self-stretch",attrs:{"color":"white","min":"1","max":"100","step":"1"},model:{value:(_vm.sliderWidth),callback:function ($$v) {_vm.sliderWidth=$$v},expression:"sliderWidth"}})],1),_c('v-col',[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.openMediaFullScreen}},[_vm._v(" mdi-fullscreen ")])],1)],1)],1):_vm._e()],1),(_vm.showImgDetails)?_c('v-col',{staticClass:"carousDetArea"},[_c('v-row',{staticClass:"overflow-y-auto galleryScrollbar"},_vm._l((_vm.data),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex child-flex pb-4 pl-2 pr-2 pt-0 noFlexGrow",on:{"click":function($event){_vm.currentCarouselImage = i}}},[_c('v-img',{staticClass:"grey lighten-2 imgBorder",staticStyle:{"cursor":"pointer"},attrs:{"max-height":"168","max-width":"270","min-height":"168","height":"168","width":"270","src":_vm.getImage(item),"lazy-src":_vm.getImage(item),"aspect-ratio":"1:2"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }