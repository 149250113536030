<template>
  <v-row class="ratingField">
    <v-tooltip top :nudge-bottom="4">
      <template v-slot:activator="{ on, attrs }">
        <v-col
          v-if="title == true"
          class="
            d-flex
            justify-center
            flex-grow-0
            text-no-wrap
            primaryColor
            textDecorationBold
          "
          :class="wrap ? 'flex-wrap' : ''"
        >
          <v-img
            v-bind="attrs"
            v-on="on"
            max-height="14"
            max-width="19"
            :src="require('@/assets/demands/approved.png')"
            style="  padding-bottom: 0px;"
          ></v-img>
          <span  style="color: #77bd86;  padding-left: 10px; font-size: 14px;"> {{ $t("goodMatch") }} </span>
        </v-col>

        <v-col
          v-if="title == false"
          class="
            d-flex
            justify-center
            flex-grow-0 flex-wrap
            text-no-wrap
            primaryColor
          "
        >
          <v-img
            v-bind="attrs"
            v-on="on"
            max-height="26"
            min-height="26"
            max-width="15"
            :src="require('@/assets/demands/declined.png')"
            class="mb-n1"
          ></v-img>
          <span style="#707070"> {{ $t("noMatch") }} </span>
        </v-col>
        <v-col v-if="title == null">
          <span class="font14"> -- </span>
        </v-col>
      </template>
      <span>{{ spanDisplay }}</span>
    </v-tooltip>
  </v-row>
</template>

<script>
/*
  Name:RatingField
  Description: Status component for matching leads at the demands

  API Property:
    title: Boolean for the approve, decline or null matching leads
    wrap: Boolean for wrap or no-wrap for the icon
*/
import i18n from "@/i18n/i18n";

export default {
  name: "MatchingLeadStatus",
  props: {
    title: Boolean,
    wrap: { type: Boolean, default: true },
  },
  async created() {
    this.spanDisplay = this.title
      ? i18n.t("itHasBeenApproved")
      : i18n.t("notApproved");
  },
};
</script>