<template>
  <v-card>
    <v-card-title>
      <v-col>
        <v-row>
          <v-col
            align="left"
            class="pb-0 primaryColor textDecorationBold"
            :class="mobileBreakpoint != true ? 'font24' : 'font13'"
          >
            <span>
              {{ $t("interestedEvaluation") }}
            </span>
          </v-col>
        </v-row>
        <v-row class="mx-n8">
          <v-col class="px-0">
            <v-divider />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-row>
              <v-col align="left" class="font14 textDecorationBold">
                <span> {{ $t("interested") }} </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="left" class="pt-0">
                <AvatarField
                  :data="cusName
                          .split(/\s/)
                          .reduce(
                            (response, word) => (response += word.slice(0, 1)),
                            ''
                          )
                  "
                  :size="32"
                  fontSizeImg="14px"
                  :showName="true"
                  :nameContent="cusName"
                  rowClass=" flex-nowrap"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-n8">
          <v-col class="px-0">
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-col>
        <v-row v-for="(demand, index, i) in firstgroup" :key="i">
          <v-col align="left" class="primaryColor textDecorationBold font14 pb-0" cols="12">
            <span> {{(index+1) + '. ' + $t(demand.q) }} </span>
          </v-col>
          <v-col
            align="left"
            class="flex-grow-0 text-no-wrap font14 py-0"
            v-for="(demandAnswers, i) in demand.a"
            :key="i"
          >
            <v-radio-group v-model="customer_evaluation[demand.id].indx" column>
              <v-radio class="mCard" :value="demandAnswers.indx" color="#e89008">
                <template v-slot:label>
                  <span class="gunPowderColor"> {{ $t(demandAnswers.title) }} </span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            v-if="demand.type == 'text' || demand.type == 'radioText'"
          >
            <v-text-field
              v-model="customer_evaluation[demand.id].textVal"
              :label="$t('enterPrice') + '...'"
              outlined
            />
          </v-col>
        </v-row>
        <v-row class="mx-n8">
          <v-col class="px-0">
            <v-divider />
          </v-col>
        </v-row>
        <v-row v-if="showMore == true">
          <v-col align="left" cols="12" class="font16 textDecorationBold">
            <span>
              {{ $t("demandEvaluation") + " | " + $t("moreQuestions") }}
            </span>
          </v-col>
          <v-col>
            <v-row v-for="(demand, index, i) in secondgroup" :key="i">
              <v-col align="left" class="py-0 primaryColor textDecorationBold font14" cols="12">
                <span> {{(index+1) + '. ' +$t(demand.q) }} </span>
              </v-col>
              <v-col v-if="demand.type == 'radio' || demand.type == 'radioText'">
                <v-row>
                  <v-col
                    align="left"
                    class="py-0 flex-grow-0 text-no-wrap font14"
                    v-for="(demandAnswers, i) in demand.a"
                    :key="i"
                  >
                    <v-radio-group
                      v-model="customer_evaluation[demand.id].indx"
                      column
                    >
                      <v-radio
                        class="mCard"
                        color="#e89008"
                        :label="$t(demandAnswers.title)"
                        :value="demandAnswers.indx"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="demand.type == 'text' || demand.type == 'radioText'"
                cols="12"
              >
                <v-text-field
                  v-model="customer_evaluation[demand.id].textVal"
                  :label="$t(demand.a.title)"
                  :placeholder="$t(demand.placeHolder)"
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="showMore == true" class="mx-n8">
          <v-col class="px-0">
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-row class="justify-end">
          <v-col class="align-self-center flex-grow-0">
            <a
              class="textDecorationBold errorColor font14"
              @click="closeDialog"
            >
              {{ $t("back") }}
            </a>
          </v-col>
          <v-col class="align-self-center flex-grow-0">
            <v-btn class="unsetCapitalize" outlined :loading="loading">
              <span
                class="textDecorationBold primaryColor"
                @click="onSubmitHandler"
              >
                {{ title }}
              </span>
            </v-btn>
          </v-col>
          <v-col class="align-self-center flex-grow-0">
            <v-btn
              class="unsetCapitalize"
              @click="showMore = !showMore"
              color="error"
            >
              <span class="textDecorationBold">
                {{ showMore ? $t("lessQuestions") : $t("moreQuestions") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script>
/*
 Name:ContactEvaluation
 Description: Component to render and evaluation form for the user

 API Property:
        customer: Object containing customer details,
        closeDialog: Function for closing dialog
        onSubmit: Function for the entry button
        isCreate: Boolean for create or edit evaluation
        title: String for the button

     
    Components Used:
      AvatarField 

    Endpoints Functions Called:      
      -ContactRepo
        get
*/
import AvatarField from "@/components/Fields/AvatarField/AvatarField";
import Repository from "@/services/Repository";
const ContactRepo = Repository.get("contacts");
import moment from "moment";
import i18n from "@/i18n/i18n";

export default {
  name: "ContactEvaluation",
  props: {
    isCreate: { type: Boolean, default: true },
    customer: Object,
    closeDialog: Function,
    onSubmit: Function,
    title: { type: String, default: i18n.t('entryDemand') },
  },
  components: {
    AvatarField,
  },
  data() {
    return {
      customer_evaluation: {
        1: { question: "", title: "",  val: 0, indx: 3 },
        2: { question: "", title: "",  val: 0, indx: 3 },
        3: { question: "", title: "",  val: 0, indx: 3 },
        4: { question: "", title: "",  val: 0, indx: 3 },
        5: { question: "", title: "",  val: 0, indx: 3, textVal: "" },
        6: { question: "", textVal: "" },
        7: { question: "", textVal: "" },
        8: { question: "", title: "",  val: 0, indx: 2 },
        9: { question: "", title: "",  val: 0, indx: 2 },
        10: { question: "", title: "", val: 0, indx: 2 },
        11: { question: "", title: "", val: 0, indx: 2 },
        12: { question: "", textVal: "" },
        13: { question: "", title: "", val: 0, indx: 2 },
        14: { question: "", title: "", val: 0, indx: 2, textVal: "" },
        15: { question: "", title: "", val: 0, indx: 2 },
        16: { question: "", title: "", val: 0, indx: 2 },
        avgRate: 0,
      },
      showMore: false,
      loading: false,
      data: [
        {
          id: 1,
          q: "evaluationQuest1",
          a: [
            { title: 'evaluationQuest1_1', val: 5, indx: 0 },
            { title: 'evaluationQuest1_2', val: 3, indx: 1 },
            { title: 'evaluationQuest1_3', val: 1,indx: 2 },
            { title: 'evaluationQuest1_4', val: 0, indx: 3 },
          ],
          type: "radio",
        },
        {
          id: 2,
          q: "evaluationQuest2",
          a: [
            { title: 'evaluationQuest2_1', val: 5, indx: 0 },
            { title: 'evaluationQuest2_2', val: 3, indx: 1 },
            { title: 'evaluationQuest2_3', val: 1, indx: 2 },
            { title: 'evaluationQuest2_4', val: 0, indx: 3 },
          ],
          type: "radio",
        },
        {
          id: 3,
          q: "evaluationQuest3",
          a: [
            { title: 'evaluationQuest3_1', val: 5, indx: 0 },
            { title: 'evaluationQuest3_2', val: 3, indx: 1 },
            { title: 'evaluationQuest3_3', val: 1, indx: 2 },
            { title: 'evaluationQuest3_4', val: 0, indx: 3 },
          ],
          type: "radio",
        },
        {
          id: 4,
          q: "evaluationQuest4",
          a: [
            { title: 'evaluationQuest4_1', val: 5, indx: 0 },
            { title: 'evaluationQuest4_2', val: 3, indx: 1 },
            { title: 'evaluationQuest4_3', val: 1, indx: 2 },
            { title: 'evaluationQuest4_4', val: 0, indx: 3 },
          ],
          type: "radio",
        },
        {
          id: 5,
          q: "evaluationQuest5",
          a: [
            { title: 'evaluationQuest5_1', val: 5,indx: 0 },
            { title: 'evaluationQuest5_2', val: 3, indx: 1 },
            { title: 'evaluationQuest5_3', val: 1, indx: 2 },
            { title: 'evaluationQuest5_4', val: 0, indx: 3 },
          ],
          type: "radioText",
        },
        {
          id: 6,
          q: "evaluationQuest6",
          a: [{ title: 'evaluationQuest6_1' }],
          type: "text",
        },
        {
          id: 7,
          q: "evaluationQuest7",
          a: [{ title: 'evaluationQuest7_1' }],
          type: "text",
        },
        {
          id: 8,
          q: "evaluationQuest8",
          a: [
            { title: 'evaluationQuest8_1', val: 5, indx: 0 },
            { title: 'evaluationQuest8_2', val: 3,indx: 1 },
            { title: 'evaluationQuest8_3', val: 0,indx: 2 },
          ],
          type: "radio",
        },
        {
          id: 9,
          q: "evaluationQuest9",
          a: [
            { title: 'evaluationQuest9_1', val: 5,indx: 0 },
            { title: 'evaluationQuest9_2', val: 3,indx: 1 },
            { title: 'evaluationQuest9_3', val: 0,indx: 2 },
          ],
          type: "radio",
        },
        {
          id: 10,
          q: "evaluationQuest10",
          a: [
            { title: 'evaluationQuest10_1', val: 5,indx: 0 },
            { title: 'evaluationQuest10_2', val: 3, indx: 1 },
            { title:'evaluationQuest10_3', val: 0, indx: 2 },
          ],
          type: "radio",
        },
        {
          id: 11,
          q: "evaluationQuest11",
          a: [
            { title: 'evaluationQuest11_1', val: 5, indx: 0 },
            { title: 'evaluationQuest11_2', val: 3, indx: 1 },
            { title: 'evaluationQuest11_3', val: 1, indx: 2 },
          ],
          type: "radio",
        },
        {
          id: 12,
          q: "evaluationQuest12",
          a: [{ title: 'evaluationQuest12_1' }],
          type: "text",
        },
        {
          id: 13,
          q: "evaluationQuest13",
          a: [
            { title: 'evaluationQuest13_1', val: 5, indx: 0 },
            { title: 'evaluationQuest13_2', val: 3, indx: 1 },
            { title: 'evaluationQuest13_3', val: 0, indx: 2 },
          ],
          type: "radio",
        },
        {
          id: 14,
          q: "evaluationQuest14",
          a: [
            { title: 'evaluationQuest14_1', val: 5, indx: 0 },
            { title: 'evaluationQuest14_2', val: 3, indx: 1 },
            { title: 'evaluationQuest14_3', val: 0, indx: 2 },
          ],
          placeHolder: "evaluationQuest14_4",
          type: "radioText",
        },
        {
          id: 15,
          q: "evaluationQuest15",
          a: [
            { title: 'evaluationQuest15_1', val: 5, indx: 0 },
            { title: 'evaluationQuest15_2', val: 3, indx: 1 },
            { title: 'evaluationQuest15_3', val: 0, indx: 2 },
          ],
          type: "radio",
        },
        {
          id: 16,
          q: "evaluationQuest16",
          a: [
            { title: 'evaluationQuest16_1', val: 5, indx: 0 },
            { title: 'evaluationQuest16_2', val: 3, indx: 1 },
            { title: 'evaluationQuest16_3', val: 0, indx: 2 },
          ],
          type: "radio",
        },
      ],
    };
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    cusName(){
      return this.customer?.name ?? 
              (this.customer?.FirstName ?? '--') + ' ' + 
              (this.customer?.LastName ?? '--');
    },
    firstgroup() {
      return this.data.filter((d, index) => {
        if (index <= 4) return d;
      });
    },
    secondgroup() {
      return this.data.filter((d, index) => {
        if (index > 4) return d;
      });
    },
  },
  created() {
    if (!this.isCreate) {
      this.customer_evaluation = this.customer?.customer_evaluation ?? this.customer_evaluation;
    }
  },
  methods: {
    async onSubmitHandler() {
      this.loading = true;
      for (let i = 1; i <= 16; i++){
        this.customer_evaluation.updatedDate = moment(new Date()).format('YYYY.MM.DD HH:mm');
        this.customer_evaluation[i].question = this.data[i-1].q;
        if ( ![null,undefined,''].includes(this.customer_evaluation[i].indx) ){
          this.customer_evaluation[i].title = this.data[i-1].a[this.customer_evaluation[i].indx].title;
          this.customer_evaluation[i].val = this.data[i-1].a[this.customer_evaluation[i].indx].val;
        }
      }
      let body = { customer_evaluation: this.customer_evaluation };
      await ContactRepo.editContact(this.customer["_id"], body);
      this.loading = false;
      this.onSubmit(this.customer_evaluation);
    },
  },
};
</script>
