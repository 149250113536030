<template>
  <v-col class="pl-0 pr-0">
    <v-row class="justify-space-between">
      <v-col v-html="labelContent" :class="labelClasses"> </v-col>
      <v-col class="text-right">
        <v-btn v-if="supportUpload == true" class="btn-custom mr-3" key="fBtn0" outlined :color="btnColor"
          :disabled="nonSelected" v-on:click="btnClicked()">
          {{ btnText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="filesRow">
      <FileList :listIcon="listIcon" :documents="documentsArray" :parentDocTypes="parentDocTypes"
        :docTypesList="getDocTypesList" :isNotParent="!nonSelected" :selectedDocType="selectedDocType"
        :selectDocTypeHandler="updateDocType" :clearSelectedHandler="clearSelected"
        :hasSubCategories="hasSubCategories" />
      <FilesTable :title="$t(selectedCategoryDescription)" :documents="showDocuments" :supportUpdate="supportUpdate"
        :updateHandler="updateDoc" :supportDelete="supportDelete" :deleteHandler="deleteDoc"
        :supportDownload="supportDownload" :downloadHandler="downloadDoc" />
    </v-row>
  </v-col>
</template>

<script>
/* 
  Name:Files
  Description: Files component to generate quickly Files table with lists. Support select document type and clear selected document type also supports update,delete,download files
  API Property 
  data: Object containing the data for the files,
  documents: Array containing documents
  docTypesList: Array containing document types
  btnText: String containing the text to be displayed on button
  btnColor: String containing the color of the button
  btnClickedHandler: Handler function from parent when button clicked,
  supportUpload:  Boolean variable to show/hide the upload button
  supportUpdate:  Boolean variable to show/hide the update button
  updateHandler: Handler function from parent when updating file
  supportDelete: Boolean variable to show/hide the delete button
  deleteHandler: Handler function from parent when deleting file
  supportDownload: Boolean variable to show/hide the download button
  downloadHandler: Handler function from parent when downloading file  
  listIcon: String containing Type of list icon for example "count","hint"
  labelClasses: String containing the classes for top left label area
  labelContent: String containing the Content of top left label area (Even HTML is accepted)

  Components Used:
    FilesTable
    FileList
*/
import { mapState } from "vuex";
import FilesTable from "./FilesTable.vue";
import FileList from "./FileList.vue";

export default {
  name: "Files",
  props: {
    data: Object,
    documents: Object, // Array of documents
    docTypesList: Array, // Array of file categories
    hasSubCategories: { type: Boolean, default: true },
    excludeObjectKeys: {
      type: Array,
      default: () => ["_id"]
    }, // Array of saved tags
    btnText: String, //Text displayed on button
    btnColor: { type: String, default: "primary" }, //Color of button
    btnClickedHandler: Function,
    supportUpload: { type: Boolean, default: false },
    supportUpdate: { type: Boolean, default: false },
    updateHandler: Function,
    supportDelete: { type: Boolean, default: false },
    deleteHandler: Function,
    supportDownload: { type: Boolean, default: false },
    downloadHandler: Function,
    listIcon: { type: String, default: "count" }, // Type of list icon "count","hint"
    labelClasses: String, // Classes for top left label area
    labelContent: String, // Content of top left label area (Even HTML is accepted)
  },
  components: { FilesTable, FileList },
  data() {
    return {
      headers: [],
      pagePerItem: 5,
      selectedDocType: "all",
      selectedParentIndex: -1,
      selectedParent: "",
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    nonSelected() {
      return (
        this.selectedDocType == "" ||
        this.selectedDocType == "all" ||
        this.selectedDocType == undefined
      );
    },
    getDocTypesList() {
      try {
        return this.hasSubCategories == true ? this.docTypesList[this.selectedParentIndex].subCats : this.docTypesList; //Return subCategories by parent index
      } catch {
        return [];
      }
    },
    parentDocTypes() {
      return this.docTypesList; //Return parent categories
    },
    documentsArray() {
      let docsArray = [];
      let allDocs = { docType: "all", docs: [], count: 0 };
      let objKeys = Object.keys(this.documents).filter(
        (i) => !this.excludeObjectKeys.includes(i) //Return category keys from documents prop that do not contain _id and estate_id
      );
      objKeys.map((o) => {
        let x = {
          //Create object for docsArray
          docs: this.documents[o],
          docType: o,
          count:
            this.documents && this.documents[o] && this.documents[o].length
              ? this.documents[o].length
              : 0,
        };
        let y = this.documents && this.documents[o] ? [...this.documents[o]] : y; //Create object for allDocs and add objectProperty docType and index on each element of array of docs
        if (y) {
          y = y.map((ym, index) => {
            ym.docType = o;
            ym.indx = index;
            return ym;
          });
          allDocs.docs = [...allDocs.docs, ...y];
          allDocs.count =
            allDocs.count + y.length != undefined ? allDocs.count + y.length : 0;
        }
        if (x) docsArray.push(x);
      });
      docsArray = [...docsArray, allDocs];
      return docsArray;
    },
    showDocuments() {
      return this.documentsArray.filter((m) => {
        return m.docType == this.selectedDocType;
      });
    },
    selectedCategoryDescription() {
      try {
        return this.nonSelected == true && this.hasSubCategories == true ? this.parentDocTypes[0].name
          : this.getDocTypesList.filter((e) => e.id == this.selectedDocType)[0].name;
      } catch {
        return "";
      }
    },
  },
  methods: {
    btnClicked() {
      this.btnClickedHandler(this.selectedDocType, this.getDocTypesList);
    },
    updateDocType(newValue) {
      //If 'all' is selected then update the selectedParent else do not change
      this.selectedParent = this.nonSelected ? newValue : this.selectedParent;
      //If 'all' is selected then update the selectedParentIndex else do not change
      this.selectedParentIndex = this.nonSelected == true ? this.docTypesList.findIndex((e) => e.id == newValue) : this.selectedParentIndex;
      this.selectedDocType = newValue;
    },
    clearSelected() {
      this.selectedDocType = "all";
      this.selectedParentIndex = -1;
      this.selectedParent = "";
    },
    updateDoc(doc) {
      try {
        this.updateHandler(doc);
      } catch (e) {
        throw e;
      }
    },
    deleteDoc(doc) {
      try {
        this.deleteHandler(doc.docType, doc.indx);
      } catch {
        true;
      }
    },
    downloadDoc(doc) {
      try {
        this.downloadHandler(doc);
      } catch {
        true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.greyBottomBorder {
  border-bottom: 1px solid #adb3ca40 !important;
}

.noBoxShadow {
  box-shadow: none !important;
}

.fontSize14 {
  font-size: 14px !important;
}

.titleColor {
  color: #3a3a40;
}

.circleCustom {
  height: 23px !important;
  width: 23px !important;
  font-size: 10px;
  color: #ffffff;
  background-color: #263573;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.orangeColor {
  color: #e89005;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
}

.btn-custom {
  text-transform: initial;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

.listHeader {
  color: #263573;
  font-size: 12px;
}

@media screen and (max-width: 619px) {
  .filesRow {
    flex-direction: column;
  }
}
</style>
