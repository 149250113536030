<template>
  <v-col class="pb-0">
    <v-row>
      <v-col class="py-1" align="left">
        <span class="primaryColor textDecorationBold font15"
          >{{ $t("accountInfo") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('Email')"
      >
        <v-text-field
          class="font14"
          v-model="email"
          :label="$t('username') + '*'"
          clearable
          dense
          color="primary"
          hide-details="auto"
          :disabled="!canEdit"
        >
          <template slot="append">
            <v-col class="pb-1" v-if="!canEdit">
              <Icon icon="fa-solid:lock" size="20" color="#9d9d9d" />
            </v-col>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('LastCompany')"
      >
        <v-select
          v-model="lastCompany"
          :items="companyList"
          class="font14"
          clearable
          dense
          color="primary"
          hide-details="auto"
          :disabled="!canEdit"
          :label="$t('companyName')"
          item-value="name_id"
          item-text="name"
        >
          <template slot="append">
            <v-col class="pb-1" v-if="!canEdit">
              <Icon icon="fa-solid:lock" size="18" color="#9d9d9d" />
            </v-col>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="isAssociateContact">
      <v-col
        class="pb-0"
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        v-if="showAttribute('IsEnabled')"
      >
        <v-select
          v-model="IsEnabled"
          :items="IsEnabledList"
          :label="$t('partnerStatus')"
          dense
          return-object
          item-text="label"
          item-value="val"
          :disabled="!canEdit"
        >
          <template slot="append">
            <v-col class="pb-1" v-if="!canEdit">
              <Icon icon="fa-solid:lock" size="18" color="#9d9d9d" />
            </v-col>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
/*
 Name:AccountEditInfo
 Description: Component to render edit account options for a contact. We can edit email, company and status

 API Property:
    attributes: Object with the data to edit
    updateAttribute: Function from the parent to update the data
    validations: Object with the validations from the parent 
    attributeModel: Array with the data model
    kind: String with the edit type like "accountEditInfo"
    
    Components Used:
      Icon

    Endpoints Functions Called:
      -CompaniesRepo
        get
*/
import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import Repository from "@/services/Repository";
import i18n from "@/i18n/i18n";

const CompaniesRepo = Repository.get("companies");

export default {
  name: "AccountEditInfo",
  props: {
    attributes: Object,
    updateAttribute: Function,
    validations: Object,
    attributeModel: Array,
    kind: { type: String, default: "accountEditInfo" },
  },
  components: { Icon },
  async created() {
    this.companyList = await this.fetchCompany();
  },
  data() {
    return {
      companyList: [],
      IsEnabledList: [
        { label: i18n.t("active2"), val: "active" },
        { label: i18n.t("inactive2"), val: "inactive" },
        { label: i18n.t("semiActive2"), val: "semi-active" },
      ],
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    canEdit() {
      return ["HR", "Secretary", "OfficeAdmin", "Admin"].includes(
        this.userDetails.Role
      );
    },
    email: {
      get() {
        return this.attributes.Email;
      },
      set(value) {
        this.updateAttribute("contactInfo", "Email", value);
      },
    },
    Type() {
      return this.attributes.Type;
    },
    lastCompany: {
      get() {
        return this.attributes.LastCompany;
      },
      set(value) {
        this.updateAttribute(this.kind, "LastCompany", value);
      },
    },
    confirmPassword: {
      get() {
        return this.attributes.confirmPassword;
      },
      set(value) {
        this.updateAttribute(this.kind, "confirmPassword", value);
      },
    },
    isAssociateContact() {
      return (
        this.Type && this.Type.includes("Συνεργάτης")
      );
    },
    IsEnabled: {
      get() {
        return this.attributes.IsEnabled;
      },
      set(value) {
        this.updateAttribute(this.kind, "IsEnabled", value);
      },
    },
  },
  methods: {
    showAttribute(attribute) {
      return this.attributeModel.includes(attribute);
    },
    async fetchCompany() {
      try {
        const resp = await CompaniesRepo.get({
          action: "get_companies",
        });
        return resp.data;
      } catch (err) {
        console.error(err, "Error");
      }
    },
  },
};
</script>
  