import AxiosClientToken from "../Client/AxiosClientToken";

// const resourceCud = "/list";

export default {
  get(filters, params) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/query_requests/`,
      filters,
      { params: params }
    );
  },
  getPropertyMatcher(payload, id, params) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/matching_estates/` + id + "/",
      payload,
      { params: params }
    );
  },
  getGeneralMatcher(payload, id, params) {
    return AxiosClientToken.post(
      `https://newdealgr.com/api/newdeal_crm/matching_requests/` + id + "/",
      payload,
      { params: params }
    );
  },
  create(payload) {
    return AxiosClientToken.post(
      // `${process.env.VUE_APP_BASE_URL}/general/InsertRequest`,
      `https://newdealgr.com/api/newdeal_crm/insert-request/`,
      payload
    );
  },
  delete(id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/general/DeleteRequest/${id}`
    );
  },
  createComment(id, body) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/general/InsertRequestComment/${id}`,
      body
    );
  },
  updateComment(id, body, index) {
    return AxiosClientToken.put(
      `${process.env.VUE_APP_BASE_URL}/general/UpdateRequestComment/${id}/${index}`,
      body
    );
  },
  deleteComment(id, index) {
    return AxiosClientToken.delete(
      `${process.env.VUE_APP_BASE_URL}/general/DeleteRequestComment/${id}/${index}`
    );
  },

  async getReportData(filters, params) {
    try {
      const response = await AxiosClientToken.post(
        `${process.env.VUE_APP_BASE_URL}/list/ExportRequests`,
        filters,
        {
          params: params,
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      let currentDate = new Date();
      currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "_");
      link.setAttribute("download", "estates_" + currentDate); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      throw err;
    }
  },
  update(payload, id) {
    return AxiosClientToken.post(
      `${process.env.VUE_APP_BASE_URL}/general/UpdateRequest/${id}`,
      payload
    );
  },
};
