import i18n from "@/i18n/i18n";
export default {
  conditionList: [
    {
      id: 1,
      text: "new",
      val: "new",
    },
    {
      id: 2,
      text: "assigned",
      val: "assigned",
    },
    {
      id: 3,
      text: "contact",
      val: "contact",
    },
    {
      id: 4,
      text: "suggestion",
      val: "suggestion",
    },
    {
      id: 5,
      text: "negotiation",
      val: "negotiation",
    },
    {
      id: 6,
      text: "Deal",
      val: "deal",
    },
    {
      id: 7,
      text: "withdrawal",
      val: "withdrawal",
    },
    {
      id: 8,
      text: "inert",
      val: "inert",
    },
  ],
  originData: [
    {
      text: i18n.t("agent"),
      val: "Agent",
    },
    {
      text: i18n.t("direct"),
      val: "Direct",
    },
  ],
  availabilityList: [
    { id: 0, text: "leased", val: "Μισθωμένο" },
    { id: 1, text: "free", val: "Ελεύθερο χρήσης" },
    { id: 2, text: "warehouse", val: "Αποθήκη" },
  ],

  sourceData: [
    {
      text: "Πανό",
      val: 1,
    },
    {
      text: "Αφίσα",
      val: 2,
    },
    {
      text: "Newsletter",
      val: 3,
    },
    {
      text: "Απροειδοποίητο τηλεφώνημα",
      val: 4,
    },
    {
      text: "Ιστοσελίδα",
      val: 5,
    },
    {
      text: "Social Media",
      val: 6,
    },
    {
      text: "Δια ζώσης",
      val: 7,
    },
    {
      text: "Άλλα",
      val: 8,
    },
  ],
  availableForList: [
    { id: 0, val: "Για πώληση", text: "sale" },
    { id: 1, val: "Για ενοικίαση", text: "lease" },
    { id: 2, val: "Για αντιπαροχή", text: "consideration" },
  ],
  buildingStatusList: [
    { id: 0, text: "newlyBuilt", val: "Νεόδμητο" },
    { id: 1, text: "unfinished", val: "Ημιτελές" },
    { id: 2, text: "preservable", val: "Διατηρητέο" },
    { id: 3, text: "neoclassical", val: "Νεοκλασικό" },
    { id: 4, text: "renovated", val: "Ανακαινισμένο" },
    { id: 5, text: "needsToBeRenovated", val: "Χρήζει ανακαίνισης" },
  ],
  featuresList: [
    {
      text: "leased",
      val: 1,
      category: [0, 1, 3],
    },
    {
      text: "free",
      val: 2,
      category: [0, 1, 3],
    },
    {
      text: "airbnb",
      val: 3,
      category: [0],
    },
    {
      text: "warehouse",
      val: 4,
      category: [0, 1, 3],
    },
    {
      text: "furnished",
      val: 5,
      category: [0, 3],
    },
    {
      text: "allowingPets",
      val: 6,
      category: [0],
    },
    {
      text: "professionalUse",
      val: 7,
      category: [2],
    },
    {
      text: "offPlan",
      val: 8,
      category: [2],
    },
  ],
  buildingUseList: [
    { id: 0, val: "Επαγγελματική χρήση", text: "professionalUse" },
    { id: 1, val: "Εκτός σχεδίου", text: "offPlan" },
  ],
  demandTypeList: [
    { id: 0, val: "Ζήτηση με ακίνητο", text: "demandRealEstate" },
    { id: 1, val: "Γενικές Ζητήσεις", text: "generalDemands" },
  ],
  propertiesList: [
    { id: 0, val: "allRelatedProperties", text: "allRelatedProperties" },
    { id: 1, val: "myProperties", text: "myProperties" },
  ],
  filterValues: {
    yearMin: null,
    yearMax: null,
    areaMin: null,
    areaMax: null,
    available: [],
    category: null,
    subCategory: null,
    priceMin: null,
    priceMax: null,
    assessmentMin: null,
    assessmentMax: null,
    publishedSelected: null,
    realEstateCode: null,
    areaPriceMin: null,
    areaPriceMax: null,
    numberOfPlacesMin: null,
    numberOfPlacesMax: null,
    floorMin: null,
    floorMax: null,
    roomMin: null,
    roomMax: null,
    bathroomMin: null,
    bathroomMax: null,
    buildingStatus: null,
    buildingUse: [],
    askingTypeStatus: [],
    statusSelected: [],
    proUse: false,
    outOfPlan: false,
    buildTo: null,
    buildFrom: null,
    areasSqmMin: null,
    areasSqmMax: null,
    ratingFrom: null,
    ratingTo: null,
    reNewDateFrom: null,
    reNewDateTo: null,
    zoneSelected: null,
    locationGoogle: null,
    areaType: "meters2",
    isActiveMatching: false,
    customer: null,
    companyId: null,
    userId: null,
    filterRequest: {
      text: "all",
      val: 0,
    },
  },
  ratingFieldList: [
    {
      text: "1",
      val: 1,
    },
    {
      text: "2",
      val: 2,
    },
    {
      text: "3",
      val: 3,
    },
    {
      text: "4",
      val: 4,
    },
    {
      text: "5",
      val: 5,
    },
  ],
  requestFilters: [
    {
      text: "all",
      val: 0,
    },
    {
      text: "clientsRequests",
      val: 1,
    },
    {
      text: "officeRequests",
      val: 2,
    },
  ],
  sortFilters: [
    {
      text: "lastUpdate",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "UpdatedDay",
    },
    {
      text: "priceTable",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Estate.Price",
    },
    {
      text: "priceSqM2",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Estate.PricePerUnit",
    },
    {
      text: "squareMeters",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Estate.Unit",
    },
    {
      text: "creationDate",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "_id",
    },
  ],
  contactHeaders: [
    {
      text: "propertyType",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "displayType",
      width: "150px",
    },
    {
      text: "dateDemand",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "RegistrationDateDisplay",
      width: "130px",
    },
    {
      text: "nextFollowUp",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "followUpDisplay",
      width: "180px",
    },
    {
      text: "responsible",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Responsible",
      width: "350px",
    },
    {
      text: "condition",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "statusTextData",
      width: "170px",
    },
    {
      text: "choices",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "actions",
      width: "20px",
    },
  ],

  contactTemplates: [
    {
      column: "Responsible",
      content: "AgentName",
      subContent: "AgentLastCompany",
      dataObject: "AgentPhoto",
      absolutePath: "AgentAbsolutePath",
      isImg: "isImgAgent",
      size: 38,
      allowClickableAvatar: "allowClickableAvatar",
      avatarId: "agentId",
      rowClass: "primaryColor reportCardClass",
      elementType: "avatar",
      backgroundAvatarColor: "backgroundAgentColor",
    },
  ],

  headers: [
    {
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "unRead",
    },
    {
      text: "responsible",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Responsible",
      width: "350px",
    },
    {
      text: "propertyType",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Property",
    },
    {
      text: "region",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "EstateArea",
      width: "200px",
    },
    {
      text: "coopTo",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "CoopTo",
      width: "200px",
    },
    {
      text: "Matching Lead",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Lead",
      width: "200px",
      tooltip: "findDemands",
    },
    {
      text: "valuationF",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Valuation",
      width: "170px",
      tooltip: "valuationPoints",
    },
    {
      text: "condition",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "displayRequestStatus",
      width: "150px",
    },

    {
      text: "interested",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "InterestedParty",
      width: "350px",
    },
    {
      text: "registrationDateF",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "RegistrationDateFormatted",
    },
    {
      text: "priceFrom",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "priceFromText",
      width: "150px",
    },

    {
      text: "priceTo2",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "priceToText",
      width: "150px",
    },
    {
      text: "construction",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "constructionText",
    },
    {
      text: "renovation",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "renovationText",
    },
    {
      text: "floors",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "floorsText",
      width: "100px",
    },
    {
      text: "demandType2",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "DemandType",
      width: "150px",
    },
    {
      text: "bedroomsShort",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "characteristicsArray",
      width: "10px",
    },
    {
      text: "areasqm",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "AreaSqm",
      width: "100px",
    },
    {
      text: "choices",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "actions",
      width: "150px",
      fixed: true,
    },
  ],
  selectedHeaders: [
    {
      text: "",
      align: "center",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "unRead",
      width: "0px",
    },
    {
      text: "responsible",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Responsible",
      width: "350px",
    },
    {
      text: "propertyType",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Property",
    },
    {
      text: "region",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "EstateArea",
      width: "200px",
    },
    {
      text: "coopTo",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "CoopTo",
      width: "200px",
    },
    {
      text: "Matching Lead",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Lead",
      width: "200px",
      tooltip: "findDemands",
    },
    {
      text: "valuationF",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "Valuation",
      width: "170px",
      tooltip: "valuationPoints",
    },
    {
      text: "condition",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "displayRequestStatus",
      width: "150px",
    },
    {
      text: "choices",
      align: "center",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "actions",
      width: "150px",
      fixed: true,
    },
  ],
  templates: [
    {
      column: "unRead",
      numTitle: "numTitle",
      redRow: "unRead",
      elementType: "unread",
    },
    {
      column: "Responsible",
      content: "AgentName",
      subContent: "AgentLastCompany",
      dataObject: "AgentPhoto",
      absolutePath: "AgentAbsolutePath",
      isImg: "isImgAgent",
      size: 38,
      allowClickableAvatar: "allowClickableAvatar",
      avatarId: "agentId",
      rowClass: "primaryColor reportCardClass",
      elementType: "avatar",
      backgroundAvatarColor: "backgroundAgentColor",
    },
    {
      column: "InterestedParty",
      content: "ClientName",
      isImg: "isImgClient",
      dataObject: "ClientPhoto",
      size: 38,
      rowClass: "primaryColor reportCardClass",
      elementType: "avatar",
      allowClickableAvatar: "allowClickableAvatar",
      avatarId: "clientId",
      backgroundAvatarColor: "backgroundClientColor",
      fontSizeImg: "16px; color: #ACB2CA !important;",
      avatarStyle: "avatarStyle",
    },
    {
      column: "Property",
      title: "EstateCategory",
      elementType: "customSpan",
      rowClass: "primaryColor",
    },
    {
      column: "Lead",
      title: "MatchingLeads",
      elementType: "matchingStatus",
    },
    {
      column: "CoopTo",
      title: "displayTask",
      elementType: "customSpan",
      rowClass: "orangeTextInput textDecorationBold",
    },
    {
      column: "DemandType",
      title: "estateCodeTitle",
      elementType: "ClickableBox",
      titleOnHoverColor: "estateBoxColor",
      titleColor: "estateTitleColor",
      titleBox: "titleBox",
      rowClass: "primaryColor textDecorationBold",
    },
    {
      column: "Valuation",
      elementType: "rating",
      colorRating: "colorRating",
      disabledRating: "disabledRating",
      valueRating: "valueRating",
      sizeRating: "sizeRating",
      ratingValue: "ratingValue",
    },
    {
      column: "displayRequestStatus",
      elementType: "statusIcon",
      isIcon: "statusIsIcon",
      iconObj: "statusIconObj",
      textData: "statusTextData",
      textClass: "statusTextClass",
    },
    {
      column: "RegistrationDateFormatted",
      rowClass: " flex-nowrap ",
      dataObject: "",
      content: "expireTimeLeft",
      nameContentClass:
        "cusTab textDecorationBold font14 text-no-wrap pb-0 pl-0",
      elementType: "plainField",
      subContent: "RegistrationDateFormatted",
      subContentClass: "cusTab font14 text-no-wrap pt-0 pl-0",
      isImg: "",
      size: 30,
      fontSizeName: "16px",
      fontSizeSubContent: "16px",
      fontSizeImg: "16px",
    },
    {
      column: "characteristicsArray",
      elementType: "characteristics",
      characteristicsArray: "characteristicsArray",
      rightSelect: "rightSelect",
    },
  ],
};
