<template>
  <v-card width="690">
    <v-card-title class="ml-3 mt-3 mr-3 mb-0">
      <v-col class="pa-0">
        <v-row>
          <v-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
            <HeaderTitle :title="$t('selectSuggestionToReview')"
              :subTitle="data.Category + ' ' + $t('propTo') + ' ' + data.Task" />
          </v-col>
          <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2" class="flex-grow-0 text-no-wrap cusTab fontSize14 pb-6"
            align-self="end">
            <v-row>
              <v-col class="pt-0">
                <v-icon @click="closeDialog" class="primaryColorOnHover xClass">
                  mdi-close
                </v-icon>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pt-0">
                <span style="font-size: 14px"> {{  data.EstateCode  }} </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ml-n9 mr-n9 mt-0">
          <v-col class="pl-0 pr-0">
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row v-for="(item, i) in data.showcases" :key="i">
          <v-col>
            <v-row>
              <v-col class="pr-0">
                <v-row>
                  <v-col align="left" class="pl-0 pb-0 textDecorationBold" style="color: #3a3a40">
                    {{  $t("intrestedFrom")  }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pl-0">
                    <AvatarField :size="32" :data="item.intrestedPhoto" :isImg="
                      ![undefined, '', null].includes(item.intrestedPhoto)
                        ? true
                        : false
                    " backgroundColor="#77BD86" fontSizeName="14px; font-family: CenturyGothic !important;"
                      :showName="true" :nameContent="item.intrestedName" fontSizeImg="16px" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col align="left" class="pb-0 textDecorationBold" style="color: #3a3a40">
                    {{  $t("dateNote")  }}
                  </v-col>
                </v-row>
                <v-row class="pt-1">
                  <v-col align="left">
                    {{  convertEpochToDateTime(item.date)  }}</v-col>
                </v-row>
              </v-col>
              <v-col class="flex-grow-0">
                <v-row class="mt-2">
                  <v-col>
                    <v-btn elevation="1" align="center" class="btn" color="error" width="27" min-width="27"
                      max-width="27" height="27" @click="selectShowcase(item)">
                      <Icon icon="ic:sharp-greater-than" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="ml-n9 mr-n9">
              <v-col class="pl-0 pr-0">
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
/* 
  Name:ShowcaseModal
  Description: Modal for suggestion review
  API Property 
    data: Object for data
    submitHandler: Function on submit
    closeHandler: Function on close

  Components Used:
    HeaderTitle
    AvatarField
    Icon
  */
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";
import CoreHelper from "@/helpers/Core/core";
import { Icon } from "@iconify/vue2";

export default {
  name: "ReviewModal",
  components: {
    HeaderTitle,
    AvatarField,
    Icon,
  },
  props: { data: Object, submitHandler: Function, closeHandler: Function },
  data() {
    return {};
  },

  methods: {
    selectShowcase(showcase) {
      try {
        this.submitHandler(showcase);
      } catch {
        true;
      }
    },
    closeDialog() {
      try {
        this.closeHandler();
      } catch {
        true;
      }
    },
    convertEpochToDateTime(date) {
      return isNaN(date) ? date : CoreHelper.EpochToDateTime(date, "|");
    },
  },
};
</script>


<style scoped>
</style>
