<template>
  <v-col>
    <v-row v-if="userData && userData.customer_evaluation">
      <v-col>
        <v-row>
          <v-col>
            <DemandReviewCard
              :headerTitle="$t('levelEvaluation') + ' 1'"
              :userData="userData"
              :firstGroup="true"
              :sendHandler="() => true"
              :editHandler="editValuation"
              :exportAsPdfHandler="() => true"
              :allowEvaluation="allowEvaluation"
            />
          </v-col>
        </v-row>
        <v-row v-show="allowSecondCard()">
          <v-col>
            <DemandReviewCard
              :headerTitle="$t('levelEvaluation') + ' 2'"
              :userData="userData"
              :hideAvgRate="true"
              :sendHandler="() => true"
              :editHandler="editValuation"
              :exportAsPdfHandler="() => true"
              :allowEvaluation="allowEvaluation"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-row>
          <v-col align="center" class="pb-0">
            <v-img
              :src="require('@/assets/rating/norating.png')"
              
              width="200"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="primaryColor textDecorationBold font20">
               {{$t("noDemandsIntersted")}}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="allowEvaluation">
          <v-col>
            <v-btn
              class="btn-custom"
              @click="createValuation"
              key="fBtn0"
              outlined
              color="primary"
            >
              {{$t("interestedAssessment2")}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-if="dialog.type == 'editEvaluation'"
      v-model="dialog.open"
      max-width="820px"
      scrollable
    >
      <ContactEvaluation
        :isCreate="dialog.isCreate"
        :customer="dialog.data"
        :closeDialog="closeDialog"
        :onSubmit="onSubmitEval"
      />
    </v-dialog>
  </v-col>
</template>

<script>
/*
 Name:ValuationDemands
 Description: Component that shows valuation for the demands

 API Property:
    userData: Object containing all the  data to be rendered on the component . For example:
                    { name: "Ioannis", surname: "Katsarakis", img: "/uploads/images/image1.png" }
    allowEvaluation: Boolean if user can edit/create evaluation          
          
 Components Used:
    DemandReviewCard 
    ContactEvaluation
*/
import DemandReviewCard from "@/components/Demands/DemandReviewCard.vue";
import ContactEvaluation from "@/components/Demands/ContactEvaluation";
export default {
  name: "ValuationDemands",
  components: { DemandReviewCard, ContactEvaluation },
  props: {
    userData: Object,
    editHandler: Function,
    allowEvaluation: { type: Boolean, default: true }, // If user can edit/create evaluation
  },
  data() {
    return {
      dialog: {
        open: false,
        type: null,
        data: null,
        isCreate: false,
      },
    };
  },
  methods: {
    allowSecondCard(){
      let found = 0;
      if(this.userData?.customer_evaluation){
       for (let i = 6; i <= 16; i++) {
        let obj ={};
        obj = this.userData.customer_evaluation[i];
        obj.title || obj.textVal ? found++ : '';
        }
       }
       return found > 0 ? true : false;
    },
    editValuation() {
      let cloneObj = JSON.parse(JSON.stringify(this.userData));
      this.openDialog("editEvaluation", cloneObj, false);
    },
    createValuation(){
      let cloneObj = JSON.parse(JSON.stringify(this.userData));
      this.openDialog("editEvaluation", cloneObj, true);
    },
    openDialog(type, data, isCreate) {
      this.dialog = {
        open: true,
        type,
        data,
        isCreate,
      };
    },
    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    onSubmitEval(customer_evaluation) {
      this.editHandler(customer_evaluation);
      this.closeDialog();
    },
  },
};
</script>
