<template>
  <v-col v-if="this.show == true">
    <v-row>
      <v-col class="py-1" align="left">
        <span class="primaryColor textDecorationBold font15"
          >{{ $t("security") }}
        </span>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
        <v-text-field
          class="font14"
          v-model="UserPassword"
          :error-messages="UserPasswordErrors"
          :label="$t('oldPassword')"
          clearable
          dense
          color="primary"
          hide-details="auto"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show1 = !show1"
          :type="show1 ? 'text' : 'password'"
        >
        </v-text-field>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
        <v-text-field
          class="font14"
          v-model="UserNewPassword"
          :error-messages="UserNewPasswordErrors"
          :label="$t('password')"
          clearable
          dense
          color="primary"
          hide-details="auto"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show2 = !show2"
          :type="show2 ? 'text' : 'password'"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
        <v-text-field
          class="font14"
          v-model="confPass"
          :label="$t('confPass2')"
          :error-messages="confirmPassErrors"
          clearable
          dense
          color="primary"
          hide-details="auto"
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show3 = !show3"
          :type="show3 ? 'text' : 'password'"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
        <v-btn
          :min-width="147"
          :min-height="42"
          :max-width="147"
          :max-height="42"
          class="unsetCapitalize textDecorationBold"
          outlined
          color="primary"
          block
          @click="onSaveHandler"
        >
          {{ $t("passwordChange") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>
  
<script>

/*
 Name:SecurityEditInfo
 Description: Component to render personal info fields for contact

 API Property:
    dataId: Number with the data id
    openCongratsDialog: Function to open congrats dialog from the parent

    Endpoints Functions Called:
      -ContactsRepo
        editContact
*/
import { required, sameAs } from "vuelidate/lib/validators";
import i18n from "@/i18n/i18n";
import Repository from "@/services/Repository";
import moment from "moment";

const ContactsRepo = Repository.get("contacts");
export default {
  name: "SecurityEditInfo",
  props: {
    dataId: Number,
    openCongratsDialog: Function,
  },
  data() {
    return {
      //UserPassword: null,
      UserNewPassword: null,
      confPass: null,
      show: null,
      show1: false,
      show2: false,
      show3: false,
    };
  },
  created() {
    this.show = true;
  },
  computed: {
    confirmPassErrors() {
      const errors = [];
      if (!this.$v.confPass.$dirty) return errors;
      if (!this.$v.confPass.required) errors.push(i18n.t("requiredField"));
      if (!this.$v.confPass.sameAsPassword)
        errors.push(i18n.t("notSamePasswords"));
      return errors;
    },
    // UserPasswordErrors() {
    //   const errors = [];
    //   if (!this.$v.UserPassword.$dirty) return errors;
    //   if (!this.$v.UserPassword.required) errors.push(i18n.t("requiredField"));
    //   return errors;
    // },
    UserNewPasswordErrors() {
      const errors = [];
      if (!this.$v.UserNewPassword.$dirty) return errors;
      if (!this.$v.UserNewPassword.required)
        errors.push(i18n.t("requiredField"));
      return errors;
    },
  },
  validations: {
    // UserPassword: {
    //   required,
    // },
    UserNewPassword: {
      required,
    },
    confPass: {
      required,
      sameAsPassword: sameAs("UserNewPassword"),
    },
  },
  methods: {
    async onSaveHandler() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      let body = {};
      body["UpdatedDay"] = moment(new Date()).unix();
     // body["UserPassword"] = this.UserPassword;
      body["UserNewPassword"] = this.UserNewPassword;
      await ContactsRepo.editContact(this.dataId, body);
      this.openCongratsDialog();
      this.show = false;
    },
  },
};
</script>
  