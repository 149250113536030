<template>
  <v-menu
    offset-y
    transition="scroll-y-transition"
    class="menu"
    max-height="423"
    max-width="241"
    left
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row v-bind="attrs" v-on="on" class="justify-end flex-nowrap">
        <v-col
          v-if="mobileBreakpoint != true"
          class="flex-grow-0 text-no-wrap align-self-center pr-0"
        >
          <span
            class="textDecorationBold"
            style="color: white; font-size: 15px"
          >
            {{ getFirstname }}
          </span>
        </v-col>
        <v-col
          v-if="mobileBreakpoint != true"
          class="flex-grow-0 text-no-wrap align-self-center pl-1 pr-0"
        >
          <span class="textDecoration" style="font-size: 15px">
            {{ getLastname }}
          </span>
        </v-col>
        <v-col class="flex-grow-0 text-no-wrap align-self-center">
          <v-avatar>
            <v-img :src="tempImg" @error="imageError" size="50" alt="John" />
          </v-avatar>
        </v-col>
      </v-row>
    </template>
    <v-container class="notifContainer">
      <v-row class="pt-3">
        <v-col
          align="left"
          class="text-no-wrap menu-selection"
          @click="openProfileHandler"
        >
          <span style="" class="font15 textInter">
            {{ $t("myProfile") }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left" class="text-no-wrap menu-selection" @click="openEditProfile(0)">
          <span  class="font15 textInter">
            {{ $t("editProfile") }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left" class="text-no-wrap menu-selection" @click="openEditProfile(1)">
          <span  class="font15 textInter">
            {{ $t("myAccount") }}
          </span>
        </v-col>
      </v-row>
      <v-row
        v-if="
          userDetails.Role
            ? ['Associate', 'OfficeAdmin'].includes(userDetails.Role)
            : false
        "
      >
        <v-col align="left" class="text-no-wrap menu-selection" @click="openEditProfile(2)">
          <span  class="font15 textInter">
            {{ $t("coinsSubscriptions") }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left" class="text-no-wrap menu-selection" @click="onLogoutHandler">
          <span  class="font15 textInter">
            {{ $t("exit") }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-menu>
</template>
<script>
/*
 Name:ProfileMenu
 Description: Profile menu to navigate in profile choices

 API Property:
    openProfile : Function for the open profile in the 
    openEditProfile : Function for open edit profile
*/
import { mapGetters, mapState } from "vuex";
import Repository from "@/services/Repository";
const AuthRepo = Repository.get("auth");

export default {
  name: "ProfileMenu",
  components: {},
  props: {
    openProfile: Function,
    openEditProfile: Function,
  },
  data() {
    return {
      tempImg: null,
    };
  },
  async created() {
    this.tempImg = this.userPhotoUrl;
  },
  computed: {
    ...mapGetters(["getFirstname", "getLastname" /*, "getUserPhoto"*/]),
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    userPhotoUrl() {
      return this.userDetails && this.userDetails.Photo
        ? `https://my.newdeal.gr/${this.userDetails.Photo}`
        : require("@/assets/general/no_img_found.png");
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  watch: {
    userPhotoUrl() {
      this.tempImg = this.userPhotoUrl;
    },
  },
  methods: {
    openProfileHandler() {
      this.openProfile();
    },
    imageError() {
      this.tempImg = require("@/assets/general/no_img_found.png");
    },

    async onLogoutHandler() {
      try {
        await AuthRepo.logout();
      } catch (e) {
        this.$store.dispatch("onSetSnackbar", {
          open: true,
          text: e.message,
          color: "error",
        });
      }
    },
  },
};
</script>
<style scoped>
.textDecoration {
  font-family: century-gothic, sans-serif;
  color: white;
}
.notifContainer {
  background-color: white;
  border-radius: 10px;
  min-width: 241px;
  max-width: 241px;
  min-height: 280px;
  max-height: 280px;
}
.menu-selection {
  cursor: pointer;
}
.menu-selection:hover {
  color: #77bd86;
}
</style>