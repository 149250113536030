<template >
    <v-row>
        <v-col cols="12" align="left">
            <span class="textInterBold font14 goldText">Στοιχεία Διεύθυνσης</span>
            <!--18n-->
        </v-col>
        <v-col>
            <ReadField v-if="true == checkShow('Country')" :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue"
                :fontFamilyLabel="fontFamilyLabel" :fontFamilyValue="fontFamilyValue" :label="$t('country')"
                :data="data.Country" :isUnderline="false" /> 
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4" v-if="true == checkShow('City')">
            <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                :fontFamilyValue="fontFamilyValue" :label="$t('regions')" :data="data.City" :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4" v-if="true == checkShow('Neighborhood')">
            <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                :fontFamilyValue="fontFamilyValue" :label="$t('manicipal')" :data="data.Neighborhood"
                :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4" v-if="true == checkShow('Street')">
            <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                :fontFamilyValue="fontFamilyValue" :label="$t('addressNumber2')" :data="data.Street"
                :isUnderline="false" />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="12" class="pt-4" v-if="true == checkShow('Zip')">
            <ReadField :fontSize="fontSizeRF" :fontSizeValue="fontSizeValue" :fontFamilyLabel="fontFamilyLabel"
                :fontFamilyValue="fontFamilyValue" :label="$t('postalCode')" :data="data.Zip"
                :isUnderline="false" />
        </v-col>
        <v-row v-if="mobileBreakpoint == true && topShow.showMore != undefined" class="mt-0 justify-center" cols="12">
            <v-col class="pt-0 pb-0" align="center">
                <v-btn class="primaryColor unsetCapitalize opacity1 textDecorationBold" text plain
                    @click="() => (topShow.showMore = !topShow.showMore)">
                    {{ topShow.showMore == true ? $t("lessInfo") : $t("moreInfo") }}...
                </v-btn>
            </v-col>
        </v-row>
    </v-row>
</template>
<script>
/*
Name:AddressInfo
Description: Component to render AddressInfo using ReadFields 

API Property:
Data: Object containing AddressInfo data 
            data: {
                country: "Ελλάδα",
                area: "Αττική",
                municipality: "Παλλήνη",
                address: "Γρηγορίου Λαμπράκη 167",
                postalCode: "15688",
            },
Components Used:
    ReadField   
*/
import ReadField from '@/components/Fields/ReadField/ReadField.vue';
import CoreHelper from "@/helpers/Core/core.js";
export default {
    name: "AddressInfo",
    components: { ReadField },
    props: ['data'],
    created() {
        if (this.mobileBreakpoint == true) {
            this.topShow = CoreHelper.showObjects(this.data, this.topArray, [], 4)
        }
    },
    computed: {
        mobileBreakpoint() {
            return this.$vuetify.breakpoint.xsOnly;
        }
    },
    data() {
        return {
            topArray: [
                "country",
                "area",
                "municipality",
                "streetAndNumber",
                "postalCode",
            ],
            topShow: { showKeys: [], showMore: undefined },
            fontSizeRF: "14px", //font-size for ReadingField component
            fontFamilyLabel: "InterBold", //font-family for ReadingField component label
            fontFamilyValue: "InterMedium", //font-family for ReadingField component value
            fontSizeValue: "13px"
        }
    },
    methods: {
        checkShow(objKey) {
            return this.data[objKey]
                ? this.mobileBreakpoint != true ? true
                    : (this.topShow.showKeys && this.topShow.showKeys.includes(objKey)) ||
                        this.topShow.showMore != false ? true
                        : false
                : false;
        },
    },
}
</script>
<style >

</style>