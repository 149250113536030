<template>
  <v-row class="ratingField">
    <v-col class="label" cols="12" xs="5" sm="5" md="5" lg="5" v-if="showLabel">
      {{ this.label }}
    </v-col>
    <v-col
      class="rating"
      cols="12"
      xs="3"
      sm="3"
      md="3"
      lg="3"
      :style="'color:' + colorRating + 'important;'"
    >
      <v-rating
        dense
        :color="colorRating"
        :background-color="colorRating + '!important'"
        empty-icon="mdi-star-outline"
        :length="size"
        :readonly="disabled"
        :size="starSize"
        :half-increments="halfIncrements"
        v-model="getRatingValue"
      />
    </v-col>
    <v-col
      class="rating"
      cols="12"
      xs="4"
      sm="4"
      md="4"
      lg="4"
      v-if="showResult"
    >
      {{ resultRating }}
    </v-col>
  </v-row>
</template>

<script>
/*
  Name:RatingField
  Description: Rating Component to show and add stars.Component show a label , stars and result of stars

  API Property:
  label:Text of rating
  showLabel: if component render/show the label text
  colorRating: Color of stars and border
  size:number of stars
  val: rating value (0-5)
  starSize: Size of the shown star
  showResult: if component show result of stars
  fieldName: name of attribute to be update on parent
  halfIncrements: Boolean that enables or disables half increments
  changeHandler: handler function to update parent state
  disable: if Rating is readonly or not
*/
import CoreHelper from "../../../helpers/Core/core";
export default {
  name: "RatingField",
  props: {
    label: String,
    showLabel: { type: Boolean, default: false },
    val: [String, Number],
    colorRating: { type: String, default: "#243774" },
    size: { type: Number, default: 1 },
    starSize: {type: Number, default: 24},
    showResult: { type: Boolean, default: false },
    showResultText: {type: Boolean, default: true},
    fieldName: [String, Number],
    halfIncrements: {type: Boolean, default: false},
    changeHandler: Function,
    disabled: { type: Boolean, default: false },
  },
  computed: {
    resultRating() {
      return this.showResultText == true ? CoreHelper.resultRating(this.val) : this.val;
    },
    getRatingValue: {
      get() {
        return this.val;
      },
      set(val) {
        this.changeHandler({
          fieldName: this.fieldName,
          selectedRating: val,
        });
      },
    },
  },
};
</script>



<style scoped>
.ratingField {
  display: flex;
}
.label {
  display: flex;
  justify-content: flex-start;
  color: #000000;
}
.rating {
  display: flex;
  justify-content: flex-start;
}
.characteristic {
  display: flex;
  justify-content: flex-start;
  color: #243774;
}
</style>
  
