export default {
    state: {
        locationDetails: [],
        internationalSchools: [],
        areas: [],
    },
    getters: {},
    mutations: {
        clearLocation(state) {
            state.locationDetails = [];
        },
        setLocation(state, payload) {
            state.locationDetails = payload;
        },
        setInternationalSchools(state, payload) {
            state.internationalSchools = payload;
        },
        setAreas(state, payload) {
            state.areas = payload;
        }
    },

    actions: {
        clearLocation({ commit }) {
            commit("clearLocation");
        },
        setLocation({ commit }, payload) {
            commit("setLocation", payload);
        },
        setInternationalSchools({ commit }, payload) {
            commit("setInternationalSchools", payload);
        },
        setAreas({ commit }, payload) {
            commit("setAreas", payload)
        }
    },
};