<template >
    <v-row class="flex-nowrap" :style="{ backgroundColor: backgroundColor }" style="max-height: 32px;" align="center" v-if="description">
        <v-col class="flex-grow-0 pr-0 pb-2">
            <Icon icon="akar-icons:circle-fill" size="19" :color="color" />
        </v-col>
        <v-col class="flex-grow-0 text-no-wrap pl-2">
            <span :class="descriptionClass">{{ description }}</span>
        </v-col>
    </v-row>
</template>
<script>
/*
Name:StatusField
Description: Component to generate Status field

API Property:
     description: description text for  statusField
     descriptionClass:  Add ClassName to Description
     color:color of status
     backgroundColor: string Containing Background Color  

    Components Used:
      Icon
*/
import { Icon } from "@iconify/vue2";
export default {
    name: "StatusField",
    props: {
        description: String, //description text for  statusField
        descriptionClass: { type: String, default: "textDecorationBold font14" }, // Add ClassName to Description
        color: { type: String, default: "#77BD86" },  //color of status
        backgroundColor: { type: String, default: "#e6f3e9" }, //  string Containing Background Color  
    },
    components: {
        Icon
    },
}
</script>