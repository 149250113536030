<template>
  <v-col class="pdfVcol">
    <v-row v-if="showTitle || showPagination" class="align-center">
      <v-col v-if="showTitle" align="left" class="flex-grow-0 text-no-wrap textDecorationBold font-size-14">
        {{ getFileName(documents[currentPdfIndx]) }}
      </v-col>
      <v-col v-if="
        documents[currentPdfIndx] &&
        documents[currentPdfIndx].visibility == 'private'
      " align="left" class="flex-grow-0 text-no-wrap">
        <HintField icon="mdi-eye-off" color="#000000" :data="$t('visibleOnlyToYou')" />
      </v-col>
      <v-col align="right" v-if="showPagination">
        <b>{{ currentPdfIndx + 1 + "/" + this.documents.length }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="max-height: 712px !important; height: 100vh">
        <embed :src="getFileUrl" width="100%" height="100%" max-height="712" max-width="1270" type="application/pdf" />
      </v-col>
      <v-col v-if="showPdfDetails" class="pdfDetArea flex-grow-0">
        <v-row class="overflow-y-auto galleryScrollbarMarketingFiles flex-grow-0">
          <v-col v-for="(document, i) in this.documents" :key="i" class="d-flex child-flex flex-grow-0">
            <MediaGalleryItem :clickHandler="() => setCurrentPdf(i)" :name="getFileName(document)"
              :size="getFileSize(document)" :created="getFileDate(document)" :type="getFileType(document)" :visibility="
                document.visibility ? document.visibility : 'private'
              " style="cursor: pointer" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
/* 
  Name: PdfViewer
  Description: Component for pdf viewer
  API Property 
    data: Array for the data
    showTitle: Boolean that shows the title,
    title: String for the itle
    showPdfDetails: Boolean that shows the array of images
    showPagination: Boolean that shows the pagination on top

  Components Used:
    MediaGalleryItem
    HintField
*/
import CoreHelper from "@/helpers/Core/core.js";
import HintField from "@/components/Fields/HintField/HintField";
import MediaGalleryItem from "@/components/RealEstate/MediaGalleryItem";
export default {
  name: "PdfViewer",
  props: {
    data: Array,
    showTitle: { type: Boolean, default: false }, // Boolean that shows the title,
    title: { type: String, default: "" },
    showPdfDetails: { type: Boolean, default: false }, // Boolean that shows the array of images
    showPagination: { type: Boolean, default: false }, // Boolean that shows the pagination on top
  },
  components: { MediaGalleryItem, HintField },
  data() {
    return { currentPdfIndx: 0 };
  },
  computed: {
    documents() {
      return this.data.filter(
        (f) => this.getFileType(f).toUpperCase() == "PDF"
      );
    },
    getFileUrl() {
      return this.documents &&
        this.documents[this.currentPdfIndx] &&
        this.documents[this.currentPdfIndx].path
        ? CoreHelper.getImagePath({
          path: this.documents[this.currentPdfIndx].path.replace("../", ""),
        })
        : "";
    },
  },
  methods: {
    setCurrentPdf(i) {
      this.currentPdfIndx = i;
    },
    getFileName(document) {
      return document
        ? document.name != undefined
          ? document.name
          : document.path != undefined
            ? CoreHelper.getFileNameFromUrl(document.path)
            : ""
        : "";
    },
    getFileType(document) {
      return document
        ? document.name != undefined
          ? CoreHelper.getFileType(document.name).toUpperCase()
          : document.path != undefined
            ? CoreHelper.getFileType(document.path).toUpperCase()
            : ""
        : "";
    },
    getFileSize(document) {
      return document
        ? document.size != undefined
          ? !isNaN(document.size)
            ? CoreHelper.getFileSize(document.size)
            : ""
          : ""
        : "";
    },
    getFileDate(document) {
      return document
        ? document.timestamp != undefined
          ? CoreHelper.EpochToDate(document.timestamp)
          : ""
        : "";
    },
  },
};
</script>

<style scoped>
.pdfDetArea {
  max-width: 590px;
  min-width: 400px;
}

.pdfVcol {
  max-height: 750px !important;
  height: 100vh;
}

@media only screen and (max-width: 1204px) {
  .pdfDetArea {
    max-width: unset !important;
    min-width: unset !important;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pdfVcol {
    max-height: unset !important;
    height: unset !important;
  }
}

.galleryScrollbarMarketingFiles {
  max-height: 700px;
}
</style>