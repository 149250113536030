<template>
  <v-card>
    <v-card-title class="ml-3 mt-3 mr-3 mb-0">
      <v-row>
        <v-col>
          <HeaderTitle
            :title="$t('entryEstateInProvider')"
            :subTitle="data.category + ' ' + $t('propTo') + ' ' + data.task"
          />
        </v-col>
        <v-col
          class="flex-grow-0 text-no-wrap cusTab fontSize14 pb-6"
          align-self="end"
        >
          {{ data.estateCode }}
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            v-for="(provider, i) in providersList"
            :key="i"
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="6"
            xs="12"
            align="left"
          >
            <v-btn
              text
              v-ripple="false"
              :class="provider.status + 'Btn textDecorationBold providerBtn'"
              @click="btnClicked(provider)"
            >
              <v-col class="pl-0">
                <v-row class="align-center justify-start">
                  <v-col class="flex-grow-0 pt-0" align="left">
                    <v-img size="30" :src="provider.src"> </v-img>
                  </v-col>
                  <v-col class="align-self-center" align="left">
                    <v-row>
                      <v-col :class="provider.status + 'ProvTitle pb-0 pl-0'">
                        <span class="noTextTransform">
                          {{ provider.title }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-2 pr-1 pl-0 flex-grow-0">
                        <Icon icon="fa-solid:coins" size="18" color="#e89008" />
                      </v-col>
                      <v-col
                        align-self="center"
                        :class="
                          provider.status + 'CoinText pl-1 pt-0 noBoldText'
                        "
                      >
                        {{ provider.coinText }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="justify-start">
                  <v-col
                    :class="
                      provider.status +
                      'LeftBtnActionText flex-grow-0 text-no-wrap pt-0 pr-1 fontSize14'
                    "
                  >
                    <span> {{ getLeftBtnActionText(provider) }} </span>
                  </v-col>
                  <v-col
                    :class="
                      provider.status +
                      'RightBtnActionText flex-grow-0 text-no-wrap pt-0 pl-0 fontSize14'
                    "
                  >
                    <span> {{ getRightBtnActionText(provider) }} </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-row class="justify-start">
          <v-col align="left" class="pl-0">
            <span class="moreCoins">
              {{ $t("needMoreCoinsMsg") }}
            </span></v-col
          >
        </v-row>
        <v-row>
          <v-col class="pl-0 pb-0">
            <v-checkbox v-model="isPeriodical" class="">
              <template v-slot:label>
                <span class="setPeriodTimeClass">
                  {{ $t("setPeriodTime") }}
                </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="isPeriodical" class="mt-0">
          <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12" class="pt-0">
            <v-menu
              ref="menuDtFrom"
              v-model="menuDtFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFromFormatted"
                  :label="$t('fromDt') + '...'"
                  clearable
                  color="primary"
                  hide-details="auto"
                  @blur="dateFrom = parseDate(dateFromFormatted, 'dateFrom')"
                >
                  <template v-slot:append>
                    <v-icon v-bind="attrs" v-on="on"> mdi-calendar </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="dateFrom"
                scrollable
                @input="menuDtFrom = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6" xs="12" class="pt-0">
            <v-menu
              ref="menuDtTo"
              v-model="menuDtTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateToFormatted"
                  :label="$t('toDt') + '...'"
                  clearable
                  color="primary"
                  hide-details="auto"
                  @blur="dateTo = parseDate(dateToFormatted, 'dateTo')"
                >
                  <template v-slot:append>
                    <v-icon v-bind="attrs" v-on="on"> mdi-calendar </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="dateTo"
                scrollable
                @input="menuDtTo = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col>
            <v-btn
              @click="close"
              align="center"
              class="textDecorationBold bottomBtns"
              text
              color="error"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              elevation="5"
              @click="submit"
              :loading="loading"
              :disable="loading"
              align="center"
              class="textDecorationBold bottomBtns"
              color="error"
            >
              {{ $t("submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

 <script>
  /*
 Name: Providers
 Description: Component for the providers at the slider

 API Property: 
    data: Object, Array containing location info
    closeHandler: Function that closes if opened as dialog
    submitHandler: Function that submits

    Components Used:
     HeaderTitle
     Icon
    
    Endpoints Functions Called:
    -RealEstateRepo
      insertProvider
      deleteProvider
*/
import { mapActions /* ,mapState */ } from "vuex";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import RealEstateModel from "@/static/realEstate/realEstate.js";
import Repository from "@/services/Repository";
const RealEstateRepo = Repository.get("realEstate");
//const ProvidersRepo = Repository.get("reqProviders");
import ProviderHelper from "@/helpers/RealEstate/providers.js";
import { Icon } from "@iconify/vue2";
// const isFormattedDate = (dateString) => {
//   if (dateString == null) return false;
//   let regEx =
//     /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
//   return dateString.match(regEx) != null;
// };
export default {
  name: "Providers",
  props: {
    data: Object,
    closeHandler: Function, //Function that closes if opened as dialog
    submitHandler: Function, // Function that submits
  },
  components: {
    HeaderTitle,
    Icon,
  },
  data() {
    return {
      providersList: RealEstateModel.providers,
      loading: false,
      // isPeriodical: false,
      // dateFrom: null,
      // dateFromFormatted: null,
      // menuDtFrom: false,
      // dateTo: null,
      // dateToFormatted: null,
      // menuDtTo: false,
    };
  },
  created() {
    this.providersList = this.providersList.map((m) => {
      m.coins = this.getProviderCoins(m.id);
      m.coinText = m.coins;
      m.status = this.getStatus(m);
      return m;
    });
  },
  computed: {
    estateProviders() {
      return ProviderHelper.getEstateProviders(this.data);
    },
  },
  methods: {
    ...mapActions(["onSetCoins", "onSetSnackbar"]),
    getProviderCoins(provider) {
      return ProviderHelper.getProviderCoins(provider);
    },
    getStatus(provider) {
      let published = this.estateProviders.some(function (e) {
        return e.Characteristic == provider.id;
      });
      return published == true ? "published" : "default";
    },
    getLeftBtnActionText(provider) {
      return ProviderHelper.getLeftBtnActionText(provider);
    },
    getRightBtnActionText(provider) {
      return ProviderHelper.getRIghtBtnActionText(provider);
    },
    btnClicked(provider) {
      provider = ProviderHelper.updateProvider(provider);
      provider;
    },
    async insertProvider(provider) {
      try {
        const resp = await RealEstateRepo.insertProvider(
          provider.id,
          this.data.estateId
        );
        if (resp.status && resp.status == 200) {
          let coinProp = ProviderHelper.getProviderCoinProp(resp.data.provider);
          await this.onSetCoins({
            prop: coinProp,
            value: resp.data.available_ads_after_submission,
          });
          // if (provider.id == "Spitogatos.gr") {
          //   let idArray = [this.data.estateId];
          //   // let newIdArray = JSON.stringify(idArray);
          //   await ProvidersRepo.pubishSpitogatos({'action':'exec', 'id': idArray});
          // }
          // if (provider.id == "ΧΕ") {
          //   let idArray = [this.data.estateId];
          //   await ProvidersRepo.pubishΧΕ({'action':'exec', 'id':idArray});
          // }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async deleteProvider(provider) {
      try {
      const resp = await RealEstateRepo.deleteProvider(
        provider.id,
        this.data.estateId
      );
      if (resp.status && resp.status == 200) {
        // let coinProp = ProviderHelper.getProviderCoinProp(resp.data.provider);
        // await this.onSetCoins({
        //   prop: coinProp,
        //   value: resp.data.available_ads_after_deletion,
        // });
          //  if (provider.id == "Spitogatos.gr") {
          //   let idArray = [this.data.estateId];
          //   // let newIdArray = JSON.stringify(idArray);
          //   await ProvidersRepo.pubishSpitogatos({'action':'del', 'id': idArray});
          // }
          // if (provider.id == "ΧΕ") {
          //   let idArray = [this.data.estateId];
          //   await ProvidersRepo.pubishΧΕ({'action':'del', 'id':idArray});
          // }
      }
      } catch (err) {
        console.error(err);
      }
    },
    close() {
      try {
        this.closeHandler();
      } catch (err) {
        console.error(err);
      }
    },
    async submit() {
      try {
        this.loading = true;
        for (let index = 0; index < this.providersList.length; index++) {
          if (this.providersList[index].status == "toPublish")
            await this.insertProvider(this.providersList[index]);
          else if (this.providersList[index].status == "toDelete")
            await this.deleteProvider(this.providersList[index]);
        }
        this.loading = false;
        this.submitHandler();
        this.close();
      } catch (err) {
        throw err;
      }
    },
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${day}/${month}/${year}`;
    // },
    // parseDate(date, dateName) {
    //   if (!date || !isFormattedDate(date)) {
    //     if (dateName == "dateFrom") {
    //       this.dateFrom = null;
    //       this.dateFromFormatted = null;
    //     } else if (dateName == "dateTo") {
    //       this.dateTo = null;
    //       this.dateToFormatted = null;
    //     }
    //     return null;
    //   }
    //   const [day, month, year] = date.split("/");
    //   return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    // },
  },
};
</script> 

<style scoped src="@/assets/styles/RealEstate/RealEstateSlider/providers.css"/>